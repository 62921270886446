import i18n from 'i18n-js';

import en from './en.json';
import es from './es.json';
import * as Localization from 'expo-localization';

const availableTranslations = {
    en,
    es
};
///start locale on spanish 
i18n.defaultLocale = 'es';
i18n.fallbacks = true;
i18n.translations = { en, es };
i18n.locale = "es";
// i18n.defaultLocale = Localization.locale;
i18n.fallbacks = true;

export default i18n;
    