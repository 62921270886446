import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, FlatList, Modal, TouchableWithoutFeedback, TouchableOpacity, ActivityIndicator, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import * as ImagePicker from 'expo-image-picker';
// import { v4 as uuidv4 } from 'uuid'
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import {getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable} from 'firebase/storage';
import { GET_CHAT, GET_RECEIVER_CHAT, GET_SENDER_CHAT, GET_USER, SEND_MESSAGE } from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import { Actions, Bubble, GiftedChat } from 'react-native-gifted-chat';
import { useIsFocused } from '@react-navigation/native';
import i18n from '../i18n/i18n';
import { getFunctions, httpsCallable } from 'firebase/functions';

const auth = getAuth(Firebase);
const storage = getStorage(Firebase);
const functions = getFunctions(Firebase);
const Messages = ({navigation , route}) => {

    // const {sellerId} = route.params;
    const {chatId}  = route.params;
    const {expoToken} = route.params;
    console.log("ChatId: ", chatId)
    console.log("ExpoToken: ", expoToken)
    const uid = getAuth().currentUser.uid;
    const [orders, setOrders] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    const [image, setImage] = useState(null);
    const [userName, setUserName] = useState();
    const [userLastname, setUserLastname] = useState();
    const [receiverExpoToken, setReceiverExpoToken] = useState();

    const {loading:chatLoading, data:chatData, error:chatError} = useSubscription(GET_CHAT, {variables:{chatId:chatId}});
    const {loading:getUserLoading, data:getUserData, error:getUserError} = useQuery(GET_USER, {variables:{uid}});

    const [sendMessage, {loading:sendMessageLoading, error:sendMessageError}] = useMutation(SEND_MESSAGE);

    const isFocused = useIsFocused();

    useEffect(() => {
        const getInitialMessages = async () => {
            try{
              console.log(chatError)
                if(chatData){
                    const {messages} = chatData; 
                    setMessages(messages);
                    console.log("MESSAGES", messages)
                }
              }catch(error){
                // console.log(error);
              }
            
        }
        console.log("ERROR", chatError);
        console.log("CHAT DATA", chatData);
        if(getUserData){
          const {users_by_pk} = getUserData;
          const {user_name, user_lastname, user_expo_token} = users_by_pk;
          setUserName(user_name);
          setUserLastname(user_lastname);
          setReceiverExpoToken(user_expo_token);
        }


        getInitialMessages();

        (async () => {
            if (Platform.OS !== 'web') {
              const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
              if (status !== 'granted') {
                alert('Sorry, we need camera roll permissions to make this work!');
              }
            }
          });

    });


    const onSend = useCallback((messages = []) => {
        // console.log(messages);
        setMessages(previousMessages => GiftedChat.append(previousMessages, messages));
        sendMessage({
            variables:{
                senderId:uid,
                message:messages[0].text,
                image:messages[0].image,
                video:messages[0].video,
                chatId:chatId,
            }
        }).then(async res => {
            try {
              // console.log("ONSEND: ", res)
            const {data} = res;
            const {insert_messages_one} = data;
            const {message} = insert_messages_one;
            const {userBySenderId} = insert_messages_one;
            const {user_name, user_lastname} = userBySenderId;

            /**Merge user_name, user_lastname */

            const full_name = user_name + ' ' + user_lastname;

            /**send notification expo */

            // const sendExpoPushNotification = fns.httpsCallable('sendExpoPushNotification');
          

            //  sendExpoPushNotification({
            //     token: expoToken,
            //     title: full_name,
            //     body: message,              
            // });

            // console.log("MESSAGE", message);
            // console.log("TOKEN", expoToken);
              
            const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');

            sendExpoPushNotification({
                token: expoToken,
                title: full_name,
                body: message,
                chatId: chatId,
                action: 'send_message',
                receiverToken: receiverExpoToken,
            }).then(res => {
              console.log("RESPONSE NOTIFICATION SEND", res)
            });

            } catch (error) {
                // console.log(error);
            }

        })
        // console.log(messages);
      }, []);

    const renderBubble = (props) => {
        return (
            <Bubble {...props}
            wrapperStyle={{

                left: {
                    backgroundColor: '#e0e0e0',
                    
                }, 
                right:{
                    backgroundColor: '#FF7043'
                }
            }}
            />
        )
    }

    const pickImageGallery = async () => {
        let result = await ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.All,
          allowsMultipleSelection: true,
          aspect: [1, 1],
          quality: Platform.OS === 'ios' ? 0 : 1,
        });

        // console.log(result);

        if (!result.canceled) {
          setImage(result.uri);

          const url = await uploadImage(result.uri).then(
            (response) => {
                // console.log("Response: ", response );
                // onSend([{image:response}]);

                // sendMessage({
                //     variables:{
                //         senderId:uid,
                //         recipientId:sellerId,
                //         message:'Image',
                //         image:response,
                //         video:null,
                //     }
                // })
            }
          );
 
        }
      };

      const handleImagePick = async ()=>{
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("You've refused to allow this app to access your camera!");
            return;
          }
      
          const result = await ImagePicker.launchCameraAsync({
              mediaTypes: ImagePicker.MediaTypeOptions.All,
              allowsEditing: true,
              aspect: [2, 2],
              quality:Platform.OS === 'ios' ? 0 : 1, 
          });
      
          // Explore the result
          // console.log(result);
      
          if (!result.canceled) {
            setImage(result.uri);
            
            await uploadImage(result.uri).then(
                (response) => {
                    // console.log("Response Cameras: ", response );
                    // onSend([{image:response}]);
                    // sendMessage({
                    //     variables:{
                    //         senderId:uid,
                    //         message:'Image',
                    //         image:response,
                    //         chatId:chatId,
                    //     }
                    // })
                });
              }
    }
    
      async function uploadImage(uri) {
        // Why are we using XMLHttpRequest? See:
        // https://github.com/expo/expo/issues/2402#issuecomment-443726662
        // const blob = await new Promise((resolve, reject) => {
        //     const xhr = new XMLHttpRequest();
        //     xhr.onload = function () {
        //       resolve(xhr.response);
        //     };
        //     xhr.onerror = function (e) {
        //       console.log(e);
        //       reject(new TypeError("Network request failed"));
        //     };
        //     xhr.responseType = "blob";
        //     xhr.open("GET", uri, true);
        //     xhr.send(null);
        //   });
        
        //   // const ref = Firebase.storage().ref().child('chat-images/' + uuidv4());
        //   const fileref = ref(storage, 'chat-images/' + uuidv4());
        //   const result = await uploadBytes(fileref, blob);
        //   // const snapshot = await ref.put(blob);
        
        //   // We're done with the blob, close and release it
        //   blob.close();

        // return await getDownloadURL(fileref)

        const fileref = ref(storage, 'chat-images/' + uuidv4());
        const img = await fetch(uri);
        const blob = await img.blob();

        // console.log('uploading image...');

        const uploadTask = uploadBytesResumable(fileref, blob);

        uploadTask.on('state_changed', (snapshot) => {
          
        }, (error) => {
          // console.log(error);
        } , () => {

          // console.log('upload completed');
          getDownloadURL(fileref).then(
            (response) => {
                // console.log("Response: ", response );
                // onSend([{image:response}]);
                sendMessage({
                    variables:{
                        senderId:uid,
                        message:'Image',
                        image:response,
                        chatId:chatId,
                    }
                })
            });
        });


      }

    const renderActions = (props) => {
        return (
            <Actions
            {...props}
            options={{
                ['Pick Image From Camera']  : handleImagePick,
                ['Pick Image From Gallery'] : pickImageGallery,
            }}
            icon={()=>(
                <Feather name="image" size={20} color='#8a918c'/>
            )}
            onSend={args =>{ 
              // console.log(args)
            }}
            ></Actions>
        )
    }
    return(
            <SafeAreaView style={{flex:1, paddingVertical:-45}}>
                    <GiftedChat
                    messages={messages}
                    renderUserNameOnMessage={true}
                    alwaysShowSend={true}
                    showUserAvatar={true}
                    showAvatarForEveryMessage={false}
                    // showAvatarForEveryMessage={true}
                    renderUsernameOnMessage={true}
                    renderAvatarOnTop={true}
                    renderUserName={true}

                    renderBubble={renderBubble}
                    onSend={messages => onSend(messages)}
                    renderActions={renderActions}
                    user={{
                        _id: uid,
                    }}></GiftedChat>
            </SafeAreaView>
            
    )
    
}

export default Messages;