import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, KeyboardAvoidingView, Platform, Modal } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';

const AddProductName = ({navigation, route}) => {

    /**route.params */
    const {subCategoryId} = route.params;

    const [productTitle, setProductTitle] = useState('')
    const [productSKU, setProductSKU] = useState('')
    const [productSlug, setProductSlug] = useState('')
    const [visibleText, setVisibleText] =useState(true)
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);

    function slugify(text) {
      const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
      const to = "aaaaaeeeeeiiiiooooouuuunc------"
    
      const newText = text.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))
    
      return newText
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }

    function removeSpacesFromSku(text){
      text = text.replace(/\s+/g, '');

      return text
    }

    useEffect(()=>{
      // console.log("SUBCATNAME: ", subCategoryId)
    })

    return(

      <SafeAreaView style={{flex:1, paddingTop:-45}}>
              <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <KeyboardAvoidingView style={{flex:1, paddingTop:-35}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:5, paddingHorizontal:5, flex:1}}>
            <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('products.addProductName.title')}
            </Text>
            </View>
        </View>
        <View style={{flex:2}}>
        {(visibleText) ? (
                <Text style={{paddingHorizontal:15,fontSize:18}}>
                  {i18n.t('products.addProductName.body')}
                  </Text>
              ):null}
            <TextInput style={{paddingHorizontal:5, backgroundColor:'#c9c1c1', paddingVertical:6,
             marginHorizontal:15, fontSize:18, borderRadius:6}} 
             onFocus={()=>setVisibleText(false)}
             placeholder={i18n.t('products.addProductName.name_placeholder')}
             onChangeText={(v)=>{setProductTitle(v); setProductSlug(slugify(v))}} 
            />
            <TextInput style={{paddingHorizontal:5, backgroundColor:'#c9c1c1',paddingVertical:6, marginTop:10, 
             marginHorizontal:15, fontSize:18, borderRadius:6}} 
             onFocus={()=>setVisibleText(false)}
             placeholder={i18n.t('products.addProductName.label_sku')}
             autoCapitalize = {"characters"}
             onChangeText={(v)=>{setProductSKU(removeSpacesFromSku(v)); 
              // console.log(removeSpacesFromSku(v))
            }}
            />

            
        </View> 
        <View style={{flex:0.4, paddingHorizontal:5}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=> {if(productTitle.trim() === '' && productSKU.trim() === ''){
              Alert.alert(i18n.t('products.addProductName.alert_product_name_required'), i18n.t('products.addProductName.alert_product_name_required_body'));
              if(Platform.OS === 'web'){
                setMessageTitle(i18n.t('products.addProductName.alert_product_name_required'));
                setMessageBody(i18n.t('products.addProductName.alert_product_name_required_body'));
                setModalVisibilityAlert(true);
              }
            } else{
              navigation.navigate('AddProductImage', {
                productTitleName:{productTitle},
                productSKUName:{productSKU},
                productSlugName:{productSlug},
                subCategoryId,
              })
            }}}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:16}}>
                  {i18n.t('products.addProductName.btn_next')}
                </Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
    </SafeAreaView>
        

    )
    
}

export default AddProductName;