import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Alert, Modal, Platform} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {Picker} from '@react-native-picker/picker';
import { Feather } from '@expo/vector-icons';
import { useQuery, useMutation} from '@apollo/client';
import { TextInput } from 'react-native-gesture-handler';
import { GET_CITY_BY_PROVINCE, GET_COUNTRIES, GET_PROVINCE_BY_COUNTRY, INSERT_USER_LOCATION } from '../../lib/queries';
// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { set } from 'react-native-reanimated';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import i18n from '../../i18n/i18n';
const auth = getAuth(Firebase);
const AddLocation = ({navigation}) => {

  /**User UID */
  const uid = getAuth().currentUser.uid;  

  /**Defining states react*/
  const [country, setCountry] = useState([])
  const [province, setProvince] = useState([])
  const [city, setCity] = useState([])
  const [zipCode, setZipCode] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  // const [provinceId, setProvinceId] = useState(null)

  const [countryId, setCountryId] = useState();
  const [provinceId, setProvinceId] = useState();
  const [cityId, setCityId] = useState();
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(0)
  const [selectedProvince, setSelectedProvince] = useState(0)
  const [selectedCity, setSelectedCity] = useState(0)
  /**Queries*/
  const {loading: fetchLoading, data} = useQuery(GET_COUNTRIES)
  const {loading: fetchProvince, data: dataProvince} = useQuery(GET_PROVINCE_BY_COUNTRY, { variables:{ countryId }})
  const {loading: fetchCity, data: dataCity} = useQuery(GET_CITY_BY_PROVINCE, {variables:{provinceId}})

  const [saveUserLocation,  {loading: mutationUserLocation, data: responseUserLocation}] = useMutation(INSERT_USER_LOCATION)

  
  useEffect(()=>{
     let newCountries = []
     let newProvinces = []
     let newCities = []
    if (data) {
      // console.log(data)
      /**Defining the name of the query fuction */
      const {countries: country} = data
      
      newCountries = country
      console.log("Data: ", newCountries)

      
    }

    if(dataProvince){
      const{provinces: provinc} = dataProvince
      newProvinces = provinc
    }
    if (dataCity){
      const{cities: citi} = dataCity
      newCities = citi
    }
    setCountry(newCountries)
    setProvince(newProvinces)
    setCity(newCities)
  }, [data]);

  // const PickerCountryChange =(index)=>{
  //   country.map((v,i)=>{
  //     if (index === i){
  //       setCountryId(country[index].country_id)
  //       console.log('Country ID',countryId)
  //     }
  //   });
  // }

    return(
    <SafeAreaView style={{flex:1, paddingTop:-45, paddingBottom:-45}}>
            <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
                              {/* <TouchableOpacity style={{marginTop:20}} onPress={() => {
                                // navigation.navigate('Home')
                                setModalVisibilityAlert(false)
                              }}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                              </TouchableOpacity> */}
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>      
      <View style={{flexDirection:'row', paddingHorizontal:6, paddingTop:5, alignItems:'center'}}>
      <TouchableOpacity
                  style={{backgroundColor:'#e0e0e0', alignItems:'center', justifyContent:'center', height:32, width:32}}
                  onPress={() => navigation.goBack()}
                >
                  <Feather name='arrow-left' size={16} color='#000000' />
                </TouchableOpacity>
                <View style={{paddingHorizontal:5}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>{i18n.t('userSettings.locations.addLocation.title')}
            </Text>
          </View>
          
        </View>
      </View>

      {(!fetchLoading) ? (
        <View style={{paddingHorizontal:6, paddingVertical:6}}>
        <Text style={{fontSize:18}}>
          {i18n.t('userSettings.locations.addLocation.country')}
        </Text>
        <Picker
        selectedValue={selectedCountry}
    style={{flexGrow:1, paddingVertical:5}}
    itemStyle={{height: 100}}
    onValueChange={(itemValue, itemIndex) => {setCountryId(itemValue); setSelectedCountry(itemValue)}}>
       <Picker.Item label={i18n.t('userSettings.locations.addLocation.show_items')}  
              value="0" key="0"/>
      {
        country.map((v) =>{
          return <Picker.Item label={v.country_name} value={v.country_id} key={v.country_id}></Picker.Item>
        })
      }


  </Picker>
      </View>  
      ): null}
<View>
<Text style={{paddingHorizontal:5,fontSize:15}}>
  {i18n.t('userSettings.locations.addLocation.province')}
        </Text>
</View>
      {(dataProvince)? (
        
        <View style={{paddingHorizontal:5}}>
         
          <Picker
          selectedValue={selectedProvince}
        style={{flexGrow:1, paddingVertical:6}}
        onValueChange={(itemValue, itemIndex) => {setProvinceId(itemValue); setSelectedProvince(itemValue)}}
        > 
        <Picker.Item label={i18n.t('userSettings.locations.addLocation.show_items')}
              value="0" key="0"/>
          {
            dataProvince.provinces.map((v)=>{
              return <Picker.Item label={v.province_name} 
              value={v.province_id} key={v.province_id}
              ></Picker.Item>
            })
          }
        </Picker>
       
          </View>

          
      ): null} 
      <View style={{paddingHorizontal:5}}>
      <Text style={{fontSize:15, paddingVertical:6}}>
        {i18n.t('userSettings.locations.addLocation.city')}
      </Text>
            {(selectedProvince)?(
         <Picker
         style={{flexGrow:1, paddingVertical:6}}
         selectedValue={selectedCity}
         onValueChange={(itemValue, itemIndex) => {setCityId(itemValue); setSelectedCity(itemValue)}}
         > 
         <Picker.Item label={i18n.t('userSettings.locations.addLocation.show_items')}
              value="" key="0"/>
         {(dataCity)?(
           dataCity.cities.map((v)=>{
             return <Picker.Item label={v.city_name} value={v.city_id} key={v.city_id}></Picker.Item>
           })
         ):null}
          </Picker>
      ): null}
      </View>
      <View style={{paddingVertical:10, flexDirection:'row', paddingHorizontal:6}}>
            <TextInput style={{flexGrow:1, paddingHorizontal:4, backgroundColor:'#e0e0e0'}}
            placeholder={i18n.t('userSettings.locations.addLocation.address1')}
            onChangeText={(v)=> {setAddress1(v); console.log(address1)}}>
            </TextInput>
          </View>
          <View style={{paddingBottom:6, flexDirection:'row', paddingHorizontal:6}}>
            <TextInput style={[{flexGrow:1, paddingHorizontal:4, backgroundColor:'#e0e0e0'}]}
            placeholder={i18n.t('userSettings.locations.addLocation.address2')}
            onChangeText={(v)=> {setAddress2(v)}}
            >
            </TextInput>
          </View>
          <View removeClippedSubviews={true} style={{flexDirection:'row', paddingHorizontal:8}}>
            <TextInput style={{ flexGrow:1, paddingHorizontal:4, backgroundColor:'#e0e0e0'}}
            keyboardType={'numeric'}
            contextMenuHidden={true}
            placeholder={i18n.t('userSettings.locations.addLocation.postal_code')}
            onChangeText={(v)=> setZipCode(v)}>
            
            </TextInput>
          </View>
      <View style={{flexDirection:'row'}}>
      </View>
       <View style={{paddingHorizontal:5}}>
       <TouchableOpacity
            style={{backgroundColor:'#000000', borderRadius:6, paddingVertical:12, marginTop:15}}
            onPress={()=> {
              address1.trim()
              address2.trim()
              zipCode.trim()
              if(address1 === ''){
                Alert.alert(i18n.t('userSettings.locations.addLocation.error_primary_address'), i18n.t('userSettings.locations.addLocation.error_primary_address_body'))
                if(Platform.OS === 'web'){
                  setMessageTitle(i18n.t('userSettings.locations.addLocation.error_primary_address'))
                  setMessageBody(i18n.t('userSettings.locations.addLocation.error_primary_address_body'))
                  setModalVisibilityAlert(true);
                }
              }else{
                saveUserLocation({
                  variables:{
                    uid,
                    cityId,
                    countryId,
                    provinceId,
                    zipCode,
                    address1,
                    address2,
                  }
                }).then(res=>{
                  Alert.alert(i18n.t('userSettings.locations.addLocation.alert_success_title'),Alert.alert(i18n.t('userSettings.locations.addLocation.alert_success_title')) , [
                    { text: "OK", onPress: () => navigation.goBack()}
                  ])
                  if(Platform.OS === 'web'){
                    setMessageTitle(i18n.t('userSettings.locations.addLocation.alert_success_title'))
                    setMessageBody(i18n.t('userSettings.locations.addLocation.alert_success_body'))
                    setModalVisibilityAlert(true);
                  }
                }).catch(e =>{
                  Alert.alert('Error', e.message)
                })
              }
            }}
          >
            <Text style={{textAlign:'center', color:'#FFFFFF', fontSize:16, borderRadius:6}}>
              {i18n.t('userSettings.locations.addLocation.btn_save')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:12, marginTop:6}}
            onPress={()=> navigation.goBack()}
          >
            <Text style={{textAlign:'center', fontWeight:'bold', borderRadius:6}}>
              {i18n.t('userSettings.locations.addLocation.btn_cancel')}
            </Text>
          </TouchableOpacity>
       </View>
      </SafeAreaView>
    )
    
}

export default AddLocation;