import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, TouchableOpacity  } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useMutation} from '@apollo/client';
import { DELETE_CURRENCY_FROM_USER, GET_CURRENCIES_BY_COUNTRY, GET_USER_COUNTRY_PAYMENT_CURRENCY, INSERT_CURRENCY_TO_USER, UPDATE_USER_COUNTRY_PAYMENT } from '../../../lib/queries';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
// import { } from 'react-native-gesture-handler';
import i18n from '../../../i18n/i18n';

const auth = getAuth(Firebase);

const CashConfig = ({navigation, route}) => {
    /**Route.params */
    const {item} = route.params;

    
    /**Firebase UID */
    const uid= getAuth().currentUser.uid;
    /**States */
    const [currencies, setCurrencies] = useState([]);
    const [arrayCurrencies, setArrayCurrencies] = useState([]);
    const [idUserCountryPaymentOption, setIdUserCountryPaymentOption] = useState(item.id_user_country_payment_option? item.id_user_country_payment_option : '');
    /**Queries */
    const {loading: getCountryCurrencies, data: countryCurrencies } = useQuery(GET_CURRENCIES_BY_COUNTRY, {variables:{
        uid,
        idUserCountryPaymentOption: item.id_user_country_payment_option? item.id_user_country_payment_option : '',
    }});
    /**Query selected currencies */
    const {loading: getUserCountryPaymentCurrency, data: userCountryPaymentCurrency, refetch } = useQuery(GET_USER_COUNTRY_PAYMENT_CURRENCY, {variables:{idUserCountryPaymentOption}});
    /**Mutations */
    const [updateUserCountryPaymentOption, {loading: mutationLoading, data:mutationData}] = useMutation(UPDATE_USER_COUNTRY_PAYMENT)
    const [deleteUserCurrency, {loading: deleteLoading, data:deleteData}] = useMutation(DELETE_CURRENCY_FROM_USER);
    const [insertUserCurrency, {loading: insertLoading, data:insertData}] = useMutation(INSERT_CURRENCY_TO_USER);

    useEffect(()=>{
        console.log(idUserCountryPaymentOption);       
        let newCurrencies;
        
        if(countryCurrencies){
            const {country_currencies} = countryCurrencies;
            newCurrencies = country_currencies;
            // console.log('user LOcations: ',user_locations)
            // console.log('newCurrencies: ',newCurrencies)
            // console.log(countryCurrencies['user_locations'][0]['country']['country_currencies'][0]['currency']['currency_code']);
        }
        // console.log('newCurrencies: ',newCurrencies)
        setCurrencies(newCurrencies);

        if(userCountryPaymentCurrency){
        const {user_country_payment_option} = userCountryPaymentCurrency;
        // console.log('user_country_payment_option: ',user_country_payment_option[0]['user_country_payment_option_config']['currencies'])
        // setArrayCurrencies(user_country_payment_option[0]['user_country_payment_option_config']['currencies']);
        // // console.log('arrayCurrencies: ', user_country_payment_option[0]['user_country_payment_option_config']['currencies'])
        setArrayCurrencies(user_country_payment_option[0]['user_country_payment_option_config']? user_country_payment_option[0]['user_country_payment_option_config']['currencies'] : []);
       

        console.log('arrayCurrencies: ', arrayCurrencies)
        }
    })

    const selectCurrency = async (currency) => {
        let newArrayCurrencies = [...arrayCurrencies]; 
    
        // newArrayCurrencies.find(item => item.currency_code === currency) ? (newArrayCurrencies = newArrayCurrencies.filter(item => item.currency_code !== currency )) : (newArrayCurrencies.push({currency_code: currency}));
        
        if(newArrayCurrencies.find(item => item.currency_code === currency)){
            newArrayCurrencies = newArrayCurrencies.filter(item => item.currency_code !== currency );
            setArrayCurrencies(newArrayCurrencies);
            let currencies = [...newArrayCurrencies];
            let currencieslist = {currencies};
            await updateUserCountryPaymentOption({variables:{idUserCountryPaymentOption, currenciesarray:currencieslist}}).then(()=>refetch());
            await deleteUserCurrency({variables:{currencyCode: currency, uid: uid}}).catch((error)=>console.log(error));
        }else{
            newArrayCurrencies.push({currency_code: currency});
            setArrayCurrencies(newArrayCurrencies);
            let currencies = [...newArrayCurrencies];
            let currencieslist = {currencies};

            await updateUserCountryPaymentOption({variables:{idUserCountryPaymentOption,currenciesarray: currencieslist}}).then(()=>{refetch()});
            await insertUserCurrency({variables:{currencyCode: currency, uid: uid}}).catch((error)=>console.log(error));
        }
        
        console.log('newArrayCurrencies: ',newArrayCurrencies);
    }

    
    const updateUserCountryPayment =  () => {
        let currencies = [...arrayCurrencies];
        // let currencyCodes = newArrayCurrencies.map(item => item.currency_code);
        // console.log('currencyCodes: ',currencyCodes);
        // let currencieslist = {currencies}
        // updateUserCountryPaymentOption({variables:{idUserCountryPaymentOption, currenciesarray: currencieslist}})
       updateUserCountryPaymentOption({variables:{idUserCountryPaymentOption, currenciesarray: currencies}})
    }

    const unselectUserCountryPaymentOption =  () => {
         updateUserCountryPaymentOption({variables:{idUserCountryPaymentOption, currencies: [arrayCurrencies]}}).then(()=>{
            console.log('unselectUserCountryPaymentOption: ', arrayCurrencies);
        })
    }


    const Item = ({datacurrency}) => {                                                                                  
        const backGroundColor = arrayCurrencies.filter(item => item.currency_code === datacurrency['currency']['currency_code']).length > 0 ? {backgroundColor:'#304FFE', paddingVertical:12, color:'#FFFFFF', borderRadius:6}  : {backgroundColor:'#e0e0e0', paddingVertical:12, color:'#FFFFFF', borderRadius:6};
        const textStyle = arrayCurrencies.filter(item => item.currency_code === datacurrency['currency']['currency_code']).length > 0 ? {color:'#FFFFFF', fontSize:18, textAlign:'center', fontFamily:'Inter-Bold'} : {color:'#000000', fontSize:18, textAlign:'center', fontFamily:'Inter-Bold'};
        return(
            <View style={{marginBottom:10}}>
                <TouchableOpacity style={backGroundColor}
                onPress={()=>{
                    selectCurrency(datacurrency['currency']['currency_code'])
                }}
                >
                <Text style={textStyle}>{datacurrency['currency']['currency_code']}</Text>
                </TouchableOpacity>  
            </View>
        )
    }

    const renderItem = ({item}) => <Item datacurrency={item} />

    return(
        <SafeAreaView style={{flex:1, paddingVertical:-75}}>
        <View style={{paddingHorizontal:5, paddingTop:0}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontFamily:'Inter-Medium', fontSize:32}}>
                {i18n.t('paymentMethods.cash.title')} 
            </Text>
            </View>
            <View>
            <Text style={{fontSize:18}}>
                {i18n.t('paymentMethods.cash.body')}
            </Text>
        </View>
        <View style={{paddingTop:25}}>
           <FlatList data={currencies} 
              renderItem={renderItem}
              keyExtractor={(item)=> item.country_currency_id.toString()}
           /> 
        </View>
        </View>
    </SafeAreaView>
    )
    
}
export default CashConfig;

