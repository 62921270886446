import { ApolloClient, ApolloProvider } from '@apollo/client';
import { registerRootComponent } from 'expo';
import {apolloClient} from './lib/apollo'
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

const Wrap = () =>(
    <ApolloProvider client={apolloClient}>
        <HelmetProvider>
        <App></App>
        </HelmetProvider>
    </ApolloProvider>
)

registerRootComponent(Wrap);
