import React, { useState, useEffect, useRef, useCallback } from 'react';
import { View, Text, ImageBackground, Animated, TouchableOpacity, TouchableWithoutFeedback, Modal, Alert, TextInput, FlatList, ToastAndroid, Platform, Dimensions, useWindowDimensions, Image, Share, ActivityIndicator, KeyboardAvoidingView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { CREATE_ORDER, GET_PRODUCT_BY_ID_DETAILED, GET_USER_LOCATIONS, GET_CHAT_FROM_PRODUCT, CREATE_CHAT_FROM_PRODUCT, CREATE_ORDER_CASH, INSERT_NEW_PRODUCT_TO_CART, GET_USER_STATUS, CREATE_ORDER_CASH_ORGANIZATION, GET_ALL_USERS_BY_ORGANIZATION_ID, UPDATE_PRODUCT_QUANTITY, CREATE_CHAT_MYSELF_FROM_PRODUCT, INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT, CREATE_ORDER_CARD, CREATE_ORDER_CARD_ORGANIZATION } from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import { WebView } from 'react-native-webview';
import Firebase from '../lib/firebase';
import { getAuth, signInWithEmailAndPassword, FacebookAuthProvider, signInWithCredential } from 'firebase/auth';
import { Picker } from '@react-native-picker/picker';
import ImageViewer from 'react-native-image-zoom-viewer';
import { useFonts } from 'expo-font';
import i18n from '../i18n/i18n';
import grayPlaceholder from '../assets/gray-image-placeholder.png';
import * as Location from 'expo-location';
import { useIsFocused } from '@react-navigation/native';
/**make import if platform is web */
import AdSense from 'react-adsense';
import { ExpandingDot } from "react-native-animated-pagination-dots";
import * as ImagePicker from 'expo-image-picker';
import { useTogglePasswordVisibility } from '../hooks/useTogglePasswordVisibility';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { makeRedirectUri, ResponseType } from 'expo-auth-session';
import { getFunctions, httpsCallable } from 'firebase/functions';
import MapView, { Circle, PROVIDER_GOOGLE } from 'react-native-maps';
// import { initPaymentSheet, presentPaymentSheet } from '@stripe/stripe-react-native';
// import { BannerAd, BannerAdSize, TestIds } from 'react-native-google-mobile-ads'
//import Circle from 'react-native-web-maps';

const initPaymentSheet = Platform.select({
    web: () => null,
    native: () => require('@stripe/stripe-react-native').initPaymentSheet,
})()

const presentPaymentSheet = Platform.select({
    web: () => null,
    native: () => require('@stripe/stripe-react-native').presentPaymentSheet,
})()


const CircleWeb = Platform.select({
    web: () => require('react-native-web-maps').Circle,
    native: () => null
})();

const BannerAd = Platform.select({
    native: () => require('react-native-google-mobile-ads').BannerAd,
    web: () => null
})();

const BannerAdSize = Platform.select({
    native: () => require('react-native-google-mobile-ads').BannerAdSize,
    web: () => null
})();


const functions = getFunctions(Firebase);
const auth = getAuth(Firebase);

const ProductDetailed = ({ navigation, route }) => {

    const copyToClipboard = async () => {
        try {
            const payload = {
                dynamicLinkInfo: {
                    domainUriPrefix: 'https://menucitos.page.link',
                    link: 'https://menucitos.com/drawer/home/navigation/product?productId=' + productId + '&sellerId=' + sellerId + "&distanceCart=" + distanceCart + "&latitude=" + latitude + "&longitude=" + longitude + "&organization_id=" + organization_id,
                    androidInfo: {
                        androidPackageName: 'com.nelson_arguera.menu',

                    },
                    iosInfo: {
                        iosBundleId: 'com.nelsonarguera.menu',
                        iosAppStoreId: 'id1643431406',
                    },
                    socialMetaTagInfo: {
                        socialTitle: productTitle,
                        socialDescription: productTitle,
                        socialImageLink: productThumbnail,
                    }
                },
                /**ios */
            };

            /**ios dynamic link*/


            let apiKey = "AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
            // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
            const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
            let json;
            let shortLink;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload),
            }).then(res => {
                res.json().then(async (data) => {
                    console.log(data);
                    json = data;
                    const result = await Share.share({
                        message: json.shortLink,
                        url: json.shortLink,
                        title: 'Share via',
                    });
                })
            }).catch(err => { console.log("WHAT", JSON.stringify(err)) });
        } catch (error) {
            console.log(error);
            ToastAndroid.show("Error", ToastAndroid.SHORT);
        }
    };


    const isFocused = useIsFocused();
    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();
    /**route.params */

    const { productId } = route.params;
    const { sellerId } = route.params;
    const { distanceCart } = route.params;
    const { latitude } = route.params;
    const { longitude } = route.params;
    const { organization_id } = route.params;
    console.log("PRODUCT ID", productId, sellerId, distanceCart, latitude, longitude, organization_id);
    const [uid, setUid] = useState();
    const [user, setUser] = useState();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { passwordVisibility, rightIcon, handlePasswordVisibility } = useTogglePasswordVisibility()

    const [customerName, setCustomerName] = useState('');
    const [customerLastName, setCustomerLastName] = useState('');
    const [customerCountryPaymentOptions, setCustomerCountryPaymentOptions] = useState('');
    const [productTitle, setProductTitle] = useState();
    const [productPostedOn, setProductPostedOn] = useState();
    const [product, setProduct] = useState();
    const [productCurrency, setProductCurrency] = useState();
    const [sellerUid, setSellerUid] = useState('');
    const [productPaymentOptions, setProductPaymentOptions] = useState();
    const [productThumbnail, setProductThumbnail] = useState();
    const [productImage, setProductImage] = useState();
    const [productDescription, setProductDescription] = useState();
    const [productDeliveryOptions, setProductDeliveryOptions] = useState();
    const [userLocations, setUserLocations] = useState([]);
    const [distanceProduct, setDistanceProduct] = useState(0);
    const { loading: productLoading, error: productError, data: productData, refetch: refetchProductDetail } = useQuery(GET_PRODUCT_BY_ID_DETAILED, { variables: { productId, languageCode, uid: uid, senderId: uid, recipientId: sellerUid, organizationId: organization_id} });
    const { loading: userStatusLoading, error: userStatusError, data: userStatusData } = useSubscription(GET_USER_STATUS, { variables: { uid: sellerUid } });

    /**create Order */
    const [createOrder, { loading: createOrderLoading, error: createOrderError }] = useMutation(CREATE_ORDER);
    const [createChat, { loading: createChatLoading, data: createChatData, error: createChatError }] = useMutation(CREATE_CHAT_FROM_PRODUCT);
    const [createChatMyself, { loading: createChatMyselfLoading, data: createChatMyselfData, error: createChatMyselfError }] = useMutation(CREATE_CHAT_MYSELF_FROM_PRODUCT);
    const [createOrderCash, { loading: createOrderCashLoading, error: createOrderCashError }] = useMutation(CREATE_ORDER_CASH);
    const [createOrderCashOrganization, { loading: loadingCreateOrderCashOrganization, error: errorCreateOrderCashOrganization, data: dataCreateOrderCashOrganization }] = useMutation(CREATE_ORDER_CASH_ORGANIZATION);
    const [createOrderCardOrganization, { loading: loadingCreateOrderCardOrganization, error: errorCreateOrderCardOrganization, data: dataCreateOrderCardOrganization }] = useMutation(CREATE_ORDER_CARD_ORGANIZATION);
    const [updateProductQuantity, { loading: loadingUpdateProductQuantity, error: errorUpdateProductQuantity, data: dataUpdateProductQuantity }] = useMutation(UPDATE_PRODUCT_QUANTITY)


    const [createOrderCard, { loading: createOrderCardLoading, error: createOrderCardError }] = useMutation(CREATE_ORDER_CARD);

    const [createUserPaymentOptionFromProduct, { loading: createUserPaymentOptionFromProductLoading, error: createUserPaymentOptionFromProductError }] = useMutation(INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT);
    /**add to Cart */

    const [insertNewProductToCart, { loading: insertNewProductToCartLoading, error: insertNewProductToCartError }] = useMutation(INSERT_NEW_PRODUCT_TO_CART);

    // const [productSeller, setProductSeller] = useState();
    const [productSellerName, setProductSellerName] = useState('');
    const [userLocationSelected, setUserLocationSelected] = useState(0);
    const [productSellerLastName, setProductSellerLastName] = useState('');
    const [productSellerThumbnail, setProductSellerThumbnail] = useState();
    const [productQuantity, setProductQuantity] = useState();
    const [productCurrencyId, setProductCurrencyId] = useState();
    const [productPrice, setProductPrice] = useState();
    const [productGallery, setProductGallery] = useState();

    //for product Lat and Long

    const [productLatitude, setProductLatitude] = useState();
    const [productLongitude, setProductLongitude] = useState();
    const [productLatitudeRegion, setProductLatitudeRegion] = useState();
    const [productLongitudeRegion, setProductLongitudeRegion] = useState();
    const [productLongitudeDelta, setProductLongitudeDelta] = useState(0.0922);
    const [productLatitudeDelta, setProductLatitudeDelta] = useState(0.0421);

    //product distance

    const [productDistance, setProductDistance] = useState();

    const [sellerExpoToken, setSellerExpoToken] = useState();
    const [sellerStatus, setSellerStatus] = useState(false);
    /**Form */
    const [selectedCountryDeliveryOption, setSelectedCountryDeliveryOption] = useState(0);
    const [selectedCountryDeliveryOptionPicker, setSelectedCountryDeliveryOptionPicker] = useState(0);
    const [selectedDeliveryOptionId, setSelectedDeliveryOptionId] = useState();
    const [selectedDeliveryOptionName, setSelectedDeliveryOptionName] = useState();
    const [selectedCountryPaymentOption, setSelectedCountryPaymentOption] = useState();
    const [selectedCountryPaymentOptionName, setSelectedCountryPaymentOptionName] = useState();
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [modalVisibility, setModalVisibility] = useState(false);
    const [merchantId, setMerchantId] = useState();
    const [tokenPaypal, setTokenPaypal] = useState();
    // const [sellerUid, setSellerUid] = useState();
    const [sellerEmail, setSellerEmail] = useState();
    const [notes, setNotes] = useState('');
    const [organizationData, setOrganizationData] = useState();
    const [isInOrganization, setIsInOrganization] = useState(false);
    const [organizationId, setOrganizationId] = useState(organization_id);
    const [organizationName, setOrganizationName] = useState();
    const [organizationDistance, setOrganizationDistance] = useState();
    const [organizationThumbnail, setOrganizationThumbnail] = useState();
    const [organizationShowProductCheckout, setOrganizationShowProductCheckout] = useState(false);
    const [deliveryFees, setDeliveryFees] = useState();
    const [sellerDistance, setSellerDistance] = useState();
    const [chatId, setChatId] = useState();
    const [chatMyselfId, setChatMyselfId] = useState();


    const [chatVisibility, setChatVisibility] = useState(false);
    const [modalVisibilityIndicator, setModalVisibilityIndicator] = useState(false);
    const [messages, setMessages] = useState([]);
    const [userChats, setUserChats] = useState([]);
    const [imageVisibility, setImageVisibility] = useState(false);
    const [modalVisibilityMessage, setModalVisibilityMessage] = useState(false);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
    const [modalVisibilityAddToCart, setModalVisibilityAddToCart] = useState(false);
    const [allUsersByOrganizationId, setAllUsersByOrganizationId] = useState([]);
    const [productShowInOrganization, setProductShowInOrganization] = useState(false);
    const [currencyCodeStripeFee, setCurrencyCodeStripeFee] = useState();

    async function getProductData() {
        console.log("productEror: ", productError);
        console.log("userError: ", userStatusError);
        // console.log("sellerId: ", sellerId);

        console.log("productData: ", productData);
        console.log("userStatusData: ", userStatusData);
        if (productData) {

            const { chat } = productData;
            if (Object.keys(chat).length === 0) {
                setChatId(null)
            } else {
                setChatId(chat[0].chat_id);
            }

            const { chat_myself } = productData;
            if (Object.keys(chat_myself).length === 0) {
                setChatMyselfId(null)
            } else {
                setChatMyselfId(chat_myself[0].chat_id);
            }

            const { users_by_pk } = productData;
            /// check if users_by_pk is empty
            if (users_by_pk) {
                const { user_name, user_lastname, user_country_payment_options } = users_by_pk;
                setCustomerName(user_name);
                setCustomerLastName(user_lastname);
                setCustomerCountryPaymentOptions(user_country_payment_options);
            }

            const { user_locations } = productData;
            setUserLocations(user_locations);
            const { products_by_pk } = productData;

            if (products_by_pk) {
                setDistanceProduct(distanceCart);
                setProduct(products_by_pk);
                setProductImage(products_by_pk.product_photo_portrait)
                setProductThumbnail(products_by_pk.product_thumbnail)
                // setProductShowInOrganization(products_by_pk.product_show_in_organization);
                setProductTitle(products_by_pk['product_translations'][0].product_title);
                setProductDescription(products_by_pk['product_descriptions'])
                setProductPaymentOptions(products_by_pk['product_payment_options'])
                setProductSellerName(products_by_pk['user_products'][0]['user'].user_name)
                setProductSellerLastName(products_by_pk['user_products'][0]['user'].user_lastname)
                setProductSellerThumbnail(products_by_pk['user_products'][0]['user'].user_thumbnail)
                setSellerUid(products_by_pk['user_products'][0]['user'].uid);

                navigation.setParams({
                    sellerId: products_by_pk['user_products'][0]['user'].uid,
                })

                setProductPostedOn(products_by_pk.posted_on)
                setOrganizationData(products_by_pk['organization_products']);

                let image = {
                    product_gallery_id: 0,
                    product_gallery_photo: products_by_pk.product_photo_portrait,
                    product_gallery_thumbnail: products_by_pk.product_thumbnail,
                }

                await setProductGallery([image, ...products_by_pk['product_galleries']]);

                if (organizationData && organizationData.length > 0) {
                    setIsInOrganization(true)
                    setOrganizationId(organizationData[0].organization.organization_id)
                    setOrganizationName(organizationData[0].organization.organization_name)
                    setOrganizationThumbnail(organizationData[0].organization.organization_thumbnail)
                    setOrganizationDistance(organizationData[0].organization.organization_distance)
                    setProductLongitude(organizationData[0].organization.organization_location.coordinates[0])
                    setProductLatitude(organizationData[0].organization.organization_location.coordinates[1])
                    setProductLongitudeRegion(organizationData[0].organization.organization_location.coordinates[0])
                    setProductLatitudeRegion(organizationData[0].organization.organization_location.coordinates[1])
                    setProductDistance(organizationData[0].organization.organization_distance)
                    setOrganizationShowProductCheckout(organizationData[0].organization.organization_show_product_checkout)
                    console.log("ORGANIZATION SHOW PRODUCT CHECKOUt: ", organizationData[0].organization.organization_show_product_checkout)
                    // console.log('productLatitude: ', productLatitude)
                    // console.log('productLongitude: ', productLongitude)
                    if (organizationId && productData) {
                        setAllUsersByOrganizationId(organizationData[0].organization['organization_users']);
                    }
                } else {
                    setIsInOrganization(false)
                    setProductLongitude(products_by_pk['user_products'][0]['user'].user_location.coordinates[0])
                    setProductLatitude(products_by_pk['user_products'][0]['user'].user_location.coordinates[1])
                    setProductLongitudeRegion(products_by_pk['user_products'][0]['user'].user_location.coordinates[0])
                    setProductLatitudeRegion(products_by_pk['user_products'][0]['user'].user_location.coordinates[1])
                    setProductDistance(products_by_pk['user_products'][0]['user'].distance)
                    setOrganizationShowProductCheckout(false);
                    // setOrganizationData(null);

                }

                if (userStatusData) {
                    const { users_by_pk } = userStatusData;
                    const { user_active } = users_by_pk;
                    setSellerStatus(user_active);
                    console.log(sellerStatus);
                }

                setSellerExpoToken(products_by_pk['user_products'][0]['user'].user_expo_token)
                setSellerUid(products_by_pk['user_products'][0]['user'].uid)
                // console.log("sellerUid: ", sellerUid);
                setSellerEmail(products_by_pk['user_products'][0]['user'].user_email)
                setSellerDistance(products_by_pk['user_products'][0]['user'].distance)
                console.log("sellerDistance: ", sellerDistance);
                setProductDeliveryOptions(products_by_pk['product_delivery_options'])
                setProductQuantity(products_by_pk.product_quantity)
                setProductCurrencyId(products_by_pk['product_currencies'][0].product_currency_id)
                setProductPrice(products_by_pk['product_currencies'][0].product_price)
                setProductCurrency(products_by_pk['product_currencies'][0].currency_code)
                setCurrencyCodeStripeFee(products_by_pk['product_currencies'][0].currency.currency_code_stripe_fee)
                console.log("currencyCodeStripeFee: ", currencyCodeStripeFee);
            }
        }
    }

    useEffect(() => {


        // const unsubscribe = navigation.addListener('focus', () => {

        //     refetchProductDetail();
        // });

        getAuth().onAuthStateChanged((user) => {
            if (user) {
                setUid(user.uid);
                setUser(user);
                // console.log("user: ", user);
                getProductData();
            } else {
                // setUid("");
                // setUser("");
                getProductData();
            }
        });
    }, [productLoading, userStatusLoading, organizationData, productData, sellerStatus, userStatusData]);

    const FooterDelivery = () => {
        return (
            <View style={{ paddingBottom: 5 }}>
                <View>
                    {(selectedDeliveryOptionId != 0) ? (
                        <>
                            <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 5, paddingHorizontal: 9, fontFamily: 'Inter-Medium' }}>{selectedDeliveryOptionName}</Text>
                        </>
                    ) : null}
                    {(selectedDeliveryOptionId === 4) ? (
                        <View>
                            <Text style={{ fontSize: 16, paddingHorizontal: 9 }}>
                                {i18n.t('home.productDetailed.delivery_menucitos_motorcycle')}
                            </Text>
                            <View style={{ flexDirection: 'row', paddingHorizontal: 9 }}>
                                <Text style={{ fontSize: 16, fontFamily: 'Inter-Medium' }}>
                                    {i18n.t('home.productDetailed.delivery_cost')}
                                </Text>
                                {(isInOrganization) ? (
                                    <Text style={{ fontSize: 16, paddingHorizontal: 7, fontWeight: 'bold', }}>
                                        {/* fix km to 2 decimals */}
                                        {(((organizationDistance / 1000).toFixed(2)) * deliveryFees).toFixed(2)} {productCurrency}
                                    </Text>

                                ) : (
                                    <Text style={{ fontSize: 16, paddingHorizontal: 7, fontWeight: 'bold', }}>
                                        {/* fix km to 2 decimals */}
                                        {(((sellerDistance / 1000).toFixed(2)) * deliveryFees).toFixed(2)} {productCurrency}
                                    </Text>
                                )}
                                {/* {(!isInOrganization)? (
                                    
                                ):null} */}
                            </View>
                        </View>
                    ) : null}
                </View>
                <View>
                    <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 5, paddingHorizontal: 9, fontWeight: 'bold' }}>{i18n.t('home.productDetailed.quantity')}</Text>
                    {/* <Text style={{fontSize:20, paddingHorizontal:9}}>Select how many items will you purchase</Text> */}
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 50, paddingTop: 15, justifyContent: 'center' }}>
                        <TouchableOpacity style={{ width: 28, height: 28, borderRadius: 100, backgroundColor: '#FF6D00', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() => {
                                if (selectedQuantity > 1) {
                                    setSelectedQuantity(selectedQuantity - 1)
                                } else {
                                    setSelectedQuantity(1)
                                }
                            }}>
                            <Feather name="minus" size={20} color="white" />
                        </TouchableOpacity>
                        <Text style={{ backgroundColor: '#e0e0e0', paddingVertical: 5, fontSize: 18, borderRadius: 6, flexGrow: 1, marginHorizontal: 10, textAlign: 'center', maxWidth: 450 }}>{selectedQuantity}</Text>
                        <TouchableOpacity style={{ width: 28, height: 28, borderRadius: 100, backgroundColor: '#FF6D00', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                            onPress={() => {
                                if (selectedQuantity < productQuantity) {
                                    setSelectedQuantity(selectedQuantity + 1)
                                } else {
                                    setSelectedQuantity(productQuantity)
                                }
                            }}>
                            <Feather name="plus" size={20} color="white" />
                        </TouchableOpacity>

                    </View>
                </View>
            </View>
        )
    }

    const ItemDeliveryOptions = ({ item, deliveryOptionText }) => (
        <View style={{ paddingBottom: 5, paddingHorizontal: 9 }}>
        </View>
    )

    const renderDeliveryOptions = ({ item }) => {
        return (
            <ItemDeliveryOptions item={item}
                deliveryOptionText={item['country_delivery_option']['delivery_option']['delivery_option_translations'][0]} />
        )
    }

    function FooterDescription() {
        return (
            <View style={{ paddingBottom: 5 }}>
                <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 1, paddingHorizontal: 9, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.delivery_options')}</Text>
                <View style={{ backgroundColor: '#e0e0e0', margin: 10, borderRadius: 6 }}>
                    <Picker selectedValue={selectedCountryDeliveryOptionPicker}
                        style={{ paddingVertical: Platform.OS === 'web' ? 10 : 0 }}
                        onValueChange={(itemValue, itemIndex) => {
                            if (itemValue != 0) {
                                setSelectedCountryDeliveryOptionPicker(itemValue);
                                //String to JSON
                                itemValue = JSON.parse(itemValue);
                                console.log("Value Index: ", itemIndex)
                                //print object as string
                                console.log("Value: " + itemValue['country_delivery_option_id'])
                                setSelectedCountryDeliveryOption(itemValue['country_delivery_option_id']);
                                setSelectedDeliveryOptionId(itemValue['country_delivery_option']['delivery_option_id']);
                                console.log(itemValue)
                                setSelectedDeliveryOptionName(itemValue['country_delivery_option']['delivery_option']['delivery_option_translations'][0]['delivery_option_name']);
                                setDeliveryFees(itemValue['country_delivery_option']['country_delivery_options_fees'].length > 0 ? itemValue['country_delivery_option']['country_delivery_options_fees'][0]['country_delivery_options_fees'] : 0)
                            } else {
                                setSelectedCountryDeliveryOptionPicker(itemValue);
                                setSelectedDeliveryOptionId(0);
                                console.log("Value: " + 0)
                            }
                        }}
                    >
                        <Picker.Item label={i18n.t('home.productDetailed.picker_delivery')} value={0} />
                        {(productDeliveryOptions) ? productDeliveryOptions.map((item, index) => {
                            return (
                                <Picker.Item label={item['country_delivery_option']['delivery_option']['delivery_option_translations'][0]['delivery_option_name']} value={JSON.stringify(item)} key={index} />
                            )
                            // console.log(item)
                        }) : null}
                    </Picker>
                </View>
                <FlatList listKey='deliveryOptions'
                    data={productDeliveryOptions}
                    // renderItem={renderDeliveryOptions} 
                    ListFooterComponent={FooterDelivery} keyExtractor={item => item.country_delivery_option_id.toString()}></FlatList>
            </View>
        )
    }

    const screenWidth = useWindowDimensions().width;
    const scrollX = useRef(new Animated.Value(0)).current;

    const itemGallery = ({ item }) => {
        return (
            <TouchableWithoutFeedback onPress={() => {
                // setImageVisibility(true)
                setProductImage(item.product_gallery_photo)
            }}>
                <View style={{ width: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35 }}>
                    <Image source={item.product_gallery_photo ? { uri: item.product_gallery_photo } : grayPlaceholder} style={{ width: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35, height: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35, borderBottomLeftRadius: 16, borderBottomRightRadius: 16 }}>
                    </Image>
                </View>
            </TouchableWithoutFeedback>
        )
    }

    const itemGalleryWeb = ({ item }) => {
        return (
            <TouchableWithoutFeedback style={{ width: 150, height: 150, marginHorizontal: 1 }} onPress={() => {
                // setImageVisibility(true)
                setProductImage(item.product_gallery_photo)
            }}>
                <Image source={item.product_gallery_photo ? { uri: item.product_gallery_photo } : grayPlaceholder} style={{ width: 150, height: 150, marginHorizontal: 2, borderRadius: 10, borderWidth: 1, borderColor: '#000' }}>
                </Image>
            </TouchableWithoutFeedback>
        )
    }

    const header = () => {
        let flexDirection;
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
            flexDirection = 'column';
        } else if (Platform.OS === 'web') {
            flexDirection = 'row';
        }
        return (
            <View style={{ flexDirection: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? 'column' : 'row' }}>
                <View>
                    <TouchableWithoutFeedback onPress={() => {
                        setImageVisibility(true)
                    }}>
                        <View style={{ width: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35 }}>
                            {(Platform.OS === 'web') ? (
                                <Image source={productImage ? { uri: productImage } : grayPlaceholder} style={{ width: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35, height: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35, borderRadius: 10, borderWidth: 1, borderColor: '#000' }}>
                                </Image>
                            ) : null}
                        </View>

                        {/* <FastImage source={{uri:productImage? productImage: undefined}} style={{width: screenWidth, height: screenWidth, borderRadius:18}}/> */}
                    </TouchableWithoutFeedback>

                    {/* productGallery on Web */}

                    {(productGallery) ? (
                        <View>
                            {(Platform.OS === 'ios' || Platform.OS === 'android') ? (
                                <View>
                                    <FlatList listKey='productImages'
                                        data={productGallery}
                                        horizontal={true}
                                        renderItem={itemGallery}
                                        showsHorizontalScrollIndicator={false}
                                        onScroll={Animated.event(
                                            [{ nativeEvent: { contentOffset: { x: scrollX } } }],
                                            { useNativeDriver: false }
                                        )}
                                        decelerationRate={'normal'}
                                        scrollEventThrottle={16}
                                        keyExtractor={item => item.product_gallery_id.toString()}
                                    />

                                    <ExpandingDot
                                        data={productGallery}
                                        scrollX={scrollX}
                                        expandingDotWidth={30}
                                        inActiveDotOpacity={0.6}
                                        activeDotColor={'#FFFFFF'}
                                        inActiveDotColor={'#FFFFFF'}
                                        dotStyle={{
                                            width: 10,
                                            height: 10,
                                            backgroundColor: '#000000',
                                            borderRadius: 5,
                                            marginHorizontal: 5,
                                        }}
                                        containerStyle={{
                                            top: 30,
                                        }}
                                    />
                                </View>
                            ) : (
                                <View style={{ width: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.35, height: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 175 ? screenWidth : 175, borderRadius: 10, paddingTop: 10 }}>
                                    <FlatList listKey='productImages'
                                        data={productGallery}
                                        horizontal={true}
                                        renderItem={itemGalleryWeb}
                                        showsHorizontalScrollIndicator={true}
                                    />
                                </View>
                            )}
                        </View>
                    ) : null}

                </View>
                <View style={{ flexDirection: 'column', width: Platform.OS === 'ios' || Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth * 0.65 }}>
                    {(sellerId === uid) ? (
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('MyProductDetailed', {
                                productId: { product_id: productId },
                            })
                        }}
                            style={{ borderColor: '#FF6D00', borderWidth: 1, borderRadius: 3, backgroundColor: '#FF6D00', marginHorizontal: 6, elevation: 20, marginTop: 10, paddingVertical: 10, flexDirection: 'row' }}>
                            <Text style={{ color: "#FFFFFF", fontWeight: 'bold', flexGrow: 1, paddingHorizontal: 9 }}>
                                {i18n.t('home.productDetailed.edit_product_post')}
                            </Text>
                            <Feather name="edit" size={20} color="white" style={{ marginRight: 25 }} />
                        </TouchableOpacity>
                    ) : null}
                    {(!uid) ? (
                        <TouchableOpacity onPress={() => {
                            navigation.navigate('Login')
                        }}
                            style={{ borderColor: '#FFE082', borderWidth: 1, borderRadius: 3, backgroundColor: '#FFE082', marginHorizontal: 6, elevation: 20, marginTop: 10, paddingVertical: 10, flexDirection: 'row' }}>
                            <Text style={{ color: "#000000", fontFamily:'Inter-Medium', flexGrow: 1, paddingHorizontal: 9, letterSpacing:-0.3, fontSize:18}}>
                                {i18n.t('home.login_for_better_experience')}
                            </Text>
                            <Feather name="log-in" size={20} color="black" style={{ marginRight: 25 }} />
                        </TouchableOpacity>
                        
                    ):null}

                    <View style={{ borderColor: '#CFD8DC', borderWidth: 1, borderRadius: 6, marginVertical: 5, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20 }}>
                        <Text style={{ fontSize: 32, paddingHorizontal: 9, marginVertical: 5, fontFamily: 'Inter-Black' }}>{productTitle}</Text>
                    </View>
                    {(Platform.OS === 'web') ? (
                        <>
                        <AdSense.Google 
                        client='ca-pub-4925893888940052'
                        slot='1038711627'
                        style={{
                            width: screenWidth < 500 ? screenWidth : screenWidth * 0.65,
                            height: 100,
                        }}
                        format=''
                        >
                        </AdSense.Google>
                        </>
                    ):null}
                    {(isInOrganization) ? (
                        <TouchableOpacity onPress={() => {
                            // console.log("productOrganization")
                            navigation.push('OrganizationPage', {
                                latitude,
                                longitude,
                                organizationId,
                                distance: distanceCart,
                            });
                        }}>
                            <View style={{ borderColor: '#CFD8DC', borderWidth: 1, flexDirection: 'row', alignItems: 'center', alignContent: 'center', borderRadius: 6, marginBottom: 5, paddingVertical: 15, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20, paddingHorizontal: 9 }}>
                                <Image source={organizationThumbnail ? { uri: organizationThumbnail } : require('../assets/gray-image-placeholder.png')} style={{ width: 55, height: 55, borderRadius: 55 }} />
                                <Text style={{ fontSize: 18, paddingHorizontal: 9, fontWeight: 'bold', color: '#FF6D00', flexShrink: 1, letterSpacing: 0.5 }}>{i18n.t('home.productDetailed.is_in_organization')} {organizationName}</Text>
                                {/* <Feather name="check-circle" size={20} color="#FF6D00" style={{right:10, top:10}}/> */}
                            </View>
                        </TouchableOpacity>
                        /**060422 */
                    ) : null}
                    <View style={{ paddingVertical: 5, borderColor: '#EEEEEE', borderWidth: 1, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20 }}>
                        <Text style={{ textAlign: 'left', paddingLeft: 9, fontSize: 18, marginVertical: 10, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.seller')}</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 9 }}>
                            <TouchableOpacity style={{ flexDirection: 'row', flexGrow: 1 }} onPress={() => {
                                navigation.navigate('PublicProfile', {
                                    user_id: sellerUid,
                                })
                            }}>
                                <Image source={productSellerThumbnail ? { uri: productSellerThumbnail } : require('../assets/gray-image-placeholder.png')} style={{ width: 55, height: 55, borderRadius: 55 / 2 }} />
                                <View style={{ flexDirection: 'column' }}>
                                    <Text style={{ textAlign: 'left', fontSize: 18, marginVertical: 10, fontFamily: 'Inter-Black', color: '#FF6D00' }}> {productSellerName + " " + productSellerLastName}</Text>
                                    <Text style={{ textAlign: 'left', paddingLeft: 5, marginLeft: 1, fontFamily: "Inter-Medium", color: "gray" }}>{i18n.t('home.productDetailed.posted_on', {
                                        days_ago: productPostedOn,
                                    })}</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ width: 55, height: 28, marginRight: 25, borderRadius: 6, backgroundColor: '#FF6D00', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                                onPress={() => {
                                    const sellerId = sellerUid;
                                    if (sellerId === uid) {
                                        //chat myself

                                        if (chatMyselfId === null) {
                                            createChatMyself({
                                                variables: {
                                                    senderId: uid,
                                                }
                                            }).then(res => {
                                                // console.log(res);
                                                const chatMyselfId = res.data.insert_chat_one.chat_id;
                                                const sellerId = sellerUid;
                                                navigation.navigate('Messages', {
                                                    sellerId,
                                                    chatId: chatMyselfId,
                                                    expoToken: sellerExpoToken,
                                                })
                                            }).catch(err => {
                                                console.log(err);
                                            })


                                            console.log("chatMyselfId is null")
                                        } else {
                                            navigation.navigate('Messages', {
                                                sellerId,
                                                chatId: chatMyselfId,
                                                expoToken: sellerExpoToken,
                                            })
                                        }

                                    } else {
                                        if (chatId === null) {
                                            createChat({
                                                variables: {
                                                    senderId: uid,
                                                    recipientId: sellerId,
                                                }
                                            }).then(res => {
                                                // console.log(res);
                                                const chatSellerId = res.data.insert_chat_one.chat_id;
                                                const sellerId = sellerUid;
                                                navigation.navigate('Messages', {
                                                    sellerId,
                                                    chatId: chatSellerId,
                                                    expoToken: sellerExpoToken,
                                                })
                                                refetchChatData();
                                            }).catch(err => {
                                                // console.log(err);
                                            })
                                        } else {
                                            navigation.navigate('Messages', {
                                                sellerId,
                                                chatId,
                                                expoToken: sellerExpoToken,
                                            })
                                        }
                                    }

                                }}>
                                <Feather name="message-square" size={20} color="white" />

                            </TouchableOpacity>

                        </View>

                    </View>
                    {(sellerStatus) ? (
                        <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center', borderColor: '#81C784', borderWidth: 1, borderRadius: 6, backgroundColor: '#81C784', marginHorizontal: 6, elevation: 20, marginTop: 5, justifyContent: 'center' }}>
                            <Text style={{ textAlign: 'center', paddingLeft: 9, fontSize: 12, marginVertical: 1, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.seller_online')}</Text>
                        </View>
                    ) : (
                        <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center', borderColor: '#e0e0e0', borderWidth: 1, borderRadius: 6, backgroundColor: '#e0e0e0', marginHorizontal: 6, elevation: 20, marginTop: 5, justifyContent: 'center' }}>
                            <Text style={{ textAlign: 'center', paddingLeft: 9, fontSize: 12, marginVertical: 1, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.seller_offline')}</Text>
                        </View>
                    )}
                    <View style={{ borderWidth: 1, flexDirection: 'row', borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20 }}>
                        <Text style={{ fontSize: 18, paddingLeft: 9, marginVertical: 10, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.product_distance')}</Text>
                        <Text style={{ fontSize: 18, paddingLeft: 5, marginVertical: 10, fontFamily: 'Inter-Medium', fontWeight: '400' }}>
                            {/* convert to km */}
                            {/* {(productDistance / 1000).toFixed(2)} km */}
                            {isInOrganization ? (organizationDistance / 1000).toFixed(2) + ' km' : (sellerDistance / 1000).toFixed(2) + ' km'}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center', borderColor: '#EEEEEE', borderWidth: 1, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20, marginTop: 5 }}>
                        {(Platform.OS === 'ios' || Platform.OS === 'android') ? (
                            <MapView provider={PROVIDER_GOOGLE}
                                style={{ flex: 1, height: 175, borderRadius: 16, paddingHorizontal: 15, marginTop: 5 }}
                                mapType="standard"
                                showsUserLocation={true}
                                region={{
                                    latitude: productLatitudeRegion,
                                    longitude: productLongitudeRegion,
                                    latitudeDelta: productLatitudeDelta,
                                    longitudeDelta: productLongitudeDelta,
                                }}
                                onRegionChangeComplete={region => {
                                    setProductLatitudeRegion(region.latitude);
                                    setProductLongitudeRegion(region.longitude);
                                    setProductLatitudeDelta(region.latitudeDelta);
                                    setProductLongitudeDelta(region.longitudeDelta);
                                }}
                            >
                                <Circle
                                    center={{
                                        latitude: productLatitude,
                                        longitude: productLongitude,

                                    }}
                                    radius={1000}
                                    fillColor={'rgba(255, 109, 0, 0.2)'}
                                >

                                </Circle>
                            </MapView>
                        ) : null}
                        {(Platform.OS === 'web') ? (
                            <MapView style={{ flex: 1, height: 175, borderRadius: 16, paddingHorizontal: 15, marginTop: 5 }}
                                region={{
                                    latitude: productLatitudeRegion,
                                    longitude: productLongitudeRegion,
                                    latitudeDelta: productLatitudeDelta,
                                    longitudeDelta: productLongitudeDelta,
                                }}
                                onRegionChangeComplete={region => {
                                    setProductLatitudeRegion(region.latitude);
                                    setProductLongitudeRegion(region.longitude);
                                    setProductLatitudeDelta(region.latitudeDelta);
                                    setProductLongitudeDelta(region.longitudeDelta);
                                }}
                            >

                            </MapView>

                        ) : null}

                    </View>
                    <View style={{ flexDirection: 'row', paddingVertical: 5, alignItems: 'center', borderColor: '#EEEEEE', borderWidth: 1, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20, marginTop: 5 }}>
                        <Text style={{ textAlign: 'right', paddingLeft: 9, fontSize: 18, marginVertical: 10, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.copy_product_link')}</Text>
                        <Text style={{ textAlign: 'right', flexGrow: 1, fontSize: 18, marginVertical: 10, fontWeight: "bold", color: '#FF6D00' }}></Text>
                        <TouchableOpacity style={{ width: 55, height: 28, marginRight: 25, borderRadius: 6, backgroundColor: '#FF6D00', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}
                            onPress={copyToClipboard}>
                            <Feather name="link-2" size={20} color="white" />

                        </TouchableOpacity>

                    </View>
                    {(merchantId) ? (
                        <View style={{ flexDirection: 'row', flexGrow: 1, marginTop: 5, justifyContent: 'space-between', alignItems: 'center', borderColor: '#78909C', borderWidth: 1, borderRadius: 6 }}>
                            <Text style={{ textAlign: 'right', paddingLeft: 9, fontSize: 20, marginVertical: 20, color: '#1e76e8' }}>{i18n.t('home.productDetailed.merchant_connected')}</Text>
                        </View>
                    ) : null}
                    <View style={{ borderWidth: 1, flexDirection: 'row', borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20 }}>
                        <Text style={{ fontSize: 18, paddingLeft: 9, marginVertical: 10, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.in_stock')}</Text>
                        <Text style={{ fontSize: 18, paddingLeft: 5, marginVertical: 10, fontFamily: 'Inter-Medium', fontWeight: '400' }}>{productQuantity} {i18n.t('home.productDetailed.items')}</Text>
                    </View>
                    <View style={{ borderWidth: 1, flexDirection: 'row', borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20 }}>
                        <Text style={{ fontSize: 18, paddingTop: 5, marginVertical: 5, paddingLeft: 9, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.price')}</Text>
                        <Text style={{ fontSize: 18, paddingTop: 5, marginVertical: 5, paddingLeft: 9, fontFamily: 'Inter-Medium', fontWeight: '400' }}>{((productPrice * 100) / 100).toFixed(2) + ' ' + productCurrency}</Text>
                    </View>
                    {(Platform.OS === 'web') ? (
                        <>
                        <AdSense.Google 
                        client='ca-pub-4925893888940052'
                        slot='7299586388'
                        style={{
                            width: screenWidth < 500 ? screenWidth : screenWidth * 0.65,
                            height: 100,
                        }}
                        format=''
                        >

                        </AdSense.Google>
                        </>
                    ):null}
                    <View>
                        {(Platform.OS === 'ios' || Platform.OS === 'android') ? (
                            <BannerAd
                                unitId={Platform.OS === 'android' ? 'ca-app-pub-4925893888940052/9415264244' : 'ca-app-pub-4925893888940052/7993494991'}
                                size={BannerAdSize.FULL_BANNER}
                                requestOptions={{
                                    requestNonPersonalizedAdsOnly: true,
                                }}
                                onAdLoaded={() => {
                                    console.log('Advert loaded');
                                }
                                }
                                onAdFailedToLoad={error => {
                                    console.error('Advert failed to load: ', error);
                                }}
                            />
                        ) : null}
                    </View>
                    <View style={{ borderColor: '#EEEEEE', borderWidth: 1, borderRadius: 6, marginTop: 5, backgroundColor: "#EEEEEE", marginHorizontal: 6, elevation: 20 }}>
                        <Text style={{ fontSize: 18, paddingTop: 5, paddingHorizontal: 10, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.characteristics')}</Text>
                        <FlatList listKey='productCharacteristics'
                            data={productDescription} ListFooterComponent={FooterDescription()} ListFooterComponentStyle={{ paddingTop: 15 }} contentContainerStyle={{ paddingBottom: 20 }} renderItem={renderItem} keyExtractor={item => item.product_description_id.toString()} />
                    </View>
                    {deliveryLocation()}
                </View>
            </View>
        )
    }

    const ItemDescription = ({ item, description, index }) => (
        <View style={{ paddingHorizontal: 10, }}>
            <Text style={{ fontSize: 18, paddingTop: 5, fontFamily: "Inter-Regular" }}>{index + 1 + ". " + description}</Text>
        </View>
    )

    const renderItem = ({ item, index }) => {
        return (
            <ItemDescription item={item}
                description={item.product_description} index={index} />

        )
    }

    const ItemPayment = ({ item, paymentType, operation, onPress, payment_option_id }) => (
        <View style={{ paddingBottom: 5 }}>
            <TouchableOpacity style={{ alignItems: 'center', alignContent: 'center', marginHorizontal: 10, borderRadius: 6, backgroundColor: '#FF6D00' }}
                onPress={onPress}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontSize: 20, paddingVertical: 10, paddingHorizontal: 9, color: 'white', fontFamily: 'Inter-Regular', letterSpacing: -0.5 }}>{i18n.t('home.productDetailed.pay_with')} {paymentType[0].payment_option_name}</Text>
                    <Feather name="lock" size={20} color="white" />
                </View>
            </TouchableOpacity>
            {payment_option_id === 3 ? (
                <>
                    <Text style={{ paddingHorizontal: 9 }}>
                        {i18n.t("home.productDetailed.stripe_payment_fee", {
                            currency_code_stripe_fee: currencyCodeStripeFee / 100,
                            currency_code: productCurrency,
                        })}
                    </Text>
                </>
            ) : null}
        </View>
    );

    const getPaymentSheetParams = async (customerId) => {
        const savePaymentMethodSetupIntent = httpsCallable(functions, 'savePaymentMethodSetupIntent');

        let stripeCustomerId = customerId;

        let ephemeralKeySecret;
        let setupIntent;

        const { data } = await savePaymentMethodSetupIntent({
            userStripeCustomerId: stripeCustomerId
        });

        console.log("DATA:", data)

        ephemeralKeySecret = data.ephemerelKey.secret;
        setupIntent = data.setupIntent.client_secret;

        return {
            ephemeralKeySecret,
            setupIntent,
            customer: stripeCustomerId
        }
    }


    const renderPayments = ({ item, index }) => {

        // console.log(item['user_country_payment_option'].country_payment_options_id)
        // console.log("PAYMENT TYPE:", 'payment_option_id', item['user_country_payment_option'].country_payment_option.payment_option.payment_options_id)
        let onPress;
        if (item['user_country_payment_option'].country_payment_option.payment_option.payment_options_id === 1) {
            onPress = () => {

                if (productQuantity > 0) {
                    // console.log("HEY")
                    if (selectedCountryDeliveryOption != 0) {
                        if (userLocationSelected != 0) {
                            // setSelectedCountryDeliveryOption(item['user_country_payment_option'].country_payment_options_id);
                            setSelectedCountryPaymentOption(item['user_country_payment_option'].country_payment_options_id);
                            setSelectedCountryPaymentOptionName(item['user_country_payment_option']['country_payment_option']['payment_option']['payment_option_translations'][0]['payment_option_name']);
                            if (Platform.OS === 'web') {
                                setMessageTitle(i18n.t('home.productDetailed.alert_cash_alert'))
                                setMessageBody(i18n.t('home.productDetailed.alert_cash_alert_body'))
                                setModalVisibilityMessage(true);
                                // alert(i18n.t('home.productDetailed.alert_cash_alert'))
                            }

                            Alert.alert(i18n.t('home.productDetailed.alert_cash_alert'), i18n.t('home.productDetailed.alert_cash_alert_body'), [{
                                text: "Cancel",
                                onPress: () => {
                                    // console.log("Cancel Pressed");
                                }
                            }, {
                                text: "OK",
                                onPress: () => {
                                    let newProductQuantity = productQuantity - selectedQuantity
                                    let transactionTotal = (selectedQuantity * productPrice)

                                    let realTimeTransaction = false

                                    let countryDeliveryOptionName;

                                    if (selectedCountryDeliveryOption == 1) {
                                        if (languageCode == 'EN') {
                                            countryDeliveryOptionName = 'Real Time Delivery'
                                        } else if (languageCode == 'ES') {
                                            countryDeliveryOptionName = 'Entrega en tiempo real'
                                        }
                                    }

                                    if (selectedCountryDeliveryOption === 1) {
                                        realTimeTransaction = true
                                    }

                                    if (isInOrganization) {
                                        let products = []

                                        products.push({
                                            product_id: productId,
                                            product_quantity: selectedQuantity,
                                            product_currency_id: productCurrencyId,
                                            product_price: productPrice,
                                        })

                                        let orderProducts = []

                                        orderProducts.push({
                                            product_id: productId,
                                            product_quantity: selectedQuantity,
                                            product_currency_id: productCurrencyId,
                                            product_price: productPrice,
                                        })

                                        let updateProducts = []

                                        updateProducts.push({
                                            product_id: productId,
                                            product_quantity: productQuantity - selectedQuantity,
                                            product_quantity_before: productQuantity,
                                            product_currency_id: productCurrencyId,
                                            product_price: productPrice,
                                        })

                                        let newProducts = [];

                                        newProducts.push({
                                            product_id: productId,
                                            product_quantity: productQuantity - selectedQuantity,
                                            product_currency_id: productCurrencyId,
                                            product_price: productPrice,
                                        })

                                        let sellers = [];

                                        allUsersByOrganizationId.map(user => {
                                            sellers.push({
                                                user_id: user.organization_user_id,
                                            })
                                        })

                                        let transactions = [];

                                        products.map(item => {
                                            transactions.push({
                                                product_currency_id: item.product_currency_id,
                                                transaction_total: item.product_price * item.product_quantity,
                                                transaction_status_id: 3
                                            })
                                        });
                                        let orderDeliveryFees = ((organizationDistance / 1000).toFixed(2)) * deliveryFees;
                                        console.log({
                                            customerUid: uid,
                                            countryDeliveryOptionId: selectedCountryDeliveryOption,
                                            countryPaymentOptionId: item['user_country_payment_option'].country_payment_options_id,
                                            userLocationId: userLocationSelected,
                                            realtime: realTimeTransaction,
                                            note: notes,
                                            orderProducts: orderProducts,
                                            sellersUid: sellers,
                                            transactionProducts: transactions,
                                            languageCode: languageCode,
                                            orderDeliveryFees: orderDeliveryFees,
                                        })

                                        createOrderCashOrganization({
                                            variables: {
                                                customerUid: uid,
                                                countryDeliveryOptionId: selectedCountryDeliveryOption,
                                                countryPaymentOptionId: item['user_country_payment_option'].country_payment_options_id,
                                                userLocationId: userLocationSelected,
                                                realtime: realTimeTransaction,
                                                note: notes,
                                                orderProducts: orderProducts,
                                                sellersUid: sellers,
                                                transactionProducts: transactions,
                                                languageCode: languageCode,
                                                organizationId: organizationId === 0 ? null : organizationId,
                                                orderDeliveryFees: orderDeliveryFees,
                                            }
                                        }).then(res => {
                                            newProducts.map(item => {
                                                // console.log(item)
                                                updateProductQuantity({ variables: { product_id: item.product_id, productQuantity: item.product_quantity } }).then(() => {
                                                    console.log("updated")
                                                }).catch((err) => {
                                                    console.log("ERROR ", err)
                                                })
                                            });

                                            //  let workers = res.data.user_orders;

                                            const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');

                                            res.data.insert_orders_one.user_orders.map(item => {
                                                sendExpoPushNotification({
                                                    token: item.user.user_expo_token,
                                                    title: i18n.t('home.productDetailed.new_order_notification'),
                                                    body: i18n.t('home.productDetailed.new_order_notification_body'),
                                                }).then(res => {
                                                    console.log("RESPONSE NOTIFICATION SEND", res)
                                                }).catch(err => {
                                                    console.log("ERROR", err)
                                                }).finally(() => {
                                                    console.log("FINALLY")
                                                });
                                            });

                                            const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');


                                            res.data.insert_orders_one.user_orders.map(item => {
                                                singleOrderSellerEmail({
                                                    order_id: res.data.insert_orders_one.order_id,
                                                    client_name: res.data.insert_orders_one.user.user_name,
                                                    client_email: res.data.insert_orders_one.user.user_email,
                                                    seller_email: item.user.user_email,
                                                    delivery_option: res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_translations[0].delivery_option_name,
                                                    payment_option: res.data.insert_orders_one.country_payment_option.payment_option.payment_option_translations[0].payment_option_name,
                                                    total: productPrice * selectedQuantity,
                                                    currency_code: productCurrency,
                                                    languageCode: languageCode,
                                                })
                                            });

                                            let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id;

                                            if (deliveryOptionId === 4) {
                                                console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                                                res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                                    sendExpoPushNotification({
                                                        token: item.user.user_expo_token,
                                                        title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                        body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                    }).catch(err => {
                                                        console.log("ERROR", err)
                                                    }).then(res => {
                                                        console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                                    }).finally(() => { });
                                                })
                                            }

                                            Alert.alert(i18n.t('home.organizationPage.order_alert_success', 'home.organizationPage.order_alert_success_body'))
                                            if (Platform.OS === 'web') {
                                                setMessageTitle(i18n.t('home.organizationPage.order_alert_success'))
                                                setMessageBody(i18n.t('home.organizationPage.order_alert_success_body'))
                                                setModalVisibilityAlert(true)
                                            }
                                            refetchProductDetail();
                                        }).catch(err => {
                                            console.log("ERROR ", err)
                                        })

                                    } else {
                                        let orderDeliveryFees = ((sellerDistance / 1000).toFixed(2)) * deliveryFees;

                                        createOrderCash({
                                            variables: {
                                                customerUid: uid,
                                                countryPaymentOptionId: item['user_country_payment_option'].country_payment_options_id,
                                                countryDeliveryOptionId: selectedCountryDeliveryOption,
                                                productId: productId,
                                                productQuantity: selectedQuantity,
                                                productCurrencyId: productCurrencyId,
                                                productPrice: productPrice,
                                                userLocationId: userLocationSelected,
                                                transactionTotal: transactionTotal,
                                                realtime: realTimeTransaction,
                                                sellerUid: sellerUid,
                                                notes: notes,
                                                newProductQuantity: newProductQuantity,
                                                orderDeliveryFees: selectedDeliveryOptionId === 4 ? 0 : orderDeliveryFees,
                                            }
                                        }).catch(err => {
                                            console.log("ERROR MUTATION", err)
                                        }).then(res => {

                                            Alert.alert(i18n.t('home.productDetailed.thank_you_paypal'), i18n.t('home.productDetailed.thank_you_paypal_body'))

                                            /**send notification */

                                            const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');

                                            sendExpoPushNotification({
                                                token: sellerExpoToken,
                                                title: i18n.t('home.productDetailed.new_order_notification'),
                                                body: i18n.t('home.productDetailed.new_order_notification_body'),
                                            }).then(res => {
                                                // console.log("RESPONSE NOTIFICATION SEND", res)
                                            }).catch(err => {
                                                // console.log("ERROR", err)
                                            }).finally(() => {
                                                // console.log("FINALLY")
                                            });
                                            /**send email notification to seller*/

                                            const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');
                                            console.log("RESPONSE RES: ", res);

                                            let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id;

                                            if (deliveryOptionId === 4) {
                                                console.log("DATAAAAAAAAAA:")
                                                console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                                                res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                                    sendExpoPushNotification({
                                                        token: item.user.user_expo_token,
                                                        title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                        body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                    }).catch(err => {
                                                        console.log("ERROR", err)
                                                    }).then(res => {
                                                        console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                                    }).finally(() => { });

                                                })
                                            }

                                            let orderId = res.data.insert_orders_one.order_id

                                            let paymentType = selectedCountryPaymentOptionName

                                            console.log("ORDER ID: ", res.data.insert_orders_one.order_id)

                                            singleOrderSellerEmail({
                                                order_id: orderId,
                                                client_name: customerName,
                                                seller_email: sellerEmail,
                                                delivery_option: countryDeliveryOptionName,
                                                payment_option: paymentType,
                                                total: transactionTotal,
                                                currency_code: productCurrency,
                                                languageCode: languageCode,
                                            }).then(res => {

                                                console.log("RESPONSE EMAIL SELLER", res)
                                            }).catch(err => {
                                                console.log("ERROR EMAIL SELLER", err)
                                            }).finally(() => { });
                                        })
                                    }
                                },
                            }
                            ])
                        } else {

                            Alert.alert(i18n.t('home.productDetailed.delivery_location_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))
                            if (Platform.OS === 'web') {
                                setMessageTitle(i18n.t('home.productDetailed.delivery_location_alert'))
                                setMessageBody(i18n.t('home.productDetailed.delivery_location_alert_body'))
                                setModalVisibilityAlert(true);
                            }
                        }
                    } else {
                        Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))
                        if (Platform.OS === 'web') {
                            setMessageTitle(i18n.t('home.productDetailed.delivery_alert'))
                            setMessageBody(i18n.t('home.productDetailed.delivery_alert_body'))
                            setModalVisibilityAlert(true);
                        }
                    }
                } else {
                    Alert.alert(i18n.t('home.productDetailed.out_of_stock'), i18n.t('home.productDetailed.out_of_stock_body'), [{
                        text: "OK",
                    }])

                    if (Platform.OS === 'web') {
                        setMessageTitle(i18n.t('home.productDetailed.out_of_stock'))
                        setMessageBody(i18n.t('home.productDetailed.out_of_stock_body'))
                        setModalVisibilityAlert(true);
                    }
                }
            }

        }
        else if (item['user_country_payment_option'].country_payment_option.payment_option.payment_options_id === 2) {
            onPress = () => {
                if (productQuantity > 0) {
                    if (selectedCountryDeliveryOption) {
                        if (userLocationSelected != 0) {
                            // setModalVisibility(true)
                            setSelectedCountryPaymentOption(2);
                        } else {
                            Alert.alert(i18n.t('home.productDetailed.delivery_location_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))

                        }
                    } else {
                        Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))
                    }
                } else {
                    Alert.alert(i18n.t('home.productDetailed.out_of_stock'), i18n.t('home.productDetailed.out_of_stock_body'))
                }
            }

        } else if (item['user_country_payment_option'].country_payment_option.payment_option.payment_options_id === 3) {
            onPress = async () => {
                if (productQuantity > 0) {
                    if (selectedCountryDeliveryOption) {
                        if (userLocationSelected != 0) {
                            // setModalVisibility(true)
                            console.log("SELECTED COUNTRY PAYMENT OPTION: ", item['user_country_payment_option'].country_payment_options_id);
                            let productSelectedCountryPaymentOption = item['user_country_payment_option'].country_payment_options_id;

                            let userStripeId = item['user_country_payment_option'].user_stripe_id;

                            console.log("USER STRIPE ID: ", userStripeId)

                            let customerCountryPaymentOption;
                            setSelectedCountryPaymentOption(3);
                            setSelectedCountryPaymentOptionName(item['user_country_payment_option'].country_payment_option.payment_option.payment_option_name);

                            // find if customer have a customer id from the same country payment option
                            customerCountryPaymentOptions.map(item => {
                                if (item.country_payment_options_id === productSelectedCountryPaymentOption) {
                                    customerCountryPaymentOption = item;
                                    console.log("CUSTOMER COUNTRY PAYMENT OPTION: ", customerCountryPaymentOption)
                                } else {
                                }
                            })

                            async function showPaymentSheet(stripeCustomerId) {
                                console.log("CUSTOMER HAVE A STRIPE CUSTOMER ID for this country payment option")
                                //create paymentIntent
                                setModalVisibilityIndicator(true);

                                const createStripePaymentIntent = httpsCallable(functions, 'createStripePaymentIntent');

                                // let stripeCustomerId = stripeCustomerId;

                                let transactionFeeInCents;

                                transactionFeeInCents = currencyCodeStripeFee;

                                // let sellerDistanceInKm = (sellerDistance / 1000).toFixed(2);
                                // let orderDeliveryFees = ((sellerDistance / 1000).toFixed(2)) * deliveryFees;
                                let orderDeliveryFees;

                                if (isInOrganization) {
                                    orderDeliveryFees = ((organizationDistance / 1000).toFixed(2)) * deliveryFees;
                                } else {
                                    orderDeliveryFees = ((sellerDistance / 1000).toFixed(2)) * deliveryFees;
                                }

                                let productPriceInCents = (productPrice * selectedQuantity) * 100;
                                // let productFee = productPrice * 0.05;
                                // let productFeeInCents = productFee.toFixed(2) * 100;
                                let orderDeliveryFeesInCents = orderDeliveryFees.toFixed(2) * 100;
                                let transactionTotalFee = (productPriceInCents + transactionFeeInCents + orderDeliveryFeesInCents) * 0.05;
                                let transactionTotalFeeInCents = (transactionTotalFee.toFixed(2) / 100).toFixed(2) * 100;
                                let transactionTotal = productPriceInCents + transactionFeeInCents + orderDeliveryFeesInCents + transactionTotalFeeInCents;

                                console.log("SELLER DISTANCE: ", sellerDistance)
                                console.log("SELLER DISTANCE IN KM: ", deliveryFees)
                                console.log("PRODUCT PRICE: ", productPrice)
                                console.log("PRODUCT PRICE IN CENTS: ", productPriceInCents)
                                // console.log("PRODUCT FEE: ", productFee)
                                // console.log("PRODUCT FEE IN CENTS: ", productFeeInCents)
                                console.log("ORDER DELIVERY FEES: ", orderDeliveryFeesInCents)
                                console.log("TRANSACTION TOTAL FEE: ", transactionTotalFeeInCents)
                                console.log("TRANSACTION TOTAL: ", (transactionTotalFee.toFixed(2) / 100).toFixed(2))
                                // console.log("TRANSACTION TOTAL BEFORE Fee: ", productPriceInCents + orderDeliveryFeesInCents + transactionFeeInCents)


                                const { data } = await createStripePaymentIntent({
                                    amount: transactionTotal,
                                    currency: productCurrency,
                                    userStripeCustomerId: stripeCustomerId,
                                    userStripeId: userStripeId,
                                });

                                console.log("DATA: ", data)
                                let clientSecret = data.paymentIntent.client_secret;
                                let ephemeralKeySecret = data.ephemerelKey.secret;

                                console.log("CLIENT SECRET: ", clientSecret)

                                // init paymentSheet

                                const { error } = await initPaymentSheet({
                                    merchantDisplayName: "Menucitos",
                                    customerId: stripeCustomerId,
                                    customerEphemeralKeySecret: ephemeralKeySecret,
                                    paymentIntentClientSecret: clientSecret,
                                    googlePay: true,
                                });

                                if (error) {
                                    console.log("ERROR: ", error)
                                    setModalVisibilityIndicator(false);
                                } else {
                                    console.log("PAYMENT SHEET INITIALIZED")
                                    setModalVisibilityIndicator(false);
                                    const { error } = await presentPaymentSheet({
                                        confirmPayment: true,
                                    });

                                    if (error) {
                                        console.log("ERROR: ", error)
                                    } else {
                                        console.log("Payment successful")
                                        //create order and details

                                        let newProductQuantity = productQuantity - selectedQuantity
                                        console.log("NEW PRODUCT QUANTITY: ", newProductQuantity)
                                        let transactionTotal = (selectedQuantity * productPrice)

                                        let realTimeTransaction = false;

                                        if (selectedCountryDeliveryOption === 1) {
                                            realTimeTransaction = true;
                                        }

                                        let countryDeliveryOptionName;

                                        countryDeliveryOptionName = selectedDeliveryOptionName;

                                        console.log("COUNTRY DELIVERY OPTION NAME: ", countryDeliveryOptionName)

                                        if (isInOrganization) {
                                            let orderDeliveryFees = ((sellerDistance / 1000).toFixed(2) * deliveryFees);

                                            let transactionSellerFee;

                                            transactionSellerFee = (currencyCodeStripeFee / 100).toFixed(2);

                                            console.log("TRANSACTION SELLER FEE: ", transactionSellerFee)

                                            //create order card organization
                                            let products = [];

                                            products.push({
                                                product_id: productId,
                                                product_quantity: selectedQuantity,
                                                product_price: productPrice,
                                                product_currency_id: productCurrencyId,
                                            })

                                            let orderProducts = []

                                            orderProducts.push({
                                                product_id: productId,
                                                product_quantity: selectedQuantity,
                                                product_price: productPrice,
                                                product_currency_id: productCurrencyId,
                                            })

                                            let updateProducts = [];

                                            updateProducts.push({
                                                product_id: productId,
                                                product_quantity: newProductQuantity,
                                                product_quantity_before: productQuantity,
                                                product_currency_id: productCurrencyId,
                                                product_price: productPrice,
                                            })

                                            let newProducts = [];

                                            newProducts.push({
                                                product_id: productId,
                                                product_quantity: newProductQuantity,
                                                product_currency_id: productCurrencyId,
                                                product_price: productPrice,
                                            })

                                            let sellers = [];
                                            let transactions = [];

                                            products.map(item => {
                                                transactions.push({
                                                    product_currency_id: item.product_currency_id,
                                                    transaction_total: item.product_price * item.product_quantity,
                                                    transaction_status_id: 3,
                                                    transaction_seller_fee: transactionSellerFee,
                                                    transaction_fee: (transactionTotalFee.toFixed(2) / 100).toFixed(2),
                                                })
                                            })


                                            allUsersByOrganizationId.map(item => {
                                                sellers.push({
                                                    user_id: item.organization_user_id,
                                                })
                                            });

                                            createOrderCardOrganization({
                                                variables: {
                                                    customerUid: uid,
                                                    countryPaymentOptionId: selectedCountryPaymentOption,
                                                    countryDeliveryOptionId: selectedCountryDeliveryOption,
                                                    userLocationId: userLocationSelected,
                                                    note: notes,
                                                    orderProducts: orderProducts,
                                                    sellersUid: sellers,
                                                    realtime: realTimeTransaction,
                                                    transactionProducts: transactions,
                                                    languageCode: languageCode,
                                                    orderDeliveryFees: orderDeliveryFees.toFixed(2),
                                                    transactionSellerFee: transactionSellerFee,
                                                    transactionFee: (transactionTotalFee.toFixed(2) / 100).toFixed(2),
                                                    organizationId: organizationId ? organizationId : 0,
                                                }
                                            }).catch(err => {
                                                console.log("ERROR: ", err)
                                            }).then(res => {
                                                newProducts.map(item => {
                                                    updateProductQuantity({ variables: { product_id: item.product_id, productQuantity: item.product_quantity } }).then(() => {
                                                        console.log("updated")
                                                    }).catch((err) => {
                                                        console.log("ERROR ", err)
                                                    })
                                                });

                                                //  let workers = res.data.user_orders;

                                                const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');

                                                res.data.insert_orders_one.user_orders.map(item => {
                                                    sendExpoPushNotification({
                                                        token: item.user.user_expo_token,
                                                        title: i18n.t('home.productDetailed.new_card_order_notification'),
                                                        body: i18n.t('home.productDetailed.new_card_order_notification_body'),
                                                    }).then(res => {
                                                        console.log("RESPONSE NOTIFICATION SEND", res)
                                                    }).catch(err => {
                                                        console.log("ERROR", err)
                                                    }).finally(() => {
                                                        console.log("FINALLY")
                                                    });
                                                });

                                                const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');


                                                res.data.insert_orders_one.user_orders.map(item => {
                                                    singleOrderSellerEmail({
                                                        order_id: res.data.insert_orders_one.order_id,
                                                        client_name: res.data.insert_orders_one.user.user_name,
                                                        client_email: res.data.insert_orders_one.user.user_email,
                                                        seller_email: item.user.user_email,
                                                        delivery_option: res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_translations[0].delivery_option_name,
                                                        payment_option: res.data.insert_orders_one.country_payment_option.payment_option.payment_option_translations[0].payment_option_name,
                                                        total: productPrice * selectedQuantity,
                                                        currency_code: productCurrency,
                                                        languageCode: languageCode,
                                                    })
                                                });

                                                let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id;

                                                if (deliveryOptionId === 4) {
                                                    console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                                                    res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                                        sendExpoPushNotification({
                                                            token: item.user.user_expo_token,
                                                            title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                            body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                        }).catch(err => {
                                                            console.log("ERROR", err)
                                                        }).then(res => {
                                                            console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                                        }).finally(() => { });
                                                    })
                                                }

                                                Alert.alert(i18n.t('home.organizationPage.order_alert_success', 'home.organizationPage.order_alert_success_body'))
                                                if (Platform.OS === 'web') {
                                                    setMessageTitle(i18n.t('home.organizationPage.order_alert_success'))
                                                    setMessageBody(i18n.t('home.organizationPage.order_alert_success_body'))
                                                    setModalVisibilityAlert(true)
                                                }

                                            })
                                            refetchProductDetail();
                                        } else {
                                            let orderDeliveryFees = ((sellerDistance / 1000).toFixed(2) * deliveryFees);

                                            createOrderCard({
                                                variables: {
                                                    customerUid: uid,
                                                    countryDeliveryOptionId: selectedCountryDeliveryOption,
                                                    countryPaymentOptionId: productSelectedCountryPaymentOption,
                                                    productId: productId,
                                                    productQuantity: selectedQuantity,
                                                    productQuantity: selectedQuantity,
                                                    productCurrencyId: productCurrencyId,
                                                    productPrice: productPrice,
                                                    userLocationId: userLocationSelected,
                                                    transactionTotal: transactionTotal,
                                                    realtime: realTimeTransaction,
                                                    sellerUid: sellerUid,
                                                    notes: notes,
                                                    transactionFee: (transactionTotalFee.toFixed(2) / 100).toFixed(2),
                                                    newProductQuantity: newProductQuantity,
                                                    transactionSellerFee: transactionSellerFee,
                                                    orderDeliveryFees: selectedDeliveryOptionId === 4 ? 0 : orderDeliveryFees,
                                                    organizationId: organizationId ? organizationId : 0,
                                                }
                                            }).catch(err => {
                                                console.log("ERROR: ", err)
                                            }).then(res => {
                                                refetchProductDetail();
                                                Alert.alert(i18n.t('home.productDetailed.thank_you_paypal'), i18n.t('home.productDetailed.thank_you_paypal_body'))

                                                const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');

                                                sendExpoPushNotification({
                                                    token: sellerExpoToken,
                                                    title: i18n.t('home.productDetailed.new_card_order_notification'),
                                                    body: i18n.t('home.productDetailed.new_card_order_notification_body'),
                                                })

                                                const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');

                                                let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id;

                                                if (deliveryOptionId === 4) {
                                                    sendExpoPushNotification({
                                                        token: item.user.user_expo_token,
                                                        title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                        body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                    }).catch(err => {
                                                        console.log("ERROR", err)
                                                    }).then(res => {
                                                        console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                                    }).finally(() => { });
                                                }

                                                let orderId = res.data.insert_orders_one.order_id

                                                let paymentType = selectedCountryPaymentOptionName

                                                console.log("ORDER ID: ", res.data.insert_orders_one.order_id)

                                                singleOrderSellerEmail({
                                                    order_id: orderId,
                                                    client_name: customerName,
                                                    seller_email: sellerEmail,
                                                    delivery_option: countryDeliveryOptionName,
                                                    payment_option: paymentType,
                                                    total: transactionTotal,
                                                    currency_code: productCurrency,
                                                    languageCode: languageCode,
                                                }).then(res => {

                                                    console.log("RESPONSE EMAIL SELLER", res)
                                                }).catch(err => {
                                                    console.log("ERROR EMAIL SELLER", err)
                                                }).finally(() => { });
                                            })

                                        }
                                    }

                                }
                            }

                            if (customerCountryPaymentOption) {
                                if (customerCountryPaymentOption.user_stripe_customer_id) {
                                    showPaymentSheet(customerCountryPaymentOption.user_stripe_customer_id);
                                } else {
                                    console.log("NO CUSTOMER ID");
                                    //create a customer

                                    const idUserCountryPaymentOptionId = customerCountryPaymentOption.id_user_country_payment_option;

                                    const savePaymentMethodSetupIntentFromProduct = httpsCallable(functions, 'savePaymentMethodSetupIntentFromProduct');
                                    let stripeCustomerId;
                                    let ephemeralKeySecret;
                                    let setupIntent;

                                    const { data } = await savePaymentMethodSetupIntentFromProduct({
                                        idUserCountryPaymentOptionId: idUserCountryPaymentOptionId,
                                    });

                                    ephemeralKeySecret = data.ephemerelKey.secret;
                                    setupIntent = data.setupIntent.client_secret;
                                    stripeCustomerId = data.customer.id;

                                    console.log("Stripe Customer Id: ", stripeCustomerId);

                                    const { error } = await initPaymentSheet({
                                        merchantDisplayName: "MenuPay",
                                        customerEphemeralKeySecret: ephemeralKeySecret,
                                        setupIntentClientSecret: setupIntent,
                                        customerId: stripeCustomerId,
                                    })

                                    if (!error) {
                                        console.log("PAYMENT SHEET INITIALIZED SETUP INTENT");

                                        const { error } = await presentPaymentSheet({
                                            clientSecret: setupIntent,
                                        }).then(async res => {
                                            console.log("PAYMENT SHEET COMPLETED FILLING");
                                            console.log(res);
                                        }).catch(err => {
                                            console.log("ERROR: ", err);
                                        })

                                        if (error) {
                                            console.log("ERROR: ", error);
                                        } else {
                                            console.log("PAYMENT SHEET PRESENTED");
                                            console.log("PAYMENT SHEET PRESENTED");
                                            presentPaymentSheet(stripeCustomerId);

                                        }
                                    } else {
                                        console.log("ERROR: ", error);
                                    }
                                }
                            } else {
                                //create a userCountryPaymentOption
                                console.log("CREATE A USER COUNTRY PAYMENT OPTION");
                                setModalVisibilityIndicator(true);
                                createUserPaymentOptionFromProduct({
                                    variables: {
                                        uid: uid,
                                        countryPaymentOptionId: productSelectedCountryPaymentOption,
                                    }
                                }).then(async res => {
                                    // refetchProductDetail();
                                    let userCountryPaymentOptionId = res.data.insert_user_country_payment_option_one.id_user_country_payment_option;

                                    const savePaymentMethodSetupIntentFromProduct = httpsCallable(functions, 'savePaymentMethodSetupIntentFromProduct');
                                    let stripeCustomerId;
                                    let ephemeralKeySecret;
                                    let setupIntent;
                                    const { data } = await savePaymentMethodSetupIntentFromProduct({
                                        idUserCountryPaymentOptionId: userCountryPaymentOptionId,
                                    });

                                    ephemeralKeySecret = data.ephemerelKey.secret;
                                    setupIntent = data.setupIntent.client_secret;
                                    stripeCustomerId = data.customer.id;

                                    console.log("Stripe Customer Id: ", stripeCustomerId);
                                    console.log("Ephemeral Key Secret: ", ephemeralKeySecret);
                                    console.log("Setup Intent: ", setupIntent);

                                    const { error } = await initPaymentSheet({
                                        merchantDisplayName: "MenuPay",
                                        customerEphemeralKeySecret: ephemeralKeySecret,
                                        setupIntentClientSecret: setupIntent,
                                        customerId: stripeCustomerId,
                                    })

                                    if (!error) {
                                        console.log("PAYMENT SHEET INITIALIZED");
                                        // setModalVisibilityIndicator(false);
                                        const { error } = await presentPaymentSheet({
                                            clientSecret: setupIntent,

                                        }).then(async res => {
                                            console.log("PAYMENT SHEET FINISHED");
                                            // await showPaymentSheet(stripeCustomerId);
                                            console.log(res);
                                            if (res.error) {
                                                console.log("ERROR: ", res.error);
                                                setModalVisibilityIndicator(false);

                                            } else {
                                                console.log("PAYMENT SHEET PRESENTED");
                                                // console.log("PAYMENT SHEET PRESENTED");
                                                await showPaymentSheet(stripeCustomerId);
                                            }
                                        }).catch(err => {
                                            console.log("ERROR: ", err);
                                            setModalVisibilityIndicator(false);
                                        })

                                        if (error) {
                                            console.log("ERROR: ", error);
                                            setModalVisibilityIndicator(false);
                                        } else {
                                            console.log("PAYMENT SHEET PRESENT PAYMENT SHEET PRESENTED");
                                            // console.log("PAYMENT SHEET PRESENTED");
                                            await showPaymentSheet(stripeCustomerId);
                                        }
                                    } else {
                                        console.log("ERROR: ", error);
                                        setModalVisibilityIndicator(false);
                                    }


                                }).catch(err => { })

                            }
                        } else {
                            Alert.alert(i18n.t('home.productDetailed.delivery_location_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))
                            if(Platform.OS === 'web'){
                                setMessageTitle(i18n.t('home.productDetailed.delivery_location_alert'));
                                setMessageBody(i18n.t('home.productDetailed.delivery_location_alert_body'));
                                setModalVisibilityAlert(true);   
                            }

                        }
                    } else {
                        Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))
                        if(Platform.OS === 'web'){
                            setMessageTitle(i18n.t('home.productDetailed.delivery_alert'));
                            setMessageBody(i18n.t('home.productDetailed.delivery_location_alert_body'));
                            setModalVisibilityAlert(true);
                        }
                    }
                } else {
                    Alert.alert(i18n.t('home.productDetailed.out_of_stock'), i18n.t('home.productDetailed.out_of_stock_body'))
                    if(Platform.OS === 'web'){
                        setMessageTitle(i18n.t('home.productDetailed.out_of_stock'));
                        setMessageBody(i18n.t('home.productDetailed.out_of_stock_body'));
                        setModalVisibilityAlert(true);
                    }
                }
            }

        }


        return (
            <ItemPayment item={item}
                onPress={onPress}
                payment_option_id={item['user_country_payment_option']['country_payment_option']['payment_option']['payment_options_id']}
                paymentType={item['user_country_payment_option']['country_payment_option']['payment_option']['payment_option_translations']} />
        )
    }

    const handleTextChange = useCallback((text) => {
        setNotes(text)
    })

    const addToCart = () => {
        /**delete orderProducts on asyncstorage*/
        insertNewProductToCart({
            variables: {
                userId: uid,
                productId: productId,
                productQuantity: selectedQuantity,
                productCurrencyId: productCurrencyId,
                organizationId: organizationId,
                productPrice: productPrice,
            }
        }).then(res => { }).catch(err => {
            // console.log("ERROR MUTATION", err)
        })
    }

    function deliveryLocation() {
        return (
            <View style={{ borderWidth: 1, borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', elevation: 20, marginHorizontal: 5 }}>
                <View>
                    <Text style={{ fontSize: 18, paddingHorizontal: 9, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.delivery_location')}</Text>
                    <Text style={{ fontSize: 16, paddingBottom: 5, paddingHorizontal: 9, fontFamily: 'Inter-Regular' }}>{i18n.t('home.productDetailed.delivery_location_info')}</Text>
                    <View style={{ backgroundColor: '#e0e0e0', margin: 10, borderRadius: 6 }}>
                        <Picker selectedValue={userLocationSelected}
                            style={{ paddingVertical: Platform.OS === 'web' ? 10 : 0 }}
                            onValueChange={(itemValue) => setUserLocationSelected(itemValue)}>
                            <Picker.Item label={i18n.t('home.productDetailed.picker_location')}
                                value="0" key="0" />
                            {
                                userLocations.map((item, index) => {
                                    return (
                                        <Picker.Item label={item.address1 + " " + item.zip_code + " " + item.country.country_name} value={item.user_location_id} key={index} />
                                    )
                                })
                            }
                        </Picker>
                    </View>


                    <View>
                        {(Platform.OS === 'ios' || Platform.OS === 'android') && (
                            <BannerAd
                                unitId={Platform.OS === 'android' ? "ca-app-pub-4925893888940052/1125710683" : "ca-app-pub-4925893888940052/9115004970"}
                                size={BannerAdSize.FULL_BANNER}
                                requestOptions={{
                                    requestNonPersonalizedAdsOnly: true,
                                }}
                                onAdLoaded={() => {
                                    // console.log('Advert loaded');
                                }}
                                onAdFailedToLoad={(error) => {
                                    console.error('Advert failed to load: ', error);
                                }}
                            />


                        )}
                    </View>
                    {
                        <KeyboardAvoidingView style={{ borderWidth: 1, borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', marginHorizontal: 6, elevation: 20 }}
                            behavior={Platform.OS === 'ios' ? "padding" : "height"}
                        >
                            <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 15, paddingHorizontal: 9, fontFamily: 'Inter-Black' }}>
                                {i18n.t('home.productDetailed.leave_notes')}
                            </Text>
                            <TextInput
                                numberOfLines={4}
                                defaultValue={notes}
                                multiline={true}
                                style={{ backgroundColor: '#e0e0e0', textAlignVertical: 'top', marginHorizontal: 9, marginBottom: 10, fontSize: 18, paddingHorizontal: 9, paddingVertical: 5 }}
                                onChangeText={text => setNotes(text)}
                                placeholder={i18n.t('home.productDetailed.leave_notes_placeholder')}></TextInput>
                        </KeyboardAvoidingView>
                    }
                    {/* product_payment_options */}
                    {(organizationShowProductCheckout && isInOrganization) && (
                        <View style={{ borderWidth: 1, borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', paddingHorizontal: 6, elevation: 20, marginHorizontal: 6 }}>
                            <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 15, paddingHorizontal: 9, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.payment_options')}</Text>
                            <FlatList listKey='paymentOptions'
                                data={productPaymentOptions} renderItem={renderPayments} keyExtractor={item => item.product_payment_options_id.toString()}></FlatList>
                        </View>
                    )}

                    {(!organizationShowProductCheckout && isInOrganization) && (
                        <View style={{ borderWidth: 1, borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', paddingHorizontal: 6, elevation: 20, marginHorizontal: 6 }}>
                            <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 15, paddingHorizontal: 9, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.payment_options')}</Text>
                            <TouchableOpacity
                                style={{ backgroundColor: "#FF6F00", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 6, marginHorizontal: 10, marginBottom: 10 }}
                                onPress={() => {
                                    navigation.navigate("OrganizationPageCart", {
                                        organizationId: organizationId,
                                        userId: uid,
                                    })
                                }}
                            >
                                <Text style={{ fontSize: 18, fontFamily: 'Inter-Medium', textAlign: 'center', color: '#FFF' }}>
                                    {i18n.t("home.productDetailed.view_in_organization_cart")}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    )}

                    {(!isInOrganization && !organizationShowProductCheckout) && (
                        <View style={{ borderWidth: 1, borderColor: '#EEEEEE', marginTop: 5, borderRadius: 6, backgroundColor: '#EEEEEE', paddingHorizontal: 6, elevation: 20, marginHorizontal: 6 }}>
                            <Text style={{ fontSize: 18, paddingTop: 5, paddingBottom: 15, paddingHorizontal: 9, fontFamily: 'Inter-Black' }}>{i18n.t('home.productDetailed.payment_options')}</Text>
                            <FlatList listKey='paymentOptions'
                                data={productPaymentOptions} renderItem={renderPayments} keyExtractor={item => item.product_payment_options_id.toString()}></FlatList>
                        </View>
                    )}

                    {(isInOrganization && isInOrganization === true) && (
                        <View style={{ borderWidth: 1, borderColor: '#EEEEEE', marginTop: 5, paddingVertical: 10, borderRadius: 6, backgroundColor: '#EEEEEE', paddingHorizontal: 6, elevation: 20, marginHorizontal: 6 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: 18, paddingHorizontal: 9, fontFamily: 'Inter-Medium' }}>{i18n.t('home.productDetailed.add_to_cart_on')}</Text>
                                <TouchableOpacity style={{ flexDirection: 'row', flexShrink: 1 }} onPress={() => {
                                    // console.log("ADD TO CART DISTANCE: ",  distanceCart)
                                    navigation.push('OrganizationPage', {
                                        latitude,
                                        longitude,
                                        organizationId,
                                        distance: distanceCart,
                                    });
                                }}>
                                    <Text style={{ fontSize: 18, color: "#3D5AFE", fontFamily: 'Inter-Black', flexShrink: 1 }}>
                                        {organizationName} {i18n.t('home.productDetailed.page')}
                                    </Text>
                                    {/* <Text style={{fontSize:18, color:"#3D5AFE", fontFamily:'Inter-Black'}}> </Text> */}
                                </TouchableOpacity>

                            </View>
                            <TouchableOpacity
                                style={{ backgroundColor: '#000000', paddingHorizontal: 10, paddingVertical: 5, borderRadius: 6, marginTop: 10, marginHorizontal: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                                onPress={() => {
                                    if (selectedQuantity > 0) {
                                        Alert.alert(i18n.t('home.productDetailed.add_to_cart_alert'), i18n.t('home.productDetailed.add_to_cart_alert_body'), [
                                            { text: i18n.t('home.productDetailed.add_to_cart_alert_cancel'), onPress: () => console.log('Cancel Pressed'), style: 'cancel' },
                                            {
                                                text: i18n.t('home.productDetailed.add_to_cart_alert_ok', { product_quantity: selectedQuantity, product_name: productTitle }), onPress: () => {
                                                    addToCart()
                                                }
                                            },
                                        ], { cancelable: true })

                                        if (Platform.OS === 'web') {
                                            setMessageTitle(i18n.t('home.productDetailed.add_to_cart_alert'))
                                            setMessageBody(i18n.t('home.productDetailed.add_to_cart_alert_body'))
                                            setModalVisibilityAddToCart(true);
                                        }
                                    } else {
                                        Alert.alert(i18n.t('home.productDetailed.out_of_stock'), i18n.t('home.productDetailed.out_of_stock_body'))
                                    }
                                }}>
                                <Text style={{ color: '#FFFFFF', textAlign: 'center', fontSize: 20, paddingVertical: 4 }}>
                                    {i18n.t('home.productDetailed.add_to_cart')}
                                </Text>
                                <Feather name="shopping-cart" size={20} color="#FFFFFF" style={{ paddingLeft: 10 }} />
                            </TouchableOpacity>
                        </View>
                    )}

                    <View>
                        {(Platform.OS === 'ios' || Platform.OS === 'android') && (
                            <BannerAd
                                unitId={Platform.OS === 'android' ? "ca-app-pub-4925893888940052/8621057325" : "ca-app-pub-4925893888940052/9758821044"}
                                size={BannerAdSize.FULL_BANNER}
                                requestOptions={{
                                    requestNonPersonalizedAdsOnly: true,
                                }}
                                onAdLoaded={() => {
                                    // console.log('Advert loaded');
                                }}
                                onAdFailedToLoad={(error) => {
                                    console.error('Advert failed to load: ', error);
                                }}
                            />

                        )}
                        {(Platform.OS === 'web') ? (
                            <AdSense.Google
                                client='ca-pub-4925893888940052'
                                slot='5150082182'
                                style={{ display: 'block' }}
                                format='auto'
                                responsive='true'
                                layoutKey='-gw-1+2a-9x+5c'
                            />
                        ) : null}
                    </View>
                </View>
            </View>
        )
    }


    if (isInOrganization) {
        // console.log("isInOrganization: ", isInOrganization)
        // setMerchantId("YLH8NQMDP8PPQ");
        // setSellerEmail("sb-rnrjw8287934@business.example.com");
    }

    let jsCode = `
            document.getElementById("productTitleLabel").innerHTML = "${productTitle}";
            document.getElementById("titleInput").value = "${productTitle}";
            document.getElementById("productImage").value = "${productImage}";
            document.getElementById("Image").style.backgroundImage = "url(${productImage})";
            document.getElementById("Image").style.backgroundSize = "contain";
            document.getElementById("Image").style.backgroundRepeat = "no-repeat";
            document.getElementById("Image").style.backgroundPosition = "center";
            document.getElementById("Image").style.backgroundColor = "white";
            document.getElementById("Image").style.width = "200px";
            document.getElementById("Image").style.height = "200px";
            document.getElementById("productPrice").value = "${productPrice}";
            document.getElementById("productQuantity").value = "${selectedQuantity}";
            document.getElementById("productCurrency").value = "${productCurrency}";
            document.getElementById("productQuantityLabel").innerHTML = "Quantity: ${selectedQuantity}";
            document.getElementById("productPriceLabel").innerHTML = "Price per Unit: ${productPrice} ${productCurrency}";
            document.getElementById("merchantId").value = "${(isInOrganization) ? "YLH8NQMDP8PPQ" : merchantId}";
            document.getElementById("merchantEmail").value = "${(isInOrganization) ? "sb-rnrjw8287934@business.example.com" : sellerEmail}";
        `;

    const onMessage = (e) => {
        // console.log(e)
        const status = JSON.parse(e.nativeEvent.data);
        if (status.status === 'COMPLETED') {
            setModalVisibility(false)
            Alert.alert(i18n.t('home.productDetailed.thank_you_paypal'), i18n.t('home.productDetailed.thank_you_paypal_body'))
            // console.log('Purchased:',status.purchase_units[0].payments.captures[0].amount.value)
            const transactionTotal = status.purchase_units[0].payments.captures[0].amount.value
            let realTimeTransaction = false

            let newProductQuantity = productQuantity - selectedQuantity;

            /**Real Time El Salvador */
            if (selectedCountryDeliveryOption === 1) {
                realTimeTransaction = true
            }

            createOrder({
                variables: {
                    customerUid: uid,
                    countryDeliveryOptionId: selectedCountryDeliveryOption,
                    countryPaymentOptionId: selectedCountryPaymentOption,
                    productId: productId,
                    productQuantity: selectedQuantity,
                    productCurrencyId: productCurrencyId,
                    productPrice: productPrice,
                    userLocationId: userLocationSelected,
                    transactionTotal: transactionTotal,
                    realtime: realTimeTransaction,
                    sellerUid: sellerUid,
                    notes: notes,
                    newProductQuantity: newProductQuantity,
                }
            }).then(res => {
                // console.log(res)
                refetchProductDetail()

            }).catch(err => {
                // console.log(err)
                Alert.alert("Error", 'Something went wrong ' + err);
            })
        }
    }
    if (productLoading, userStatusLoading, !organizationData
    ) return (
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator color='#FF6D00' size={'large'} />
        </SafeAreaView>
    )
    return (
        <SafeAreaView style={{ flex: 1, paddingTop: -45, paddingBottom: -45 }}>
            <Modal visible={imageVisibility} animationType='slide'>
                <ImageViewer imageUrls={[{ url: productImage }]}
                    enableSwipeDown={true}

                    onSwipeDown={() => {
                        setImageVisibility(false)
                    }}
                />
            </Modal>
            <Modal visible={modalVisibility} animationType='slide'>
                <SafeAreaView style={{ flex: 1 }}>
                    <TouchableOpacity style={{ padding: 15 }} onPress={() => {
                        setModalVisibility(false);
                    }}>
                        <Feather name="x" size={36} color={'#607D8B'} />
                    </TouchableOpacity>
                    <WebView onMessage={onMessage} thirdPartyCookiesEnabled injectedJavaScript={jsCode} sharedCookiesEnabled={true} javaScriptEnabled source={{ uri: 'https://menucitos-328501.web.app/' }} style={{ flex: 1 }} />
                </SafeAreaView>
            </Modal>
            <Modal visible={chatVisibility} animationType='slide'>
                <View style={{ flex: 1 }}>
                    <TouchableOpacity style={{ padding: 25 }} onPress={() => {
                        setModalVisibility(false);
                    }}>
                        <Feather name="x" size={36} color={'#607D8B'} />
                    </TouchableOpacity>
                </View>
            </Modal>

            {/* // Modal Activity Indicator */}

            <Modal visible={modalVisibilityIndicator} transparent={true}

                animationType={'fade'}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <ActivityIndicator color={'#FF6D00'} size={'large'} />
                    <TouchableOpacity style={{ marginTop: 20, borderColor: "#FF6D00", borderWidth: 2, borderRadius: 6 }} onPress={() => {
                        setModalVisibilityIndicator(false)
                    }}>
                        <Text style={{ color: '#FF6D00', paddingHorizontal: 10 }}>Close</Text>
                    </TouchableOpacity>

                </View>
            </Modal>


            <Modal visible={modalVisibilityMessage}
                transparent={true}
                animationType={'fade'}
                onRequestClose={() => setModalVisibilityMessage(false)}
            >
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{messageTitle}</Text>
                        <Text style={{ fontSize: 20 }}>{messageBody}</Text>
                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => {
                            let newProductQuantity = productQuantity - selectedQuantity
                            let transactionTotal = (selectedQuantity * productPrice)

                            let realTimeTransaction = false

                            let countryDeliveryOptionName;

                            if (selectedCountryDeliveryOption == 1) {
                                if (languageCode == 'EN') {
                                    countryDeliveryOptionName = 'Real Time Delivery'
                                } else if (languageCode == 'ES') {
                                    countryDeliveryOptionName = 'Entrega en tiempo real'
                                }
                            }

                            if (selectedCountryDeliveryOption === 1) {
                                realTimeTransaction = true
                            }

                            if (isInOrganization) {
                                let products = []

                                products.push({
                                    product_id: productId,
                                    product_quantity: selectedQuantity,
                                    product_currency_id: productCurrencyId,
                                    product_price: productPrice,
                                })

                                let orderProducts = []

                                orderProducts.push({
                                    product_id: productId,
                                    product_quantity: selectedQuantity,
                                    product_currency_id: productCurrencyId,
                                    product_price: productPrice,
                                })

                                let updateProducts = []

                                updateProducts.push({
                                    product_id: productId,
                                    product_quantity: productQuantity - selectedQuantity,
                                    product_quantity_before: productQuantity,
                                    product_currency_id: productCurrencyId,
                                    product_price: productPrice,
                                })

                                let newProducts = [];

                                newProducts.push({
                                    product_id: productId,
                                    product_quantity: productQuantity - selectedQuantity,
                                    product_currency_id: productCurrencyId,
                                    product_price: productPrice,
                                })

                                let sellers = [];

                                allUsersByOrganizationId.map(user => {
                                    sellers.push({
                                        user_id: user.organization_user_id,
                                    })
                                })

                                let transactions = [];

                                products.map(item => {
                                    transactions.push({
                                        product_currency_id: item.product_currency_id,
                                        transaction_total: item.product_price * item.product_quantity,
                                        transaction_status_id: 3
                                    })
                                });
                                let orderDeliveryFees = ((organizationDistance / 1000).toFixed(2)) * deliveryFees;
                                console.log({
                                    customerUid: uid,
                                    countryDeliveryOptionId: selectedCountryDeliveryOption,
                                    countryPaymentOptionId: item['user_country_payment_option'].country_payment_options_id,
                                    userLocationId: userLocationSelected,
                                    realtime: realTimeTransaction,
                                    note: notes,
                                    orderProducts: orderProducts,
                                    sellersUid: sellers,
                                    transactionProducts: transactions,
                                    languageCode: languageCode,
                                    orderDeliveryFees: orderDeliveryFees,
                                })

                                createOrderCashOrganization({
                                    variables: {
                                        customerUid: uid,
                                        countryDeliveryOptionId: selectedCountryDeliveryOption,
                                        countryPaymentOptionId: item['user_country_payment_option'].country_payment_options_id,
                                        userLocationId: userLocationSelected,
                                        realtime: realTimeTransaction,
                                        note: notes,
                                        orderProducts: orderProducts,
                                        sellersUid: sellers,
                                        transactionProducts: transactions,
                                        languageCode: languageCode,
                                        organizationId: organizationId === 0 ? null : organizationId,
                                        orderDeliveryFees: orderDeliveryFees,
                                    }
                                }).then(res => {
                                    newProducts.map(item => {
                                        // console.log(item)
                                        updateProductQuantity({ variables: { product_id: item.product_id, productQuantity: item.product_quantity } }).then(() => {
                                            console.log("updated")
                                        }).catch((err) => {
                                            console.log("ERROR ", err)
                                        })
                                    });

                                    //  let workers = res.data.user_orders;

                                    const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');

                                    res.data.insert_orders_one.user_orders.map(item => {
                                        sendExpoPushNotification({
                                            token: item.user.user_expo_token,
                                            title: i18n.t('home.productDetailed.new_order_notification'),
                                            body: i18n.t('home.productDetailed.new_order_notification_body'),
                                        }).then(res => {
                                            console.log("RESPONSE NOTIFICATION SEND", res)
                                        }).catch(err => {
                                            console.log("ERROR", err)
                                        }).finally(() => {
                                            console.log("FINALLY")
                                        });
                                    });

                                    const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');


                                    res.data.insert_orders_one.user_orders.map(item => {
                                        singleOrderSellerEmail({
                                            order_id: res.data.insert_orders_one.order_id,
                                            client_name: res.data.insert_orders_one.user.user_name,
                                            client_email: res.data.insert_orders_one.user.user_email,
                                            seller_email: item.user.user_email,
                                            delivery_option: res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_translations[0].delivery_option_name,
                                            payment_option: res.data.insert_orders_one.country_payment_option.payment_option.payment_option_translations[0].payment_option_name,
                                            total: productPrice * selectedQuantity,
                                            currency_code: productCurrency,
                                            languageCode: languageCode,
                                        })
                                    });

                                    let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id;

                                    if (deliveryOptionId === 4) {
                                        console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                                        res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                            sendExpoPushNotification({
                                                token: item.user.user_expo_token,
                                                title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                            }).catch(err => {
                                                console.log("ERROR", err)
                                            }).then(res => {
                                                console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                            }).finally(() => { });
                                        })
                                    }

                                    Alert.alert(i18n.t('home.organizationPage.order_alert_success', 'home.organizationPage.order_alert_success_body'))
                                    if (Platform.OS === 'web') {
                                        setMessageTitle(i18n.t('home.organizationPage.order_alert_success'))
                                        setMessageBody(i18n.t('home.organizationPage.order_alert_success_body'))
                                        setModalVisibilityAlert(true)
                                    }
                                    refetchProductDetail();
                                }).catch(err => {
                                    console.log("ERROR ", err)
                                })

                            } else {
                                let orderDeliveryFees = ((sellerDistance / 1000).toFixed(2)) * deliveryFees;

                                createOrderCash({
                                    variables: {
                                        customerUid: uid,
                                        countryPaymentOptionId: item['user_country_payment_option'].country_payment_options_id,
                                        countryDeliveryOptionId: selectedCountryDeliveryOption,
                                        productId: productId,
                                        productQuantity: selectedQuantity,
                                        productCurrencyId: productCurrencyId,
                                        productPrice: productPrice,
                                        userLocationId: userLocationSelected,
                                        transactionTotal: transactionTotal,
                                        realtime: realTimeTransaction,
                                        sellerUid: sellerUid,
                                        notes: notes,
                                        newProductQuantity: newProductQuantity,
                                        orderDeliveryFees: selectedDeliveryOptionId === 4 ? 0 : orderDeliveryFees,
                                    }
                                }).catch(err => {
                                    console.log("ERROR MUTATION", err)
                                }).then(res => {

                                    Alert.alert(i18n.t('home.productDetailed.thank_you_paypal'), i18n.t('home.productDetailed.thank_you_paypal_body'))

                                    /**send notification */

                                    const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');

                                    sendExpoPushNotification({
                                        token: sellerExpoToken,
                                        title: i18n.t('home.productDetailed.new_order_notification'),
                                        body: i18n.t('home.productDetailed.new_order_notification_body'),
                                    }).then(res => {
                                        // console.log("RESPONSE NOTIFICATION SEND", res)
                                    }).catch(err => {
                                        // console.log("ERROR", err)
                                    }).finally(() => {
                                        // console.log("FINALLY")
                                    });
                                    /**send email notification to seller*/

                                    const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');
                                    console.log("RESPONSE RES: ", res);

                                    let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id;

                                    if (deliveryOptionId === 4) {
                                        console.log("DATAAAAAAAAAA:")
                                        console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                                        res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                            sendExpoPushNotification({
                                                token: item.user.user_expo_token,
                                                title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                                body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                            }).catch(err => {
                                                console.log("ERROR", err)
                                            }).then(res => {
                                                console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                            }).finally(() => { });

                                        })
                                    }

                                    let orderId = res.data.insert_orders_one.order_id

                                    let paymentType = selectedCountryPaymentOptionName

                                    console.log("ORDER ID: ", res.data.insert_orders_one.order_id)

                                    singleOrderSellerEmail({
                                        order_id: orderId,
                                        client_name: customerName,
                                        seller_email: sellerEmail,
                                        delivery_option: countryDeliveryOptionName,
                                        payment_option: paymentType,
                                        total: transactionTotal,
                                        currency_code: productCurrency,
                                        languageCode: languageCode,
                                    }).then(res => {

                                        console.log("RESPONSE EMAIL SELLER", res)
                                    }).catch(err => {
                                        console.log("ERROR EMAIL SELLER", err)
                                    }).finally(() => { });
                                })
                            }
                        }}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>OK</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setModalVisibilityMessage(false)}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Cancel</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>

            <Modal visible={modalVisibilityAlert}
                transparent={true}
                animationType={'fade'}
                onRequestClose={() => setModalVisibilityAlert(false)}
            >
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{messageTitle}</Text>
                        <Text style={{ fontSize: 20 }}>{messageBody}</Text>

                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setModalVisibilityAlert(false)}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Close</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>

            <Modal visible={modalVisibilityAddToCart}
                transparent={true}
                animationType={'fade'}
                onRequestClose={() => setModalVisibilityAddToCart(false)}
            >
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{messageTitle}</Text>
                        <Text style={{ fontSize: 20 }}>{messageBody}</Text>
                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => {
                            addToCart();
                            setModalVisibilityAddToCart(false);
                        }}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>OK</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setModalVisibilityAddToCart(false)}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Cancel</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
            <FlatList listKey='productDetails'
                contentContainerStyle={{ paddingHorizontal: 0, paddingTop: 0 }}
                ListHeaderComponent={header()}
            ></FlatList>
        </SafeAreaView>
    )
}

export default ProductDetailed;