import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert ,TextInput, TouchableOpacity, Modal} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES, GET_SUBCATEGORIES_BY_CATEGORY_ID } from '../../lib/queries';
import { Picker } from '@react-native-picker/picker';
import i18n from '../../i18n/i18n';
import { useIsFocused } from '@react-navigation/native';


const AddProductDefaultSubCategory = ({navigation}) => {
    const isFocused = useIsFocused();

    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();

    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    
    const [subCategoryId, setSubCategoryId] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(0);

    const [selectedSubCategory, setSelectedSubCategory] = useState(0);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);


    /**Queries */
    const {loading: loadingCategories, data: dataCategories, error:errorCategories, refetch: refetchCategories} = useQuery(GET_CATEGORIES,{variables:{languageCode: languageCode}});
    const {loading: loadingSubCategories, data: dataSubCategories, error:errorSubCategories, refetch:refetchSubCategories}= useQuery(GET_SUBCATEGORIES_BY_CATEGORY_ID, {variables:{categoryId:selectedCategory, languageCode: languageCode}});
    useEffect(() => {
        // console.log('error', errorCategories);
        async function getCategories(){
            if(dataCategories){
                const {default_categories} = dataCategories;
                setCategory(default_categories);
            }
        }
        
        if(isFocused){
            refetchCategories();
            getCategories();
            refetchSubCategories();
        }

    })

    
    
    return(
        <SafeAreaView style={{flex:1, paddingTop:-45}}>
            <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:5, paddingHorizontal:5, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontWeight:'bold', fontSize:32}}>
                {i18n.t('products.addProductDefaultCategories.title')}
            </Text>
            </View>

        </View>
        <View style={{flex:2.5}}>
            {/* <TextInput style={{paddingHorizontal:12, backgroundColor:getColor('gray-200'), paddingVertical:12,
             marginHorizontal:15, fontSize:18}} onChangeText={(v)=> setName(v)} />
             */}
             <View style={{paddingTop:0}}>
                <Text style={{fontSize:22}}>{i18n.t('products.addProductDefaultCategories.label_category')}</Text>
            </View>
             {(!loadingCategories && category)?(
                 <Picker
                 style={{marginHorizontal:5}}
                 selectedValue={selectedCategory}
                 itemStyle={{height:100}}
                    onValueChange={(itemValue, itemIndex) => {setSelectedCategory(itemValue); 
                        setSelectedSubCategory(0);
                    //     getSubCategories({variables:{
                    //     categoryId:itemValue,
                    // }})
                    // console.log('itemValue', errorSubCategories);
                    // console.log("SUBCATS: ", dataSubCategories);
                    }}>
                    <Picker.Item label={i18n.t('products.addProductDefaultCategories.picker_category_label')} 
                        value="0" key="0"/>

                    {
                        category.map((category, index) => {
                               return(
                                <Picker.Item label={category.default_category_translations[0].default_category_name}
                                key={index}
                                value={category.category_id}></Picker.Item>
                            )
                        })
                    }
                 </Picker>
             ):null}

            
             <View style={{paddingTop:25}}>
                    <Text style={{fontSize:22, marginHorizontal:5}}>{i18n.t('products.addProductDefaultCategories.label_subcategory')}</Text>
                    {(!loadingSubCategories && dataSubCategories)?(
                <Picker
                style={{marginHorizontal:5, marginTop:25}}
                itemStyle={{height:100}}
                selectedValue={selectedSubCategory}
                onValueChange={(itemValue, itemIndex) => {
                    if(itemValue === 0){
                        setSelectedSubCategory(0);
                        console.log('itemValue', itemValue);
                    }else{
                        setSelectedSubCategory(itemValue);
                        console.log('itemValue', selectedSubCategory);

                    }
                }}>
                
                <Picker.Item label={i18n.t('products.addProductDefaultCategories.picker_subcategory_label')}
                    value="0" key="0"/>
                {
                    dataSubCategories.default_subcategories.map((subCategory, index) => {
                        return(
                            <Picker.Item label={subCategory.default_subcategory_translations[0].default_subcategory_name}
                            key={index}
                            value={subCategory.subcategory_id}></Picker.Item>
                        )
                    })
                }

                </Picker>
             ):null}
             </View>
        </View> 
        <View style={{flex:0.4, paddingHorizontal:5}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{
                console.log('selectedCategory', selectedSubCategory);
                if(selectedSubCategory == 0){
                    Alert.alert(i18n.t('products.addProductDefaultCategories.alert_subcategory_required'), i18n.t('products.addProductDefaultCategories.alert_subcategory_required_body'), [{text: "OK"}]);
                    if(Platform.OS === 'web'){
                        setModalVisibilityAlert(true);
                        setMessageTitle(i18n.t('products.addProductDefaultCategories.alert_subcategory_required'));
                        setMessageBody(i18n.t('products.addProductDefaultCategories.alert_subcategory_required_body'));
                    }
                }else{
                    navigation.navigate('AddProductName', {subCategoryId:selectedSubCategory});
                }
            }}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:16}}>
                    {i18n.t('products.addProductDefaultCategories.btn_next')}
                </Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
              
    </SafeAreaView>
    )
    
}

export default AddProductDefaultSubCategory;