import React, { useState, useEffect,  } from 'react';
import { View, Text, TouchableOpacity, Dimensions,ImageBackground, TouchableWithoutFeedback , ActivityIndicator, FlatList, Platform, useWindowDimensions} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Firebase from '../../lib/firebase';
import {getAuth} from 'firebase/auth'
import { GET_USER_PRODUCT_IMAGE_BY_ID } from '../../lib/queries';
import { useMutation, useQuery } from '@apollo/client';
import i18n from '../../i18n/i18n';

const auth = getAuth(Firebase);

const MyProducts = ({navigation}) => {
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();

  /**Queries */

  const uid = getAuth().currentUser.uid;
  const {loading: loadingProducts, error: errorProducts, data: dataProducts, refetch: refetchProducts} = useQuery(GET_USER_PRODUCT_IMAGE_BY_ID, {variables:{uid}});

  /**States */

  const [myProducts, setMyProducts] = useState([]);

  useEffect(() => {
    console.log(errorProducts);
    let newMyProducts;
    if(dataProducts){
      const {user_products} = dataProducts;
      newMyProducts = user_products;
    }

    setMyProducts(newMyProducts);

    const unsubscribe = navigation.addListener('focus', () => {
      // console.log("Focus")
      refetchProducts()
    });
  })
  
      let screenWidth;
      let numColumns;
      let itemWidth;
    if(Platform.OS === 'ios' || Platform.OS ==='android'){
      screenWidth = Dimensions.get('window').width;
      numColumns = 2;
      itemWidth = screenWidth / numColumns;
    }else if(Platform.OS === 'web'){
      screenWidth = useWindowDimensions().width;

      if(screenWidth > 1200){
        numColumns = 6;
        itemWidth = screenWidth / numColumns;
      }else if(screenWidth > 768){
        numColumns = 5;
        itemWidth = screenWidth / numColumns;
      }
      else{
        numColumns = 2;
        itemWidth = screenWidth/numColumns;
      }
    }

    /**renderItem */
    const Item = ({item, product_id}) => {
      /**Dimensions */
      // console.log(product_id)
      return(
        <View style={{paddingHorizontal:2, paddingVertical:10}}>
          {/* <View style={{width:195, height:250, paddingHorizontal:3}}> */}
            <TouchableWithoutFeedback style={{}} onPress={()=>{navigation.navigate('MyProductDetailed',{
              productId:{product_id},
            })}}>
            <ImageBackground  source={{uri: item['product'].product_thumbnail}} resizeMode='contain' style={{paddingVertical:5, width:itemWidth, height:itemWidth, overflow:'hidden', borderRadius:6}}>
              </ImageBackground>
            </TouchableWithoutFeedback>

              <View style={{backgroundColor:"black", borderRadius:6, alignItems:'center', marginVertical:5}}>
              <Text numberOfLines={1} style={{color:"white", fontSize:18, fontWeight:'bold'}}>
          {item['product']['product_translations'][0].product_title.length < 16 ? item['product']['product_translations'][0].product_title : item['product']['product_translations'][0].product_title.substring(0,15) + '...'}
            </Text>
              </View>


         {/* </View> */}
        </View>

      )
    }

    function header(){
      return(
        <View>
          <View style={{paddingHorizontal:10, paddingTop:5}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
              {i18n.t('products.products.title')}
            </Text>
          </View>
        </View>
        <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:15,marginTop:10, marginHorizontal:5}}
            onPress={()=> navigation.navigate('AddProducts')}
          >
            <Text style={{color:'#000000', textAlign:'center', fontWeight:'bold'}}>
              {i18n.t('products.products.btn_add_product')}
            </Text>
          </TouchableOpacity>
        </View>
      )
    }

    const renderItem = ({item}) => {
      return(<Item item={item}
    product_id={item.product_id}/>)}

    /**renderSeparator */
    if (loadingProducts) return (
      <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
        <ActivityIndicator color='#1e76e8' size={'large'}/>
      </SafeAreaView>
    )
    return(
    <SafeAreaView style={{flex:1,paddingTop:-45, paddingBottom:-45}}>
        
          <FlatList data={myProducts} renderItem={renderItem}
          numColumns={numColumns}
          key={numColumns}
          ListHeaderComponent={header}
          contentContainerStyle={{paddingBottom: Platform.OS === 'ios' || Platform.OS ==='android' ? 0 : 0}}
          // columnWrapperStyle={{paddingBottom: Platform.OS === 'ios' || Platform.OS ==='android' ? 125 : 300}}
          keyExtractor={(item)=>item.product_id.toString()}/>
      </SafeAreaView>
    )

}

export default MyProducts;