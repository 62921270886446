import { gql } from "@apollo/client";

/**Profile queries */
export const GET_USER = gql`
  query($uid: String! = "") {
    users_by_pk(uid: $uid) {
      uid
      user_email
      user_name
      user_lastname
      user_expo_token
      user_photo
      user_thumbnail
      organization_users{
        organization_id
        organization_role_id
        organization{
          organization_name
          organization_thumbnail
          organization_logo
          organization_setup
        }
      }
    }
  }
`;

export const GET_USER_PERMISSION_BY_ORGANIZATION = gql`
query ($uid: String! ="", $organizationId:Int!) {
  organization_users(where:{organization_user_id:{_eq:$uid}, organization_id:{_eq:$organizationId}}){
 organization_role_id
 user{
   uid
 }
}
}
`;

export const UPDATE_USER_NAME = gql`
    mutation($uid:String!, $user_name: String!){
      update_users_by_pk(pk_columns:{uid: $uid}, _set:{user_name: $user_name}){
        user_name
      }
    }
`;

export const UPDATE_USER_LASTNAME = gql`
mutation($uid:String!, $user_lastname: String!){
  update_users_by_pk(pk_columns:{uid: $uid}, _set:{user_lastname: $user_lastname}){
    user_lastname
  }
}
`;

/**Countries Query */

export const GET_COUNTRIES = gql`
query{
  countries(where:{country_active:{_eq:true}}){
    country_id
    country_name
  }
}
`;

export const GET_COUNTRY_CODE = gql`
query($countryId:Int!){
  countries_by_pk(country_id:$countryId){
    country_phone_code
  }
}
`

export const GET_PROVINCE_BY_COUNTRY = gql`
query($countryId: Int!){
  provinces(where:{country_id:{_eq:$countryId}}){
    country_id
    province_id
    province_name
  }
}
`

export const GET_CITY_BY_PROVINCE = gql`
query ($provinceId: Int!){
  cities(where:{province_id:{_eq:$provinceId}}){
    city_id
    city_name
    province_id
  }
}
`

export const INSERT_USER_LOCATION = gql`
mutation ($uid: String! $cityId: Int! $provinceId: Int! $address1:String! $address2:String $zipCode:String $countryId:Int!){
  insert_user_locations_one(object:{user_id:$uid, city_id:$cityId,
  province_id:$provinceId, country_id:$countryId, address1:$address1, address2:$address2,
  zip_code:$zipCode}){
    user_id
  }
}
`

export const GET_USER_LOCATIONS = gql`
query ($uid: String!){
  user_locations(where:{user_id:{_eq:$uid}}){
    user_location_id
    address1
    address2
    zip_code
    country_id
    province_id
    city_id
    country{
      country_name
    }
    province{
      province_name
    }
    city{
      city_name
    }
  }
}
`
export const UPDATE_USER_LOCATIONS = gql`
mutation ($userLocationId:Int! $countryId:Int! $provinceId:Int! $cityId:Int
  $address_1:String! $address_2:String $zipCode:String){
    update_user_locations_by_pk(pk_columns:{user_location_id:$userLocationId}, 
      _set:{country_id:$countryId, province_id:$provinceId, city_id:$cityId, 
      address1:$address_1, address2:$address_2, zip_code:$zipCode}){
      user_location_id
      country_id
      province_id
      city_id
      address1
      address2
      zip_code
    }
  }
`
export const DELETE_USER_LOCATIONS = gql`
mutation ($userLocationId:Int!){
  delete_user_locations_by_pk(user_location_id:$userLocationId){
    user_location_id
  }
}
`
export const GET_USER_lOCATION_ID = gql`
query ($userLocationId:Int!){
  user_locations_by_pk(user_location_id:$userLocationId){
    country_id
    province_id
    city_id
  }
}`

export const GET_PROVINCES = gql`
query {
  provinces{
    province_id
    province_name
  }
}`

export const GET_CITIES = gql`
query {
  cities{
    city_id
    city_name
  }
}`

/**Languages */

export const GET_LANGUAGES = gql`
query{
  languages{
    language_code
    language_name
  }
}
`

/**Categories */

export const INSERT_CUSTOM_CATEGORIES = gql`
mutation ($customCategoryPhoto:String,
  $uid:String!, $selectedLanguage:String!, $customCategoryName:String!){
  insert_custom_categories(objects:{custom_category_photo:$customCategoryPhoto, 
  user_categories:{data:{user_id:$uid}},
  custom_category_translations:{data:{language_code:$selectedLanguage, custom_category_name:$customCategoryName}}}){
    affected_rows
  }
}
`

export const GET_MY_CATEGORIES = gql`
query($uid:String!){
  user_categories(where:{user_id:{_eq:$uid} custom_category_id:{_neq:28}}){
    user_id
    user_category_id
    custom_category{
      custom_category_id
      custom_category_photo
      custom_category_translations(order_by:{created_at: asc}, limit:1){
        id
        language_code
        custom_category_name
        created_at
        updated_at
      }
    }
  }
}
`

export const UPDATE_CUSTOM_CATEGORY_PHOTO = gql`
mutation($customCategoryId:Int!, $customCategoryPhoto:String!){
  update_custom_categories_by_pk(pk_columns:{custom_category_id:$customCategoryId},
    _set:{custom_category_photo:$customCategoryPhoto}){
    custom_category_id
  }
}
`

export const DELETE_CUSTOM_CATEGORY = gql`
mutation ($customCategoryId:Int!){
  delete_custom_categories_by_pk(custom_category_id:$customCategoryId){
    custom_category_id
    custom_category_photo
  }
}
`
/**Query Custom_Category_Name by Current ID and Language Code :) */

export const GET_CUSTOM_CATEGORY_LANGUAGE = gql`
query($customCategoryLanguageCodeDetail:String!, $customCategoryIdDetail:Int!){
  custom_category_translations(where:{custom_category_id:{_eq:$customCategoryIdDetail}, _and:{
    language_code:{_eq:$customCategoryLanguageCodeDetail}}}){
    custom_category_name
    language_code
    language{
      language_name
    }
  }
}
`

export const UPDATE_CUSTOM_CATEGORY_NAME_LANGUAGE = gql`
mutation UPDATE_CUSTOM_CATEGORY_NAME_LANGUAGE($customCategoryText:String!, $customCategoryTranslationId:Int!){
  update_custom_category_translations_by_pk(pk_columns:{id:$customCategoryTranslationId}, _set:{custom_category_name:$customCategoryText}){
    id
  }
}
`

export const GET_CUSTOM_CATEGORY_TRANSLATION_NAME = gql`
query($customCategoryTranslationId:Int!){
  custom_category_translations_by_pk(id:$customCategoryTranslationId){
    custom_category_name
  }
}
`

export const GET_USER_CATEGORY_ID = gql`
query($customCategoryId:Int, $uid:String!){
  user_categories(where:{custom_category_id:{_eq:$customCategoryId}_and:{user_id:{_eq:$uid}}}){
    user_category_id
  }
}
`
export const GET_PRODUCTS_BY_CATEGORY = gql`
query($uid:String!, $customCategoryId:Int!, $languageCode:String){
  user_categories_products(where:{user_id:{_eq:$uid}, _and:{user_category:{custom_category:{custom_category_id:{_eq:$customCategoryId}}}}}){
    id
    user_product_id
    user_product{
      product{
        product_sku
        product_quantity
        product_photo_portrait
        product_thumbnail
        product_translations(limit:1 order_by:{created_at: desc}){
          product_title
        }
      }
    }
  }
}
`
// export const USER_COUNTRY_LOCATION= gql`
// query($uid:String!){
//   user_locations(where:{user_id:{_eq:$uid}}){
//     country_id
//   }
// }`

// export const DELIVERY_OPTIONS_BY_COUNTRY = gql`
// query($countryId:Int){
//   country_delivery_options(where:{country_id:{_eq:$countryId}}){
//     delivery_option{
//       delivery_option_id
//       delivery_option_translations(where:{language_code:{_eq:"EN"}}){
//         delivery_option_name
//         language_code
//       }
//     }
//   }
// }
// `


export const GET_COUNTRY_DELIVERY_OPTIONS = gql`
query ($uid:String!, $languageCode:String!){
	user_locations(where:{user_id:{_eq:$uid}}, limit:1 order_by:{created_at:asc}){
    country{
      country_delivery_options(where:{enabled:{_eq:true}}){
        country_delivery_option_id
        delivery_option_id
        delivery_option{
          delivery_option_translations(where:{language_code:{_eq:$languageCode}}){
            delivery_option_name
            language_code
          }
        }
      }
    }
  }
}
`

/**Get payment Options */
// export const GET_COUNTRY_PAYMENTS_OPTIONS = gql`
// query ($uid:String!, $paymentOptionId:[Int!], $languageCode:String!){
//   user_locations(where:{user_id:{_eq:$uid}}, limit:1){
//   country{
//     country_payment_options(where:{payment_option_id:{_nin:$paymentOptionId}}){
//       payment_option{
//         payment_options_id
//         payment_option_translations(where:{language_code:{_eq:$languageCode}}){
//           payment_option_name
//           language_code
//         }
//       }
//       country{
//         country_name
//       }
//     }
//   }
// }
// }
// `

export const GET_COUNTRY_PAYMENTS_OPTIONS = gql`
query ($uid:String!, $paymentOptionId:[Int!], $languageCode:String!){
  country_payment_options(where:{country:{user_locations:{user_id:{_eq:$uid}}} country_payment_options_id:{_nin:$paymentOptionId}}){
    country_payment_options_id
    payment_option{
      payment_options_id
      payment_option_translations(where:{language_code:{_eq:$languageCode}}){
        payment_option_name
        language_code
      }
    }
    country{
      country_name
    }
  }
}
`

/**Set my payment options */

export const INSERT_USER_PAYMENT_OPTIONS = gql`
mutation($user_payment_options:[user_country_payment_option_insert_input!]!){
  insert_user_country_payment_option(objects:$user_payment_options){
    affected_rows
  }
}
`

export const INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT = gql`
mutation($uid:String!, $countryPaymentOptionId:Int!){
  insert_user_country_payment_option_one(object:{user_id:$uid, country_payment_options_id:$countryPaymentOptionId}){
    id_user_country_payment_option
  }
}
`

/**Query my payment options */

export const GET_USER_PAYMENT_OPTIONS = gql`
query($uid:String!, $languageCode:String!){
user_country_payment_option(where:{user_id:{_eq:$uid}}){
  id_user_country_payment_option
  user_stripe_id
  user_stripe_customer_id
  country_payment_option{
    country{
      country_name
    }
    country_payment_options_id
    payment_option{
      payment_options_id
      payment_option_translations(where:{language_code:{_eq:$languageCode}}){
        payment_option_name
      }
    }
  }
}
}
`

/**Query currencies on my country */

// export const GET_CURRENCIES_BY_COUNTRY = gql`
// query($uid:String!){
//   user_locations(where:{user_id:{_eq:$uid}}, distinct_on:country_id){
//     country{
//       country_currencies{
//         country_currency_id 
//         currency{
//           currency_code
//         }
//       }
//     } 
//   }
// }
// `

export const GET_CURRENCIES_BY_COUNTRY = gql`
query($uid:String! $idUserCountryPaymentOption:Int!){
  country_currencies(where:{country:{user_locations:{user_id:{_eq:$uid} } country_payment_options:{user_country_payment_options:{id_user_country_payment_option:{_eq:$idUserCountryPaymentOption}}}}} distinct_on:currency_code){
    country_currency_id
    currency{
      currency_code
      country_currencies(where:{country:{user_locations:{user_id:{_eq:$uid}}}}){
        country{
					country_name
        }
      }
    }
  }
}
`

/**mutation update user country payment */

export const UPDATE_USER_COUNTRY_PAYMENT = gql`
mutation ($currenciesarray: jsonb $idUserCountryPaymentOption:Int!){
  update_user_country_payment_option_by_pk(pk_columns:{id_user_country_payment_option:$idUserCountryPaymentOption}, _set:{
    user_country_payment_option_config:$currenciesarray
  }){
    user_country_payment_option_config
  }
}
`
/**Get user country payment */

export const GET_USER_COUNTRY_PAYMENT_CURRENCY = gql`
query($idUserCountryPaymentOption:Int!){
  user_country_payment_option(where:{id_user_country_payment_option:{_eq:$idUserCountryPaymentOption}}){
    user_country_payment_option_config
  }
}
`
/**GET_USER_COUNTRY_CURRENCY_OPTION */

// export const GET_USER_COUNTRY_CURRENCY_OPTION = gql`
// query($uid:String!){
//   user_country_payment_option(where:{country_payment_option:{payment_option:{payment_options_id:{_eq:1}}}, 
//     _and:{user_id:{_eq:$uid}}}){
//     user_country_payment_option_config
//   }
// }
// `

export const GET_USER_COUNTRY_CURRENCY_OPTION = gql`
query userCountryPaymentOptionV2($uid:String!){
  user_currencies(where:{user:{user_country_payment_options:{country_payment_option:{payment_option_id:{_eq:1}}} uid:{_eq:$uid}}}){
    user_currency_code
  }
}
`

export const INSERT_NEW_PRODUCT = gql`
mutation(
  #variables
  $uid:String!,
  $productTitle:String!,
  $productSKU:String!,
  $productImage:String,
  $productSlug:String!,
  $productQuantity:Int!,
  $productCurrency:String!,
  $productPrice:numeric,
  $subCategoryId:Int!,
  $newDescriptionList: [product_descriptions_insert_input!]!
  $arrayPaymentOptionsId:[product_payment_options_insert_input!]!
  $arrayDeliveryOptionsId:[product_delivery_options_insert_input!]!
  $languageCode:String!
){
  insert_products_one(object:{product_sku:$productSKU,
  	product_slug:$productSlug, 
    product_quantity:$productQuantity,
    product_photo_portrait:$productImage,
    product_translations:{data:{product_title:$productTitle,language_code:$languageCode}},
    user_products:{data:{user_id:$uid}},
    product_descriptions:{data:$newDescriptionList},
    product_payment_options:{data:$arrayPaymentOptionsId},
    product_delivery_options:{data:$arrayDeliveryOptionsId},
    product_currencies:{data:{currency_code:$productCurrency,
    product_price:$productPrice}},
    product_user_id:$uid,
    product_default_subcategories:{data:{default_subcategory_id:$subCategoryId}}
  }){
    product_id
  }
}
`

export const UPDATE_THUMBNAIL_BY_PRODUCT_ID = gql`
mutation($productId:Int!, $thumbnail:String!){
  update_products_by_pk(pk_columns:{product_id:$productId}, _set:{product_thumbnail:$thumbnail}){
    product_id
  }
}
`

export const UPDATE_PRODUCT_PHOTO_BY_PRODUCT_ID = gql`
mutation ($productId:Int!, $photo:String){
  update_products_by_pk(pk_columns:{product_id:$productId}, _set:{product_photo_portrait:$photo}){
    product_id
  }
}
`

export const INSERT_PRODUCT_GALLERY_ONE = gql`
mutation ($productId:Int,  $productGalleryPhoto:String){
  insert_product_gallery_one(object:{product_id:$productId, product_gallery_photo:$productGalleryPhoto}){
    product_gallery_id
  }
}
`

export const UPDATE_PRODUCT_GALLERY_THUMBNAIL_BY_PRODUCT_GALLERY_ID = gql`
mutation ($productGalleryId:Int!, $productThumbnail:String){
  update_product_gallery_by_pk(pk_columns:{product_gallery_id:$productGalleryId} _set:{product_gallery_thumbnail:$productThumbnail}){
    product_id
  }
}
`

export const GET_USER_PRODUCT_IMAGE_BY_ID = gql`
query($uid:String!){
  user_products(where:{user_id:{_eq:$uid}}, order_by:{created_at: desc}){
  id
  product_id
    product{
      product_photo_portrait
      product_thumbnail
      product_translations(order_by:{created_at: desc} limit:1){
        product_title
        language_code
      }
    }
  }
}
`

export const GET_USER_PRODUCT_BY_ID = gql`
query ($uid:String! $product_id:Int! $languageCode:String!){
  user_products(where:{user_id:{_eq:$uid}, product_id:{_eq:$product_id}}, order_by:{created_at: desc}){
    product_id
    product{
      organization_products{
        organization_id
      }
      product_sku
      product_slug
      product_quantity
      product_visible
      product_photo_portrait
      product_thumbnail
      product_show_in_organization
      product_translations(limit:1 order_by:{created_at: desc}){
        product_translations_id
        product_title
        language_code
      }
    product_galleries{
        product_gallery_id
        product_gallery_photo
        product_gallery_thumbnail
      }
      product_currencies(where:{currency:{country_currencies:{country:{user_locations:{user_id:{_eq:$uid}}}}}} limit:1){
        product_currency_id
        product_price
        currency_code
      }
      product_descriptions(where:{language_code:{_eq:$languageCode}}  order_by:{created_at: asc}){
        product_description_id
        product_description
        language_code
      }
      product_payment_options{
        product_payment_options_id
        user_country_payment_option{
          id_user_country_payment_option
          country_payment_option{
            country_payment_options_id
            payment_option{
              payment_options_id
              payment_option_translations(where:{language_code:{_eq:$languageCode}}){
                payment_option_name
              }
            }
            
          }
        }
      }
      product_delivery_options{
        country_delivery_option{
          country_delivery_option_id
          delivery_option{
            delivery_option_translations(where:{language_code:{_eq:$languageCode}}){
              delivery_option_name
  
            }
          }
        }
      }
    }
  }
  organization(where:{organization_users:{organization_user_id:{_in:[$uid]}}}){
    organization_id
    organization_name
    organization_thumbnail
  }
}
`
export const UPDATE_PRODUCT_IMAGE_PORTRAIT = gql`
mutation($productId:Int!, $productPhotoPortrait:String!){
  update_products_by_pk(pk_columns:{product_id:$productId} _set:{product_photo_portrait:$productPhotoPortrait}){
    product_photo_portrait
    product_thumbnail
  }
}
`

export const UPDATE_PRODUCT_IMAGE_THUMBNAIL = gql`
mutation($productId:Int!, $productThumbnail:String!){
  update_products_by_pk(pk_columns:{product_id:$productId} _set:{product_thumbnail:$productThumbnail}){
    product_photo_portrait
    product_thumbnail
  }
}
`

export const UPDATE_ORGANIZATION_LOGO = gql`
mutation($organizationId:Int!, $organizationLogo:String){
  update_organization_by_pk(pk_columns:{organization_id:$organizationId}, _set:{organization_logo:$organizationLogo}){
    organization_id
  }
}
`

export const UPDATE_ORGANIZATION_THUMBNAIL = gql`
mutation($organizationId:Int!, $organizationThumbnail:String){
  update_organization_by_pk(pk_columns:{organization_id:$organizationId}, _set:{organization_thumbnail:$organizationThumbnail}){
    organization_id
  }
}
`

export const UPDATE_PRODUCT_VISIBLE = gql`
mutation($productId:Int!, $visible:Boolean!){
  update_products_by_pk(pk_columns:{product_id:$productId}, _set:{product_visible:$visible}){
    product_visible
  }
}
`

export const UPDATE_PRODUCT_SHOW_IN_ORGANIZATION = gql`
mutation ($productId:Int!, $visible:Boolean!){
  update_products_by_pk(pk_columns:{product_id:$productId}, _set:{product_show_in_organization:$visible}){
    product_visible
  }
}
`

export const UPDATE_PRODUCT_TITLE_SKU_SLUG = gql`
mutation($productSKU:String!, $productSlug:String!, $product_id:Int!, $productTitle:String!, $product_translations_ID:Int!){
    update_products_by_pk(pk_columns:{product_id:$product_id} _set:{product_slug:$productSlug, 
    product_sku:$productSKU}){
      product_id
    }
    update_product_translations_by_pk(pk_columns:{product_translations_id:$product_translations_ID},
    _set:{product_title:$productTitle}){
      product_id
    }
  }
`

export const UPDATE_PRODUCT_PRICE = gql`
mutation($productCurrencyId:Int!, $productPrice:numeric!){
  update_product_currencies_by_pk(pk_columns:{product_currency_id:$productCurrencyId}, _set:{product_price:$productPrice}){
    updated_at
  }
}
`
export const UPDATE_PRODUCT_QUANTITY = gql`
mutation($productQuantity:Int!, $product_id:Int!){
  update_products_by_pk(pk_columns:{product_id:$product_id} _set:{product_quantity:$productQuantity}){
    product_id
  }
}
`

export const UPDATE_PRODUCT_DESCRIPTION_BY_ID = gql`
mutation($productDescriptionId:Int!,
  $productDescription:String!){
    update_product_descriptions_by_pk(pk_columns:{product_description_id:$productDescriptionId},
    _set:{product_description:$productDescription}){
      product_description_id
    }
  }
`

export const INSERT_PRODUCT_DESCRIPTION_ONE = gql`
mutation($productId:Int!, $productDescription:String!, $languageCode:String!){
  insert_product_descriptions_one(object:{product_id:$productId, product_description:$productDescription, language_code:$languageCode}){
    product_description_id
  }
}
`
export const DELETE_PRODUCT_DESCRIPTION_BY_ID = gql`
mutation ($productDescriptionId:Int!){
  delete_product_descriptions_by_pk(product_description_id:$productDescriptionId){
    product_description_id
  }
}
`
export const GET_PRODUCT_DELIVERY_OPTIONS_BY_ID = gql`
query($productId:Int! $languageCode:String!){
  product_delivery_options(where:{product_id:{_eq:$productId}}){
    country_delivery_option{
    country_delivery_option_id
     delivery_option_id
     delivery_option{
      delivery_option_translations(where:{language_code:{_eq:$languageCode}}){
        delivery_option_name
        language_code
      }
    }
    }
  }
}
`
export const DELETE_PRODUCT_DELIVERY_OPTION_BY_PRODUCT_ID = gql`
mutation($productId:Int!,
  $countryDeliveryOptionId:Int){
    delete_product_delivery_options(where:{product_id:{_eq:$productId}, country_delivery_option_id:{_eq:$countryDeliveryOptionId}}){
      affected_rows
      
    }
  }
`
export const COUNTRY_DELIVERY_OPTIONS_NOT_SELECTED = gql`
query ($uid:String!, $countryDeliveryOptionsId:[Int!]!, $languageCode:String!){
  user_locations(where:{user_id:{_eq:$uid}}, limit:1 order_by:{created_at:asc}){
    country{
      country_delivery_options(where:{country_delivery_option_id:{_nin:$countryDeliveryOptionsId}}){
        country_delivery_option_id
        delivery_option{
      delivery_option_translations(where:{language_code:{_eq:$languageCode}}){
        delivery_option_name
      }
      }
      }
    }
  }  
}
`
export const INSERT_DELIVERY_OPTION_ONE = gql`
mutation($productId:Int!, $countryDeliveryOptionId:Int!){
  insert_product_delivery_options_one(object:{product_id:$productId, country_delivery_option_id:$countryDeliveryOptionId}){
    product_id
  }
}
`

export const GET_PRODUCT_PAYMENT_OPTIONS = gql`
query($productId:Int! $languageCode:String!){
  product_payment_options(where:{product_id:{_eq:$productId}}){
    product_payment_options_id
    user_country_payment_options_id
    user_country_payment_option{
      country_payment_option{
        country{
          country_name
        }
        payment_option{
          payment_options_id
          payment_option_translations(where:{language_code:{_eq:$languageCode}}){
            payment_option_name
            
          }
        }
      }
    }
  }
}
`

export const DELETE_PRODUCT_PAYMENT_OPTIONS_BY_ID = gql`
mutation($productPaymentOptionsId:Int!){
  delete_product_payment_options_by_pk(product_payment_options_id:$productPaymentOptionsId){
    product_id
  }
}
`

export const GET_USER_PAYMENT_OPTIONS_NOT_SELECTED = gql`
query($uid:String!, $idUserCountryPaymentOption:[Int!]! , $languageCode:String!){
  user_country_payment_option(where:{user_id:{_eq:$uid}, _and:{id_user_country_payment_option:{_nin:$idUserCountryPaymentOption}}}){
    id_user_country_payment_option,
    user_id,
    country_payment_option{
      country{
        country_name
      }
      payment_option{
        payment_options_id
        payment_option_translations(where:{language_code:{_eq:$languageCode}}){
          payment_option_name
        }
      }
    }
  }
}
`

export const INSERT_PRODUCT_PAYMENT_OPTION_ONE = gql`
mutation($idUserCountryPaymentOptions:Int!, $productId:Int!){
  insert_product_payment_options_one(object:{user_country_payment_options_id:$idUserCountryPaymentOptions, product_id:$productId}){
    product_id
  }
}
`

export const INSERT_PRODUCTS_TO_CUSTOM_CATEGORY = gql`
mutation INSERT_PRODUCTS_TO_CUSTOM_CATEGORY($userCategoryProducts:[user_categories_products_insert_input!]!){
  insert_user_categories_products(objects:$userCategoryProducts on_conflict:{update_columns:user_category_id constraint:user_categories_products_user_product_id_key}){
      affected_rows
    }
  }
`

export const DELETE_PRODUCT_BY_CATEGORY = gql`
mutation($id:Int!){
  delete_user_categories_products_by_pk(id:$id){
    id
  }
}
`

export const GET_USER_PRODUCT_IMAGE_BY_ID_NOT_IN_CATEGORY = gql`
query GET_USER_PRODUCT_IMAGE_BY_ID_NOT_IN_CATEGORY($uid:String!, $userProductId:[Int!] $languageCode:String!){
  user_products(where:{user_id:{_eq:$uid} _and:{id:{_nin:$userProductId}}}, order_by:{created_at: desc}){
  id
  product_id
    product{
      product_photo_portrait
      product_thumbnail
      product_translations(where:{language_code:{_eq:$languageCode}} limit:1){
        product_title
        language_code
      }
    }
  }
}
`

export const DELETE_PRODUCT_BY_ID = gql`
mutation($productId:Int!){
  delete_products_by_pk(product_id:$productId){
    product_id
    product_thumbnail
    product_photo_portrait
    product_galleries{
      product_gallery_id
      product_gallery_photo
      product_gallery_thumbnail
    }
  }
}
`

export const GET_FIRSTNAME_BY_ID = gql`
query($uid:String!){
  users_by_pk(uid:$uid){
    user_name
  }
}
`

export const GET_USER_LOCATION_ONE = gql`
query($uid:String!){
  user_locations(where:{user_id:{_eq:$uid}} limit:1 order_by:{created_at:asc}){
    country{
      country_id
      country_name
    }
  	province{
      province_id
    	province_name
    }
    city{
      city_id
      city_name
      }
    }
}
`

export const GET_ACCOUNT_HAS_SETUP = gql`
query($uid:String! = ""){
  users_by_pk(uid:$uid){
    user_setup
    user_language
    user_assigned_roles{
      user_role_id
    }
  }
}
`

export const GET_COUNTRY_CURRENCIES = gql`
query($countryId:Int!){
  country_currencies(where:{country_id:{_eq:$countryId}}){
    country_currency_id
    currency_code
  }
}
`

export const INSERT_USER_LOCATION_SETUP = gql`
mutation completeSetup($uid: String! $cityId: Int! $provinceId: Int! $address1:String! $address2:String $zipCode:String $countryId:Int! $name:String! $lastname:String! $currencies:jsonb!, $countryPaymentOptionId:Int!, $phone:bigint!, $currencyCode:String!){
  insert_user_locations_one(object:{user_id:$uid, city_id:$cityId,
  province_id:$provinceId, country_id:$countryId, address1:$address1, address2:$address2,
  zip_code:$zipCode}){
    user_id
  }
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_setup:true
    user_name:$name user_lastname:$lastname user_phone:$phone}){
    uid
  }
  insert_user_country_payment_option_one(object:{country_payment_options_id:$countryPaymentOptionId, user_id:$uid, user_country_payment_option_config:$currencies}){
    user_id
  }
  insert_user_currencies_one(object:{user_id:$uid, user_currency_code:$currencyCode}){
    user_id
  }
}
`

export const GET_PRODUCT_BY_ID_DETAILED = gql`
query($productId:Int!, $languageCode:String! ="", $uid:String! ="", $senderId:String! ="", $recipientId:String! ="", $organizationId:Int = 0){
  products_by_pk(product_id:$productId){
    product_id,
    product_sku,
    product_quantity,
    product_photo_portrait,
    product_thumbnail,
    product_show_in_organization,
    posted_on:days_ago
    product_translations(limit:1 order_by:{created_at:desc}){
      product_title
    }
    product_descriptions(where:{language_code:{_eq:$languageCode}}){
      product_description_id
      product_description
    }
    product_galleries{
      product_gallery_id
      product_gallery_photo
      product_gallery_thumbnail
    }
    product_currencies(limit:1){
      product_currency_id
      product_price
      currency_code
      currency {
        currency_code_stripe_fee
      } 
    }
    product_delivery_options{
      country_delivery_option_id
      country_delivery_option{
      	country_delivery_options_fees(where:{country_currency:{country:{user_locations:{user_id:{_eq:$uid}}}}} order_by:{country_currency:{country:{user_locations_aggregate:{min:{created_at:asc}}}}}){
          country_delivery_options_fees
        }
        delivery_option_id
        delivery_option{          
          delivery_option_translations(where:{language_code:{_eq:$languageCode}}){
            delivery_option_name
          }
        }
      }
    }
    product_payment_options{
    product_payment_options_id
    user_country_payment_option{
        country_payment_options_id
        user_stripe_id
        country_payment_option{
          payment_option{
            payment_options_id
            payment_option_translations(where:{language_code:{_eq:$languageCode}}){
              payment_option_name
            }
          }
        }
      }
    }
    organization_products{
      organization{
        organization_id
        organization_name
        organization_thumbnail
        organization_distance(args:{user_id:$uid})
        organization_location
        organization_show_product_checkout
        organization_users(order_by:{organization_owner:desc}){
          organization_user_id
          user{
            user_email
            user_expo_token
          }
        }
      }
    }
    user_products{
      user_categories_products{
			user_category{
        custom_category{
          custom_category_photo
          custom_category_translations(where:{language_code:{_eq:$languageCode}}){
            custom_category_name
            
          }
        }
      }
      }
      user{
        uid
        user_name
        user_lastname
        user_email
        user_expo_token
        user_thumbnail
        user_location
        distance(args:{user_id:$uid})
        organization_users{
          organization_id
          organization{
            organization_name
            organization_thumbnail
            organization_distance(args:{user_id:$uid})
            organization_location
          }
        }
      }
    }
  }
    users_by_pk(uid:$uid){
      user_name
      user_lastname
      user_email
      user_country_payment_options{
        id_user_country_payment_option
        country_payment_options_id
        user_stripe_customer_id
      }
    }
    user_locations(where:{user_id:{_eq:$uid}}){
    user_location_id
    address1
    address2
    zip_code
    country_id
    province_id
    city_id
    country{
      country_name
    }
    province{
      province_name
    }
    city{
      city_name
    }
  }
  chat(where:{user_chats:{chat:{user_chats:{user_chat_user_id:{_eq:$senderId}}} user_chat_user_id:{_eq:$recipientId}}} order_by:{created_at:asc} limit:1){
    chat_id
  }
  chat_myself: chat(where: {user_chats: {chat: {user_chats: {user_chat_user_id: {_eq: $senderId}}}, user_chat_user_id: {_eq: $recipientId}}, user_chats_aggregate: {count: {predicate: {_eq: 1}}}}, order_by: {created_at: desc}, limit: 1) {
    chat_id
    chat_topic
    created_at
    updated_at
  }
  organization(where:{organization_id:{_eq:$organizationId}}){
    organization_users{
      organization_user_id
    }
  }
}
`

export const GET_PRODUCTS_FROM_USERS_BY_CITY_LOCATION = gql`
query($uid:String!){
  products(where:{user_products:{user:{user_locations:{city:{user_locations:{user_id:{_eq:$uid}}}}}}}){
    product_id
    product_photo_portrait
    product_translations(where:{language_code:{_eq:"EN"}}){
          product_title
    }
    user_products{
      user_id
    }
    product_currencies(where:{currency_code:{_eq:"USD"}}){
        product_price
        currency_code
    }
  }
}
`

export const GET_PRODUCTS_FROM_USERS_BY_CITY_NEWEST = gql`
query($uid:String!){
  products(where:{user_products:{user:{user_locations:{city:{user_locations:{user_id:{_eq:$uid}}}}}}} order_by:{created_at:desc}){
    product_id
    product_photo_portrait
    product_translations(where:{language_code:{_eq:"EN"}}){
          product_title
    }
    user_products{
      user_id
    }
    product_currencies(where:{currency_code:{_eq:"USD"}}){
        product_price
        currency_code
    }
  }
}
`

export const CREATE_ORDER = gql`
mutation($sellerUid:String!, 
  $customerUid:String!, 
  $countryDeliveryOptionId:Int!,
  $countryPaymentOptionId:Int!,
	$productId:Int!, 
	$productQuantity:Int!,
  $productCurrencyId:Int!,
  $transactionTotal:numeric!,
  $userLocationId:Int!
  $realtime:Boolean
  $notes:String
  $productPrice:numeric!
  $newProductQuantity:Int!
  $organizationId:Int
){
  insert_orders_one(object:{country_delivery_option_id:$countryDeliveryOptionId, user_id:$customerUid,
  order_status_id:4, country_payment_option_id:$countryPaymentOptionId,
  user_location_id:$userLocationId,
  order_delivered:false,
  order_reviewed:false,
  order_organization_id:$organizationId,
  order_realtime:$realtime, notes:$notes
  user_orders:{data:{user_id:$sellerUid}}, order_products:{data:{product_id:$productId,product_quantity:$productQuantity,product_currency_id:$productCurrencyId
  product_price:$productPrice}}, transactions:{data:{transaction_status_id:3, transaction_total:$transactionTotal,product_currency_id:$productCurrencyId}}}){
  order_id
  }
  update_products_by_pk(pk_columns:{product_id:$productId}, _set:{product_quantity:$newProductQuantity}){
    product_id
  }
}
`

export const CREATE_ORDER_CASH = gql`
mutation($sellerUid: String!, $customerUid: String!, $countryDeliveryOptionId: Int!, $countryPaymentOptionId: Int!, $productId: Int!, $productQuantity: Int!, $productCurrencyId: Int!, $transactionTotal: numeric!, $userLocationId: Int!, $realtime: Boolean, $notes: String, $productPrice: numeric!, $newProductQuantity: Int!, $organizationId: Int, $orderDeliveryFees: numeric!) {
  insert_orders_one(object: {country_delivery_option_id: $countryDeliveryOptionId, user_id: $customerUid, order_status_id: 5, country_payment_option_id: $countryPaymentOptionId, user_location_id: $userLocationId, order_delivered: false, order_reviewed: false, order_delivery_fees: $orderDeliveryFees, order_organization_id: $organizationId, order_realtime: $realtime, notes: $notes, user_orders: {data: {user_id: $sellerUid}}, order_products: {data: {product_id: $productId, product_quantity: $productQuantity, product_currency_id: $productCurrencyId, product_price: $productPrice}}, transactions: {data: {transaction_status_id: 3, transaction_total: $transactionTotal, product_currency_id: $productCurrencyId}}}) {
    order_id
    country_delivery_option {
      country {
        user_locations(where: {user: {user_assigned_roles: {user_assigned_role_id: {_eq: 1}}}}) {
          user {
            user_expo_token
          }
        }
      }
      delivery_option{
        delivery_option_id
      }
    }
  }
  update_products_by_pk(pk_columns: {product_id: $productId}, _set: {product_quantity: $newProductQuantity}) {
    product_id
  }
}
`
export const CREATE_ORDER_CARD = gql`
mutation($sellerUid: String!, $customerUid: String!, $countryDeliveryOptionId: Int!, $countryPaymentOptionId: Int!, $productId: Int!, $productQuantity: Int!, $productCurrencyId: Int!, $transactionTotal: numeric!, $userLocationId: Int!, $realtime: Boolean, $notes: String, $productPrice: numeric!, $newProductQuantity: Int!, $organizationId: Int, $orderDeliveryFees: numeric! $transactionFee: numeric! $transactionSellerFee:numeric!) {
  insert_orders_one(object: {country_delivery_option_id: $countryDeliveryOptionId, user_id: $customerUid, order_status_id: 4, country_payment_option_id: $countryPaymentOptionId, user_location_id: $userLocationId, order_delivered: false, order_reviewed: false, order_delivery_fees: $orderDeliveryFees, order_organization_id: $organizationId, order_realtime: $realtime, notes: $notes, user_orders: {data: {user_id: $sellerUid}}, order_products: {data: {product_id: $productId, product_quantity: $productQuantity, product_currency_id: $productCurrencyId, product_price: $productPrice}}, transactions: {data: {transaction_status_id: 3, transaction_total: $transactionTotal, product_currency_id: $productCurrencyId, transaction_fee:$transactionFee, transaction_seller_fee:$transactionSellerFee}}}) {
    order_id
    country_delivery_option {
      country {
        user_locations(where: {user: {user_assigned_roles: {user_assigned_role_id: {_eq: 1}}}}) {
          user {
            user_expo_token
          }
        }
      }
      delivery_option{
        delivery_option_id
      }
    }
  }
  update_products_by_pk(pk_columns: {product_id: $productId}, _set: {product_quantity: $newProductQuantity}) {
    product_id
  }
}
`
export const GET_ORDER_BY_BUYER_ID = gql`
subscription($customerUid: String!, $languageCode: String!) {
  orders(where: {user_id: {_eq: $customerUid}}, order_by: {created_at: desc}) {
    order_id
    order_realtime
    order_type_id
    notes
    country_delivery_option_id
    country_payment_option_id
    order_status_id
    user_location_id
    created_at
    order_delivered
    order_delivery_user
    order_delivery_fees
    userByOrderDeliveryUser {
      user_location
      uid
      user_name
      user_lastname
      user_email
      user_expo_token
      user_chats(where: {_or: [{chat: {messages: {sender_id: {_eq: $customerUid}}}}, {chat: {user_chats: {user_chat_user_id: {_eq: $customerUid}}}}]}, order_by: {created_at: desc}, limit: 1) {
        user_chat_chat_id
      }
      chat_myself: user_chats(where: {chat: {user_chats: {user_chat_user_id: {_eq: $customerUid}}, user_chats_aggregate: {count: {predicate: {_eq: 1}}}}, user_chat_user_id: {_eq: $customerUid}}, limit: 1) {
        user_chat_chat_id
      }
    }
    user_orders(limit: 1) {
      user {
        user_name
        user_lastname
        uid
        user_expo_token
        user_chats(where: {_or: [{chat: {messages: {sender_id: {_eq: $customerUid}}}}, {chat: {user_chats: {user_chat_user_id: {_eq: $customerUid}}}}]}, order_by: {created_at: desc}, limit: 1) {
          user_chat_chat_id
        }
        chat_myself: user_chats(where: {chat: {user_chats: {user_chat_user_id: {_eq:$customerUid}}, user_chats_aggregate: {count: {predicate: {_eq: 1}}}}, user_chat_user_id: {_eq: $customerUid}}, limit: 1) {
       	 user_chat_chat_id
      	}
      }
    }
    country_delivery_option {
      delivery_option_id
      delivery_option {
        delivery_option_translations(where: {language_code: {_eq: $languageCode}}) {
          delivery_option_name
          language_code
        }
      }
    }
    country_payment_option {
      payment_option {
        payment_option_translations(where: {language_code: {_eq: $languageCode}}) {
          payment_option_name
          language_code
        }
      }
    }
    order_status {
      order_status_translations(where: {language_code: {_eq: $languageCode}}) {
        language_code
        order_status_name
      }
    }
    user_location {
      address1
      address2
      address3
      zip_code
      country {
        country_name
        country_active
      }
      province {
        province_name
      }
      city {
        city_name
      }
    }
    transactions {
      transaction_id
      transaction_total
      transaction_fee
      transaction_seller_fee
      transaction_currency_code
      product_currency {
        currency_code
      }
      transaction_status {
        transaction_status_translations(where: {language_code: {_eq: $languageCode}}) {
          transaction_status_name
          language_code
        }
      }
    }
    transactions_aggregate {
      aggregate {
        sum {
          transaction_total
        }
      }
    }
  }
}
`

export const GET_PRODUCTS_BY_ORDER_ID = gql`
query($orderId:Int!){
  orders_by_pk(order_id:$orderId){
    notes
    order_products{
      product_id
      product_price
      product_quantity
      product{
        product_translations(limit:1 order_by:{created_at:desc}){
          product_title
        }
        product_photo_portrait
      }
      product_currency{
        currency_code
      }
      created_at
    }
    user_location{
      address1
      address2
      address3
      zip_code
      city{
        city_name
      }
      province{
        province_name
      }
    }
  }
} 
`
export const GET_ORDER_REALTIME_BY_ID = gql`
subscription($orderId: Int!, $languageCode:String!) {
  orders_by_pk(order_id: $orderId) {
    order_realtime
    order_delivery_user
    country_delivery_option_id
    country_delivery_option{
      delivery_option_id
    }
    country_payment_option_id
    country_payment_option{
      payment_option_id
    }
    user{
			user_expo_token
      user_email
      user_location
    }
    user_orders(limit:1){
      user{
        user_name
        user_lastname
        user_location
      }
    }
    order_status_id
    user_location_id
    order_delivered
    order_delivered_time
    order_reviewed
    order_reviewed_time
    created_at
    order_status {
      order_status_translations(where: {language_code: {_eq: $languageCode}}) {
        language_code
        order_status_name
      }
    }
    transactions {
      transaction_status_id
      transaction_status {
        transaction_status_translations(where: {language_code: {_eq: $languageCode}}) {
          transaction_status_name
          language_code
        }
      }
    }

  }
}
`

export const GET_ORDERS_REALTIME_BY_SELLER_UID = gql`
subscription($sellerUid:String!, $languageCode:String!){
  user_orders(where:{user_id:{_eq:$sellerUid}} order_by:{created_at:desc}){
    order{
      order_id
      order_realtime
      order_type_id
      notes
      country_delivery_option_id
      country_payment_option_id
      order_status_id
      user_location_id
      created_at
      order_reviewed
      order_delivered
      order_delivery_fees
      user{
        uid
        user_name
        user_lastname
        user_expo_token
        user_chats(where:{_or:[{chat:{messages:{sender_id:{_eq:$sellerUid}}}}, {chat:{user_chats:{user_chat_user_id:{_eq:$sellerUid}}}}]} order_by:{created_at:desc} limit:1){
          user_chat_chat_id
        }
        chat_myself: user_chats(where:{chat:{user_chats:{user_chat_user_id:{_eq:$sellerUid}}, user_chats_aggregate:{count:{predicate:{_eq:1}}}}, user_chat_user_id:{_eq:$sellerUid}} limit:1){
          user_chat_chat_id
        }
      }
       country_delivery_option{
      delivery_option{
        delivery_option_translations(where:{language_code:{_eq:$languageCode}}){
          delivery_option_name
          language_code
        }
      }
    }
    country_payment_option{
      payment_option{
        payment_option_translations(where:{language_code:{_eq:$languageCode}}){
          payment_option_name
          language_code
        }
      }      
    }
    order_status{
      order_status_translations(where:{language_code:{_eq:$languageCode}}){
        language_code
        order_status_name
      }
    }
    transactions{
      transaction_id
      transaction_total
      transaction_currency_code
      product_currency{
        currency_code
      }
      transaction_status{
        transaction_status_id
        transaction_status_translations(where:{language_code:{_eq:$languageCode}}){
          transaction_status_name
          language_code
        }
      }
      
    }
    transactions_aggregate{
      aggregate{
        sum{
          transaction_total
        }
      }
    }
    order_organization_id
    organization{
      organization_id
      organization_thumbnail
      organization_name
    }
    }
  }
}
`

export const SET_ORDER_VIEWED_BY_ID = gql`
mutation($orderId:Int!){
  update_orders_by_pk(pk_columns:{order_id:$orderId} _set:{order_reviewed:true, 
  order_reviewed_time:"now()"}){
    order_id
  }
}
`

export const SET_TRANSACTION_STATUS_BY_ORDER_ID_FILLING = gql`
mutation($orderId:Int!){
  update_transactions(where:{transaction_order_id:{_eq:$orderId}}, _set:{transaction_status_id:2}){
    affected_rows
  }
}
`

export const SET_TRANSACTION_STATUS_BY_ORDER_ID_COMPLETED = gql`
mutation($orderId:Int!){
  update_transactions(where:{transaction_order_id:{_eq:$orderId}}, _set:{transaction_status_id:1}){
    affected_rows
  }
}
`

export const SET_ORDER_DELIVERED_BY_ORDER_ID = gql`
mutation($orderId:Int!){
  update_orders_by_pk(pk_columns:{order_id:$orderId},
    _set:{order_delivered:true, order_delivered_time:"now()"}){
    order_id
  }
}`

export const SET_ORDER_DELIVERED_CASH_BY_ORDER_ID = gql`
mutation($orderId:Int!){
  update_orders_by_pk(pk_columns:{order_id:$orderId},
    _set:{order_delivered:true, order_delivered_time:"now()", order_status_id:4}){
    order_id
  }
}
`

export const GET_CHAT = gql`
subscription($chatId:Int!){
  messages(where:{chat_id:{_eq:$chatId}} order_by:{created_at:desc}){
    _id:id
    text:message
    createdAt:created_at
    image:image
    user:userBySenderId{
      _id:uid,
      name:user_name
      avatar:user_thumbnail
    }
  }
}
`

export const GET_SENDER_CHAT = gql`
subscription($senderId:String!, $recipientId:String!){
  sender:messages(where:{recipient_id:{_eq:$recipientId}, sender_id:{_eq:$senderId}}  order_by:{created_at:desc}){
    _id:id
    text:message
    createdAt:created_at
    user:userBySenderId{
      _id:uid, 
      name:user_name
    }
  }
}
`
export const GET_RECEIVER_CHAT = gql`
subscription($senderId:String!, $recipientId:String!){
  receiver:messages(where:{sender_id:{_eq:$recipientId} recipient_id:{_eq:$senderId}}  order_by:{created_at:desc}){
    _id:id
    text:message
    createdAt:created_at
    user:userBySenderId{
      _id:uid
      name:user_name
    }
  }
}
`

export const SEND_MESSAGE = gql`
mutation($senderId:String!  $video:String, $message:String!, $chatId:Int! $image:String){
  insert_messages_one(object:{chat_id:$chatId, sender_id:$senderId, message:$message, video:$video, image:$image}){
    message
    userBySenderId{
      user_name
      user_lastname
    }
  }
}
`

export const GET_INBOX_MESSAGES = gql`
subscription($recipientId:String!){
  chat_aggregate(where:{user_chats:{user:{uid:{_eq:$recipientId}}}} order_by:{messages_aggregate:{max:{created_at:desc_nulls_last}}}){
    nodes{
      chat_id
      messages(limit:1 order_by:{created_at:desc_nulls_first} ){
        id
        chat_id
        message
        sender_id
        created_at
        userBySenderId{
          uid
          user_name
          user_lastname
          user_expo_token
          
        }
        chat{
          user_chats(limit:1 where:{user_chat_user_id:{_neq:$recipientId}}){
            user{
              user_name
							user_lastname
              user_active
              user_thumbnail
            }
          }
        }
      }
    }
  }
}
`

export const GET_EXISTING_CHAT = gql`
query($recipientId:String! $senderId:String!){
  chat(where:{messages:{sender_id:{_eq:$recipientId} } user_chats:{user_chat_user_id:{_eq:$senderId}}}){
    chat_id
  }
}
`

export const CREATE_CHAT_FROM_PRODUCT = gql`
mutation($recipientId:String!, $senderId:String!){
  insert_chat_one(object:{user_chats:{data:[{user_chat_user_id:$recipientId}, {user_chat_user_id:$senderId}]}}){
    chat_id
  }
}
`
export const CREATE_CHAT_MYSELF_FROM_PRODUCT = gql`
mutation($senderId:String!){
  insert_chat_one(object:{user_chats:{data:[{user_chat_user_id:$senderId}]}}){
    chat_id
  }
}
`

export const GET_CHAT_FROM_PRODUCT = gql`
query($recipientId:String! $senderId:String!){
  chat(where:{user_chats:{user_chat_user_id:{_eq:$recipientId}}}  order_by:{created_at:desc} limit:1){
    user_chats(where:{user_chat_user_id:{_eq:$senderId}} order_by:{created_at:desc} limit:1){
      user_chat_chat_id
    }
  }
}
`

export const UPDATE_USER_LOCATION_BY_UID = gql`
mutation($uid:String!, $coords:geography!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_location:$coords}){
    user_location
  }
}
`

// export const GET_NEAR_PRODUCTS = gql`
// query($uid:String!, $point:geography!){
//   products(where:{user_products:{user:{user_location:{_st_d_within:{distance:1000, from:$point}}} user_id:{_neq:$uid}}}){
//     product_id
//     product_sku
//     product_photo_portrait
//     product_currencies(where:{currency_code:{_eq:"USD"}}){
//       product_price
//       currency_code
//     }
//     product_translations(where:{language_code:{_eq:"EN"}}){
//       product_title
//     }
//     user_products{
//       user{
//         uid
//         user_name
//         user_lastname
//         distance(args:{user_id:$uid})
//       }
//     }
//   }
// } 
// `

export const GET_NEAR_PRODUCTS = gql`
query ($uid:String! ="",$point:geography!, $distance:Float!,  $locationFilter:[user_locations_bool_exp!] = [] ,$categoryFilter:product_default_subcategories_bool_exp = {}, $languageCode:String, $userLocationsFilter:user_locations_bool_exp = {},  $productTranslationsFilter:product_translations_bool_exp ={}, $searchText:String){
  search_products(args:{search:$searchText} where:{user:{_or:[{user_location:{_st_d_within:{distance:$distance, from:$point}}}, {user_locations:{country:{user_locations:{user_id:{_eq:$uid}}} _and:$locationFilter }}, {user_locations:$userLocationsFilter}], user_locations:$userLocationsFilter} user_id:{_neq:$uid} product:{product_visible:{_eq:true} product_default_subcategories:$categoryFilter product_translations:$productTranslationsFilter product_show_in_organization:{_eq:false}}} order_by:{user:{user_location:desc}}){
      product{
      product_id
      product_sku
      product_photo_portrait
      product_thumbnail
      product_currencies( limit:1){
      product_price
      currency_code
      }
      product_translations(order_by:{language_code:asc} limit:1){
        product_title
      }
      product_default_subcategories{
        default_subcategory{
          default_subcategory_translations(where:{language_code:{_eq:$languageCode}}){
            default_subcategory_name
          }
          default_category{
            default_category_translations(where:{language_code:{_eq:$languageCode}}){
              default_category_name
            }
          }
        }
      }
    }
    user{
      distance(args:{user_id:$uid})
      uid
      user_locations(limit:1 order_by:{created_at:asc}){
        province{
          province_abreviation
        }
        city{
          city_name
        }
      }
      organization_users{
        organization_id
      }
    }
  }
}
`

export const UPDATE_EXPO_TOKEN_BY_UID = gql`
mutation($uid:String!, $expoToken:String!){
  update_users_by_pk(pk_columns:{uid:$uid}, _set:{user_expo_token:$expoToken}){
    uid
  }
}
`
export const GET_CATEGORIES = gql`
query($languageCode:String!){
  default_categories{
    category_id
    category_photo
    default_category_translations(where:{language_code:{_eq:$languageCode}}){
      default_category_name
    }
  }
}
`

export const GET_SUBCATEGORIES_BY_CATEGORY_ID = gql`
query($categoryId:Int, $languageCode:String!){
  default_subcategories(where:{default_category_id:{_eq:$categoryId}}){
    subcategory_id
    default_subcategory_translations(where:{language_code:{_eq:$languageCode}}){
      default_subcategory_name
    }
  }
}
`

export const GET_PRODUCTS_BY_SUBCATEGORY_ID = gql`
query($categoryId:Int, $uid:String! ="", $languageCode:String!, $distance:Float!, $point:geography!){
  default_categories(where:{category_id:{_eq:$categoryId}}){
    default_category_translations(where:{language_code:{_eq:$languageCode}}){
      default_category_name
    }
    default_subcategories{
      subcategory_id
      default_subcategory_translations(where:{language_code:{_eq:$languageCode}}){
        language_code
        default_subcategory_name
      }
      data:product_default_subcategories(where:{product:{user_products:{user:{_or:[{user_location:{_st_d_within:{distance:$distance, from:$point }}}, {user_locations:{country:{user_locations:{user_id:{_eq:$uid}}}}}]}}  product_visible:{_eq:true}}}){
        key:product_id
        product{
          product_id
          product_sku
          product_thumbnail
          product_photo_portrait
          product_translations(limit:1 order_by:{created_at:desc}){
            product_title
          }
          user_products{
            user_id
          }
          organization_products{
            organization_id
          }
        }
      }
    }
  }
}
`
export const GET_SUBCATEGORIES_BY_CATEGORY = gql`
query($categoryId:Int!){
  default_categories_by_pk(category_id:$categoryId){
    default_category_translations(where:{language_code:{_eq:"EN"}}){
      language_code
      default_category_name
    }
    default_subcategories{
    subcategory_id
		default_subcategory_translations(where:{language_code:{_eq:"EN"}}){
      language_code
      default_subcategory_name
    }
    }
  }
}
`

export const GET_CASH_OPTION_BY_COUNTRY = gql`
query ($countryId:Int!){
  country_payment_options(where:{country_id:{_eq:$countryId}, payment_option_id:{_eq:1}}){
    country_payment_options_id
  }
}
`
export const GET_ORGANIZATION_BY_ID = gql`
query($organizationId:Int!){
  organization_by_pk(organization_id:$organizationId){
    organization_name
    organization_email
    organization_phone
    organization_logo
    organization_thumbnail
    organization_location
    organization_description
    organization_whatsapp
    organization_address1
    organization_show_product_checkout
    organization_active
    user{
      user_photo
      user_thumbnail
      user_name
      user_lastname
      uid
    }
    country{
      country_phone_code
      country_id
      country_name
    }
    province{
      province_id
      province_name
    }
    city{
      city_id
      city_name
    }
  }
}
`

export const GET_ORGANIZATION_PRODUCTS_BY_USERS = gql`
query($organizationId:Int! $point:geography! $distance:Float! $uid:String!, $query:String!){
  search_products(args:{search:$query}, where:{user:{organization_users:{organization_id:{_eq:$organizationId}} _or:[{user_location:{_st_d_within:{distance:$distance, from:$point}}},{user_locations:{province:{user_locations:{user_id:{_eq:$uid}}}}}]} product:{product_visible:{_eq:true} organization_products:{organization_id:{_in:[$organizationId]}} } }){
    product_id
    product{
      product_id
      product_sku
      product_photo_portrait
      product_thumbnail
      product_currencies(limit:1 order_by:{created_at:desc}){
      product_price
      currency_code
      }
      product_translations(limit:1 order_by:{created_at:desc}){
        product_title
      }
    }
    user{
      uid
    }
  }
}
`
export const GET_ORGANIZATION_PRODUCTS_BY_USERS_CATEGORIES = gql`
query ($organizationId: Int! $productDescriptionsFilter: product_descriptions_bool_exp) {
  user_categories(where: {_or: [{user_categories_products: {user_product: {product: {organization_product: {organization_id: {_eq: $organizationId}} product_visible:{_eq:true}}}}}],} order_by:{custom_category:{custom_category_translations_aggregate:{max:{custom_category_name:desc, created_at:asc}}}}) {
    user_category_id
    custom_category {
      custom_category_photo
      custom_category_translations(limit: 1, order_by: {created_at: desc}) {
        custom_category_name
      }
    }
    data:user_categories_products(where: {user_product: {product: {product_visible: {_eq: true}, product_descriptions: $productDescriptionsFilter, organization_product: {organization_id: {_eq: $organizationId}}}}}) {
      user_product_id
      user_product {
        product {
          product_id
          product_sku
          product_photo_portrait
          product_thumbnail
          product_currencies(limit: 1, order_by: {created_at: desc}) {
            product_price
            currency_code
          }
          product_translations(limit: 1, order_by: {created_at: desc}) {
            product_title
          }
        }
        user{
          uid
        }
      }
    }
  }
}
`

export const GET_SEARCH_RESULTS = gql`
query($uid:String! = "",$point:geography!, $query:String!, $distance:Float!){
  search_products( args:{search:$query} , where:{user:{_or:[{user_location:{_st_d_within:{distance:$distance, from:$point }}}, {user_locations:{country:{user_locations:{user_id:{_eq:$uid}}}}}]}
    user_id:{_neq:$uid} _or:[{product:{product_translations:{product_title:{_ilike:$query}} product_visible:{_eq:true}} }, {product:{product_descriptions:{product_description:{_ilike:$query}} product_visible:{_eq:true}}}]} order_by:{user:{user_location:desc}}){
    product_id
    product{
      product_id
      product_sku
      product_photo_portrait
      product_currencies(limit:1 order_by:{created_at:asc}){
      product_price
      currency_code
      }
      product_translations(limit:1 order_by:{created_at:asc}){
        product_title
      }
      product_descriptions(order_by:{created_at:asc}){
        product_description_id
        product_description
      }
      organization_products{
        organization_id
      }
    }
    user{
      distance(args:{user_id:$uid})
      uid
    }
  }
}
`

export const IS_USER_IN_ORGANIZATION = gql`
query($uid:String!){
  organization_users(where:{organization_user_id:{_eq:$uid}}){
    organization_id
  }
}
`

export const GET_NEAR_STORES = gql`
query ($point:geography!, $distance:Float!, $uid:String! ="",  $organizationCountryId:Int_comparison_exp ={}, $organizationProvinceId:Int_comparison_exp ={}, $organizationCityId:Int_comparison_exp ={}, $searchText:String, $organizationLocationFilter:organization_bool_exp! = {}){
  search_organizations(args:{search:$searchText} where:{_or:[{organization_location:{_st_d_within:{distance:$distance,from:$point}}}, {country:{user_locations:{user_id:{_eq:$uid}}}},$organizationLocationFilter] organization_country_id:$organizationCountryId organization_province_id:$organizationProvinceId organization_city_id:$organizationCityId organization_setup:{_eq:true} organization_active:{_eq:true}} order_by:{organization_location:desc}){
    organization_id
    organization_name
    organization_logo
    organization_email
    organization_phone
    organization_description
    organization_distance(args:{user_id:$uid})
    country{
      country_phone_code
    }
  }
}
`

export const GET_MY_PRODUCTS = gql`
query($uid:String! = ""){
  user_products(where:{user_id:{_eq:$uid}}){
    product_id
    product{
      product_id
      product_id
      product_sku
      product_photo_portrait
      product_thumbnail
      product_currencies(limit:1){
      product_price
      currency_code
      }
      product_translations(order_by:{language_code:asc} limit:1){
        product_title
      }
    }
    user{
      distance(args:{user_id:$uid})
      uid
      organization_users{
        organization_id
      }
    }
  }
}
`

export const INSERT_NEW_PRODUCT_TO_CART = gql`
mutation($productCurrencyId:Int!, $userId:String!, $productId:Int!, $productQuantity:Int!, $organizationId:Int!, $productPrice:numeric!){
  insert_shopping_cart_one(object:{organization_id:$organizationId, product_currency_id:$productCurrencyId, product_quantity:$productQuantity, user_id:$userId, product_id:$productId, product_price:$productPrice}){
    cart_id
  }
}
`
export const GET_ORGANIZATION_CART_BY_ORGANIZATION_ID = gql`
query ($userId: String! ="", $organizationId: Int!, $languageCode: String!) {
  products_aggregate(where: {shopping_carts: {organization_id: {_eq: $organizationId}, user_id: {_eq: $userId}, checked_out: {_eq: false}}}) {
    nodes {
      product_id
      product_quantity
      product_thumbnail
      product_translations(limit: 1, order_by: {created_at: asc}) {
        product_title
      }
      product_currencies {
        product_price
        currency_code
      }
      user_products {
        user_id
        user {
          user_email
        }
      }
      shopping_carts_aggregate(where: {organization_id: {_eq: $organizationId}, user_id: {_eq: $userId}, checked_out: {_eq: false}}) {
        nodes {
          cart_id
          product_price
          product_currency{
            product_currency_id
            currency_code
            currency {
              currency_code_stripe_fee
            }
          }
        }
        aggregate {
          sum {
            product_quantity
          }
        }
      }
    }
  }
  organization(where: {organization_id: {_eq: $organizationId}}) {
    organization_name
    organization_distance(args:{user_id:$userId})
  }
  shopping_cart_aggregate(where: {user_id: {_eq: $userId}, organization_id: {_eq: $organizationId}, checked_out: {_eq: false}}) {
    aggregate {
      sum {
        product_quantity
      }
    }
  }
  product_delivery_options_aggregate(where: {product: {shopping_carts: {user_id: {_eq: $userId}, organization_id: {_eq: $organizationId}, checked_out: {_eq: false}}}}) {
    nodes {
      country_delivery_option {
        country_delivery_option_id
        delivery_option {
          delivery_option_translations(where: {language_code: {_eq: $languageCode}}) {
            delivery_option_name
            language_code
          }
        }
      }
    }
  }
    product_currencies_aggregate(where: {shopping_carts: {organization_id: {_eq: $organizationId}, user_id: {_eq: $userId}, checked_out: {_eq: false}}}) {
    aggregate {
      sum {
        product_price
      }
    }
    nodes {
      currency_code
      currency {
        currency_code_stripe_fee
      }
    }
  }
  country_delivery_options_aggregate(where: {product_delivery_options: {product: {shopping_carts: {user_id: {_eq: $userId}, organization_id: {_eq: $organizationId}, checked_out: {_eq: false}}}}}) {
    nodes {
      country_delivery_option_id
      country_delivery_options_fees{
        country_delivery_options_fees
      }
      delivery_option {
        delivery_option_id
        delivery_option_translations(where: {language_code: {_eq: $languageCode}}) {
          language_code
          delivery_option_name
        }
      }
    }
  }
  country_payment_options_aggregate(where: {user_country_payment_options: {product_payment_options: {product: {shopping_carts: {user_id: {_eq: $userId}, organization_id: {_eq: $organizationId}, checked_out: {_eq: false}}}}}}) {
    nodes {
      country_payment_options_id
      user_country_payment_options_aggregate(where:{user:{organizations:{organization_id:{_eq:$organizationId} organization_users:{organization_owner:{_eq:true}}}}}){
        nodes{
          user_stripe_id
          country_payment_option{
            country{
              country_name
            }
          }
        }
      }
      payment_option {
        payment_options_id
        payment_option_translations(where: {language_code: {_eq: $languageCode}}) {
          language_code
          payment_option_name
        }
      }
    }
  }
  user_locations(where: {user_id: {_eq: $userId}}) {
    user_location_id
    address1
    address2
    zip_code
    country_id
    province_id
    city_id
    country {
      country_name
    }
    province {
      province_name
    }
    city {
      city_name
    }
  }
  users_by_pk(uid:$userId){
    user_name
    user_lastname
    user_email
    user_country_payment_options{
      id_user_country_payment_option
      country_payment_options_id
      user_stripe_customer_id
    }
  }
}
`

export const CREATE_ORDER_CASH_ORGANIZATION = gql`
mutation createOrderOrg($customerUid: String!, $countryDeliveryOptionId: Int!, $countryPaymentOptionId: Int!, $userLocationId: Int!, $realtime: Boolean, $notes: String, $languageCode: String, $orderProducts: [order_products_insert_input!]!, $sellersUid: [user_orders_insert_input!]!, $transactionProducts: [transactions_insert_input!]!, $organizationId: Int, $orderDeliveryFees: numeric!) {
  insert_orders_one(object: {country_delivery_option_id: $countryDeliveryOptionId, user_id: $customerUid, order_status_id: 5, country_payment_option_id: $countryPaymentOptionId, user_location_id: $userLocationId, order_delivered: false, order_reviewed: false, order_delivery_fees: $orderDeliveryFees, order_realtime: $realtime, notes: $notes, order_organization_id: $organizationId, user_orders: {data: $sellersUid}, order_products: {data: $orderProducts}, transactions: {data: $transactionProducts}}) {
    order_id
    user {
      user_name
      user_lastname
    }
    country_payment_option {
      payment_option {
        payment_option_translations(where: {language_code: {_eq: $languageCode}}) {
          payment_option_name
          language_code
        }
      }
    }
    country_delivery_option {
      country {
        user_locations(where:{user:{user_assigned_roles:{user_assigned_role_id:{_eq:1}}}}) {
          user {
            user_expo_token
          }
        }
      }
      delivery_option {
        delivery_option_id
        delivery_option_translations(where: {language_code: {_eq: $languageCode}}) {
          delivery_option_name
          language_code
        }
      }
    }
    user_orders {
      user {
        uid
        user_expo_token
        user_name
        user_lastname
        user_email
      }
    }
  }
}
`
export const CREATE_ORDER_CARD_ORGANIZATION = gql`
mutation createOrderCardOrg($customerUid: String!, $countryDeliveryOptionId: Int!, $countryPaymentOptionId: Int!, $userLocationId: Int!, $realtime: Boolean, $notes: String, $languageCode: String, $orderProducts: [order_products_insert_input!]!, $sellersUid: [user_orders_insert_input!]!, $transactionProducts: [transactions_insert_input!]!, $organizationId: Int, $orderDeliveryFees: numeric!) {
  insert_orders_one(object: {country_delivery_option_id: $countryDeliveryOptionId, user_id: $customerUid, order_status_id: 4, country_payment_option_id: $countryPaymentOptionId, user_location_id: $userLocationId, order_delivered: false, order_reviewed: false, order_delivery_fees: $orderDeliveryFees, order_realtime: $realtime, notes: $notes, order_organization_id: $organizationId, user_orders: {data: $sellersUid}, order_products: {data: $orderProducts}, transactions: {data: $transactionProducts}}) {
    order_id
    user {
      user_name
      user_lastname
    }
    country_payment_option {
      payment_option {
        payment_option_translations(where: {language_code: {_eq: $languageCode}}) {
          payment_option_name
          language_code
        }
      }
    }
    country_delivery_option {
      country {
        user_locations(where:{user:{user_assigned_roles:{user_assigned_role_id:{_eq:1}}}}) {
          user {
            user_expo_token
          }
        }
      }
      delivery_option {
        delivery_option_id
        delivery_option_translations(where: {language_code: {_eq: $languageCode}}) {
          delivery_option_name
          language_code
        }
      }
    }
    user_orders {
      user {
        uid
        user_expo_token
        user_name
        user_lastname
        user_email
      }
    }
  }
}
`

export const SHOPPING_CART_CHECKOUT_BY_ORGANIZATION_ID = gql`
mutation($organizationId:Int, $userId:String!){
  update_shopping_cart(where:{user_id:{_eq:$userId} organization_id:{_eq:$organizationId}} _set:{checked_out:true}){
    affected_rows
  }
}
`

export const DELETE_FROM_CART_BY_ID = gql`
mutation deleteFromShoppingListByCartId($cartId: [Int!]!) {
  delete_shopping_cart(where: {cart_id: {_in: $cartId}}) {
    affected_rows
  }
}
`
export const GET_USER_LOCATION_POINT = gql`
query($uid:String! = ""){
  users_by_pk(uid:$uid){
    user_location
  }
}
`

export const SET_LAST_SEEN_ACTIVITY = gql`
mutation($uid:String!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{last_seen:"now()"}){
    uid
    updated_at
  }
}
`

export const SET_USER_ONLINE = gql`
mutation($uid:String!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_active:true}){
  uid
}
}
`

export const SET_USER_OFFLINE = gql`
mutation($uid:String!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_active:false}){
  uid
}
}
`

export const GET_USER_STATUS = gql`
subscription($uid:String! = ""){
  users_by_pk(uid:$uid){
    user_active
  }
}
`
export const GET_ALL_USERS_BY_ORGANIZATION_ID = gql`
query($organizationId:Int!){
  organization_by_pk(organization_id:$organizationId){
    organization_users(order_by:{organization_owner:desc}){
      organization_user_id
    }
  }
}
`

export const UPDATE_ORGANIZATION_BY_ID = gql`
mutation ($organizationId:Int!, $organizationName:String!, $organizationEmail:String!, $organizationPhone:numeric!, $organizationWhatsapp:numeric!, $point:geography!,
  $organizationAddress1:String!, $selectedCountryId:Int!, $selectedProvinceId:Int!, $selectedCityId:Int!, $organizationImage:String, $organizationThumbnail:String,  $organizationDescription:String!){
    update_organization_by_pk(pk_columns:{organization_id:$organizationId}, _set:{
      organization_name:$organizationName, organization_email:$organizationEmail, organization_phone:$organizationPhone, organization_whatsapp:$organizationWhatsapp, 
      organization_location:$point, organization_address1:$organizationAddress1, organization_country_id:$selectedCountryId, organization_city_id:$selectedCityId, 
      organization_province_id:$selectedProvinceId, organization_logo:$organizationImage, organization_thumbnail:$organizationThumbnail, organization_description:$organizationDescription
    }){
      organization_id
    }
  }
`

export const UPDATE_ORGANIZATION_PRODUCT_SHOW_CHECKOUT = gql`
mutation ($organizationId:Int!, $organizationShowProductCheckout:Boolean!){
  update_organization_by_pk(pk_columns:{organization_id:$organizationId} _set:{organization_show_product_checkout:$organizationShowProductCheckout}){
    organization_id
  }
}
`

export const UPDATE_ORGANIZATION_ACTIVE = gql`
mutation ($organizationId:Int!, $organizationActive:Boolean!){
  update_organization_by_pk(pk_columns:{organization_id:$organizationId} _set:{organization_active:$organizationActive}){
    organization_id
  }
}
`

export const GET_ORGANIZATION_USERS_BY_ORGANIZATION_ID = gql`
query ($organizationId:Int!){
  organization_users(where:{organization_id:{_eq:$organizationId}} order_by:{created_at:asc}){
    id
    organization_user_id
    organization_owner
    user{
      uid
      user_name
      user_lastname
      user_email
    }
     role{
      role_id
    }
  }
  organization_by_pk(organization_id:$organizationId){
    organization_owner
  }
}
`
export const DELETE_USER_ORGANIZATION_BY_ID = gql`
mutation($organizationUserId:Int!){
  delete_organization_users_by_pk(id:$organizationUserId){
    organization_user_id
  }
}
`

export const GET_USER_BY_EMAIL = gql`
query ($email:String!){
  users(where:{user_email:{_eq:$email}}){
    user_email
    uid
    organization_users{
      organization_id
    }
  }
}
`
export const INSERT_ORGANIZATION_USER_ONE = gql`
mutation ($organizationId:Int!, $userId:String!, $roleId:Int!){
  insert_organization_users_one(object:{organization_id:$organizationId, organization_user_id:$userId, organization_role_id:$roleId }){
    organization_id
  }
}
`

export const UPDATE_ORGANIZATION_USER_LEVEL_BY_ID = gql`
mutation($id:Int!, $roleId:Int!){
  update_organization_users_by_pk(pk_columns:{id:$id} _set:{organization_role_id:$roleId}){
    organization_id
  }
}
`

export const DELETE_PRODUCT_GALLERY_BY_ID = gql`
mutation($productGalleryId:Int!){
  delete_product_gallery_by_pk(product_gallery_id:$productGalleryId){
    product_gallery_id
  }
}
`

export const INSERT_ORGANIZATION_ONE = gql`
mutation($uid:String!){
  insert_organization_one(object:{
    organization_owner:$uid,
    organization_users:{
      data:{organization_user_id:$uid, organization_role_id:1, organization_owner:true}
    }
  }){
    organization_id
    organization_owner
  }
}
`

export const UPDATE_ORGANIZATION_OWNER = gql`
mutation($uid:String!, $itemUserId:String!, $organizationId:Int!){
  update_organization_users_many(updates:[
    {_set:{organization_owner:false} where:{organization_user_id:{_eq:$uid}}}
    {_set:{organization_owner:true, organization_role_id:1} where:{organization_user_id:{_eq:$itemUserId}}}
  ]){
    affected_rows
  }
  update_organization(_set:{organization_owner:$itemUserId}, where:{organization_id:{_eq:$organizationId}}){
    affected_rows
  }
}
`

export const SETUP_ORGANIZATION = gql`
mutation($organizationId:Int!, $organizationName:String!, $organizationEmail:String!, $organizationPhone:numeric!, $organizationWhatsapp:numeric!, $point:geography!,
  $organizationAddress1:String!, $selectedCountryId:Int!, $selectedProvinceId:Int!, $selectedCityId:Int!, $organizationImage:String, $organizationThumbnail:String,  $organizationDescription:String!){
    update_organization_by_pk(pk_columns:{organization_id:$organizationId}, _set:{
      organization_name:$organizationName, organization_email:$organizationEmail, organization_phone:$organizationPhone, organization_whatsapp:$organizationWhatsapp, 
      organization_location:$point, organization_address1:$organizationAddress1, organization_country_id:$selectedCountryId, organization_city_id:$selectedCityId, 
      organization_province_id:$selectedProvinceId, organization_logo:$organizationImage, organization_thumbnail:$organizationThumbnail, organization_description:$organizationDescription, organization_setup:true, organization_active:true
    }){
      organization_id
    }
  }`

export const DELETE_ORGANIZATION_BY_ID = gql`
  mutation($organizationId:Int!){
    delete_organization_by_pk(organization_id:$organizationId){
      organization_id
    }
}
`

export const UPDATE_USER_PHOTO = gql`
mutation ($uid:String!, $userPhoto:String!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_photo:$userPhoto}){
    user_photo
  }
}
`

export const UPDATE_USER_THUMBNAIL = gql`
mutation ($uid:String!, $userThumbnail:String!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_thumbnail:$userThumbnail}){
    user_thumbnail
  }
}`

export const INSERT_PRODUCT_ORGANIZATION = gql`
mutation($organizationId:Int!, $productId:Int!, $visible:Boolean!){
  insert_organization_products_one(object:{organization_id:$organizationId, product_id:$productId}, on_conflict:{update_columns:organization_id, constraint:organization_products_product_id_key}){
    organization_product_id
  }
    update_products_by_pk(pk_columns:{product_id:$productId}, _set:{product_show_in_organization:$visible}){
    product_visible
  }
}
`

export const DELETE_PRODUCT_ORGANIZATION = gql`
mutation ($productId:Int!, $visible:Boolean!){
  delete_organization_products(where:{product_id:{_eq:$productId}}){
    affected_rows
  }
  update_products(where:{product_id:{_eq:$productId}}, _set:{product_show_in_organization:$visible}){
    affected_rows
  }
}
`

export const GET_IF_CHAT_EXISTS = gql`
query getExistingChat($recipientId:String!, $senderId:String! =""){
  chat(where:{user_chats:{chat:{user_chats:{user_chat_user_id:{_eq:$senderId}}} user_chat_user_id:{_eq:$recipientId}}} order_by:{created_at:asc} limit:1){
   chat_id
 }
 chat_myself: chat(where: {user_chats: {chat: {user_chats: {user_chat_user_id: {_eq: $senderId}}}, user_chat_user_id: {_eq: $recipientId}}, user_chats_aggregate: {count: {predicate: {_eq: 1}}}}, order_by: {created_at: desc}, limit: 1) {
  chat_id
  chat_topic
  created_at
  updated_at
}
}
`

export const DELETE_CURRENCY_FROM_USER = gql`
mutation($uid:String!, $currencyCode:String!){
  delete_user_currencies(where:{user_id:{_eq:$uid}, user_currency_code:{_eq:$currencyCode}}){
    affected_rows
  }
}
`
export const INSERT_CURRENCY_TO_USER = gql`
mutation($uid:String!, $currencyCode:String!){
  insert_user_currencies_one(object:{user_id:$uid, user_currency_code:$currencyCode}){
   user_id
 }
}
`

export const GET_AVAILABLE_TRIPS = gql`
subscription($uid: String! $languageCode: String!) {
  orders(where: {transactions: {transaction_status_id: {_in:[3,2,1]}}, _or:[{ order_delivery_user: {_is_null: true}}, {order_delivery_user:{_eq:$uid}}], country_delivery_option: {delivery_option: {delivery_option_id: {_eq: 4}}}} order_by:{created_at:desc}) {
    order_id
    order_delivery_fees
    country_delivery_option_id
    country_payment_option_id
    order_status_id
    user_location_id
    created_at
    order_delivered
    order_delivery_user
    user_location {
      address1
      address2
      address3
      zip_code
      country {
        country_name
        country_active
      }
      province {
        province_name
      }
      city {
        city_name
      }
    }
    country_delivery_option {
      delivery_option_id
      delivery_option {
        delivery_option_translations(where: {language_code: {_eq: $languageCode}}) {
          delivery_option_name
          language_code
        }
      }
    }
    country_payment_option {
      payment_option {
        payment_option_translations(where: {language_code: {_eq: $languageCode}}) {
          payment_option_name
        }
      }
    }
    transactions {
      transaction_id
      transaction_total
      product_currency{
        product_currency_id
        currency_code
      }
      transaction_status {
        transaction_status_translations(where: {language_code: {_eq: $languageCode}}) {
          transaction_status_name
        }
      }
    }
    order_status {
      order_status_translations(where: {language_code: {_eq: $languageCode}}) {
        order_status_name
      }
    }
    notes
    transactions_aggregate {
      aggregate {
        sum {
          transaction_total
        }
      }
      nodes {
        product_currency {
          product_currency_id
          currency_code
        }
      }
    }
    user {
      uid
      user_name
      user_lastname
      user_expo_token
      user_chats(where: {_or: [{chat: {messages: {sender_id: {_eq: $uid}}}}, {chat: {user_chats: {user_chat_user_id: {_eq: $uid}}}}]}, order_by: {created_at: asc}, limit: 1) {
        user_chat_chat_id
      }
      chat_myself: user_chats(where:{chat:{user_chats:{user_chat_user_id:{_eq:$uid}}, user_chats_aggregate:{count:{predicate:{_eq:1}}}}, user_chat_user_id:{_eq:$uid}} limit:1){
        user_chat_chat_id
      }
    }
    user_orders(limit:1){
      user {
        uid
        user_name
        user_lastname
        user_expo_token
        user_location
        user_chats(where: {_or: [{chat: {messages: {sender_id: {_eq: $uid}}}}, {chat: {user_chats: {user_chat_user_id: {_eq: $uid}}}}]}, order_by: {created_at: asc}, limit: 1) {
          user_chat_chat_id
        }
        chat_myself: user_chats(where:{chat:{user_chats:{user_chat_user_id:{_eq:$uid}}, user_chats_aggregate:{count:{predicate:{_eq:1}}}}, user_chat_user_id:{_eq:$uid}} limit:1){
          user_chat_chat_id
        }
      }
    }
    order_organization_id
    organization{
      organization_id
      organization_thumbnail  
      organization_name
      organization_location
    }
  }
}
`

export const TAKE_TRIP = gql`
mutation($uid:String!, $orderId:Int!){
  update_orders_by_pk(pk_columns:{order_id:$orderId} _set:{order_delivery_user:$uid}){
    user_id
  }
}
`
export const GET_USER_PAYMENT_OPTION_BY_ID = gql`
query($id:Int!){
  user_country_payment_option_by_pk(id_user_country_payment_option:$id){
    user_stripe_clicked
    user_stripe_id
    user_stripe_object
    user{
      user_name
      user_lastname
      user_email
      user_currencies(where:{currency:{country_currencies:{country:{country_payment_options:{user_country_payment_options:{id_user_country_payment_option:{_eq:$id}}}}}}}){
        user_currency_code
      }
    }
    country_payment_option{
      payment_option{
        payment_options_id
      }
      country{
        country_name
        country_code
      }
    }
  }
}
`

export const GET_USER_PAYMENT_STRIPE_VERIFIED = gql`
subscription($id:Int!){
  user_country_payment_option(where:{id_user_country_payment_option:{_eq:$id}}){
    user_stripe_id
    user_stripe_object
    user_stripe_external_accounts
    user_stripe_customer_id
  }
}
`

export const DELETE_USER_COUNTRY_PAYMENT_OPTION = gql`
mutation($idUserCountryPaymentOption:Int!){
	delete_user_country_payment_option_by_pk(id_user_country_payment_option:$idUserCountryPaymentOption){
    id_user_country_payment_option
  }
}
`
export const GET_USER_CURRENCIES_BY_UID = gql`
query ($uid:String! $payee:String!){
  user_currencies(where:{user_id:{_eq:$uid} user:{user_currencies:{user_id:{_in:[$uid, $payee]}}}} order_by:{created_at:asc}){
    user_currency_code
    currency{
      currency_code_stripe_fee
    }
  }
  users_by_pk(uid:$uid){
    user_name
    user_lastname
    user_email
    user_country_payment_options{
      id_user_country_payment_option
      country_payment_options_id
      user_stripe_customer_id
    }
  }
}
`

export const GET_USER_COUNTRY_PAYMENT_OPTIONS = gql`
query($payee:String! $currencyCode:String! = "", $languageCode:String!){
  user_country_payment_option(where:{country_payment_option:{country:{country_currencies:{currency_code:{_eq:$currencyCode}}} payment_option_id:{_eq:3}} user_id:{_eq:$payee}}){
    id_user_country_payment_option
    user_stripe_id
    user_id
    country_payment_option{
      country_payment_options_id
      country{
        country_name
        country_code
      }
      payment_option{
        payment_option_translations(where:{language_code:{_eq:$languageCode}}){
          payment_option_name
          language_code
        }
      }
    }
  }
}
`

export const CREATE_ORDER_CASH_PAYEE = gql`
mutation ($customerUid:String!, $countryPaymentOptionId:Int!, $transactionTotal:numeric!
  $transactionFee:numeric! $sellerUid:String!, $transactionSellerFee:numeric!, $currencyCode:String!){
    insert_orders_one(object:{user_id:$customerUid, order_status_id:4, order_type_id:2,user_orders:{data:{user_id:$sellerUid}}
    country_payment_option_id:$countryPaymentOptionId
    transactions:{data:{transaction_status_id: 3, transaction_total:$transactionTotal, transaction_fee:$transactionFee, transaction_seller_fee:$transactionSellerFee, transaction_currency_code:$currencyCode}}
    }){
      order_id
    }
  }
`

export const VERSION_INFORMATION = gql`
query{
  version_information(limit:1 order_by:{created_at:desc}){
    version_id
    version_code
    created_at
  }
}
`

export const UPDATE_USER_LANGUAGE = gql`
mutation($languageCode:String! $uid:String!){
  update_users_by_pk(pk_columns:{uid:$uid} _set:{user_language:$languageCode}){
    uid
  }
}
`



