import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList, Alert, Button } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native'
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_USER_LOCATIONS } from '../../lib/queries';
import { useQuery } from '@apollo/client';
import { set } from 'react-native-reanimated';
import { useIsFocused } from '@react-navigation/native';
import i18n from '../../i18n/i18n';

const auth = getAuth(Firebase);


const Locations = ({navigation}) => {

  /**Location list def */
  const uid = getAuth().currentUser.uid

  const [userLocations, setUserLocations] = useState([])
  const {loading: locationsFetch, data: locationData, refetch } = useQuery(GET_USER_LOCATIONS, {variables:{uid}})
  const isFocused = useIsFocused();
    useEffect(()=>{    
      let newUserLocations
      if(locationData){
        const {user_locations} = locationData
        console.log(user_locations)
        newUserLocations= user_locations
      }

      setUserLocations(newUserLocations)
      
      const unsubscribe = navigation.addListener('focus', () => {
        refetch()
      });
      
    }, [])

    /**Component to be rendered */
  const Item = ({ country, province, city, address1, address2, zip_code, user_location_id, country_identifier, province_identifier, city_identifier}) => (
    <View style={{borderRadius:6, borderWidth:1, paddingVertical:6, paddingHorizontal:5}}>
      
  <View style={{flexDirection:'row', paddingVertical:3}}>
      <Text style={{fontSize:22, flexGrow:1}}>{country.country_name}</Text>
      <TouchableOpacity
                  style={{width:32, height:32, backgroundColor:'#1e76e8', alignItems:'center', justifyContent:'center', borderRadius:6}}
                  onPress={()=> navigation.navigate('EditLocationNav',{
                    userLocationIdentifier: {user_location_id},
                    countryIdentifier:{country_identifier},
                    provinceIdentifier:{province_identifier},
                    cityIdentifier:{city_identifier},
                    address1Param:{address1},
                    address2Param:{address2},
                    zipCodeParam:{zip_code}
                  })}
                >
                  <Feather name='edit' size={16} color='#fff' />
                </TouchableOpacity>
    </View>
    <View style={{flexDirection:'row'}}>
    <Text style={{textAlign:'center'}}>{province.province_name}</Text>
    </View>
    <View style={{flexDirection:'row'}}>
    <Text style={{justifyContent:'center'}}>{city.city_name}</Text>
    </View>
    <View style={{flexDirection:'row'}}>
    <Text style={{justifyContent:'center'}}>{address1}</Text>
    </View>
    {(address2)?(
      <View style={{flexDirection:'row'}}>
      <Text style={{justifyContent:'center'}}>{address2}</Text>
      </View>
    ):null}
    {(zip_code)?(
      <View style={{flexDirection:"row"}}>
      <Text style={{justifyContent:'center'}}>{zip_code}</Text>
      </View>
    ):null}
    </View>
  
  );

  /**Item renderer */
  const renderItem = ({ item }) => <Item country={item.country} 
  province={item.province} 
  city={item.city} 
  address1={item.address1}
  address2={item.address2}
  zip_code={item.zip_code}
  user_location_id={item.user_location_id}
  country_identifier={item.country_id}
  province_identifier={item.province_id}
  city_identifier={item.city_id}
    />;
  // const DATA = [
  //   {
  //     id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
  //     title: 'First Item',
  //   },
  //   {
  //     id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
  //     title: 'Second Item',
  //   },
  //   {
  //     id: '58694a0f-3da1-471f-bd96-145571e29d72',
  //     title: 'Third Item',
  //   },
  // ];
    return(
    <SafeAreaView style={{flex:1,paddingTop:-45, paddingBottom:-45}}>
        <View style={{paddingHorizontal:5, paddingTop:5,}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
              {i18n.t('userSettings.locations.title')}
            </Text>
          </View>
        </View>
        <View style={{paddingHorizontal:5}}>
        <TouchableOpacity
        // bg-gray-200 rounded-lg py-3 mt-5 items-center justify-center'
            style={{backgroundColor:'#e0e0e0', paddingVertical:15, marginTop:5, alignItems:'center', justifyContent:'center', borderRadius:6,}}
            onPress={() => navigation.navigate('AddLocationNav')}
          >
                <View style={{flexDirection:'row'}}>
              <Text style={{textAlign:'center', fontWeight:'bold', justifyContent:'center'}}>
              {i18n.t('userSettings.locations.btn_add')}
              
            </Text>
                </View>
          </TouchableOpacity>

          <View style={{flexDirection:'row', paddingTop:10}}>
              <Text style={{fontSize:18, fontWeight:'bold'}}>
                {i18n.t('userSettings.locations.body')}
              </Text>
          </View>
          {(isFocused)?(
            <View style={{flexDirection:'row'}}>
            {(locationData)?(
                <FlatList data={locationData.user_locations}
                
                renderItem={renderItem} keyExtractor={item => item.user_location_id.toString()} />
            ):null}
          </View>
          ):null}
        </View>
      </SafeAreaView>
    )  
}

export default Locations;