import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert,TextInput, TouchableOpacity, Modal} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMutation, useQuery } from '@apollo/client';
import { GET_COUNTRIES, UPDATE_USER_LANGUAGE } from '../../lib/queries';
import i18n from '../../i18n/i18n';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StatusBar } from 'expo-status-bar';
import { getAuth } from 'firebase/auth';
import Firebase from '../../lib/firebase';
import { useIsFocused } from '@react-navigation/native';

const auth = getAuth(Firebase);


const Country = ({navigation, route}) => {

    const isFocused = useIsFocused();

    const {name} = route.params;
    const {lastname} = route.params;
    // const {phone} = route.params;

    /**Queries */
    const {loading: loadingCountries, data: dataCountries, refetch: refetchCountries} = useQuery(GET_COUNTRIES);

    //mutation
    const [updateUserLanguage, {loading: loadingUpdateUserLanguage}] = useMutation(UPDATE_USER_LANGUAGE);

    /**State */
    const [countries, setCountries] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);

    const uid = getAuth().currentUser.uid;
    useEffect(() => {
        if(isFocused){
            if(dataCountries){
                const {countries} = dataCountries;
                setCountries(countries);
                // console.log('countries', countries);
            }
        }
    }, [dataCountries, countries])
    return(
        <SafeAreaView style={{flex:1}}>
            <StatusBar style='dark' backgroundColor='white'/>
                    <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
                        </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>
            <Text style={{fontSize:36, fontWeight:'bold'}}>
                {i18n.t('setup.country.title')}
            </Text>
            </View>
            <View style={{paddingTop:25}}>
                <Text style={{fontSize:22}}>
                    {i18n.t('setup.country.body')}
                </Text>
            </View>
        </View>
        <View style={{flex:2}}>
            {/* <TextInput style={{paddingHorizontal:12, backgroundColor:getColor('gray-200'), paddingVertical:12,
             marginHorizontal:15, fontSize:18}} /> */}
    {(!loadingCountries && countries)?(
        <Picker
        selectedValue={selectedCountry}
        itemStyle={{height: 100}}
    onValueChange={(itemValue, itemIndex) => {
        setSelectedCountry(itemValue)
        if(itemValue === 3 || itemValue === 4 || itemValue === 5 || itemValue === 6 || itemValue === 7){
            const value = "es";
            // setLanguageCode(value);
            i18n.locale = value;
            AsyncStorage.setItem('language', 'en');
            updateUserLanguage({
                variables:{
                    uid: uid,
                    languageCode: value
                }
            }).then((res) => {
                console.log('res', res);
            }).catch((err) => {
                console.log('err', err);
            })

          }else if(itemValue === 1 || itemValue === 8){
            const value = "en";
            // setLanguageCode(value);
            i18n.locale = value;
            AsyncStorage.setItem('language', 'en');
            updateUserLanguage({
                variables:{
                    uid: uid,
                    languageCode: value
                }
            }).then((res) => {
                console.log('res', res);
            }).catch((err) => {
                console.log('err', err);
            })
          }
        }}>
       <Picker.Item label={i18n.t('setup.country.picker_country_label')}
              value="0" key="0"/>
      {
        countries.map((country, index) => {
            return(
                <Picker.Item label={country.country_name} 
                value={country.country_id} key={index}/>
            )
        })
      }


  </Picker>
    ): null}
            
            
        </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{
                if(selectedCountry != 0){
                    navigation.navigate('ProvinceScreen',{
                        countryId: selectedCountry,
                        name,
                        lastname,
                        // phone,
                    })
                }else{
                    Alert.alert(i18n.t('setup.country.error'), i18n.t('setup.country.error_body'));
                    if(Platform.OS === 'web'){
                        setMessageTitle(i18n.t('setup.country.error'));
                        setMessageBody(i18n.t('setup.country.error_body'));
                        setModalVisibilityAlert(true);
                    }

                }

                }}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>
                    {i18n.t('setup.country.btn_next')}
                </Text>
            </TouchableOpacity> 
            </View>
        </KeyboardAvoidingView>
              
    </SafeAreaView>
    )
    
}

export default Country;