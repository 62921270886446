import React, { useState, useEffect, useCallback } from 'react';
import { View, Text,  TouchableOpacity, KeyboardAvoidingView, FlatList ,ImageBackground, Platform, TextInput, TouchableWithoutFeedback} from 'react-native';
// import {  } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES , GET_SEARCH_RESULTS} from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import i18n from '../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as Localization from 'expo-localization';
import * as Location from 'expo-location';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { useIsFocused } from '@react-navigation/native';

const auth = getAuth(Firebase);


const Results = ({navigation, route}) => {
  const isFocused = useIsFocused();
  const {searchText} = route.params;
  const {latitude, longitude} = route.params;
  const {distance} = route.params;
  const jsonGeography ={
    "type": "Point",
    "coordinates": [
      longitude,
      latitude
    ]
  }

  /**Add % at the start and end of the searchText */

  const searchTextWithPercentage = `%${searchText}%`;

  const [uid, setUid] = useState('');
  const [products, setProducts] = useState([]);


  // const uid = getAuth().currentUser.uid;
  /**Query get_categories */
    const {loading:resultsLoading, data:resultsData, error:resultsError, refetch} = useQuery(GET_SEARCH_RESULTS, {variables:{uid, query:searchTextWithPercentage, point:jsonGeography, distance}});

    useEffect(() => {
      console.log("ERROR: ", resultsError)
      async function getResultsData(){
        if(resultsData){
          const {search_products} = resultsData;
          setProducts(search_products)
          console.log("user_products: ", search_products)
        }
      }

      getAuth().onAuthStateChanged(async function(user) {
        if (user) {
          // User is signed in.
          setUid(user.uid)
          getResultsData();
        } else {
          // No user is signed in.
          setUid('')
          getResultsData();
        }
      });

      if(isFocused){
        getResultsData();
        refetch();
      }
      
    })

    function Descriptions({item, product_description}){
      return(
        <View style={{flexShrink:1}}>
          <Text style={{flexShrink:1, fontFamily:"Inter-Regular", fontSize:16}}>{product_description}</Text>
        </View>
      )
    }

    function renderDescriptions({item}){
      return(
        <Descriptions item={item}
        product_description={item.product_description}
        />
      )
    }


    function ProductItem({item, product_photo_portrait, product_title, product_descriptions, product_id, seller_id, organization_id}) {
      return(
        <TouchableOpacity style={{ borderColor:'#78909C', borderWidth:1,  borderRadius:6,marginBottom:6, marginHorizontal:6}}
        onPress={()=>{
          navigation.navigate('ProductDetailed',{
            productId: product_id,
            sellerId: seller_id,
            organization_id: organization_id? organization_id : 0
          })
        }}>
          {/* {console.log(product_descriptions)} */}
          <Text style={{fontFamily:'Inter-Medium', fontSize:18}}>{product_title}</Text>
          <View style={{flexDirection:'row', flexShrink:1}}>
          <ImageBackground source={{uri: product_photo_portrait}} resizeMode='contain' style={{paddingVertical:5, width:85, height:85, overflow:'hidden', borderRadius:6}}></ImageBackground>
          <FlatList key={'product_description'} style={{flexShrink:1, paddingHorizontal:10}} listKey={product_id} renderItem={renderDescriptions} data={product_descriptions} keyExtractor={item => item.product_description_id.toString()}/>
          </View>
        </TouchableOpacity>
      )
    }


    function renderItem({item}){
      return(
        <ProductItem item={item}
        product_photo_portrait={item.product.product_photo_portrait}
        product_title={item.product.product_translations[0].product_title}
        product_descriptions={item.product.product_descriptions}
        product_id={item.product.product_id}
        seller_id={item.user.uid}
        organization_id={item.product.organization_products[0] ? item.product.organization_products[0].organization_id : null}
        />
      )
    }

    
    function header() {
      return(
        <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
        <View style={{paddingTop:5, paddingHorizontal:5, flex:1}}>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <Text style={{fontFamily:'Inter-Medium', fontSize:24}}>
            {i18n.t('search.results.title')}
        </Text>
        </View>
    </View>
    </KeyboardAvoidingView>
      )
    }

    return(
      <SafeAreaView style={{flex:1, paddingVertical:-45}}>

  <FlatList ListHeaderComponent={header()} data={products} renderItem={renderItem} keyExtractor={item => item.product_id.toString()}></FlatList>

</SafeAreaView>
    )
    
}

export default Results;