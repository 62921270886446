import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Modal, TouchableWithoutFeedback, TouchableOpacity, Alert, useWindowDimensions, Platform,ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_ORDER_BY_BUYER_ID, GET_PRODUCTS_BY_ORDER_ID, CREATE_CHAT_FROM_PRODUCT, CREATE_CHAT_MYSELF_FROM_PRODUCT } from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import i18n from '../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as Localization from 'expo-localization';
import MapView, { Marker } from 'react-native-maps';
import { PROVIDER_GOOGLE } from 'react-native-maps';

const auth = getAuth(Firebase);

const Orders = ({navigation}) => {

    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();    

    // const uid = getAuth().currentUser.uid
    const [orders, setOrders] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [latitudeState, setLatitudeState] = useState(0);
    const [longitudeState, setLongitudeState] = useState(0);
    const [regionLatitude, setRegionLatitude] = useState(0);
    const [regionLongitude, setRegionLongitude] = useState(0);
    const [modalVisibleMap, setModalVisibleMap] = useState(false);
    const [latitudeDelta, setLatitudeDelta] = useState(0.0022);
    const [longitudeDelta, setLongitudeDelta] = useState(0.0022);
    const [uid, setUid] = useState('');
    const {loading: loadingOrders, data: dataOrders, error:errorOrders} = useSubscription(GET_ORDER_BY_BUYER_ID, {variables:{customerUid:uid, languageCode}});

    /**Mutation */
    const [createChat, {loading:createChatLoading, data:createChatData, error:createChatError}] = useMutation(CREATE_CHAT_FROM_PRODUCT);
    const [createChatMyself, {loading:createChatMyselfLoading, data:createChatMyselfData, error:createChatMyselfError}] = useMutation(CREATE_CHAT_MYSELF_FROM_PRODUCT);
    useEffect(() => {
        console.log(errorOrders);

        async function getOrders(){
            let newOrders;
            if(dataOrders){
                // console.log(dataOrders);
                const {orders} = dataOrders;
                newOrders = orders
                // console.log(newOrders);
            }
    
            setOrders(newOrders);
        }

        getAuth().onAuthStateChanged(async function(user) {
            if(user){
                setUid(user.uid);
                getOrders();
            }else{
                setUid('');
                getOrders();
            }
        });
        
    })

    let screenWidth
    let numColumns
    let itemWidth
  
    if(Platform.OS === 'ios' || Platform.OS === 'android'){
      screenWidth =  useWindowDimensions().width;
      numColumns = 3;
      itemWidth = screenWidth / numColumns;
    }else if(Platform.OS === 'web'){
      // screenWidth = window.innerWidth;
      // numColumns = 3;
      // itemWidth = screenWidth / numColumns;
  
      screenWidth = useWindowDimensions().width;
  
      if(screenWidth > 1200){
        numColumns = 6;
        itemWidth = screenWidth / numColumns;
      }else if(screenWidth > 768){
        numColumns = 5;
        itemWidth = screenWidth / numColumns;
      }
      else{
        numColumns = 2;
        itemWidth = screenWidth/numColumns;
      }
    }
  

    const OrderItem = ({item, order_id, delivery_option_name, payment_option_name, country_name, city_name, sellerExpoToken,
        province_name, address1, address2, zipCode, transaction_total, product_currency, order_status, transaction_status,
    user_name, user_lastname, onPress, order_delivered, order_delivered_label, userId, user_chat_id, delivery_option_id, 
    order_delivery_user, order_delivery_user_chat, order_delivery_user_location, order_delivery_fees, order_delivery_user_name, order_delivery_user_lastname,
    order_delivery_user_expo_token, user_has_chat_myself, user_has_chat_deliverer_myself,
    order_transaction_fees, order_transaction_seller_fees, order_status_color, order_type_id, currency_code, day_name, time_ordered, date_ordered}) => {
        return (
            <View style={{borderRadius:6, borderWidth:1, borderColor:'#B0BEC5', paddingVertical:15, marginVertical:2, flexShrink:1}}>
                {(order_type_id === 1)?(
                    <>
                     <Text style={{fontSize:24, fontFamily:'Inter-Black', paddingHorizontal:9}}>{i18n.t('orders.order_id')}{order_id}</Text>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.seller')}</Text>
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>{" "+user_name} {user_lastname}</Text>
                </View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10, flexShrink:1}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.delivery_type')}</Text>
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular', flexShrink:1}}>{" "+delivery_option_name}</Text>
                </View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.payment_type')}</Text>   
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}> {payment_option_name}</Text>
                </View>

                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.order_status')} </Text>
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular', backgroundColor:order_status_color, textAlign:'center', paddingHorizontal:10, borderRadius:6}}>{order_status}</Text>
                </View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.transactions_status')}</Text>
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}> {transaction_status}</Text>
                </View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.order')} </Text>
                    {(item.order_delivered) ?(
                        <Text style={{fontSize:18, fontFamily:'Inter-Regular', backgroundColor:'#81C784', paddingHorizontal:5, borderRadius:6}}>{order_delivered_label}</Text>
                    ):(
                        <Text style={{fontSize:18, fontFamily:'Inter-Regular', backgroundColor:'#EF9A9A', paddingHorizontal:5, borderRadius:6}}>{order_delivered_label}</Text>
                    )}
                </View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.transaction_delivery_fees')}</Text>
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}> {order_delivery_fees} {product_currency}</Text>
                </View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.transaction_fees')}</Text>
                    <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>{(order_transaction_fees + order_transaction_seller_fees).toFixed(2)} {product_currency}</Text>
                </View>
            <View style={{flexDirection:'row', alignItems:'center', flexGrow:1 ,paddingHorizontal:9, paddingTop:10}}>
                <TouchableOpacity style={{paddingVertical:9, backgroundColor:'#304FFE',flexGrow:1, marginRight:6, borderRadius:6 }}
                onPress={()=>{
                    navigation.navigate('OrderDetails',{
                        order_id,
                    })
                }}>
                    <Text style={{textAlign:'center', color:'#FFFFFF', paddingHorizontal:2, fontFamily:'Inter-Bold', }}>{i18n.t('orders.btn_order_details')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{alignItems:'center' ,paddingHorizontal:15, backgroundColor:'#000000', paddingVertical:9, marginHorizontal:6, borderRadius:6}}
                onPress={()=>{
                    if(userId === uid){
                        if(user_has_chat_myself === null){
                            console.log("NO CHAT")
                            createChatMyself({
                                variables:{
                                    senderId:uid,
                                }
                            }).then(res=>{
                                const chatMyselfId =  res.data.insert_chat_one.chat_id;
                                    const sellerId = userId;
                                    navigation.navigate('Messages', {
                                        sellerId,
                                        chatId:chatMyselfId,
                                        expoToken:sellerExpoToken,
                                    })
                            })
                        }else{
                            console.log("CHAT")
                            const chatMyselfId = user_has_chat_myself.user_chat_chat_id;
                            navigation.navigate('Messages', {
                                chatId:chatMyselfId,
                                expoToken:sellerExpoToken,
                            })
                        }
                    }else{
                        if(user_chat_id === null){
                            // Alert.alert("No chat available")
    
                            createChat({
                                variables:{
                                    senderId:uid,
                                    recipientId:userId,
                                }
                            }).then(res=>{
                                // console.log(res);
                                const chatSellerId = res.data.insert_chat_one.chat_id;
                                const sellerId = userId;
                                navigation.navigate('Messages', {
                                    sellerId,
                                    chatId:chatSellerId,
                                    expoToken: sellerExpoToken,
                                })
                                refetch();
                            }).catch(err=>{
                                // console.log(err);
                            })
                        }else{
                            const userChatId = user_chat_id.user_chat_chat_id;
                            console.log(userChatId);
                            navigation.navigate('Messages', {
                                chatId:userChatId,
                                expoToken: sellerExpoToken,
                            })
                        }
                    }
                }}>
                    <Text style={{color:'white', fontWeight:'bold', textAlign:'center'}}>Chat</Text>
                </TouchableOpacity>
                <Text style={{fontWeight:'bold', fontSize:18, textAlign:'right'}}>Total: </Text>
                <Text style={{fontSize:18, textAlign:'right'}}>{((transaction_total*100)/100).toFixed(2)} {product_currency}</Text>
            </View>
                {(delivery_option_id === 4 && !order_delivered)?(
                    <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                    <TouchableOpacity style={{flex:1}} onPress={()=>{
                        if(order_delivery_user === null){
                            Alert.alert(i18n.t('orders.deliverer_not_found'), i18n.t('orders.deliverer_not_found_body'))
                        }else{
                            // Alert.alert("Delivery user: "+order_delivery_user)

                            console.log(order_delivery_user_location.coordinates);
                            // setLatitudeState(order_delivery_user_location.coordinates[0]);
                            // setLongitudeState(order_delivery_user_location.coordinates[1]);
                            setLongitudeState(order_delivery_user_location.coordinates[0]);
                            setLatitudeState(order_delivery_user_location.coordinates[1]);
                            setRegionLongitude(order_delivery_user_location.coordinates[0]);
                            setRegionLatitude(order_delivery_user_location.coordinates[1]);
                            setLatitudeDelta(0.0025);
                            setLongitudeDelta(0.0021);
                            setModalVisibleMap(true);

                            // setModalVisibleMap(true);
                        }
                    }}>
                        <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.btn_view_deliverer')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{flex:1}} onPress={()=>{
                        if(order_delivery_user === uid  && user_has_chat_deliverer_myself === null){
                            createChatMyself({
                                variables:{
                                    senderId:uid,
                                }
                            }).then(res=>{
                                // console.log(res);
                                const chatMyselfId = res.data.insert_chat_one.chat_id;
                                navigation.navigate('Messages', {
                                    chatId:chatMyselfId,
                                    expoToken:order_delivery_user_expo_token,
                                })

                            })
                        }else if(order_delivery_user === uid && user_has_chat_deliverer_myself !== null){
                            console.log("CHAT DELIVERER MYSELF: "+user_has_chat_deliverer_myself)
                            console.log("EXPOTOKEN DELIVERER MYSELF: "+order_delivery_user_expo_token)
                            const chatMyselfId = user_has_chat_deliverer_myself;
                            navigation.navigate('Messages', {
                                chatId:chatMyselfId,
                                expoToken:order_delivery_user_expo_token,
                            })
                        }else if(order_delivery_user !== uid && order_delivery_user_chat === null && order_delivery_user !== null){
                            console.log("IF 3")
                            createChat({
                                variables:{
                                    senderId:uid,
                                    recipientId:order_delivery_user,
                                }
                            }).then(res=>{
                                // console.log(res);
                                const chatSellerId = res.data.insert_chat_one.chat_id;
                                const sellerId = order_delivery_user;
                                console.log('DELIVERER EXPOTOKEN: '+order_delivery_user_expo_token);
                                navigation.navigate('Messages', {
                                    sellerId,
                                    chatId:chatSellerId,
                                    expoToken: order_delivery_user_expo_token,
                                })
                            }).catch(err=>{
                                // console.log(err);
                            })
                        }else if(order_delivery_user !== uid && order_delivery_user_chat !== null && order_delivery_user !== null){
                            console.log("IF 4")
                            const userChatId = order_delivery_user_chat;
                            console.log("KUUUUU");
                            navigation.navigate('Messages', {
                                chatId:userChatId,
                                expoToken: order_delivery_user_expo_token,  
                            })
                            console.log(userChatId);
                        }else{
                            Alert.alert(i18n.t('orders.deliverer_not_found'), i18n.t('orders.deliverer_not_found_body'))
                        }

                        // if(order_delivery_user_chat === null && order_delivery_user !== null){
                        //     createChat({
                        //         variables:{
                        //             senderId:uid,
                        //             recipientId:userId,
                        //         }
                        //     }).then(res=>{
                        //         // console.log(res);
                        //         const chatSellerId = res.data.insert_chat_one.chat_id;
                        //         const sellerId = userId;
                        //         console.log('DELIVERER EXPOTOKEN: '+order_delivery_user_expo_token);
                        //         navigation.navigate('Messages', {
                        //             sellerId,
                        //             chatId:chatSellerId,
                        //             expoToken: order_delivery_user_expo_token,
                        //         })
                        //         refetch();
                        //     }).catch(err=>{
                        //         // console.log(err);
                        //     })
                        // }else if(order_delivery_user_chat !== null && order_delivery_user !== null){
                        //     const userChatId = order_delivery_user_chat;
                        //     console.log("KUUUUU");
                        //     navigation.navigate('Messages', {
                        //         chatId:userChatId,
                        //         expoToken: order_delivery_user_expo_token,  
                        //     })
                        //     console.log(userChatId);
                        // }else{
                        //     Alert.alert(i18n.t('orders.deliverer_not_found'), i18n.t('orders.deliverer_not_found_body'))
                        // }
                    }}>
                        <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.btn_chat_with_deliverer')}</Text>
                    </TouchableOpacity>
                </View>
                ):null}

                {(delivery_option_id === 4 && order_delivered)?(
                    <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                        <View style={{flex:1}}>
                            <Text style={{fontFamily:'Inter-Medium'}}>{i18n.t('orders.order_delivered_by')} {order_delivery_user_name + ' ' +order_delivery_user_lastname}</Text>
                        </View>
                        <View style={{flex:1}}>
                        <TouchableOpacity style={{}} onPress={()=>{
                                                if(order_delivery_user === uid  && user_has_chat_deliverer_myself === null){
                                                    createChatMyself({
                                                        variables:{
                                                            senderId:uid,
                                                        }
                                                    }).then(res=>{
                                                        // console.log(res);
                                                        const chatMyselfId = res.data.insert_chat_one.chat_id;
                                                        navigation.navigate('Messages', {
                                                            chatId:chatMyselfId,
                                                            expoToken:order_delivery_user_expo_token,
                                                        })

                                                    })
                                                }else if(order_delivery_user === uid && user_has_chat_deliverer_myself !== null){
                                                    console.log("CHAT DELIVERER MYSELF: "+user_has_chat_deliverer_myself)
                                                    console.log("EXPOTOKEN DELIVERER MYSELF: "+order_delivery_user_expo_token)
                                                    const chatMyselfId = user_has_chat_deliverer_myself;
                                                    navigation.navigate('Messages', {
                                                        chatId:chatMyselfId,
                                                        expoToken:order_delivery_user_expo_token,
                                                    })
                                                }else if(order_delivery_user !== uid && order_delivery_user_chat === null && order_delivery_user !== null){
                                                    console.log("IF 3")
                                                    createChat({
                                                        variables:{
                                                            senderId:uid,
                                                            recipientId:order_delivery_user,
                                                        }
                                                    }).then(res=>{
                                                        // console.log(res);
                                                        const chatSellerId = res.data.insert_chat_one.chat_id;
                                                        const sellerId = order_delivery_user;
                                                        console.log('DELIVERER EXPOTOKEN: '+order_delivery_user_expo_token);
                                                        navigation.navigate('Messages', {
                                                            sellerId,
                                                            chatId:chatSellerId,
                                                            expoToken: order_delivery_user_expo_token,
                                                        })
                                                    }).catch(err=>{
                                                        // console.log(err);
                                                    })
                                                }else if(order_delivery_user !== uid && order_delivery_user_chat !== null && order_delivery_user !== null){
                                                    console.log("IF 4")
                                                    const userChatId = order_delivery_user_chat;
                                                    console.log("KUUUUU");
                                                    navigation.navigate('Messages', {
                                                        chatId:userChatId,
                                                        expoToken: order_delivery_user_expo_token,  
                                                    })
                                                    console.log(userChatId);
                                                }else{
                                                    Alert.alert(i18n.t('orders.deliverer_not_found'), i18n.t('orders.deliverer_not_found_body'))
                                                }
                        
                    }}>
                        <Text style={{fontFamily:'Inter-Black', fontSize:18}}>{i18n.t('orders.btn_chat_with_deliverer')}</Text>
                    </TouchableOpacity>
                        </View>
                    </View> 
                ):null}
                    </>
                ):null}
                {(order_type_id === 2)?(
                    <>
                    <Text style={{fontSize:22, fontFamily:'Inter-Black', paddingHorizontal:9}}>{i18n.t('ordersCustomers.order_id')} #{order_id}</Text>
                    <View>
                        <Text style={{paddingHorizontal:9, fontSize:18}}>
                            {i18n.t("ordersCustomers.transaction_description", {
                                transaction_total: transaction_total,
                                user_name : user_name,
                                currency_code: currency_code,
                            })}
                        </Text>
                        <Text style={{alignContent:'flex-end', alignItems:'flex-end', textAlign:'right', fontFamily:'Inter-Light', paddingRight:8}}>
                            {day_name} {date_ordered} {time_ordered}
                        </Text>
                    </View>
                    </>
                ):null}
            </View>
            
        )
    }

    const renderOrders = ({item}) =>{
        const timeOrder = new Date(item.created_at);
        const timeOrdered = timeOrder.toLocaleTimeString();
        const dateOrdered = timeOrder.toLocaleDateString();
        
        /**get Day name */
        const day = timeOrder.getDay();

        // const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const dayNames = [i18n.t('orders.order_detailed.sunday'), 
        i18n.t('orders.order_detailed.monday'), 
        i18n.t('orders.order_detailed.tuesday'),
        i18n.t('orders.order_detailed.wednesday'), 
        i18n.t('orders.order_detailed.thursday'), 
        i18n.t('orders.order_detailed.friday'), 
        i18n.t('orders.order_detailed.saturday')];


        const dayName = dayNames[day];

        const order_delivered_label = item.order_delivered ? i18n.t('orders.delivered') : i18n.t('orders.not_delivered')
        
        let user_has_chat;
        
        user_has_chat = item['user_orders'][0] ? item['user_orders'][0]['user']['user_chats'][0] : undefined;

        if(user_has_chat === undefined){
            user_has_chat = null;
        }

        //chat myself

        let user_has_chat_myself;

        user_has_chat_myself = item['user_orders'][0] ? item['user_orders'][0]['user']['chat_myself'][0] : undefined;

        if(user_has_chat_myself === undefined){
            user_has_chat_myself = null;
        }

        const onPress = () => {
            // console.log('sup');
            setModalVisible(true);
        }

        let user_has_chat_deliverer;

        user_has_chat_deliverer = item['userByOrderDeliveryUser'] ? item['userByOrderDeliveryUser']['user_chats'].length > 0 ? item['userByOrderDeliveryUser']['user_chats'][0]['user_chat_chat_id'] : undefined:  undefined;

        if(user_has_chat_deliverer === undefined){
            user_has_chat_deliverer = null;
        }

        //chat delivery myself

        let user_has_chat_deliverer_myself;

        user_has_chat_deliverer_myself = item['userByOrderDeliveryUser'] ? item['userByOrderDeliveryUser']['chat_myself'].length > 0 ? item['userByOrderDeliveryUser']['chat_myself'][0]['user_chat_chat_id'] : undefined:  undefined;
        // console.log('CHAT DELIVERER MYSELF: '+user_has_chat_deliverer_myself)
        if(user_has_chat_deliverer_myself === undefined){
            user_has_chat_deliverer_myself = null;
        }

        let order_status_color; 

        if(item['order_status_id'] === 1){
            //cancelado
            order_status_color = '#E57373';
        }else if(item['order_status_id'] === 2){
            //verificando
            
            order_status_color = '#FFA500';
        }else if(item['order_status_id'] === 3){
            //fallido
            order_status_color = '#E57373';

        }else if(item['order_status_id'] === 4){
            //pagado green
            order_status_color = '#A5D6A7';
        }else if(item['order_status_id'] === 5){
            //sin pagar
            order_status_color = '#FF0000';
        }



        
        return(
            <OrderItem item={item} 
            order_id={item.order_id}
            order_delivered={item.order_delivered}
            order_delivered_label={order_delivered_label}
            delivery_option_name={item['country_delivery_option'] ?  item['country_delivery_option']['delivery_option'] ? item['country_delivery_option']['delivery_option']['delivery_option_translations'][0].delivery_option_name : null : null}
            payment_option_name={item['country_payment_option']['payment_option']['payment_option_translations'][0].payment_option_name}
            delivery_option_id={item['country_delivery_option'] ? item['country_delivery_option']['delivery_option_id'] : null}
            address1={item['user_location'] ? item['user_location'].address1 : null}
            address2={item['user_location'] ? item['user_location'].address2 : null}
            city_name={item['user_location'] ? item['user_location']['city'].city_name : null}
            zipCode={item['user_location'] ? item['user_location'].zip_code : null}
            province_name={item['user_location'] ? item['user_location']['province'].province_name : null}
            // transaction_total={item['transactions'][0].transaction_total}
            transaction_total={item['transactions_aggregate'].aggregate.sum.transaction_total}
            product_currency={item['transactions'][0] ? item['transactions'][0].product_currency ? item['transactions'][0].product_currency.currency_code : null : null}
            order_status={item['order_status']['order_status_translations'][0].order_status_name}
            transaction_status={item['transactions'][0]? item['transactions'][0]['transaction_status']['transaction_status_translations'][0].transaction_status_name : null}
            user_name={item['user_orders'][0]? item['user_orders'][0]['user'].user_name : null}
            user_lastname={item['user_orders'][0]? item['user_orders'][0]['user'].user_lastname : null}
            userId={item['user_orders'][0] ? item['user_orders'][0]['user'].uid: null}
            onPress={onPress}
            user_chat_id={user_has_chat}
            order_delivery_user={item['order_delivery_user']}
            order_delivery_user_name={item['userByOrderDeliveryUser'] ? item['userByOrderDeliveryUser']['user_name']: null}
            order_delivery_user_lastname={item['userByOrderDeliveryUser'] ? item['userByOrderDeliveryUser']['user_lastname']: null}
            order_delivery_user_chat={user_has_chat_deliverer}
            order_delivery_user_location={item['userByOrderDeliveryUser'] ? item['userByOrderDeliveryUser']['user_location']: null}
            sellerExpoToken={item['user_orders'][0]? item['user_orders'][0]['user'].user_expo_token : null}
            order_delivery_fees={item['order_delivery_fees']}
            order_delivery_user_expo_token={item['userByOrderDeliveryUser'] ? item['userByOrderDeliveryUser']['user_expo_token']: null}
            user_has_chat_myself={user_has_chat_myself}
            user_has_chat_deliverer_myself={user_has_chat_deliverer_myself}
            order_transaction_fees={item['transactions'][0] ? item['transactions'][0]['transaction_fee'] : null}
            order_transaction_seller_fees={item['transactions'][0] ? item['transactions'][0]['transaction_seller_fee'] : null}
            order_status_color={order_status_color}
            order_type_id={item['order_type_id']}
            currency_code={item['transactions'][0] ? item['transactions'][0]['transaction_currency_code'] : null}
            date_ordered={dateOrdered}
            time_ordered={timeOrdered}
            day_name={dayName}
            // order_delivery_user_chat={item['userByOrderDeliveryUser']['user_chats'][0]['user_chat_chat_id']}
            />

        )
    }

    return(
        <SafeAreaView style={{flex:1, paddingTop:-45}}>
        <Modal visible={modalVisibleMap}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibleMap(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>

              <View
              style={{backgroundColor:'#fff', borderRadius:10, justifyContent:'center', alignItems:'center'}}
              >
                 {(Platform.OS === 'web')?(
                  <MapView  style={{height:500, width:500}}
                  region={{
                    latitude: regionLatitude,
                    longitude: regionLongitude,
                    latitudeDelta: latitudeDelta,
                    longitudeDelta: longitudeDelta,

                  }}
                  onRegionChangeComplete={(region)=>{
                    // console.log(region);
                    setRegionLatitude(region.latitude)
                    setRegionLongitude(region.longitude)
                  }}
                  onPress={async (e) =>{
                    let data = await e;
                    // console.log(data);

                    let lat = await data.latLng.lat;

                    // console.log(lat());

                    let lng = await data.latLng.lng;
                    // console.log(lng());
                    setLatitudeState(lat)
                    setLongitudeState(lng)

                  }}
                  onPoiClick={(e)=>{
                    let data = decycle(e);
                    // console.log(data.latLng.lat);
                  }}
                  >
                  <MapView.Marker
                  key={1}
                  coordinate={{
                    latitude: latitudeState,
                    longitude: longitudeState,
                  }}
                  title={i18n.t('home.my_location')}
                  description={i18n.t('home.my_location')}
                  />
                
                </MapView>

                 ):(Platform.OS === 'android' || Platform.OS === 'ios')?(
                  <MapView
                  provider={PROVIDER_GOOGLE}
                  style={{height:500, width:screenWidth}}
                  region={{
                    latitude: regionLatitude,
                    longitude: regionLongitude,
                    latitudeDelta: latitudeDelta,
                    longitudeDelta: longitudeDelta,
                  }}

                  onRegionChangeComplete={(region)=>{
                    // console.log(region);
                    setRegionLatitude(region.latitude)
                    setRegionLongitude(region.longitude)
                    setLatitudeDelta(region.latitudeDelta)
                    setLongitudeDelta(region.longitudeDelta)
                  }}
                  onPress={(e) =>{
                    let data = e;
                    // console.log(e.nativeEvent.coordinate);

                    let lat = data.nativeEvent.coordinate.latitude;
                    let lng = data.nativeEvent.coordinate.longitude;
                    setLatitudeState(lat)
                    setLongitudeState(lng)
                  }}
                  // onPoiClick={(e)=>{
                  //   let data = decycle(e);
                  //   console.log(data.nativeEvent.coordinate);
                  // }}
                  >
                  <Marker
                  key={1}
                  coordinate={{
                    latitude: latitudeState,
                    longitude: longitudeState,
                  }}
                  title={i18n.t('home.my_location')}
                  description={i18n.t('home.my_location')}
                  
                  ></Marker>
                  </MapView>

                 ):null}
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibleMap(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>
        <View style={{flex:1, paddingHorizontal:9}}>
            <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
                {i18n.t('orders.title')}
            </Text>
            {(loadingOrders)?(
                <View style={{flex:1, justifyContent:'center'}}>
                <ActivityIndicator size="large" color={'#3D5AFE'} />
                </View>
            ):(
                <FlatList data={orders} renderItem={renderOrders}
            keyExtractor={item => item.order_id.toString()}
            ></FlatList>
            )}
            
        </View>
    </SafeAreaView>
    )
}
export default Orders;