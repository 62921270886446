import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, ImageBackground, Button, Alert, Dimensions, Platform, Image, FlatList} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as ExpoImagePicker from 'expo-image-picker';
import { ImagePicker } from 'expo-image-multiple-picker';
import Modal from 'react-native-modal'

import i18n from '../../i18n/i18n';
import { Feather } from '@expo/vector-icons';

const AddProductGallery = ({navigation, route}) => {

    /**productTitle route.params */
    const {productTitleImage} = route.params
    const {productTitle} = productTitleImage

    const {productSKUImage} = route.params
    const {productSKU} = productSKUImage

    /**productSlug */

    const {productSlugImage} = route.params
    const {productSlug} = productSlugImage

      /**productImage */
  const {productImageImage} = route.params
  const {image}= productImageImage

  /**product subcategoryId */

  const {subCategoryId} = route.params

    const [productGallery, setProductGallery] = useState([]);
    const [modalView, setModalView] = useState(false);


    useEffect(()=>{
        // (async () => {
        //     // if (Platform.OS !== 'web') {
        //       const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        //       if (status !== 'granted') {
        //         alert('Sorry, we need camera roll permissions to make this work!');
        //       }
        //     // }
        //   })();

          // console.log("SUBCAT IMAGE", subCategoryId);
    })


    const openCamera = async ()=>{
      const permissionResult = await ExpoImagePicker.requestCameraPermissionsAsync();
      if (permissionResult.granted === false) {
          alert("You've refused to allow this app to access your camera!");
          return;
        }
    
        const result = await ExpoImagePicker.launchCameraAsync({
            mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [2, 2],
            quality:Platform.OS === 'ios' ? 0 : 1,
        });
    
        // Explore the result
        // console.log("RESULT: ", result);
    
        if (!result.canceled) {
          //add image to array
          console.log(result);
          setProductGallery([...productGallery, result.assets[0]]);
        }
  }

    const openGallery = async ()=>{

        const permissionResult = await ExpoImagePicker.getMediaLibraryPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("You've refused to allow this app to access your camera!");
            return;
          }

          const result = await ExpoImagePicker.launchImageLibraryAsync({
              allowsMultipleSelection: true,
              quality:Platform.OS === 'ios' ? 0 : 1,
              mediaTypes: ExpoImagePicker.MediaTypeOptions.Images,
          });

          // Explore the result
          // console.log("RESULT: ", result);

          if (!result.canceled) {
            
            //add image to array
            console.log("RESULTSSS: ", result);
            setProductGallery([...productGallery, ...result.assets]);
            console.log("PRODUCT GALLERY: ", productGallery);
          }

    }
    const screenWidth = Dimensions.get('window').width;
    const screenHeight = Dimensions.get('window').height;
    return(
        <SafeAreaView style={{flex:1, paddingTop:-45}}>
        <Modal
        onBackButtonPress={()=>setModalView(false)}
        onBackdropPress={()=>setModalView(false)}
        
        isVisible={modalView} style={{flex:1, justifyContent:'center', alignItems:'center'}}>
            <View style={{backgroundColor:'#fff', width:screenWidth, height:screenHeight, borderRadius:10, alignItems:'center'}}>
                {/* Close modal */}
                <TouchableOpacity onPress={()=>setModalView(false)} style={{position:'absolute', top:10, right:10}}>
                    <Feather name="x" size={24} color="black" />
                </TouchableOpacity>


                <ImagePicker 
                onSave={(assets) => {
                    console.log(assets);
                    console.log('assets', assets);
                    setProductGallery(assets)
                    setModalView(false)
                }}
                onCancel={() => console.log('no permissions or user go back')}
                multiple
                limit={10}

              />
            </View>
        </Modal>

        <View style={{flex:1}}>
        <View style={{paddingHorizontal:5}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('products.addProductGallery.title')}
            </Text>
            <Text style={{fontSize:16}}>
                {i18n.t('products.addProductGallery.body')}
            </Text>
        </View>
        <View>
        <View style={{paddingBottom:6}}>
        <Button title={i18n.t('products.addProductGallery.btn_camara')} onPress={()=> {
          openCamera();
        }}></Button>
        </View>
        
        <Button  title={i18n.t('products.addProductGallery.btn_gallery')} onPress={()=> {
          // setModalView(true);
          openGallery();
        }}></Button>
            {/* <Image source={{ uri: image }} resizeMode="contain" imageStyle={{borderRadius:6}} style={{marginTop:10, height:screenWidth, width:screenWidth}}>
        </Image> */}

        </View>
        <FlatList 
        contentContainerStyle={{paddingBottom:100}}
        data={productGallery}
        renderItem={({item})=>(
            <ImageBackground source={{ uri: item.uri }} resizeMode="contain" imageStyle={{borderRadius:6}} style={{marginTop:10, height:screenWidth, width:screenWidth}}>
            {/* //delete button */}
            <TouchableOpacity onPress={()=> {
              const newProductGallery = productGallery.filter((product)=> product.uri !== item.uri)
              setProductGallery(newProductGallery)
            }} style={{position:'absolute', top:10, right:10}}>
                <Feather name="x" size={24} color="black" />
            </TouchableOpacity>


            </ImageBackground>
        )}
        keyExtractor={(item, index)=>index.toString()}
        />
        
        </View>
        <View style={{position:'absolute', bottom:15, flexGrow:1}}>
      <TouchableOpacity
            style={{backgroundColor:'#FF6D00', padding:10, borderRadius:6, marginHorizontal:5, paddingVertical:15, flexGrow:1, width:screenWidth-10}}
            onPress={()=> {if(image){
              navigation.navigate('AddProductPrice', {
                productTitleImage:{productTitle},
                productImageImage:{image},
                productSKUImage:{productSKU},
                productSlugImage:{productSlug},
                subCategoryId,
                productGalleryGallery:{productGallery}
              })
            }
            else{
              // navigation.navigate('AddProductPrice', {
              //   productTitleImage:{productTitle},
              //   productImageImage:{image},
              //   productSKUImage:{productSKU},
              //   productSlugImage:{productSlug}

              // })
              Alert.alert("Error",i18n.t('products.addProductGallery.alert_body'))

            }}
          }
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', fontSize:18, borderRadius:6}}>
              {i18n.t('products.addProductGallery.btn_next')}
            </Text>
          </TouchableOpacity>
        </View>

    </SafeAreaView>
    )

}

export default AddProductGallery;