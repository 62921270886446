import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, ImageBackground, Button, Alert, Dimensions, Platform, Image} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as ImagePicker from 'expo-image-picker';

import i18n from '../../i18n/i18n';

const AddProductImage = ({navigation, route}) => {

    /**productTitle route.params */
    const {subCategoryId} = route.params

    const {productTitleName} = route.params
    const {productTitle} = productTitleName

    const {productSKUName} = route.params
    const {productSKU} = productSKUName

    /**productSlug */
    
    const {productSlugName} = route.params
    const {productSlug} = productSlugName
    
    const [image, setImage] = useState()

    useEffect(()=>{
        (async () => {
              const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
              if (status !== 'granted') {
                alert('Sorry, we need camera roll permissions to make this work!');
              }



              

          })();

          // console.log("SUBCAT IMAGE", subCategoryId);
    })


    const openCamera = async ()=>{
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("You've refused to allow this app to access your camera!");
            return;
          }
      
          const result = await ImagePicker.launchCameraAsync({
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
              allowsEditing: true,
              aspect: [2, 2],
              quality:Platform.OS === 'ios' ? 0 : 1,
          });
      
          // Explore the result
          // console.log("RESULT: ", result);
      
          if (!result.canceled) {
            setImage(result.uri);
            // console.log(result.uri);
          }
    }

    const openGallery = async ()=>{

        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("You've refused to allow this app to access your camera!");
            return;
          }

          const result = await ImagePicker.launchImageLibraryAsync({
              mediaTypes: ImagePicker.MediaTypeOptions.Images,
              allowsEditing: true,
              aspect: [1, 1],
              quality:Platform.OS === 'ios' ? 0 : 1,
          });

          // Explore the result
          // console.log("RESULT: ", result);

          if (!result.canceled) {
            setImage(result.uri);
            // console.log(result.uri);
          }
    }
    const screenWidth = Dimensions.get('window').width;
    return(
        <SafeAreaView style={{flex:1, paddingTop:-45}}>
        <View style={{flex:1}}>
        <View style={{paddingHorizontal:5}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('products.addProductImage.title')}
            </Text>
        </View>
        <View>
          <View>
        <View style={{paddingHorizontal:10}}>
        <Button color={'#FF6D00'} title={i18n.t('products.addProductImage.btn_camara')} onPress={()=> openCamera()}></Button> 
        </View>
        {(Platform.OS === 'android' || Platform.OS === 'ios')?(
          /**Open gallery */
          <View style={{paddingTop:10, paddingHorizontal:10}}>
          <Button color={'#FF6D00'}  title={i18n.t('products.addProductImage.btn_gallery')} onPress={()=> openGallery()}></Button>
          </View>
        ):null}

          </View>
            <Image source={{ uri: image }} resizeMode="contain" imageStyle={{borderRadius:6}} style={{marginTop:10, height:screenWidth, width:screenWidth}}>
        </Image>
        </View>

        </View>
        <View style={{flex:2}}>

        </View>
        <View style={{flex:0.4}}>
      <TouchableOpacity
            style={{backgroundColor:'#FF6D00', padding:10, borderRadius:6, marginHorizontal:5, paddingVertical:15}}
            onPress={()=> {if(image){
              navigation.navigate('AddProductGallery', {
                productTitleImage:{productTitle},
                productImageImage:{image},
                productSKUImage:{productSKU},
                productSlugImage:{productSlug},
                subCategoryId,
              })
            }
            else{
              Alert.alert("Error",i18n.t('products.addProductImage.alert_body'))   
            }}
          }
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', fontSize:18, borderRadius:6}}>
              {i18n.t('products.addProductImage.btn_next')}
            </Text>
          </TouchableOpacity>
        </View>
        
    </SafeAreaView>
    )
    
}

export default AddProductImage;