import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, Text,
  Modal,
  TouchableOpacity, ActivityIndicator, View, TextInput, ImageBackground, TouchableWithoutFeedback, FlatList, Platform, Image, Dimensions, Alert, Keyboard } from 'react-native';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { useQuery, useLazyQuery, useMutation} from '@apollo/client';
import { GET_ORGANIZATION_CART_BY_ORGANIZATION_ID, DELETE_FROM_CART_BY_ID, UPDATE_PRODUCT_QUANTITY, CREATE_ORDER_CASH_ORGANIZATION, SHOPPING_CART_CHECKOUT_BY_ORGANIZATION_ID, GET_ALL_USERS_BY_ORGANIZATION_ID, CREATE_ORDER_CARD_ORGANIZATION, INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT} from '../lib/queries';
import {Feather} from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';
import i18n from '../i18n/i18n';

import { useIsFocused } from '@react-navigation/native';

import { getFunctions, httpsCallable } from 'firebase/functions';

const initPaymentSheet = Platform.select({
  web: () => null,
  native: () => require('@stripe/stripe-react-native').initPaymentSheet,
})()

const presentPaymentSheet = Platform.select({
web: () => null,
native: () => require('@stripe/stripe-react-native').presentPaymentSheet,
})()

const functions = getFunctions(Firebase);

const auth = getAuth(Firebase);


const OrganizationPageCart = ({navigation, route}) => {

    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();


  const {organizationId} = route.params;
   
  const isFocused = useIsFocused();

  /**Firebase */
  // const uid= getAuth().currentUser.uid;

  const [organizationName, setOrganizationName] = useState('');
  const [uid, setUid] = useState();
  const [user, setUser] = useState();
  const [organizationCart, setOrganizationCart] = useState([]);
  const [allUsersByOrganizationId, setAllUsersByOrganizationId] = useState([]);
  const [shoppingCartAggregate, setShoppingCartAggregate] = useState('');
  const [productCurrencyAggregate, setProductCurrencyAggregate] = useState('');
  const [totalProducts, setTotalProducts] = useState('');
  const [total, setTotal] = useState('');
  const [nodes, setNodes] = useState([]);
  const [currencySame, setCurrencySame] = useState(false);
  const [currency, setCurrency] = useState('');
  const [currencyCodeStripeFee, setCurrencyCodeStripeFee] = useState('');
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [selectedDeliveryOptionsPicker, setSelectedDeliveryOptions] = useState(0);
  const [customerCountryPaymentOptions, setCustomerCountryPaymentOptions] = useState('');

  const [deliveryLocations, setDeliveryLocations] = useState([]);
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState(0);

  const [organizationDistance, setOrganizationDistance] = useState(0);
  const [deliveryFees, setDeliveryFees] = useState(0);
  const [selectedDeliveryOptionId, setSelectedDeliveryOptionId] = useState(0);
  const [selectedCountryDeliveryOptionId, setSelectedCountryDeliveryOptionId] = useState(0);
  const [selectedDeliveryOptionName, setSelectedDeliveryOptionName] = useState('');

  const [notes, setNotes] = useState('');
  const [outOfStock, setOutOfStock] = useState(false);
  const [keyboardOffset, setKeyboardOffset] = useState(0);
  const onKeyboardShow = event => setKeyboardOffset(event.endCoordinates.height);
  const onKeyboardHide = () => setKeyboardOffset(0);
  const keyboardDidShowListener = useRef();
  const keyboardDidHideListener = useRef();

  const [modalVisibilityCreateOrderCash, setModalVisibilityCreateOrderCash] = useState(false);
  const [modalVisibilityIndicator, setModalVisibilityIndicator] = useState(false);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);


  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentOptions, setSelectedPaymentOptions] = useState();

  const {loading: loadingOrganizationCart, error: errorOrganizationCart, data: dataOrganizationCart, refetch: refetchOrganizationCart} = useQuery(GET_ORGANIZATION_CART_BY_ORGANIZATION_ID, {variables:{userId: uid, organizationId, languageCode}});
  const {loading: loadingAllUsersByOrganizationId, error: errorAllUsersByOrganizationId, data: dataAllUsersByOrganizationId, refetch: refetchAllUsersByOrganizationId} = useQuery(GET_ALL_USERS_BY_ORGANIZATION_ID, {variables:{organizationId}});

    /**delete from cart by id mutation */

  const [deleteFromCartById, {loading: loadingDeleteFromCartById, error: errorDeleteFromCartById, data: data}] = useMutation(DELETE_FROM_CART_BY_ID)
  const [createOrderCardOrganization, {loading: loadingCreateOrderCardOrganization, error: errorCreateOrderCardOrganization, data: dataCreateOrderCardOrganization}] = useMutation(CREATE_ORDER_CARD_ORGANIZATION);
  const [createUserPaymentOptionFromProduct, {loading:createUserPaymentOptionFromProductLoading, error:createUserPaymentOptionFromProductError}] = useMutation(INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT);


  // /**UPDATE PRODUCT QUANTIY */

  const [updateProductQuantity, {loading: loadingUpdateProductQuantity, error: errorUpdateProductQuantity, data: dataUpdateProductQuantity}] = useMutation(UPDATE_PRODUCT_QUANTITY)

  // /**CREATE ORDER CASH */

  const [createOrderCashOrganization, {loading: loadingCreateOrderCashOrganization, error: errorCreateOrderCashOrganization, data: dataCreateOrderCashOrganization}] = useMutation(CREATE_ORDER_CASH_ORGANIZATION);

  const [shoppingCartCheckout, {loading: loadingShoppingCartCheckout, error: errorShoppingCartCheckout, data: dataShoppingCartCheckout}] = useMutation(SHOPPING_CART_CHECKOUT_BY_ORGANIZATION_ID);

  async function getOrganizationCart(){
    if(dataOrganizationCart){
      // console.log(dataOrganizationCart);

      const {products_aggregate} = dataOrganizationCart

      setOrganizationCart(products_aggregate.nodes);
      

      /**Organization Name */
      const {organization} = dataOrganizationCart;
      setOrganizationName(organization[0].organization_name);
      setOrganizationDistance(organization[0].organization_distance);


      /** Order Summary*/
      const {shopping_cart_aggregate} = dataOrganizationCart;
      const {product_currencies_aggregate} = dataOrganizationCart;

      //customerPaymentOptions
      const {users_by_pk} = dataOrganizationCart;
      const {user_country_payment_options} = users_by_pk;
      
      setCustomerCountryPaymentOptions(user_country_payment_options);

      setShoppingCartAggregate(shopping_cart_aggregate);
      setProductCurrencyAggregate(product_currencies_aggregate);
      setTotalProducts(shopping_cart_aggregate.aggregate.sum.product_quantity);

      /**Product currencies Aggregate */
      setNodes(productCurrencyAggregate.nodes);

      // console.log("NODOS: ", nodes);

      /**Check if all currencies are the same */
      try {
        const allCurrencyEqual = nodes.every(node => node.currency_code === nodes[0].currency_code);

    //    console.log(allCurrencyEqual)

         if(allCurrencyEqual){
            setCurrencySame(true)
            setCurrency(nodes[0].currency_code)
            setCurrencyCodeStripeFee(nodes[0].currency.currency_code_stripe_fee)
            console.log("CURRENCY: ", currency)
            console.log("CURRENCY CODE STRIPE FEE: ", currencyCodeStripeFee)
             }
            else{
            setCurrencySame(false)
            }
        // console.log(currencySame)
       } catch (error) {
            // console.log(error)
       }
          /**Payment and Delivery Options */
          const {country_delivery_options_aggregate} = dataOrganizationCart;

          setDeliveryOptions(country_delivery_options_aggregate.nodes);
    
          const {user_locations} = dataOrganizationCart;
          setDeliveryLocations(user_locations);
    
          const {country_payment_options_aggregate} = dataOrganizationCart;
    
          setPaymentOptions(country_payment_options_aggregate.nodes);
    
          // console.log(organizationCart)
    
          /**Total */
    
          let total = 0;
    
          organizationCart.map(product => {
            total += product.shopping_carts_aggregate.nodes[0].product_price * product.shopping_carts_aggregate.aggregate.sum.product_quantity;
          })
    
          setTotal(total.toFixed(2));
    
          // console.log(total)
    }

    if(dataAllUsersByOrganizationId){
      const {organization_by_pk} = dataAllUsersByOrganizationId;
      const {organization_users} = organization_by_pk;
      setAllUsersByOrganizationId(organization_users);
    }
  }
  

  useEffect(() => {
    keyboardDidShowListener.current = Keyboard.addListener('keyboardWillShow', onKeyboardShow);
    keyboardDidHideListener.current = Keyboard.addListener('keyboardWillHide', onKeyboardHide);

    getAuth().onAuthStateChanged(function(user) {
      if (user) {
        // console.log(user)
        setUid(user.uid);
        setUser(user);
        getOrganizationCart();

        // console.log(uid)
      } else {
        // No user is signed in.
        // console.log("There is no logged in user");

        setUid("");
        setUser(null);
        getOrganizationCart();
      }
    });



    if(isFocused){
        getOrganizationCart();
        refetchOrganizationCart();
        refetchAllUsersByOrganizationId();
    }

    return () => {
      keyboardDidShowListener.current.remove();
      keyboardDidHideListener.current.remove();
    };
  },);

    const CartItem = ({item, index, product_title, product_currency, product_price, product_thumbnail, product_quantity, cart_id_array, product_stock}) => (
            <View style={{paddingTop:10}}>
                <View style={{flex:1}}>
                    <View style={{flexDirection:'row'}}>
                    <Image source={{uri: product_thumbnail}} style={{width:100, height:100, borderRadius:6}}/>
                    <View style={{flexDirection:'column', flexShrink:1}}>
                        <Text style={{fontSize:16, fontWeight:'bold', paddingHorizontal:6, flexShrink:1}}>{product_title}</Text>
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <Text style={{fontSize:16, paddingHorizontal:6}}>{i18n.t('home.organizationPage.price')}</Text>
                            <Text style={{fontWeight:'bold'}}>{product_currency} {product_price}</Text>
                        </View>
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <Text style={{fontSize:16, paddingHorizontal:6}}>{i18n.t('home.organizationPage.quantity')}</Text>
                            <Text style={{fontWeight:'bold'}}>{product_quantity}</Text>
                        </View>
                        <View style={{flexDirection:'row', alignItems:'center'}}>
                            <Text style={{fontSize:16, paddingHorizontal:6}}>{i18n.t('home.organizationPage.in_stock')}</Text>
                            {(product_quantity > product_stock)?(
                              <Text style={{fontSize:16, fontWeight:'bold', color:'#EF9A9A'}}>{product_stock}</Text>
                            ):null}
                            {(product_quantity <= product_stock)?(
                              <Text style={{fontSize:16, fontWeight:'bold'}}>{product_stock}</Text>
                            ):null}
                        </View>
                        <TouchableOpacity
                        style={{paddingHorizontal:6, paddingVertical:6, backgroundColor:'#EF9A9A', borderRadius:6, marginHorizontal:6, flexDirection:'row', alignItems:'center'}}
                        onPress={()=>{

                          // console.log("EquisDe: ", cart_id_array)

                          /**cartId array */

                          const cartIdArray = cart_id_array.map(cartId => cartId.cart_id)


                          // console.log("cartIdArray: ", cartIdArray)

                            deleteFromCartById({variables:{cartId: cartIdArray}}).then(()=>{
                                refetchOrganizationCart();
                            }).catch((err)=>{
                                // console.log(err)
                            })
                        }}>
                            <Text>
                                {i18n.t('home.organizationPage.delete_from_cart')}
                            </Text>
                            <Feather name="shopping-bag" style={{marginLeft:6}}/>
                        </TouchableOpacity>
                    </View>
                    </View>
                </View>
            </View>
        )
    

    const renderItem = ({item, index}) => {
      return(<CartItem item={item}
        product_title={item.product_translations[0].product_title}
        product_thumbnail={item.product_thumbnail}
        product_currency={item.shopping_carts_aggregate.nodes[0].product_currency.currency_code}
        product_price={item.shopping_carts_aggregate.nodes[0].product_price}
        product_quantity={item.shopping_carts_aggregate.aggregate.sum.product_quantity}
        product_stock={item.product_quantity}
        cart_id_array={item.shopping_carts_aggregate.nodes}
        />)
    }

    function cartHeader(){
    return(
      <View>
              <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
      <Text style={{fontSize:32, fontWeight:'bold'}}>
        {i18n.t('home.organizationPage.shopping_cart')} {organizationName}
        </Text>
      <TouchableOpacity onPress={()=>{
        // setCartVisibility(false)
      }}>
      </TouchableOpacity>
    </View>
    <Text style={{fontSize:18, }}>{i18n.t('home.organizationPage.product_list')}</Text>
      </View>

    )
    }

    const ItemPayment = ({item, paymentType, operation, onPress, payment_option_id}) => (
      <View style={{paddingBottom:5}}>
          <TouchableOpacity style={{alignItems:'center', alignContent:'center', marginHorizontal:10, borderRadius:6 ,backgroundColor:'#FF6D00'}}
          onPress={onPress}>
          <View style={{flexDirection:'row', alignItems:'center'}}>
          <Text style={{fontSize:20, paddingVertical:6, paddingHorizontal:9, color:'white', marginVertical:5}}>{i18n.t('home.productDetailed.pay_with')} {paymentType}</Text>
          <Feather name="lock" size={20} color="white" />
          </View>
          
          </TouchableOpacity>
          {(payment_option_id === 3) ? (
            <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
              <Text>
              {i18n.t("home.productDetailed.stripe_payment_fee",{
                        currency_code_stripe_fee: currencyCodeStripeFee / 100,
                        currency_code: currency,
                    })}
              </Text>
            </View>
          ) : null}
      </View>
  )

    const renderPayments = ({item, index, product_order, product_stock}) => {

      // console.log(item.country_payment_options_id)
      setSelectedPaymentOptions(item.payment_option.payment_options_id)
      let onPress;
      if(item.payment_option.payment_options_id === 1){
        onPress = ()=>{
          setSelectedPaymentOptions(item.payment_option.payment_options_id)
            if(selectedDeliveryOptionsPicker != 0){
              if(selectedDeliveryLocation !=0){
               if(!outOfStock){
                 /**check if product_quantity is higher than product_stock for each then do not allow to purchase until it's resolved */
                 Alert.alert(i18n.t('home.productDetailed.alert_cash_alert'), i18n.t('home.productDetailed.alert_cash_alert_body'), [{
                   text: "Cancel",
                   onPress: () => {
                  //  console.log("Cancel Pressed");
                   }
               },{
                   text: "OK",
                   onPress: () => {
                       /**Make an array of products with the product quantity and product id */  
 
                       let products = [];

                       organizationCart.map(cart => {
                            products.push({
                              product_id: cart.product_id,
                              product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                              product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                              product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                            })          
                       })

                       console.log("Organization Cart: ", organizationCart)

                       let orderProducts = []

                       organizationCart.map(cart => {
                        orderProducts.push({
                          product_id: cart.product_id,
                          product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                          product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                          product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                        })
                       })

                      let updateProducts = []

                      organizationCart.map(cart => {
                        updateProducts.push({
                          product_id: cart.product_id,
                          product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                          product_quantity_before: cart.product_quantity,
                          product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                          product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                        })
                      })
                       
                      console.log("UPDATE PRODUCTS: ",updateProducts)

                       /**update products with new quantity */
 
                       let newProducts = [];

                       updateProducts.map(item => {
                        newProducts.push({
                          product_id: item.product_id,
                          product_quantity: item.product_quantity_before - item.product_quantity,
                          product_currency_id: item.product_currency_id,
                          product_price: item.product_price,
                        })
                       })
 
                       /**Make an array of the sellers */
 
                       let sellers = [];



                        allUsersByOrganizationId.map(user => {
                          sellers.push({
                            user_id: user.organization_user_id,
                          })
                        })

                        console.log("SELLERS: ",sellers)
 
                       /**create array transactions */
 
                       let transactions = [];
 
                       products.map(item => {
                         transactions.push({
                           product_currency_id: item.product_currency_id,
                           transaction_total: item.product_price * item.product_quantity,
                           transaction_status_id:3,
                         })
                       })
 
                       let realTimeTransaction = false;
 
                       if(selectedCountryDeliveryOptionId === 1){
                         realTimeTransaction = true;
                       }
                       
                       let orderDeliveryFees = ((organizationDistance / 1000).toFixed(2)) * deliveryFees;
                       /**mutation createOrderOrganizationCash */
 
                       createOrderCashOrganization({
                         variables: {
                           customerUid:uid,
                           countryDeliveryOptionId: selectedCountryDeliveryOptionId,
                           countryPaymentOptionId: selectedPaymentOptions,
                           userLocationId: selectedDeliveryLocation,
                           realtime: realTimeTransaction,
                           note: notes,
                           orderProducts: orderProducts,
                           sellersUid: sellers,
                           transactionProducts: transactions,
                           languageCode: languageCode,
                           organizationId: organizationId,
                           orderDeliveryFees: orderDeliveryFees.toFixed(2),
                         }
                       }).then(res => {
                          try {
                            newProducts.map(item => {
                              // console.log(item)
                               updateProductQuantity({variables:{product_id: item.product_id, productQuantity: item.product_quantity}}).then(()=>{
                                 console.log("updated")
                               }).catch((err)=>{
                                 console.log("ERROR ",err)
                               })
                             });
    
                            //  let workers = res.data.user_orders;
    
                             const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');
    
                             res.data.insert_orders_one.user_orders.map(item => {
                              sendExpoPushNotification({
                                  token: item.user.user_expo_token,
                                  title: i18n.t('home.productDetailed.new_order_notification'),
                                  body: i18n.t('home.productDetailed.new_order_notification_body'),
                              }).then(res => {
                                    console.log("RESPONSE NOTIFICATION SEND", res)
                                }).catch(err => {
                                    console.log("ERROR", err)
                                }).finally(() => {
                                    console.log("FINALLY")
                                });
                             });
                             
                             const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');
    
                            res.data.insert_orders_one.user_orders.map(item => {
                              singleOrderSellerEmail({
                                order_id: res.data.insert_orders_one.order_id,
                                client_name: res.data.insert_orders_one.user.user_name,
                                client_email: res.data.insert_orders_one.user.user_email,
                                seller_email: item.user.user_email,
                                delivery_option: res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_translations[0].delivery_option_name,
                                payment_option: res.data.insert_orders_one.country_payment_option.payment_option.payment_option_translations[0].payment_option_name,
                                total: total,
                                currency_code: currency,
                                languageCode: languageCode,
                              })
                            });

                            let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id; 
                                            
                            if(deliveryOptionId === 4){
                                console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                                res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                    sendExpoPushNotification({
                                        token: item.user.user_expo_token,
                                        title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                        body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                    }).catch(err => {
                                        console.log("ERROR", err)
                                    }).then(res => {
                                        console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                    }).finally(() => {});


                                })
                            }
    
                             shoppingCartCheckout({
                               variables: {
                                 userId: uid,
                                 organizationId: organizationId
                               }
                             }).then(res => {
                               refetchOrganizationCart();
                               Alert.alert(i18n.t('home.organizationPage.order_alert_success', 'home.organizationPage.order_alert_success_body'))
                               if(Platform.OS === 'web'){
                                setMessageTitle(i18n.t('home.organizationPage.order_alert_success'))
                                setMessageBody(i18n.t('home.organizationPage.order_alert_success_body'))
                                setModalVisibilityAlert(true)
                               }
                             }) 
                          } catch (error) {
                            
                          } 
                       }).catch(err => {
                        console.log("ERROR ",err)
                       })                                
                   },
               }
           ])      
           if(Platform.OS === 'web'){
            setMessageTitle(i18n.t('home.productDetailed.alert_cash_alert'))
            setMessageBody(i18n.t('home.productDetailed.alert_cash_alert_body'))
            setModalVisibilityCreateOrderCash(true);
           }                           
               }else{
                  Alert.alert(i18n.t('home.productDetailed.alert_out_of_stock'), i18n.t('home.productDetailed.alert_out_of_stock_body'))
                  if(Platform.OS === 'web'){
                    setMessageTitle(i18n.t('home.productDetailed.alert_out_of_stock'))
                    setMessageBody(i18n.t('home.productDetailed.alert_out_of_stock_body'))
                    setModalVisibilityAlert(true)
                  }
               }
              }else{
                Alert.alert(i18n.t('home.productDetailed.delivery_location_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))
                if(Platform.OS ==='web'){
                  setMessageTitle(i18n.t('home.productDetailed.delivery_location_alert'))
                  setMessageBody(i18n.t('home.productDetailed.delivery_location_alert_body'))
                  setModalVisibilityAlert(true)
                }

              }
            }else {
              Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_alert_body'))
              if(Platform.OS === 'web'){
                setMessageTitle(i18n.t('home.productDetailed.delivery_alert'))
                setMessageBody(i18n.t('home.productDetailed.delivery_alert_body'))
                setModalVisibilityAlert(true)
              }
            }
        }
      }else if(item.payment_option.payment_options_id === 2){
        setSelectedPaymentOptions(item.payment_option.payment_options_id)
          if(selectedDeliveryLocation != 0){
            if(selectedDeliveryOptionsPicker !=0){
              
              Alert.alert(i18n.t('home.productDetailed.alert_cash_alert'), i18n.t('home.productDetailed.alert_cash_alert_body'), [{
                text: "Cancel",
                onPress: () => {
                // console.log("Cancel Pressed");
                }
            },{
                text: "OK",
                onPress: () => {
                  
                }
            }])
          }else{
            Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_alert_body'))
          }
        }else{
          Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_alert_body'))
        }
      } else if(item.payment_option.payment_options_id === 3){
        onPress = async () => {
          setSelectedPaymentOptions(item.payment_option.payment_options_id)
          let productSelectedCountryPaymentOption = item.country_payment_options_id
          if(selectedDeliveryOptionsPicker != 0){
            if(selectedDeliveryLocation !=0){
              if(!outOfStock){
                let userStripeId; 
                userStripeId =item.user_country_payment_options_aggregate.nodes[0].user_stripe_id;
                console.log("USER STRIPE ID", userStripeId)

                let customerCountryPaymentOption;

                customerCountryPaymentOptions.map(item => {
                    if(item.country_payment_options_id === productSelectedCountryPaymentOption){
                        customerCountryPaymentOption = item;
                        console.log("CUSTOMER COUNTRY PAYMENT OPTION: ", customerCountryPaymentOption)
                    }else{
                    }
                })

                async function showPaymentSheet(stripeCustomerId){
                  setModalVisibilityIndicator(true)
                  console.log("CUSTOMER HAVE A STRIPE CUSTOMER ID for this country payment option")
                  const createStripePaymentIntent = httpsCallable(functions, 'createStripePaymentIntent');

                  let transactionFeeInCents;

                  transactionFeeInCents = currencyCodeStripeFee;

                  let orderDeliveryFees;

                  orderDeliveryFees = (((organizationDistance / 1000).toFixed(2)) * deliveryFees).toFixed(2)

                  let productTotalInCents = total * 100;
                  let deliveryFeesInCents = orderDeliveryFees * 100;
                  let transactionTotalFee = (productTotalInCents + deliveryFeesInCents + transactionFeeInCents) * 0.05;
                  let transactionTotalFeeInCents = (transactionTotalFee.toFixed(2) /100).toFixed(2) * 100;
                  let totalAmountInCents = productTotalInCents + deliveryFeesInCents + transactionFeeInCents + transactionTotalFeeInCents;
                  console.log("TOTAL AMOUNT IN CENTS: ", totalAmountInCents)
                  console.log("TOTAL DELIVERY FEES IN CENTS: ", deliveryFeesInCents)
                  console.log("TOTAL PRODUCT IN CENTS: ", productTotalInCents)
                  console.log("TOTAL TRANSACTION FEE IN CENTS: ", transactionTotalFeeInCents)
                  console.log("TOTAL TRANSACTION FEE: ", (transactionTotalFee.toFixed(2) /100).toFixed(2))


                  const { data } = await createStripePaymentIntent({
                    amount: totalAmountInCents,
                    currency: currency,
                    userStripeCustomerId: stripeCustomerId,
                    userStripeId: userStripeId,
                });

                console.log("DATA: ", data)
                let clientSecret = data.paymentIntent.client_secret;
                let ephemeralKeySecret = data.ephemerelKey.secret;

                console.log("CLIENT SECRET: ", clientSecret)

                // init paymentSheet

                const { error } = await initPaymentSheet({
                  merchantDisplayName: "Menucitos",
                  customerId: stripeCustomerId,
                  customerEphemeralKeySecret: ephemeralKeySecret,
                  paymentIntentClientSecret: clientSecret,
                  googlePay: true,
              });

              if(error){

              }else{
                  setModalVisibilityIndicator(false)
                  console.log("PAYMENT SHEET INITIALIZED")
                  const {error} = await presentPaymentSheet({
                    confirmPayment: true,
                });

                if(error){
                  console.log("ERROR: ", error)
                }else{
                  let products = [];

                       organizationCart.map(cart => {
                            products.push({
                              product_id: cart.product_id,
                              product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                              product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                              product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                            })          
                       })

                       console.log("Organization Cart: ", organizationCart)

                       let orderProducts = []

                       organizationCart.map(cart => {
                        orderProducts.push({
                          product_id: cart.product_id,
                          product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                          product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                          product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                        })
                       })

                      let updateProducts = []

                      organizationCart.map(cart => {
                        updateProducts.push({
                          product_id: cart.product_id,
                          product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                          product_quantity_before: cart.product_quantity,
                          product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                          product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                        })
                      })
                       
                      console.log("UPDATE PRODUCTS: ",updateProducts)

                       /**update products with new quantity */
 
                       let newProducts = [];

                       updateProducts.map(item => {
                        newProducts.push({
                          product_id: item.product_id,
                          product_quantity: item.product_quantity_before - item.product_quantity,
                          product_currency_id: item.product_currency_id,
                          product_price: item.product_price,
                        })
                       })
 
                       /**Make an array of the sellers */
 
                       let sellers = [];



                      allUsersByOrganizationId.map(user => {
                        sellers.push({
                          user_id: user.organization_user_id,
                        })
                      })

                        console.log("SELLERS: ",sellers)
 
                                            /**Make sure sellers[i].user_id is unique  and make an array with {user_id: ''}*/

                        let uniqueSellers = [];

                        sellers.map(item => {
                          if(uniqueSellers.length === 0){
                            uniqueSellers.push({
                              user_id: item.user_id
                            })
                          }else{
                            let found = false;
                            uniqueSellers.map(item => {
                              if(item.user_id === item.user_id){
                                found = true;
                              }
                            })
                            if(!found){
                              uniqueSellers.push({
                                user_id: item.user_id
                              })
                            }
                          }
                        })
                       /**create array transactions */
 
                       let transactions = [];
 
                       products.map(item => {
                         transactions.push({
                           product_currency_id: item.product_currency_id,
                           transaction_total: item.product_price * item.product_quantity,
                           transaction_status_id:3,
                           transaction_fee: (transactionTotalFee.toFixed(2) /100).toFixed(2),
                         })
                       })
 
                       let realTimeTransaction = false;
 
                       if(selectedCountryDeliveryOptionId === 1){
                         realTimeTransaction = true;
                       }
                       
                       let orderDeliveryFees = ((organizationDistance / 1000).toFixed(2)) * deliveryFees;
                       /**mutation createOrderOrganizationCard*/

                       createOrderCardOrganization({
                        variables:{
                          customerUid:uid,
                          countryDeliveryOptionId:selectedCountryDeliveryOptionId,
                          countryPaymentOptionId:productSelectedCountryPaymentOption,
                          userLocationId: selectedDeliveryLocation,
                          realtime: realTimeTransaction,
                          note: notes,
                          orderProducts: orderProducts,
                          sellersUid: uniqueSellers,
                          transactionProducts: transactions,
                          languageCode: languageCode,
                          organizationId: organizationId,
                          orderDeliveryFees: orderDeliveryFees.toFixed(2),
                          transactionSellerFee:transactionFeeInCents / 100,
                          // transactionFee: (transactionTotalFee.toFixed(2) /100).toFixed(2),
                        }
                       }).then(async res => {
                        newProducts.map(item => {
                          // console.log(item)
                           updateProductQuantity({variables:{product_id: item.product_id, productQuantity: item.product_quantity}}).then(()=>{
                             console.log("updated")
                           }).catch((err)=>{
                             console.log("ERROR ",err)
                           })
                         })

                         /** send notifications for each worker from res.data*/
                         const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');
                         res.data.insert_orders_one.user_orders.map(item => {
                          sendExpoPushNotification({
                              token: item.user.user_expo_token,
                              title: i18n.t('home.productDetailed.new_order_notification'),
                              body: i18n.t('home.productDetailed.new_order_notification_body'),
                          }).then(res => {
                                console.log("RESPONSE NOTIFICATION SEND", res)
                            }).catch(err => {
                                console.log("ERROR", err)
                            }).finally(() => {
                                console.log("FINALLY")
                            });
                         });

                         const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');

                         res.data.insert_orders_one.user_orders.map(item => {
                           singleOrderSellerEmail({
                             order_id: res.data.insert_orders_one.order_id,
                             client_name: res.data.insert_orders_one.user.user_name,
                             client_email: res.data.insert_orders_one.user.user_email,
                             seller_email: item.user.user_email,
                             delivery_option: res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_translations[0].delivery_option_name,
                             payment_option: res.data.insert_orders_one.country_payment_option.payment_option.payment_option_translations[0].payment_option_name,
                             total: productPrice * selectedQuantity,
                             currency_code: productCurrency,
                             languageCode: languageCode,
                           })
                         });

                          let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id; 
                                            
                          if(deliveryOptionId === 4){
                              console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                              res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                  sendExpoPushNotification({
                                      token: item.user.user_expo_token,
                                      title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                      body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                  }).catch(err => {
                                      console.log("ERROR", err)
                                  }).then(res => {
                                      console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                  }).finally(() => {});

                              })
                          }
                         shoppingCartCheckout({
                           variables: {
                             userId: uid,
                             organizationId: organizationId
                           }
                         }).then(res => {
                           refetchOrganizationCart();
                           Alert.alert(i18n.t('home.organizationPage.order_alert_success', 'home.organizationPage.order_alert_success_body'))
                           if(Platform.OS === 'web'){
                            setMessageTitle(i18n.t('home.organizationPage.order_alert_success'))
                            setMessageBody(i18n.t('home.organizationPage.order_alert_success_body'))
                            setModalVisibilityAlert(true)
                           }
                         })  
                       }).catch(err => {
                          console.log("ERROR ",err)
                        })
                }
              }
              }

                if(customerCountryPaymentOption){
                  if(customerCountryPaymentOption.user_stripe_customer_id){
                      // setModalVisibilityIndicator(true);
                      showPaymentSheet(customerCountryPaymentOption.user_stripe_customer_id);
                  }else{
                    console.log("NO CUSTOMER ID");
                    //create a customer
                    const idUserCountryPaymentOptionId = customerCountryPaymentOption.id_user_country_payment_option;

                    const savePaymentMethodSetupIntentFromProduct = httpsCallable(functions, 'savePaymentMethodSetupIntentFromProduct');
                    let stripeCustomerId;
                    let ephemeralKeySecret;
                    let setupIntent;

                    const { data } = await savePaymentMethodSetupIntentFromProduct({
                        idUserCountryPaymentOptionId: idUserCountryPaymentOptionId,
                    });

                    ephemeralKeySecret = data.ephemerelKey.secret;
                    setupIntent = data.setupIntent.client_secret;
                    stripeCustomerId = data.customer.id;

                    console.log("Stripe Customer Id: ", stripeCustomerId);

                    const { error } = await initPaymentSheet({
                        merchantDisplayName: "MenuPay",
                        customerEphemeralKeySecret: ephemeralKeySecret,
                        setupIntentClientSecret: setupIntent,
                        customerId: stripeCustomerId,
                    })

                    if(!error){
                        console.log("PAYMENT SHEET INITIALIZED SETUP INTENT");

                        const {error} = await presentPaymentSheet({
                            clientSecret: setupIntent,
                        }).then(async res => {
                            console.log("PAYMENT SHEET COMPLETED FILLING");
                            console.log(res);
                        }).catch(err => {
                            console.log("ERROR: ", err);
                        })

                        if(error){
                            console.log("ERROR: ", error);
                        }else{
                            console.log("PAYMENT SHEET PRESENTED");
                            console.log("PAYMENT SHEET PRESENTED");
                            presentPaymentSheet(stripeCustomerId);
                            
                        }
                    }else {
                        console.log("ERROR: ", error);
                    }
                  }
                }else{
                  console.log("CREATE A USER COUNTRY PAYMENT OPTION");
                  console.log("CREATE A USER COUNTRY PAYMENT OPTION");
                  setModalVisibilityIndicator(true);
                  createUserPaymentOptionFromProduct({
                      variables:{
                          uid: uid,
                          countryPaymentOptionId: productSelectedCountryPaymentOption,
                      }
                  }).then(async res => {
                      // refetchProductDetail();
                      let userCountryPaymentOptionId = res.data.insert_user_country_payment_option_one.id_user_country_payment_option;

                      const savePaymentMethodSetupIntentFromProduct = httpsCallable(functions, 'savePaymentMethodSetupIntentFromProduct');
                      let stripeCustomerId;
                      let ephemeralKeySecret;
                      let setupIntent;
                      const { data } = await savePaymentMethodSetupIntentFromProduct({
                          idUserCountryPaymentOptionId: userCountryPaymentOptionId,
                      });

                      ephemeralKeySecret = data.ephemerelKey.secret;
                      setupIntent = data.setupIntent.client_secret;
                      stripeCustomerId = data.customer.id;

                      console.log("Stripe Customer Id: ", stripeCustomerId);
                      console.log("Ephemeral Key Secret: ", ephemeralKeySecret);
                      console.log("Setup Intent: ", setupIntent);

                      const { error } = await initPaymentSheet({
                          merchantDisplayName: "MenuPay",
                          customerEphemeralKeySecret: ephemeralKeySecret,
                          setupIntentClientSecret: setupIntent,
                          customerId: stripeCustomerId,
                      })

                      if(!error){
                          console.log("PAYMENT SHEET INITIALIZED");
                          // setModalVisibilityIndicator(false);
                          const {error} = await presentPaymentSheet({
                              clientSecret: setupIntent,
                              
                          }).then(async res => {
                              console.log("PAYMENT SHEET FINISHED");
                              // await showPaymentSheet(stripeCustomerId);
                              console.log(res);
                              if(res.error){
                                  console.log("ERROR: ", res.error);
                                  setModalVisibilityIndicator(false);

                              } else {
                                  console.log("PAYMENT SHEET PRESENTED");
                                  // console.log("PAYMENT SHEET PRESENTED");
                                  await showPaymentSheet(stripeCustomerId);
                              }
                          }).catch(err => {
                              console.log("ERROR: ", err);
                              setModalVisibilityIndicator(false);
                          })

                          if(error){
                              console.log("ERROR: ", error);
                              setModalVisibilityIndicator(false);
                          }else{
                              console.log("PAYMENT SHEET PRESENT PAYMENT SHEET PRESENTED");
                              // console.log("PAYMENT SHEET PRESENTED");
                              await showPaymentSheet(stripeCustomerId);
                          }
                      }else {
                          console.log("ERROR: ", error);
                          setModalVisibilityIndicator(false);
                      }


                  }).catch(err => {})
                }
              }else{
                Alert.alert(i18n.t('home.productDetailed.alert_out_of_stock'), i18n.t('home.productDetailed.alert_out_of_stock_body'))
              }
            }else{
              Alert.alert(i18n.t('home.productDetailed.delivery_location_alert'), i18n.t('home.productDetailed.delivery_location_alert_body'))   
            }
          }else{
            Alert.alert(i18n.t('home.productDetailed.delivery_alert'), i18n.t('home.productDetailed.delivery_alert_body'))
          }
        }
      }
      return(
          <ItemPayment item={item}
          onPress={onPress}
          paymentType={item.payment_option.payment_option_translations[0].payment_option_name}
          product_order={item.product_quantity}
          payment_option_id={item.payment_option.payment_options_id}
          />
      )
  }

    function cartFooter(){
        return(
            <View>
                <Text style={{fontSize:24, fontWeight:'bold', paddingBottom:15}}>{i18n.t('home.organizationPage.summary')}</Text>
                <View style={{flexDirection:'row'}}>
                    <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('home.organizationPage.products_added')}</Text>
                    <Text style={{fontSize:16, fontWeight:'bold'}}>{totalProducts}</Text>
                </View>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('home.organizationPage.total')}</Text>
                    <Text style={{fontSize:16}}>{
                        total
                    } {(
                        currencySame ? currency : ''
                    )}</Text>
                </View>
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('home.organizationPage.delivery_fees')}</Text>
                    <Text style={{fontSize:16, paddingLeft:5}}>{
                        (((organizationDistance / 1000).toFixed(2)) * deliveryFees).toFixed(2)
                    } {(
                        currencySame ? currency : ''
                    )}</Text>
                </View>
                <View style={{paddingTop:15}}>
                    <Text style={{fontSize:16, fontWeight:'bold'}}>
                        {i18n.t('home.organizationPage.delivery_options')}
                    </Text>
                    <Picker selectedValue={selectedDeliveryOptionsPicker} onValueChange={
                        (itemValue, itemIndex) => {
                          if(itemValue != 0){
                            setSelectedDeliveryOptions(itemValue)
                            let deliveryOption = JSON.parse(itemValue)
                            console.log(deliveryOption)
                            setSelectedCountryDeliveryOptionId(deliveryOption['country_delivery_option_id'])
                            setSelectedDeliveryOptionId(deliveryOption['delivery_option_id'])
                            setDeliveryFees(deliveryOption['country_delivery_options_fees'].length > 0 ? deliveryOption['country_delivery_options_fees'][0]['country_delivery_options_fees'] : 0)
                            // console.log
                            console.log(deliveryOption['country_delivery_option_id'])
                            console.log(deliveryOption['delivery_option']['delivery_option_id'])
                            console.log(deliveryOption['country_delivery_options_fees'].length > 0 ? deliveryOption['country_delivery_options_fees'][0]['country_delivery_options_fees'] : 0)
                          } else{
                            setSelectedDeliveryOptions(itemValue)
                          }
                        }
                    }>
                      <Picker.Item label={i18n.t('home.organizationPage.delivery_options_select')} value={0}/>
                        {deliveryOptions.map(option => (
                          
                            <Picker.Item key={option.country_delivery_option_id} label={option.delivery_option.delivery_option_translations[0].delivery_option_name} value={JSON.stringify(option)}/>
                        ))}
                    </Picker>
                    <Text style={{fontWeight:"bold", fontSize:16}}>
                      {i18n.t('home.organizationPage.delivery_location')}
                    </Text>
                    <Picker selectedValue={selectedDeliveryLocation} onValueChange={(itemValue, itemIndex)=>{
                        setSelectedDeliveryLocation(itemValue)
                        // console.log(itemValue)
                    }}>
                      <Picker.Item label={i18n.t('home.organizationPage.delivery_location_select')} value={0}/>
                        {deliveryLocations.map((item, index) => (
                            <Picker.Item label={item.address1 +" "+ item.zip_code + " " + item.country.country_name} value={item.user_location_id} key={index}/>
                        ))}
                    </Picker>
                    <Text style={{fontWeight:'bold', fontSize:16}}>
                        {i18n.t('home.organizationPage.leave_notes')}
                    </Text>
                    <TextInput 
                    numberOfLines={4}
                    style={{backgroundColor:'#ccc', textAlignVertical:'top', marginBottom:keyboardOffset, fontSize:16, paddingVertical:5, paddingHorizontal:3}}
                    placeholder={i18n.t('home.organizationPage.leave_notes_placeholder')}
                    onChangeText={(text)=>{
                        setNotes(text)
                    }}
                    >
                    </TextInput>

                    <Text style={{fontWeight:'bold', fontSize:16, paddingTop:25, paddingBottom:10}}>
                        {i18n.t('home.organizationPage.payment_options')}
                    </Text>
                    <FlatList data={paymentOptions} renderItem={renderPayments} keyExtractor={item => item.country_payment_options_id.toString()}></FlatList>
                </View>
            </View>
        )
    }

  return (
    <SafeAreaView style={{flex:1}}>
                <Modal visible={modalVisibilityAlert}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibilityAlert(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                <Text style={{fontSize:20}}>{messageBody}</Text>

                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>

          <Modal visible={modalVisibilityIndicator} transparent={true}
        
        animationType={'fade'}>
            <View style={{flex:1, justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                <ActivityIndicator color={'#FF6D00'} size={'large'}/>
                <TouchableOpacity style={{marginTop:20, borderColor:"#FF6D00", borderWidth:2, borderRadius:6}} onPress={() => {
                    setModalVisibilityIndicator(false)
                }}>
                    <Text style={{color:'#FF6D00', paddingHorizontal:10}}>Close</Text>
                </TouchableOpacity>

            </View>
        </Modal>


          <Modal visible={modalVisibilityCreateOrderCash}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => modalVisibilityCreateOrderCash(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                <Text style={{fontSize:20}}>{messageBody}</Text>
                <TouchableOpacity style={{marginTop:20}} onPress={() => {
                       /**Make an array of products with the product quantity and product id */  
 
                       let products = [];

                       organizationCart.map(cart => {
                            products.push({
                              product_id: cart.product_id,
                              product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                              product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                              product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                            })          
                       })

                       console.log("Organization Cart: ", organizationCart)
 
                      //  dataOrganizationCart.shopping_cart.map(item => {
                      //    products.push({
                      //      product_id: item.product.product_id,
                      //      product_quantity: item.product_quantity,
                      //      product_quantity_before: item.product.product_quantity,
                      //      product_currency_id: item.product_currency.product_currency_id,
                      //      product_price: item.product_currency.product_price,
 
                      //    })
                      //  })
 
                       let orderProducts = []

                       organizationCart.map(cart => {
                        orderProducts.push({
                          product_id: cart.product_id,
                          product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                          product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                          product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                        })
                       })
 
                      //  dataOrganizationCart.shopping_cart.map(item => {
                      //    orderProducts.push({
                      //      product_id: item.product.product_id,
                      //      product_quantity: item.product_quantity,
                      //      product_currency_id: item.product_currency.product_currency_id,
                      //      product_price: item.product_currency.product_price,
                      //    })
                      //  })
 
                       // console.log(products)
 
                       /**substract selected product quantity by current array product quantity in products array*/
                      //  products.map(item => {
                      //    item.product_quantity_before = item.product_quantity_before - item.product_quantity;
                      //  })
 
                      //  console.log("PRODUCTS: ",products)

                      let updateProducts = []

                      organizationCart.map(cart => {
                        updateProducts.push({
                          product_id: cart.product_id,
                          product_quantity: cart.shopping_carts_aggregate.aggregate.sum.product_quantity,
                          product_quantity_before: cart.product_quantity,
                          product_currency_id: cart.shopping_carts_aggregate.nodes[0].product_currency.product_currency_id,
                          product_price: cart.shopping_carts_aggregate.nodes[0].product_price,
                        })
                      })
                       
                      console.log("UPDATE PRODUCTS: ",updateProducts)

                       /**update products with new quantity */
 
                       let newProducts = [];

                       updateProducts.map(item => {
                        newProducts.push({
                          product_id: item.product_id,
                          product_quantity: item.product_quantity_before - item.product_quantity,
                          product_currency_id: item.product_currency_id,
                          product_price: item.product_price,
                        })
                       })
 
                      //  products.map(item => {
                      //    newProducts.push({
                      //      product_id: item.product_id,
                      //      product_quantity: item.product_quantity_before,
                      //      product_currency_id: item.product_currency_id,
                      //      product_price: item.product_price,
                      //    })
                      //  })
                       
                       // newProducts.map(item => {
                       //   updateProductQuantity({variables:{product_id: item.product_id, productQuantity: item.product_quantity}}).then(()=>{
                       //     console.log("updated")
                       //   }).catch((err)=>{
                       //     console.log(err)
                       //   })
                       // })
 
                       /**Make an array of the sellers */
 
                       let sellers = [];

                        organizationCart.map(cart => {
                          sellers.push({
                            user_id: cart.user_products[0].user_id,
                          })
                        })
 
                      //  dataOrganizationCart.shopping_cart.map(item => {
                      //    sellers.push({
                      //      user_id: item.product.user_products[0].user_id
                      //    })
 
                      //  })
 
                       // console.log("Sellers: ", sellers)
 
                       /**Make sure sellers[i].user_id is unique  and make an array with {user_id: ''}*/
 
                       let uniqueSellers = [];
 
                       sellers.map(item => {
                         if(uniqueSellers.length === 0){
                           uniqueSellers.push({
                             user_id: item.user_id
                           })
                         }else{
                           let found = false;
                           uniqueSellers.map(item => {
                             if(item.user_id === item.user_id){
                               found = true;
                             }
                           })
                           if(!found){
                             uniqueSellers.push({
                               user_id: item.user_id
                             })
                           }
                         }
                       })
 
                       // console.log("Unique Sellers: ", uniqueSellers)
 
                       /**create array transactions */
 
                       let transactions = [];
 
                       products.map(item => {
                         transactions.push({
                           product_currency_id: item.product_currency_id,
                           transaction_total: item.product_price * item.product_quantity,
                           transaction_status_id:3,
                         })
                       })
 
                       let realTimeTransaction = false;
 
                       if(selectedCountryDeliveryOptionId === 1){
                         realTimeTransaction = true;
                       }
 
 
 
                       /**mutation createOrderOrganizationCash */
 
                       createOrderCashOrganization({
                         variables: {
                           customerUid:uid,
                           countryDeliveryOptionId: selectedCountryDeliveryOptionId,
                           countryPaymentOptionId: selectedPaymentOptions,
                           userLocationId: selectedDeliveryLocation,
                           realtime: realTimeTransaction,
                           note: notes,
                           orderProducts: orderProducts,
                           sellersUid: uniqueSellers,
                           transactionProducts: transactions,
                           languageCode: languageCode,
                           organizationId: organizationId,
                         }
                       }).then(res => {
                         newProducts.map(item => {
                          // console.log(item)
                           updateProductQuantity({variables:{product_id: item.product_id, productQuantity: item.product_quantity}}).then(()=>{
                             console.log("updated")
                           }).catch((err)=>{
                             console.log("ERROR ",err)
                           })
                         })

                         /** send notifications for each worker from res.data*/
                         const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');
                         res.data.user_orders.map(item => {
                          sendExpoPushNotification({
                              token: item.user.user_token,
                              title: i18n.t('home.productDetailed.new_order_notification'),
                              body: i18n.t('home.productDetailed.new_order_notification_body'),
                          }).then(res => {
                                // console.log("RESPONSE NOTIFICATION SEND", res)
                            }).catch(err => {
                                // console.log("ERROR", err)
                            }).finally(() => {
                                // console.log("FINALLY")
                            });
                         })

                         const singleOrderSellerEmail = httpsCallable(functions, 'singleOrderSellerEmail');

                          res.data.user_orders.map(item => {
                            singleOrderSellerEmail({
                              order_id: item.order_id,
                              client_name: item.user.user_name,
                              client_email: item.user.user_email,
                              seller_email: item.user
                            })
                          })

                          let deliveryOptionId = res.data.insert_orders_one.country_delivery_option.delivery_option.delivery_option_id; 
                                            
                          if(deliveryOptionId === 4){
                              console.log(res.data.insert_orders_one.country_delivery_option.country.user_locations)
                              res.data.insert_orders_one.country_delivery_option.country.user_locations.map(item => {
                                  sendExpoPushNotification({
                                      token: item.user.user_expo_token,
                                      title: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                      body: i18n.t('home.productDetailed.delivery_menucitos_motorcycle_guy_notification'),
                                  }).catch(err => {
                                      console.log("ERROR", err)
                                  }).then(res => {
                                      console.log("RESPONSE NOTIFICATION SEND TO DELIVERY GUY", res)
                                  }).finally(() => {});


                              })
                          }

 
                         shoppingCartCheckout({
                           variables: {
                             userId: uid,
                             organizationId: organizationId
                           }
                         }).then(res => {
                           refetchOrganizationCart();
                           Alert.alert(i18n.t('home.organizationPage.order_alert_success', 'home.organizationPage.order_alert_success_body'))
                           if(Platform.OS === 'web'){
                            setMessageTitle(i18n.t('home.organizationPage.order_alert_success'))
                            setMessageBody(i18n.t('home.organizationPage.order_alert_success_body'))
                            setModalVisibilityAlert(true)
                           }
                         })  
                       }).catch(err => {

                       })              
                }}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>OK</Text>
                </TouchableOpacity>                
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityCreateOrderCash(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Cancel</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>
            <View style={{flex:1, backgroundColor:'#fff', paddingHorizontal:10}}>
            <FlatList
            ListHeaderComponent={cartHeader()}
            ListFooterComponent={cartFooter()}
            ListFooterComponentStyle={{paddingTop:25, paddingBottom:25}}
            data={organizationCart}
            renderItem={renderItem}
            keyExtractor={(item, index) => item.product_id.toString()}
            ></FlatList>
            </View>
    </SafeAreaView>
  );
}


export default OrganizationPageCart;