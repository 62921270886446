import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, Modal, ActivityIndicator, KeyboardAvoidingView, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {Picker} from '@react-native-picker/picker';
import { useQuery , useMutation } from '@apollo/client';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_USER_COUNTRY_CURRENCY_OPTION, UPDATE_PRODUCT_PRICE } from '../../../lib/queries';
import i18n from '../../../i18n/i18n';

const auth = getAuth(Firebase);

const EditProductPrice = ({navigation, route}) => {

    /**route.params */
    /**productPrice */
    const {product_Price} = route.params
    const {Price} = product_Price
  
    /**product_Currency */
    const {product_Currency} = route.params
    const {Currency} = product_Currency

    /**product_Currency_Id */

    const {product_Currency_Id} = route.params
    const {productCurrencyId} = product_Currency_Id

  /**Firebase uid */
  const uid = getAuth().currentUser.uid;
  /**States */
  const [productPrice, setProductPrice] = useState(Price? Price.toString(): '') 
  const [productCurrency, setProductCurrency] = useState(Currency? Currency: '')
  const [currencyList, setCurrencyList] = useState()
  /**Queries */
  const {loding: currencyLoading, error: currencyError, data: currencyData, refetch} = useQuery(GET_USER_COUNTRY_CURRENCY_OPTION, {variables:{uid}})

  /**Mutations */

  const [updateProductPrice, {loading: updatePriceLoading, error: updatePriceError}] = useMutation(UPDATE_PRODUCT_PRICE)

  useEffect(()=>{
    // console.log(image)
    let newCurrencyList;
    if(currencyData){
      const {user_currencies} = currencyData
      // console.log(user_country_payment_option[0]['user_country_payment_option_config']['currencies']);
      newCurrencyList = user_currencies;
      console.log(newCurrencyList);
    }

    setCurrencyList(newCurrencyList);
    const unsubscribe = navigation.addListener('focus', () => {
      refetch();
    });
  },)

  /**format productPrice to 2 decimal chars */

    const formatProductPrice = (productPrice) => {
        let productPriceFormatted = ((productPrice * 100) / 100).toFixed(2);
        return productPriceFormatted
    }

    return(

      <SafeAreaView style={{flex:1 , paddingTop:5}}>
            <Modal 
            visible={updatePriceLoading}
            style={{flex:1, justifyContent:'center', alignItems:'center'}} 
            animationType="none"
            transparent={true}>
                <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                {/* <Text>Hey</Text> */}
                <ActivityIndicator size="large" color='#1e76e8' />
                {/* <Button title="Close" onPress={()=>setVisibleModal(false)}/> */}
                </View>
                
            </Modal>
      <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
      <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
      <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
      <Text style={{fontSize:18, fontWeight:'bold'}}>
          {i18n.t('products.editProductPrice.title')}
      </Text>
      </View>
      <View style={{paddingTop:25}}>
          {/* <Text style={{fontSize:22}}> Select a good and attractive price for your product</Text> */}
      </View>
  </View>
  <View style={{flex:2.5}}>
  <Text style={{fontSize:22, paddingHorizontal:15}}>
    {i18n.t('products.editProductPrice.edit_product_price')}
  </Text>
      <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:15,
       marginHorizontal:15, fontSize:18}} 
        keyboardType={'numeric'}
        defaultValue={productPrice}
       contextMenuHidden={true}
       onChangeText={(v)=>{setProductPrice(v);
        // console.log(formatProductPrice(v))
      }}
       placeholder="4.99"/>
        <Text style={{fontSize:22, paddingHorizontal:15}}>
          {i18n.t('products.editProductPrice.currency_label')}
        </Text>
        <Picker
        style={{paddingHorizontal:15, marginTop:15, marginHorizontal:15}}
        selectedValue={productCurrency} onValueChange={(itemValue)=>{
         setProductCurrency(itemValue);
       }}
      
       >
          <Picker.Item label={i18n.t('products.editProductPrice.pick_currency_label')} value="0" key="0" />
          {(currencyList)?currencyList.map((item, index)=>{
            return <Picker.Item label={item.user_currency_code} value={item.user_currency_code} key={index} />
          }):null}
       </Picker>

  </View> 
  <View style={{flex:0.6, paddingHorizontal:15}}>
      <TouchableOpacity style={{backgroundColor:'#000000', paddingVertical:15, borderRadius:6}}
       onPress={()=> {if(productPrice && productCurrency){
            
                  updateProductPrice(
                      {variables:{
                          productCurrencyId,
                          productPrice : formatProductPrice(productPrice),
                      }}
                  ).then(()=>{
                      Alert.alert('Success', 'Product price updated successfully', [{text:'OK',}])
                  })
                }else{
                  Alert.alert("Error", "Please suggest a price and choose a currency")
                  
                }}}
      >
          <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>
            {i18n.t('products.editProductPrice.btn_save')}
          </Text>
      </TouchableOpacity>
      </View>
  </KeyboardAvoidingView>
        
</SafeAreaView>

    )
    
}

export default EditProductPrice;