import React, { useState, useEffect, useRef } from 'react';
import { View, Text, FlatList, TouchableOpacity, ActivityIndicator, TextInput, KeyboardAvoidingView, Platform, Dimensions  } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useMutation, useSubscription} from '@apollo/client';
import { GET_USER_PAYMENT_OPTION_BY_ID, GET_USER_PAYMENT_STRIPE_VERIFIED } from '../../../lib/queries';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
// import { } from 'react-native-gesture-handler';
import i18n from '../../../i18n/i18n';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Alert } from 'react-native';
import { Linking } from 'react-native';
import Modal from 'react-native-modal'

//isfocused
import { useIsFocused } from '@react-navigation/native';
import { Feather, FontAwesome, Ionicons } from '@expo/vector-icons';
// import { CardField, useStripe } from '@stripe/stripe-react-native';

const useStripe = Platform.select({
    web: () => null,
    native: () => require('@stripe/stripe-react-native').useStripe,
})();
const CardField = Platform.select({
    web: () => null,
    native: () => require('@stripe/stripe-react-native').CardField,
})();


import { SceneMap, TabBar, TabView } from 'react-native-tab-view';
import { useToggleModalBankVisibility } from '../../../hooks/useTogglePasswordVisibility';


const auth = getAuth(Firebase);
const functions = getFunctions(Firebase);

const BankRoute = ({id, setModalVisible}) => {
    const isFocused = useIsFocused();
    // const id = getAuth().currentUser.uid;
    // console.log(id);
    const [bankAccountHolderName, setBankAccountHolderName] = useState('');
    const [bankAccountRoutingNumber, setBankAccountRoutingNumber] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const bankAccountHolderNameRef = useRef(null);
    const bankAccountRoutingNumberRef = useRef(null);
    const bankAccountNumberRef = useRef(null);
    const bankAccountSubmitRef = useRef(null);

    const [stripeUserId, setStripeUserId] = useState('');
    const [stripeUserClicked, setStripeUserClicked] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userCountry, setUserCountry] = useState('');
    const [userCountryCode, setUserCountryCode] = useState('');
    const [userCurrencyCode, setUserCurrencyCode] = useState('');
    const [paymentOptionId, setPaymentOptionId] = useState('');
    const [stripeUserVerified, setStripeUserVerified] = useState(false);
    const [stripeDisabledReason, setStripeDisabledReason] = useState('');
    const [stripeCurrentlyDue, setStripeCurrentlyDue] = useState([]);

    const {loading: loadingUserPaymentOption, error: errorUserPaymentOption, data: dataUserPaymentOption, refetch:refechtDataUserPaymentOption} = useQuery(GET_USER_PAYMENT_OPTION_BY_ID, {variables: {id:id}});
    const {loading: loadingStripeVerified, error: errorStripeVerified, data: dataStripeVerified} = useSubscription(GET_USER_PAYMENT_STRIPE_VERIFIED, {variables: {id:id}})

    // const {modalVisible, handleModalVisibility} = useToggleModalBankVisibility()

    useEffect(() => {
        let newStripeUserId;
        let newUserName;
        let newUserLastName;
        let newUserEmail;
        let newUserCountry;
        let newUserCountryCode;
        let newUserCurrencyCode;
        let newPaymentOptionId;
        let newBankAccountHolderName;
        // console.log("Modal Visible: ", modalVisible)
        console.log("ERRRRORRR: ", loadingStripeVerified, errorStripeVerified, dataStripeVerified);
        if(dataUserPaymentOption){
            console.log("DATA: ", dataUserPaymentOption);
            const {user_country_payment_option_by_pk} = dataUserPaymentOption;
            newBankAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
            // newCardAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
            newStripeUserId = user_country_payment_option_by_pk.user_stripe_id;
            // newStripeUserClicked = user_country_payment_option_by_pk.user_stripe_clicked;
            newUserEmail = user_country_payment_option_by_pk.user.user_email;
            newUserName = user_country_payment_option_by_pk.user.user_name;
            newUserLastName = user_country_payment_option_by_pk.user.user_last_name;
            newUserCountry = user_country_payment_option_by_pk.country_payment_option.country.country_name;
            newUserCountryCode = user_country_payment_option_by_pk.country_payment_option.country.country_code;
            newUserCurrencyCode = user_country_payment_option_by_pk.user.user_currencies[0].user_currency_code;
            newPaymentOptionId = user_country_payment_option_by_pk.country_payment_option.payment_option.payment_options_id;
            newBankAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
            // newCardAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
        }
        if(dataStripeVerified){
            const {user_country_payment_option} = dataStripeVerified;
            newStripeUserId = user_country_payment_option[0]["user_stripe_id"];
        }
        setStripeUserId(newStripeUserId);
        setUserEmail(newUserEmail);
        setUserFirstName(newUserName);
        setUserLastName(newUserLastName);
        setUserCountry(newUserCountry);
        setUserCountryCode(newUserCountryCode);
        console.log("newUserCountryCode: ", newUserCountryCode);
        setUserCurrencyCode(newUserCurrencyCode);
        console.log("newUserCurrencyCode: ", newUserCurrencyCode);
        setPaymentOptionId(newPaymentOptionId);
        setBankAccountHolderName(newBankAccountHolderName);
        console.log("newBankAccountHolderName: ", dataStripeVerified);
        // setCardAccountHolderName(newCardAccountHolderName);


    }, [dataUserPaymentOption, dataStripeVerified]);

    return(
    <KeyboardAvoidingView 
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{backgroundColor:'white', padding:22, height:500, borderRadius:4, borderColor:'rgba(0, 0, 0, 0.1)'}}>
        <TouchableOpacity onPress={()=> {
            setModalVisible(false);
        }}>
        <Text style={{fontSize:24, fontFamily:'Inter-Black'}}>
          {i18n.t('paymentMethods.menupayindividual.create_bank_account')}
        </Text>
        </TouchableOpacity>
      <Text style={{fontSize:16, fontFamily:'Inter-Regular'}}>
        {i18n.t('paymentMethods.menupayindividual.create_bank_account_description')}
      </Text>
      <Text style={{fontSize:16, fontFamily:'Inter-Medium'}}>
        {i18n.t('paymentMethods.menupayindividual.bank_account_holder_name')}
      </Text>
      <TextInput
        ref={bankAccountHolderNameRef}  
        style={{borderWidth:1, borderColor:'rgba(0,0,0,0.1)', borderRadius:4, padding:10, marginTop:10}}
        placeholder={i18n.t('paymentMethods.menupayindividual.bank_account_holder_name')}
        onChangeText={(text)=> setBankAccountHolderName(text)}
        value={bankAccountHolderName}
        defaultValue={bankAccountHolderName}
        onSubmitEditing={()=> bankAccountRoutingNumberRef.current.focus()}
        />
        <Text style={{fontSize:16, fontFamily:'Inter-Medium', paddingTop:8}}>
        {i18n.t('paymentMethods.menupayindividual.bank_account_routing_number')}
        </Text>
        <TextInput
        ref={bankAccountRoutingNumberRef}
        style={{borderWidth:1, borderColor:'rgba(0,0,0,0.1)', borderRadius:4, padding:10, marginTop:10}}
        placeholder={i18n.t('paymentMethods.menupayindividual.bank_account_routing_number_description')}
        onChangeText={(text)=> {
            //capitalize all letters
            setBankAccountRoutingNumber(text.toUpperCase());
        }}
        onSubmitEditing={()=> bankAccountNumberRef.current.focus()}
        value={bankAccountRoutingNumber}
        />

        <Text style={{fontSize:16, fontFamily:'Inter-Medium', paddingTop:8}}>
        {i18n.t('paymentMethods.menupayindividual.bank_account_number')}
        </Text>
        <TextInput
        ref={bankAccountNumberRef}
        style={{borderWidth:1, borderColor:'rgba(0,0,0,0.1)', borderRadius:4, padding:10, marginTop:10}}
        placeholder={i18n.t('paymentMethods.menupayindividual.bank_account_number_description')}
        onChangeText={(text)=> setBankAccountNumber(text)}
        value={bankAccountNumber}
        onSubmitEditing={()=> bankAccountSubmitRef.current.focus()}
        />
        <TouchableOpacity
        ref={bankAccountSubmitRef}
        onPress={()=> {
            console.log("bankAccountHolderName: ", bankAccountHolderName);
            // refechtDataUserPaymentOption();
            if(bankAccountHolderName.length < 1){
                Alert.alert("Invalid", "Please enter a valid bank account holder name.");
            }else if(bankAccountRoutingNumber.length < 1){
                Alert.alert("Invalid", "Please enter a valid bank account routing number.");
            }else if(bankAccountNumber.length < 1){
                Alert.alert("Invalid", "Please enter a valid bank account number.");
            }else{
                //create stripe bank account
                const createStripeExternalBankAccount = httpsCallable(functions, 'createStripeExternalBankAccount');

                createStripeExternalBankAccount({
                    userStripeId: stripeUserId,
                    country_code: userCountryCode,
                    currency: userCurrencyCode,
                    accountHolderName: bankAccountHolderName,
                    routingNumber: bankAccountRoutingNumber,
                    accountHolderType: 'individual',
                    accountNumber: bankAccountNumber,
                }).then((result) => {
                    console.log("result", result);
                    // setBankAccountModal(false);
                    Alert.alert(
                        i18n.t('paymentMethods.menupayindividual.add_bank_account_success_title'),
                        i18n.t('paymentMethods.menupayindividual.add_bank_account_success'),
                    );
                    setModalVisible(false);
                }).catch((error) => {
                    console.log("error", error);
                    Alert.alert("Error", error.message);
                });
            }
        }}>
            <View style={{backgroundColor:'#FF6D00', padding:10, borderRadius:4, marginTop:10}}>
                <Text style={{color:'white', textAlign:'center', fontFamily:'Inter-Bold'}}>
                    {i18n.t('paymentMethods.menupayindividual.create_account')}
                </Text>
            </View>
        </TouchableOpacity>

      </KeyboardAvoidingView>
)}



const CardRoute = ({id, setModalVisible}) => {
    
    const {createToken } = useStripe();
    const _createToken = async (type, params) => {
        //create token from cardField details
        console.log("params: ", params)
        const { token, error } = await createToken({
            type:"Card",
            params,
            currency: userCurrencyCode,
        });
        if (error) {
            console.log('error', error);
        }else if(token){
            console.log('token', token);
            // create stripe external account
            const createStripeExternalCardAccount = httpsCallable(functions, 'createStripeExternalCardAccount');
            createStripeExternalCardAccount({
                userStripeId: stripeUserId,
                token: token.id,
            }).then((result) => {
                console.log("result", result);
            }).catch((error) => {
                console.log("error", error);
                // Alert.alert("Error", error.message);
                if(error.message === "This card doesn't appear to be a debit card."){
                    Alert.alert(i18n.t('paymentMethods.menupayindividual.card_not_debit_title'),
                    i18n.t('paymentMethods.menupayindividual.card_not_debit'));
                }
            });
        }

    }

    function buildTestTokenParams(type){
        switch(type){
            case 'card':
                return {
                    type:"Card",
                    number: '4242424242424242',
                    exp_month: 12,
                    exp_year: 2021,
                    cvc: '123',
                    currency: userCurrencyCode,
                };
            case 'bank_account':
                return {
                    type:"BankAccount",
                    country: 'US',
                    currency: 'usd',
                    account_holder_name: 'Jenny Rosen',
                    account_holder_type: 'individual',
                    routing_number: '110000000',
                    account_number: '000123456789',
                };
            default:
                throw new Error('Unhandled token type.');
            }
    }

    const [cardAccountHolderName, setCardAccountHolderName] = useState('');
    const [cardAccountNumber, setCardAccountNumber] = useState('');
    const [cardAccountExpMonth, setCardAccountExpMonth] = useState('');
    const [cardAccountExpYear, setCardAccountExpYear] = useState('');
    const [cardAccountCvc, setCardAccountCvc] = useState('');
    const [userCountryCode, setUserCountryCode] = useState('');
    const [userCurrencyCode, setUserCurrencyCode] = useState('');
    const [stripeUserId, setStripeUserId] = useState('');
    const [cardDetails, setCardDetails] = useState({});

    /// cardAccountHolderNameRef ref on textinput
    const cardAccountHolderNameRef = useRef(null);
    const cardAccountNumberRef = useRef(null);
    const cardAccountExpMonthRef = useRef(null);
    const cardAccountExpYearRef = useRef(null);
    const cardAccountCvcRef = useRef(null);
    const cardAccountSubmitRef = useRef(null);
    const {modalVisible, handleModalVisibility} = useToggleModalBankVisibility()

    const {loading: loadingUserPaymentOption, error: errorUserPaymentOption, data: dataUserPaymentOption, refetch:refechtDataUserPaymentOption} = useQuery(GET_USER_PAYMENT_OPTION_BY_ID, {variables: {id:id}});
    const {loading: loadingStripeVerified, error: errorStripeVerified, data: dataStripeVerified} = useSubscription(GET_USER_PAYMENT_STRIPE_VERIFIED, {variables: {id:id}})

    useEffect(()=>{
        let newCardAccountHolderName;
        // let newCardAccountNumber;
        // let newCardAccountExpMonth;
        // let newCardAccountExpYear;
        // let newCardAccountCvc;
        let newUserCountryCode;
        let newUserCurrencyCode;
        let newStripeUserId;

        console.log("modalVisible: ", modalVisible);

        if(dataUserPaymentOption){
            const {user_country_payment_option_by_pk} = dataUserPaymentOption;
            newCardAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
            newUserCountryCode = user_country_payment_option_by_pk.country_payment_option.country.country_code;
            newUserCurrencyCode = user_country_payment_option_by_pk.user.user_currencies[0].user_currency_code;
        }
        if(dataStripeVerified){
            const {user_country_payment_option} = dataStripeVerified;
            newStripeUserId = user_country_payment_option[0]["user_stripe_id"];
        }
        setCardAccountHolderName(newCardAccountHolderName);
        setUserCountryCode(newUserCountryCode);
        console.log("newUserCountryCode: ", newUserCountryCode);
        setUserCurrencyCode(newUserCurrencyCode);
        setStripeUserId(newStripeUserId);

    }, [dataUserPaymentOption, dataStripeVerified]);

    return(
    <KeyboardAvoidingView 
    behavior={Platform.OS === "ios" ? "padding" : "height"}
    style={{backgroundColor:'white', padding:22, height:500, borderRadius:4, borderColor:'rgba(0, 0, 0, 0.1)'}}>
    <Text style={{fontSize:24, fontFamily:'Inter-Black'}}>
        {i18n.t('paymentMethods.menupayindividual.create_card_account')}
      </Text>
    <Text style={{fontSize:16, fontFamily:'Inter-Regular'}}>
      {i18n.t('paymentMethods.menupayindividual.create_card_account_description')}
    </Text>
        <CardField  
        
        postalCodeEnabled={false}
        countryCode={userCountryCode}
        // currencyCode={userCurrencyCode}
        placeholder={{
            number: i18n.t('paymentMethods.menupayindividual.card_number'),
            expiration: i18n.t('paymentMethods.menupayindividual.expiration'),
            cvc: i18n.t('paymentMethods.menupayindividual.cvc'),
        }}
        style={{
            width: '100%',
            height: 50,
            marginVertical: 30,
        }}
        onCardChange={(cardDetailsField) => {
            // console.log('cardDetails', cardDetails);
            // setCardDetails(cardDetails); and add currencyCode to cardDetails

            setCardDetails({
                ...cardDetailsField,
                currency: userCurrencyCode,
            });

            console.log('cardDetails', cardDetails);

        }}
        
        onFocus={(focusedField) => {
            console.log('focusField', focusedField);
        }}
        />
         <TouchableOpacity
        onPress={()=> {
            console.log("stripeUserId: " + stripeUserId);
            
            _createToken("Card", cardDetails);
        }}
        style={{backgroundColor:'#000', padding:10, borderRadius:4, marginTop:5}}>
        <Text style={{color:'#fff', textAlign:'center', fontFamily:'Inter-Black'}}>
            {i18n.t('paymentMethods.menupayindividual.create_card_account')}
        </Text>
        </TouchableOpacity> 
    </KeyboardAvoidingView>
)}

const MenuPayIndividual = ({navigation, route}) => {
    /**Route.params */
    const {item} = route.params;
    const {id} = route.params;

    console.log("ID PAYMENT OPTION: ", id);
    /**Firebase UID */
    const uid= getAuth().currentUser.uid;
    /**States */
    const [stripeUserId, setStripeUserId] = useState('');
    const [stripeUserClicked, setStripeUserClicked] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userCountry, setUserCountry] = useState('');
    const [userCountryCode, setUserCountryCode] = useState('');
    const [userCurrencyCode, setUserCurrencyCode] = useState('');
    const [paymentOptionId, setPaymentOptionId] = useState('');
    const [stripeUserVerified, setStripeUserVerified] = useState(false);
    const [stripeDisabledReason, setStripeDisabledReason] = useState('');
    const [stripeCurrentlyDue, setStripeCurrentlyDue] = useState([]);
    const [stripeEventuallyDue, setStripeEventuallyDue] = useState([]);

    const [index, setIndex] = useState(0);
    const [routes] = useState([
        {key: 'first', title: i18n.t('paymentMethods.menupayindividual.add_bank')},
        {key: 'second', title: i18n.t('paymentMethods.menupayindividual.add_card')},
    ]);


    const [bankAccountModal, setBankAccountModal] = useState(false);
    const [bankAccountHolderName, setBankAccountHolderName] = useState('');
    const [bankAccountRoutingNumber, setBankAccountRoutingNumber] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');

    const [cardAccountModal, setCardAccountModal] = useState(false);
    const [cardAccountHolderName, setCardAccountHolderName] = useState('');
    const [cardAccountNumber, setCardAccountNumber] = useState('');
    const [cardAccountExpMonth, setCardAccountExpMonth] = useState('');
    const [cardAccountExpYear, setCardAccountExpYear] = useState('');
    const [cardAccountCvc, setCardAccountCvc] = useState('');
    

    const [userCustomerId, setUserCustomerId] = useState('');

    const [stripeExternalAccounts, setStripeExternalAccounts] = useState([]);

    const [loading, setLoading] = useState(false);

    const {initPaymentSheet, presentPaymentSheet, confirmPaymentSheetPayment} = useStripe();
    

    //connected accounts
    const [connectedAccounts, setConnectedAccounts] = useState([]);

    /// bankAccountHolderNameRef ref on textinput
    const bankAccountHolderNameRef = useRef(null);
    const bankAccountRoutingNumberRef = useRef(null);
    const bankAccountNumberRef = useRef(null);
    const bankAccountSubmitRef = useRef(null);

    /// cardAccountHolderNameRef ref on textinput
    const cardAccountHolderNameRef = useRef(null);
    const cardAccountNumberRef = useRef(null);
    const cardAccountExpMonthRef = useRef(null);
    const cardAccountExpYearRef = useRef(null);
    const cardAccountCvcRef = useRef(null);
    const cardAccountSubmitRef = useRef(null);

    const {modalVisible, handleModalVisibility} = useToggleModalBankVisibility();

    const isFocused = useIsFocused();

    const updateBankAccountModal = (state) => {
        setBankAccountModal(state);
    }

    // Queries to get user country payment configuration

    const {loading: loadingUserPaymentOption, error: errorUserPaymentOption, data: dataUserPaymentOption, refetch:refechtDataUserPaymentOption} = useQuery(GET_USER_PAYMENT_OPTION_BY_ID, {variables: {id:id}});
    const {loading: loadingStripeVerified, error: errorStripeVerified, data: dataStripeVerified} = useSubscription(GET_USER_PAYMENT_STRIPE_VERIFIED, {variables: {id:id}})

    useEffect(()=>{
        if(isFocused){
            console.log("error", errorUserPaymentOption);
        let newStripeUserId;
        let newStripeUserClicked;
        let newUserEmail;
        let newUserName;
        let newUserLastName;
        let newUserCountry;
        let newUserCountryCode;
        let newUserCurrencyCode;
        let newPaymentOptionId;
        let newStripeUserVerified;
        let newStripeDisabledReason;
        let newStripeCurrentlyDue;
        let newBankAccountHolderName;
        let newCardAccountHolderName;
        let newStripeExternalAccounts;
        let newStripeCustomerId;
        let newStripeEventuallyDue;

        if(dataUserPaymentOption){
            const {user_country_payment_option_by_pk} = dataUserPaymentOption;
            newStripeUserId = user_country_payment_option_by_pk.user_stripe_id;
            newStripeUserClicked = user_country_payment_option_by_pk.user_stripe_clicked;
            newUserEmail = user_country_payment_option_by_pk.user.user_email;
            newUserName = user_country_payment_option_by_pk.user.user_name;
            newUserLastName = user_country_payment_option_by_pk.user.user_last_name;
            newUserCountry = user_country_payment_option_by_pk.country_payment_option.country.country_name;
            newUserCountryCode = user_country_payment_option_by_pk.country_payment_option.country.country_code;
            newUserCurrencyCode = user_country_payment_option_by_pk.user.user_currencies[0].user_currency_code;
            newPaymentOptionId = user_country_payment_option_by_pk.country_payment_option.payment_option.payment_options_id;
            newBankAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
            newCardAccountHolderName = user_country_payment_option_by_pk.user.user_name + " " + user_country_payment_option_by_pk.user.user_lastname;
            console.log("Account holder name: ", newBankAccountHolderName);
        }
        if(dataStripeVerified){
            const {user_country_payment_option} = dataStripeVerified;
            // console.log("user_country_payment_option_by_pk", user_country_payment_option);
            newStripeUserId = user_country_payment_option[0]["user_stripe_id"];
            newStripeUserVerified = user_country_payment_option[0]["user_stripe_object"]? user_country_payment_option[0]["user_stripe_object"]["individual"] ? user_country_payment_option[0]["user_stripe_object"]["individual"]["verification"]["status"] === "verified" ? true : false : false : false;
            newStripeCurrentlyDue = user_country_payment_option[0]["user_stripe_object"]? user_country_payment_option[0]["user_stripe_object"] ? user_country_payment_option[0]["user_stripe_object"]["requirements"]["currently_due"] : null : null;
            newStripeDisabledReason = user_country_payment_option[0]["user_stripe_object"]? user_country_payment_option[0]["user_stripe_object"] ? user_country_payment_option[0]["user_stripe_object"]["requirements"]["disabled_reason"] : null : null;
            newStripeCurrentlyDue = user_country_payment_option[0]["user_stripe_object"]? user_country_payment_option[0]["user_stripe_object"] ? user_country_payment_option[0]["user_stripe_object"]["requirements"]["currently_due"] : null : null;
            newStripeEventuallyDue = user_country_payment_option[0]["user_stripe_object"]? user_country_payment_option[0]["user_stripe_object"] ? user_country_payment_option[0]["user_stripe_object"]["requirements"]["eventually_due"] : null : null;
            console.log("newStripeCurrentlyDue", newStripeCurrentlyDue);
            console.log("newStripeEventuallyDue", newStripeEventuallyDue);
            newStripeExternalAccounts = user_country_payment_option[0]["user_stripe_external_accounts"];
            newStripeCustomerId = user_country_payment_option[0]["user_stripe_customer_id"];
            //convert object to array
            let stripeExternalAccountsArray = [];
            for (const key in newStripeExternalAccounts) {
                stripeExternalAccountsArray.push(newStripeExternalAccounts[key]);
            }
            newStripeExternalAccounts = stripeExternalAccountsArray;
            console.log("stripeCustomerId", newStripeCustomerId);
        }

        setStripeUserId(newStripeUserId);
        setStripeUserClicked(newStripeUserClicked);
        setUserEmail(newUserEmail);
        setUserFirstName(newUserName);
        setUserLastName(newUserLastName);
        // setBankAccountHolderName(newBankAccountHolderName);
        // setCardAccountHolderName(newCardAccountHolderName);
        setUserCountry(newUserCountry);
        setUserCountryCode(newUserCountryCode);
        setUserCurrencyCode(newUserCurrencyCode);
        setPaymentOptionId(newPaymentOptionId);
        setStripeUserVerified(newStripeUserVerified);
        setStripeDisabledReason(newStripeDisabledReason);
        setStripeCurrentlyDue(newStripeCurrentlyDue);
        setStripeEventuallyDue(newStripeEventuallyDue);
        setStripeExternalAccounts(newStripeExternalAccounts);
        setUserCustomerId(newStripeCustomerId);
        console.log("stripeUserId", stripeUserId);
        console.log("stripeUserClicked", stripeUserClicked);
        console.log("paymentOptionId", paymentOptionId);
        // console.log("stripeExternalAccounts", stripeExternalAccounts);
        }
        //subscription 
        const unsubscribe = navigation.addListener('focus', () => {
            refechtDataUserPaymentOption();
            console.log("refechting data");
        });


    }, [isFocused, dataUserPaymentOption , dataStripeVerified])


    const handleStripeSetup = () => {
        console.log("stripeUserId", stripeUserId);
        console.log("stripeUserClicked", stripeUserClicked);
        if(stripeUserVerified){
            console.log("stripeUserId", stripeUserId);
        }else{
            const stripeSetup = httpsCallable(functions, 'createStripeAccount');

            let businessType;

            if(paymentOptionId === 3){
                businessType = "individual";
                console.log("businessType", businessType);
            }

            let email = userEmail;
            if(userEmail === null){
                email = userFirstName + userLastName + "@gmail.com";
            }

            stripeSetup({
                country_code: userCountryCode,
                email: email,
                id: id,
                currency_code: userCurrencyCode,
                businessType: businessType,
            }).then((result) => {
                console.log("result", result.data.url);
                // open stripe url

                Linking.openURL(result.data.url);
            }).catch((error) => {
                console.log("error", error);
                if(error.message === 'stripe-account-created'){
                    Alert.alert("Account already created", "You already have a stripe account created. Please contact support to change your account details.")
                }
            });
        }
    };

    const handleDeleteStripeBankAccount = (item) => {
        console.log("item", item.id);
        Alert.alert(
            i18n.t("paymentMethods.menupayindividual.delete_bank_account"),
            i18n.t("paymentMethods.menupayindividual.delete_bank_account_body"),
            [
                {
                    text: "Cancel",
                    onPress: () => console.log("Cancel Pressed"),
                    style: "cancel"
                },
                {
                    text: i18n.t("paymentMethods.menupayindividual.delete_bank_account_button"),
                    onPress: () => {
                        const deleteStripeExternalBankAccount = httpsCallable(functions, 'deleteStripeExternalBankAccount');
                        deleteStripeExternalBankAccount({
                            userStripeId: stripeUserId,
                            bankAccountId: item.id,
                        }).then((result) => {
                            console.log("result", result);
                        }).catch((error) => {
                            console.log("error", error);
                        });
                    },
                    style: "destructive"
                }
            ]
        );
    };

    const getPaymentSheetParams = async () => {
        const savePaymentMethodSetupIntent = httpsCallable(functions, 'savePaymentMethodSetupIntent');

        let stripeCustomerId = userCustomerId;
        // console.log("stripeCustomerId", stripeCustomerId);
        let ephemeralKeySecret;
        let setupIntent;

        // console.log("userCustomerId", userCustomerId);
        const {data} = await savePaymentMethodSetupIntent({
            userStripeId: stripeUserId,
            userStripeCustomerId: stripeCustomerId,
        });

        console.log("INTENTCLIEEEEEEEENT", data.setupIntent.client_secret);

        ephemeralKeySecret = data.ephemerelKey.secret;
        setupIntent = data.setupIntent.client_secret;
        

        // console.log("ephemeralKeySecret", ephemeralKeySecret);

        return {
            ephemeralKeySecret,
            setupIntent,
            customer: stripeCustomerId,
        };

    };

    const handleAddStripeCard = async () => {
        const {ephemeralKeySecret, setupIntent, customer} = await getPaymentSheetParams();

        console.log("ephemeralKeySecretttttttttttttt", customer);
        const {error} = await initPaymentSheet({
            merchantDisplayName: 'MenuPay',
            customerEphemeralKeySecret: ephemeralKeySecret,
            setupIntentClientSecret: setupIntent,
            customerId: customer,
        });

        if(!error){
           console.log("success sheet");
           //just save the card
            const {error} = await presentPaymentSheet({
                clientSecret: setupIntent,
                locale: 'es',
            });
            
            if(error){
                console.log("error sheet", error);
            }

        }else{
            console.log("error sheet", error);
        }
    }

    const renderScene = ({route}) => {
        switch(route.key){
            case 'first':
                return <BankRoute id={id} setModalVisible={
                    updateBankAccountModal
                }/>
            case 'second':
                return <CardRoute id={id} setModalVisible={
                    updateBankAccountModal
                }/>
            default:
                return null;
        }
    }

    const RenderAccountsItem = ({item, bank_name, currency, default_for_currency, country, account, account_holder_type, currently_due, last4, 
        account_holder_name, routing_number, object, card_brand, available_payout_methods}) => {
        console.log("item", card_brand);
        return(
            <View style={{borderWidth:0.5, borderRadius:6, marginTop:5}}>
                <View style={{flexDirection:'row', alignContent:'center', alignItems:'center', paddingHorizontal:10, paddingVertical:5}}>
                    {/* <Ionicons name="reader-outline" size={24} color="black" /> */}
                    {/* {gray color} */}

                    {/* <FontAwesome name="bank" size={24} color="#BDBDBD" /> */}
                    {object === "bank_account" ? <FontAwesome name="bank" size={24} color="#BDBDBD" /> : <FontAwesome name="credit-card" size={24} color="#BDBDBD" />}
                    {/* <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2}}>
                        {bank_name} - {default_for_currency  ? i18n.t("paymentMethods.menupayindividual.primary_account") : ""}
                    </Text> */}
                    {object === "bank_account" ?(
                        <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:5}}>
                            {bank_name} - {default_for_currency  ? i18n.t("paymentMethods.menupayindividual.primary_account") : ""}
                        </Text>

                    ): object === "card" ? (
                        <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:5}}>
                            {card_brand} - {default_for_currency  ? i18n.t("paymentMethods.menupayindividual.primary_account") : i18n.t("paymentMethods.menupayindividual.secondary_account")}
                        </Text>

                    ): null}

                </View>
                <View style={{flexDirection:'row', alignContent:'center', alignItems:'center', paddingHorizontal:10}}>
                    {/* <Ionicons name="person-circle-outline" size={24} color="black" /> */}
    
                    <FontAwesome name="user-o" size={24} color="#BDBDBD" />
                    <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:5}}>
                        {account_holder_name} - {account_holder_type ? account_holder_type : "Individual"}
                    </Text>
                </View>
                <View style={{flexDirection:'row', alignContent:'center', alignItems:'center', paddingHorizontal:10, paddingVertical:5}}>
                    {/* <Ionicons name="card-outline" size={24} color="black" /> */}
                    {/* <FontAwesome name="credit-card" size={24} color="#BDBDBD" /> */}
                    <Ionicons name="md-caret-forward" size={24} color="#BDBDBD" />
                    {object === "bank_account" ? (
                        <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:5}}>
                            XXXXXXXX {last4}
                        </Text>
                    ): object === "card" ? (
                        <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:5}}>
                            XXXX XXXX XXXX {last4}
                        </Text>
                    ): null}
                    

                    {/* <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:5}}>
                        XXXXXXXX {last4}
                    </Text> */}
                </View>
                <View style={{flexDirection:'row', alignContent:'center', alignItems:'center'}}>
                    <Text style={{fontFamily:"Inter-Medium", fontSize:16, letterSpacing:0.2, paddingLeft:10, paddingBottom:5, flexGrow:1}}>
                        {country} - {currency.toUpperCase()}
                    </Text>
                    <Text style={{fontFamily:"Inter-Medium", paddingRight:10}}>
                        {routing_number}
                    </Text>
                </View>
                <View style={{flexDirection:'row', alignContent:'flex-start', alignItems:'flex-start', paddingHorizontal:10, paddingBottom:5, justifyContent:'flex-start'}}>
                    {/* <Text>
                        {i18n.t("paymentMethods.menupayindividual.available_payout_methods")}
                    </Text> */}
                    {available_payout_methods ? available_payout_methods.map((item, index) => {
                        return(
                            <TouchableOpacity key={index} style={{alignContent:'flex-start'}}
                            >
                                <Text key={index} style={{fontFamily:"Inter-Regular", fontSize:16, letterSpacing:-0.5, paddingLeft:5, flexShrink:1, backgroundColor:'#e0e0e0', paddingHorizontal:2,marginHorizontal:5, borderRadius:6,}}>
                                {item === "standard" ? i18n.t("paymentMethods.menupayindividual.pm_standard") : item === "instant" ? i18n.t("paymentMethods.menupayindividual.pm_instant") : null}
                                </Text>
                            </TouchableOpacity>
                            
                        )
                    }): null
                    }
                </View>

                <View style={{alignContent:'flex-end', alignItems:'flex-end', flexDirection:'row-reverse'}}>
                    <TouchableOpacity style={{padding:2, alignContent:'flex-end'}} onPress={() =>{
                        if(default_for_currency){
                            Alert.alert(i18n.t("paymentMethods.menupayindividual.primary_account"),
                             i18n.t("paymentMethods.menupayindividual.primary_account_delete_error"))
                        }else{
                            handleDeleteStripeBankAccount(item);
                        }
                    }}>
                        <Ionicons name="trash-outline" size={24} color="black" />
                    </TouchableOpacity>
                </View>  
            </View>
        )
    }
        
    const renderAccounts = ({item}) => {
        return(
            <RenderAccountsItem item={item} 
            bank_name={item.bank_name}
            currency={item.currency}
            default_for_currency={item.default_for_currency}
            country={item.country}
            account={item.account}
            object={item.object}
            account_holder_type={item.account_holder_type}
            currently_due={item.currently_due}
            last4={item.last4}
            account_holder_name={item.account_holder_name}
            routing_number={item.routing_number}
            card_brand={item.brand}
            available_payout_methods={item.available_payout_methods}
            />
        )
    }

    const renderTabBar = props => (
        <TabBar
            {...props}
            indicatorStyle={{ backgroundColor: '#FF6D00' }}
            style={{ backgroundColor: 'white' }}
            renderLabel={({ route, focused, color }) => (
                <Text style={{ color: focused ? '#FF6D00' : 'black', margin: 2, fontFamily:'Inter-Medium', fontSize:16 }}>
                    {route.title}
                </Text>
            )}
        />
    );


    if(loadingUserPaymentOption , loadingStripeVerified , !dataUserPaymentOption ,!dataStripeVerified){
        return(
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                <ActivityIndicator size="large" color="#FF6D00" />
            </View>
        )
    }

    return(
        <SafeAreaView style={{flex:1, paddingVertical:-75}}>

        {/* /// modals */}

        <Modal 
        // ={bankAccountModal}
        isVisible={bankAccountModal}
        avoidKeyboard={true}
        coverScreen={false}
        backdropColor={'rgba(0,0,0,0.5)'}  
        backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={()=> setBankAccountModal(false)}
        onBackdropPress={()=> setBankAccountModal(false)}
        style={{elevation:5,justifyContent:'flex-end', margin:0, height:500, flex:1, bottom:0, position:'absolute', width:'100%', borderRadius:6}}>
            <TabView
            navigationState={{index, routes}}
            renderScene={renderScene}
            animationEnabled={false}
            onIndexChange={setIndex}
            keyboardDismissMode='none'

            initialLayout={{width:Dimensions.get('window').width}}
            renderTabBar={renderTabBar}
            />
        </Modal> 

        <View style={{paddingHorizontal:5, paddingTop:0}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontFamily:'Inter-Black', fontSize:28, letterSpacing:0.5}}>
                {i18n.t('paymentMethods.menupayindividual.title')} 
            </Text>
            </View>
            <View>
            <Text style={{fontSize:16, fontFamily:"Inter-Regular", fontStyle:'normal', letterSpacing:0.3}}>
                {i18n.t('paymentMethods.menupayindividual.body', {
                    country_name: userCountry,
                })}
            </Text>
        </View>
        <View style={{paddingTop:10}}>
            {stripeUserVerified ? (
                <Text style={{color:"blue", fontFamily:"Inter-Medium", letterSpacing:0.5}}>
                    {i18n.t('paymentMethods.menupayindividual.verified_statement')}
                </Text>
            ):(
                <Text style={{color:"red"}}>
                    {i18n.t('paymentMethods.menupayindividual.unverified_statement')}
                </Text>
            )}
            {/* //check for eventually_due  "verification.document" */}
            {stripeEventuallyDue && stripeEventuallyDue.includes('individual.verification.document') ? (
                <Text style={{color:"red"}}>
                   - {i18n.t('paymentMethods.menupayindividual.verification_document')}
                </Text>
            ):null}

        </View>
        <View style={{paddingTop:10}}>
            <TouchableOpacity style={{backgroundColor:'#304FFE', padding:8, borderRadius:3, justifyContent:'center'}} 
            onPress={handleStripeSetup}
            >
                <Text style={{fontFamily:'Inter-Regular', fontSize:18, textAlign:'center', color:"#ffffff", letterSpacing:-0.5}}>
                    {stripeUserVerified ? i18n.t('paymentMethods.menupayindividual.account_w_setup') : i18n.t('paymentMethods.menupayindividual.account_wo_setup')}
                </Text>
            </TouchableOpacity>
        </View>
        <View style={{paddingTop:15}}>
            {(stripeCurrentlyDue && stripeCurrentlyDue.includes('external_account') ) ? (
                <>
                {stripeDisabledReason === 'requirements.past_due' ? (
                    <>
                    <Text style={{fontFamily:'Inter-Regular', fontSize:16, color:"red"}}>
                    {i18n.t('paymentMethods.menupayindividual.external_account_needed')}
                </Text>
                <TouchableOpacity style={{alignItems:'center', backgroundColor:"#e0e0e0", flexGrow:1, padding:8, borderRadius:8}} onPress={()=>{
                    setBankAccountModal(true);
                }}>
                    <Text style={{fontFamily:'Inter-Bold', fontSize:16, color:"black"}}>
                        {i18n.t('paymentMethods.menupayindividual.create_bank_account')}
                    </Text>
                </TouchableOpacity>
                    </>
                ):null}
                </>
            ):null}

            {(!stripeCurrentlyDue || !stripeCurrentlyDue.includes('external_account')) ? (
                <View style={{flexDirection:'row'}}>
                    {(stripeUserClicked) ? (
                        <TouchableOpacity style={{alignItems:'center', justifyContent:"center",backgroundColor:"#e0e0e0", padding:8, borderRadius:6, flexGrow:1, flex:1, marginHorizontal:10, flexDirection:"row"}} onPress={()=>{
                            setBankAccountModal(true);
                        }}>
                            <FontAwesome name="bank" size={18} color="black" style={{marginRight:5}}/>
                            <Text style={{fontFamily:'Inter-Medium', fontSize:16, color:"black", letterSpacing:-0.5}}>
                                {i18n.t('paymentMethods.menupayindividual.add_bank_account')}
                            </Text>
                        </TouchableOpacity>
                    ):null}
                    <TouchableOpacity style={{alignItems:'center', alignContent:"center", justifyContent:'center',backgroundColor:"#e0e0e0", padding:6, borderRadius:8, flexGrow:1, flex:1, marginHorizontal:10, flexDirection:"row"}} onPress={()=>{
                        handleAddStripeCard();
                }   
                    }>
                        <Ionicons name="card-outline" size={24} color="black" style={{marginRight:5}}/>
                        <Text style={{fontFamily:'Inter-Medium', fontSize:16, color:"black", letterSpacing:-0.5}}>
                            {i18n.t('paymentMethods.menupayindividual.add_card')}
                        </Text>
                    </TouchableOpacity>
                </View>
           
            ):null}

            {(!stripeCurrentlyDue || !stripeCurrentlyDue.includes('external_account')) ? (
                <>
                <Text style={{fontFamily:'Inter-Bold', fontSize:20, color:"black", letterSpacing:0.3, paddingBottom:5, paddingTop:5}}>
                    {i18n.t('paymentMethods.menupayindividual.linked_accounts')}
                </Text>
                <FlatList
                data={stripeExternalAccounts}
                renderItem={renderAccounts}
                >
                </FlatList>
                </>
            ):null}
        </View>

        </View>
    </SafeAreaView>
    )
}
export default MenuPayIndividual;

