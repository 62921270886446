import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert,TextInput, TouchableOpacity, Modal } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from '@apollo/client';
import { GET_PROVINCE_BY_COUNTRY } from '../../lib/queries';
import i18n from '../../i18n/i18n';
import { useIsFocused } from '@react-navigation/native';


const Province = ({navigation, route}) => {

    const isFocused = useIsFocused();

    /**Route.params */
    const {countryId} = route.params;
    const {name} = route.params;
    const {lastname} = route.params;
    // const {phone} = route.params;

    /**Queries */
    const {loading: loadingProvinces, data: dataProvinces, error: errorProvinces, refetch: refetchProvinces} = useQuery(GET_PROVINCE_BY_COUNTRY, {variables: {countryId}});

    /**State */
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState(0);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);

    useEffect(() => {
        // console.log("CountryId", countryId);
        // console.log(errorProvinces)
      if(isFocused){
        if(dataProvinces){
            setProvinces(dataProvinces.provinces);
            // console.log(dataProvinces.provinces);
          }
        refetchProvinces();
      }
    }, [dataProvinces, provinces])
    return(
        <SafeAreaView style={{flex:1}}>
            
                                <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
                        </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>
            <Text style={{fontSize:36, fontWeight:'bold'}}>
                {i18n.t('setup.province.title')}
            </Text>
            </View>
            <View style={{paddingTop:25}}>
                <Text style={{fontSize:22}}>
                    {i18n.t('setup.province.body')}
                </Text>
            </View>
        </View>
        <View style={{flex:2}}>
            {/* <TextInput style={{paddingHorizontal:12, backgroundColor:getColor('gray-200'), paddingVertical:12,
             marginHorizontal:15, fontSize:18}} /> */}
    
            {(!loadingProvinces && provinces)?(
                <Picker
                itemStyle={{height:100}}
                selectedValue={selectedProvince}
            onValueChange={(itemValue, itemIndex) => {setSelectedProvince(itemValue)}}>
               <Picker.Item label={i18n.t('setup.province.picker_province_label')} 
                      value="0" key="0"/>
              {
                provinces.map((provinces, index) => {
                    return(
                        <Picker.Item label={provinces.province_name} 
                        value={provinces.province_id} key={index}/>
                    )
                })
              }
        
        
          </Picker> 
            ):null}

            
        </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{
                if(selectedProvince != 0){    
                    navigation.navigate('CityScreen', {
                        provinceId: selectedProvince,
                        countryId, 
                        lastname,
                        name,
                        // phone,
                    });
                }else{
                    Alert.alert(i18n.t('setup.province.error'), i18n.t('setup.province.error_body'));
                    if(Platform.OS === 'web'){
                        setMessageTitle(i18n.t('setup.province.error'));
                        setMessageBody(i18n.t('setup.province.error_body'));
                        setModalVisibilityAlert(true);
                    }
                }
                }}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('setup.province.btn_next')}</Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
    </SafeAreaView>
    )
    
}

export default Province;