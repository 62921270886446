import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity , VirtualizedList, Alert, Modal, ActivityIndicator, FlatList} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {Picker} from '@react-native-picker/picker';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PRODUCT_DELIVERY_OPTIONS_BY_ID, DELETE_PRODUCT_DELIVERY_OPTION_BY_PRODUCT_ID, COUNTRY_DELIVERY_OPTIONS_NOT_SELECTED, INSERT_DELIVERY_OPTION_ONE } from '../../../lib/queries';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
import i18n from '../../../i18n/i18n';

const auth = getAuth(Firebase);

const EditDeliveryOption = ({navigation : { setParams }, route}) => {

  
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();

  /**route.params */

  const {productId } = route.params
  const {product_id} = productId

  const {countryDeliveryOptionsId} = route.params
  const {countryDeliveryOptions_Id} = countryDeliveryOptionsId

  /**States */
  const uid = getAuth().currentUser.uid;
  const [deliveryOptions, setDeliveryOptions] = useState()

  /**Query deliveryOption */
  const {loading: loadingDeliveryOptions, error: errorDeliveryOptions, data: dataDeliveryOptions, refetch: refetchDeliveryOptions} = useQuery(GET_PRODUCT_DELIVERY_OPTIONS_BY_ID, {variables:{productId:product_id, languageCode }})
  
  /**Query COUNTRY_DELIVERY_OPTIONS_NOT_SELECTED */

  const {loading: loadingDeliveryOptionsNotSelected, error: errorDeliveryOptionsNotSelected, data: dataDeliveryOptionsNotSelected, refetch: refetchDeliveryOptionsNotSelected} = useQuery(COUNTRY_DELIVERY_OPTIONS_NOT_SELECTED, {variables:{uid,
    countryDeliveryOptionsId:countryDeliveryOptions_Id, languageCode }})

  /**Const color */
  const [arrayDeliveryOptions, setArrayDeliveryOptions] = useState([])
  const [selectedDeliveryOptions, setSelectedDeliveryOptions] = useState([])

  const [arrayDeliveryOptionsId, setArrayDeliveryOptionsId] = useState([])

  /**mutation */

  const [deleteDeliveryOption, {loading: loadingDeleteDeliveryOption, error: errorDeleteDeliveryOption}] = useMutation(DELETE_PRODUCT_DELIVERY_OPTION_BY_PRODUCT_ID)
  const [insertDeliveryOption, {loading: loadingInsertDeliveryOption, error: errorInsertDeliveryOption}] = useMutation(INSERT_DELIVERY_OPTION_ONE)
  
  
  useEffect(()=>{

   if(dataDeliveryOptions){
     const {product_delivery_options} = dataDeliveryOptions
    //  console.log(product_delivery_options[0]['country_delivery_option'])
     setDeliveryOptions(product_delivery_options) 
    
   }
   let newDeliveryOptionsNotSelected = []
   if(dataDeliveryOptionsNotSelected){
     const {user_locations} = dataDeliveryOptionsNotSelected

      newDeliveryOptionsNotSelected = user_locations[0]['country']['country_delivery_options']
    //  console.log(user_locations)
   }
    setArrayDeliveryOptions(newDeliveryOptionsNotSelected)

    // console.log(newDeliveryOptionsNotSelected)
  }, [dataDeliveryOptions, dataDeliveryOptionsNotSelected]);

  /**select unselect deliveryoption */



  const Item = ({item, index}) =>{
    return(
    <View>
    <TouchableOpacity style={{backgroundColor:'#64b5f6', paddingVertical:10, marginTop:1, borderRadius:6}} key={index} onPress={()=>{
      // console.log("Edit")
    Alert.alert(
        "Edit",
        "Select an option",
        [
            {
                text: "Cancel",
                onPress:()=>{},
                style: "cancel"
            },
            {
                text: "Delete",
                onPress:()=>{
                    // console.log("Delete")
                    Alert.alert(
                        "Delete",
                        "Are you sure you want to delete this option?",
                        [
                            {
                                text: "Cancel",
                                onPress:()=>{},
                                style: "cancel"
                            },
                            {
                                text: "Delete",
                                onPress:()=>{
                                  let newArrayCountryDeliveryOptionsId = [...route.params.countryDeliveryOptionsId.countryDeliveryOptions_Id]

                                  newArrayCountryDeliveryOptionsId.splice(index, 1)
                                  // console.log(newArrayCountryDeliveryOptionsId)
                                  setParams({
                                    countryDeliveryOptionsId:{countryDeliveryOptions_Id:newArrayCountryDeliveryOptionsId},
                                  })

                                    deleteDeliveryOption({
                                        variables:{
                                            productId:product_id,
                                            countryDeliveryOptionId:item['country_delivery_option'].country_delivery_option_id
                                        }
                                    }).then(()=>{
                                        refetchDeliveryOptions()
                                    })
                                }
                            }
                        ]
                    )
                },
                style: "cancel"
            }
        ],
        {cancelable: false}
    )
}}>
        <Text key={index} style={{textAlign:'center', fontSize:16}}>{item['country_delivery_option']['delivery_option']['delivery_option_translations'][0].delivery_option_name}</Text>
    </TouchableOpacity>
    </View>
  );
    }

  const renderItem = ({item, index}) => <Item
    index={index}
    item={item}
    />;
    

   

    /**ListFooter */

    const ItemDeliveryNotSelected = ({item, index}) =>{
      return(
        <View>
          <TouchableOpacity style={{backgroundColor:'#e0e0e0', marginTop:1, paddingVertical:10}} onPress={()=>{
            // console.log(item.country_delivery_option_id)

            Alert.alert("Add", "Select an option", [
                {
                    text: "Cancel",
                    OnPress:()=>{},
                    style: "cancel"
                },
                {
                    text: "Add",
                    onPress:()=>{
                        // console.log("Add")
                        /**push item.country_delivery_option_id  */
                        let newArrayCountryDeliveryOptionsId = [...route.params.countryDeliveryOptionsId.countryDeliveryOptions_Id]
                        newArrayCountryDeliveryOptionsId.push(item.country_delivery_option_id)

                        // console.log(newArrayCountryDeliveryOptionsId)
                        setParams({
                          countryDeliveryOptionsId:{
                            countryDeliveryOptions_Id:newArrayCountryDeliveryOptionsId,
                          }
                        })

                        insertDeliveryOption({
                            variables:{
                                productId:product_id,
                                countryDeliveryOptionId:item.country_delivery_option_id,
                            }
                        }).then(()=>{
                            refetchDeliveryOptions()
                            refetchDeliveryOptionsNotSelected()

                        })
                    },
                    style: "cancel"
                }
            ], {cancelable: false})
          }}>

        <Text key={index} style={{textAlign:'center', fontSize:16}}>{item['delivery_option']['delivery_option_translations'][0].delivery_option_name}</Text>
          </TouchableOpacity>
         </View>
      )
    }

    const renderDeliveryOptionsNotSelected = ({item, index}) => {
    return(
<ItemDeliveryNotSelected
    item={item}
    index={index}
    />
    );
    }

    const ListFooter = () => {
      return(
        <View style={{paddingTop:5}}>
        <Text style={{fontSize:18, fontWeight:'bold'}}>
          {i18n.t('products.editDeliveryOptions.add_new_delivery')}
        </Text>
        <Text style={{fontSize:16, paddingTop:5, paddingBottom:5}}>
          {i18n.t('products.editDeliveryOptions.add_new_delivery_body')}
        </Text>
        {(arrayDeliveryOptions.length < 1) ?(
          <Text style={{textAlign:'center', fontSize:16, color:'#9e9e9e'}}>{
            i18n.t('products.editDeliveryOptions.no_delivery_options')
          }</Text>
        ): null}
        <FlatList data={arrayDeliveryOptions} renderItem={renderDeliveryOptionsNotSelected} keyExtractor={item => item.country_delivery_option_id.toString()}/>
        </View>
      )
    }

    return(
        <SafeAreaView style={{flex:1, justifyContent:'center'}}>
          <Modal 
            visible={loadingDeleteDeliveryOption}
            style={{flex:1, justifyContent:'center', alignItems:'center'}} 
            animationType="none"
            transparent={true}>
                <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                {/* <Text>Hey</Text> */}
                <ActivityIndicator size="large" color='#1e76e8' />
                {/* <Button title="Close" onPress={()=>setVisibleModal(false)}/> */}
                </View>
                
            </Modal>
        <View style={{paddingTop:5, paddingHorizontal:5}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Text style={{fontSize:18, fontWeight:'bold'}}>
              {i18n.t('products.editDeliveryOptions.title')}
            </Text>
            </View>
            <View style={{paddingVertical:5}}>
            <Text style={{fontSize:18}}>
              {i18n.t('products.editDeliveryOptions.edit_product_delivery')}
            </Text>
        </View>
        <View>
          
          {(loadingDeliveryOptions)?(
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
            <ActivityIndicator size="large"  color='#1e76e8'/>
            </View>

          ):(
            <FlatList data={deliveryOptions} renderItem={renderItem} ListFooterComponent={ListFooter} keyExtractor={item => {return item['country_delivery_option'].country_delivery_option_id.toString();}}/>
          )}  

        </View>
        </View>
        <View style={{flex:2}}>
        </View>

    </SafeAreaView>
    )

}

export default EditDeliveryOption;