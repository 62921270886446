import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, Alert, Image, ScrollView, Touchable, Share } from 'react-native';
import {Picker} from '@react-native-picker/picker'; 
import { useQuery, useMutation } from '@apollo/client';
import { Feather, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_USER, UPDATE_USER_NAME, UPDATE_USER_LASTNAME, UPDATE_USER_PHOTO, UPDATE_USER_THUMBNAIL, GET_IF_CHAT_EXISTS, CREATE_CHAT_FROM_PRODUCT } from '../lib/queries';
import i18n from '../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'; 
import Modal from 'react-native-modal'
import QRCode from 'react-native-qrcode-svg';
import RNQRGenerator from 'rn-qr-generator';
import * as Sharing from 'expo-sharing';
const auth = getAuth(Firebase);
const storage = getStorage(Firebase);

const UserPublicProfile = ({navigation, route}) => {

  const shareProfile = async () => {
    try {
      const payload ={
        dynamicLinkInfo:{
            domainUriPrefix: 'https://menucitos.page.link',
            link: 'https://menucitos.com/drawer/usersettings/profile/public/'+user_id,
            androidInfo:{
                androidPackageName:'com.nelson_arguera.menu',
            },
            iosInfo:{
                iosBundleId: 'com.nelsonarguera.menu',
                iosAppStoreId: 'id1643431406',
            },
            socialMetaTagInfo:{
                socialTitle: user_name + ' ' + user_lastname,
                socialDescription: i18n.t('userSettings.profile.check_out_my_profile'),
                socialImageLink: user_thumbnail,
            }
        },
        /**ios */
    };

    let apiKey ="AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
    // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
    const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
    let json;
    let shortLink;
    const response = await fetch(url, {
        method: 'POST',
        headers:{
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
    }).then(res => {
        res.json().then(async (data) => {
            console.log(data);
            json = data;
            const result = await Share.share({
            message: json.shortLink,
            url: json.shortLink,
            title: 'Share via',
            });
        })
    }).catch(err => {console.log("WHAT",JSON.stringify(err))});
    } catch (error) {
      console.log(error)
    }
  }

  const { user_id } = route.params;
  
  // const uid = getAuth().currentUser.uid;
  const [editing, setEditing] = useState(false);
  const [uid, setUid] = useState(null);
  const [editingLastName, setEditingLastName] = useState(false)
  const [user_name, setuser_name] = useState('');
  const [user_lastname, setuser_lastname] = useState('')
  const [user_email, setuser_email] = useState('')
  const [user_photo, setuser_photo] = useState('')
  const [user_thumbnail, setuser_thumbnail] = useState('')
  const [selected_language, setSelected_language] = useState(0);
  const [chatId, setChatId] = useState();
  const [chatMyselfId, setChatMyselfId] = useState();
  const [sellerExpoToken, setSellerExpoToken] = useState();
  const [modalQR, setModalQR] = useState(false);
  const [shortURL, setShortURL] = useState('');

  const { loading: fetchLoading, data } = useQuery(GET_USER, { variables: { uid: user_id }});
  const {loading: loadingChat, data: dataChat, error: errorChat} = useQuery(GET_IF_CHAT_EXISTS, {variables: {recipientId: user_id, senderId: uid}})

  // const [ updateUser, { loading: mutationLoading, data: response } ] = useMutation(UPDATE_USER_NAME);
  // const [ updateLastName, {loading: mutationLoadingLastName, data: responseLastName}] = useMutation(UPDATE_USER_LASTNAME)

  // const [updateUserPhoto, {loading: mutationLoadingPhoto, data: responsePhoto}] = useMutation(UPDATE_USER_PHOTO)
  // const [updateUserThumbnail, {loading: mutationLoadingThumbnail, data: responseThumbnail}] = useMutation(UPDATE_USER_THUMBNAIL)

  const [createChat, {loading:createChatLoading, data:createChatData, error:createChatError}] = useMutation(CREATE_CHAT_FROM_PRODUCT, {variables: {recipientId: user_id, senderId: uid}});
  const [createChatMyself, {loading:createChatMyselfLoading, data:createChatMyselfData, error:createChatMyselfError}] = useMutation(CREATE_CHAT_FROM_PRODUCT);
  
  useEffect(() => {

    getAuth().onAuthStateChanged(function(user) {
      if (user) {
        setUid(user.uid)
      } else {
        // No user is signed in.
        setUid(null);
      }
    });

    let newName = null;
    let newLastName = null;
    let newUserEmail = null;
    let newPhoto = null;
    let newThumbnail = null;
    let newExpoToken = null;
    
    // console.log("FIREBASEUID: ", uid)
    // console.log('error: ', errorChat)
    if (data) {

      // console.log("DATOS: ", data)
      const { users_by_pk: user } = data;
      const { user_name } = user;
      const { user_lastname } = user;
      const { user_email} = user;
      const { user_photo } = user;
      const { user_thumbnail } = user;
      const { user_expo_token } = user;

      
      // if (user_name === null){
      //   setuser_name('Empty')
      //   // console.log(user_name)
      // }
      {/* if a name already exists, set it as the name */}
      newName = user_name;
      newLastName = user_lastname
      newUserEmail = user_email
      newPhoto = user_photo
      newThumbnail = user_thumbnail
      newExpoToken = user_expo_token
      // console.log('newPhoto: ', newPhoto)
    }  
    if (dataChat) {
        const { chat } = dataChat;
        if(Object.keys(chat).length === 0){
            setChatId(null)
        }else{
            setChatId(chat[0].chat_id);
        }

        const { chat_myself } = dataChat;
        if(Object.keys(chat_myself).length === 0){
            setChatMyselfId(null)
        }else{
            setChatMyselfId(chat_myself[0].chat_id);
        }
    }  
    // if (response) {
    //   // console.log("DATOS: ", response)
    //   const { update_users_by_pk } = response;
    //   const { user_name } = update_users_by_pk;

    //   {/* when a user updates their name, set that as the name */}
    //   newName = user_name;
    // }

    // if(responsePhoto){
    //   const { update_users_by_pk } = responsePhoto;
    //   const { user_photo } = update_users_by_pk;

    //   newPhoto = user_photo;
    // }

    // if(responseThumbnail){
    //   const { update_users_by_pk } = responseThumbnail;
    //   const { user_thumbnail } = update_users_by_pk;

    //   newThumbnail = user_thumbnail;
    // }

    setuser_name(newName);
    setuser_lastname(newLastName)
    setuser_email(newUserEmail)
    setuser_photo(newPhoto)
    setuser_thumbnail(newThumbnail)
    setSellerExpoToken(newExpoToken)

    // console.log('language: ', language)
  }, [data, dataChat, uid]);


  if (fetchLoading || loadingChat) return (
    // flex-1 justify-center items-center
    <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
      <ActivityIndicator color={'#1e76e8'} size={"small"}/>
    </SafeAreaView>
  )
  return (
    <SafeAreaView style={{flex:1}}>
         <Modal isVisible={modalQR}
        backdropColor={'rgba(0,0,0,0.5)'}
          // backdropColor='black'
          backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={()=> setModalQR(false)}
        onBackdropPress={()=> setModalQR(false)}
        style={{elevation:5,justifyContent:'flex-end', margin:0, height:450, flex:0, bottom:0, position:'absolute', width:'100%', borderRadius:6}}
        >
          <View style={{backgroundColor:'white', padding:22, height:450, borderRadius:10, borderColor:'rgba(0, 0, 0, 0.1)'}}>
          <TouchableOpacity onPress={()=> setModalCategoryFilter(false)}>
            <Text style={{fontSize:24, fontFamily:'Inter-Black'}}>
              {i18n.t('userSettings.profile.qr_code')}
            </Text>
          </TouchableOpacity>
          <Text style={{fontSize:16, fontFamily:'Inter-Regular'}}>
            {i18n.t('userSettings.profile.qr_code_body')}
          </Text>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10}}>
          <QRCode value={shortURL} size={200}/>
          </View>

          <TouchableOpacity style={{backgroundColor:'#1e76e8', padding:10, borderRadius:16, marginTop:10}}
          onPress={()=>{
            if(Platform.OS !== 'web'){
              RNQRGenerator.generate({
                value: shortURL,
                height: 200,
                width: 200,
                color: '#000000',
              }).then(response =>{
                
                Sharing.shareAsync(response.uri)
  
              }).catch(error => {
                console.log(error);
              });  
            }
          }}>
            <Text style={{color:'#fff', fontSize:16, fontFamily:'Inter-Black', textAlign:'center'}}>
              {i18n.t('userSettings.profile.qr_code_share')}
            </Text>
          </TouchableOpacity>

          </View>
    </Modal>
      <ScrollView style={{flex:1, backgroundColor:'#fff'}}>
        <View style={{paddingHorizontal:5, paddingTop:-45}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
              {i18n.t('userSettings.profile.title')}
            </Text>
          </View>
          <View >
          <Image source={user_photo ? {uri:user_photo} : require('../assets/gray-image-placeholder.png')} style={{width:250, height:250, alignSelf:'center', borderRadius:250}}/>
          </View>
          <View style={{flexDirection:'row',alignItems:'center', justifyContent:'center', paddingTop:10}}>
            <Text style={{fontSize:32, fontWeight:'bold', color:'#000'}}>
              {user_name}
              </Text>

          </View>
          <View style={{flexDirection:'row',alignItems:'center', justifyContent:'center', paddingTop:10}}>
            <TouchableOpacity style={{padding:8, backgroundColor:'#e0e0e0', borderRadius:10, marginHorizontal:2}} onPress={()=>{
                    if(uid){
                      if(user_id === uid){
                        if(chatMyselfId === null){
                          createChatMyself({variables:{
                            senderId:uid,
                            recipientId:uid,
                        }}).then(res=>{
                            // console.log(res);
                            const chatMyselfId =  res.data.insert_chat_one.chat_id;
                            navigation.navigate('ChatNav', {
                                chatId:chatMyselfId,
                                expoToken:sellerExpoToken,
                            })
                        }).catch(err=>{
                            console.log(err);
                        })
  
                        }else{
                          navigation.navigate('ChatNav', {
                              chatId:chatMyselfId,
                              expoToken:sellerExpoToken,
                          })
                        }
                      }else{
                        if(chatId === null){
                          createChat({variables:{
                              senderId:uid,
                              recipientId:user_id,
                          }}).then(res=>{
                              // console.log(res);
                              const chatSellerId = res.data.insert_chat_one.chat_id;
                              navigation.navigate('ChatNav', {
                                  sellerId:user_id,
                                  chatId:chatSellerId,
                                  expoToken:sellerExpoToken,
                              })
                              refetchChatData();
                          }).catch(err=>{
                          })
                      }else{
                        console.log('chatId: ', chatId)
                          navigation.navigate('ChatNav', {
                              sellerId:user_id,
                              chatId:chatId,
                              expoToken:sellerExpoToken,
                          })
                      }
                      }
                    }
            }}>
              <Text style={{fontFamily:'Inter-Medium'}}>
              {i18n.t('userSettings.publicProfile.send_message')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{padding:8, backgroundColor:'#FF6D00', borderRadius:10, marginHorizontal:2}} onPress={()=>{
              shareProfile();
            }}>
              <Text style={{color:"#ffffff", fontFamily:'Inter-Regular'}}>
              {i18n.t('userSettings.publicProfile.share_profile')}

              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{padding:8, backgroundColor:'#e0e0e0', borderRadius:6, marginHorizontal:2}} onPress={async ()=>{
                      try{
                        const payload ={
                          dynamicLinkInfo:{
                              domainUriPrefix: 'https://menucitos.page.link',
                              link: 'https://menucitos.com/drawer/usersettings/profile/public/'+uid,
                              androidInfo:{
                                  androidPackageName:'com.nelson_arguera.menu',
                              },
                              iosInfo:{
                                  iosBundleId: 'com.nelsonarguera.menu',
                                  iosAppStoreId: 'id1643431406',
                              },
                              socialMetaTagInfo:{
                                  socialTitle: user_name + ' ' + user_lastname,
                                  socialDescription: i18n.t('userSettings.profile.check_out_my_profile'),
                                  socialImageLink: user_thumbnail,
                              }
                          },
                          /**ios */
                      };
                      let apiKey ="AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
                      // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
                      const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
                      let json;
                  let shortLink;
                  const response = await fetch(url, {
                      method: 'POST',
                      headers:{
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(payload),
                  }).then(res => {
                      res.json().then(async (data) => {
                          // console.log(data);
                          json = data;
                          setShortURL(json.shortLink);
                          setModalQR(true);

                      })
                  }).catch(err => {console.log("WHAT",JSON.stringify(err))});
      
                      }catch(err){
                        console.log('err', err);
                      }
                    }}>
              <Ionicons name="qr-code-outline" size={18} color="#000"/>
            </TouchableOpacity>
            {(uid) ? (
              <TouchableOpacity style={{padding:8, backgroundColor:'#e0e0e0', borderRadius:6, marginHorizontal:2}} onPress={async ()=>{
                navigation.navigate("UserPay",{
                  user_id:user_id,
                })
              }}>
              <MaterialCommunityIcons name='hand-coin' size={18} color="#000"/>
            </TouchableOpacity>
            ) : null}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default UserPublicProfile;