import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert, SectionList, FlatList, TouchableWithoutFeedback, ImageBackground, Dimensions,TextInput, TouchableOpacity, useWindowDimensions } from 'react-native';
// import { } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_BY_SUBCATEGORY_ID, GET_SUBCATEGORIES_BY_CATEGORY, GET_SUBCATEGORIES_BY_CATEGORY_ID } from '../lib/queries';
import Firebase from '../lib/firebase';
import { useIsFocused } from '@react-navigation/native';
import { getAuth } from 'firebase/auth';
import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from '../i18n/i18n';
import * as Localization from 'expo-localization';

const auth = getAuth(Firebase);

const CategoriesDetailed = ({navigation, route}) => {
    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();
    const {categoryId} = route.params
    const {distanceCart} = route.params
    const {latitude, longitude} = route.params

    const isFocused = useIsFocused();

    const [uid, setUid] = useState('');
    const [categoryLabel, setCategoryLabel] = useState('');
    const [subCategories, setSubCategories] = useState([]);

    

    const jsonGeography ={
        "type": "Point",
        "coordinates": [
            longitude,
            latitude
        ]
    }

    // const uid = getAuth().currentUser.uid;
    const {loading: loadingCategories, error: errorCategories, data: dataCategories, refetch} = useQuery(GET_PRODUCTS_BY_SUBCATEGORY_ID, {variables:{
        uid: uid,
        categoryId: categoryId,
        languageCode: languageCode,
        point: jsonGeography,
        distance: distanceCart
    }});

    async function getSubCategories(){
        console.log("ERROR CATEGORIES", errorCategories)   
        if(dataCategories){
            console.log(dataCategories);
            const {default_categories} = dataCategories
            // console.log("Default Categories: ", default_categories);
            setCategoryLabel(default_categories[0].default_category_translations[0].default_category_name);
            setSubCategories(default_categories[0].default_subcategories);
            // console.log("SubCategories: ", default_categories[0].default_subcategories);
        }
    }


    useEffect(() => {

        getAuth().onAuthStateChanged(async (user) => {
            if (user) {
                setUid(user.uid);
                getSubCategories();
            } else {
                console.log("No user logged");
                getSubCategories();
            }
        });

        
        if(isFocused){
            getSubCategories();
            console.log("ERROR CATEGORIES", errorCategories)
        }

        const unsubscribe = navigation.addListener('focus', () => {
            console.log("ERROR CATEGORIES", errorCategories)
            refetch();
        });

    } , [dataCategories, isFocused])
    let screenWidth
    let numColumns
    let itemWidth 
  
    if(Platform.OS === 'ios' || Platform.OS === 'android'){
      screenWidth = Dimensions.get('window').width;
      numColumns = 3;
      itemWidth = screenWidth / numColumns;
    }else if(Platform.OS === 'web'){
      // screenWidth = window.innerWidth;
      // numColumns = 3;
      // itemWidth = screenWidth / numColumns;
  
      screenWidth = useWindowDimensions().width;
  
      if(screenWidth > 1200){
        numColumns = 6;
        itemWidth = screenWidth / numColumns;
      }else if(screenWidth > 768){
        numColumns = 5;
        itemWidth = screenWidth / numColumns;
      }
      else{
        numColumns = 3;
        itemWidth = screenWidth/numColumns;
      }
    }
    const ListItem = ({item, product_title, product_id, seller_id, organizationId}) => {
        return (
            <View style={{paddingHorizontal:2}}>
                <TouchableWithoutFeedback onPress={()=> {
                    // console.log("Product ID: ", product_id);
                    navigation.navigate('ProductDetailed',{
                        productId: product_id,
                        sellerId: seller_id,
                        distanceCart: distanceCart,
                        latitude: latitude,
                        longitude: longitude,
                        organization_id: organizationId ? organizationId : 0
                    })
                    }}>
                <ImageBackground source={{uri: item.product.product_thumbnail}} resizeMode='contain' style={{paddingVertical:5, width:itemWidth-5, height:itemWidth-5, overflow:'hidden', borderRadius:15}}></ImageBackground>
                </TouchableWithoutFeedback>
                <Text style={{fontFamily:'Inter-Bold', fontSize: Platform.OS === 'android' || Platform.OS === 'ios'? 14 : 18}}>{
                product_title.length > 16 ? product_title.substring(0,15)+"..." : product_title
                }</Text>
            </View>
        )
    }

    const bannerError = (e) => {
        // console.log(e)
    }
    return(
        <SafeAreaView style={{flex:1, paddingVertical:-45}}>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{ flex:1}}>
            <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {categoryLabel} 
            </Text>
            </View>
            <SectionList
            sections={subCategories}
            StickySectionHeadersEnabled={false}
            renderSectionHeader={({section}) => (
            <>
                <Text style={{fontWeight:'bold', fontSize:18}}>{section.default_subcategory_translations[0].default_subcategory_name}</Text>
                <FlatList data={section.data} numColumns={numColumns} 
                key={numColumns}
                keyExtractor={(item, index) => index.toString()}
                renderItem={({item}) =>{
                    return <ListItem item={item}
                    product_title={item.product.product_translations[0].product_title}
                    product_id={item.key}
                    seller_id={item.product.user_products[0].user_id}
                    organizationId={item.product.organization_products[0] ? item.product.organization_products[0].organization_id : null}
                    />
                }}
                >

                </FlatList>
            </>
            )}
            renderItem={({item}) =>{
                return null
                return <ListItem item={item}/>
            }}
            ></SectionList>
            {/* <FlatList data={subCategories} keyExtractor={item => item.subcategory_id.toString()}/> */}
        </View>
        </KeyboardAvoidingView>
              
    </SafeAreaView>
    )
    
}
export default CategoriesDetailed;  