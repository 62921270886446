import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert,Modal, Button,StyleSheet, ActivityIndicator, KeyboardAvoidingView, Platform} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMutation } from '@apollo/client';
import { UPDATE_PRODUCT_TITLE_SKU_SLUG } from '../../../lib/queries';
import i18n from '../../../i18n/i18n';

const EditProductName = ({navigation, route}) => {

    /**route.params */

    /**productId */

    const {productId} = route.params
    const {product_id} = productId

    /**productTitle */

    const {product_title} = route.params;
    const {product_Title} = product_title;

    /**product_SKU */

    const {product_sku} = route.params;
    const {product_SKU} = product_sku;

    /**product_translation_ID */
    const {product_translations_id} = route.params;
    const {product_translations_ID} = product_translations_id;

    const [productTitle, setProductTitle] = useState(product_Title? product_Title : '')
    const [productSKU, setProductSKU] = useState(product_SKU? product_SKU : '')
    const [productSlug, setProductSlug] = useState('')
    const [visibleText, setVisibleText] =useState(true)

    /**Mutation */

    const [updateProductName, { loading: loadingUpdateproductName, error, data: dataProductNames }] = useMutation(UPDATE_PRODUCT_TITLE_SKU_SLUG)

    /**VisibleModal */

    const [visibleModal, setVisibleModal] = useState(false)

    function slugify(text) {
      const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
      const to = "aaaaaeeeeeiiiiooooouuuunc------"
    
      const newText = text.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))
    
      return newText
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }

    function removeSpacesFromSku(text){
      text = text.replace(/\s+/g, '');

      return text
    }

    useEffect(()=>{
        // console.log('productTitle', product_Title)
        // console.log('productSKU', product_SKU)
        // console.log('productTranslationId', product_trapnslations_ID)
        // console.log('productId', product_id)
    },)

    return(
      <SafeAreaView style={{flex:1, paddingTop:5}}>
      <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
      <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
      <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
      <Text style={{fontWeight:"bold", fontSize:18}}>
          {i18n.t('products.editProductName.title')}
      </Text>
      </View>
      <View style={{paddingTop:25}}>
          {/* <Text style={{fontSize:22}}>Edit the name for your product, try to choose good words </Text> */}
      </View>
  </View>
  <View style={{flex:2}}>
          <Text style={{fontSize:18, paddingHorizontal:15}}>{i18n.t('products.editProductName.edit_product_name')}</Text>
            <TextInput
            onFocus={(v)=>{setVisibleText(false)}}
            defaultValue={productTitle}
            onChangeText={(v)=>{setProductTitle(v); setProductSlug(slugify(v));}}
            style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:12,
            marginHorizontal:15, fontSize:18}}
            placeholder="Coke Cola Can 12 OZ"/>
            <Text style={{fontSize:18, paddingHorizontal:15}}>{i18n.t('products.editProductName.edit_product_sku')}</Text>

            <TextInput
            onFocus={(v)=>{setVisibleText(false)}}
            autoCapitalize = {"characters"}
            defaultValue={productSKU}
            style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:12,
            marginHorizontal:15, fontSize:18}}
            onChangeText={(v)=>{setProductSKU(removeSpacesFromSku(v)); 
              // console.log(removeSpacesFromSku(v))
            }}
            placeholder="SKUMENUCITOS0001"/>
      
  </View> 
  <View style={{flex:0.4, paddingHorizontal:15}}>
      <TouchableOpacity style={{backgroundColor:'#000000', paddingVertical:15, borderRadius:6}}
            onPress={()=> {if(productTitle.trim() === '' && productSKU.trim() === ''){
              Alert.alert("Error", "Fill the two inputs")
            } else{
              updateProductName({
                  variables:{
                      productTitle,
                      productSKU,
                      productSlug,
                      product_translations_ID,
                      product_id
                  }
              }).then(()=>{
                  Alert.alert("Success", "Product name updated")
              }).catch((e)=>{
                  console.log(e)
              })

            }}}
      >
          <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>
            {i18n.t('products.editProductName.btn_save')}
          </Text>
      </TouchableOpacity>
      </View>
  </KeyboardAvoidingView>
        
</SafeAreaView>
    )
    
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
        backgroundColor: '#FFFFFF',
        height: 100,
        width: 100,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    modalContent: {
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        backgroundColor: '#00000040'
    },
    bottomModal: {
      justifyContent: 'flex-end',
      margin: 0,
    },
  });

export default EditProductName;