import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert, TextInput, TouchableOpacity, Modal} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';
import { StatusBar } from 'expo-status-bar';
const Name = ({navigation}) => {

    const [name, setName] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);


    return(
        <SafeAreaView style={{flex:1}}>
            <StatusBar style='dark' backgroundColor='white'/>
                    <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
                        </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": "height"} enabled>
            <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>
            <Text style={{fontSize:36, fontWeight:'bold'}}>
                {i18n.t('setup.name.title')}
            </Text>
            </View>
            <View style={{paddingTop:25}}>
                <Text style={{fontSize:22}}>{i18n.t('setup.name.body')} </Text>
            </View>
        </View>
        <View style={{flex:2}}>
            <TextInput style={{paddingHorizontal:12, backgroundColor:'#c9c1c1', paddingVertical:5,
             marginHorizontal:15, fontSize:18, borderRadius:6}} 
             placeholder={i18n.t('setup.name.name_placeholder')}
             onChangeText={(v)=> setName(v)} />

            
        </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{
                if(name.trim() === ''){
                    Alert.alert(i18n.t('setup.name.error'), i18n.t('setup.name.error_body'), [{text:'OK'}])
                    if(Platform.OS === 'web'){
                        setMessageTitle(i18n.t('setup.name.error'));
                        setMessageBody(i18n.t('setup.name.error_body'));
                        setModalVisibilityAlert(true);
                    }
                }else{
                    navigation.navigate('Lastname',{
                        name:name.trim(),
                    })
                }
               
            }}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('setup.name.btn_next')}</Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
              
    </SafeAreaView>
    )
    
}

export default Name;