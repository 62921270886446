import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// import tailwind, { getColor } from 'tailwind-rn';

const AddCountryOptions = ({navigation, route}) => {

  /**route.params */
  const {DeliveryOptionsList} = route.params;
  const {arrayDeliveryOptionsId} = DeliveryOptionsList

  useEffect(()=>{
    console.log(arrayDeliveryOptionsId)
  })

  const Item = ({item}) => {
    return (
      <Text>Hey</Text>
    )
  }

  const renderItem = ({item}) => (
    <Item item={item} />
  );
    return(
        <SafeAreaView style={{flex:1}}>
        {/* <View style={tailwind('px-5 absolute top-10')}>
            <View style={tailwind('flex-row justify-between items-center')}>
            <Text style={tailwind('text-4xl font-bold top-0')}>
                Country Delivery Options
            </Text>
            </View>
            <View style={tailwind('py-5')}>
            <Text style={tailwind('text-lg')}>
              Do you plan to expand your bussiness and offer your products to foreign countries?
              Please select the countries you want to offer your products
            </Text>
        </View>
        <View style={tailwind('top-10')}>
            <TextInput
             contextMenuHidden={true}
             style={tailwind('text-2xl bg-gray-200 px-2 flex-grow')}
             placeholder="Test"
            />
            <FlatList></FlatList>
        </View>
        </View>
        <View style={tailwind('absolute bottom-0 inset-x-4')}>
      <TouchableOpacity
            style={tailwind('bg-blue-500 rounded-lg py-3 mt-2 bottom-4')}
            onPress={()=> navigation.navigate('AddPaymentOptions')}
          >
            <Text style={tailwind('text-white relative text-center font-bold text-lg rounded-lg')}>
              Next
            </Text>
          </TouchableOpacity>
        </View>
         */}
    </SafeAreaView>
    )
    
}

export default AddCountryOptions;