import { ApolloClient, from, HttpLink, InMemoryCache, split} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import Firebase from './firebase'
import { getAuth } from 'firebase/auth'
import { onError } from "@apollo/client/link/error";

const auth = getAuth(Firebase);
// This is where the magic happens, this function
// is called every time we make a request to our Hasura
// database. And because of that, we always get a fresh
// token. This way, we never run into issues with expired tokens

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors){
        console.log('graphQLErrors', graphQLErrors);
        graphQLErrors.forEach(({ message, locations, path }) =>{
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            );
        }
      );
    }
      
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

const httpLink = new HttpLink({
    uri: 'https://console.menucitos.com/v1/graphql',
})

const wsLink = new GraphQLWsLink(
    createClient({
        url: 'wss://console.menucitos.com/v1/graphql',
    })
);

const splitLink = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
);
export const ApolloClientUnregistered = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, splitLink]),
});