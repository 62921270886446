import React, { useEffect, useState } from 'react';
import {
  Modal,
    SafeAreaView, Text, View, TextInput, TouchableOpacity, Alert, KeyboardAvoidingView, Platform, Image, Dimensions
  } from 'react-native';
import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from '../lib/queries';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from '../i18n/i18n';
import Firebase from '../lib/firebase';
import {getFunctions, httpsCallable} from 'firebase/functions'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import WelcomeLogo from '../assets/WelcomeScreen.png';
import LogoBienvenida from '../assets/PantallaBievenida.png';

const functions = getFunctions(Firebase);

const auth = getAuth(Firebase);

/**import firebase/functions*/


const Policies = ({ navigation }) => {

    useEffect(()=>{
    })

      const screenWidth = Dimensions.get('window').width;

      return (
        <SafeAreaView style={{flex:1}}>
        <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
        <View style={{paddingTop:15, paddingHorizontal:15, flex:0.4}}>
        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
        <Text style={{fontSize:32, fontWeight:'bold'}}>
            {i18n.t('policies.title')}
        </Text>
        </View>
        <View>
        
        </View>

    </View>
    <View style={{paddingHorizontal:10}}>
    <Text style={{fontSize:16}}>
        </Text>
        <Text style={{fontSize:16, fontWeight:'bold'}}>
        {i18n.t('policies.personal_data')}
        </Text>
        <Text style={{fontSize:16}}>
        {i18n.t('policies.personal_data_body')}
        </Text>
        <Text style={{fontSize:16, fontWeight:'bold'}}>
        {i18n.t('policies.privacy_policy')}
        </Text>
        <Text style={{fontSize:16}}>
        {i18n.t('policies.privacy_policy_body')}
        </Text>
        <Text style={{fontSize:16, fontWeight:'bold'}}>
        {i18n.t('policies.copyright')}
        </Text>
        <Text style={{fontSize:16}}>
        {i18n.t('policies.copyright_body')}
        </Text>
        <Text style={{fontSize:16, fontWeight:'bold'}}>
        {i18n.t('policies.risk_of_loss')}
        </Text>
        <Text style={{fontSize:16}}>
        {i18n.t('policies.risk_of_loss_body')}
        </Text>
        <Text style={{fontSize:16, fontWeight:'bold'}}>
        {i18n.t('policies.policie_changes')}
        </Text>
        <Text style={{fontSize:16}}>
        {i18n.t('policies.policie_changes_body')}
        </Text>

    <View style={{paddingTop:5}}>
    
        </View>
        <View style={{marginTop:5, flexDirection:'row', justifyContent:'center'}}>
       <Text style={{fontSize:16}}>{i18n.t('signup.already_registered')} </Text>
        <TouchableOpacity
        style={{marginLeft:1}}
        onPress={() => navigation.navigate('Login')}
      >
        <Text style={{fontSize:16, fontWeight:'bold', color:'#1e76e8'}}>
          {i18n.t('signup.btn_login')}
        </Text>
      </TouchableOpacity>
    </View>
    </View> 
    <View style={{flex:0.4, paddingHorizontal:15}}>
        </View>
    </KeyboardAvoidingView>
          
    </SafeAreaView>
    );    
};

export default Policies;