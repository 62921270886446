import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert , FlatList, TextInput, TouchableOpacity, Modal } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CASH_OPTION_BY_COUNTRY, GET_COUNTRY_CURRENCIES, INSERT_USER_LOCATION_SETUP } from '../../lib/queries';
import i18n from '../../i18n/i18n';
import { Picker } from '@react-native-picker/picker';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { StatusBar } from 'expo-status-bar';

const auth = getAuth(Firebase);

const Currency = ({navigation, route}) => {

    /**route.params */

    const {name} = route.params;
    const {lastname} = route.params;
    const {provinceId} = route.params;
    const {phone} = route.params;
    // const {countryId} = route.params;
    const {cityId} = route.params;
    const {address1} = route.params;
    const {address2} = route.params;
    const {zipCode} = route.params;

    const {countryId} = route.params;

    const uid = getAuth().currentUser.uid;

    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState(0);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
    const [modalVisibilityDone, setModalVisibilityDone] = useState(false);

    const [selectedPaymentCashConfigId, setSelectedPaymentCashConfigId] = useState(0);

    const {loading: loadingCurrencies, error: errorCurrencies, data: dataCurrencies} = useQuery(GET_COUNTRY_CURRENCIES, {variables:{countryId}});

    const {loading: countryCashLoading, error: countryCashError, data: countryCashData} = useQuery(GET_CASH_OPTION_BY_COUNTRY, {variables:{countryId}});

    const [arrayCurrencies, setArrayCurrencies] = useState([]);

    const [insertUserLocation, {loading: loadingInsertUserLocation}] = useMutation(INSERT_USER_LOCATION_SETUP);

    useEffect(() => {
        // console.log(countryId);
        if(dataCurrencies){
            const { country_currencies} = dataCurrencies;
            setCurrencies(country_currencies);
            // console.log(country_currencies);
        }

        if(countryCashData){
            const {country_payment_options} = countryCashData;
            const {country_payment_options_id} = country_payment_options[0];

            setSelectedPaymentCashConfigId(country_payment_options_id);
            // console.log(country_payment_options_id);
        }
    },)

    

    const Item = ({item, datacurrency}) => (
        <TouchableOpacity style={{backgroundColor:'#c9c1c1',marginHorizontal:15, paddingVertical:10, marginTop:5, borderRadius:6}}>
            <Text style={{textAlign:'center', fontSize:18}}>{datacurrency.currency_code}</Text>
        </TouchableOpacity>
    )

    const renderItem = ({item}) => {

        return(
            <Item datacurrency={item} />
        )
    }

    const pickerHandler = (itemValue) => {

        let newArrayCurrencies = [];

        if(itemValue === 0){
            newArrayCurrencies = [];
            
    }else{
        setSelectedCurrencies(itemValue);
        newArrayCurrencies.push({currency_code:itemValue});
        let currencies = [...newArrayCurrencies];
        let currencieslist = {currencies};
        // console.log(currencieslist);
        setArrayCurrencies(currencieslist);
    }
    }

    return(
        <SafeAreaView style={{flex:1}}>
            <StatusBar style='dark' backgroundColor='white'/>
            <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <Modal visible={modalVisibilityDone}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityDone(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => {
                                setModalVisibilityDone(false)
                                //reload the page
                                window.location.reload();
                            }}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('setup.currency.title')}
            </Text>
            </View>
            <View style={{paddingTop:25}}>
                <Text style={{fontSize:22}}>{i18n.t('setup.currency.body')}</Text>
            </View>
        </View>
        <View style={{flex:2}}>
            
            {(!loadingCurrencies && currencies)?(
                <Picker selectedValue={selectedCurrencies}
                onValueChange={pickerHandler}>
                <Picker.Item label={i18n.t('setup.currency.picker_currency_label')}
                          value="0" key="0"/>

                {
                    currencies.map((currency, index) => {
                        return(
                            <Picker.Item label={currency.currency_code}
                            value={currency.currency_code} key={index}/>
                        )
                    })
                }
                </Picker>
            ):null}
        </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{
                if(selectedCurrencies != 0){
                    insertUserLocation({variables: {
                        uid,
                        countryId,
                        provinceId,
                        cityId,
                        address1,
                        address2,
                        zipCode,
                        lastname,
                        name,
                        phone,
                        currencyCode: selectedCurrencies,
                        countryPaymentOptionId: selectedPaymentCashConfigId,
                        currencies: arrayCurrencies,
                    }}).then(res =>{
                        Alert.alert(i18n.t('setup.currency.btn_save_success_title'), i18n.t('setup.currency.btn_save_success'), [{text: 'OK', onPress: ()=>{
                                    navigation.navigate('DrawerOne');
                                }}]);
                        if(Platform.OS === 'web'){
                            setMessageTitle(i18n.t('setup.currency.btn_save_success_title'));
                            setMessageBody(i18n.t('setup.currency.btn_save_success'));
                            setModalVisibilityDone(true);
                        }
                    }).catch(err =>{
                        // console.log(err);
                        // console.log({
                        //     uid,
                        //     countryId,
                        //     provinceId,
                        //     cityId,
                        //     address1,
                        //     address2,
                        //     zipCode,
                        //     lastname,
                        //     name,
                        //     countryPaymentOptionId: selectedPaymentCashConfigId,
                        //     currencies: arrayCurrencies,
                        // })
                        console.log("ERROR " , err)
                    }
                )
                }else{
                    Alert.alert(i18n.t('setup.currency.error'), i18n.t('setup.currency.error_body'));
                    if(Platform.OS === 'web'){
                        setMessageTitle(i18n.t('setup.currency.error'));
                        setMessageBody(i18n.t('setup.currency.error_body'));
                        setModalVisibilityAlert(true);
                    }
                        
                }
            }}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('setup.currency.btn_next')}</Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
              
    </SafeAreaView>
    )
    
}

export default Currency;