import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState, useRef } from 'react';
import { StyleSheet, Text, View, Platform, ActivityIndicator, SafeAreaView, Touchable, TouchableOpacity, AppState, Image, Button, useWindowDimensions } from 'react-native';
/**Icons */
import { Feather } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as Notifications from 'expo-notifications';
import {
  createDrawerNavigator, DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import { ApolloProvider, useQuery, useMutation } from '@apollo/client'
import { ApolloClientUnregistered } from './lib/apollounregistered'
import { apolloClient } from './lib/apollo';
import Login from './screens/Login';
import Signup from './screens/Signup';
import Recover from './screens/Recover';
import Firebase from './lib/firebase';
import { getAuth } from 'firebase/auth';
import i18n from 'i18n-js';
import { GET_ACCOUNT_HAS_SETUP, UPDATE_USER_LOCATION_BY_UID, GET_USER_LOCATION_POINT, SET_USER_ONLINE, SET_USER_OFFLINE, SET_LAST_SEEN_ACTIVITY, VERSION_INFORMATION, UPDATE_USER_LANGUAGE } from './lib/queries';
/**Setup Account Screens */
import Greeting from './screens/Setup/Greeting';
import Name from './screens/Setup/Name';
import Lastname from './screens/Setup/Lastname';
import Country from './screens/Setup/Country';
import Province from './screens/Setup/Province';
import City from './screens/Setup/City';
import Currency from './screens/Setup/Currency';
import Home from './screens/Home';
import CategoriesDetailed from './screens/CategoriesDetailed';
import * as Location from 'expo-location';
import MapView, { Polyline, Circle, Marker } from 'react-native-maps';
import { requestTrackingPermissionsAsync } from "expo-tracking-transparency";
import { useFonts } from 'expo-font';
import * as Device from 'expo-device';
import * as WebBrowser from 'expo-web-browser';
import * as Localization from 'expo-localization';
//Contants
import Constants from 'expo-constants';


/** import useScript from hooks if platform.os === web */

/**Task Manager */
import * as TaskManager from 'expo-task-manager';
import { useIsFocused } from '@react-navigation/native';



/**Inbox */
import Inbox from './screens/Inbox';
import Messages from './screens/Messages';

/**Categories */
// WebBrowser.maybeCompleteAuthSession();

import CustomCategories from './screens/Categories/customCategories';
import AddCustomCategories from './screens/Categories/addCustomCategories';
import EditCustomCategories from './screens/Categories/editCustomCategories';
import DetailedCustomCategories from './screens/Categories/detailedCustomCategories';
import EditCategoryTranslations from './screens/Categories/editCategoryTranslations';
import ListProductsByCategory from './screens/Categories/Products/ProductsByCategory';
import AddProductsByCategory from './screens/Categories/Products/addProductsByCategory';
/**My Products Under Categories */
import myProducts from './screens/Products/myProducts';
import MyProductDetailed from './screens/Products/myProductDetailed';
import AddProducts from './screens/Products/AddProducts';
import AddProductName from './screens/Products/addProductName';
import AddProductPrice from './screens/Products/addProductPrice';
import AddProductQuantity from './screens/Products/addProductQuantity';
import AddProductDescription from './screens/Products/addProductDescriptions';
import AddDeliveryOption from './screens/Products/addDeliveryOptions';
import AddCountryOptions from './screens/Products/addCountryOptions';
import AddPaymentOptions from './screens/Products/addPaymentOptions';
import AddProductImage from './screens/Products/addProductImage';
import AddProductDefaultSubCategory from './screens/Products/addProductDefaultCategory';
/**Edit Products */
import EditProductName from './screens/Products/editProducts/editProductName';
import EditProductPrice from './screens/Products/editProducts/editProductPrice';
import EditProductQuantity from './screens/Products/editProducts/editProductQuantity';
import EditProductDescription from './screens/Products/editProducts/editProductDescriptions';
import EditProductListDescription from './screens/Products/editProducts/editProductListDescriptions';
import EditDeliveryOption from './screens/Products/editProducts/editDeliveryOptions';
import EditPaymentOptions from './screens/Products/editProducts/editPaymentOptions';

import UserData from './screens/UserData';

/**productDetailed */
import ProductDetailed from './screens/ProductDetailed';
import Results from './screens/Results';
import OrganizationPage from './screens/OrganizationPage';
import OrganizationPageCart from './screens/OrganizationPageCart';
import Search from './screens/Search';

/**Location */
import Locations from './screens/Locations/Locations';
import AddLocation from './screens/Locations/addLocation';
import EditLocation from './screens/Locations/editLocation';

/**Orders */
import Orders from './screens/Orders';
import OrdersSeller from './screens/Orders/OrdersSeller';
import OrderDetailed from './screens/OrderDetailed';
import ReviewOrder from './screens/Orders/ReviewOrder';

/**Payment Methods */
import PaymentMethods from './screens/PaymentMethods/paymentMethods';
import AddPaymentMethod from './screens/PaymentMethods/addPaymentMethod';
import PayPalConfig from './screens/PaymentMethods/paymentConfigs/PayPalConfig';
import CashConfig from './screens/PaymentMethods/paymentConfigs/cashConfig';
import { isDevice } from 'expo-device';
import * as Linking from 'expo-linking';
import Policies from './screens/Policies';
import Privacy from './screens/Privacy';
import Phone from './screens/Setup/Phone';
import AddProductGallery from './screens/Products/addProductGallery';
import AsyncStorage from '@react-native-async-storage/async-storage';
import EditOrganizationPage from './screens/Organization/EditOrganizationPage';
import EditOrganizationUsers from './screens/Organization/EditOrganizationUsers';
import SetupOrganizationPage from './screens/Organization/SetupOrganizationPage';
import Landing from './screens/Landing';
import UserPublicProfile from './screens/UserPublicProfile';
import DeliveryMotorcycle from './screens/Delivery/Delivery4/Home';
import ReviewOrderDelivery from './screens/Delivery/ReviewOrder';
import Support from './screens/Support';
import { Alert } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
// import { initStripe } from '@stripe/stripe-react-native';
import * as Updates from 'expo-updates';


const initStripe = Platform.select({
  ios: () => require('@stripe/stripe-react-native').initStripe,
  android: () => require('@stripe/stripe-react-native').initStripe,
  web: () => null,
})();

import MenuPayIndividual from './screens/PaymentMethods/paymentConfigs/menuPayIndividual';
import UserPay from './screens/Payout/UserPay';
// import EditOrganizationPage from './screens/EditOrganizationPage';


const prefix = Linking.createURL('/')

const auth = getAuth(Firebase);

const RooStack = createStackNavigator();

const TopTabStack = createMaterialTopTabNavigator();

const AuthenticationStack = createStackNavigator();

const DrawerStack = createDrawerNavigator();

const TabStack = createBottomTabNavigator();

const AddLocationStack = createStackNavigator();

const SetupAccountStack = createStackNavigator();


/**notification handler */

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,

  })
})


const LOCATION_TASK_NAME = "background-location-task";

TaskManager.defineTask(LOCATION_TASK_NAME, async ({ data, error }) => {
  if (error) {
    console.log("ERROR FATLA: ", error);
    return;
  }
  getAuth().onAuthStateChanged(async (user) => {
    if (user) {
      if (data) {
        const { locations } = data;
        const { latitude, longitude } = locations[0].coords;
        // console.log("LOCATION: ", latitude," ", longitude);

        const jsonGeography = {
          "type": "Point",
          "coordinates": [longitude, latitude]
        }

        // console.log("JSON GEOMETRY: ", JSON.stringify(jsonGeography));

        const jsonGeographyString = JSON.stringify(jsonGeography);
        /**GET USER TOKEN*/

        const myStr = jsonGeographyString;
        const myStr2 = myStr.replace(/\"/g, "'");
        // console.log("myStr2: ", myStr2);
        // const token = user.getIdToken();
        const uid = getAuth().currentUser.uid;


        const query = JSON.stringify({
          query: `
            mutation {
              update_users_by_pk(pk_columns:{uid:"${uid}"} _set:{user_location:"${myStr2}"}) {
                user_location
              }
            }
          `,
        });

        try {
          const response = await fetch("https://console.menucitos.com/v1/graphql", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`,
            },
            body: query,
          });
          const data = await response.json();
          // console.log("DATAchange: ", data);
          return data;
        } catch (error) {
          console.error('err', error);
        }
      }
    }
  })
});


function HomeNav({ navigation, route }) {
  const isFocused = useIsFocused();

  const [longitude, setLongitude] = useState();
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [latitude, setLatitude] = useState();
  const [regionLatitude, setRegionLatitude] = useState();
  const [regionLongitude, setRegionLongitude] = useState();
  const [regionLatitudeDelta, setRegionLatitudeDelta] = useState(750);
  const [regionLongitudeDelta, setRegionLongitudeDelta] = useState(750);
  const [permissions, setPermissions] = useState(false);
  const [selectedLongitude, setSelectedLongitude] = useState();
  const [selectedLatitude, setSelectedLatitude] = useState();
  const [confirmed, setConfirmed] = useState(false);
  const [loadingView, setLoadingView] = useState(true);
  const [mapClicked, setMapClicked] = useState(false);

  /**QUERY GET USER POINT LOCATION */
  // const uid = getAuth().currentUser.uid;
  // console.log("UID: ", uid);

  const { data: userLocationData, loading: userLocationLoading, error: userLocationError } = useQuery(GET_USER_LOCATION_POINT, { variables: { uid: uid } });

  /**UPDATE LOCATION MUTATION */

  const [updateLocation, { loading: updateLocationLoading, error: updateLocationError }] = useMutation(UPDATE_USER_LOCATION_BY_UID)

  const _getLocationAsync = async () => {
    const locationPermission = await Location.getForegroundPermissionsAsync();
    const taskIsDefined = await TaskManager.isTaskDefined(LOCATION_TASK_NAME);
    console.log("IS TASK DEFINED: glu glu ", taskIsDefined);
    console.log("LOCATION PERMISSION: ", locationPermission.status);
    if (Platform.OS === 'android' && locationPermission.status === 'granted' && !taskIsDefined) {
      Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
        enableHighAccuracy: true,
        distanceInterval: 1,
        timeInterval: 1000,
        foregroundService: {
          notificationTitle: i18n.t('home.location_service_title'),
          notificationBody: i18n.t('home.location_service_body'),
          notificationColor: '#FF231F7C',
        }
      })
    }
    // // watchPositionAsync Return Lat & Long on Position Change
    if (Platform.OS === 'ios') {
      getAuth().onAuthStateChanged(async (user) => {
        if (user) {
          let location = await Location.watchPositionAsync(
            {
              enableHighAccuracy: true,
              distanceInterval: 5,
              timeInterval: 10000,
            },
            async newLocation => {
              let { coords } = newLocation;
              // console.log("COORDS: ", coords);
              // setLatitude(coords.latitude);
              // setLongitude(coords.longitude);

              const jsonGeography = {
                "type": "Point",
                "coordinates": [coords.longitude, coords.latitude]
              }

              // console.log("JSON GEOMETRY: ", JSON.stringify(jsonGeography));

              const jsonGeographyString = JSON.stringify(jsonGeography);
              /**GET USER TOKEN*/

              const myStr = jsonGeographyString;
              const myStr2 = myStr.replace(/\"/g, "'");
              // console.log("myStr2: ", myStr2);
              // const token = getAuth().currentUser.getIdToken();
              const uid = getAuth().currentUser.uid;


              // console.log("TOKEN: ", token);



              const query = JSON.stringify({
                query: `
                  mutation {
                    update_users_by_pk(pk_columns:{uid:"${uid}"} _set:{user_location:"${myStr2}"}) {
                      user_location
                    }
                  }
                `,
              });

              try {
                const response = await fetch("https://console.menucitos.com/v1/graphql", {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`,
                  },
                  body: query,
                });
                const data = await response.json();
                // console.log("DATAchange: ", data);
                return data;
              } catch (error) {
                console.error('err', error);
              }

              /**Update location in GRAPHQL */

            },
            error => console.log(error)
          );
          return location;
        } else {
          console.log("NO USER");
          return null;
        }
      })
    }
  };

  const getUserPoint = async () => {
    if (userLocationData && !mapClicked) {
      // console.log("userLocation: ", userLocationData);
      const { users_by_pk } = userLocationData;
      const { user_location } = users_by_pk;
      if (user_location) {
        const [longitude, latitude] = user_location.coordinates;
        setLoadingView(false);
        setLongitude(parseFloat(longitude));
        setSelectedLatitude(parseFloat(latitude));
        setSelectedLongitude(parseFloat(longitude));
        setLatitude(parseFloat(latitude));
        setRegionLatitude(parseFloat(latitude));
        setRegionLongitude(parseFloat(longitude));
        setRegionLatitudeDelta(0.01);
        setRegionLongitudeDelta(0.01);

      } else {
        // console.log("NO USER LOCATION");
        setLongitude(1);
        setLatitude(1);
        setRegionLatitude(1);
        setRegionLongitude(1);
        setRegionLatitudeDelta(150);
        setRegionLongitudeDelta(150);
        setSelectedLatitude(1);
        setSelectedLongitude(1);
        setLoadingView(false);
      }
    }
  }

  async function requestPermissions() {
    let { status, canAskAgain } = await Location.requestForegroundPermissionsAsync();
    console.log("STATUS: ", status);
    if (status === 'granted') {
      // setConfirmed(true);
      try {
        if (Platform.OS === 'android' || Platform.OS === 'ios') {
          let location = await Location.getCurrentPositionAsync({
            enableHighAccuracy: true,
            distanceInterval: 1,
            timeInterval: 1000,
          });
          let { coords } = location;
          // console.log("COORDS: ", coords);
          setLatitude(coords.latitude);
          setLongitude(coords.longitude);
          setLoadingView(false);
          setConfirmed(true);
          setPermissions(true);
          await _getLocationAsync();

        } else if (Platform.OS === 'web') {
          if (userLocationData.users_by_pk.user_location === null) {
            let location = await Location.getCurrentPositionAsync({
              accuracy: Location.Accuracy.Highest,
              enableHighAccuracy: true,
            });
            let { coords } = location;
            // console.log("COORDS WEB: ", coords);
            setLatitude(coords.latitude);
            setLongitude(coords.longitude);
            setRegionLatitude(coords.latitude);
            setRegionLongitude(coords.longitude);
          }
        }
      } catch (error) {
      }

    } else if (status === 'denied' || !canAskAgain) {
      setPermissions(false);
      setConfirmed(false);
      Alert.alert(
        i18n.t('home.location_service_title'),
        i18n.t('home.location_permission_body'),
        [
          {
            text: i18n.t('home.location_permission_alert_button'),
            onPress: () => Linking.openSettings(),
          },
        ],
        { cancelable: true }
      )
    } else {
      // setPermissions(false);
      // setConfirmed(false);
    }
  }

  useEffect(() => {
    getAuth().onAuthStateChanged(async (user) => {
      if (user) {
        setUid(user.uid);
        setUser(user);
        (async () => {
          // // await getUserPoint();
          let { status } = await Location.getForegroundPermissionsAsync();
          if (status === 'granted') {
            // console.log("latitude: ", latitude);
            // console.log("longitude: ", longitude);
            // console.log("Permisions granted");
            if (Platform.OS === 'ios' || Platform.OS === 'android') {
              // console.log("Platform: ", Platform.OS);
              let location = await Location.getCurrentPositionAsync({
                enableHighAccuracy: true,
                distanceInterval: 1,
                timeInterval: 1000,
              });
              let { coords } = location;
              // console.log("COORDS: ", coords);
              setLatitude(coords.latitude);
              setLongitude(coords.longitude);
              setPermissions(true);
              await _getLocationAsync().then(() => {

                // console.log("latitude: ", latitude);
                // console.log("longitude: ", longitude);
              });
              //moved from _getLocationAsync to here
              setPermissions(true);
              setConfirmed(true);
              setLoadingView(false);
            } else if (Platform.OS === 'web') {
              getUserPoint();
            }

          } else {
            setLoadingView(false);
          }

          if (isFocused) {
            if (Platform.OS === 'web') {
              getUserPoint();
            } else if (Platform.OS === 'ios' || Platform.OS === 'android') {
              requestPermissions();
            }
          }
        })();

      } else {
        setUid(null);
        setUser(false)
        setLoadingView(false);
        setPermissions(true);
        setConfirmed(true);
      }
    })
  }, [userLocationData, permissions, confirmed, isFocused, latitude, longitude, loadingView]);

  if (loadingView) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {/* {console.log(loadingView)} */}
        {/* <Text>Hey</Text> */}
        <ActivityIndicator size="large" color="#FF6D00" />
      </View>
    )
  }
  else if (latitude && longitude && permissions || confirmed) return (
    <AddLocationStack.Navigator initialRouteName='Home' screenOptions={{ headerShown: false, headerStatusBarHeight: 0, headerMode: 'screen' }}>
      <AddLocationStack.Group>
        <AddLocationStack.Screen initialParams={{
          latitude, longitude
        }} name='Home' component={Home} options={{ title: i18n.t('home.label'), }} />
        <AddLocationStack.Screen name='Messages' component={Messages} />
        <AddLocationStack.Screen name='ProductDetailed' component={ProductDetailed} />
        <AddLocationStack.Screen name='ChatNav' component={Messages} />
        <AddLocationStack.Screen name='PublicProfile' component={UserPublicProfile} />
        <AddLocationStack.Screen name='UserPay' component={UserPay} />
        <AddLocationStack.Screen name='OrganizationPage' component={OrganizationPage} />
        <AddLocationStack.Screen name='EditOrganizationPage' component={EditOrganizationPage} />
        <AddLocationStack.Screen name='EditOrganizationUsers' component={EditOrganizationUsers} />
        <AddLocationStack.Screen name='SetupOrganizationPage' component={SetupOrganizationPage} />
        <AddLocationStack.Screen name='OrganizationPageCart' component={OrganizationPageCart} />
        <AddLocationStack.Screen name="AddProducts" component={AddProducts} />
        <AddLocationStack.Screen name="AddProductDefaultSubCategory" component={AddProductDefaultSubCategory} />
        <AddLocationStack.Screen name="AddProductName" component={AddProductName} />
        <AddLocationStack.Screen name="AddProductPrice" component={AddProductPrice} />
        <AddLocationStack.Screen name="AddProductQuantity" component={AddProductQuantity} />
        <AddLocationStack.Screen name="AddProductDescription" component={AddProductDescription} />
        <AddLocationStack.Screen name="AddDeliveryOptions" component={AddDeliveryOption} />
        <AddLocationStack.Screen name="AddCountryOptions" component={AddCountryOptions} />
        <AddLocationStack.Screen name="AddPaymentOptions" component={AddPaymentOptions} />
        <AddLocationStack.Screen name="AddProductImage" component={AddProductImage} />
        <AddLocationStack.Screen name="AddProductGallery" component={AddProductGallery} />
        <AddLocationStack.Screen name="MyProductDetailed" component={MyProductDetailed} />
        <AddLocationStack.Screen name="EditMyProductName" component={EditProductName} />
        <AddLocationStack.Screen name="EditMyProductPrice" component={EditProductPrice} />
        <AddLocationStack.Screen name="EditMyProductQuantity" component={EditProductQuantity} />
        <AddLocationStack.Screen name="EditMyProductDescriptions" component={EditProductDescription} />
        <AddLocationStack.Screen name="EditMyProductListDescriptions" component={EditProductListDescription} />
        <AddLocationStack.Screen name="EditMyDeliveryOptions" component={EditDeliveryOption} />
        <AddLocationStack.Screen name="EditMyPaymentOptions" component={EditPaymentOptions} />
      </AddLocationStack.Group>
    </AddLocationStack.Navigator>
  )
  else if (Platform.OS === 'web') return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ paddingHorizontal: 5, paddingVertical: 5 }}>
        <Text style={{ fontSize: 32, fontFamily: 'Inter-Black' }}>{i18n.t('home.confirm_location')}</Text>
        {(Platform.OS === 'web') && <Text style={{ fontSize: 18, fontFamily: 'Inter-Regular' }}>{i18n.t('home.confirm_location_description_web')}</Text>}
      </View>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {/* <Button title={i18n.t('home.confirm_location_button')} onPress={() => {
        }}/> */}
        {(Platform.OS === 'web') ? (
          latitude && longitude && <MapView style={{ height: 450, width: 450 }}
            region={{
              latitude: parseFloat(regionLatitude),
              longitude: parseFloat(regionLongitude),
              latitudeDelta: parseFloat(regionLongitudeDelta),
              longitudeDelta: parseFloat(regionLongitudeDelta),

            }}
            initialRegion={{
              latitude: parseFloat(regionLatitude),
              longitude: parseFloat(regionLongitude),
              latitudeDelta: parseFloat(regionLongitudeDelta),
              longitudeDelta: parseFloat(regionLongitudeDelta),
            }}

            onRegionChangeComplete={(region) => {
              setRegionLatitude(parseFloat(region.latitude));
              setRegionLongitude(parseFloat(region.longitude));
            }}
            onPress={async (e) => {
              console.log('Pressed: ', e.latLng);
              setSelectedLatitude(e.latLng.lat());
              setSelectedLongitude(e.latLng.lng());
              setLatitude(e.latLng.lat());
              setLongitude(e.latLng.lng());
              setMapClicked(true);
            }}
          >
            <MapView.Marker
              key={1}
              coordinate={{
                latitude: selectedLatitude,
                longitude: selectedLongitude,
              }}
              title={i18n.t('home.my_location')}
              description={i18n.t('home.my_location')}
            />
          </MapView>
        ) : null}
        <TouchableOpacity style={{ height: 50, marginTop: 10, backgroundColor: '#FF6D00', justifyContent: 'center', alignItems: 'center', maxWidth: 350, borderRadius: 15 }} onPress={() => {
          let jsonGeography = {
            "type": "Point",
            "coordinates": [selectedLongitude, selectedLatitude]
          }
          updateLocation({
            variables: {
              uid: getAuth().currentUser.uid,
              coords: jsonGeography
            }
          }).then((res) => {
            setConfirmed(true);
            // console.log('Updated location: ', res);
            navigation.navigate('Home');
          }).catch((err) => {
            // console.log('Error updating location: ', err);
          })
        }}>
          <Text style={{ fontSize: 18, fontFamily: 'Inter-Bold', color: '#FFF', paddingHorizontal: 10 }}>{i18n.t('home.confirm_location_button')}</Text>
        </TouchableOpacity>
      </View>

    </SafeAreaView>
  )
  else if (Platform.OS === 'android' || Platform.OS === 'ios' && confirmed === false && permissions === false) return (
    <SafeAreaView style={{ flex: 1 }}>
      <View style={{ flex: 1, paddingTop: 12, paddingHorizontal: 6 }}>
        {console.log('Permissions: ', permissions)}
        <View style={{ flex: 1 }}>
          <Text style={{ fontSize: 32, fontWeight: 'bold' }}>{i18n.t('home.location_title_permission')}</Text>
          <Text style={{ fontSize: 18 }}>{i18n.t('home.location_body_permission')}</Text>
        </View>
        <View style={{ flex: 2 }}>
          <Text></Text>
        </View>
        <View style={{ flex: 0.4 }}>
          <TouchableOpacity style={{ backgroundColor: '#2979FF', padding: 10, borderRadius: 6, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
            requestPermissions();
          }
          }>
            <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#FFF' }}>{i18n.t('home.location_button_permission')}</Text>
          </TouchableOpacity>
        </View>
      </View>

    </SafeAreaView>
  )
}

function UserLocationNav({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Screen name='LocationNav' component={Locations}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='AddLocationNav' component={AddLocation}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='EditLocationNav' component={EditLocation}></AddLocationStack.Screen>
    </AddLocationStack.Navigator>
  )
}


function CustomCategoriesNav({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Screen name="MyCategories" component={CustomCategories}></AddLocationStack.Screen>
      <AddLocationStack.Screen name="AddCustomCategories" component={AddCustomCategories}></AddLocationStack.Screen>
      <AddLocationStack.Screen name="EditCustomCategories" component={EditCustomCategories}></AddLocationStack.Screen>
      <AddLocationStack.Screen name="detailedCustomCategories" component={DetailedCustomCategories}></AddLocationStack.Screen>
      <AddLocationStack.Screen name="editCategoryTranslations" component={EditCategoryTranslations}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='ProductsByCategory' component={ListProductsByCategory}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='addProductsByCategory' component={AddProductsByCategory}></AddLocationStack.Screen>
    </AddLocationStack.Navigator>
  )
}

function ProductsNav({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Screen name="MyProducts" component={myProducts} />
      <AddLocationStack.Screen name="AddProducts" component={AddProducts} />
      <AddLocationStack.Screen name="AddProductDefaultSubCategory" component={AddProductDefaultSubCategory} />
      <AddLocationStack.Screen name="AddProductName" component={AddProductName} />
      <AddLocationStack.Screen name="AddProductPrice" component={AddProductPrice} />
      <AddLocationStack.Screen name="AddProductQuantity" component={AddProductQuantity} />
      <AddLocationStack.Screen name="AddProductDescription" component={AddProductDescription} />
      <AddLocationStack.Screen name="AddDeliveryOptions" component={AddDeliveryOption} />
      <AddLocationStack.Screen name="AddCountryOptions" component={AddCountryOptions} />
      <AddLocationStack.Screen name="AddPaymentOptions" component={AddPaymentOptions} />
      <AddLocationStack.Screen name="AddProductImage" component={AddProductImage} />
      <AddLocationStack.Screen name="MyProductDetailed" component={MyProductDetailed} />
      <AddLocationStack.Screen name="EditMyProductName" component={EditProductName} />
      <AddLocationStack.Screen name="EditMyProductPrice" component={EditProductPrice} />
      <AddLocationStack.Screen name="EditMyProductQuantity" component={EditProductQuantity} />
      <AddLocationStack.Screen name="EditMyProductDescriptions" component={EditProductDescription} />
      <AddLocationStack.Screen name="EditMyProductListDescriptions" component={EditProductListDescription} />
      <AddLocationStack.Screen name="EditMyDeliveryOptions" component={EditDeliveryOption} />
      <AddLocationStack.Screen name="EditMyPaymentOptions" component={EditPaymentOptions} />
    </AddLocationStack.Navigator>
  )

}

function DriverMotorcycleNav({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Screen name='DriverNav' component={DeliveryMotorcycle} />
      <AddLocationStack.Screen name='Messages' component={Messages} />
      <AddLocationStack.Screen name='OrderDetails' component={ReviewOrderDelivery} />
      {/* <AddLocationStack.Screen name='DriverDetailed' component={DriverDetailed} /> */}
    </AddLocationStack.Navigator>
  )
}

function NavOrders({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Group>
        <AddLocationStack.Screen name='OrdersNav' component={Orders} />
        <AddLocationStack.Screen name='OrderDetails' component={OrderDetailed} />
        <AddLocationStack.Screen name='Messages' component={Messages} />
      </AddLocationStack.Group>
    </AddLocationStack.Navigator>
  )
}

function NavOrdersSeller({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Group>
        <AddLocationStack.Screen name='OrdersNav' component={OrdersSeller} />
        <AddLocationStack.Screen name='ReviewOrder' component={ReviewOrder} />
      </AddLocationStack.Group>
      <AddLocationStack.Group>
        <AddLocationStack.Screen name='Messages' component={Messages} />
      </AddLocationStack.Group>
    </AddLocationStack.Navigator>
  )
}

function TabOrders({ navigation }) {
  return (
    <TabStack.Navigator screenOptions={{ headerShown: false }}>
      <TabStack.Screen name='OrdersTab' options={{ title: i18n.t('menu.ordersTab.orders'), tabBarIcon: ({ tintColor }) => (<Feather name='book-open' size={24} color={'black'} />) }} component={NavOrdersSeller} />
    </TabStack.Navigator>
  )
}

function TabPaymentMethods({ navigation }) {
  return (
    <TabStack.Navigator screenOptions={{ headerShown: false }}>
      <TabStack.Screen name='PaymentMethodsNav' options={{ title: i18n.t('menu.paymentMethodsTab.payment'), tabBarIcon: ({ tintColor }) => (<Feather name='credit-card' size={25} color={'black'} />) }} component={PaymentMethodsNav} />
    </TabStack.Navigator>
  )
}

function NavUser({ navigation }) {
  return (
    <AddLocationStack.Navigator initialRouteName='UserNav' screenOptions={{ headerShown: false }}>
      <AddLocationStack.Screen name='UserNav' component={UserData} />
      <AddLocationStack.Screen name='PublicUserNav' component={UserPublicProfile} />
      <AddLocationStack.Screen name='ChatNav' component={Messages} />
      <AddLocationStack.Screen name='UserPay' component={UserPay} />
    </AddLocationStack.Navigator>
  )
}

function TabUser({ navigation }) {
  return (
    <TabStack.Navigator initialRouteName='UserData' screenOptions={{ headerShown: false }}>
      <TabStack.Screen name='UserData' options={{ title: i18n.t('menu.userTab.profile'), tabBarIcon: ({ tintColor }) => (<Feather name='user' size={24} color={'black'} />), tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />) }} component={NavUser} />
      <TabStack.Screen name='UserLocation' options={{ title: i18n.t('menu.userTab.locations'), tabBarIcon: ({ tintColor }) => (<Feather name='map-pin' size={24} color={'black'} />), tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />) }} component={UserLocationNav} />
    </TabStack.Navigator>
  )
}

function TabProducts({ navigation }) {
  return (
    <TabStack.Navigator screenOptions={{ headerShown: false }}>
      <TabStack.Screen name='CategoriesTab' options={{ title: i18n.t('menu.productsTab.categories'), tabBarHideOnKeyboard: true, tabBarIcon: ({ tintColor }) => (<Feather name='sidebar' size={24} color={'black'} />), tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />) }} component={CustomCategoriesNav} />
      <TabStack.Screen name='ProductsTab' options={{ title: i18n.t('menu.productsTab.products'), tabBarHideOnKeyboard: true, tabBarIcon: ({ tintColor }) => (<Feather name='package' size={24} color={'black'} />), tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />) }} component={ProductsNav} />
    </TabStack.Navigator>
  )
}

function TabDriverMotorcycle({ navigation }) {
  return (
    <TabStack.Navigator screenOptions={{ headerShown: false }}>
      <TabStack.Screen name='DriverTab' options={{ title: i18n.t('menu.driverTab.driver_trips'), tabBarHideOnKeyboard: true, tabBarIcon: ({ tintColor }) => (<Feather name='truck' size={24} color={'black'} />), tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />) }} component={DriverMotorcycleNav} />
    </TabStack.Navigator>
  )
}

function SearchNavigation({ navigation, route }) {
  return (
    <AddLocationStack.Navigator initialRouteName='Search' screenOptions={{ headerShown: false }}>
      <AddLocationStack.Screen name='Search' component={Search} />
      <AddLocationStack.Screen name='Results' component={Results} />
      <AddLocationStack.Screen name='CategoryDetailed' component={CategoriesDetailed} />
      <AddLocationStack.Screen name='ProductDetailed' component={ProductDetailed} />
      <AddLocationStack.Screen name='Messages' component={Messages} />
    </AddLocationStack.Navigator>
  )
}


function TabMenu({ navigation, route }) {
  // console.log("Params: ", route)

  return (
    <TabStack.Navigator backBehavior='initialRoute' screenOptions={{
      headerShown: false, headerStatusBarHeight: 0, tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />),
      tabBarActiveTintColor: '#FF6D00', tabBarLabelStyle: { fontFamily: 'Inter-Bold', fontSize: Platform.OS !== 'web' ? 14 : 16 }
    }}>
      <TabStack.Screen
        options={{
          tabBarHideOnKeyboard: true,
          title: i18n.t('menu.homeTab.home'), tabBarIcon: ({ tintColor }) => (<Feather name='home' size={24} color={'black'} />)
        }}
        name='HomeTab' component={HomeNav} />
      <TabStack.Screen name='SearchTab' options={{ tabBarHideOnKeyboard: true, title: i18n.t('menu.homeTab.search'), tabBarIcon: ({ tintColor }) => (<Feather name='search' size={24} color={'black'} />) }} component={SearchNavigation} />
      {(Platform.OS != 'web') ? (
        <TabStack.Screen name='SupportTab' options={{ tabBarHideOnKeyboard: true, title: i18n.t('menu.homeTab.support'), tabBarIcon: ({ tintColor }) => (<Feather name='life-buoy' size={24} color={'black'} />) }} component={Support} />
      ) : null}
      <TabStack.Screen name='OrdersTab' options={{ tabBarHideOnKeyboard: true, title: i18n.t('menu.homeTab.orders'), tabBarIcon: ({ tintColor }) => (<Feather name='shopping-bag' size={24} color={'black'} />) }} component={NavOrders} />
    </TabStack.Navigator>
  )
}

function NavTabMessages({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false }}>
      <AddLocationStack.Group>
        <AddLocationStack.Screen name='Inbox' component={Inbox} />
        <AddLocationStack.Screen name='Message' component={Messages} />
      </AddLocationStack.Group>
    </AddLocationStack.Navigator>

  )
}

function PaymentMethodsNav({ navigation }) {
  return (
    <AddLocationStack.Navigator screenOptions={{ headerShown: false, detachPreviousScreen: true }}>
      <AddLocationStack.Screen name='PaymentMethodsScreen' component={PaymentMethods}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='AddPaymentMethodsNav' component={AddPaymentMethod}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='PayPalConfig' component={PayPalConfig}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='CashConfig' component={CashConfig}></AddLocationStack.Screen>
      <AddLocationStack.Screen name='MenuPayIndividual' component={MenuPayIndividual}></AddLocationStack.Screen>
    </AddLocationStack.Navigator>
  )
}


function TabMessages({ }) {
  return (
    <TabStack.Navigator screenOptions={{ headerShown: false }}>
      <TabStack.Screen name='MessagesTab' options={{ tabBarHideOnKeyboard: true, title: i18n.t('menu.messagesTab.inbox'), tabBarIcon: ({ titnColor }) => (<Feather name='message-circle' size={24} color={'black'} />), tabBarBackground: () => (<View style={{ backgroundColor: '#BBDEFB', height: 60, borderTopColor: '#ddd', borderTopWidth: 1 }} />) }} component={NavTabMessages} />
    </TabStack.Navigator>
  )
}

function DrawerFirst({ navigation }) {
  const [accountSetup, setAccountSetup] = useState(null);

  const uid = getAuth().currentUser.uid;
  // console.log("UID", uid)

  /**Query */

  const { loading: loadingAccountSetup, error: errorAccountSetup, data: dataAccountSetup } = useQuery(GET_ACCOUNT_HAS_SETUP, { variables: { uid } });


  useEffect(() => {

    async function getAccountSetup() {
      try {
        // console.log("ErrorAccountSetup", errorAccountSetup);

        if (dataAccountSetup) {
          const { users_by_pk } = dataAccountSetup;
          const { user_setup } = users_by_pk;
          setAccountSetup(user_setup);
          // console.log("USER_SETUP", dataAccountSetup);
        }
      } catch (error) {
        console.log(error);
        // console.log("USER_SETUP", accountSetup)
        setAccountSetup(null);
      }
    }
    // console.log("Query Error: ", errorAccountSetup);
    getAccountSetup();
  }, []);


  return (

    <DrawerStack.Navigator initialRouteName='Home' drawerContent={props => {
      return (
        <DrawerContentScrollView {...props}>

          <DrawerItemList {...props} />
          <DrawerItem label="Log out" onPress={() => {
            const user = getAuth().currentUser;
            if (user.isAnonymous) {
              getAuth().signOut();
              user.delete().then(() => {
                console.log("User deleted");
              }).catch((error) => {
                console.log("Error deleting user");
              });
            } else {
              getAuth().signOut().then(() => {
                // Sign-out successful.
                console.log("Sign-out successful.");
                navigation.navigate('Login')
              }).catch((error) => {
                // An error happened.
                console.log("An error happened.");
              });
            }
          }} />
        </DrawerContentScrollView>
      )
    }}>
      <DrawerStack.Screen name="Home" component={TabMenu} options={{
        title: i18n.t('home.label'), headerTintColor: '#e7305b', headerStyle: {
          backgroundColor: '#e7305b',
        }
      }} />
      <DrawerStack.Screen name='Messages' options={{ title: i18n.t('menu.messages') }} component={TabMessages} />
      <DrawerStack.Screen name='Products' options={{ title: i18n.t('menu.products') }} component={TabProducts} />
      <DrawerStack.Screen name='UserSettings' options={{ title: i18n.t('menu.userSettings') }} component={TabUser} />
      <DrawerStack.Screen name='PaymentMethods' options={{ title: i18n.t('menu.paymentMethods') }} component={TabPaymentMethods} />
      <DrawerStack.Screen name='Orders' options={{ title: i18n.t('menu.orders') }} component={TabOrders} />
      {/* <DrawerStack.Screen name='TabMenu' component={Tab}/> */}
    </DrawerStack.Navigator>
  )
}

function SetupAccount({ navigation }) {
  return (
    <SetupAccountStack.Navigator initialRouteName='Greeting' screenOptions={{ headerShown: false }}>
      <SetupAccountStack.Screen name='Greeting' component={Greeting} />
      <SetupAccountStack.Screen name='NameScreen' component={Name} />
      <SetupAccountStack.Screen name='Lastname' component={Lastname} />
      <SetupAccountStack.Screen name='PhoneScreen' component={Phone} />
      <SetupAccountStack.Screen name='CountryScreen' component={Country} />
      <SetupAccountStack.Screen name='ProvinceScreen' component={Province} />
      <SetupAccountStack.Screen name='CityScreen' component={City} />
      <SetupAccountStack.Screen name='DrawerOne' component={DrawerFirst} />
      <SetupAccountStack.Screen name='CurrencyScreen' component={Currency} />
    </SetupAccountStack.Navigator>
  )
}


function Drawer({ navigation, route }) {
  const isFocused = useIsFocused();
  const [accountSetup, setAccountSetup] = useState(true);
  const [user, setUser] = useState(null);
  const [uid, setUid] = useState(null);
  const [accountAssignedRoles, setAccountAssignedRoles] = useState(null);
  const [userLanguage, setUserLanguage] = useState(null);

  // const uid = getAuth().currentUser.uid;
  // console.log("UID", uid)

  /**Query */

  const { loading: loadingAccountSetup, error: errorAccountSetup, data: dataAccountSetup, refetch } = useQuery(GET_ACCOUNT_HAS_SETUP, { variables: { uid } });

  const Dimensions = useWindowDimensions();
  useEffect(() => {

    if(isFocused){
      getAuth().onAuthStateChanged(async function (user) {
        if (user) {
          // User is signed in.
          console.log("User is signed in.");
          setUser(user);
          setUid(user.uid);
          await getAccountSetup();
        } else {
          // No user is signed in.
          console.log("No user is signed in.");
          setAccountSetup(false);
          setUser(null);
        }
      });  
    }

    async function getAccountSetup() {
      try {
        // console.log("ErrorAccountSetup", errorAccountSetup);
        if (dataAccountSetup) {
          const { users_by_pk } = dataAccountSetup;
          const { user_setup } = users_by_pk;
          const { user_assigned_roles } = users_by_pk;
          const { user_language } = users_by_pk;
          setAccountSetup(user_setup);
          setAccountAssignedRoles(user_assigned_roles);

          if (user_language) {
            setUserLanguage(user_language);
            i18n.locale = user_language;
          }

          user_assigned_roles.find((role) => {
            if (role.user_role_id === 1) {
              console.log("DRIVER")
            }
          })
          console.log("USER_SETUP", user_assigned_roles);
        }
      } catch (error) {

        getAuth().currentUser.refreshToken;
        // console.log(error);
        // console.log("USER_SETUP", accountSetup)
        setAccountSetup(false);
      }
    }
    // console.log("Query Error: ", errorAccountSetup);
    // getAccountSetup();
    if (isFocused) {
      refetch();
    }
  }, [isFocused, userLanguage, uid, dataAccountSetup]);
  if (accountSetup === null) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#FF6D00" />
      </View>
    )
  }
  if (user || !user) {
    return (
      <DrawerStack.Navigator initialRouteName='Home' screenOptions={{
        // drawerContentContainerStyle: {
        //   width: Dimensions.width * 0.8,
        // },
        drawerType: 'front',
        // drawerStyle: {
        //   backgroundColor: '#fff',
        //   width: Dimensions.width * 0.8,
        // },
        drawerActiveTintColor: '#FF6D00',
        drawerInactiveTintColor: '#000000',
        drawerActiveBackgroundColor: '#fff',
        drawerInactiveBackgroundColor: '#fff',
        drawerItemStyle: {
          borderBottomWidth: 1,
          borderBottomColor: '#e0e0e0',
        },
      }} drawerContent={props => {
        if (user) {
          return (
            <DrawerContentScrollView {...props}>
              <DrawerItemList {...props} />
              <DrawerItem label={i18n.t('menu.logout')} onPress={async () => {
                //stop listening to location
                // console.log("Stop listening to location");
                if (Platform.OS !== 'web') {
                  const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
                  if (taskIsDefined) {
                    Location.stopLocationUpdatesAsync(LOCATION_TASK_NAME);
                  }
                }
                getAuth().signOut();
              }} />
            </DrawerContentScrollView>
          )
        } else {
          return (
            <DrawerContentScrollView {...props}>
              <DrawerItemList {...props} />
              <DrawerItem label={i18n.t('menu.login')} onPress={async () => {
                navigation.navigate('Login');
                
              }} />
            </DrawerContentScrollView>
          )
        }
      }}>
        {(user && accountSetup || !user) ? (
          <>
            <DrawerStack.Screen name="Home" component={TabMenu} options={
              ({ navigation, route }) => ({
                title: i18n.t('home.label'),
                headerTintColor: '#fff',
                headerStyle: {
                  backgroundColor: '#FF6D00',
                },

                headerLeft: () => (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity onPress={() => navigation.openDrawer()}>
                      <Feather name="menu" size={24} color="#fff" style={{ marginLeft: 10 }} />
                    </TouchableOpacity>
                    {/* back button if route is not Home*/}
                    <TouchableOpacity onPress={() => navigation.goBack()} style={{ marginLeft: 10 }}>
                      <Feather name="arrow-left" size={24} color="#fff" />
                    </TouchableOpacity>
                  </View>
                ),
              })
            } />
          </>
        ) : null}
        {(user && !accountSetup) ? (
          <>
            <DrawerStack.Screen name="Home" component={SetupAccount} options={{headerShown: false}}/>
          </>
        ) : null}

        {(user) ? (
          <>
            <DrawerStack.Screen name='Messages' options={{ title: i18n.t('menu.messages'), headerTitleStyle: { fontFamily: 'Inter-Medium', color: "#e0e0e0" }, headerStyle: { backgroundColor: '#FF6D00' } }} component={TabMessages} />
            <DrawerStack.Screen name='Products' options={{ title: i18n.t('menu.products'), headerTitleStyle: { fontFamily: 'Inter-Medium', color: "#e0e0e0" }, headerStyle: { backgroundColor: '#FF6D00' } }} component={TabProducts} />
          </>
        ) : null}
        {/* {(user && accountAssignedRoles.length > 0 && accountAssignedRoles.find((role) => role.user_role_id === 1)) ? <DrawerStack.Screen name='Driver' options={{ title: i18n.t('menu.driver_motorcycle'), headerTitleStyle: { fontFamily: 'Inter-Medium', color: "#e0e0e0" }, headerStyle: { backgroundColor: '#FF6D00' } }} component={TabDriverMotorcycle} /> : null} */}
        {/* {(accountAssignedRoles.find((role) => role.user_role_id === 1)) ? <DrawerStack.Screen name='Driver' options={{ title: i18n.t('menu.driver') }} component={TabDriver} /> : null} */}
        {(user && accountAssignedRoles) ? (
          <>
            {(accountAssignedRoles.find((role) => role.user_role_id === 1)) ? <DrawerStack.Screen name='Driver' options={{ title: i18n.t('menu.driver_motorcycle') }} component={TabDriverMotorcycle} /> : null}
          </>
        ) : null}
        {(user) ? (
          <>
            <DrawerStack.Screen name='UserSettings' options={{ title: i18n.t('menu.userSettings'), headerTitleStyle: { fontFamily: 'Inter-Medium', color: "#e0e0e0" }, headerStyle: { backgroundColor: '#FF6D00' } }} component={TabUser} />
            <DrawerStack.Screen name='PaymentMethods' options={{ title: i18n.t('menu.paymentMethods'), headerTitleStyle: { fontFamily: 'Inter-Medium', color: "#e0e0e0" }, headerStyle: { backgroundColor: '#FF6D00' } }} component={TabPaymentMethods} />
            <DrawerStack.Screen name='Orders' options={{ title: i18n.t('menu.orders'), headerTitleStyle: { fontFamily: 'Inter-Medium', color: "#e0e0e0" }, headerStyle: { backgroundColor: '#FF6D00' } }} component={TabOrders} />
          </>
        ) : null}
      </DrawerStack.Navigator>
    )
  }
}


export default function App() {

  const lastNotificationResponse = Notifications.useLastNotificationResponse();

  const [fontsLoaded] = useFonts({
    'Inter-Black': require('./assets/fonts/Roboto-Black.ttf'),
    'Inter-BlackItalic': require('./assets/fonts/Roboto-BlackItalic.ttf'),
    'Inter-Bold': require('./assets/fonts/Roboto-Bold.ttf'),
    'Inter-BoldItalic': require('./assets/fonts/Roboto-BoldItalic.ttf'),
    'Inter-Italic': require('./assets/fonts/Roboto-Italic.ttf'),
    'Inter-Light': require('./assets/fonts/Roboto-Light.ttf'),
    'Inter-LightItalic': require('./assets/fonts/Roboto-LightItalic.ttf'),
    'Inter-Medium': require('./assets/fonts/Roboto-Medium.ttf'),
    'Inter-MediumItalic': require('./assets/fonts/Roboto-MediumItalic.ttf'),
    'Inter-Regular': require('./assets/fonts/Roboto-Regular.ttf'),
    'Inter-Thin': require('./assets/fonts/Roboto-Thin.ttf'),
    'Inter-ThinItalic': require('./assets/fonts/Roboto-ThinItalic.ttf'),
  });

  console.log('fontsLoaded', fontsLoaded)
  /**Prefix linking */
  const linking = {
    prefixes: [prefix, 'http://localhost:19006/', 'https://menucitos.com/', 'https://www.menucitos.com/', 'https://menucitos.page.link/', 'menucitos://', 'https://localhost:19006/'],
    config: {
      screens: {
        Landing: {
          path: '/',
        },
        Login: {
          path: 'login',
        },
        Signup: {
          path: 'signup',
        },
        Recover: {
          path: 'recover',
        },
        Privacy: {
          path: 'privacy',
        },
        Policies: {
          path: 'policies',
        },
        Drawer: {
          path: 'drawer',
          initialRouteName: 'Home',
          screens: {
            Greeting: {
              path: 'greeting',
            },
            NameScreen: {
              path: 'name',
            },
            Lastname: {
              path: 'lastname',
            },
            CountryScreen: {
              path: 'country',
            },
            ProvinceScreen: {
              path: 'province',
            },
            CityScreen: {
              path: 'city',
            },
            Home: {
              path: 'home',
              screens: {
                HomeTab: {
                  /**Here it fixed the product link! */
                  initialRouteName: 'Home',
                  path: 'navigation',
                  screens: {
                    Home: {
                      path: 'main/',
                      parse: {
                        latitude: latitude => parseFloat(latitude),
                        longitude: longitude => parseFloat(longitude),
                      }

                    },
                    ProductDetailed: {
                      initialRouteName: 'Home',
                      path: 'product/',
                      parse: {
                        productId: productId => parseInt(productId),
                        sellerId: sellerId => parseInt(sellerId),
                        distanceCart: distanceCart => parseFloat(distanceCart),
                        organization_id: organization_id => parseInt(organization_id),
                      }
                    },
                    Messages: {
                      path: 'messages/:chatId/',

                    },
                    OrganizationPage: {
                      path: 'organization/:organizationId/',
                      parse: {
                        latitude: latitude => parseFloat(latitude),
                        longitude: longitude => parseFloat(longitude),
                        distance: distance => parseFloat(distance),
                        organizationId: organizationId => parseInt(organizationId),
                      }
                    },
                    OrganizationPageCart: {
                      path: 'organization/:organizationId/cart/',
                    }
                  }
                },
                SearchTab: {
                  path: 'search',
                  initialRouteName: 'Search',
                  screens: {
                    Search: {
                      path: 'search'
                    },
                    Results: {
                      path: 'results'
                    },
                    ProductDetailed: {
                      path: 'product/:productId/',
                      parse: {
                        productId: productId => parseInt(productId),
                        sellerId: sellerId => /**Text */ sellerId,
                        distanceCart: distanceCart => parseFloat(distanceCart),
                        organization_id: organization_id => parseInt(organization_id ? organization_id : 0)
                      }
                    },
                    CategoryDetailed: {
                      path: 'category/:categoryId/',
                      parse: {
                        latitude: latitude => parseFloat(latitude),
                        longitude: longitude => parseFloat(longitude),
                        distanceCart: distanceCart => parseFloat(distanceCart),
                      }
                    }
                  }
                }
              }
            },
            Messages: {
              path: 'messages',
              initialRouteName: 'MessagesTab',
              screens: {
                MessagesTab: {
                  path: 'navigation',
                  initialRouteName: 'Inbox',
                  screens: {
                    Inbox: 'inbox',
                    Message: 'message/:chatId/',
                  }
                }
              }
            },
            UserSettings: {
              path: 'usersettings',
              screens: {
                UserData: {
                  path: 'profile',
                  initialRouteName: 'UserNav',
                  screens: {
                    UserNav: 'me',
                    PublicUserNav: 'public/:user_id',
                  }
                }
              },
            },
            Orders: {
              path: 'orders',
              screens: {
                OrdersTab: {
                  path: 'navigation',
                  screens: {
                    OrdersNav: {
                      path: 'orders',

                    }
                  }
                }
              }
            },
            Products: {
              path: 'products',
              screens: {
                ProductsTab: {
                  path: 'navigation',
                  screens: {
                    MyProducts: {
                      path: 'myproducts',
                    }
                  }
                },
                CategoriesTab: {
                  path: 'categories',
                  screens: {
                    MyCategories: {
                      path: 'mycategories',
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
  }

  const appState = useRef(AppState.currentState)
  const [appStateVisible, setAppStateVisible] = useState(appState.current)
  const [user, setUser] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [session, setSession] = useState(false);
  const [setup, setSetup] = useState(false);
  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  const navigationRef = useRef()

  const [data, setData] = useState(null);

  /**Mutation */

  const [setUserOnline, { loading: setUserOnlineLoading, error: setUserOnlineError, data: setUserOnlineData }] = useMutation(SET_USER_ONLINE);
  const [setUserOffline, { loading: setUserOfflineLoading, error: setUserOfflineError, data: setUserOfflineData }] = useMutation(SET_USER_OFFLINE);
  const [setUserLastSeen, { loading: setUserLastSeenLoading, error: setUserLastSeenError, data: setUserLastSeenData }] = useMutation(SET_LAST_SEEN_ACTIVITY);
  const [updateUserLanguage, { loading: updateUserLanguageLoading, error: updateUserLanguageError, data: updateUserLanguageData }] = useMutation(UPDATE_USER_LANGUAGE);
  /**Query version_information*/

  const { loading: versionLoading, error: versionError, data: versionData } = useQuery(VERSION_INFORMATION, {
    fetchPolicy: 'network-only',
  });


  async function registerForPushNotificationsAsync(uid, reqToken) {
    if (Device.isDevice) {

      if (uid && reqToken) {
        if (!loaded) {
          if (Platform.OS === 'android' || Platform.OS === 'ios') {
            const { status: existingStatus } = await Notifications.getPermissionsAsync();
            let finalStatus = existingStatus;
            if (existingStatus !== 'granted') {
              const { status } = await Notifications.requestPermissionsAsync();
              finalStatus = status;
            }
            if (finalStatus !== 'granted') {
              // alert('Failed to get push token for push notification!');
              return;
            }
            const token = (await Notifications.getExpoPushTokenAsync()).data;
            console.log(token);
            // setExpoPushToken(token);

            const query = JSON.stringify({
              query: `
              mutation {
                update_users_by_pk(pk_columns:{uid:"${uid}"}, _set:{user_expo_token:"${token}"}) {
                  uid
                  user_expo_token
                }
              }
            `,
            });

            // try {

            if (reqToken) {
              try {
                const response = await fetch("https://console.menucitos.com/v1/graphql", {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${await reqToken}`,
                  },
                  body: query,
                });
                const data = await response.json();
                // console.log("TOKEN: ", data);
              } catch (error) {
                console.log("ERROR: ", error);
              }
            } else {
              console.log("NO TOKEN");
            }
          }
        }
      }

    } else {
      alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
        showBadge: true,
      });
    }
  };

  function handleDeepLink(event) {
    console.log("EVENT URL: ", event.url)
    let data = Linking.parse(event.url);
    console.log("DEEPLINKkkkkkkkkkkkkkkkkkkkkk: ", data);
    setData(data);
  }

  /**Appstate Active or Offline user */
  const handleAppStateChange = (nextAppState) => {
    // console.log("APPSTATE: ", nextAppState);
    if (appState.current.match(/inactive|background/) && nextAppState === 'active') {
      /**IOS tracking alert */
      (async () => {
        setTimeout(async () => {
          const { status } = await requestTrackingPermissionsAsync();
          if (status === 'granted') {
            console.log('Yay! I have user permission to track data');
          } else {
            console.log('No permission to track data');
          }
        }, 1000);

        ///register location tracking
        const locationPermission = await Location.getForegroundPermissionsAsync();
        const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
        console.log("IS TASK DEFINED: ", taskIsDefined);
        if (Platform.OS === 'android' && locationPermission.status === 'granted' && !taskIsDefined) {
          Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
            accuracy: Location.Accuracy.Balanced,
            timeInterval: 1000,
            distanceInterval: 1,
            foregroundService: {
              notificationTitle: i18n.t('home.location_service_title'),
              notificationBody: i18n.t('home.location_service_body'),
              notificationColor: '#FF231F7C',
            },
          });
        }
      })();
      console.log('App has come to the foreground!');

      getAuth().onAuthStateChanged(async (user) => {
        if (user) {
          // console.log("USER:", user.uid);
          setUserOnline({
            variables: {
              uid: user.uid
            }
          }).then(() => {
            console.log("ONLINE");
          })
        }
      })
    } else {
      console.log("OFFLINE")
      ///unregister location tracking
      if (Platform.OS === 'android') {
        (async () => {
          const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
          console.log("IS TASK DEFINED gu gu: ", taskIsDefined);
          if (taskIsDefined) {
            Location.stopLocationUpdatesAsync(LOCATION_TASK_NAME);
          }
        })();
      }
      getAuth().onAuthStateChanged(async (user) => {
        if (user) {
          // console.log("USER:", user.uid);
          setUserOffline({
            variables: {
              uid: user.uid
            }
          })
          setUserLastSeen({
            variables: {
              uid: user.uid
            }
          })
        }
      })
    }
    appState.current = nextAppState;
    setAppStateVisible(appState.current);
    console.log('AppState', appState.current);
  };



  useEffect(() => {
    (async () => {

      //get language item from storage
      const language = await AsyncStorage.getItem('language');
      if (language) {
        console.log("LANGUAGE: ", language);
        i18n.locale = language;
      } else {
        console.log("NO LANGUAGE: ", Localization.locale);
        i18n.locale = Localization.locale;
      }

      if (Platform.OS != 'web') {
        initStripe({
          publishableKey: "pk_live_51Mf91sFD83VMkUmAQK27ps6zjEoB3X6Kh5PzXjElkbsT0zevztQGogNUvWfj2nRonKlSyG4HL1p5W4D72E61mLHd00hzyQWIUc",
          urlScheme: Constants.appOwnership == "expo" ? Linking.createURL('/--/') : Linking.createURL(''),
          merchantIdentifier: "merchant.com.nelsonarguera.menu",
        }).then(() => {
          console.log("Stripe Initialized");
        }).catch((error) => {
          console.log("ERROR INITIALIZING STRIPE: ", error);
        })
      }

      /**get is session is active from Firebase */
      getAuth().onAuthStateChanged(async user => {
        if (user) {
          // setLoaded(true);
          //if user logged in with facebook wait for custom claims to be set
          if (user.isAnonymous) {
            user.getIdToken(true);
            console.log("FACEBOOK USER");
            if (user) {
              setUser(user);
              setLoaded(true);
              setUserOnline({
                variables: {
                  uid: user.uid
                }
              }).then(() => {
                console.log("USER ONLINE");
              }).catch((error) => {
                console.log("ERROR SETTING USER ONLINE:", error);
                if (Platform.OS === 'web') {
                  // window.location.reload();
                  window.location.reload();
                } else {
                  user.getIdToken(true);
                }
              });
              //update user language
              updateUserLanguage({
                variables: {
                  uid: user.uid,
                  languageCode: i18n.locale
                }
              }).then(() => {
                console.log("USER LANGUAGE UPDATED");

              }).catch((error) => {
                console.log("ERROR UPDATING USER LANGUAGE:", error);
                if (Platform.OS === 'web') {
                  // window.location.reload();
                  window.location.reload();
                } else {
                  user.getIdToken(true);
                }
              });
              // setLoaded(true);
              if (Platform.OS != 'web') {
                registerForPushNotificationsAsync(user.uid, user.getIdToken(true)).then(token => setExpoPushToken(token));
                notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                  console.log("NOTIFICATION:", notification);
                  setNotification(notification);
                  // console.log("NOTIFICATION URL:", notification.request.content.data.url);
                });

                responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                  console.log("RESPONSE:", response);
                  if (response.notification.request.content.data.action === "send_message") {
                    navigationRef.current?.navigate('Messages', {
                      chatId: response.notification.request.content.data.chatId,
                      expoToken: response.notification.request.content.data.expoToken,
                    });
                  }
                });

                // linking open data url
                if (data) {
                  console.log("DATAAAAAAAAAAAAAA:", data);
                  if (data.path == "drawer/home/navigation/product") {
                    navigationRef.current?.navigate('Drawer', {
                      screen: 'Home',
                      params: {
                        screen: 'HomeTab',
                        params: {
                          screen: 'ProductDetailed',
                          initial: false,
                          initialRouteName: 'HomeTab',
                          params: {
                            distanceCart: data.queryParams.distanceCart,
                            latitude: data.queryParams.latitude,
                            longitude: data.queryParams.longitude,
                            productId: data.queryParams.productId,
                            organization_id: data.queryParams.organization_id,
                            sellerId: data.queryParams.sellerId,
                          },
                        }
                      },
                    });
                    setData(null);
                  }
                  else if (data.path.includes("drawer/home/navigation/organization")) {
                    //get the organization id from the url after path: 'organization/:organizationId/',
                    const organizationId = data.path.split('/')[4];
                    console.log("ORGANIZATION ID: ", organizationId);
                    navigationRef.current?.navigate('Drawer', {
                      screen: 'Home',
                      params: {
                        screen: 'HomeTab',
                        params: {
                          screen: 'OrganizationPage',
                          initial: false,
                          initialRouteName: 'HomeTab',
                          params: {
                            distance: parseFloat(data.queryParams.distance),
                            latitude: parseFloat(data.queryParams.latitude),
                            longitude: parseFloat(data.queryParams.longitude),
                            organizationId: parseInt(organizationId),
                          },
                        }
                      },
                    });
                    setData(null);
                  }
                }
                const locationPermission = await Location.getForegroundPermissionsAsync();
                const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
                console.log("IS TASK DEFINED: ", taskIsDefined);
                if (Platform.OS === 'android' && locationPermission.status === 'granted' && !taskIsDefined) {
                  Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
                    accuracy: Location.Accuracy.Balanced,
                    timeInterval: 1000,
                    distanceInterval: 1,
                    foregroundService: {
                      notificationTitle: i18n.t('home.location_service_title'),
                      notificationBody: i18n.t('home.location_service_body'),
                      notificationColor: '#FF231F7C',
                    },
                  });
                }
              }
              // setLoaded(true);
            } else {
              setUser(false);
              console.log("USER NOT LOGGED IN");
            }
          } else {
            if (user.providerData[0].providerId == "facebook.com") {
              user.getIdToken(true);
              console.log("FACEBOOK USER");

              if (user) {
                setUser(user);
                setLoaded(true);
                setUserOnline({
                  variables: {
                    uid: user.uid
                  }
                }).then(() => {
                  console.log("USER ONLINE");
                }).catch((error) => {
                  console.log("ERROR SETTING USER ONLINE:", error);
                  if (Platform.OS === 'web') {
                    // window.location.reload();
                    user.refreshToken(true);
                  } else {
                    user.refreshToken(true);
                  }
                });
                //update user language
                updateUserLanguage({
                  variables: {
                    uid: user.uid,
                    languageCode: i18n.locale
                  }
                }).then(() => {
                  console.log("USER LANGUAGE UPDATED");
                }).catch((error) => {
                  console.log("ERROR UPDATING USER LANGUAGE:", error);
                });
                // setLoaded(true);
                if (Platform.OS != 'web') {
                  registerForPushNotificationsAsync(user.uid, user.getIdToken(true)).then(token => setExpoPushToken(token));
                  notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                    console.log("NOTIFICATION:", notification);
                    setNotification(notification);
                    // console.log("NOTIFICATION URL:", notification.request.content.data.url);
                  });

                  responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                    console.log("RESPONSE:", response);
                    if (response.notification.request.content.data.action === "send_message") {
                      navigationRef.current?.navigate('Messages', {
                        chatId: response.notification.request.content.data.chatId,
                        expoToken: response.notification.request.content.data.expoToken,
                      });
                    }
                  });


                  const locationPermission = await Location.getForegroundPermissionsAsync();
                  const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
                  console.log("IS TASK DEFINED: ", taskIsDefined);
                  if (Platform.OS === 'android' && locationPermission.status === 'granted' && !taskIsDefined) {
                    Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
                      accuracy: Location.Accuracy.Balanced,
                      timeInterval: 1000,
                      distanceInterval: 1,
                      foregroundService: {
                        notificationTitle: i18n.t('home.location_service_title'),
                        notificationBody: i18n.t('home.location_service_body'),
                        notificationColor: '#FF231F7C',
                      },
                    });
                  }
                }
                // linking open data url
                if (data) {
                  if (data.path == "drawer/home/navigation/product") {
                    navigationRef.current?.navigate('Drawer', {
                      screen: 'Home',
                      params: {
                        screen: 'HomeTab',
                        params: {
                          screen: 'ProductDetailed',
                          initial: false,
                          initialRouteName: 'HomeTab',
                          params: {
                            distanceCart: data.queryParams.distanceCart,
                            latitude: data.queryParams.latitude,
                            longitude: data.queryParams.longitude,
                            productId: data.queryParams.productId,
                            organization_id: data.queryParams.organization_id,
                            sellerId: data.queryParams.sellerId,
                          },
                        }
                      },
                    });
                    setData(null);
                  }
                  else if (data.path.includes("drawer/home/navigation/organization")) {
                    //get the organization id from the url after path: 'organization/:organizationId/',
                    const organizationId = data.path.split('/')[4];
                    console.log("ORGANIZATION ID: ", organizationId);
                    navigationRef.current?.navigate('Drawer', {
                      screen: 'Home',
                      params: {
                        screen: 'HomeTab',
                        params: {
                          screen: 'OrganizationPage',
                          initial: false,
                          initialRouteName: 'HomeTab',
                          params: {
                            distance: parseFloat(data.queryParams.distance),
                            latitude: parseFloat(data.queryParams.latitude),
                            longitude: parseFloat(data.queryParams.longitude),
                            organizationId: parseInt(organizationId),
                          },
                        }
                      },
                    });
                    setData(null);
                  }
                }
                // setLoaded(true);
              } else {
                setUser(false);
              }
            } else if (user.providerData[0].providerId == "apple.com") {
              user.getIdToken(true);
              // console.log("APPLE USER");
              if (user) {
                setUser(user);
                setLoaded(true);
                setUserOnline({
                  variables: {
                    uid: user.uid
                  }
                }).then(() => {
                  console.log("USER ONLINE");
                }).catch((error) => {
                  console.log("ERROR SETTING USER ONLINE:", error);
                  if (Platform.OS === 'web') {
                    window.location.reload();
                  } else {
                    user.refreshToken(true);
                  }
                });

                //update user language
                updateUserLanguage({
                  variables: {
                    uid: user.uid,
                    languageCode: i18n.locale
                  }
                }).then(() => {
                  console.log("USER LANGUAGE UPDATED");
                }).catch((error) => {
                  console.log("ERROR UPDATING USER LANGUAGE:", error);
                });

                // setLoaded(true);
                if (Platform.OS != 'web') {
                  registerForPushNotificationsAsync(user.uid, user.getIdToken(true)).then(token => setExpoPushToken(token));
                  notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                    console.log("NOTIFICATION:", notification);
                    setNotification(notification);
                    // console.log("NOTIFICATION URL:", notification.request.content.data.url);
                  });

                  responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                    console.log("RESPONSE:", response);
                    if (response.notification.request.content.data.action === "send_message") {
                      navigationRef.current?.navigate('Messages', {
                        chatId: response.notification.request.content.data.chatId,
                        expoToken: response.notification.request.content.data.expoToken,
                      });
                    }
                  });
                  const locationPermission = await Location.getForegroundPermissionsAsync();
                  const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
                  console.log("IS TASK DEFINED: ", taskIsDefined);
                  if (Platform.OS === 'android' && locationPermission.status === 'granted' && !taskIsDefined) {
                    Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
                      accuracy: Location.Accuracy.Balanced,
                      timeInterval: 1000,
                      distanceInterval: 1,
                      foregroundService: {
                        notificationTitle: i18n.t('home.location_service_title'),
                        notificationBody: i18n.t('home.location_service_body'),
                        notificationColor: '#FF231F7C',
                      },
                    });
                  }
                }
                // linking open data url
                if (data) {
                  if (data.path == "drawer/home/navigation/product") {
                    navigationRef.current?.navigate('Drawer', {
                      screen: 'Home',
                      params: {
                        screen: 'HomeTab',
                        params: {
                          screen: 'ProductDetailed',
                          initial: false,
                          initialRouteName: 'HomeTab',
                          params: {
                            distanceCart: data.queryParams.distanceCart,
                            latitude: data.queryParams.latitude,
                            longitude: data.queryParams.longitude,
                            productId: data.queryParams.productId,
                            organization_id: data.queryParams.organization_id,
                            sellerId: data.queryParams.sellerId,
                          },
                        }
                      },
                    });
                    setData(null);
                  } else if (data.path.includes("drawer/home/navigation/organization")) {
                    //get the organization id from the url after path: 'organization/:organizationId/',
                    const organizationId = data.path.split('/')[4];
                    console.log("ORGANIZATION ID: ", organizationId);
                    navigationRef.current?.navigate('Drawer', {
                      screen: 'Home',
                      params: {
                        screen: 'HomeTab',
                        params: {
                          screen: 'OrganizationPage',
                          initial: false,
                          initialRouteName: 'HomeTab',
                          params: {
                            distance: parseFloat(data.queryParams.distance),
                            latitude: parseFloat(data.queryParams.latitude),
                            longitude: parseFloat(data.queryParams.longitude),
                            organizationId: parseInt(organizationId),
                          },
                        }
                      },
                    });
                    setData(null);
                  }
                }
                // setLoaded(true);
              } else {
                setUser(false);
              }
            } else {
              setLoaded(true);
              setUser(user);
              setUserOnline({
                variables: {
                  uid: user.uid
                }
              }).then(() => {
                console.log("USER ONLINE");
              }).catch((error) => {
                console.log("ERROR SETTING USER ONLINE:", error);
                user.refreshToken(true);
              });
              //update user language
              updateUserLanguage({
                variables: {
                  uid: user.uid,
                  languageCode: i18n.locale
                }
              }).then(() => {
                console.log("USER LANGUAGE UPDATED");
              }).catch((error) => {
                console.log("ERROR UPDATING USER LANGUAGE:", error);
              });
              // setLoaded(true);
              if (Platform.OS != 'web') {
                registerForPushNotificationsAsync(user.uid, user.getIdToken(true)).then(token => setExpoPushToken(token));
                notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                  console.log("NOTIFICATION:", notification);
                  setNotification(notification);
                  console.log("NOTIFICATION URL:", notification.request.content.data.url);
                });

                responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                  console.log("RESPONSE:", response);
                  if (response.notification.request.content.data.action === "send_message") {
                    navigationRef.current?.navigate('Messages', {
                      chatId: response.notification.request.content.data.chatId,
                      expoToken: response.notification.request.content.data.expoToken,
                    });
                  }
                });
                const locationPermission = await Location.getForegroundPermissionsAsync();
                const taskIsDefined = await TaskManager.isTaskRegisteredAsync(LOCATION_TASK_NAME);
                console.log("IS TASK DEFINED: ", taskIsDefined);
                if (Platform.OS === 'android' && locationPermission.status === 'granted' && !taskIsDefined) {
                  Location.startLocationUpdatesAsync(LOCATION_TASK_NAME, {
                    accuracy: Location.Accuracy.Balanced,
                    timeInterval: 1000,
                    distanceInterval: 1,
                    foregroundService: {
                      notificationTitle: i18n.t('home.location_service_title'),
                      notificationBody: i18n.t('home.location_service_body'),
                      notificationColor: '#FF231F7C',
                    },
                  });
                }
              }
              // linking open data url
              if (data) {
                if (data.path == "drawer/home/navigation/product") {
                  navigationRef.current?.navigate('Drawer', {
                    screen: 'Home',
                    params: {
                      screen: 'HomeTab',
                      params: {
                        screen: 'ProductDetailed',
                        initial: false,
                        initialRouteName: 'HomeTab',
                        params: {
                          distanceCart: data.queryParams.distanceCart,
                          latitude: data.queryParams.latitude,
                          longitude: data.queryParams.longitude,
                          productId: data.queryParams.productId,
                          organization_id: data.queryParams.organization_id,
                          sellerId: data.queryParams.sellerId,
                        },
                      }
                    },
                  });
                  setData(null);
                } else if (data.path.includes("drawer/home/navigation/organization")) {
                  //get the organization id from the url after path: 'organization/:organizationId/',
                  const organizationId = data.path.split('/')[4];
                  console.log("ORGANIZATION ID: ", organizationId);
                  navigationRef.current?.navigate('Drawer', {
                    screen: 'Home',
                    params: {
                      screen: 'HomeTab',
                      params: {
                        screen: 'OrganizationPage',
                        initial: false,
                        initialRouteName: 'HomeTab',
                        params: {
                          distance: parseFloat(data.queryParams.distance),
                          latitude: parseFloat(data.queryParams.latitude),
                          longitude: parseFloat(data.queryParams.longitude),
                          organizationId: parseInt(organizationId),
                        },
                      }
                    },
                  });
                  setData(null);
                }
                // setData(null);
              }
            }

          }
        } else {
          setUser(false);
          setLoaded(true);
          console.log("NO USER LOGGED IN");

          if (data) {
            console.log("DATA: ", data);
            if (data.path == "drawer/home/navigation/product") {
              navigationRef.current?.navigate('Drawer', {
                screen: 'Home',
                params: {
                  screen: 'HomeTab',
                  params: {
                    screen: 'ProductDetailed',
                    initial: false,
                    initialRouteName: 'HomeTab',
                    params: {
                      distanceCart: data.queryParams.distanceCart,
                      latitude: data.queryParams.latitude,
                      longitude: data.queryParams.longitude,
                      productId: data.queryParams.productId,
                      organization_id: data.queryParams.organization_id,
                      sellerId: data.queryParams.sellerId,
                    },
                  }
                },
              });
              setData(null);
            } else if (data.path.includes("drawer/home/navigation/organization")) {
              //get the organization id from the url after path: 'organization/:organizationId/',
              const organizationId = data.path.split('/')[4];
              console.log("ORGANIZATION ID: ", organizationId);
              navigationRef.current?.navigate('Drawer', {
                screen: 'Home',
                params: {
                  screen: 'HomeTab',
                  params: {
                    screen: 'OrganizationPage',
                    initial: false,
                    initialRouteName: 'HomeTab',
                    params: {
                      distance: parseFloat(data.queryParams.distance),
                      latitude: parseFloat(data.queryParams.latitude),
                      longitude: parseFloat(data.queryParams.longitude),
                      organizationId: parseInt(organizationId),
                    },
                  }
                },
              });
              setData(null);
            }
          }


          (async () => {
            setTimeout(async () => {
              const { status } = await requestTrackingPermissionsAsync();
              if (status === 'granted') {
                console.log('Yay! I have user permission to track data');
              } else {
                console.log('No permission to track data');
              }
            }, 1000);
          })();
        }
      })
    })();

    if (Platform.OS != 'web') {
      if (
        lastNotificationResponse &&
        lastNotificationResponse.notification.request.content.data.url &&
        lastNotificationResponse.actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER
      ) {
        // Linking.openURL(lastNotificationResponse.notification.request.content.data.url);
        // if (lastNotificationResponse.notification.request.content.data.url === 'messages') {
        //   navigationRef.current.navigate('Messages', {
        //     chatId: lastNotificationResponse.notification.request.content.data.chatId,
        //     expoToken: lastNotificationResponse.notification.request.content.data.expoToken,
        //   })
        // }
      }
    }
    async function getInitialURLNotification() {
      const url = await Linking.getInitialURL();
      console.log("URL:", url);
      if (url) {
        console.log("URLLLLL ", url);
        // setData(url);
        return url;
      }
    }

    async function onFetchUpdateAsync() {
      try {
        const update = Updates.checkForUpdateAsync();

        if (update.isAvailable) {
          await Updates.fetchUpdateAsync();
          // ... notify user of update ...
          await Updates.reloadAsync();
        }
      } catch (error) {
        console.log("ERROR UPDATING APP: ", error);
      }
    }
    getInitialURLNotification();
    if (Platform.OS != 'web') {
      
      onFetchUpdateAsync();
    }

    const subscription = AppState.addEventListener('change', handleAppStateChange);
    var linkingSubscription;
    if (Platform.OS != 'web') {
      linkingSubscription = Linking.addEventListener('url', handleDeepLink);
    }
    return () => {
      if (Platform.OS == 'android' || Platform.OS == 'ios') {
        if (notificationListener.current) {
          Notifications.removeNotificationSubscription(notificationListener.current);
        }
        if (responseListener.current) {
          Notifications.removeNotificationSubscription(responseListener.current);
        }

        if (Platform.OS != 'web') {
          subscription.remove();
          linkingSubscription.remove();
        }
      }
    };
  }, [loaded, user]);

  function LogoTitle() {
    return (
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image
          style={{ width: 50, height: 50, borderRadius: 15 }}
          source={require('./assets/icon.png')}
        />
        <Text style={{ color: '#000', fontSize: 20, fontFamily: 'Inter-Black', marginLeft: 10 }}>Menucitos</Text>
      </View>
    );
  }

  if (!loaded) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ActivityIndicator size="large" color="#FF6D00" />
      </View>
    )
  }
  return (
    <NavigationContainer linking={linking} ref={navigationRef} >
      <StatusBar style="auto" />
      <ApolloProvider client={user ? apolloClient : ApolloClientUnregistered}>
        <AuthenticationStack.Navigator
          initialRouteName={Platform.OS === 'web' ? 'Drawer' : 'Drawer'}
          screenOptions={{ headerShown: Platform.OS === 'web' ? true : true }}
        >
          {!user && (
            <>
              <AuthenticationStack.Screen name="Login" component={Login} options={{
                title: i18n.t('authNavigator.login'), headerTitleStyle: { fontFamily: 'Inter-Bold', color: '#fff' }
                , headerStyle: { backgroundColor: '#FF6D00' }
              }} />
              <AuthenticationStack.Screen name="Signup" component={Signup} options={{
                title: i18n.t('signup.title'), headerTitleStyle: { fontFamily: 'Inter-Bold', color: '#fff' },
                headerStyle: { backgroundColor: '#FF6D00' }
              }} />
            </>
          )}
          <AuthenticationStack.Screen name='Drawer' component={Drawer} options={{ headerShown: false }} />
          <AuthenticationStack.Screen name="Recover" component={Recover} options={{ title: i18n.t('authNavigator.title') }} />
          <AuthenticationStack.Screen name="Policies" component={Policies} options={{ title: i18n.t('authNavigator.title') }} />
          <AuthenticationStack.Screen name="Privacy" component={Privacy} options={{ title: i18n.t('authNavigator.title') }} />
          <AuthenticationStack.Screen name="Landing" component={Landing} options={{ headerShown: false }} />
        </AuthenticationStack.Navigator>
      </ApolloProvider>
      {/* <StatusBar style="auto" />
      {user && (
        <ApolloProvider client={apolloClient}>
          <RooStack.Navigator screenOptions={{ headerShown: false }}>
            <RooStack.Screen name='Drawer' component={Drawer} />
            <RooStack.Screen name="Recover" component={Recover} options={{ title: i18n.t('authNavigator.title') }} />
            <RooStack.Screen name="Policies" component={Policies} options={{ title: i18n.t('authNavigator.title') }} />
            <RooStack.Screen name="Privacy" component={Privacy} options={{ title: i18n.t('authNavigator.title') }} />
          </RooStack.Navigator>
        </ApolloProvider>
      )}
      {!user && (
        <ApolloProvider client={ApolloClientUnregistered}>
          <AuthenticationStack.Navigator
            initialRouteName={Platform.OS === 'web' ? 'Landing' : 'Login'}
            screenOptions={{ headerShown: Platform.OS === 'web' ? true : true }}
          >
            <AuthenticationStack.Screen name="Landing" component={Landing} options={{
              headerShown: true, headerTitle: (props) => <LogoTitle {...props} />, title: i18n.t('authNavigator.website'),
              headerRight: () => (
                <View style={{ paddingLeft: 5, marginHorizontal: 10, justifyContent: 'center', alignContent: 'center' }}>
                  <TouchableOpacity style={{ flexDirection: 'row' }} onPress={() => navigationRef.current.navigate('Login')}>
                    <Feather name="log-in" size={24} color="#000" />
                    <Text style={{ color: '#000', fontSize: 16, fontFamily: 'Inter-Black' }}>{i18n.t('authNavigator.login')}</Text>
                  </TouchableOpacity>

                </View>
              ),
            }} />
            <AuthenticationStack.Screen name='Drawer' component={Drawer}  options={{headerShown:false}}/>
            <AuthenticationStack.Screen name="Login" component={Login} options={{
              title: i18n.t('authNavigator.login'), headerTitleStyle: { fontFamily: 'Inter-Bold', color: '#fff' }
              , headerStyle: { backgroundColor: '#FF6D00' }
            }} />
            <AuthenticationStack.Screen name="Signup" component={Signup} options={{
              title: i18n.t('signup.title'), headerTitleStyle: { fontFamily: 'Inter-Bold', color: '#fff' },
              headerStyle: { backgroundColor: '#FF6D00' }
            }} />
            <AuthenticationStack.Screen name="Recover" component={Recover} options={{ title: i18n.t('authNavigator.title') }} />
            <AuthenticationStack.Screen name="Policies" component={Policies} options={{ title: i18n.t('authNavigator.title') }} />
            <AuthenticationStack.Screen name="Privacy" component={Privacy} options={{ title: i18n.t('authNavigator.title') }} />
          </AuthenticationStack.Navigator>
        </ApolloProvider>
      )} */}
    </NavigationContainer>
  );
}


