import React, { useState, useEffect } from 'react';
import { View, Text,Dimensions, TextInput, TouchableOpacity, Button,KeyboardAvoidingView, Platform, NativeEventEmitter,FlatList, ScrollView, Alert, ActivityIndicator } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {useMutation} from '@apollo/client'
import { INSERT_PRODUCT_DESCRIPTION_ONE } from '../../../lib/queries';
import i18n from '../../../i18n/i18n';
import * as Localization from 'expo-localization';

const EditProductListDescription = ({navigation, route}) => {

    /**route.params */
 
    //   const {productDesc} = route.params
    //   const {productDescriptionItem} = productDesc

    const {productId} = route.params

    //   const {productDescId} = route.params
     
      /**state */

      const [productDescription, setProductDescription] = useState('')

      /**mutations */

      const [insertProductDescription, {loading: mutationLoading, error: mutationError}] = useMutation(INSERT_PRODUCT_DESCRIPTION_ONE)    
    useEffect(()=>{
    //   console.log(productDescId)
    },)

    return(
      <SafeAreaView style={{flex:1, paddingTop:5}}>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:45, paddingHorizontal:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontWeight:'bold', fontSize:22}}>
                {i18n.t('products.editProductDescriptions.title')}
            </Text>
            </View>
            <View style={{paddingTop:5}}>
            <Text style={{fontSize:18}}>
                {i18n.t('products.editProductDescriptions.edit_product_description')}
            </Text>
            <TextInput style={{ backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:10,
             fontSize:18}} 
              defaultValue={productDescription}
              onChangeText={(text)=>setProductDescription(text)}
              placeholder={i18n.t('products.editProductDescriptions.description_placeholder')}
             />
            </View>
        </View>
        <View style={{flex:2}}>

        </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#000000', paddingVertical:15, borderRadius:6}}
                       onPress={()=> {
                const lang = i18n.locale;
                const language = lang.substring(0, 2);
                const languageCode = language.toUpperCase();
                
                if(productDescription){
                insertProductDescription({
                    variables:{
                        productId: productId,
                        productDescription: productDescription,
                        languageCode: languageCode
                    }
                }).then(()=>{
                    Alert.alert("Description added")
                }).catch(err=>{
                    Alert.alert(err.message)
                    console.log(err)
                })
            }else{
              Alert.alert("Error", "Type a description for your product filling the textbox and hitting enter")
            }}}
    >
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>
                    {i18n.t('products.editProductDescriptions.btn_save')}
                </Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
              
    </SafeAreaView>
    )
    
}

export default EditProductListDescription;