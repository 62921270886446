import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity,KeyboardAvoidingView, Alert, ActivityIndicator, FlatList, Platform, Modal } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMutation, useQuery } from '@apollo/client';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'

import {GET_USER, GET_USER_PAYMENT_OPTIONS, INSERT_NEW_PRODUCT , INSERT_PRODUCT_GALLERY_ONE, IS_USER_IN_ORGANIZATION, UPDATE_THUMBNAIL_BY_PRODUCT_ID, UPDATE_PRODUCT_IMAGE_PORTRAIT, UPDATE_PRODUCT_GALLERY_THUMBNAIL_BY_PRODUCT_GALLERY_ID} from '../../lib/queries'
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import {getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable} from 'firebase/storage';
import i18n from '../../i18n/i18n';

const auth = getAuth(Firebase);
const storage = getStorage(Firebase);
import base64 from 'react-native-base64';
import { async } from '@firebase/util';

const AddPaymentOptions = ({navigation, route}) => {
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const langQuery = language.toUpperCase();

  /**route.params */

  const {subCategoryId} = route.params
  /**productSKUPrice */
  const {productSKUDelivery} = route.params
  const {productSKU} = productSKUDelivery
  
  /**productTitle */ 
  const {productTitleDelivery} = route.params
  const {productTitle} = productTitleDelivery

  /**productSlug */
  const {productSlugDelivery} = route.params
  const {productSlug} = productSlugDelivery

  /**productImage */
  const {productImageDelivery} = route.params
  const {image} = productImageDelivery

  /**productPrice */

  const {productPriceDelivery} = route.params
  const {productPrice} = productPriceDelivery

  /**productQuantity */
  const {productQuantityDelivery} = route.params
  const {productQuantity} = productQuantityDelivery

  /**ProductCurrency */

  const {productCurrencyDelivery} = route.params
  const {productCurrency} = productCurrencyDelivery

  /**ProductDescriptions */
  const {productDescriptionsDelivery} = route.params
  const {descriptionList} = productDescriptionsDelivery

  /**DeliveryOptionsList */

  const {DeliveryOptionsList} = route.params
  const {arrayDeliveryOptionsId} = DeliveryOptionsList

  /**productGalleryDelivery */

  const {productGalleryDelivery} = route.params
  const {productGallery} = productGalleryDelivery


  /**Firebase UID */
  const uid = getAuth().currentUser.uid;
  /**Queries */

  const {loading: loadingPaymentOption, error: errorPaymentOption, data: dataPaymentOption, refetch:refetchPaymentMethods} = useQuery(GET_USER_PAYMENT_OPTIONS, {variables:{uid, languageCode: langQuery}} )
  const {loading: isUserOnOrganization, error: errorIsUserOnOrganization, data: dataIsUserOnOrganization} = useQuery(IS_USER_IN_ORGANIZATION, {variables:{uid}});
  /**Mutations */
  const [insertNewProduct, {loading: mutationLoading, data: mutationData}] = useMutation(INSERT_NEW_PRODUCT);
  const [updateProductThumbnail, {loading: mutationLoadingUpdateProductThumbnail, data: mutationDataUpdateProductThumbnail}] = useMutation(UPDATE_THUMBNAIL_BY_PRODUCT_ID);
  const [insertProductGalleryOne, {loading: mutationLoadingInsertProductGalleryOne, data: mutationDataInsertProductGalleryOne}] = useMutation(INSERT_PRODUCT_GALLERY_ONE);
  const [updateProductImagePortrait, {loading: mutationLoadingUpdateProductImagePortrait, data: mutationDataUpdateProductImagePortrait}] = useMutation(UPDATE_PRODUCT_IMAGE_PORTRAIT);
  const [updateProductGalleryThumbnail, {loading: mutationLoadingUpdateProductGalleryThumbnail, data: mutationDataUpdateProductGalleryThumbnail}] = useMutation(UPDATE_PRODUCT_GALLERY_THUMBNAIL_BY_PRODUCT_GALLERY_ID);
  /**state */
  const [paymentOptions, setPaymentOptions] = useState([]);

  const [arrayPaymentOptions, setArrayPaymentOptions] = useState([]);
  const [arrayPaymentOptionsId, setArrayPaymentOptionsId] = useState([]);
  const [isInOrganizations, setIsInOrganizations] = useState();
  const [tokenPaypal, setTokenPaypal] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [imageUuid, setImageUuid] = useState('');
  const [processLoading, setProcessLoading] = useState(false);
  const [processPercentage, setProcessPercentage] = useState(0);
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
  /**Query user email */
  const{loading:userData, data:userDataData, error:userDataError} = useQuery(GET_USER, {variables: {uid}});

  function slugify(text) {
    const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
    const to = "aaaaaeeeeeiiiiooooouuuunc------"
  
    const newText = text.split('').map(
      (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))
  
    return newText
      .toString()                     // Cast to string
      .toLowerCase()                  // Convert the string to lowercase letters
      .trim()                         // Remove whitespace from both sides of a string
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/&/g, '-y-')           // Replace & with 'and'
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-');        // Replace multiple - with single -
  }

  useEffect(() => {
    // console.log('productGallery', productGallery);
    if(userDataData){
        setUserEmail(userDataData.users_by_pk.user_email);
        // setImageUuid(uuidv4());
    }

    if(dataIsUserOnOrganization){
      console.log(dataIsUserOnOrganization);
      const {organization_users} = dataIsUserOnOrganization;
      if(organization_users.length > 0){
        setIsInOrganizations(true);
        console.log(isInOrganizations);
      }else{
        setIsInOrganizations(false);
      }
    }

    async function getPayPalToken(){
     const response = await fetch('https://api-m.paypal.com/v1/oauth2/token ', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Accept-Language': 'en_US', 
      'Authorization': 'Basic '+ base64.encode('AZuIVyOpv53U1fXhArisIyI25BDbsCo2aIFLmN90y-fgKMyQ-5NY1eAUupZcT5qza1cgVHnlVnneCCG3:EKIS8dDy-LbzLI1Q3eQ63c5rkrU8lGDNoqMcDPK1m0r1OR04X1fmrSs9_uZIKu411NKDp6EV5hXLczGA'),
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: 'grant_type=client_credentials',
  })

    const content = await response.json();
    // console.log(content.access_token);

    return content.access_token;
    }

    const token = getPayPalToken().then(res => {
        // console.log(res);
        setTokenPaypal(res);
    });

    console.log("token de accesso:", tokenPaypal);
    async function getMerchantId(){

        const response = await fetch('https://api-m.paypal.com/v1/customer/partners/YLH8NQMDP8PPQ/merchant-integrations?tracking_id='+ uid,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Accept-Language': 'en_US',
                'Authorization': 'Bearer '+ tokenPaypal,
        },
        });

        const content = await response.json();

        // console.log(content.merchant_id);
        return content.merchant_id;
    }

    getMerchantId().then(res => {
        console.log("merchantId:", res);
        setMerchantId(res);
    });



    let newUserCountryPaymentOption;
    // console.log(dataPaymentOption)

    console.log("SUB CATEGORY ID", subCategoryId);
    if(dataPaymentOption){
      const {user_country_payment_option} = dataPaymentOption
      newUserCountryPaymentOption = user_country_payment_option
      // console.log("Hey " , newUserCountryPaymentOption)
    } 
    setPaymentOptions(newUserCountryPaymentOption);
    // console.log(arrayDeliveryOptionsId);

    const unsubscribe = navigation.addListener('focus', () => {
      refetchPaymentMethods();
    });
  })

  const pressColor = (index)=>{
    let newArrayPaymentOptions = [...arrayPaymentOptions];
    newArrayPaymentOptions[index] = !newArrayPaymentOptions[index];
    setArrayPaymentOptions(newArrayPaymentOptions);

    // console.log("Array Delivery Options: ", newArrayPaymentOptions);

    if(newArrayPaymentOptions[index]){
      let newArrayPaymentOptionsId = [...arrayPaymentOptionsId, {user_country_payment_options_id: paymentOptions[index].id_user_country_payment_option}];
      setArrayPaymentOptionsId(newArrayPaymentOptionsId);
      console.log("Array payment options: ", newArrayPaymentOptionsId)

    }else{
      let newArrayPaymentOptionsId = [...arrayPaymentOptionsId];
      newArrayPaymentOptionsId.splice(index, 1);
      setArrayPaymentOptionsId(newArrayPaymentOptionsId);
      console.log("Array payment options: ", newArrayPaymentOptionsId);
    }

  }

  const Item = ({item, index, payment_method_name, paypal_enabled, paypal_label, payment_option_id, country_name})=>{
   const BackgroundColor = arrayPaymentOptions[index] ? {backgroundColor:'#64b5f6', marginTop:3, paddingVertical:10, borderRadius:6} : {backgroundColor:'#e0e0e0', marginTop:3, paddingVertical:10, borderRadius:6};
    return(
      <View>
             <TouchableOpacity style={BackgroundColor}
      disabled={paypal_enabled}
      onPress={()=>{
        pressColor(index);
        console.log("paypalenabled", paypal_enabled);
        console.log("OPTION ID", payment_option_id);
      }}>
        <Text style={{fontSize:16, fontWeight:'bold', textAlign:'center'}}>{payment_method_name} {country_name}</Text>
      </TouchableOpacity>
      {(paypal_label == '')?(
        <View></View>
      ):(
        <Text style={{fontWeight:'bold', color:'#FF6D00'}}>{paypal_label}</Text>
      )}
      </View>
 
      
    )
    
  }
  /**Uploadimage function */
  function delay(t, v) {
    return new Promise(function(resolve) { 
      setTimeout(resolve.bind(null, v), t)
    });
  }
  
  function keepTrying(triesRemaining, storageRef) {
    if (triesRemaining < 0) {
      return Promise.reject('out of tries');
    }

    return getDownloadURL(storageRef).then(url => {
      return url;
    }).catch(err => {
      if (err.code === 'storage/object-not-found') {
        return delay(1000).then(() => {
          return keepTrying(triesRemaining - 1, storageRef);
        });
      } else {
        return Promise.reject(err);
      }
    })
  }
      
  async function uploadImage(uri, imageName) {
      console.log("IMAGE UUID, ", imageName)
      let newDescriptionList = descriptionList.filter(item => item.product_description !== '');
      const userId = getAuth().currentUser.uid;
      console.log("New Description List: ", newDescriptionList);
      insertNewProduct({variables:{
        uid,
        productTitle,
        productSKU,
        productSlug,
        productQuantity,
        productCurrency,
        productPrice,
        newDescriptionList,
        arrayDeliveryOptionsId,
        arrayPaymentOptionsId,
        subCategoryId,
        productImage: imageName,
        languageCode: langQuery
      }}).then(async res => {
          const productId = res.data.insert_products_one.product_id;
          console.log("PRODUCT ID", productId);
          const fileref = ref(storage, 'products/'+imageName);
          const img = await fetch(uri);
          const blob = await img.blob();
          console.log('uploading image...'+ uri);

          const uploadTask = uploadBytesResumable(fileref, blob);

          uploadTask.on('state_changed', (snapshot) => {
              // console.log('snapshot progess ' + snapshot.);
              //snapshot progress in percentage
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setProcessPercentage(progress);
              console.log('Upload is ' + progress + '% done');
          }, (err) => {
              console.log(err);
          } , async () => {
              console.log('upload image success');
              const url = await getDownloadURL(fileref);


              console.log("URL", url);

              updateProductImagePortrait({variables:{
                productId: productId,
                productPhotoPortrait: url
              }}).then(res => {

              const pathRef = ref(storage, 'products/resized/'+imageName+'_500x500');
                  keepTrying(35, pathRef).then(
                  url =>{
                     console.log("URL", url);
                     
                  /**upload thumbnail */
                  updateProductThumbnail({variables:{
                    productId,
                    thumbnail: url
                  }}).then((res)=>{
                    console.log("Thumbnail updated");
                    setProcessLoading(false);
                      Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [
                        
                        {text: 'OK', onPress: () => console.log('OK Pressed')},
                      ], {cancelable: false});

                      if(Platform.OS === 'web'){
                        setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
                        setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
                        setModalVisibilityAlert(true);
                      }
            
                    }).catch((err)=>{
                        console.log("Error", err);
                      })
                  }
                )
              }).catch(err =>{
                console.log(err);
              })  


      })

      /**upload productGallery */

      productGallery.map(async (item, index)=>{

        console.log("ITEM IMAGEEEEEEEEEEEEEE", item.uri);
        const fileref = ref(storage, 'products/'+imageName+'_'+index);

        const img = await fetch(item.uri);
        const blob = await img.blob();

        const uploadTask = uploadBytesResumable(fileref, blob);

        uploadTask.on('state_changed', (snapshot) => {
          console.log('snapshot progess ' + snapshot);
        }, (err) => {
          console.log(err);
        } , async () => {
          console.log('upload image success');
          const url = await getDownloadURL(fileref);
          console.log("URL", url);
          insertProductGalleryOne({variables:{
            productId,
            productGalleryPhoto: url
          }}).then(res => {
            const productGalleryId = res.data.insert_product_gallery_one.product_gallery_id;

            const pathRef = ref(storage, 'products/resized/'+imageName+'_'+index+'_500x500');

            keepTrying(35, pathRef).then(
              url =>{

                updateProductGalleryThumbnail({variables:{
                  productGalleryId,
                  productThumbnail: url
                }}).then(res => {
                  console.log("Thumbnail updated");
                }).catch(err =>{
                  console.log(err);
                })
              }
            )
          }).catch(err => {
            console.log("Error", err);
          })
        })

        // const imgg = fetch(item.uri);
        // const blobb = imgg.blob();
        // console.log('uploading image...'+ item.uri);

        // const uploadTaskk = uploadBytesResumable(fileref, blobb);

        // uploadTaskk.on('state_changed', (snapshot) => {
        //     console.log('snapshot progess ' + snapshot);
        // }
        // , (err) => { 
        //     console.log(err);
        // }
        // , async () => {
        //     console.log('upload image success');
        //     const url = await getDownloadURL(fileref);
        //     console.log("URL", url);
        //     insertProductGalleryOne({variables:{
        //       productId,
        //       productGalleryPhoto: url
        //     }}).then(res => {
        //       console.log("Gallery updated");
        //     }).catch(err => {
        //       console.log(err);
        //     })
        // })
      })


    }).catch(err => {
      console.log("ERROR QUERY", err);
    })

          // const fileref = ref(storage, 'products/'+imageName);
      // const img = await fetch(uri);
      // const blob = await img.blob();

      // console.log('uploading image...'+ uri);

      // const uploadTask = uploadBytesResumable(fileref, blob);

      // uploadTask.on('state_changed', (snapshot) => {
          
      // }, (error) => {
      //   console.log(error);
      // } , () => {
          
      //   getDownloadURL(fileref).then(
      //     (response) => {
      //         console.log("Response: ", response );
      //         let newDescriptionList = descriptionList.filter(item => item.product_description !== '');

      //         console.log("New Description List: ", newDescriptionList);

      //         insertNewProduct({variables:{
      //           uid,
      //           productTitle,
      //           productSKU,
      //           productImage: response,
      //           productSlug,
      //           productQuantity,
      //           productCurrency,
      //           productPrice,
      //           newDescriptionList,
      //           arrayDeliveryOptionsId,
      //           arrayPaymentOptionsId,
      //           subCategoryId,
      //           languageCode: langQuery
      //         }}).then(async (res)=>{
      //           // console.log('Product inserted');
      //           const productId = res.data.insert_products_one.product_id;
                
      //           const pathRef = ref(storage, 'products/resized/'+imageName+'_500x500');

              
      //           keepTrying(35, pathRef).then(
      //             url =>{
      //                console.log("URL", url);
                     
      //             /**upload thumbnail */
      //             updateProductThumbnail({variables:{
      //               productId,
      //               thumbnail: url
      //             }}).then((res)=>{
      //               console.log("Thumbnail updated");



      //               setProcessLoading(false);
      //                 Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [
                        
      //                   {text: 'OK', onPress: () => console.log('OK Pressed')},
      //                 ], {cancelable: false});

      //                 if(Platform.OS === 'web'){
      //                   setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
      //                   setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
      //                   setModalVisibilityAlert(true);
      //                 }
            
      //               }).catch((err)=>{
      //                   console.log("Error", err);
      //                 })
      //             }
      //           )
      //         }).catch(err =>{
      //           console.log(err);
      //         })
              
      //     });
      // });


  }

  async function postNewProduct(imageName){
    setProcessLoading(true);
    const productImage = await uploadImage(image, imageName);
    console.log("Product Image: ", imageName);

  }


  const renderItem = ({item, index})=>{

    let paymentOptionId = item['country_payment_option']['payment_option'].payment_options_id
    let paypalenabled = true;
    let paypalLabel = '';

    if(paymentOptionId === 2 && merchantId == undefined){
      paypalenabled = true;
      paypalLabel = i18n.t('products.addPaymentOptions.configure_paypal');
    }else{
      paypalenabled = false;
      paypalLabel = '';
    }
    // else if(isInOrganizations){
    //   paypalenabled = false;
    //   paypalLabel = i18n.t('products.addPaymentOptions.paypal_organization');
    // }

    if(isInOrganizations && paymentOptionId === 2){
      paypalenabled = false;
      paypalLabel = i18n.t('products.addPaymentOptions.paypal_organization');

    }

    return(<Item item={item} 
      index={index}
      payment_method_name={item['country_payment_option']['payment_option']['payment_option_translations'][0]['payment_option_name']}
      paypal_enabled={paypalenabled}
      paypal_label={paypalLabel}
      payment_option_id={paymentOptionId}
      country_name={item['country_payment_option']['country']['country_name']}
      />)
  }

    return(
        <SafeAreaView style={{flex:1, paddingHorizontal:5, paddingVertical:-45}}>
                                  <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
                              <TouchableOpacity style={{marginTop:20}} onPress={() => {
                                navigation.navigate('Home')
                                setModalVisibilityAlert(false)
                              }}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                              </TouchableOpacity>
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
        <View style={{flex:1}}>
          <View style={{flex:1}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontWeight:"bold", fontSize:32}}>
                {i18n.t('products.addPaymentOptions.title')}
            </Text>
            </View>
            <View style={{paddingVertical:5, flexDirection:'row'}}>
            <Text style={{fontSize:16}}>
                {i18n.t('products.addPaymentOptions.body')}
            </Text>
        </View>
        <View style={{flexDirection:'row'}}>
          <Text style={{fontSize:16}}>{i18n.t('products.addPaymentOptions.menupay_instruction')}
          </Text>
        </View>
          </View>
        </View>
            

        <View style={{flex:2}}>
            <FlatList data={paymentOptions} renderItem={renderItem} keyExtractor={item => item.id_user_country_payment_option.toString()}/>
        </View>
        <View style={{flex:0.4}}>
      <TouchableOpacity
            disabled={processLoading ? true : false}
            style={{backgroundColor:'#000000', borderRadius:6, paddingVertical:15, marginTop:10}}
            onPress={async ()=> {if(arrayPaymentOptionsId[0]){
              // setImageUuid(uuidv4())
              const imageuniqueID = uuidv4();
              try {
               await postNewProduct(imageuniqueID);
              } catch (error) {
                console.log("Error", error);
              }
              
            }else{
              Alert.alert("Error", i18n.t('products.addPaymentOptions.alert_body'));
            }}}
          >
            {(processLoading)?(
              <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:16, marginRight:10}}>
                  {(processPercentage) == 100 ? (
                    i18n.t('products.addPaymentOptions.almost_done')
                  ):(
                    processPercentage.toFixed(0) + '%'
                  )}
                </Text>
                <ActivityIndicator size="small" color="#FFFFFF" />
                </View>
                
            ):(
              <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:16}}>
              {i18n.t('products.addPaymentOptions.btn_save')}
            </Text>
            )}
          </TouchableOpacity>
        </View>
        
    </SafeAreaView>
    )
}

export default AddPaymentOptions;