import React, { useEffect, useState } from 'react';
import {
  Modal,
    SafeAreaView, Text, View, TextInput, TouchableOpacity, Alert, KeyboardAvoidingView, Platform, Image, Dimensions
  } from 'react-native';
import { useQuery } from '@apollo/client';
import { GET_COUNTRIES } from '../lib/queries';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from '../i18n/i18n';
import Firebase from '../lib/firebase';
import {getFunctions, httpsCallable} from 'firebase/functions'
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import WelcomeLogo from '../assets/WelcomeScreen.png';
import LogoBienvenida from '../assets/PantallaBievenida.png';

const functions = getFunctions(Firebase);

const auth = getAuth(Firebase);

/**import firebase/functions*/


const Recover = ({ navigation }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(0);
    const [languageCode, setLanguageCode] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
  
    // const {loading:loadingCountries, error:errorCountries, data:dataCountries} = useQuery(GET_COUNTRIES);
  
    useEffect(()=>{
    //   console.log("ERROR", errorCountries)
    //   if(dataCountries){
    //     setCountries(dataCountries.countries);
    //     console.log("Countries: ", dataCountries.countries);
    //   }
    })

    const handleRecover = async () => {
        console.log("Email: ", email);
        try {
          {/* You should ideally do some sort of validation before this
          Think password length and so on */}
          
          /**Call firebase function */

        //   const registerUser = httpsCallable(functions, 'registerUser');
        //   await registerUser({email, password});
           
        //   // console.log("Result: ", result);
    
        //  //Log the user in
        //  await signInWithEmailAndPassword(auth, email, password);

        await sendPasswordResetEmail(auth, email).then(() => {
            // Alert.alert('Success', 'Password reset email sent');
            Alert.alert(i18n.t('recover.success_message'))
            if(Platform.OS === 'web'){
                setModalVisibilityAlert(true);
                setMessageTitle(i18n.t('recover.success_message'));
            }
        }).catch(error => {});
    
        } catch (error) {
          // console.log(error);
          /**Manage errors firebase signinWithEmail */
         Alert.alert('Error', error.message);
        if(error.message === 'The email address is badly formatted.'){
          Alert.alert(i18n.t('signup.email_invalid'), i18n.t('signup.email_invalid_body'));
          if(Platform.OS === 'web'){
            setMessageTitle(i18n.t('recover.email_invalid'));
            setMessageBody(i18n.t('recover.email_invalid_body'));
            setModalVisibilityAlert(true);
          }
        }else if(error.message === 'The email address is already in use by another account.'){
          Alert.alert(i18n.t('recover.error_email_in_use'), i18n.t('signup.error_email_in_use_body'));
        }else if(error.message === 'The password must be 6 characters long or more.'){
          Alert.alert('Password too short', 'Please enter a password of at least 6 characters');
        }
        }   
      };

      const handlePickerChange = async (itemValue, itemIndex) => {
        // console.log("Picker: ", itemValue, itemIndex);
        setSelectedCountry(itemValue);
        try{
          if(itemValue === 3 || itemValue === 4 || itemValue === 5 || itemValue === 6 || itemValue === 7){
            const value = "es";

            i18n.locale = value;
            // await AsyncStorage.setItem('languageCode', value);
            // await AsyncStorage.setItem('languageCodeQuery', value.toUpperCase());
            // console.log("AsyncStorage: ", await AsyncStorage.getItem('languageCode'));
    
          }else if(itemValue === 1 || itemValue === 8){
            const value = "en";
            setLanguageCode(value);
            i18n.locale = value;
            // await AsyncStorage.setItem('languageCode', value);
            // await AsyncStorage.setItem('languageCodeQuery', value.toUpperCase());
            // console.log("AsyncStorage: ", await AsyncStorage.getItem('languageCode'));
          }
    
        }catch(e){
          // console.log(e);
        }
      }
      const screenWidth = Dimensions.get('window').width;

      return (
        <SafeAreaView style={{flex:1}}>

<Modal visible={modalVisibilityAlert}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibilityAlert(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                <Text style={{fontSize:20}}>{messageBody}</Text>

                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>
        <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
        <View style={{paddingTop:15, paddingHorizontal:15, flex:0.4}}>
        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
        <Text style={{fontSize:32, fontWeight:'bold'}}>
            {i18n.t('recover.title')}
        </Text>
        </View>
        <View>
       
        </View>

    </View>
    <View style={{flex:2}}>
    {/* <Image source={languageCode === 'EN'? WelcomeLogo : LogoBienvenida}  
              style={{width:screenWidth, height:screenWidth*0.3}}/> */}
    <View style={{paddingTop:5}}>
            {/* <Text style={{fontSize:18, fontWeight:'bold', paddingHorizontal:15}}>{i18n.t('signup.country_label')} </Text> */}
            {/* <Picker
            selectedValue={selectedCountry}
            itemStyle={{height:100}}
            onValueChange={handlePickerChange}
            >
            <Picker.Item label={i18n.t('signup.picker_country_label')} value={0} />
            {countries.map((country, index)=>{
    
              return(
                <Picker.Item label={country.country_name} value={country.country_id} key={index}/>
              )
            })}
            </Picker> */}
        </View>
        <Text style={{paddingHorizontal:15, fontSize:16, fontWeight:'bold'}}>{i18n.t('signup.email_label')}</Text>
        <TextInput style={{paddingHorizontal:12, backgroundColor:'#c9c1c1', paddingVertical:5,
         marginHorizontal:15, fontSize:18, borderRadius:6}}
        onChangeText={(val) => setEmail(val.trim())}
        placeholder='carlos@gmail.com'
        autoCapitalize='none'
         />
        {/* <Text style={{paddingHorizontal:15, fontSize:16, fontWeight:'bold'}}>{i18n.t('signup.password_label')}</Text>
        <TextInput style={{paddingHorizontal:12, backgroundColor:'#c9c1c1', paddingVertical:5,
         marginHorizontal:15, fontSize:18, borderRadius:6}} 
         placeholder={i18n.t('signup.password_placeholder')}
         onChangeText={(val) => setPassword(val)}
         secureTextEntry 
        /> */}
        <View style={{marginTop:5, flexDirection:'row', justifyContent:'center'}}>
       <Text style={{fontSize:16}}>{i18n.t('signup.already_registered')} </Text>
        <TouchableOpacity
        style={{marginLeft:1}}
        onPress={() => navigation.navigate('Login')}
      >
        <Text style={{fontSize:16, fontWeight:'bold', color:'#1e76e8'}}>
          {i18n.t('signup.btn_login')}
        </Text>
      </TouchableOpacity>
    </View>
    {/* <View style={{marginTop:5, flexDirection:'row', justifyContent:'center'}}>
       <Text style={{fontSize:16}}>{i18n.t('signup.policies_label')} </Text>
        <TouchableOpacity
        style={{marginLeft:1}}
        onPress={() => navigation.navigate('Login')}
      >
        <Text style={{fontSize:16, fontWeight:'bold', color:'#1e76e8'}}>
          {i18n.t('signup.policies_link')}
        </Text>
      </TouchableOpacity>
    </View> */}
    </View> 
    <View style={{flex:0.4, paddingHorizontal:15}}>
        <TouchableOpacity style={{backgroundColor:'#1e76e8', paddingVertical:15, borderRadius:6}}
        onPress={()=>{
                    if(email.trim() === ''){
                      Alert.alert('Error', i18n.t('recover.error_email_null'));
                      if(Platform.OS === 'web'){
                        setModalVisibilityAlert(true);
                        setMessageTitle(i18n.t('recover.error_email_null_title'));
                        setMessageBody(i18n.t('recover.error_email_null'));
                      }
                    }else{
                      handleRecover();
                    }
                  }}
          >
            <Text style={{alignSelf:'center', color:'#fff', fontWeight:'bold', fontSize:18}}>{i18n.t('recover.btn_recover')}</Text>
        </TouchableOpacity>
        </View>
    </KeyboardAvoidingView>
          
    </SafeAreaView>
      );    

};

export default Recover;