import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Modal, ActivityIndicator,TouchableWithoutFeedback, TouchableOpacity, ImageBackground, Platform, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useSubscription } from '@apollo/client';
import Firebase from '../lib/firebase';
import { GET_ORDER_BY_BUYER_ID, GET_PRODUCTS_BY_ORDER_ID , GET_ORDER_REALTIME_BY_ID, SET_ORDER_DELIVERED_BY_ORDER_ID} from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import { set } from 'react-native-reanimated';
import i18n from '../i18n/i18n';
import * as Localization from 'expo-localization';
import AsyncStorage from "@react-native-async-storage/async-storage"
import { getAuth } from 'firebase/auth';
import { createMapLink } from 'react-native-open-maps';
// import FastImage from 'react-native-fast-image';
const auth = getAuth(Firebase);

// let languageCode;


// async function getCurrencyAsyncStorage(){

//   try {
//     const value = await AsyncStorage.getItem('languageCodeQuery');
//     if (value !== null) {
//       // We have data!!
//       console.log("LanguageCOde",value);
//       languageCode = value;
//     }
//   } catch (error) {
//     // Error retrieving data
//   }
// }  

// getCurrencyAsyncStorage();

const OrderDetailed = ({navigation, route}) => {
    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();

    const {order_id} = route.params;

    const [productList, setProductList] = useState([]);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [city, setCity] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [province, setProvince] = useState('');
    const [notes, setNotes] = useState('');
    const [orderInfo, setOrderInfo] = useState('');
    const [transactionStatusName, setTransactionStatusName] = useState('');
    const [transactionStatusId, setTransactionStatusId] = useState('');
    const [orderReviewed, setOrderReviewed] = useState('');
    const [reviewedTimeStamp, setReviewedTimeStamp] = useState('');
    const [orderDelivered, setOrderDelivered] = useState('');
    const [deliveryOptionId, setDeliveryOptionId] = useState('');
    const [pickUpLocation, setPickUpLocation] = useState('');

    const {loading:loadingProductList, error:errorProductList, data:dataProductList} = useQuery(GET_PRODUCTS_BY_ORDER_ID, {variables:{
        orderId: order_id,
        languageCode: languageCode
    }});

    const {loading:loadingOrder, error:errorOrder, data:dataOrder} = useSubscription(GET_ORDER_REALTIME_BY_ID, {variables:{
        orderId: order_id,
        languageCode: languageCode
    }});

     



    const uid = getAuth().currentUser.uid;
    // const [orders, setOrders] = useState();
    // const [modalVisible, setModalVisible] = useState(false);
    // const {loading: loadingOrders, data: dataOrders, error:errorOrders, refetch} = useQuery(GET_ORDER_BY_BUYER_ID, {variables:{customerUid:uid}});

    useEffect(() => {
        // console.log(errorOrder);
        if(dataProductList){
            const {orders_by_pk} = dataProductList;
            setProductList(orders_by_pk.order_products);
            // console.log(productList);
            setAddress1(orders_by_pk.user_location.address1);
            setAddress2(orders_by_pk.user_location.address2);
            setAddress3(orders_by_pk.user_location.address3);
            setCity(orders_by_pk.user_location.city.city_name);
            setProvince(orders_by_pk.user_location.province.province_name);
            setZipCode(orders_by_pk.user_location.zip_code);
            setNotes(orders_by_pk.notes);
            // console.log("Hey:", errorOrder, loadingOrder);     
        }
        if(dataOrder){
            const {orders_by_pk} = dataOrder;
            setOrderInfo(orders_by_pk);
            setTransactionStatusName(orders_by_pk['transactions'][0] ? orders_by_pk['transactions'][0]['transaction_status']['transaction_status_translations'][0]['transaction_status_name'] : '');
            setTransactionStatusId(orders_by_pk['transactions'][0] ? orders_by_pk['transactions'][0]['transaction_status_id'] : '');
            setOrderReviewed(orders_by_pk['order_reviewed']);
            setReviewedTimeStamp(orders_by_pk['order_reviewed_time'])
            setOrderDelivered(orders_by_pk.order_delivered);
            setDeliveryOptionId(orders_by_pk['country_delivery_option'].delivery_option_id);
            setPickUpLocation(orders_by_pk['user_orders'][0] ? orders_by_pk['user_orders'][0]['user']['user_location']['coordinates'] : '');
            // console.log('dick:', pickUpLocation);
        }
    }, );

    const ProductItem = ({item, product_title, product_image, product_currency, product_quantity, created_at, date, dayName}) => (
        <View>
            <Text style={{fontSize:16, fontWeight:'bold'}}>{product_title}</Text>
            <View style={{flexDirection:'row'}}>
                <ImageBackground source={{uri: product_image}} resizeMode='contain' style={{paddingVertical:5, width:125, height:125, overflow:'hidden', borderRadius:6}}></ImageBackground>
                {/* <FastImage source={{uri: product_image}} resizeMode='contain' style={{paddingVertical:5, width:190, height:190, overflow:'hidden', borderRadius:6}}></FastImage> */}
                <View style={{flexDirection:'column', paddingLeft:5, flexShrink:1}}>
                    <View style={{flexDirection:'row', alignItems:'center', flexShrink:1}}>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.unit_price')}</Text>
                    </View>
                    <Text style={{fontSize:16}}> {item.product_price} {product_currency}</Text>
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.quantity')}</Text>
                        <Text style={{fontSize:16}}> {product_quantity}</Text>
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center', flexShrink:1}}>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.ordered_at')}</Text>
                    </View>
                    <Text style={{fontSize:16, flexShrink:1}}>{dayName} {date} {created_at} </Text>
                </View>
            </View>
        </View>
    )

    const renderProductList = ({item}) => {
        const timeOrder = new Date(item.created_at);
        const timeOrdered = timeOrder.toLocaleTimeString();
        const dateOrdered = timeOrder.toLocaleDateString();
        
        /**get Day name */
        const day = timeOrder.getDay();

        // const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const dayNames = [i18n.t('orders.order_detailed.sunday'), 
        i18n.t('orders.order_detailed.monday'), 
        i18n.t('orders.order_detailed.tuesday'),
        i18n.t('orders.order_detailed.wednesday'), 
        i18n.t('orders.order_detailed.thursday'), 
        i18n.t('orders.order_detailed.friday'), 
        i18n.t('orders.order_detailed.saturday')];


        const dayName = dayNames[day];

        return(
            <ProductItem item={item}
            product_title={item['product']['product_translations'][0].product_title}
            product_image={item['product'].product_photo_portrait}
            product_currency={item['product_currency'].currency_code}
            product_quantity={item.product_quantity}
            date={dateOrdered}
            dayName={dayName}
            created_at={timeOrdered}
            />
        )
    }

    function header(){
        return(
            <View style={{paddingTop:5}}>
                <Text style={{fontSize:32, fontWeight:'bold', textAlign:'left'}}>
                {i18n.t('orders.order_detailed.title')}
            </Text>  
            {(!orderDelivered) ? (
                <View>
                    <Text style={{fontSize:16, paddingBottom:9}}>{i18n.t('orders.order_detailed.body')}</Text>
                    <ActivityIndicator size='small' color={'#212121'}></ActivityIndicator> 
                </View>
            ):null}
            <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.products_ordered')}</Text>
            <FlatList data={productList} renderItem={renderProductList} keyExtractor={item => item.product_id.toString()}></FlatList> 
            </View>
        )
    }

    function footer(){
        const timeOrder = new Date(orderInfo.order_reviewed_time);
        const timeOrdered = timeOrder.toLocaleTimeString();
        const dateOrdered = timeOrder.toLocaleDateString();
        
        /**get Day name */
        const day = timeOrder.getDay();

        
        const dayNames = [i18n.t('orders.order_detailed.sunday'), 
        i18n.t('orders.order_detailed.monday'), 
        i18n.t('orders.order_detailed.tuesday'),
        i18n.t('orders.order_detailed.wednesday'), 
        i18n.t('orders.order_detailed.thursday'), 
        i18n.t('orders.order_detailed.friday'), 
        i18n.t('orders.order_detailed.saturday')];

        const dayName = dayNames[day];
        return(
            <View>
                {(deliveryOptionId === 1)?(
                    <View>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.delivery_address')}</Text>
                        <Text style={{fontSize:16}}>{address1} {address2} {address3} {zip_code} {city} {province}</Text>
                    </View>
                ):null}
                {(deliveryOptionId === 2)?(
                    <View>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.delivered_method')}</Text>
                        <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed.delivered_method_2')}</Text>
                    </View>
                ):null}
                <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.notes')}</Text>
                {(notes) ? <Text style={{fontSize:16}}>{notes}</Text> : <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed.no_notes')}</Text>}
                <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed.transactions_status')} {transactionStatusName}</Text>
                <View style={{flexDirection:'row'}}>
                {/* <Text style={{fontSize:22}}>{orderInfo['transactions'][0]['transaction_status']['transaction_status_translations'][0].transaction_status_name}</Text> */}
                {(transactionStatusId === 3)? <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed.transaction_status_3')}</Text>: null}
                {(transactionStatusId === 2)? <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed.transaction_status_2')}</Text>: null}
                {(transactionStatusId === 1)? <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed.transaction_status_1')}</Text>: null}

                </View>
                {(orderReviewed)?(
                    <Text style={{color:'green', fontSize:16}}>{i18n.t('orders.order_detailed.order_reviewed')} {(timeOrder)?(
                        dayName + ', ' + dateOrdered + ' ' + timeOrdered
                    ):null}</Text>
                ):(
                    <Text style={{color:'red', fontSize:16}}>{i18n.t('orders.order_detailed.order_not_reviewed')}</Text>
                )}
                {/* <Text>Hey k{console.log("Sexo? ",dataOrder)}</Text> */}
                {(orderDelivered)?(
                    <TouchableWithoutFeedback style={{backgroundColor:'green'}}
                    onPress={()=>{

                    }}>
                    <Text style={{fontSize:18, textAlign:'center', backgroundColor:'#81C784', paddingVertical:12, fontWeight:'bold', borderRadius:6, marginTop:12}}>{i18n.t('orders.order_detailed.btn_order_delivered')}</Text>
                </TouchableWithoutFeedback>
                ):null}

                {(deliveryOptionId === 1 && transactionStatusId === 1 && orderDelivered ===false)?(
                     <TouchableOpacity style={{backgroundColor:'#000000', marginVertical:10, paddingVertical:12, marginHorizontal:10, borderRadius:6, flexDirection:'row',
                     alignItems:'center', alignContent:'center', justifyContent:'center'}}
                     onPress={()=>{
                     }}>
                             <Text style={{textAlign:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('orders.order_detailed.btn_track_your_order')}</Text>
                             <Feather name='arrow-right' size={28} style={{paddingLeft:10}} color={'white'}></Feather>
                         </TouchableOpacity>
                ):null}
                {(deliveryOptionId === 2 && transactionStatusId === 1 && orderDelivered ===false)?(
                    <TouchableOpacity style={{backgroundColor:'#000000', marginVertical:10, paddingVertical:12, marginHorizontal:10, borderRadius:6, flexDirection:'row',
                    alignItems:'center', alignContent:'center', justifyContent:'center'}}
                    onPress={()=>{
                        // console.log('deliverd');
                        let latitude = pickUpLocation[1];
                        let longitude = pickUpLocation[0];
                        const locationString = `${latitude},${longitude}`;
                        const mapLink = createMapLink({query:locationString, provider: Platform.OS === 'ios' ? 'apple' : 'google'});
                        Linking.openURL(mapLink);
                    }}>
                            <Text style={{textAlign:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('orders.order_detailed.btn_pick_your_order')}</Text>
                            <Feather name='arrow-right' size={28} style={{paddingLeft:10}} color={'white'}></Feather>
                        </TouchableOpacity>
                ):null}
            </View>
        )}
    let style;
    if(Platform.OS === 'ios' && Platform.OS === 'android'){
        style={flex:1, paddingHorizontal:9}
    }else if(Platform.OS ==='web'){
        style = {flex:1, paddingHorizontal:9, justifyContent:'center', alignItems:'center', alignContent:'center'}
    }
    return(
        <SafeAreaView style={{flex:1, paddingTop:-45}}>
        

        <View style={style}>
        <FlatList ListHeaderComponent={header} ListFooterComponent={footer} contentContainerStyle={{marginHorizontal:6}}></FlatList>
        </View>
    </SafeAreaView>
    )
    
}

export default OrderDetailed;