import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, ImageBackground, TouchableWithoutFeedback, Alert, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Feather } from '@expo/vector-icons';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PRODUCTS_BY_CATEGORY, GET_USER_CATEGORY_ID, DELETE_PRODUCT_BY_CATEGORY} from '../../../lib/queries';
import i18n from '../../../i18n/i18n';
const ListProductsByCategory = ({navigation, route}) => {

  
const lang = i18n.locale;
const language = lang.substring(0, 2);
const languageCode = language.toUpperCase();

  const auth = getAuth(Firebase);
  /**Route.params */
  /**Category Name */
    const { customCategoryName} = route.params;
    const {custom_category_name} = customCategoryName

    const {custom_Category_Id} = route.params
    const {custom_category_id : customCategoryId} = custom_Category_Id

    const {userCategoryIdentifier} = route.params
    const {user_category_id} = userCategoryIdentifier

    /**Defining variables */
    const uid = getAuth().currentUser.uid
    /**States */
    const [categoryName, setCategoryName] = useState(custom_category_name? custom_category_name: null)
    const [userCategoryId, setUserCategoryId] = useState('')
    const [userProductsByCategory, setUserProductsByCategory] = useState()
    /**Queries */
    const {loading: fetchUserCategoriesProducts, data: dataUserCategoriesProducts, error: errorUserCategoriesProducts,refetch:refetchProducts}= useQuery(GET_PRODUCTS_BY_CATEGORY, {variables:{uid, customCategoryId , languageCode}})

    /**Mutations */
    const [deleteProductByCategory, {loading: deleteProductByCategoryLoading, error: deleteProductByCategoryError}] = useMutation(DELETE_PRODUCT_BY_CATEGORY)

    useEffect(()=>{
      console.log('Hey', errorUserCategoriesProducts)
      // if(dataUserCategoriesProducts){
      //   console.log(dataUserCategoriesProducts)
      // }

      let newUserProductsByCategory
      if(dataUserCategoriesProducts){
        const {user_categories_products} = dataUserCategoriesProducts;
        newUserProductsByCategory = user_categories_products
        // console.log(user_categories_products)
        // const {0 : user_product} = user_categories_products
        // console.log("user_product dest:" ,user_product['user_product']['product'])
        // const product = user_product[0]['product']
        // console.log("user_products: ", product)
      }
      setUserProductsByCategory(newUserProductsByCategory)
      console.log("userProductsByCategory: ", newUserProductsByCategory)

      const unsubscribe = navigation.addListener('focus', () => {
        console.log("Focus")
        refetchProducts()
      });
    })

    const Item = ({products_category_id, productsByCategory, item}) => (


      <View style={{paddingHorizontal:2, paddingVertical:5}}>
        <TouchableWithoutFeedback style={{}}
         onPress={()=>{
           console.log("IDPRODUCTCAT,", products_category_id)
          Alert.alert("Choose an option", "Please select an option", [
            {
              text: "Delete",
              onPress: ()=>{
                deleteProductByCategory({
                  variables:{
                    id: products_category_id,
                  }
                }).then(()=>{
                  Alert.alert('Product deleted', "Product deleted succesfully")
                  refetchProducts()
                })
              },
              style: "destructive"
            },
            {
              text: "Cancel",
              onPress: ()=>{},
              style: "cancel"
            }
          ]);

         }}>
         <ImageBackground source={{uri: productsByCategory['product'].product_thumbnail}} resizeMode="contain" style={{paddingVertical:5, width:190, height:190, overflow:'hidden', borderRadius:6}}>
          </ImageBackground>
         </TouchableWithoutFeedback>

         <View style={{backgroundColor:"black", borderRadius:6, alignItems:'center', marginVertical:5}}>
              <Text numberOfLines={1} style={{color:"white", fontSize:22}}>
          {productsByCategory['product']['product_translations'][0].product_title.length < 16 ? productsByCategory['product']['product_translations'][0].product_title : productsByCategory['product']['product_translations'][0].product_title.substring(0,15) + '...'}
            </Text>
              </View>
      </View>

    );

    const renderItem = ({ item, index }) => <Item
      products_category_id={item.id}
      productsByCategory={item.user_product}
    />;

    return(
    <SafeAreaView style={{flex:1, paddingTop:-45}}>
        <View style={{paddingHorizontal:5}}>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', alignContent:"center"}}>
            <Text style={{fontSize:32, fontWeight:'bold', textAlign:'center'}}> {i18n.t('products.categories.productsByCategory.title')}
            </Text>
          </View>
          <View style={{alignItems:'center'}}>
            <Text style={{fontSize:18}}>{categoryName}
            </Text>
          </View>
          {/* <Text>
            Hola
            {console.log('Hola',dataUserCategoriesProducts)}
          </Text> */}
          <View style={{paddingTop:16}}>
       <TouchableOpacity
            style={{backgroundColor:"#e0e0e0", paddingVertical:12, borderRadius:6}}
            onPress={()=> {
              // console.log("Hey", userProductsByCategory)

              const newUserProductsIdByCategory = userProductsByCategory.map(userProduct => userProduct.user_product_id)
              console.log("newUserProductsIdByCategory", newUserProductsIdByCategory)
              navigation.navigate('addProductsByCategory',{
                custom_name: {categoryName},
                custom_id:{customCategoryId},
                usercategory_id:{user_category_id},
                userProductsId:{newUserProductsIdByCategory},
              })
            }} >
            <Text style={{color:'#000000', textAlign:'center', fontWeight:'bold'}}>
              {i18n.t('products.categories.productsByCategory.btn_add')}
            </Text>
          </TouchableOpacity>
       </View>

        </View>
        <View style={{alignItems:'center'}}>
            {/* {(customCategories)?(
              <FlatList contentContainerStyle={{paddingBottom: 200}} data={customCategories} renderItem={renderItem} keyExtractor={item => item.user_category_id.toString()}/>
            ):null} */}

            <FlatList data={userProductsByCategory}
            numColumns={2}
            contentContainerStyle={{paddingBottom: 150}}
            renderItem={renderItem} keyExtractor={item => item.id.toString()}/>
          </View>
      </SafeAreaView>
    )

}


export default ListProductsByCategory;