import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, Text, TouchableOpacity, KeyboardAvoidingView,ActivityIndicator, View, TextInput, ImageBackground, Linking,TouchableWithoutFeedback, FlatList, Platform, Image, Dimensions, Modal, useWindowDimensions, Share, ScrollView, Alert, Switch } from 'react-native';
import Firebase from '../../lib/firebase';
import { useQuery, useLazyQuery, useMutation} from '@apollo/client';
import { GET_NEAR_PRODUCTS, GET_ORGANIZATION_BY_ID, GET_ORGANIZATION_PRODUCTS_BY_USERS, GET_USER_PERMISSION_BY_ORGANIZATION,
    GET_COUNTRIES, GET_PROVINCE_BY_COUNTRY, GET_CITY_BY_PROVINCE, UPDATE_ORGANIZATION_BY_ID, UPDATE_ORGANIZATION_LOGO, UPDATE_ORGANIZATION_THUMBNAIL, SETUP_ORGANIZATION, DELETE_ORGANIZATION_BY_ID, UPDATE_ORGANIZATION_ACTIVE, UPDATE_ORGANIZATION_PRODUCT_SHOW_CHECKOUT
} from '../../lib/queries';
import * as TaskManager from 'expo-task-manager';
import * as Location from 'expo-location';
import i18n from '../../i18n/i18n';

import { useFonts } from 'expo-font';
// import * as Linking from 'expo-linking'
// import FastImage from 'react-native-fast-image';
import { useIsFocused } from '@react-navigation/native';
import { getAuth } from 'firebase/auth';
import {Feather} from '@expo/vector-icons';
import openMap, { createMapLink } from 'react-native-open-maps';
import * as Clipboard from 'expo-clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Ionicons from '@expo/vector-icons/Ionicons';
import MapView, { Circle, Marker } from 'react-native-maps';
import { PROVIDER_GOOGLE } from 'react-native-maps';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'; 
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
// import * as Permissions from 'expo-permissions';
import { async } from '@firebase/util';
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
const storage = getStorage(Firebase);

const ModalDropdown = Platform.select({
    native: () => require('react-native-modal-dropdown'),
    web: () => null 
})();

const auth = getAuth(Firebase);

const SetupOrganizationPage = ({navigation, route}) => {
  const [fontsLoaded] = useFonts({
    'Inter-Black': require('../../assets/fonts/Roboto-Black.ttf'),
    'Inter-BlackItalic': require('../../assets/fonts/Roboto-BlackItalic.ttf'),
    'Inter-Bold': require('../../assets/fonts/Roboto-Bold.ttf'),
    'Inter-BoldItalic': require('../../assets/fonts/Roboto-BoldItalic.ttf'),
    'Inter-Italic': require('../../assets/fonts/Roboto-Italic.ttf'),
    'Inter-Light': require('../../assets/fonts/Roboto-Light.ttf'),
    'Inter-LightItalic': require('../../assets/fonts/Roboto-LightItalic.ttf'),
    'Inter-Medium': require('../../assets/fonts/Roboto-Medium.ttf'),
    'Inter-MediumItalic': require('../../assets/fonts/Roboto-MediumItalic.ttf'),
    'Inter-Regular': require('../../assets/fonts/Roboto-Regular.ttf'),
    'Inter-Thin': require('../../assets/fonts/Roboto-Thin.ttf'),
    'Inter-ThinItalic': require('../../assets/fonts/Roboto-ThinItalic.ttf'),
  });
  // console.log(getLocation());

  const {latitude} = route.params;
  const {distance} = route.params;
  const {longitude} = route.params;
  const {organizationId} = route.params;

  const jsonGeography = {
    "type": "Point",
    "coordinates": [longitude, latitude]
  }

  /**Near products */
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  const [userLevel, setUserLevel] = useState(0);
  const [nearProducts, setNearProducts] = useState();
  // const [organizationId, setOrganizationId] = useState();
  const [organizationName, setOrganizationName] = useState('');
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [organizationPhone, setOrganizationPhone] = useState('');
  const [organizationWhatsapp, setOrganizationWhatsapp] = useState('');
  const [organizationCountryCode, setOrganizationCountryCode] = useState('');
  const [organizationLatitude, setOrganizationLatitude] = useState(1);
  const [organizationLongitude, setOrganizationLongitude] = useState(1);
  const [organizationLatitudeDelta, setOrganizationLatitudeDelta] = useState(0.0922);
  const [organizationLongitudeDelta, setOrganizationLongitudeDelta] = useState(0.0421);
  const [organizationAddress, setOrganizationAddress] = useState('');
  const [storeLatitude, setStoreLatitude] = useState(0);
  const [storeLongitude, setStoreLongitude] = useState(0);
  const [organizationAddress1, setOrganizationAddress1] = useState('');
  const [organizationActive, setOrganizationActive] = useState(false);
  const [showProductCheckout, setShowProductCheckout] = useState(false);
  const [organizationLogo, setOrganizationLogo] = useState('https://firebasestorage.googleapis.com/v0/b/menucitos-328501.appspot.com/o/organization%2Fresized%2Fgray-image-placeholder_500x500.png?alt=media&token=c0c69b74-d0d4-4b81-8d7c-573200493470');
  const [organizationThumbnail, setOrganizationThumbnail] = useState('https://firebasestorage.googleapis.com/v0/b/menucitos-328501.appspot.com/o/organization%2Fresized%2Fgray-image-placeholder_500x500.png?alt=media&token=c0c69b74-d0d4-4b81-8d7c-573200493470');
  const [organizationLocation, setOrganizationLocation] = useState('');
  const [organizationDescription, setOrganizationDescription] = useState('');
  const [distanceState, setDistanceState] = useState(distance);
  const [cartVisibility, setCartVisibility] = useState(false);
  
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedProvinceName, setSelectedProvinceName] = useState('');
  const [selectedCityName, setSelectedCityName] = useState('');

  
  const [selectedCityId, setSelectedCityId] = useState(0);
  const [selectedProvinceId, setSelectedProvinceId] = useState(0);
  const [selectedCountryId, setSelectedCountryId] = useState(0);

  const [country, setCountry] = useState([]);
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);

  const isFocused = useIsFocused();

  /**Firebase */
  const uid= getAuth().currentUser.uid;

  const {loading: loadingCountries, data: dataCountries, error:errorCountries, refetch: refetchCountries} = useQuery(GET_COUNTRIES);
  const {loading: loadingProvinces, data: dataProvinces, error:errorProvinces, refetch: refetchProvinces} = useQuery(GET_PROVINCE_BY_COUNTRY, {variables:{countryId: selectedCountryId}});
  const {loading: loadingCities, data: dataCities, error:errorCities ,refetch: refetchCities} = useQuery(GET_CITY_BY_PROVINCE, {variables:{provinceId: selectedProvinceId}});
  const {loading:loadingOrganization, error:errorOrganization, data:dataOrganization, refetch} = useQuery(GET_ORGANIZATION_BY_ID, {variables:{organizationId}});
  const [updateOrganizationById, {loading:loadingUpdateOrganization, error:errorUpdateOrganization, data:dataUpdateOrganization}] = useMutation(UPDATE_ORGANIZATION_BY_ID);
  const [updateOrganizationLogo, {loading:loadingUpdateOrganizationLogo, error:errorUpdateOrganizationLogo, data:dataUpdateOrganizationLogo}] = useMutation(UPDATE_ORGANIZATION_LOGO);
  const [updateOrganizationThumbnail, {loading:loadingUpdateOrganizationThumbnail, error:errorUpdateOrganizationThumbnail, data:dataUpdateOrganizationThumbnail}] = useMutation(UPDATE_ORGANIZATION_THUMBNAIL);
  const [updateOrganizationActive, {loading:loadingUpdateOrganizationActive, error:errorUpdateOrganizationActive, data:dataUpdateOrganizationActive}] = useMutation(UPDATE_ORGANIZATION_ACTIVE);
  const [updateOrganizationProductShowCheckout, {loading:loadingUpdateOrganizationProductShowCheckout, error:errorUpdateOrganizationProductShowCheckout, data:dataUpdateOrganizationProductShowCheckout}] = useMutation(UPDATE_ORGANIZATION_PRODUCT_SHOW_CHECKOUT);

  /**Setup Organization */

  const [setupOrganization, {loading:loadingSetupOrganization, error:errorSetupOrganization, data:dataSetupOrganization}] = useMutation(SETUP_ORGANIZATION);
  const [deleteOrganizationById, {loading:loadingDeleteOrganization, error:errorDeleteOrganization, data:dataDeleteOrganization}] = useMutation(DELETE_ORGANIZATION_BY_ID);


    async function getOrganization(){
        console.log('errorOrganization', errorOrganization);
        if(dataOrganization){
        setDistanceState(distance);
        const {organization_by_pk} = dataOrganization;
        setOrganizationLogo(organization_by_pk.organization_logo);
        setOrganizationThumbnail(organization_by_pk.organization_thumbnail);
        setOrganizationActive(organization_by_pk.organization_active);
        setShowProductCheckout(organization_by_pk.organization_show_product_checkout);
        }


    }

  async function getCountries(){
    console.log('errorCountries', errorCountries);
    if(dataCountries){
      const {countries} = dataCountries;
      console.log("COUNTRIES: ", countries);
      setCountry(countries);
    }
  }

  async function getProvinces(){
    if(dataProvinces){
      const {provinces} = dataProvinces;
      // console.log("PROVINCES: ", provinces);
      setProvince(provinces);
    }
  }

  async function getCities(){
    if(dataCities){
      const {cities} = dataCities;
      // console.log("CITIES: ", cities);
      setCity(cities);
    }
  }

    const renderButtonCountry = (rowData) => {
        const {country_id, country_name} = rowData;
        return `${country_name}`
      }
    
      const renderDropDownListCountry = (rowData) => {
        return(
            <Text style={{padding:10, fontSize:18}}>{rowData.country_name}</Text>
        )
      }

      const renderButtonProvince = (rowData) => {
        const {province_id, province_name} = rowData;
        return `${province_name}`
      }
    
      const renderDropDownListProvince = (rowData) => {
        return(
            <Text style={{padding:10, fontSize:18}}>{rowData.province_name}</Text>
        )
      }
    
      const renderButtonCity = (rowData) => {
        const {city_id, city_name} = rowData;
        return `${city_name}`
      }
    
      const renderDropDownListCity = (rowData) => {
        return(
            <Text style={{padding:10, fontSize:18}}>{rowData.city_name}</Text>
        )
      }
    
      const renderButtonCategory = (rowData) => {
        const {category_id, default_category_translations} = rowData;
        return `${default_category_translations [0].default_category_name}`
      }
    
      const renderDropDownListCategory = (rowData) => {
        return(
            <Text style={{padding:10, fontSize:18}}>{rowData.default_category_translations[0].default_category_name}</Text>
        )
      }
    
      const renderButtonSubCategory = (rowData) => {
        const {default_subcategory_translations} = rowData;
        return `${default_subcategory_translations[0].default_subcategory_name}`
      }
    
    
      const renderDropDownListSubCategory = (rowData) => {
        return(
            <Text style={{padding:10, fontSize:18}}>{rowData.default_subcategory_translations[0].default_subcategory_name}</Text>
        )
      }

      
      async function getLocation (){
        const { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== 'granted') {
          // console.log('Permission to access location was denied');
        }
        const location = await Location.getCurrentPositionAsync({});
        

        const {latitude, longitude} = location.coords;
        setStoreLatitude(latitude);
        setStoreLongitude(longitude);
        setOrganizationLatitude(latitude);
        setOrganizationLongitude(longitude);
        setOrganizationLatitudeDelta(0.0022);
        setOrganizationLongitudeDelta(0.0021);
        return location;
      }



  useEffect(() => {

    if(isFocused){
    refetch();
    getOrganization();
    getCountries();
    getProvinces();
    getCities();
    refetchCities();
    getLocation();
    }
  }, [dataOrganization, selectedCityId, selectedProvinceId, selectedCountryId, isFocused, dataCountries, dataProvinces, dataCities, country]);

  let screenWidth
  let numColumns 
  let itemWidth 

  const itemHeight = Dimensions.get('window').width*0.50;

  if(Platform.OS === 'android' || Platform.OS === 'ios'){
    screenWidth = Dimensions.get('window').width;
    numColumns = 3;
    itemWidth = screenWidth/numColumns;
  }else if(Platform.OS === 'web'){
    screenWidth = useWindowDimensions().width;
    if(screenWidth > 1200){
      numColumns = 8;
      itemWidth = screenWidth / numColumns;
    }else if(screenWidth > 768){
      numColumns = 5;
      itemWidth = screenWidth / numColumns;
    }
    else{
      numColumns = 2;
      itemWidth = screenWidth/numColumns;
    }
  }

  const openGallery = async () => {

     const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
    
     if (permissionResult.granted === false) {
       alert("Permission to access camera roll is required!");
       return;
     }

        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [2,1],
            quality: Platform.OS === 'ios' ? 0 : 1,
        });

        if(!result.canceled){
            const imageuniqueID = uuidv4();
            try {
                await updateOrganizationImage(result.uri, imageuniqueID);
            } catch (error){
                console.log(error);
            }
        }
  };

  async function uploadImage(uri, imageName) {

    console.log("IMAGE UUID, ", imageName)

    const fileref = ref(storage, 'organization/'+imageName);
    const img = await fetch(uri);
    const blob = await img.blob();

    console.log('uploading image...');

    const uploadTask = uploadBytesResumable(fileref, blob);

    uploadTask.on('state_changed', (snapshot) => {
        
    }, (error) => {
      console.log("ERROR UPLOADING IMAGE: ", error);
    } , () => {

      console.log('upload completed');
        
      getDownloadURL(fileref).then(
        async (response) => {
            console.log("Response: ", response );
            if(organizationLogo){
              if(organizationThumbnail){
                  // console.log('organization Image:', organizationLogo);
                  // console.log("thumbnail", organizationThumbnail);
                  const storageRef = ref(storage, organizationThumbnail);
  
                    try {
                        await deleteObject(storageRef).then(()=>{
                          console.log("deleted");
                      })
                    } catch (error) {
                      console.log("ERROR NO ID", error);
                    }
                  
                  const storageRef1 = ref(storage, organizationLogo);

                   try {
                    await deleteObject(storageRef1).then(()=>{
                      console.log("deleted");
                     })
                   } catch (error) {
                      console.log("ERROR NO ID1", error);
                   }
              }

          }
          console.log("deleted");

          console.log("organizationId: ", organizationId);

          updateOrganizationLogo({variables:{
              organizationId: organizationId,
              organizationLogo: response,
          }}).then(async (res)=>{
              console.log("Logo updated");

              const pathRef = ref(storage, 'organization/resized/'+imageName+'_500x500');

              await keepTrying(9999, pathRef).then(
                  url =>{
                      console.log("URL", url);
          /**upload thumbnail */
              updateOrganizationThumbnail({variables:{
                    organizationId: organizationId,
                    organizationThumbnail: url,
              }}).then((res)=>{
              console.log("Thumbnail updated");
              // setProcessLoading(false);
              refetch();
                  // Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [
                  
                  // {text: 'OK', onPress: () => navigation.goBack()},
                  // ], {cancelable: false});
          
                  if(Platform.OS === 'web'){
                  setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
                  setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
                  setModalVisibilityAlert(true);
                  }
          
              }).catch((err)=>{
                  console.log("Error Thumbnail", err);
              })
              }).catch((err)=>{
                  console.log("out of tries", err);
              });
          }).catch((err)=>{
              console.log("Error thlo", err);
          })
        }).catch((error)=>{
              console.log("Error", error);
          });
    });

  }

       /**Uploadimage function */
       function delay(t, v) {
        return new Promise(function(resolve) { 
          setTimeout(resolve.bind(null, v), t)
        });
      }
      
      function keepTrying(triesRemaining, storageRef) {
        if (triesRemaining < 0) {
          console.log('out of tries');
          return Promise.reject('out of tries');
        }
    
        return getDownloadURL(storageRef).then(url => {
          return url;
        }).catch(err => {
          if (err.code === 'storage/object-not-found') {
            return delay(1000).then(() => {
              console.log('retrying');
              return keepTrying(triesRemaining - 1, storageRef);
            });
          } else {
            return Promise.reject(err);
          }
        })
    }

  const updateOrganizationImage = async (uri, imageName) => {
    const organizationImage = await uploadImage(uri, imageName);
  };


  const handleDownload = async () => {
    let date = new Date().getDate('dd-MM-yyyy');
    let fileUri = FileSystem.documentDirectory + date + '.jpg';
    try {
       const res = await FileSystem.downloadAsync(organizationLogo, fileUri);
       saveFile(res.uri);
    } catch (error) {
      console.log(error);
    }
  };

  const saveFile = async (fileUri) => {
    const { status } = await MediaLibrary.requestPermissionsAsync();
    if (status === 'granted') {
        try {
            const assets = await MediaLibrary.createAssetAsync(fileUri);
            const album = await MediaLibrary.getAlbumAsync('Download');
            if (album === null) {
                await MediaLibrary.createAlbumAsync('Download', assets, false);
            } else {
                await MediaLibrary.addAssetsToAlbumAsync([assets], album, false);
            }
        } catch (error) {
            console.log(error);
        }
    }else if (status !== 'granted') {
        alert('Permission to access media library is required!');
    }
  };

  return (
    <SafeAreaView style={{flex:1}}>
      <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios' ? 'padding' : 'height'}>
        <ScrollView>
        <View style={{paddingTop:-45}}>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <Text style={{fontSize:28, fontWeight:'bold', paddingHorizontal:5}}>
            {i18n.t('home.editOrganizationPage.title')}
        </Text>
        
        </View>
        <Text style={{paddingHorizontal:8, fontSize:16}}>
        {i18n.t('home.editOrganizationPage.organizationImage')}
        </Text>

        </View>
        
        <View style={{alignContent:'center', alignItems:'center'}}>
        <Image source={{uri:organizationThumbnail}} style={{width:screenWidth-10, height:screenWidth*0.45, resizeMode:'cover', borderRadius:3}}
        >
        </Image>
        <TouchableOpacity style={{position:'absolute', top:screenWidth*0.45-50, left:screenWidth-50, backgroundColor:'white', borderRadius:50, width:40, height:40, justifyContent:'center', alignItems:'center'}} onPress={()=>{
            openGallery();
        }}>
        <Feather name="edit" size={20} color="black" />
        </TouchableOpacity>
        <TouchableOpacity style={{position:'absolute', top:screenWidth*0.45-50, left:screenWidth-95, backgroundColor:'white', borderRadius:50, width:40, height:40, justifyContent:'center', alignItems:'center'}} onPress={()=>{
            handleDownload();
        }}>
        <Feather name="download" size={20} color="black" />
        </TouchableOpacity>
        </View>
        <View style={{padding:10}}>
        <Text style={{fontSize:16, fontWeight:'bold'}}>
            {i18n.t('home.editOrganizationPage.organizationName')}
        </Text>
        <TextInput style={{borderWidth:1, borderColor:'gray', borderRadius:5, padding:10, fontSize:16, marginTop:5}} defaultValue={organizationName} onChangeText={(text)=>{
            setOrganizationName(text);
        }}></TextInput>

        <Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationEmail')}
        </Text>
        <TextInput style={{borderWidth:1, borderColor:'gray', borderRadius:5, padding:10, fontSize:16, marginTop:5}} defaultValue={organizationEmail} onChangeText={(text)=>{
            setOrganizationEmail(text);
        }}></TextInput>

        <Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationPhone')}
        </Text>
        <TextInput style={{borderWidth:1, borderColor:'gray', borderRadius:5, padding:10, fontSize:16, marginTop:5}} 
        keyboardType="phone-pad"
        defaultValue={organizationPhone} onChangeText={(text)=>{
            setOrganizationPhone(text);
        }}></TextInput>

        <Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationWhatsApp')}
        </Text>
        <TextInput style={{borderWidth:1, borderColor:'gray', borderRadius:5, padding:10, fontSize:16, marginTop:5}}
        keyboardType="phone-pad"
        defaultValue={organizationWhatsapp} onChangeText={(text)=>{
            setOrganizationWhatsapp(text);
        }}></TextInput>
        <Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationMapPosition')}
        </Text>
        <View style={{alignContent:'center', alignItems:'center'}}>
        <MapView
        style={{width:screenWidth-10, height:screenWidth*0.55, marginTop:10}}
        provider={PROVIDER_GOOGLE}
        region={{
            latitude: organizationLatitude,
            longitude: organizationLongitude,
            latitudeDelta: organizationLatitudeDelta,
            longitudeDelta: organizationLongitudeDelta,
        }}
        initialRegion={{
                    latitude: organizationLatitude,
                    longitude: organizationLongitude,
                    latitudeDelta: organizationLatitudeDelta,
                    longitudeDelta:   organizationLongitudeDelta,
        }}
        onRegionChangeComplete={(region)=>{
            setOrganizationLatitudeDelta(region.latitudeDelta);
            setOrganizationLongitudeDelta(region.longitudeDelta);
            setOrganizationLatitude(region.latitude);
            setOrganizationLongitude(region.longitude);
        }}

        onPress={(e) =>{
                    let data = e;
                    // console.log(e.nativeEvent.coordinate);

                    let lat = data.nativeEvent.coordinate.latitude;
                    let lng = data.nativeEvent.coordinate.longitude;
                    setStoreLatitude(lat)
                    setStoreLongitude(lng)
                  }}
        >
                <Marker
                  key={1}
                  coordinate={{
                    latitude: storeLatitude,
                    longitude: storeLongitude,
                  }}
                  title={i18n.t('home.my_location')}
                  description={i18n.t('home.my_location')}
                  ></Marker>
        </MapView>
        </View>
        <Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationAddress')}
        </Text>
            <TextInput style={{borderWidth:1, borderColor:'gray', borderRadius:5, padding:10, fontSize:16, marginTop:5}} defaultValue={organizationAddress1} onChangeText={(text)=>{
                setOrganizationAddress1(text);
            }}></TextInput>

<Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationDescription')}
          </Text>

            <TextInput style={{borderWidth:1, borderColor:'gray', borderRadius:5, padding:10, fontSize:16, marginTop:5}} defaultValue={organizationDescription}
            numberOfLines={5}
            multiline={true}
            onChangeText={(text)=>{
                setOrganizationDescription(text);
            }}></TextInput>
          <View style={{flexDirection:'row'}}>
            <Text style={{fontSize:16, fontWeight:'bold', marginTop:10, flexGrow:1}}>
              {i18n.t('home.editOrganizationPage.organization_active')}
            </Text>
              <Switch style={{marginTop:10, marginLeft:10}} value={organizationActive} onValueChange={(value)=>{
                setOrganizationActive(value);
                updateOrganizationActive({
                  variables:{
                    organizationId:organizationId,
                    organizationActive: value
                  }
                }).then(({data})=>{
                  refetch()
                }).catch((error)=>{
                  console.log(error)
                })

              }}></Switch>
            </View>
          <View style={{flexDirection:'row'}}>
            <Text style={{fontSize:16, fontWeight:'bold', marginTop:10, flexGrow:1}}>
              {i18n.t('home.editOrganizationPage.show_product_check_out')}
            </Text>
              <Switch style={{marginTop:10, marginLeft:10}} value={showProductCheckout} onValueChange={(value)=>{
                setShowProductCheckout(value);
                updateOrganizationProductShowCheckout({
                  variables:{
                    organizationId:organizationId,
                    organizationShowProductCheckout:value
                  }
                }).then(({data})=>{
                  refetch()
                }).catch((error)=>{})
              }}></Switch>

            </View>
            
            <Text style={{fontSize:16, fontWeight:'bold', marginTop:10}}>
            {i18n.t('home.editOrganizationPage.organizationCountry')}
            </Text>

            {/* Modal DropDownCountry */}

            {(Platform.OS === 'android' || Platform.OS === 'ios') && (
            <View>
              <View style={{flexDirection:'row'}}>
          <ModalDropdown 
          dropdownStyle={{width:200, height:200}}
          dropdownTextStyle={{fontSize:16}}
          defaultIndex={selectedCountryId}
        
          style={{borderRadius:6,padding:8, backgroundColor:'#e0e0e0', flexGrow:1}} textStyle={{fontSize:16}} options={country}
          renderButtonText={(rowData) => renderButtonCountry(rowData)}
          renderRow={(rowData) => renderDropDownListCountry(rowData)}
          onSelect={(index, value) => {
            setSelectedCountryId(value.country_id);
            setSelectedCountryName(value.country_name);
            refetchProvinces()
          }}
          >
            <Text>
              {selectedCountryName}
            </Text>
          </ModalDropdown>
          <Feather  name='chevron-down' style={{position:'absolute', alignSelf:'center', right:25}}/>
          </View>
          <Text style={{fontSize:16, fontWeight:'bold'}}>
            {i18n.t('home.province')}
          </Text>
          <View style={{flexDirection:'row'}}>
          <ModalDropdown 
          dropdownStyle={{width:200, height:200}}
          renderButtonText={(rowData) => renderButtonProvince(rowData)}
          renderRow={(rowData) => renderDropDownListProvince(rowData)}
          onSelect={(index, value) => {
            setSelectedProvinceId(value.province_id);
            setSelectedProvinceName(value.province_name);
            refetchProvinces();
          }}
          dropdownTextStyle={{fontSize:16}}
          style={{borderRadius:6,padding:8, backgroundColor:'#e0e0e0', flexGrow:1}} textStyle={{fontSize:16}} options={province}>
            <Text>
              {selectedProvinceName}
            </Text>
          </ModalDropdown>
          <Feather  name='chevron-down' style={{position:'absolute', alignSelf:'center', right:25}}/>
          </View>
          <Text style={{fontSize:16, fontWeight:'bold'}}>
            {i18n.t('home.city')}
          </Text>
          <View style={{flexDirection:'row'}}>
          <ModalDropdown 
          dropdownStyle={{width:200, height:200}}
          dropdownTextStyle={{fontSize:16}}
          renderButtonText={(rowData) => renderButtonCity(rowData)}
          renderRow={(rowData) => renderDropDownListCity(rowData)}
          onSelect={(index, value) => {
            setSelectedCityId(value.city_id);
            setSelectedCityName(value.city_name);
            refetchCities();
          }}

          style={{borderRadius:6,padding:8, backgroundColor:'#e0e0e0', flexGrow:1}} textStyle={{fontSize:16}} options={city}>
            <Text>
              {selectedCityName}
            </Text>
          </ModalDropdown>
          <Feather  name='chevron-down' style={{position:'absolute', alignSelf:'center', right:25}}/>
          </View>

            </View>
          )}



            <TouchableOpacity style={{backgroundColor:'#304FFE', padding:10, borderRadius:5, marginTop:10}} onPress={()=>{
                let point ={
                    "type": "Point",
                    "coordinates": [
                        storeLongitude,
                        storeLatitude
                    ]
                }
                // updateOrganizationById({
                //     variables:{
                //         organizationId: organizationId,
                //         organizationName: organizationName,
                //         organizationEmail: organizationEmail,
                //         organizationPhone: organizationPhone,
                //         organizationWhatsapp: organizationWhatsapp,
                //         point: point,
                //         organizationAddress1: organizationAddress1,
                //         selectedCountryId: selectedCountryId,
                //         selectedProvinceId: selectedProvinceId,
                //         selectedCityId: selectedCityId,
                //         organizationDescription: organizationDescription,
                //         organizationImage: organizationLogo,
                //         organizationThumbnail: organizationThumbnail,
                //     }
                // }).then((result)=>{
                //     Alert.alert(i18n.t('home.editOrganizationPage.alert_title'), i18n.t('home.editOrganizationPage.alert_body'));

                // }).catch((error)=>{console.log(error)})

                setupOrganization({variables:{
                    organizationId: organizationId,
                    organizationName: organizationName,
                    organizationEmail: organizationEmail,
                    organizationPhone: organizationPhone,
                    organizationWhatsapp: organizationWhatsapp,
                    point: point,
                    organizationAddress1: organizationAddress1,
                    selectedCountryId: selectedCountryId,
                    selectedProvinceId: selectedProvinceId,
                    selectedCityId: selectedCityId,
                    organizationDescription: organizationDescription,
                    organizationImage: organizationLogo,
                    organizationThumbnail: organizationThumbnail,
                }}).then((result)=>{
                    Alert.alert(i18n.t('home.editOrganizationPage.alert_title'), i18n.t('home.editOrganizationPage.alert_body'));

                }).catch((error)=>{console.log(error)})

            }}>
                <Text style={{color:'white', textAlign:'center', fontSize:16, fontWeight:'bold'}}>
                    {i18n.t('home.editOrganizationPage.save')}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', padding:10, borderRadius:5, marginTop:10}} onPress={()=>{

Alert.alert(
  i18n.t('home.editOrganizationPage.alert_title_delete'),
  i18n.t('home.editOrganizationPage.alert_body_delete'),
  [
    {
      text: i18n.t('home.editOrganizationPage.alert_cancel'),
      onPress: () => console.log("Cancel Pressed"),
      style: "cancel"
    },
    { text: i18n.t('home.editOrganizationPage.alert_ok'), onPress:() => {
        deleteOrganizationById({
          variables:{
            organizationId: organizationId,
          } 
        }).then(async(result)=>{
          Alert.alert(i18n.t('home.editOrganizationPage.alert_title_delete_success'), i18n.t('home.editOrganizationPage.alert_body_delete_success'));
          if(organizationLogo){
            if(organizationThumbnail){
                // console.log('organization Image:', organizationLogo);
                // console.log("thumbnail", organizationThumbnail);
                const storageRef = ref(storage, organizationThumbnail);

                  try {
                      await deleteObject(storageRef).then(()=>{
                        console.log("deleted");
                    })
                  } catch (error) {
                    console.log("ERROR NO ID", error);
                  }
                
                const storageRef1 = ref(storage, organizationLogo);

                try {
                  await deleteObject(storageRef1).then(()=>{
                    console.log("deleted");
                  })
                } catch (error) {
                    console.log("ERROR NO ID1", error);
                }
            }
         }
          

         navigation.navigate('Home');
        }).catch((error)=>{console.log(error)})
      } 
    }
  ]
);



}}>
  <Text style={{color:'white', textAlign:'center', fontSize:16, fontWeight:'bold'}}>
      {i18n.t('home.editOrganizationPage.delete')}
  </Text>
</TouchableOpacity>
            
        </View>

        </ScrollView>
        </KeyboardAvoidingView>
    </SafeAreaView>
  );
}

export default SetupOrganizationPage;