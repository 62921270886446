import React, { useState, useEffect } from 'react';
import { View, Modal,Text, TextInput, TouchableOpacity, Alert, ActivityIndicator, SafeAreaView, FlatList} from 'react-native';
import {useMutation} from '@apollo/client'
import { UPDATE_PRODUCT_DESCRIPTION_BY_ID, DELETE_PRODUCT_DESCRIPTION_BY_ID } from '../../../lib/queries';
import i18n from '../../../i18n/i18n';


const EditProductDescription = ({navigation:{setParams}, route}) => {

    /**route.params */

      const {productDesc} = route.params
      const {productDescriptionItem} = productDesc

      const {productDescId} = route.params

      /**state */

      const [productDescription, setProductDescription] = useState(productDescriptionItem? productDescriptionItem:'')

      /**mutations */

      const [updateProductDescription, {loading: updateProductDescriptionLoading}] = useMutation(UPDATE_PRODUCT_DESCRIPTION_BY_ID)
      const [deleteProductDescription, {loading: deleteProductDescriptionLoading}] = useMutation(DELETE_PRODUCT_DESCRIPTION_BY_ID)
    useEffect(()=>{
      // console.log(productDescId)
    },)

    return(
        <SafeAreaView style={{flex:1}}>
          <Modal
            visible={deleteProductDescriptionLoading}
            style={{flex:1, justifyContent:'center', alignItems:'center'}}
            animationType="none"
            transparent={true}>
                <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                {/* <Text>Hey</Text> */}
                <ActivityIndicator size="large" color='#1e76e8' />
                {/* <Button title="Close" onPress={()=>setVisibleModal(false)}/> */}
                </View>

            </Modal>
        <View style={{flex:1}}>
            <View>
            <View style={{flexDirection:"row", alignItems:'center'}}>
            <Text style={{fontSize:18, fontWeight:'bold'}}>
                {i18n.t('products.editProductDescription.title')}
            </Text>
            </View>
            <View style={{paddingVertical:5}}>
            <Text style={{fontSize:16}}>
              {i18n.t('products.editProductDescription.body')}
            </Text>
            </View>
            </View>
        </View>
        <View style={{flex:2}}>
        <TextInput
        defaultValue={productDescription}
        onChangeText={(text)=>setProductDescription(text)}
        style={{paddingVertical:5, paddingHorizontal:5, backgroundColor:'#e0e0e0'}}
        placeholder="Description"></TextInput>
        </View>
        <View style={{flex:0.6}}>
        <TouchableOpacity
            style={{backgroundColor:'#FF6D00', borderRadius:5, paddingVertical:5, marginTop:2}}
            onPress={()=> {if(productDescription){
              Alert.alert('Delete', "Are you sure you want to delete this description?", [
                {
                  text: 'Cancel',
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel'
                },
                {
                  text: 'OK',
                  onPress: () => {
                    deleteProductDescription({
                      variables:{
                        productDescriptionId: productDescId,
                      }
                    }).then(()=>{
                      Alert.alert('Description deleted', "Product Description deleted succesfully")
                      setParams({
                        productDescription: '',
                      })
                    })
                  }
                }
              ])
            }else{
              Alert.alert("Error", "Type a description for your product filling the textbox and hitting enter")
            }}}
          >
            <Text style={{color:'#FFFFFF', textAlign:"center", fontWeight:'bold', fontSize:16, borderRadius:6, paddingVertical:5}}>
              {i18n.t('products.editProductDescription.btn_delete')}
            </Text>
          </TouchableOpacity>
        <TouchableOpacity
            style={{backgroundColor:'#000000', borderRadius:5, paddingVertical:15, marginTop:2}}
            onPress={()=> {if(productDescription){
              updateProductDescription({
                variables:{
                  productDescriptionId: productDescId,
                  productDescription: productDescription,
                }
              }).then(()=>{
                Alert.alert('Description updated', "Product Description updated succesfully")
                setParams({
                  productDescription: productDescription,
                })
              })
            }else{
              Alert.alert("Error", "Type a description for your product filling the textbox and hitting enter")
            }}}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6}}>
              {i18n.t('products.editProductDescription.btn_save')}
            </Text>
          </TouchableOpacity>

        </View>
    </SafeAreaView>
    )

}

export default EditProductDescription;