import React, { useState, useEffect } from 'react';
import { View, Text, KeyboardAvoidingView, Platform, Alert, TouchableWithoutFeedback, Keyboard , TextInput, TouchableOpacity, Modal } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CITY_BY_PROVINCE, INSERT_USER_LOCATION_SETUP } from '../../lib/queries';
import Firebase from '../../lib/firebase';
import i18n from '../../i18n/i18n';
import { getAuth } from 'firebase/auth';
import { StatusBar } from 'expo-status-bar';

const auth = getAuth(Firebase);

const City = ({navigation, route}) => {

    /**Route.params */
    const {name} = route.params;
    const {lastname} = route.params;
    const {provinceId} = route.params;
    const {countryId} = route.params;
    // const {phone} = route.params;
    const uid = getAuth().currentUser.uid;
    /**Queries */
    const {loading: loadingCities, data: dataCities, refetch: refetchCities} = useQuery(GET_CITY_BY_PROVINCE, {variables: {provinceId}});
    /**Mutation */ 
    const [insertUserLocation, {loading: loadingInsertUserLocation}] = useMutation(INSERT_USER_LOCATION_SETUP);
    /**State */
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(0);

    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);

    useEffect(() => {
      if(dataCities){
        setCities(dataCities.cities);
      }
    }, [dataCities, cities])
    return(
        <SafeAreaView style={{flex:1}}>
            <StatusBar style='dark' backgroundColor='white'/>
            {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
            <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": "height"} enabled>
                
            <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('setup.city.title')}
            </Text>
            </View>
            <View style={{paddingTop:25}}>
                <Text style={{fontSize:22}}>
                    {i18n.t('setup.city.body')}
                </Text>
            </View>
        </View>
        <View style={{flex:2}}>
            {/* <TextInput style={{paddingHorizontal:12, backgroundColor:getColor('gray-200'), paddingVertical:12,
             marginHorizontal:15, fontSize:18}} /> */}
    
            {(!loadingCities && cities)?(
                <Picker
                itemStyle={{height:100}}
                selectedValue={selectedCity}
            onValueChange={(itemValue, itemIndex) => {setSelectedCity(itemValue)}}>
               <Picker.Item label={i18n.t('setup.city.picker_city_label')} 
                      value="0" key="0"/>
              {
                cities.map((cities, index) => {
                    return(
                        <Picker.Item label={cities.city_name} 
                        value={cities.city_id} key={index}/>
                    )
                })
              }
        
        
          </Picker> 
            ):null}
            
            <TextInput placeholder={i18n.t('setup.city.primary_address_placeholder')} onChangeText={(v)=> setAddress1(v)} style={{backgroundColor:'#c9c1c1', marginTop:20, marginHorizontal:15, paddingVertical:5, fontSize:16, borderRadius:6, paddingHorizontal:3}}></TextInput>
            <TextInput placeholder={i18n.t('setup.city.secondary_address_placeholder')} onChangeText={(v)=> setAddress2(v)} style={{backgroundColor:'#c9c1c1', marginTop:20, marginHorizontal:15,paddingVertical:5, fontSize:16, borderRadius:6, paddingHorizontal:3}}></TextInput>
            <TextInput placeholder={i18n.t('setup.city.zip_code_placeholder')} keyboardType='numeric' onChangeText={(v)=> setZipCode(v)} style={{backgroundColor:'#c9c1c1', marginTop:20, marginHorizontal:15,paddingVertical:5, fontSize:16, borderRadius:6, paddingHorizontal:3}}></TextInput>
              
  </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{
                if(selectedCity !=0){
                    if(address1 != ''){
                        navigation.navigate('PhoneScreen',{
                            name,
                            lastname,
                            // phone,
                            provinceId,
                            countryId,
                            cityId: selectedCity,
                            address1,
                            address2,
                            zipCode,
                        });
                    }
                    else{
                        Alert.alert(i18n.t('setup.city.error_primary_address'), i18n.t('setup.city.error_primary_address_body'), [{text: 'OK'}])
                        if(Platform.OS === 'web'){
                            setMessageTitle(i18n.t('setup.city.error_primary_address'));
                            setMessageBody(i18n.t('setup.city.error_primary_address_body'));
                            setModalVisibilityAlert(true);
                        }
                    }
                }else{
                    Alert.alert(i18n.t('setup.city.error'), i18n.t('setup.city.error_body'), [{text: 'OK'}])
                    if(Platform.OS==='web'){
                        setMessageTitle(i18n.t('setup.city.error'));
                        setMessageBody(i18n.t('setup.city.error_body'));
                        setModalVisibilityAlert(true);

                    }
                }
            }}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('setup.city.btn_next')}</Text>
            </TouchableOpacity>
            </View>
            
        </KeyboardAvoidingView>
           {/* </TouchableWithoutFeedback>    */}
    </SafeAreaView>
    )
    
}

export default City;