import React, { useState, useEffect } from 'react';
import { SafeAreaView, FlatList, Text, Switch, TouchableOpacity, ActivityIndicator, View, TextInput, ImageBackground, ScrollView, Alert, Dimensions, Button, Platform, Modal, Image } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { useQuery, useMutation } from '@apollo/client';
import {
    DELETE_PRODUCT_BY_ID, GET_USER_PRODUCT_BY_ID, UPDATE_PRODUCT_IMAGE_PORTRAIT, UPDATE_PRODUCT_IMAGE_THUMBNAIL, UPDATE_PRODUCT_SHOW_IN_ORGANIZATION, UPDATE_PRODUCT_VISIBLE, DELETE_PRODUCT_GALLERY_BY_ID, INSERT_PRODUCT_GALLERY_ONE, UPDATE_PRODUCT_GALLERY_THUMBNAIL_BY_PRODUCT_GALLERY_ID, INSERT_PRODUCT_ORGANIZATION, DELETE_PRODUCT_ORGANIZATION
} from '../../lib/queries';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import i18n from '../../i18n/i18n';
import * as ImagePicker from 'expo-image-picker';
import base64 from 'react-native-base64';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';


const auth = getAuth(Firebase);
const storage = getStorage(Firebase);

const MyProductDetailed = ({ navigation, route }) => {

    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();

    /**Route.params */
    const { productId } = route.params;
    const { product_id } = productId;

    /**State*/
    const [product, setProduct] = useState('');
    const [productImage, setProductImage] = useState('');
    // const [productId, setProductId] = useState('');
    const [productInfo, setProductInfo] = useState('');
    const [productTranslations, setProductTranslations] = useState('');
    const [productCurrency, setProductCurrency] = useState('');
    const [productGallery, setProductGallery] = useState('');
    /**productListDescriptions */
    const [productListDescriptions, setProductListDescriptions] = useState('');
    /**productDeliveryOptions */
    const [productDeliveryOptions, setProductDeliveryOptions] = useState('');
    /**productPaymentOptions */
    const [productPaymentOptions, setProductPaymentOptions] = useState('');

    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
    const [productVisible, setProductVisible] = useState(false);
    const [productOrganizationVisible, setProductOrganizationVisible] = useState(false);
    const [productThumbnail, setProductThumbnail] = useState('');
    const [processLoading, setProcessLoading] = useState(false);
    const [productNewImage, setProductNewImage] = useState('');
    /**organizations */

    const [organizations, setOrganizations] = useState();
    const [activeOrganizationId, setActiveOrganizationId] = useState(false);
    /** */
    const uid = getAuth().currentUser.uid;
    /**Fetch Product */
    const { loading: productLoading, error: productError, data: productData, refetch } = useQuery(GET_USER_PRODUCT_BY_ID, { variables: { uid, product_id, languageCode } });
    /**productPrice */
    const [productPrice, setProductPrice] = useState('');

    /**Mutation */
    const [deleteProductById, { loading: deleteProductLoading, error: deleteProductError }] = useMutation(DELETE_PRODUCT_BY_ID);
    const [updateProductVisible, { loading: updateProductVisibleLoading, error: updateProductVisibleError }] = useMutation(UPDATE_PRODUCT_VISIBLE);
    const [updateProductImagePortrait, { loading: updateProductImagesLoading, error: updateProductImagesError }] = useMutation(UPDATE_PRODUCT_IMAGE_PORTRAIT);
    const [updateProductThumbnail, { loading: updateProductThumbnailLoading, error: updateProductThumbnailError }] = useMutation(UPDATE_PRODUCT_IMAGE_THUMBNAIL);
    // const [updateProductOrganizationVisible, {loading: updateProductOrganizationVisibleLoading, error: updateProductOrganizationVisibleError}] = useMutation(UPDATE_PRODUCT_SHOW_IN_ORGANIZATION);
    const [insertProductGalleryOne, { loading: mutationLoadingInsertProductGalleryOne, data: mutationDataInsertProductGalleryOne }] = useMutation(INSERT_PRODUCT_GALLERY_ONE);
    const [updateProductGalleryThumbnail, { loading: mutationLoadingUpdateProductGalleryThumbnail, data: mutationDataUpdateProductGalleryThumbnail }] = useMutation(UPDATE_PRODUCT_GALLERY_THUMBNAIL_BY_PRODUCT_GALLERY_ID);
    const [deleteProductGalleryById, { loading: deleteProductGalleryLoading, error: deleteProductGalleryError }] = useMutation(DELETE_PRODUCT_GALLERY_BY_ID);


    /**mutation*/

    const [insertProductOrganization, { loading: mutationLoadingInsertProductOrganization, data: mutationDataInsertProductOrganization }] = useMutation(INSERT_PRODUCT_ORGANIZATION);
    const [deleteProductOrganization, { loading: mutationLoadingDeleteProductOrganization, data: mutationDataDeleteProductOrganization }] = useMutation(DELETE_PRODUCT_ORGANIZATION);

    useEffect(() => {
        if (productError) {
            // console.log(productError);
        }
        // console.log("product_id:", product_id);
        let newProductData;
        if (productData) {
            const { user_products } = productData;
            // const {} = user_products;
            // console.log(user_products[0]['product']);
            newProductData = user_products;
            setProductImage(user_products[0]['product']['product_photo_portrait'])
            setProductThumbnail(user_products[0]['product']['product_thumbnail'])
            setProductInfo(user_products[0]['product'])
            setProductVisible(user_products[0]['product']['product_visible'])
            setProductOrganizationVisible(user_products[0]['product']['product_show_in_organization'])
            setProductTranslations(user_products[0]['product']['product_translations'][0])
            setProductCurrency(user_products[0]['product']['product_currencies'][0])
            setProductGallery(user_products[0]['product']['product_galleries'])
            setProductListDescriptions(user_products[0]['product']['product_descriptions'])
            // setProductDeliveryOptions(user_products[0]['product']['product_delivery_options']['country_delivery_option']['delivery_option']['delivery_option_translations'])
            setProductDeliveryOptions(user_products[0]['product']['product_delivery_options'])
            setProductPaymentOptions(user_products[0]['product']['product_payment_options'])
            // console.log("Hey",productPaymentOptions);
            // console.log("ProductcurrencyId", productCurrency.product_price.toFixed(2))
            setProductPrice((Math.round(productCurrency.product_price * 100) / 100).toFixed(2));

            if (user_products[0]['product']['organization_products'] !== null) {
                setActiveOrganizationId(user_products[0]['product']['organization_products'][0] ? user_products[0]['product']['organization_products'][0]['organization_id'] : null)

            } else {
                console.log("No organization");
            }

            const { organization } = productData;
            setOrganizations(organization);
            console.log("organization", organization);
        }
        setProduct(newProductData);

        // console.log("product description: ", productListDescriptions);
        // console.log('Product Galleries: ', productGallery);
        // setProductId(product[0]['product_id']);

        // if(newProductData.hasOwnProperty('product_photo_portrait')){
        //     setProductImage(newProductData['product_photo_portrait']);
        // }
        const unsubscribe = navigation.addListener('focus', () => {
            // console.log("Focus")
            refetch()
        });
    })
    /**Item */
    const Item = ({ item }) => {
        return (

            <View style={{ paddingHorizontal: 5, paddingVertical: 3, marginTop: 2, borderRadius: 6, borderWidth: 1, marginHorizontal: 10 }}>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 5, paddingRight: 10 }}>
                    <View style={{ marginVertical: 2, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ marginRight: 10 }}>
                            <Text style={{ color: '#000000', fontSize: 18 }}>{item.product_description}</Text>
                        </View>
                    </View>
                    <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center' }} onPress={() => {
                        var productDescriptionItem = item.product_description;
                        var productDescId = item.product_description_id;
                        // console.log("productDescriptionItem: ", productDescriptionItem, productDescId);
                        navigation.navigate('EditMyProductDescriptions', {
                            productDesc: { productDescriptionItem },
                            productDescId: item.product_description_id,
                        })
                    }}><Feather name="edit" size={20} color='#000000' /></TouchableOpacity>
                </View>
            </View>

        )
    }

    /**renderProductList */

    const renderProductList = ({ item }) => {
        return (
            <Item item={item} />
        )
    }


    /**renderProductDeliveryOptions */
    const ProductDeliveryOptionItem = ({ item }) => {
        // console.log(item);
        return (
            <View style={{ paddingHorizontal: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ paddingLeft: 2, fontSize: 18 }}>{item['country_delivery_option']['delivery_option']['delivery_option_translations'][0].delivery_option_name}</Text>
                </View>
            </View>
        )
    }

    const renderProductDeliveryOptions = ({ item }) => {
        return (<ProductDeliveryOptionItem item={item} />)
    }

    /**ItemProductPaymentOptions */

    const ItemProductPaymentOptions = ({ item }) => {
        return (
            <View style={{ paddingHorizontal: 5 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 18, color: '#000000', paddingLeft: 2 }}>{item['user_country_payment_option']['country_payment_option']['payment_option']['payment_option_translations'][0].payment_option_name}</Text>
                </View>
            </View>
        )
    }

    /**renderProductPaymentOptions */
    const renderProductPaymentOptions = ({ item }) => {
        return (<ItemProductPaymentOptions item={item} />)
    }


    const openCamera = async (mode = 1) => {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("You've refused to allow this app to access your camera!");
            return;
        }

        const result = await ImagePicker.launchCameraAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [2, 2],
            quality: Platform.OS === 'ios' ? 0 : 1,
        });

        // Explore the result
        // console.log("RESULT: ", result);

        if (!result.canceled) {
            // setProductkNewImage(result.uri);
            const imageuniqueID = uuidv4();
            try {
                await postNewProduct(result.uri, imageuniqueID, mode);
            } catch (error) {
                console.log("Error CAMERA", error);
            }
        }
    }

    const openGallery = async (mode = 1) => {

        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (permissionResult.granted === false) {
            alert("You've refused to allow this app to access your camera!");
            return;
        }

        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [1, 1],
            quality: Platform.OS === 'ios' ? 0 : 1,
        });

        // Explore the result
        // console.log("RESULT: ", result);

        if (!result.canceled) {
            // setProductNewImage(result.uri);
            const imageuniqueID = uuidv4();
            try {
                await postNewProduct(result.uri, imageuniqueID, mode);
            } catch (error) {
                setProcessLoading(false);
                console.log("Error XDra", error);
            }
        }
    }


    /**Uploadimage function */
    function delay(t, v) {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, v), t)
        });
    }

    function keepTrying(triesRemaining, storageRef) {
        if (triesRemaining < 0) {
            return Promise.reject('out of tries');
        }

        return getDownloadURL(storageRef).then(url => {
            return url;
        }).catch(err => {
            if (err.code === 'storage/object-not-found') {
                return delay(1000).then(() => {
                    return keepTrying(triesRemaining - 1, storageRef);
                });
            } else {
                return Promise.reject(err);
            }
        })
    }

    async function uploadImage(uri, imageName, mode) {

        console.log("IMAGE UUID, ", imageName)

        const fileref = ref(storage, 'products/' + imageName);
        const img = await fetch(uri);
        const blob = await img.blob();

        console.log('uploading image...');

        const uploadTask = uploadBytesResumable(fileref, blob);

        uploadTask.on('state_changed', (snapshot) => {

        }, (error) => {
            console.log(error);
        }, () => {

            console.log('upload completed');

            getDownloadURL(fileref).then(
                async (response) => {
                    console.log("Response: ", response);
                    if (mode == 1) {
                        if (productImage) {
                            if (productThumbnail) {
                                console.log('productImage:', productImage);
                                console.log("thumbnail", productThumbnail);
                                const storageRef = ref(storage, productThumbnail);

                                await deleteObject(storageRef).then(() => {
                                    console.log("deleted");
                                }).catch((error) => {
                                    console.log(error);
                                });
                                const storageRef1 = ref(storage, productImage);

                                await deleteObject(storageRef1).then(() => {

                                }).catch((error) => {
                                    console.log("ERROR NO ID", error);
                                });
                            }
                            console.log("deleted");

                            console.log("productID", product_id);

                            updateProductImagePortrait({
                                variables: {
                                    productId: product_id,
                                    productPhotoPortrait: response,
                                }
                            }).then((res) => {
                                // const productId = res.data.insert_products_one.product_id;
                                // console.log("Product ID:" , productId);

                                const pathRef = ref(storage, 'products/resized/' + imageName + '_500x500')

                                keepTrying(9999, pathRef).then(
                                    url => {
                                        console.log("URL", url);
                                        /**upload thumbnail */
                                        updateProductThumbnail({
                                            variables: {
                                                productId: product_id,
                                                productThumbnail: url
                                            }
                                        }).then((res) => {
                                            console.log("Thumbnail updated");
                                            setProcessLoading(false);
                                            Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [

                                                { text: 'OK', onPress: () => navigation.goBack() },
                                            ], { cancelable: false });

                                            if (Platform.OS === 'web') {
                                                setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
                                                setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
                                                setModalVisibilityAlert(true);
                                            }

                                        }).catch((err) => {
                                            console.log("Error Thumbnail", err);
                                        })
                                    }).catch((err) => {
                                        console.log("Error Portrait", err);
                                    })

                            })

                        }
                    } else if (mode == 2) {

                        const fileref1 = ref(storage, 'products/' + imageName);
                        const img1 = await fetch(uri);
                        const blob1 = await img1.blob();
                        console.log('uploading image...' + uri);

                        const uploadTask1 = uploadBytesResumable(fileref1, blob1);

                        uploadTask1.on('state_changed', (snapshot) => {
                            console.log('snapshot progess ' + snapshot);
                        }, (err) => {
                            console.log(err);
                        }, async () => {
                            console.log('upload image success');
                            const url1 = await getDownloadURL(fileref1);

                            console.log("URL", url1);

                            insertProductGalleryOne({
                                variables: {
                                    productId: product_id,
                                    productGalleryPhoto: url1
                                }
                            }).then(res => {
                                const productGalleryId = res.data.insert_product_gallery_one.product_gallery_id;

                                const pathRef1 = ref(storage, 'products/resized/' + imageName + '_500x500');
                                keepTrying(35, pathRef1).then(
                                    url2 => {
                                        console.log("URL", url2);

                                        /**upload thumbnail */
                                        updateProductGalleryThumbnail({
                                            variables: {
                                                productGalleryId,
                                                productThumbnail: url2
                                            }
                                        }).then((res) => {
                                            console.log("Thumbnail updated");
                                            setProcessLoading(false);
                                            refetch();
                                            Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [

                                                { text: 'OK', onPress: () => console.log('OK Pressed') },
                                            ], { cancelable: false });

                                            if (Platform.OS === 'web') {
                                                setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
                                                setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
                                                setModalVisibilityAlert(true);
                                            }

                                        }).catch((err) => {
                                            console.log("Error22222222", err);
                                        })
                                    }
                                )
                            }).catch(err => {
                                console.log("Error3333333", err);
                            })


                        })
                    }
                }).catch((error) => {
                    console.log("Error", error);
                }
                )


        });

    }

    async function postNewProduct(uri, imageName, mode) {
        setProcessLoading(true);
        const productImage = await uploadImage(uri, imageName, mode);
        console.log("Product Image: ", imageName);
    }

    const RenderGalleryItem = ({ item, product_gallery_thumbnail, product_gallery_photo }) => {
        return (
            <View style={{ paddingHorizontal: 7, paddingVertical: 10 }}>
                <ImageBackground source={{ uri: item.product_gallery_thumbnail ? item.product_gallery_thumbnail : undefined }} resizeMode="contain" imageStyle={{ borderRadius: 16, overflow: 'hidden' }} style={{ height: screenWidth / 3.5, width: screenWidth / 3.5 }}>

                    <TouchableOpacity onPress={() => {
                        Alert.alert(i18n.t('products.myProductDetailed.delete_image_title'), i18n.t('products.myProductDetailed.delete_image_body'), [
                            { text: i18n.t('products.myProductDetailed.delete_image_cancel'), onPress: () => console.log('Cancel Pressed'), style: 'cancel' },
                            {
                                text: i18n.t('products.myProductDetailed.delete_image_ok'), onPress: () => {
                                    console.log("Delete");
                                    if (product_gallery_thumbnail) {
                                        const storageRef = ref(storage, product_gallery_thumbnail);
                                        deleteObject(storageRef).then(() => {
                                            console.log("deleted");


                                        }).catch((error) => {
                                            console.log(error);
                                        });
                                    }

                                    if (product_gallery_photo) {
                                        const storageRef1 = ref(storage, product_gallery_photo);
                                        deleteObject(storageRef1).then(() => {
                                            console.log("deleted");
                                        }).catch((error) => {
                                            console.log(error);
                                        });
                                    }

                                    deleteProductGalleryById({
                                        variables: {
                                            productGalleryId: item.product_gallery_id
                                        }
                                    }).then((res) => {
                                        console.log("Deleted");
                                        refetch();
                                    }).catch((err) => {
                                        console.log("Error", err);
                                    })
                                }
                            }
                        ], { cancelable: false })

                    }} style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.5)', padding: 5, borderRadius: 50 }}>
                        <Feather name="x" size={20} color="white"></Feather>
                    </TouchableOpacity>

                </ImageBackground>
            </View>

        )
    }

    const galleryItem = ({ item }) => {

        return (
            <RenderGalleryItem item={item}
                product_gallery_photo={item.product_gallery_photo}
                product_gallery_thumbnail={item.product_gallery_thumbnail}></RenderGalleryItem>
        )

    }

    const RenderOrganizationItem = ({ item, organization_id, organization_thumbnail, organization_name, active }) => {

        return (
            <View>
                <View style={{ paddingHorizontal: 5, paddingVertical: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image source={{ uri: item.organization_thumbnail ? item.organization_thumbnail : undefined }} style={{ height: 50, width: 50, borderRadius: 50 }}></Image>
                        <Text style={{ fontSize: 18, paddingLeft: 15, flexShrink: 1, flexGrow: 1 }}>{item.organization_name}</Text>
                        <Switch value={active} onValueChange={(value) => {
                            if (value) {

                                /// set value active to true in organization array of object
                                setOrganizations(organizations.map((org) => {
                                    if (org.organization_id === item.organization_id) {
                                        org.active = true;
                                    }
                                    return org;
                                })
                                )
                                setActiveOrganizationId(item.organization_id);
                                insertProductOrganization({
                                    variables: {
                                        organizationId: organization_id,
                                        productId: product_id,
                                        visible: value
                                    }
                                }).catch((err) => { console.log("Error", err) }).then((res) => {
                                    setActiveOrganizationId(item.organization_id)
                                    refetch();
                                })
                            } else {
                                setActiveOrganizationId(null);
                                deleteProductOrganization({
                                    variables: {
                                        productId: product_id,
                                        visible: value
                                    }
                                }).then((res) => {
                                    setActiveOrganizationId(null);
                                    refetch();
                                }).catch((err) => {
                                    console.log("Error", err);
                                })
                            }
                        }} />
                    </View>
                </View>
            </View>
        )
    }

    const organizationItem = ({ item }) => {
        let active;

        if (item.organization_id === activeOrganizationId) {
            active = true;
        } else {
            active = false;
        }

        return (
            <RenderOrganizationItem item={item} active={active} organization_id={item.organization_id}
            ></RenderOrganizationItem>
        )

    }


    const screenWidth = Dimensions.get('window').width - 20;
    const header = () => {
        return (
            <View>
                <View style={{ overflow: 'hidden', paddingTop: 10, alignContent: 'center', alignItems: 'center' }}>
                    <ImageBackground source={{ uri: productImage ? productImage : undefined }} resizeMode="contain" imageStyle={{ borderRadius: 16, overflow: 'hidden' }} style={{ height: screenWidth, width: screenWidth }}></ImageBackground>
                </View>
                <View style={{ paddingHorizontal: 10, paddingVertical: 10 }}>
                    <Button title={i18n.t('products.myProductDetailed.change_photo_from_camera')} onPress={() => {
                        openCamera(1)
                    }}></Button>
                    {(Platform.OS === 'android' || Platform.OS === 'ios') ? (
                        /**Open gallery */
                        <View style={{ marginTop: 10 }}>
                            <Button title={i18n.t('products.myProductDetailed.change_photo_from_gallery')} onPress={() => {
                                openGallery(1)
                            }}></Button>
                        </View>
                    ) : null}
                </View>
                <View style={{ flexDirection: 'row', alignItems: 'center', borderRadius: 6, borderWidth: 1, paddingVertical: 8, flexShrink: 1, paddingHorizontal: 7, marginHorizontal: 9, marginBottom: 5 }}>
                    <Text style={{ fontSize: 28, fontWeight: 'bold', color: '#000000', flexShrink: 1, flexGrow: 1 }}>{productTranslations.product_title}</Text>

                    <TouchableOpacity
                        style={{ backgroundColor: '#1e76e8', height: 32, width: 32, borderRadius: 8, alignItems: 'center', justifyContent: 'center', marginRight: 6 }}
                        onPress={() => {
                            let product_translations_ID = productTranslations.product_translations_id;
                            let product_Title = productTranslations.product_title;
                            let product_SKU = productInfo.product_sku;

                            //   console.log("Edit");
                            navigation.navigate('EditMyProductName', {
                                product_title: { product_Title },
                                product_sku: { product_SKU },
                                product_translations_id: { product_translations_ID },
                                productId: { product_id }
                            })
                        }}
                    >
                        <Feather name='edit' size={16} color='#fff' />
                    </TouchableOpacity>
                </View>
                <View style={{ borderRadius: 6, borderWidth: 1, paddingVertical: 8, flexShrink: 1, paddingHorizontal: 7, marginHorizontal: 9 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#000000', flexShrink: 1, flexGrow: 1 }}>{i18n.t('products.myProductDetailed.gallery')}</Text>
                        <TouchableOpacity style={{ backgroundColor: '#1e76e8', height: 32, width: 32, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                            /**Alert gallery or camera */

                            Alert.alert(i18n.t('products.myProductDetailed.add_image'), i18n.t('products.myProductDetailed.add_image_body'), [
                                {
                                    text: i18n.t('products.myProductDetailed.cancel'),
                                    onPress: () => {
                                        console.log("Cancel");
                                    }
                                },
                                {
                                    text: i18n.t('products.myProductDetailed.camera'),
                                    onPress: () => {
                                        openCamera(2)
                                    }
                                },
                                {
                                    text: i18n.t('products.myProductDetailed.gallery'),
                                    onPress: () => {
                                        openGallery(2)
                                    }
                                }

                            ], { cancelable: true })


                        }}>
                            <Feather name="plus" size={16} color={'#FFFFFF'} />
                        </TouchableOpacity>
                    </View>
                    <View>
                        <FlatList
                            data={productGallery}
                            horizontal={false}
                            numColumns={3}
                            renderItem={galleryItem}
                            keyExtractor={item => item.product_gallery_id.toString()}
                            showsVerticalScrollIndicator={false}
                            showsHorizontalScrollIndicator={false}
                        >

                        </FlatList>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 10, paddingVertical: 3, marginTop: 2 }}>
                    <TouchableOpacity style={{flexDirection: 'row', alignItems: 'center', alignContent:'center', paddingHorizontal: 5, borderRadius: 6, borderWidth: 1, paddingVertical: 8, backgroundColor:"#1e76e8", borderColor:'#1e76e8'}}>
                        <Text style={{alignSelf:'center', fontFamily:"Inter-Medium", color:"#FFF", fontSize:18}}>
                        Transfer Product to other user 
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={{ paddingHorizontal: 10, paddingVertical: 3, marginTop: 2 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5, borderRadius: 6, borderWidth: 1, paddingVertical: 8 }}>
                        <Text style={{ fontWeight: 'bold', fontSize: 18 }}> {i18n.t('products.myProductDetailed.price')}</Text>
                        <Text style={{ color: '#000000', fontSize: 16, flexGrow: 1 }}>{productPrice} {productCurrency.currency_code}</Text>
                        <TouchableOpacity style={{ backgroundColor: '#1e76e8', height: 32, width: 32, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                            let Price = productPrice;
                            let Currency = productCurrency.currency_code;
                            // console.log("price", productCurrency.product_price);
                            let productCurrencyId = productCurrency.product_currency_id;
                            navigation.navigate('EditMyProductPrice', {
                                product_Price: { Price },
                                product_Currency: { Currency },
                                product_Currency_Id: { productCurrencyId }
                            })
                        }}>
                            <Feather name='edit' size={16} color='#fff' />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 10, paddingVertical: 3, marginTop: 2 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5, borderRadius: 6, borderWidth: 1, paddingVertical: 8 }}>
                        <Text style={{ color: '#000000', fontSize: 18, flexGrow: 1, fontWeight: 'bold' }}>Visible:</Text>
                        <Switch value={productVisible} onValueChange={(value) => {
                            updateProductVisible({
                                variables: {
                                    productId: product_id,
                                    visible: value
                                }
                            }).then(res => {
                                setProductVisible(value);
                                refetch();
                            }).catch(err => {
                                console.log(err);
                            })
                        }} />
                    </View>
                </View>

                <View style={{ paddingHorizontal: 10, paddingVertical: 3, marginTop: 2, borderRadius: 6, borderWidth: 1, paddingVertical: 8, marginHorizontal: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5 }}>
                        <Text style={{ color: '#000000', fontSize: 18, flexGrow: 1, fontWeight: 'bold' }}>Activa tu producto en una tienda</Text>

                    </View>

                    <FlatList
                        listKey='organizations'
                        data={organizations} renderItem={organizationItem} keyExtractor={item => item.organization_id.toString()} showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} extraData={activeOrganizationId} />
                </View>

                <View style={{ paddingHorizontal: 10, paddingVertical: 3, marginTop: 2 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5, borderRadius: 6, borderWidth: 1, paddingVertical: 8 }}>
                        <Text style={{ fontSize: 18, color: '#000000', fontWeight: 'bold', flexGrow: 1 }}> {i18n.t('products.myProductDetailed.stock')} {productInfo.product_quantity} {i18n.t('products.myProductDetailed.units')}</Text>
                        <TouchableOpacity style={{
                            backgroundColor: '#1e76e8',
                            height: 32,
                            width: 32,
                            borderRadius: 8,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }} onPress={() => {
                            let Quantity = productInfo.product_quantity;
                            navigation.navigate('EditMyProductQuantity', {
                                product_quantity: { Quantity },
                                productId: { product_id }
                            })
                        }}>
                            <Feather name='edit' size={16} color='#fff' />
                        </TouchableOpacity>
                    </View>

                </View>

                <View style={{ marginHorizontal: 10, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5 }}>
                    <Text style={{ fontSize: 18, fontWeight: 'bold', flexGrow: 1 }}>{i18n.t('products.myProductDetailed.description')}</Text>
                    <TouchableOpacity style={{ backgroundColor: '#1e76e8', height: 32, width: 32, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onPress={() => {

                        navigation.navigate('EditMyProductListDescriptions', {
                            productId: product_id,
                        })
                    }}>
                        <Feather name="plus" size={16} color={'#FFFFFF'} />
                    </TouchableOpacity>
                </View>



            </View>
        )
    }

    const paymentFooter = () => {
        return (
            <View>
                <View style={{ paddingHorizontal: 10, paddingBottom: 10 }}>
                    <TouchableOpacity onPress={() => {
                        Alert.alert(i18n.t('products.myProductDetailed.alert_delete'), i18n.t('products.myProductDetailed.alert_delete_body'), [
                            {
                                text: "Cancel",
                                onPress: () => console.log("Cancel Pressed"),
                            },
                            {
                                text: "OK",
                                onPress: () => {
                                    deleteProductById({
                                        variables: {
                                            productId: product_id
                                        }
                                    }).then(async (res) => {
                                        //    Alert.alert("Success", "Product deleted successfully");
                                        //    navigation.navigate('MyProducts');

                                        let product_photo_portrait_url = res.data.delete_products_by_pk.product_photo_portrait;
                                        let product_thumbnail = res.data.delete_products_by_pk.product_thumbnail;

                                        // let product_galleries = res.data.delete_products_by_pk.product_galleries;
                                        // console.log("product_galleries", res.data);

                                        if (product_photo_portrait_url) {

                                            const storageRef = ref(storage, product_photo_portrait_url);

                                            await deleteObject(storageRef).then(() => {
                                                // console.log("deleted");
                                            }).catch((error) => {
                                                // console.log(error);
                                            });

                                        }
                                        // console.log("product_thumbnail", product_thumbnail);
                                        if (product_thumbnail) {
                                            // console.log("thumbnail", product_thumbnail);
                                            const storageRef = ref(storage, product_thumbnail);

                                            await deleteObject(storageRef).then(() => {
                                                // console.log("deleted");
                                            }).catch((error) => {
                                                // console.log(error);
                                            });

                                        }
                                        productGallery.map((gallery, index) => {
                                            if (gallery.product_gallery_photo) {
                                                const storageRef = ref(storage, gallery.product_gallery_photo);

                                                deleteObject(storageRef).then(() => {
                                                    console.log("deleted");
                                                }).catch((error) => {
                                                    // console.log(error);
                                                });
                                            }

                                            if (gallery.product_gallery_thumbnail) {
                                                const storageRef = ref(storage, gallery.product_gallery_thumbnail);

                                                deleteObject(storageRef).then(() => {
                                                    console.log("deleted");
                                                }).catch((error) => {
                                                    // console.log(error);
                                                });
                                            }

                                        })

                                        // product_galleries.map((gallery, index)=>{
                                        //         if(gallery.product_gallery_photo){
                                        //             const storageRef = ref(storage, gallery.product_gallery_photo);

                                        //             deleteObject(storageRef).then(()=>{
                                        //                 console.log("deleted galerryy photo");
                                        //             }).catch((error)=>{
                                        //                 console.log(error);
                                        //             });
                                        //         }

                                        //         if(gallery.product_gallery_thumbnail){
                                        //             const storageRef = ref(storage, gallery.product_gallery_thumbnail);

                                        //             deleteObject(storageRef).then(()=>{
                                        //                 console.log("deleted gallery thumbnail");
                                        //             }).catch((error)=>{
                                        //                 console.log(error);
                                        //             });
                                        //         }
                                        //     })

                                        Alert.alert(i18n.t('products.myProductDetailed.product_deleted'), i18n.t('products.myProductDetailed.product_deleted_body'));
                                        navigation.goBack();
                                    }).catch((error) => {
                                        //    console.log(error);
                                        let errorMessage = error.toString();
                                        // Alert.alert("Error", errorMessage);
                                        // console.log("errorMessage", error.message);
                                        if (error.message === `Foreign key violation. update or delete on table "products" violates foreign key constraint "order_products_product_id_fkey" on table "order_products"`) {
                                            Alert.alert("Error", "Product is in use, cannot be deleted");
                                        }
                                    })
                                }
                            }

                        ], { cancelable: false },
                        );

                        /**here */
                        if (Platform.OS === 'web') {
                            setMessageTitle(i18n.t('products.myProductDetailed.alert_delete'));
                            setMessageBody(i18n.t('products.myProductDetailed.alert_delete_body'));
                            setModalVisibilityAlert(true);
                        }


                    }} style={{ backgroundColor: '#FF6D00', marginTop: 3, paddingVertical: 10, borderRadius: 6, opacity: 0.7 }}>
                        <Text style={{ fontSize: 18, fontWeight: 'bold', color: '#000000', textAlign: 'center' }}>
                            {i18n.t('products.myProductDetailed.btn_delete')}
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        )
    }

    const footer = () => {
        return (

            <View>

                <View style={{ paddingHorizontal: 5, paddingVertical: 3, marginTop: 2, borderRadius: 6, borderWidth: 1, marginHorizontal: 10 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 18, color: '#000000', fontWeight: 'bold', flexGrow: 1 }}>{i18n.t('products.myProductDetailed.delivery')}</Text>
                        <TouchableOpacity style={{ backgroundColor: '#1e76e8', height: 32, width: 32, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }} onPress={() => {
                            /**countryDeliveryOptionsId */
                            // console.log(productDeliveryOptions[1]['country_delivery_option'].country_delivery_option_id);

                            const countryDeliveryOptions_Id = productDeliveryOptions.map(item => {
                                return item.country_delivery_option.country_delivery_option_id;
                            });

                            console.log(countryDeliveryOptions_Id);
                            navigation.navigate('EditMyDeliveryOptions', {
                                productId: { product_id },
                                countryDeliveryOptionsId: { countryDeliveryOptions_Id }
                            })
                        }}
                        >
                            <Feather name='edit' size={16} color='#fff' />
                        </TouchableOpacity>
                    </View>
                    <View style={{ marginTop: 1, paddingBottom: 5 }}>

                        <FlatList data={productDeliveryOptions} listKey={1} renderItem={renderProductDeliveryOptions} keyExtractor={(item, index) => { return item['country_delivery_option'].country_delivery_option_id.toString() }} />
                        <View style={{ flexDirection: 'row', alignItems: 'center', paddingHorizontal: 25, paddingTop: 5 }}>

                        </View>
                    </View>
                </View>
                <View style={{ borderWidth: 1, borderRadius: 6, marginTop: 4, marginHorizontal: 10, paddingBottom: 10 }}>
                    <View style={{ flexDirection: 'row', marginHorizontal: 5, alignItems: 'center' }}>
                        <Text style={{ fontSize: 18, color: '#000000', fontWeight: 'bold', flexGrow: 1 }}>{i18n.t('products.myProductDetailed.payment')}</Text>
                        <TouchableOpacity style={{ backgroundColor: '#1e76e8', height: 32, width: 32, borderRadius: 8, justifyContent: 'center', alignItems: 'center', marginTop: 5 }} onPress={() => {
                            // console.log("HOlA", productPaymentOptions[0]['user_country_payment_option'].id_user_country_payment_option);
                            const userCountryPaymentOptions_Id = productPaymentOptions.map(item => item.user_country_payment_option.id_user_country_payment_option);
                            console.log(userCountryPaymentOptions_Id);
                            //    const paymentOptionsId = productPaymentOptions.map(item => item.payment_option.payment_option_id);
                            navigation.navigate("EditMyPaymentOptions", {
                                productId: { product_id },
                                userCountryPaymentOptionsId: { userCountryPaymentOptions_Id },
                            });
                        }}>
                            <Feather name='edit' size={16} color='#fff' />
                        </TouchableOpacity>
                    </View>
                    <FlatList data={productPaymentOptions} ListFooterComponentStyle={{
                        paddingTop: 35
                    }} contentContainerStyle={{ paddingBottom: 15 }} listKey={2} renderItem={renderProductPaymentOptions} keyExtractor={(item, index) => { return item['user_country_payment_option'].id_user_country_payment_option.toString() }} />
                </View>
                {paymentFooter()}
            </View>
        )
    }

    if (productLoading) return (
        <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator size="large" color='#1e76e8' />
        </SafeAreaView>
    )
    return (
        <SafeAreaView style={{ flex: 1, paddingTop: -45 }}>
            {/* Modal Porcces loading */}

            <Modal visible={processLoading}>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator size="large" color='#1e76e8' />
                </View>

            </Modal>


            <Modal visible={modalVisibilityAlert}
                transparent={true}
                animationType={'fade'}
                onRequestClose={() => setModalVisibilityAlert(false)}
            >
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <View style={{ backgroundColor: 'white', borderRadius: 10, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{messageTitle}</Text>
                        <Text style={{ fontSize: 20 }}>{messageBody}</Text>
                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => {
                            deleteProductById({
                                variables: {
                                    productId: product_id
                                }
                            }).then(async (res) => {
                                //    Alert.alert("Success", "Product deleted successfully");
                                //    navigation.navigate('MyProducts');

                                let product_photo_portrait_url = res.data.delete_products_by_pk.product_photo_portrait;
                                let product_thumbnail = res.data.delete_products_by_pk.product_thumbnail;

                                if (product_photo_portrait_url) {

                                    const storageRef = ref(storage, product_photo_portrait_url);

                                    await deleteObject(storageRef).then(() => {
                                        // console.log("deleted");
                                    }).catch((error) => {
                                        // console.log(error);
                                    });

                                }
                                // console.log("product_thumbnail", product_thumbnail);
                                if (product_thumbnail) {
                                    // console.log("thumbnail", product_thumbnail);
                                    const storageRef = ref(storage, product_thumbnail);

                                    await deleteObject(storageRef).then(() => {
                                        // console.log("deleted");
                                    }).catch((error) => {
                                        // console.log(error);
                                    });

                                }

                                Alert.alert(i18n.t('products.myProductDetailed.product_deleted'), i18n.t('products.myProductDetailed.product_deleted_body'));
                                navigation.navigate('MyProducts');
                            }).catch((error) => {
                                console.log(error);
                                let errorMessage = error.toString();
                                // Alert.alert("Error", errorMessage);
                                // console.log("errorMessage", error.message);
                                if (error.message === `Foreign key violation. update or delete on table "products" violates foreign key constraint "order_products_product_id_fkey" on table "order_products"`) {
                                    Alert.alert("Error", "Product is in use, cannot be deleted");
                                }
                            })
                        }}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Delete</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setModalVisibilityAlert(false)}>
                            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>Close</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>

            <FlatList data={productListDescriptions} renderItem={renderProductList} keyExtractor={(item, index) => { return item.product_description_id.toString(); }}
                ListHeaderComponent={header()} ListFooterComponent={footer()}></FlatList>


        </SafeAreaView>
    );
}

export default MyProductDetailed;