import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';

const AddProducts = ({navigation}) => {
    return(
    <SafeAreaView style={{flex:1, paddingVertical:-45}}>
        <View style={{paddingHorizontal:5, flex:1, paddingTop:5}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>{i18n.t('products.addProducts.title')}
            </Text>
          </View>
          <View style={{paddingVertical:5}}>
          <Text style={{fontSize:22}}>
            {i18n.t('products.addProducts.body')}
          </Text>
          
          </View>
          <View style={{flexDirection:'row'}}>
          <Text style={{fontSize:22}}>
            {i18n.t('products.addProducts.label_introducing')}
          </Text>
          
          </View>
        </View>
        <View style={{flex:2}}>

        </View>
        <View style={{flex:0.4}}>
      <TouchableOpacity
            style={{backgroundColor:'#FF6D00', borderRadius:6, paddingVertical:15, marginTop:3, marginTop:10, marginHorizontal:5}}
            onPress={()=> navigation.navigate('AddProductDefaultSubCategory')}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', fontSize:16, borderRadius:6}}>
              {i18n.t('products.addProducts.btn_next')}
            </Text>
          </TouchableOpacity>
        </View>
        
      </SafeAreaView>
    )
    
}

export default AddProducts;