import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, ImageBackground, ActivityIndicator, Dimensions, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Feather } from '@expo/vector-icons';
import { useQuery } from '@apollo/client';
import * as ImagePicker from 'expo-image-picker';
import { GET_MY_CATEGORIES } from '../../lib/queries';
import Firebase from '../../lib/firebase';
import { cond, cos } from 'react-native-reanimated';
import { BlurView } from 'expo-blur';
import i18n from '../../i18n/i18n';
import { getAuth } from 'firebase/auth';
import { useIsFocused } from '@react-navigation/native';

const auth = getAuth(Firebase);


const CustomCategories = ({navigation}) => {
  const isFocused = useIsFocused();
  const uid = getAuth().currentUser.uid
    /**Queries */
    // const {loading: customCategoriesFetch, data: customCategoriesData} = useQuery(GET_MY_CATEGORIES, {variables:{uid}})
    const {loading: customCategoriesFetch, data: customCategoriesData, refetch: refetchCustomCategories} = useQuery(GET_MY_CATEGORIES, {variables:{uid}})
    const [customCategories, setCustomCategories] = useState([])

    /**async function */
    async function getCustomCategories(){
      let newUserCustomCategories
      if(customCategoriesData){
        const {user_categories} = customCategoriesData
        // const {0: custom_categories} = user_categories
        // console.log("DATA:",custom_categories)
        newUserCustomCategories = user_categories
        // console.log("DATA:",user_categories)
      }
      setCustomCategories(newUserCustomCategories)
    }

    useEffect(()=>{

      if(isFocused){
        refetchCustomCategories()
        getCustomCategories();
      }


    }, [isFocused, customCategoriesData])

    const screenWidth = Dimensions.get('window').width-10;

    const Item = ({user_category_id, custom_category, index, custom_cat_photo, custom_category_id, custom_category_name, custom_category_language_code, custom_category_translations_id}) => (
      <View style={{paddingVertical:3}}>
        <TouchableOpacity onPress={()=>{
          console.log("Hey", user_category_id)
          navigation.navigate('EditCustomCategories',{
          imageLink:{custom_cat_photo},
          customCategoryIdentifier:{custom_category_id},
          customCategory_Name:{custom_category_name},
          customLanguageCode:{custom_category_language_code},
          customCategoryTranslationIdentifier:{custom_category_translations_id},
          userCategoryIdentifier:{user_category_id},
        })}}>

      <ImageBackground source={{uri: custom_category.custom_category_photo}} imageStyle={{borderRadius:16}} resizeMode="cover" style={{alignItems:'center', height:200, width:screenWidth }}>

      <Text style={{position:'absolute', paddingHorizontal:10, zIndex:50,fontSize:32 ,fontWeight:'bold', color:'#FFFFFF', marginTop:75}}>{(custom_category.custom_category_translations[0]['custom_category_name'])?(
        custom_category.custom_category_translations[0]['custom_category_name']
      ): "Default"}</Text>
      <View style={{alignSelf:'flex-start', height:200, borderRadius:16, backgroundColor:'#000000', opacity:0.2, width:screenWidth, }}>

      </View>
    </ImageBackground>
        </TouchableOpacity>
        <TouchableOpacity
            style={{backgroundColor:'#000000', borderRadius:6, paddingHorizontal:10, paddingVertical:12, marginTop:3}}
            onPress={()=> navigation.navigate('ProductsByCategory',{
              customCategoryName:{custom_category_name},
              custom_Category_Id:{custom_category_id},
              userCategoryIdentifier:{user_category_id},
            })}
          >
            {/* ('text-white text-center rounded-lg') */}
            <Text style={{textAlign:'center', color:'#FFFFFF', borderRadius:1}}>
              {i18n.t('products.categories.addCategories.view_products_listed')} {custom_category.custom_category_translations[0]['custom_category_name']}
            </Text>
          </TouchableOpacity>
      </View>

    );

    const renderItem = ({ item, index }) => <Item
    user_category_id={item.user_category_id}
    custom_category={item.custom_category}
    // custom_category_translations={item.custom_category.custom_category_translations}
    // imageLink:{custom_category.custom_category_photo}
    custom_cat_photo={item.custom_category.custom_category_photo}
    index={index}
    custom_category_id={item.custom_category.custom_category_id}
    custom_category_name={item.custom_category.custom_category_translations[0]['custom_category_name']}
    custom_category_language_code={item.custom_category.custom_category_translations[0]['language_code']}
    custom_category_translations_id={item.custom_category.custom_category_translations[0]['id']}
    />;

    if(customCategoriesFetch)return(
      <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
        <ActivityIndicator color='#1e76e8' size={'large'}/>
      </SafeAreaView>
    )
    return(
    <SafeAreaView style={{flex:1, paddingTop:-45}}>
        <View style={{paddingHorizontal:10, paddingTop:-45}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>{i18n.t('products.categories.title')}
            </Text>
          </View>
        </View>
        <View style={{paddingHorizontal:5}}>
        <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:15, marginTop:10, alignItems:'center', alignContent:'center'}}
            onPress={() => navigation.navigate('AddCustomCategories')}
          >
                <View style={{flexDirection:'row'}}>
                      <Text style={{color:'black', textAlign:'center', fontWeight:'bold', borderRadius:6, justifyContent:'center'}}>
              {i18n.t('products.categories.btn_add_category')}
            </Text>
                </View>
          </TouchableOpacity>
          {/* FlatList */}

          <View style={{flexDirection:'row'}}>
            {(customCategories)?(
              <FlatList contentContainerStyle={{paddingBottom: 200}} data={customCategories} renderItem={renderItem} keyExtractor={item => item.user_category_id.toString()}/>
            ):null}
          </View>
          </View>
      </SafeAreaView>
    )

}

export default CustomCategories;