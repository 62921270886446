import { useQuery } from '@apollo/client';
import React, { useState, useEffect} from 'react';
import { View, Text, TouchableOpacity} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';
import { GET_CUSTOM_CATEGORY_LANGUAGE } from '../../lib/queries';

const DetailedCustomCategories = ({navigation, route}) => {

    /**Custom CategoryName */
    const {customCategoryNameEdit} = route.params
    const {customTranslationName} = customCategoryNameEdit
    /**Custom Language Code */

    const {customCategoryLanguageCodeEdit} = route.params
    const {customCategoryLanguageCode} = customCategoryLanguageCodeEdit

    /**Custom Category Id */
    const {customCategoryIdEdit} = route.params
    const {customCategoryId} = customCategoryIdEdit
    
    /**customCategoryTranslationId */

    const {customCategoryTranslationIdEdit} = route.params
    const {customCategoryTranslationId} = customCategoryTranslationIdEdit

    const [customCategoryNameDetail, setCustomCategoryNameDetail] = useState(customTranslationName? customTranslationName: null)
    const [customCategoryLanguageCodeDetail, setCustomCategoryLanguageCodeDetail] = useState(customCategoryLanguageCode? customCategoryLanguageCode: null)
    const [customCategoryIdDetail, setCustomCategoryIdDetail] = useState(customCategoryId)
    const [customCategoryTranslationIdentifier, setCustomCategoryTranslationsIdentifier] = useState(customCategoryTranslationId ? customCategoryTranslationId: null)

    /**On Effect */
    const [languageCode, setLanguageCode] = useState('')
    const [customCategoryNameQ, setCustomCategoryNameQ] = useState('')
    const [customCategorylanguageName, setCustomCategoryLanguageName] = useState('')



    /**Queries */
    const {loading: fetchLoading, data:dataCustomCategory, refetch} = useQuery(GET_CUSTOM_CATEGORY_LANGUAGE, {variables:{customCategoryIdDetail, customCategoryLanguageCodeDetail}})

    useEffect(()=>{
        let newlanguageCode
        let newCustomCategoryName
        let newLanguagename
        if(dataCustomCategory){
            const {custom_category_translations} = dataCustomCategory
            // console.log(custom_category_translations[0]['custom_category_name'])
            const {0 :{custom_category_name}= custom_category_name} = custom_category_translations
            // console.log('Hey', custom_category_name)
            // console.log(custom_category_translations)
            /**Destructuring languages */
            const {0 :{language} = language} = custom_category_translations
            // console.log(language)

            const {language_name} = language
            // console.log("hey",language_name)
            // console.log("hey",customCategoryTranslationIdentifier)
            newCustomCategoryName = custom_category_name
            newLanguagename = language_name
        }
        setCustomCategoryNameQ(newCustomCategoryName)
        setCustomCategoryLanguageName(newLanguagename)

        const unsubscribe = navigation.addListener('focus', () => {
            // console.log("Focus")
            refetch()
          });
    })
    return(
    <SafeAreaView style={{flex:1}}>
        <View style={{paddingHorizontal:5}}>
          <View style={{alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}> {i18n.t('products.categories.categoryTranslations.title')}
            </Text>
            <Text style={{fontSize:22, justifyContent:'center', paddingHorizontal:5}}>
            {customCategoryNameQ} {customCategoryLanguageCodeDetail}
            </Text>
          </View>
          <View>
            <Text style={{paddingVertical:5, fontSize:22}}>
            {i18n.t('products.categories.categoryTranslations.body')}
            </Text>
          <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', paddingVertical:10, borderRadius:6}}
            onPress={()=> navigation.navigate('editCategoryTranslations', {
                custom_category_nameDetail:{customCategoryNameQ},
                custom_category_idDetail:{customCategoryIdDetail},
                custom_category_language_codeDetail:{customCategoryLanguageCodeDetail},
                custom_category_translation_idDetail:{customCategoryTranslationIdentifier}
            })}
          >
            <Text style={{textAlign:'center', fontWeight:'bold', fontSize:22, borderRadius:6}}>
              {customCategorylanguageName}
            </Text>
          </TouchableOpacity>
          </View>
         
        </View>
      </SafeAreaView>
    )
    
}

export default DetailedCustomCategories;