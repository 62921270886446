import React, { useState, useEffect } from 'react';
import { View, Text, ActivityIndicator , Modal,TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
import base64 from 'react-native-base64';
import { Feather } from '@expo/vector-icons';
import WebView from 'react-native-webview';
import { useQuery } from '@apollo/client';
import { GET_USER } from '../../../lib/queries';
import i18n from '../../../i18n/i18n';

const auth = getAuth(Firebase);

const PayPalConfig = ({navigation, route}) => {
    
    /**route.params */

    // const {accessToken} = route.params;


    const uid = getAuth().currentUser.uid;

    const [tokenPaypal, setTokenPaypal] = useState('');
    const [merchantId, setMerchantId] = useState('');
    const [userEmail, setUserEmail] = useState('');

    /**Query user email */
    const{loading:userData, data:userDataData, error:userDataError} = useQuery(GET_USER, {variables: {uid}});

    useEffect(() => {

        /**User data */
       console.log(userDataError);
        if(userDataData){
            setUserEmail(userDataData.users_by_pk.user_email);
        }

        async function getPayPalToken(){
         const response = await fetch('https://api-m.paypal.com/v1/oauth2/token ', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Accept-Language': 'en_US', 
          'Authorization': 'Basic '+ base64.encode('AZuIVyOpv53U1fXhArisIyI25BDbsCo2aIFLmN90y-fgKMyQ-5NY1eAUupZcT5qza1cgVHnlVnneCCG3:EKIS8dDy-LbzLI1Q3eQ63c5rkrU8lGDNoqMcDPK1m0r1OR04X1fmrSs9_uZIKu411NKDp6EV5hXLczGA'),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'grant_type=client_credentials',
      })

        const content = await response.json();
        console.log(content.access_token);

        return content.access_token;
        }

        const token = getPayPalToken().then(res => {
            // console.log(res);
            setTokenPaypal(res);
        });

        console.log("token de accesso:", tokenPaypal);
        async function getMerchantId(){

            const response = await fetch('https://api-m.paypal.com/v1/customer/partners/YLH8NQMDP8PPQ/merchant-integrations?tracking_id='+ uid,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Accept-Language': 'en_US',
                    'Authorization': 'Bearer '+ tokenPaypal,
            },
            });

            const content = await response.json();

            // console.log(content.merchant_id);
            return content.merchant_id;
        }

        getMerchantId().then(res => {
            console.log(res);
            setMerchantId(res);
        });

    })

    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [webViewUrl, setWebViewUrl] = useState('');

    const createPartnerLink = async () => {
        // console.log('Token: ', accessToken);
    
    setLoading(true);

    const response = await fetch('https://api-m.paypal.com/v1/oauth2/token ', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Accept-Language': 'en_US', 
          'Authorization': 'Basic '+ base64.encode('AZuIVyOpv53U1fXhArisIyI25BDbsCo2aIFLmN90y-fgKMyQ-5NY1eAUupZcT5qza1cgVHnlVnneCCG3:EKIS8dDy-LbzLI1Q3eQ63c5rkrU8lGDNoqMcDPK1m0r1OR04X1fmrSs9_uZIKu411NKDp6EV5hXLczGA'),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'grant_type=client_credentials',
      })

      const content = await response.json();

      console.log("token de acceso: ",(content.access_token));



    const response2 = await fetch('https://api-m.paypal.com/v2/customer/partner-referrals',{
        method: 'POST',
        headers: {
            
            'Accept': 'application/json',
            'Accept-Language': 'en_US',
            'Authorization': 'Bearer '+ content.access_token,

            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "tracking_id": uid,
            "email": userEmail,
            "operations": [
                {
                    "operation": "API_INTEGRATION",
                    "api_integration_preference": {
                      "rest_api_integration": {
                        "integration_method": "PAYPAL",
                        "integration_type": "THIRD_PARTY",
                        "third_party_details": {
                          "features": [
                            "PAYMENT",
                            "REFUND",
                            "PARTNER_FEE"
                         ]
                        }
                      }
                    }
                }
            ],
            "products": [
                "EXPRESS_CHECKOUT"
            ],
            "legal_consents": [
                {
                  "type": "SHARE_DATA_CONSENT",
                  "granted": true
                }
            ]
        })
    
    });

    

    const data = await response2.json();
    console.log('data: ', data);

    if(data.links){
        // setWebViewUrl(data.links[1].href);
        console.log('data.links[1].href: ', data.links[1].href);
        setLoading(false);
        setWebViewUrl(data.links[1].href);
        setModalVisible(true);
        
    }


    }

    

    return(
        <SafeAreaView style={{flex:1}}>
        <Modal visible={modalVisible} animationType='slide'>
            <View style={{flex:1}}>
                <TouchableOpacity style={{margin:15}} onPress={() => {
                    setModalVisible(false);
                }}>
                    <Feather name='x' size={36} color={'#FFFFFF'} 
                    // color={'#78909C'}
                    />
                {/* <Text>Hey</Text> */}
                
                </TouchableOpacity>
                <WebView source={{uri: webViewUrl}} style={{flex:1}}/>
            </View>
            
        </Modal>

        <View style={{flex:1, paddingHorizontal:12, paddingTop:25}}>
            <View>
            <Text style={{fontSize:38, fontWeight:'bold'}}>
                {i18n.t('paymentMethods.paypal.title')}
            </Text>
            </View>
            <View>
            <Text style={{fontSize:22}}>
                {i18n.t('paymentMethods.paypal.body')}
            </Text>
            <Text style={{paddingTop:10, fontWeight:'bold'}}>{i18n.t('paymentMethods.paypal.paypal_warning')}</Text>
        </View>

        </View>
        
        <View style={{flex:1.7, paddingHorizontal:15}}>
        <TouchableOpacity disabled={merchantId? true: false} style={{paddingVertical:10, backgroundColor:'#304FFE', borderRadius:6,}}
        onPress={()=>{createPartnerLink()}}>
            {(!loading)?(
            (merchantId)?(
            <Text style={{fontSize:20, fontWeight:'bold', textAlign:'center', color:'#FFFFFF'}}>
                {i18n.t('paymentMethods.paypal.account_w_setup')}
            </Text>

            ):
            <Text style={{fontSize:20, fontWeight:'bold', textAlign:'center', color:'#FFFFFF'}}>{i18n.t('paymentMethods.paypal.account_wo_setup')}</Text>
            ):(
                <ActivityIndicator size='large' color={'#FFFFFF'}/>
            )}
        </TouchableOpacity>
        <View style={{paddingTop:35}}>
            <Text style={{fontSize:28, fontWeight:'bold'}}>{i18n.t('paymentMethods.paypal.account_status')}
            </Text>
        </View>
        <View>
            <Text style={{fontSize:18}}>{(merchantId)?(i18n.t('paymentMethods.paypal.account_active') ,' ' + merchantId):(i18n.t('paymentMethods.paypal.account_inactive'))}</Text>
        </View>
        </View>
        
        
    </SafeAreaView>
    )
    
}

export default PayPalConfig;