import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity , VirtualizedList, Alert, FlatList, Dimensions} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {Picker} from '@react-native-picker/picker';
import { useQuery, useLazyQuery } from '@apollo/client';
import { DELIVERY_OPTIONS_BY_COUNTRY, GET_COUNTRY_DELIVERY_OPTIONS, USER_COUNTRY_LOCATION } from '../../lib/queries';
import Firebase from '../../lib/firebase';
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage';
import i18n from '../../i18n/i18n';
import { getAuth } from 'firebase/auth';

const auth = getAuth(Firebase);
const storage = getStorage(Firebase);

const AddDeliveryOption = ({navigation, route}) => {
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const langQuery = language.toUpperCase();

  /**route.params */
  const {subCategoryId} = route.params

  /**productSKUPrice */
  const {productSKUDescriptions} = route.params
  const {productSKU} = productSKUDescriptions
  
  /**productTitle */ 
  const {productTitleDescriptions} = route.params
  const {productTitle} = productTitleDescriptions

  /**productSlug */

  const {productSlugDescriptions} = route.params
  
  const {productSlug} = productSlugDescriptions

  /**productImage */
  const {productImageDescriptions} = route.params
  const {image} = productImageDescriptions

  /**productPrice */

  const {productPriceDescriptions} = route.params
  const {productPrice} = productPriceDescriptions

  /**productQuantity */
  const {productQuantityDescriptions} = route.params
  const {productQuantity} = productQuantityDescriptions

  /**ProductCurrency */

  const {productCurrencyDescriptions} = route.params
  const {productCurrency} = productCurrencyDescriptions

  /**ProductDescriptions */
  const {productDescriptionsDescriptions} = route.params
  const {descriptionList} = productDescriptionsDescriptions

  /**productGalleryDescriptions */

  const {productGalleryDescriptions} = route.params
  const {productGallery} = productGalleryDescriptions


  /**States */
  const [deliveryOptions, setDeliveryOptions] = useState([])
  // const [countryId, setCountryId] = useState()
  /**User UID */
  const uid = getAuth().currentUser.uid;
  /**Query deliveryOption */
  const {loading: loadingDeliveryOptions, error: errorDeliveryOptions, data: dataDeliveryOptions, refetch} = useQuery(GET_COUNTRY_DELIVERY_OPTIONS, {variables:{uid, languageCode:langQuery}})
  /**Const color */
  const [arrayDeliveryOptions, setArrayDeliveryOptions] = useState([])
  const [arrayDeliveryOptionsId, setArrayDeliveryOptionsId] = useState([])

  useEffect(()=>{
    let newDeliveryOptions
    console.log("ERROR DELIVERY OPTIONS", errorDeliveryOptions)
   if(dataDeliveryOptions){
     const {user_locations} = dataDeliveryOptions
    //  const {country_delivery_options} = user_locations
      //  newDeliveryOptions   =  user_locations[0]['country']['country_delivery_options']
    //  console.log("Hey",  delivery_options.delivery_option_id);
     newDeliveryOptions = user_locations[0]['country']['country_delivery_options']

   }
   console.log("Product Descriptions", subCategoryId);
  //  console.log("Delivery Options ", newDeliveryOptions);
   setDeliveryOptions(newDeliveryOptions)

   refetch();
   
  })

  const pressColor = (index)=>{
    let newArrayDeliveryOptions = [...arrayDeliveryOptions]
    newArrayDeliveryOptions[index] = !newArrayDeliveryOptions[index]
    setArrayDeliveryOptions(newArrayDeliveryOptions)
    console.log("Array Delivery Options ", newArrayDeliveryOptions);

    if(newArrayDeliveryOptions[index]){
      let newArrayDeliveryOptionsId = [...arrayDeliveryOptionsId, {country_delivery_option_id: deliveryOptions[index].country_delivery_option_id}]
      // newArrayDeliveryOptionsId.push(deliveryOptions[index].delivery_option_id)
      setArrayDeliveryOptionsId(newArrayDeliveryOptionsId);
      console.log("Array Delivery Options Id ", newArrayDeliveryOptionsId);
    }else{
      let newArrayDeliveryOptionsId = [...arrayDeliveryOptionsId]
      newArrayDeliveryOptionsId.splice(index, 1)
      setArrayDeliveryOptionsId(newArrayDeliveryOptionsId)
      console.log("Array Delivery Options Id ", newArrayDeliveryOptionsId);
    }    
  }

  const Item = ({item, index}) =>{ 

   const BackgroundColor = arrayDeliveryOptions[index] ? {backgroundColor:'#64b5f6', marginTop:3, paddingVertical:10, borderRadius:6} : {backgroundColor:'#e0e0e0', marginTop:3, paddingVertical:10, borderRadius:6}

    return(
    <View>
      
      <TouchableOpacity key={index} style={BackgroundColor} onPress={()=>{
        pressColor(index);
}}>
        <Text key={index} style={{fontSize:16, fontWeight:'bold', textAlign:'center'}}>{item['delivery_option']['delivery_option_translations'][0].delivery_option_name}</Text>
      
      </TouchableOpacity>
      
    </View>
  );
    }
  const renderItem = ({item, index}) => <Item
    index={index}
    item={item}
    />;
  
    return(
        <SafeAreaView style={{flex:1, paddingVertical:-45}}>
        <View style={{paddingHorizontal:5, flex:1}}>
          <View>
      <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('products.addDeliveryOptions.title')}
            </Text>
            </View>
            <View style={{paddingVertical:5}}>
            <Text style={{fontSize:16}}>
              {i18n.t('products.addDeliveryOptions.body')}
            </Text>
        </View>
          </View>
        
        <View>
      

            {(deliveryOptions)?(
              <FlatList data={deliveryOptions} renderItem={renderItem} keyExtractor={item => {return item.delivery_option_id.toString()}}/>
          //  <VirtualizedList data={deliveryOptions} getItem={pressColor} getItemCount={getItemCount} renderItem={renderItem} keyExtractor={(item, index) => {item['delivery_option_id'][index]}}/>
           ):null}
        </View>
        
        <View style={{position:'absolute', bottom:50, width:Dimensions.get('window').width, paddingHorizontal:5
      }}>
      <TouchableOpacity
            style={{backgroundColor:'#FF6D00', borderRadius:6, marginTop:10, paddingVertical:15}}
            onPress={()=> {if(arrayDeliveryOptionsId[0]){
              navigation.navigate('AddPaymentOptions',{
                productSKUDelivery:{productSKU},
                productTitleDelivery:{productTitle},
                productSlugDelivery:{productSlug},
                productImageDelivery:{image},
                productPriceDelivery:{productPrice},
                productQuantityDelivery:{productQuantity},
                productCurrencyDelivery:{productCurrency},
                productDescriptionsDelivery:{descriptionList},
                DeliveryOptionsList:{arrayDeliveryOptionsId},
                subCategoryId,
                productGalleryDelivery:{productGallery}
              })
            }else{
              Alert.alert("Error", i18n.t('products.addDeliveryOptions.alert_body'))
            }}}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:16}}>
              {i18n.t('products.addDeliveryOptions.btn_next')}
            </Text>
          </TouchableOpacity>
        </View>
        </View>
    </SafeAreaView>
    )
    
}

export default AddDeliveryOption;