import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, Pressable, ImageBackground, Linking, TouchableWithoutFeedback, FlatList, Platform, Image, Dimensions, useWindowDimensions, Share, SectionList } from 'react-native';
import Firebase from '../lib/firebase';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_NEAR_PRODUCTS, GET_ORGANIZATION_BY_ID, GET_ORGANIZATION_PRODUCTS_BY_USERS, GET_ORGANIZATION_PRODUCTS_BY_USERS_CATEGORIES, GET_USER_PERMISSION_BY_ORGANIZATION } from '../lib/queries';
import * as Location from 'expo-location';
import i18n from '../i18n/i18n';
import { useFonts } from 'expo-font';
// import * as Linking from 'expo-linking'
// import FastImage from 'react-native-fast-image';
import { Link, useIsFocused } from '@react-navigation/native';
import { getAuth, signInWithEmailAndPassword, FacebookAuthProvider, signInWithCredential } from 'firebase/auth';
import { Feather } from '@expo/vector-icons';
import openMap, { createMapLink } from 'react-native-open-maps';
import * as Clipboard from 'expo-clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Ionicons from '@expo/vector-icons/Ionicons';
import QRCode from 'react-native-qrcode-svg';
import Modal from 'react-native-modal'
import RNQRGenerator from 'rn-qr-generator';
import AdSense from 'react-adsense';
import * as ImagePicker from 'expo-image-picker';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import * as AppleAuthentication from 'expo-apple-authentication';
import { useTogglePasswordVisibility } from '../hooks/useTogglePasswordVisibility';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { makeRedirectUri, ResponseType } from 'expo-auth-session';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as Sharing from 'expo-sharing';
import { Helmet } from 'react-helmet-async';

const auth = getAuth(Firebase);
const functions = getFunctions(Firebase);

async function getLocation() {
  const { status } = await Location.requestForegroundPermissionsAsync();
  if (status !== 'granted') {
    // console.log('Permission to access location was denied');
  }
  const location = await Location.getCurrentPositionAsync({});
  // console.log("location", location);
  return location;
}

const OrganizationPage = ({ navigation, route }) => {
  const [fontsLoaded] = useFonts({
    'Inter-Black': require('../assets/fonts/Roboto-Black.ttf'),
    'Inter-BlackItalic': require('../assets/fonts/Roboto-BlackItalic.ttf'),
    'Inter-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
    'Inter-BoldItalic': require('../assets/fonts/Roboto-BoldItalic.ttf'),
    'Inter-Italic': require('../assets/fonts/Roboto-Italic.ttf'),
    'Inter-Light': require('../assets/fonts/Roboto-Light.ttf'),
    'Inter-LightItalic': require('../assets/fonts/Roboto-LightItalic.ttf'),
    'Inter-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
    'Inter-MediumItalic': require('../assets/fonts/Roboto-MediumItalic.ttf'),
    'Inter-Regular': require('../assets/fonts/Roboto-Regular.ttf'),
    'Inter-Thin': require('../assets/fonts/Roboto-Thin.ttf'),
    'Inter-ThinItalic': require('../assets/fonts/Roboto-ThinItalic.ttf'),
  });
  // console.log(getLocation());

  const { latitude } = route.params;
  const { distance } = route.params;
  const { longitude } = route.params;
  const { organizationId } = route.params;

  parseFloat(latitude);
  parseFloat(longitude);
  parseFloat(distance);

  const jsonGeography = {
    "type": "Point",
    "coordinates": [longitude, latitude]
  }
  console.log("jsonGeography", jsonGeography);
  console.log("organizationId", organizationId);
  console.log("distance", distance);
  console.log("latitude", latitude);
  console.log("longitude", longitude);
  /**Near products */
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  const [userLevel, setUserLevel] = useState(0);
  const [nearProducts, setNearProducts] = useState([]);
  const [uid, setUid] = useState('');
  const [user, setUser] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [organizationId, setOrganizationId] = useState();
  const [organizationName, setOrganizationName] = useState('');
  const [organizationEmail, setOrganizationEmail] = useState('');
  const [organizationPhone, setOrganizationPhone] = useState('');
  const [organizationWhatsapp, setOrganizationWhatsapp] = useState('');
  const [organizationCountryCode, setOrganizationCountryCode] = useState('');
  const [organizationLogo, setOrganizationLogo] = useState('https://firebasestorage.googleapis.com/v0/b/menucitos-328501.appspot.com/o/organization%2Fresized%2Fgray-image-placeholder_500x500.png?alt=media&token=c0c69b74-d0d4-4b81-8d7c-573200493470');
  const [organizationThumbnail, setOrganizationThumbnail] = useState('https://firebasestorage.googleapis.com/v0/b/menucitos-328501.appspot.com/o/organization%2Fresized%2Fgray-image-placeholder_500x500.png?alt=media&token=c0c69b74-d0d4-4b81-8d7c-573200493470');
  const [organizationLocation, setOrganizationLocation] = useState('');
  const [organizationDescription, setOrganizationDescription] = useState('');
  const [ownerPhotoThumbnail, setOwnerPhotoThumbnail] = useState('https://firebasestorage.googleapis.com/v0/b/menucitos-328501.appspot.com/o/organization%2Fresized%2Fgray-image-placeholder_500x500.png?alt=media&token=c0c69b74-d0d4-4b81-8d7c-573200493470');
  const [ownerUid, setOwnerUid] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [ownerLastName, setOwnerLastName] = useState('');
  const [distanceState, setDistanceState] = useState(distance);
  const [cartVisibility, setCartVisibility] = useState(false);
  const [modalQR, setModalQR] = useState(false);
  const [shortURL, setShortURL] = useState('');

  const isFocused = useIsFocused();

  const QRDataUrl = useRef(null);


  const [modalLoginVisible, setModalLoginVisible] = useState(false);

  const [searchText, setSearchText] = useState({});
  const { passwordVisibility, rightIcon, handlePasswordVisibility } = useTogglePasswordVisibility()
  /**Cart */
  const [cart, setCart] = useState([]);

  /**Firebase */
  // const uid = getAuth().currentUser.uid;


  const { loading: userPermissionByOrganization, data: userPermissionByOrganizationData, refetch: refetchUserPermissionByOrganization } = useQuery(GET_USER_PERMISSION_BY_ORGANIZATION, { variables: { organizationId: organizationId, uid: uid } });
  const { loading: loadingOrganization, error: errorOrganization, data: dataOrganization, refetch } = useQuery(GET_ORGANIZATION_BY_ID, { variables: { organizationId } });
  // const {loading:loadingNearProductsByUser, error:errorNearProductsByUser, data:dataNearProductsByUser, refetch:refetchNearProductsByUser} = useQuery(GET_ORGANIZATION_PRODUCTS_BY_USERS, {variables:{organizationId: organizationId, point: jsonGeography, distance: distance,uid, query: searchText}});
  const { loading: loadingNearProductsByCategory, error: errorNearProductsByCategory, data: dataNearProductsByCategory, refetch: refetchNearProductsByCategory } = useQuery(GET_ORGANIZATION_PRODUCTS_BY_USERS_CATEGORIES, {
    variables: {
      organizationId: organizationId,
      productDescriptionsFilter: searchText
    }
  });
  async function getOrganization() {
    // console.log("errorProducts", errorNearProductsByUser);
    console.log('errorOrganization', errorOrganization);
    console.log("errorNearProductsByCategory", errorNearProductsByCategory);
    if (dataOrganization) {
      setDistanceState(distance);
      // console.log('dataOrganization', dataOrganization);
      const { organization_by_pk } = dataOrganization;
      setOrganizationName(organization_by_pk.organization_name);
      setOrganizationEmail(organization_by_pk.organization_email);
      setOrganizationPhone(organization_by_pk.organization_phone);
      setOrganizationCountryCode(organization_by_pk.country.country_phone_code);
      setOrganizationLogo(organization_by_pk.organization_logo);
      setOrganizationThumbnail(organization_by_pk.organization_thumbnail);
      setOrganizationLocation(organization_by_pk.organization_location.coordinates);
      setOrganizationDescription(organization_by_pk.organization_description);
      setOrganizationWhatsapp(organization_by_pk.organization_whatsapp);
      setOwnerPhotoThumbnail(organization_by_pk.user.user_thumbnail);
      setOwnerName(organization_by_pk.user.user_name);
      setOwnerLastName(organization_by_pk.user.user_lastname);
      setOwnerUid(organization_by_pk.user.uid);
    }
    console.log("Error", errorNearProductsByCategory);
    if (dataNearProductsByCategory) {
      const { user_categories } = dataNearProductsByCategory;
      console.log('user_categories', JSON.stringify(user_categories));
      setNearProducts(dataNearProductsByCategory.user_categories);
    }

  }

  async function getUserPermissions() {
    if (userPermissionByOrganizationData) {
      const { organization_users } = userPermissionByOrganizationData;
      // console.log("organization_users", organization_users);

      if (organization_users.length > 0) {
        setUserLevel(organization_users[0].organization_role_id);
      }

    }
  }

  async function getCart() {
    const shopping = AsyncStorage.getItem('orderProducts').then(res => {
      if (res) {
        const cart = JSON.parse(res);
        setCart(cart);
        // console.log('cart', cart);
      }
    }).catch(err => { });
  }

  /**Facebook result */
  const [request, response, promptAsync] = Facebook.useAuthRequest({
    ResponseType: ResponseType.Token,
    clientId: '308615167097974',
    scopes: ['public_profile', 'email'],
  }, {
    useProxy: false,
  });

  useEffect(() => {
    // console.log("route.params", route);
    getAuth().onAuthStateChanged(function (user) {
      if (user) {
        setUid(user.uid);
        setUser(user);
        getOrganization();
        getCart();
        getUserPermissions();
      } else {
        setUid("");
        setUser("");
        getOrganization();
        getCart();
        getUserPermissions();
      }
    });

    (async () => {
      // setSelectedLanguage(language);

      if (response?.type === 'success') {
        const { access_token } = response.params;
        console.log("ACCESS TOKEN", response.params)
        // login with facebook using cloud function createuserfaceobok
        // const credential = new FacebookAuthProvider.credential(access_token);
        // const credential = provider.credential(access_token);
        // Sign in with the credential from the Facebook user.
        // console.log("CREDENTIAL", credential)

        const credential = FacebookAuthProvider.credential(access_token);

        //signInWithCredential(auth, credential) and then wait for custom claims to be set
        await signInWithCredential(auth, credential).catch((error) => {
          console.log("ERROR", error)
          setModalLoginVisible(false);
        });
      }
      //request

      if (request) {
        // console.log("REQUEST", request)
      }
    })();

    if (isFocused) {
      refetch();
      // refetchNearProductsByUser();
      refetchUserPermissionByOrganization();
      refetchNearProductsByCategory();
      getOrganization();
      getCart();
      getUserPermissions();
    }
  }, [isFocused, distance, searchText, dataOrganization, dataNearProductsByCategory, userPermissionByOrganizationData, nearProducts, response]);

  let screenWidth;
  let numColumns;
  let itemWidth;
  let logoDivide;

  const itemHeight = Dimensions.get('window').width * 0.50;

  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    screenWidth = Dimensions.get('window').width;
    numColumns = 3;
    itemWidth = screenWidth / numColumns;
    logoDivide = 1;
  } else if (Platform.OS === 'web') {
    screenWidth = useWindowDimensions().width;
    if (screenWidth > 1200) {
      numColumns = 8;
      itemWidth = screenWidth / numColumns;
      logoDivide = 2;
    } else if (screenWidth > 768 && screenWidth <= 1200) {
      numColumns = 5;
      itemWidth = screenWidth / numColumns;
      logoDivide = 2;
    }
    else {
      numColumns = 3;
      itemWidth = screenWidth / numColumns;
      logoDivide = 1;
    }
  }

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password).then(() => {
        setModalVisibility(false)
      });
    }
    catch (error) {
      console.log("Error LOGIN: ", error);
      if (error.code === 'auth/wrong-password') {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.wrong_password'), i18n.t('login.wrong_password_message'));
        } else if (Platform.OS === 'web') {
          // alert(i18n.t('login.wrong_password'), i18n.t('login.wrong_password_message'));
          setModalVisibility(true)
        }
      } else if (error.code === 'auth/too-many-requests') {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.too_many_attempts'), i18n.t('login.too_many_attempts_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityAttempts(true)
        }
      } else if (error.code === 'auth/user-not-found') {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.email_not_found'), i18n.t('login.email_not_found_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityUserNotFound(true)
        }
      } else if (error.code === "auth/network-request-failed") {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.network_error'), i18n.t('login.network_error_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityNetworkError(true)
        }
      } else if (error.code === "auth/invalid-email") {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.invalid_email'), i18n.t('login.invalid_email_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityInvalidEmail(true)
        }
      } else if (error.code === "(auth/user-not-found)") {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.user_not_found'), i18n.t('login.user_not_found_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityUserNotFound(true)
        }
      }
    }
  }

  const handleSignup = async () => {

    try {
      {/* You should ideally do some sort of validation before this
      Think password length and so on */}

      /**Call firebase function */

      const registerUser = httpsCallable(functions, 'registerUser');
      await registerUser({ email, password });

      // console.log("Result: ", result);

      //Log the user in
      await signInWithEmailAndPassword(auth, email, password);

    } catch (error) {
      // console.log(error);
      /**Manage errors firebase signinWithEmail */
      Alert.alert('Error', error.message);
      if (error.message === 'The email address is badly formatted.') {
        Alert.alert(i18n.t('signup.email_invalid'), i18n.t('signup.email_invalid_body'));
        if (Platform.OS === 'web') {
          setMessageTitle(i18n.t('recover.email_invalid'));
          setMessageBody(i18n.t('recover.email_invalid_body'));
          setModalVisibilityAlert(true);
        }
      } else if (error.message === 'The email address is already in use by another account.') {
        Alert.alert(i18n.t('recover.error_email_in_use'), i18n.t('recover.error_email_in_use_body'));
        if (Platform.OS === 'web') {
          setMessageTitle(i18n.t('recover.error_email_in_use'));
          setMessageBody(i18n.t('recover.error_email_in_use_body'));
          setModalVisibilityAlert(true);
        }
      } else if (error.message === 'The password must be 6 characters long or more.') {
        Alert.alert(i18n.t('signup.password_too_short'), i18n.t('signup.password_too_short'));
        if (Platform.OS === 'web') {
          setMessageTitle(i18n.t('signup.password_too_short'));
          setMessageBody(i18n.t('signup.password_too_short'));
          setModalVisibilityAlert(true);
        }
      }
    }
  };

  const handleFacebookLogin = async () => {
    console.log("Facebook Login");

    if (Platform.OS === 'ios' || Platform.OS === 'android' || Platform.OS === 'web') {
      await promptAsync();
    }
  }

  function Header() {
    return (
      <View style={{ flex: 1 }}>
        <Image style={{ height: Platform.OS === 'web' ? itemHeight / logoDivide : itemHeight, width: Platform.OS === 'web' ? screenWidth / logoDivide : screenWidth, justifyContent: 'center', alignItems: 'center', alignSelf: 'center', borderBottomRightRadius: 15, borderBottomLeftRadius: 15 }} source={{ uri: organizationLogo ? organizationLogo : '' }}>

        </Image>
        {(userLevel === 1) && (
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginTop: 10 }}>
            <TouchableOpacity onPress={() => navigation.navigate('EditOrganizationPage', { organizationId: organizationId })} style={{ backgroundColor: '#F2F2F2', padding: 10, borderRadius: 10, marginRight: 10 }}>
              <Text style={{ color: '#000', fontSize: 16, fontWeight: 'bold' }}>
                {i18n.t('home.organizationPage.edit_page')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('OrganizationProducts', { organizationId: organizationId })} style={{ backgroundColor: '#F2F2F2', padding: 10, borderRadius: 10, marginRight: 10 }}>
              <Text style={{ color: '#000', fontSize: 16, fontWeight: 'bold' }}>
                {i18n.t('home.organizationPage.edit_products')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigation.navigate('EditOrganizationUsers', { organizationId: organizationId })} style={{ backgroundColor: '#F2F2F2', padding: 10, borderRadius: 10, marginRight: 10 }}>
              <Text style={{ color: '#000', fontSize: 16, fontWeight: 'bold' }}>
                {i18n.t('home.organizationPage.edit_users')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <View style={{ borderRadius: 6 }}>
          <Text style={{ fontSize: 32, paddingHorizontal: 10, fontFamily: 'Inter-Black' }}>{organizationName}</Text>
          <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            {/* <Text style={{fontWeight:'bold', fontSize:22, paddingLeft:15}}>{i18n.t('home.organizationPage.email')}</Text> */}
            <Feather name="mail" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
            <TouchableOpacity onPress={() => {
              Linking.openURL(`mailto:${organizationEmail}`)
            }}>
              <Text style={{ fontSize: 16, paddingLeft: 10, color: '#FF6D00', fontFamily: 'Inter-Medium', letterSpacing: 0.5 }}>{organizationEmail}
              </Text>
            </TouchableOpacity>
          </View>
          <View>


          </View>
          <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            {/* <Text style={{fontWeight:'bold', fontSize:22, paddingLeft:15}}>{i18n.t('home.organizationPage.phone')}</Text> */}
            <Feather name="phone" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(`tel:+${organizationCountryCode}${organizationPhone}`)
              }}
            >
              <Text style={{ fontSize: 16, paddingLeft: 10, color: '#FF6D00', fontFamily: 'Inter-Medium', letterSpacing: 0.5 }}>+{organizationCountryCode} {organizationPhone}</Text>
            </TouchableOpacity>
          </View>
          <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            {/* <Text style={{fontWeight:'bold', fontSize:22, paddingLeft:15}}>{i18n.t('home.organizationPage.phone')}</Text> */}
            {/* <Feather name="phone" size={22} color="#78909C" style={{paddingLeft:15}} /> */}
            <Feather name="map-pin" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
            <TouchableOpacity
              onPress={() => {
                // openMap({latitude: organizationLocation[1], longitude: organizationLocation[0]});
                const locationString = `${organizationLocation[1]},${organizationLocation[0]}`;
                // console.log('locationString', locationString);
                const mapLink = createMapLink({ query: locationString, provider: Platform.OS === 'ios' ? 'apple' : 'google' });
                Linking.openURL(mapLink);
              }}
            >
              <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Medium', letterSpacing: 0.5, color: '#FF6D00' }}>{i18n.t('home.organizationPage.open_in_maps')}</Text>
            </TouchableOpacity>
          </View>

          <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
            {/* <Text style={{fontWeight:'bold', fontSize:22, paddingLeft:15}}>{i18n.t('home.organizationPage.phone')}</Text> */}
            {/* <Feather name="phone" size={22} color="#78909C" style={{paddingLeft:15}} /> */}
            <Ionicons name='logo-whatsapp' size={22} color="#78909C" style={{ paddingLeft: 15 }} />
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(`https://wa.me/${organizationCountryCode}${organizationWhatsapp}`)
              }}
            >
              <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Medium', letterSpacing: 0.5, color: '#FF6D00' }}>+{organizationCountryCode} {organizationWhatsapp}</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
          {/* <Text style={{fontWeight:'bold', fontSize:22, paddingLeft:15}}>{i18n.t('home.organizationPage.phone')}</Text> */}
          {/* <Feather name="phone" size={22} color="#78909C" style={{paddingLeft:15}} /> */}
          <Feather name="link-2" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
          <TouchableOpacity
            onPress={async () => {
              // await Clipboard.setString('https://www.menucitos.com/drawer/home/navigation/organization/'+ organizationId +'?longitude='+longitude+'&latitude='+latitude+ '&distance='+distanceState);
              try {
                // const payload = {
                //   dynamicLinkInfo: {
                //     domainUriPrefix: 'https://menucitos.page.link',
                //     link: 'https://menucitos.com/drawer/home/navigation/organization/' + organizationId + '?longitude=' + longitude + '&latitude=' + latitude + '&distance=' + distanceState + '&organizationId=' + organizationId,
                //     androidInfo: {
                //       androidPackageName: 'com.nelson_arguera.menu',

                //     },
                //     iosInfo: {
                //       iosBundleId: 'com.nelsonarguera.menu',
                //       iosAppStoreId: 'id1643431406',
                //     },
                //     socialMetaTagInfo: {
                //       socialTitle: organizationName,
                //       socialDescription: organizationDescription,
                //       socialImageLink: organizationThumbnail,
                //     }
                //   }
                // };

                let payload;
                if (Platform.OS === 'web') {
                  payload = {
                    dynamicLinkInfo: {
                      domainUriPrefix: 'https://menucitos.page.link',
                      link: 'https://menucitos.com/drawer/home/navigation/organization/' + organizationId + '?longitude=' + longitude + '&latitude=' + latitude + '&distance=' + distanceState + '&organizationId=' + organizationId,
                      socialMetaTagInfo: {
                        socialTitle: organizationName,
                        socialDescription: organizationDescription,
                        socialImageLink: organizationThumbnail,
                      },
                      navigationInfo: {
                        enableForcedRedirect: true,
                      }
                    }
                  };
                } else {
                  payload = {
                      dynamicLinkInfo: {
                        domainUriPrefix: 'https://menucitos.page.link',
                        link: 'https://menucitos.com/drawer/home/navigation/organization/' + organizationId + '?longitude=' + longitude + '&latitude=' + latitude + '&distance=' + distanceState + '&organizationId=' + organizationId,
                        androidInfo: {
                          androidPackageName: 'com.nelson_arguera.menu',

                        },
                        iosInfo: {
                          iosBundleId: 'com.nelsonarguera.menu',
                          iosAppStoreId: 'id1643431406',
                        },
                        socialMetaTagInfo: {
                          socialTitle: organizationName,
                          socialDescription: organizationDescription,
                          socialImageLink: organizationThumbnail,
                        }
                      }
                  };
                }

                let apiKey = "AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
                // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
                const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
                let json;
                let shortLink;
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(payload),
                }).then(res => {
                  res.json().then(async (data) => {
                    console.log(data);
                    json = data;
                    const result = await Share.share({
                      message: json.shortLink,
                      url: json.shortLink,
                      title: 'Share via',
                    });
                  })
                }).catch(err => { console.log("WHAT", JSON.stringify(err)) });

              } catch (err) {
                console.log('err', err);
              }
            }}
          >
            <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Medium', letterSpacing: 0.5, color: '#FF6D00' }}>{i18n.t('home.organizationPage.copy_page_link')}</Text>
          </TouchableOpacity>

        </View>



        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
          {/* <Text style={{fontWeight:'bold', fontSize:22, paddingLeft:15}}>{i18n.t('home.organizationPage.phone')}</Text> */}
          {/* <Feather name="phone" size={22} color="#78909C" style={{paddingLeft:15}} /> */}
          <Feather name="globe" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
          <TouchableOpacity
            onPress={() => {
              Linking.openURL("https://menucitos.com/drawer/home/navigation/organization/" + organizationId + "?longitude=" + longitude + "&latitude=" + latitude + "&distance=" + distanceState + "&organizationId=" + organizationId)
            }}
          >
            <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Medium', letterSpacing: 0.5, color: '#FF6D00' }}>
              {i18n.t('home.organizationPage.open_in_browser')}
            </Text>
          </TouchableOpacity>
        </View>



        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', flexShrink: 1 }}>
          <Feather name="info" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
          <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Regular', letterSpacing: 0.1, color: '#000000', flexShrink: 1 }}>{organizationDescription}</Text>
        </View>
        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
          {/*generate QR code  */}
          {/* <Feather name="" size={22} color="#78909C" style={{paddingLeft:15}} /> */}
          <Ionicons name="qr-code-outline" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
          <TouchableOpacity
            onPress={async () => {
              try {
                const payload = {
                  dynamicLinkInfo: {
                    domainUriPrefix: 'https://menucitos.page.link',
                    link: 'https://menucitos.com/drawer/home/navigation/organization/' + organizationId + '?longitude=' + longitude + '&latitude=' + latitude + '&distance=' + distanceState,
                    androidInfo: {
                      androidPackageName: 'com.nelson_arguera.menu',

                    },
                    iosInfo: {
                      iosBundleId: 'com.nelsonarguera.menu',
                      iosAppStoreId: 'id1643431406',
                    },
                    socialMetaTagInfo: {
                      socialTitle: organizationName,
                      socialDescription: organizationDescription,
                      socialImageLink: organizationThumbnail,
                    }
                  }
                };
                let apiKey = "AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
                // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
                const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
                let json;
                let shortLink;
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(payload),
                }).then(res => {
                  res.json().then(async (data) => {
                    // console.log(data);
                    json = data;
                    setShortURL(json.shortLink);
                    setModalQR(true);

                    // const result = await Share.share({
                    // message: json.shortLink,
                    // url: json.shortLink,
                    // title: 'Share via',
                    // });
                  })
                }).catch(err => { console.log("WHAT", JSON.stringify(err)) });

              } catch (err) {
                console.log('err', err);
              }
            }}
          >
            <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Regular', color: '#FF6D00' }}>{i18n.t('home.organizationPage.generate_qr_code_app')}</Text>
          </TouchableOpacity>
        </View>
        {/* {generate QR Code web} */}
        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
          {/* <Feather name="" size={22} color="#78909C" style={{paddingLeft:15}} /> */}
          <Ionicons name="qr-code-outline" size={22} color="#78909C" style={{ paddingLeft: 15 }} />
          <TouchableOpacity
            onPress={() => {
              setShortURL("https://menucitos.com/drawer/home/navigation/organization/" + organizationId + "?longitude=" + longitude + "&latitude=" + latitude + "&distance=" + distanceState + "&organizationId=" + organizationId);
              setModalQR(true);
            }}
          >
            <Text style={{ fontSize: 16, paddingLeft: 10, fontFamily: 'Inter-Regular', color: '#FF6D00' }}>{i18n.t('home.organizationPage.generate_qr_code_web')}</Text>
          </TouchableOpacity>
        </View>



        <TouchableOpacity style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}
          onPress={() => {
            navigation.navigate('PublicProfile', {
              user_id: ownerUid,
            })
          }}
        >
          <Image style={{ height: 30, width: 30, borderRadius: 30, marginLeft: 10, marginRight: 10 }} source={{ uri: ownerPhotoThumbnail }} />
          <Text style={{ fontSize: 16, paddingLeft: 5, fontFamily: 'Inter-Medium', color: '#FF6D00' }}>{i18n.t('home.organizationPage.owner')}:</Text>
          <Text style={{ fontSize: 16, paddingLeft: 5, fontFamily: 'Inter-Regular', color: '#000000', letterSpacing: -0.5 }}>{ownerName} {ownerLastName}</Text>
        </TouchableOpacity>

        {(Platform.OS === 'web') ? (
          <>
            <TouchableOpacity style={{ backgroundColor: '#3D5AFE', borderRadius: 5, padding: 5, marginHorizontal: 10, marginTop: 10, paddingVertical:8 }}
              onPress={async () => {
                // await Clipboard.setString('https://www.menucitos.com/drawer/home/navigation/organization/'+ organizationId +'?longitude='+longitude+'&latitude='+latitude+ '&distance='+distanceState);
                try {
                  const payload = {
                    dynamicLinkInfo: {
                      domainUriPrefix: 'https://menucitos.page.link',
                      link: 'https://menucitos.com/drawer/home/navigation/organization/' + organizationId + '?longitude=' + longitude + '&latitude=' + latitude + '&distance=' + distanceState + '&organizationId=' + organizationId,
                      androidInfo: {
                        androidPackageName: 'com.nelson_arguera.menu',

                      },
                      iosInfo: {
                        iosBundleId: 'com.nelsonarguera.menu',
                        iosAppStoreId: 'id1643431406',
                      },
                      socialMetaTagInfo: {
                        socialTitle: organizationName,
                        socialDescription: organizationDescription,
                        socialImageLink: organizationThumbnail,
                      }
                    }
                  };

                  let apiKey = "AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
                  // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
                  const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
                  let json;
                  let shortLink;
                  const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                  }).then(res => {
                    res.json().then(async (data) => {
                      // console.log(data);
                      json = data;
                      // const result = await Share.share({
                      //   message: json.shortLink,
                      //   url: json.shortLink,
                      //   title: 'Share via',
                      // });
                      Linking.openURL(json.shortLink);

                    })
                  }).catch(err => { console.log("WHAT", JSON.stringify(err)) });



                }
                catch (err) {
                  console.log('err', err);
                }
              }}
            >
              <Text style={{ fontSize: 20, paddingLeft: 10, fontFamily: 'Inter-Regular', letterSpacing: 0.5, color: '#FFFFFF' }}>
                {i18n.t('home.organizationPage.order_in_app')}
              </Text>

            </TouchableOpacity>

          </>
        ) : null}
        <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
          {/* <QRCode value='https://google.com' size={100} /> */}
        </View>
        <View style={{ alignContent: 'center', alignItems: 'center' }}>
          <TextInput
            style={{ height: 40, width: screenWidth - 20, borderRadius: 10, backgroundColor: '#e0e0e0', paddingHorizontal: 10, fontFamily: 'Inter-Regular', fontSize: 16, marginTop: 10 }}
            placeholder={i18n.t('home.search')}
            onChangeText={text => setSearchText(
              text ? {
                "_or": [
                  {
                    "product_description": {
                      "_ilike": "%" + text + "%"
                    }
                  },
                  {
                    "product_title": {
                      "_ilike": "%" + text + "%"
                    }

                  }
                ]
              } : {}
            )}
          />
        </View>
        {(Platform.OS === 'web') ? (
          <>
            <AdSense.Google
              client='ca-pub-4925893888940052'
              slot='1855688014'
              style={{
                width: screenWidth,
                height: 100,
              }}
              format=''
            >
            </AdSense.Google>
          </>
        ) : null}
        <Text style={{ fontFamily: 'Inter-Regular', fontSize: 18, color: '#302f2d', paddingTop: 10, paddingHorizontal: 2 }}>{i18n.t('home.products_near_you')}</Text>
      </View>

    )
  }
  const NearProducts = ({ item, product_id, product_photo, product_title, product_currency, product_price, seller_id, product_thumbnail }) => (
    <View style={{ width: itemWidth - 5, justifyContent: 'space-between', backgroundColor: '#ffff', borderRadius: 10, marginHorizontal: 2.50, marginBottom: 5 }}>
      {(Platform.OS != 'web') ? (
        <>
          <TouchableOpacity onPress={() => {
            // console.log(product_price)
            navigation.push("ProductDetailed", {
              productId: product_id,
              sellerId: seller_id,
              organizationId,
              distanceCart: distance,
              latitude: latitude,
              longitude: longitude,
              organization_id: organizationId,
            })
          }}>
            <Image style={{ height: itemWidth - 5, width: itemWidth - 5, borderRadius: 10 }} source={{ uri: product_thumbnail }} />
            {/* <FastImage style={{height:itemWidth, width:itemWidth-2, borderRadius:6}} source={{uri:product_photo}}/> */}
          </TouchableOpacity>

          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 15 : 18, textAlign: 'left', fontFamily: 'Inter-Regular' }}>{
              // product_title.length > 16 ? product_title.substring(0,15)+"..." : product_title
              product_title
            }</Text>

          </View>
          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 18, textAlign: 'left', textAlignVertical: 'bottom', fontFamily: 'Inter-Bold', alignSelf: 'flex-end' }}> {product_price} {product_currency} </Text>
        </>
      ) : (
        <>
          <View style={{}}>
            <Image style={{ height: itemWidth - 5, width: itemWidth - 5, borderRadius: 10 }} source={{ uri: product_thumbnail }} />

            <View style={{ flexDirection: 'row' }}>
              <Link to={{
                screen: "Drawer",
                params: {
                  screen: "Home",
                  params: {
                    screen: "HomeTab",
                    params: {
                      screen: "ProductDetailed",
                      params: {
                        productId: product_id,
                        sellerId: seller_id,
                        distanceCart: distance,
                        latitude: latitude,
                        longitude: longitude,
                        organization_id: organizationId,
                      }
                    }
                  }
                }
              }}>
                <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 15 : 18, textAlign: 'left', fontFamily: 'Inter-Regular', textDecorationLine: 'underline' }}>{
                  // product_title.length > 16 ? product_title.substring(0,15)+"..." : product_title
                  product_title
                }</Text>
              </Link>
            </View>
            <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 18, textAlign: 'left', textAlignVertical: 'bottom', fontFamily: 'Inter-Bold', alignSelf: 'flex-end' }}> {product_price} {product_currency} </Text>
          </View>
        </>
      )}
    </View>
  )

  const renderItem = ({ item }) => {
    return (
      <NearProducts item={item} product_photo={item.user_product.product.product_photo_portrait}
        product_title={item.user_product.product.product_translations[0].product_title}
        product_currency={item.user_product.product.product_currencies[0].currency_code}
        product_price={item.user_product.product.product_currencies[0].product_price}
        product_id={item.user_product.product.product_id}
        seller_id={item.user_product.user.uid}
        product_thumbnail={item.user_product.product.product_thumbnail}
      />
    )
  }

  function cartHeader() {
    return (
      <View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Text style={{ fontSize: 32, fontFamily: 'Inter-Black', paddingTop: 10 }}>
            {i18n.t('home.organizationPage.shopping_cart')} {organizationName}
          </Text>
          <TouchableOpacity onPress={() => {
            setCartVisibility(false)
          }}>
            <Feather name="x" size={22} color="#78909C" />
          </TouchableOpacity>
        </View>
        <Text style={{ fontSize: 18, }}>{i18n.t('home.organizationPage.product_list')}</Text>
      </View>

    )
  }

  const getDataUrl = () => {
    QRDataUrl?.toDataUrl().then((dataUrl) => {
      // setQRDataUrl(dataUrl)
    })
  }


  if (dataNearProductsByCategory, loadingOrganization) return (
    <SafeAreaView style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      {(Platform.OS === 'web') ? (
        <>
          <Helmet>
            <title>{organizationName}</title>
            <meta name="description" content={organizationDescription} />
            <meta name="keywords" content={organizationName} />
            <meta name="author" content={organizationName} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="theme-color" content="#FF6D00" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@menucitos" />
            <meta name="twitter:title" content={organizationName} />
            <meta name="twitter:description" content={organizationDescription} />
            <meta name="twitter:image" content={organizationThumbnail} />
            <meta property="og:title" content={organizationName} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://menucitos.com/drawer/home/navigation/organization/${organizationId}`} />
            <meta property="og:image" content={organizationThumbnail} />
            <meta property="og:description" content={organizationDescription} />
            <meta property="og:site_name" content={organizationName} />
            <meta property="fb:app_id" content="your fb app id" />
            <link rel="canonical" href={`https://menucitos.com/drawer/home/navigation/organization/${organizationId}`} />
          </Helmet>
        </>
      ) : null}
      <ActivityIndicator color='#FF6D00' size={'large'} />
    </SafeAreaView>
  )
  return (
    <SafeAreaView style={{ flex: 1 }}>
      {/* react helmet */}

      {(Platform.OS === 'web') ? (
        <>
          <Helmet>
            <title>{organizationName}</title>
            <meta name="description" content={organizationDescription} />
            <meta name="keywords" content={organizationName} />
            <meta name="author" content={organizationName} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="theme-color" content="#FF6D00" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@menucitos" />
            <meta name="twitter:title" content={organizationName} />
            <meta name="twitter:description" content={organizationDescription} />
            <meta name="twitter:image" content={organizationThumbnail} />
            <meta property="og:title" content={organizationName} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://menucitos.com/drawer/home/navigation/organization/${organizationId}`} />
            <meta property="og:image" content={organizationThumbnail} />
            <meta property="og:description" content={organizationDescription} />
            <meta property="og:site_name" content={organizationName} />
            <meta property="fb:app_id" content="your fb app id" />
            <link rel="canonical" href={`https://menucitos.com/drawer/home/navigation/organization/${organizationId}`} />
          </Helmet>
        </>
      ) : null}


      <Modal isVisible={modalLoginVisible}
        // hasBackdrop={true}
        backdropColor={'rgba(0,0,0,0.5)'}
        // backdropColor='black'
        backdropOpacity={75}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        onBackdropPress={() => {
          setModalLoginVisible(false)
        }}
        onRequestClose={() => setModalLoginVisible(false)}
      >
        {/* <View style={{flex:1,justifyContent:'center', alignItems:'center'}}> */}
        <View
          style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: 6, margin: 5, padding: 5, width: screenWidth - 20, maxWidth: 700 }}
        >
          <Text style={{ fontSize: 18, fontFamily: 'Inter-Black', color: 'black' }}>{i18n.t('home.login')}</Text>
          {/* <View style={{ flex: 1 }}> */}
          <Text style={{ paddingHorizontal: 15, fontSize: 16, fontFamily: 'Inter-Black' }}>{i18n.t('login.email')}</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <TextInput style={{
              paddingHorizontal: 12, backgroundColor: '#e0e0e0', paddingVertical: 5, flexGrow: 1,
              marginHorizontal: 15, fontSize: 18, borderRadius: 10, fontFamily: 'Inter-Regular'
            }}
              onChangeText={(val) => setEmail(val.trim())}
              placeholder='carlos@gmail.com'
              autoCapitalize='none'
              textContentType='emailAddress'
              keyboardType='email-address'
              autoCorrect={false}
              autoComplete='email'
            />
          </View>
          <Text style={{ paddingHorizontal: 15, fontSize: 16, fontFamily: 'Inter-Black' }}>{i18n.t('login.password')}</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <TextInput style={{
              paddingHorizontal: 12, backgroundColor: '#e0e0e0', paddingVertical: 5, flexGrow: 1,
              marginHorizontal: 15, fontSize: 18, borderRadius: 10, fontFamily: 'Inter-Regular'
            }}
              placeholder={i18n.t('login.password')}
              onChangeText={(val) => setPassword(val)}
              secureTextEntry={passwordVisibility}
            />
            <Pressable onPress={handlePasswordVisibility} style={{ position: 'absolute', alignSelf: 'center', right: 25 }}>
              <Feather name={rightIcon} size={22} ></Feather>
            </Pressable>
          </View>

          <View style={{ marginTop: 5, flexDirection: 'row', justifyContent: 'center' }}>
            <Text style={{ fontSize: 16 }}>{i18n.t('login.not_registered')}</Text>
            <TouchableOpacity
              style={{ marginLeft: 5 }}
              onPress={() => navigation.navigate('Signup')}
            >
              <Text style={{ fontSize: 16, fontFamily: 'Inter-Black', color: '#FF6D00' }}>
                {i18n.t('login.btn_signup')}
              </Text>
            </TouchableOpacity>
          </View>
          <View>
            <View style={{ marginTop: 5, flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontSize: 16 }}>{i18n.t('login.forgot_password')}</Text>
              <TouchableOpacity
                style={{ marginLeft: 5 }}
                onPress={() => navigation.navigate('Recover')}
              >
                <Text style={{ fontSize: 16, fontFamily: 'Inter-Black', color: '#FF6D00' }}>
                  {i18n.t('login.forgot_password_btn')}
                </Text>
              </TouchableOpacity>

            </View>
            <View style={{ paddingTop: 5, paddingBottom: 15, paddingHorizontal: 15, maxWidth: 250 }}>
            </View>
          </View>
          <TouchableOpacity style={{ borderWidth: 2, borderColor: '#FF6D00', backgroundColor: "#FF6D00", paddingVertical: 10, borderRadius: 6, paddingHorizontal: 30, marginBottom: 5, flexDirection: 'row', justifyContent: 'center', marginTop: 3 }}
            onPress={handleLogin}>
            <Text style={{ alignSelf: 'center', color: '#FFFFFF', fontFamily: 'Inter-Bold', fontSize: 18 }}>{i18n.t('login.btn_login')}</Text>
            <Feather name='mail' size={22} color='#FFFFFF' style={{ alignSelf: 'center', marginLeft: 10 }}></Feather>
          </TouchableOpacity>
          <TouchableOpacity style={{ borderWidth: 2, borderColor: '#FF6D00', paddingVertical: 10, borderRadius: 6, paddingHorizontal: 30, marginBottom: 5, flexDirection: 'row', justifyContent: 'center', marginTop: 3 }}
            onPress={handleSignup}>
            <Text style={{ alignSelf: 'center', color: '#FF6D00', fontFamily: 'Inter-Bold', fontSize: 18 }}>{i18n.t('login.btn_signup_email')}</Text>
            <Feather name='mail' size={22} color='#FF6D00' style={{ alignSelf: 'center', marginLeft: 10 }}></Feather>
          </TouchableOpacity>
          {(Platform.OS === 'android' || Platform.OS === 'web' || Platform.OS === 'ios') ? (
            <TouchableOpacity style={{ backgroundColor: '#3D5AFE', paddingVertical: 10, borderRadius: 6, paddingHorizontal: 30, flexDirection: 'row', justifyContent: 'center' }}
              onPress={handleFacebookLogin}>
              <Text style={{ alignSelf: 'center', color: 'white', fontFamily: 'Inter-Bold', fontSize: 18 }}>{i18n.t('login.btn_facebook')}</Text>
              <Ionicons name='logo-facebook' size={22} color='white' style={{ alignSelf: 'center', marginLeft: 10 }}></Ionicons>
            </TouchableOpacity>
          ) : null}
          {(Platform.OS === 'ios') && (
            <View style={{ flex: 1, marginTop: 3 }}>
              <AppleAuthentication.AppleAuthenticationButton
                buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                cornerRadius={5}
                style={{
                  flexGrow: 1,
                  height: 44,
                }}
                onPress={async () => {
                  try {
                    const credential = await AppleAuthentication.signInAsync({
                      requestedScopes: [
                        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                        AppleAuthentication.AppleAuthenticationScope.EMAIL,
                      ],
                    });
                    //sign in with credential
                    const { identityToken, email, state } = credential;
                    const provider = new OAuthProvider('apple.com');
                    const credentialFirebase = provider.credential({
                      idToken: identityToken,
                      rawNonce: state,
                    });
                    await signInWithCredential(auth, credentialFirebase);

                  } catch (e) {
                    console.log(e);
                    if (e.code === 'ERR_REQUEST_CANCELED') {
                      // handle that the user canceled the sign-in flow

                    } else {
                      // handle other errors
                    }
                  }
                }}
              />
            </View>
          )}
        </View>
      </Modal>

      <Modal isVisible={modalQR}
        backdropColor={'rgba(0,0,0,0.5)'}
        // backdropColor='black'
        backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={() => setModalQR(false)}
        onBackdropPress={() => setModalQR(false)}
        style={{ elevation: 5, justifyContent: 'flex-end', margin: 0, height: 450, flex: 0, bottom: 0, position: 'absolute', width: '100%', borderRadius: 6 }}
      >
        <View style={{ backgroundColor: 'white', padding: 22, height: 450, borderRadius: 4, borderColor: 'rgba(0, 0, 0, 0.1)' }}>
          <TouchableOpacity onPress={() => setModalCategoryFilter(false)}>
            <Text style={{ fontSize: 24, fontFamily: 'Inter-Black' }}>
              {i18n.t('home.organizationPage.qr_code')}
            </Text>
          </TouchableOpacity>
          <Text style={{ fontSize: 16, fontFamily: 'Inter-Regular' }}>
            {i18n.t('home.organizationPage.qr_code_info')}
          </Text>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
            <QRCode value={shortURL} size={200}
              getRef={
                (c) => {
                  QRDataUrl.current = c;
                }
              } />
          </View>

          <TouchableOpacity style={{ backgroundColor: '#FF6D00', padding: 10, borderRadius: 4, marginTop: 10 }}
            onPress={() => {
              if (Platform.OS !== 'web') {
                RNQRGenerator.generate({
                  value: shortURL,
                  height: 200,
                  width: 200,
                  color: '#000000',
                }).then(response => {

                  Sharing.shareAsync(response.uri)



                }).catch(error => {
                  console.log(error);
                });
              } else {
                console.log(QRDataUrl.current)
              }
            }}>
            <Text style={{ color: '#fff', fontSize: 16, fontFamily: 'Inter-Black', textAlign: 'center' }}>
              {i18n.t('home.organizationPage.qr_code_share')}
            </Text>
          </TouchableOpacity>

        </View>
      </Modal>

      <View style={{ flex: 1 }}>
        <SectionList
          ListHeaderComponent={Header()}
          StickySectionHeadersEnabled={false}
          sections={nearProducts}
          contentContainerStyle={{ paddingBottom: 100 }}
          renderSectionHeader={({ section }) => (
            <>
              <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', paddingBottom: 6 }}>
                {console.log(section.custom_category.custom_category_photo)}
                <Image style={{ height: 75, width: 75, resizeMode: 'cover', borderRadius: 100, marginLeft: 6 }} source={{ uri: section.custom_category.custom_category_photo }} />
                <Text style={{ fontFamily: "Inter-Black", fontSize: 28, paddingLeft: 10 }}>
                  {section.custom_category.custom_category_translations[0] ? section.custom_category.custom_category_translations[0].custom_category_name : null}
                </Text>
              </View>
              <FlatList
                data={section.data}
                renderItem={renderItem}
                numColumns={numColumns}
                key={numColumns}
                ListFooterComponent={() => {
                  if (Platform.OS === 'web') {
                    return (
                      <>
                        <AdSense.Google
                          client='ca-pub-4925893888940052'
                          slot='5763444754'
                          style={{
                            width: screenWidth,
                            height: 100,
                          }}
                          format=''
                        >
                        </AdSense.Google>
                      </>
                    )
                  }
                }}
                keyExtractor={item => item.user_product_id.toString()}
                horizontal={false}
                showsHorizontalScrollIndicator={false}
                ListEmptyComponent={() => {
                  return (
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                      <Text style={{ fontFamily: 'Inter-Black', fontSize: 18, textAlign: 'center' }}>
                        {i18n.t('home.organizationPage.no_products')}
                      </Text>
                    </View>
                  )
                }}

              />

            </>
          )}
          renderItem={({ item }) => {
            return null
          }}
        ></SectionList>

      </View>

      {/**Cart button */}

      <TouchableOpacity style={{ position: 'absolute', flexDirection: 'row', bottom: 10, right: 10, backgroundColor: '#FF6D00', width: 200, height: 50, borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}
        onPress={() => {
          if (user) {
            // navigation.navigate("Cart")
            navigation.navigate("OrganizationPageCart", {
              organizationId: organizationId,
              userId: uid,
            })
          } else {
            setModalLoginVisible(true)
          }
        }}>
        <Text style={{ color: '#fff', fontSize: 16, fontFamily: 'Inter-Black', textAlign: 'center' }}>
          {i18n.t('home.organizationPage.shopping_cart_label')}
        </Text>

        <Feather name="shopping-cart" size={30} color="#fff" />
      </TouchableOpacity>
    </SafeAreaView>
  );
}

export default OrganizationPage;