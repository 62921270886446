import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, ImageBackground,Modal, TouchableWithoutFeedback, FlatList, Platform, Image, Dimensions, useWindowDimensions, Linking, Alert } from 'react-native';
import Firebase from '../../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { useQuery, useLazyQuery, useSubscription, useMutation} from '@apollo/client';
import { CREATE_CHAT_FROM_PRODUCT, CREATE_CHAT_MYSELF_FROM_PRODUCT, GET_AVAILABLE_TRIPS, GET_NEAR_PRODUCTS, GET_NEAR_STORES, TAKE_TRIP} from '../../../lib/queries';
import i18n from '../../../i18n/i18n';
import { useIsFocused } from '@react-navigation/native';

import openMap, { createMapLink } from 'react-native-open-maps';

const auth = getAuth(Firebase);


const DeliveryMotorbycle = ({navigation, route}) => {

  const isFocused = useIsFocused();

//   const {latitude} = route.params;
//   const {longitude} = route.params;

//   let lat =parseFloat(latitude);
//   let long =parseFloat(longitude);
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const langQuery = language.toUpperCase();
  const [trips, setTrips] = useState([]);
  
  // const jsonGeography = {
  //   "type": "Point",
  //   "coordinates": [longitudeState, latitudeState]
  // }
  // const isFocused = useIsFocused();

  /**Firebase */
  const uid= getAuth().currentUser.uid;

  const {loading: loadingTrips, data: dataTrips, error: errorTrips} = useSubscription(GET_AVAILABLE_TRIPS, {variables:{uid:uid, languageCode: langQuery}});

  //mutation 
    const [takeTrip, {loading: loadingTakeTrip, data: dataTakeTrip, error: errorTakeTrip}] = useMutation(TAKE_TRIP);
    const [createChat, {loading:loadingCreateChat}] = useMutation(CREATE_CHAT_FROM_PRODUCT)
    const [createChatMyself, {loading:createChatMyselfLoading, data:createChatMyselfData, error:createChatMyselfError}] = useMutation(CREATE_CHAT_MYSELF_FROM_PRODUCT);

  useEffect(() => {
    let newTrips; 
    if(dataTrips){
      const {orders} = dataTrips;
      newTrips = orders;
    }
    setTrips(newTrips);
  });

  let screenWidth
  let numColumns
  let itemWidth

  if(Platform.OS === 'ios' || Platform.OS === 'android'){
    screenWidth =  useWindowDimensions().width;
    numColumns = 3;
    itemWidth = screenWidth / numColumns;
  }else if(Platform.OS === 'web'){
    // screenWidth = window.innerWidth;
    // numColumns = 3;
    // itemWidth = screenWidth / numColumns;

    screenWidth = useWindowDimensions().width;

    if(screenWidth > 1200){
      numColumns = 6;
      itemWidth = screenWidth / numColumns;
    }else if(screenWidth > 768){
      numColumns = 5;
      itemWidth = screenWidth / numColumns;
    }
    else{
      numColumns = 2;
      itemWidth = screenWidth/numColumns;
    }
  }


  function header(){
    return(
      <View>

      </View>
    
    )
  }
  const OrderItem = ({item, order_id, delivery_option_name, payment_option_name, country_name, city_name, buyer_id,
    province_name, address1, address2, zipCode, transaction_total, product_currency, order_status, transaction_status,
user_name, user_lastname, onPress, order_delivered, order_delivered_label, userId, user_chat_id, delivery_option_id, order_delivery_user, order_delivery_user_chat, 
order_delivery_user_location, seller_expo_token, buyer_expo_token, order_delivery_fees, order_organization_id, organization_location, seller_location}) => {
    return (
<View style={{borderRadius:6, borderWidth:1, borderColor:'#B0BEC5', paddingVertical:15, marginVertical:2, flexShrink:1}}>
            
            <Text style={{fontSize:24, fontWeight:'bold', paddingHorizontal:9}}>{i18n.t('orders.order_id')}{order_id}</Text>
            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.seller')}</Text>
                <Text style={{fontSize:18}}>{" "+user_name} {user_lastname}</Text>
            </View>
            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10, flexShrink:1}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.delivery_type')}</Text>
                <Text style={{fontSize:18, flexShrink:1}}>{" "+delivery_option_name}</Text>
            </View>
            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.payment_type')}</Text>   
                <Text style={{fontSize:18}}> {payment_option_name}</Text>
            </View>

            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.order_status')}</Text>
                <Text style={{fontSize:18}}> {order_status}</Text>
            </View>
            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.transactions_status')}</Text>
                <Text style={{fontSize:18}}> {transaction_status}</Text>
            </View>
            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.order')} </Text>
                {(item.order_delivered) ?(
                    <Text style={{fontSize:18, backgroundColor:'#81C784', paddingHorizontal:5, borderRadius:6}}>{order_delivered_label}</Text>
                ):(
                    <Text style={{fontSize:18, backgroundColor:'#EF9A9A', paddingHorizontal:5, borderRadius:6}}>{order_delivered_label}</Text>
                )}
            </View>
            <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.transaction_delivery_fees')}</Text>
                <Text style={{fontSize:18}}> {order_delivery_fees} {product_currency} </Text>
            </View>
        <View style={{flexDirection:'row', alignItems:'center', flexGrow:1 ,paddingHorizontal:9, paddingTop:10}}>
            <TouchableOpacity style={{paddingVertical:9, backgroundColor:'#304FFE',flexGrow:1, marginRight:6, borderRadius:6 }}
            onPress={()=>{
                navigation.navigate('OrderDetails',{
                    order_id,
                })
            }}>
                <Text style={{textAlign:'center', color:'#FFFFFF', paddingHorizontal:2}}>{i18n.t('orders.btn_order_details')}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{alignItems:'center' ,paddingHorizontal:15, backgroundColor:'#000000', paddingVertical:9, marginHorizontal:6, borderRadius:6}}
            onPress={()=>{
                if(user_chat_id === null){
                    Alert.alert("buyer_id", buyer_id)
                    createChat({
                        variables:{
                            senderId:uid,
                            recipientId:buyer_id,
                        }
                    }).then(res=>{
                        // console.log(res);
                        const chatSellerId = res.data.insert_chat_one.chat_id;
                        const sellerId = uid;
                        navigation.navigate('Messages', {
                            sellerId,
                            chatId:chatSellerId,
                            expoToken:buyer_expo_token,
                        })
                    }).catch(err=>{
                        console.log(err);
                    })
                }else{
                    const userChatId = user_chat_id.user_chat_chat_id;
                    console.log(userChatId);
                    navigation.navigate('Messages', {
                        chatId:userChatId,
                        expoToken:buyer_expo_token,
                    })
                    console.log('userChatId', userChatId);
                    console.log('user_chat_id', user_chat_id);

                }
            }}>
                <Text style={{color:'white', fontWeight:'bold', textAlign:'center'}}>
                    {i18n.t('orders.btn_chat_with_buyer')}
                </Text>
            </TouchableOpacity>
            <Text style={{fontWeight:'bold', fontSize:18, textAlign:'right'}}>Total: </Text>
            <Text style={{fontSize:18, textAlign:'right'}}>{((transaction_total*100)/100).toFixed(2)} {product_currency}</Text>
        </View>
            {(delivery_option_id === 4)?(
              <View>
                <View style={{flexDirection:'row', paddingHorizontal:9, paddingTop:10}}>
                <TouchableOpacity style={{flex:1}} onPress={()=>{
                    if(order_organization_id === null){
                        console.log('seller loc', seller_location);
                        const locationString = `${seller_location.coordinates[1]},${seller_location.coordinates[0]}`;
                        const mapLink = createMapLink({query: locationString, provider: Platform.OS === 'ios' ? 'apple' : 'google'});
                        Linking.openURL(mapLink);
                    }else{
                        const coordinates = organization_location['coordinates'];
                        const locationString = `${coordinates[1]},${coordinates[0]}`;
                        console.log('locationString', locationString);
                        const mapLink = createMapLink({query: locationString, provider: Platform.OS === 'ios' ? 'apple' : 'google'});
                        Linking.openURL(mapLink);
                    }

                }}>
                    <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.btn_see_package_location')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{flex:1}} onPress={()=>{
                    if(order_delivery_user_chat === null && order_delivery_user !== null){
                        if(userId === uid){
                            console.log('can not chat with yourself');
                            createChatMyself({
                                variables:{
                                    senderId:uid,

                                }
                            }).then(res=>{
                                // console.log(res);
                                const chatSellerId = res.data.insert_chat_one.chat_id;
                                const sellerId = userId;
                                navigation.navigate('Messages', {
                                    sellerId,
                                    chatId:chatSellerId,
                                    expoToken:seller_expo_token
                                });
                            }).catch(err=>{
                                // console.log(err);

                            })
                        }else{
                            createChat({
                                variables:{
                                    senderId:uid,
                                    recipientId:userId,
                                }
                            }).then(res=>{
                                // console.log(res);
                                const chatSellerId = res.data.insert_chat_one.chat_id;
                                const sellerId = userId;
                                navigation.navigate('Messages', {
                                    sellerId,
                                    chatId:chatSellerId,
                                    expoToken:seller_expo_token
                                })
                            }).catch(err=>{
                                // console.log(err);
                            })
                        }
                    }else if(order_delivery_user_chat !== null){
                        const userChatId = order_delivery_user_chat;
                        console.log(userChatId);
                        navigation.navigate('Messages', {
                            chatId:userChatId,
                            expoToken:seller_expo_token
                        })
                        console.log(userChatId);
                    }else{
                        console.log('order_delivery_user_chat', order_delivery_user_chat)
                        console.log('order_delivery_user', order_delivery_user)
                        Alert.alert(i18n.t('orders.deliverer_not_found'), i18n.t('orders.deliverer_not_found_body'))
                    }
                }}>
                    <Text style={{fontWeight:'bold', fontSize:18}}>{i18n.t('orders.btn_chat_with_seller')}</Text>
                </TouchableOpacity>
            </View>
            {(order_delivery_user == null)?(
                <View>
                <TouchableOpacity style={{backgroundColor:'#e0e0e0',marginHorizontal:9, marginVertical:3, paddingVertical:10, borderRadius:6}} onPress={()=>{
                    if(order_delivery_user == null){
                        //take trip
                        takeTrip({
                            variables:{
                                orderId:order_id,
                                uid:uid,
                            }
                        }).then(res=>{
                            navigation.navigate('OrderDetails', {
                                order_id:order_id,
                            }).catch(err=>{
                                console.log(err);
                            })
                        })
                    }
                }}>
                  <Text style={{color:'#000000', textAlign:'center', fontSize:16}}>
                    {i18n.t('orders.take_trip')}
                  </Text>
                </TouchableOpacity>
            </View>
            ):null}
            </View>
            ):null}
        </View>
        
    )
}

  const renderOrders = ({item}) =>{
    // const total= item['transactions'].transaction_total
    // console.log(total);

    const order_delivered_label = item.order_delivered ? i18n.t('orders.delivered') : i18n.t('orders.not_delivered')
    
    let user_has_chat;
    
    // user_has_chat = item['user_orders'][0] ? item['user_orders'][0]['user']['user_chats'][0] : undefined;

    if(item['user']['uid'] === uid){
        user_has_chat = item['user'] ? item['user']['chat_myself'][0] : undefined;
    } else {
        user_has_chat = item['user'] ? item['user']['user_chats'][0] : undefined;
    }

    // user_has_chat = item['user'] ? item['user']['user_chats'][0] : undefined;

    // if(user_has_chat === undefined){
    //     user_has_chat = null;
    // }

    // //chat myself

    // let user_has_chat_myself;

    // user_has_chat_myself = item['user'] ? item['user']['chat_myself'][0] : undefined;

    // console.log('user_has_chat', user_has_chat);

    const onPress = () => {
        // console.log('sup');
        setModalVisible(true);
    }

    let order_delivery_seller_chat;

    if(item['user_orders'][0]){
        if(item['user_orders'][0]['user']['uid'] === uid){
            order_delivery_seller_chat = item['user_orders'][0]['user']['chat_myself'][0] ? item['user_orders'][0]['user']['chat_myself'][0]['user_chat_chat_id'] : null
        }else{
            order_delivery_seller_chat = item['user_orders'][0]['user']['user_chats'][0] ? item['user_orders'][0]['user']['user_chats'][0]['user_chat_chat_id'] : null    
        }
    }

    // order_delivery_seller_chat =item['user_orders'][0] ? item['user_orders'][0]['user']['user_chats']? item['user_orders'][0]['user']['user_chats'][0]['user_chat_chat_id'] : null : null
    
    return(
        <OrderItem item={item}  
        order_id={item.order_id}
        order_delivered={item.order_delivered}
        order_delivered_label={order_delivered_label}
        delivery_option_name={item['country_delivery_option']['delivery_option']['delivery_option_translations'][0].delivery_option_name}
        payment_option_name={item['country_payment_option']['payment_option']['payment_option_translations'][0].payment_option_name}
        delivery_option_id={item['country_delivery_option']['delivery_option_id']}
        address1={item['user_location'].address1}
        address2={item['user_location'].address2}
        city_name={item['user_location']['city'].city_name}
        zipCode={item['user_location'].zip_code}
        province_name={item['user_location']['province'].province_name}
        // transaction_total={item['transactions'][0].transaction_total}
        transaction_total={item['transactions_aggregate'].aggregate.sum.transaction_total}
        product_currency={item['transactions'][0] ? item['transactions'][0].product_currency.currency_code: null}
        order_status={item['order_status']['order_status_translations'][0].order_status_name}
        transaction_status={item['transactions'][0]? item['transactions'][0]['transaction_status']['transaction_status_translations'][0].transaction_status_name : null}
        user_name={item['user_orders'][0]? item['user_orders'][0]['user'].user_name : null}
        user_lastname={item['user_orders'][0]? item['user_orders'][0]['user'].user_lastname : null}
        userId={item['user_orders'][0] ? item['user_orders'][0]['user'].uid: null}
        onPress={onPress}
        user_chat_id={user_has_chat}
        order_delivery_user_chat={order_delivery_seller_chat}
        seller_expo_token={item['user_orders'][0] ? item['user_orders'][0]['user']['user_expo_token'] : null}
        seller_location={item['user_orders'][0] ? item['user_orders'][0]['user']['user_location'] : null}
        buyer_expo_token={item['user']? item['user']['user_expo_token'] : null}
        buyer_id={item['user']? item['user']['uid'] : null}
        order_delivery_fees={item['order_delivery_fees']}
        order_delivery_user={item['order_delivery_user']}
        order_organization_id={item['order_organization_id']}
        organization_location={item['organization'] ? item['organization']['organization_location'] : null}
        />

    )
}


  return (
    <SafeAreaView style={{flex:1}}>
        <View style={{paddingHorizontal:9}}>
          <Text style={{fontSize:32, fontWeight:'bold'}}>
            Viajes para realizar.
          </Text>
        </View>
        {(!loadingTrips)?(
            <FlatList
            //  contentContainerStyle={{paddingBottom:100}}
             ListHeaderComponent={header}
            //  initialNumToRender={6}
             data={trips} 
            renderItem={renderOrders}
             keyExtractor={(item, index) => index.toString()}
             />

        ):(
            <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        )}
    </SafeAreaView>
  );
}


export default DeliveryMotorbycle;