import React, { useState, useEffect, useCallback } from 'react';
import { View, Text,Dimensions, TextInput, TouchableOpacity, Button,KeyboardAvoidingView, Platform, NativeEventEmitter, Alert, FlatList, ScrollView, Modal } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';


const AddProductDescription = ({navigation, route}) => {
      const lang = i18n.locale;
      const language = lang.substring(0, 2);
      const langQuery = language.toUpperCase();
      console.log("LANGUAGE", langQuery);
    /**route.params */
      const {subCategoryId} = route.params

      /**productSKUPrice */
      const {productSKUQuantity} = route.params
      const {productSKU} = productSKUQuantity
      
      /**productTitle */ 
      const {productTitleQuantity} = route.params
      const {productTitle} = productTitleQuantity

      /**productSlug */
    const {productSlugQuantity} = route.params
    const {productSlug} = productSlugQuantity
  
      /**productImage */
      const {productImageQuantity} = route.params
      const {image} = productImageQuantity
  
      /**productPrice */
  
      const {productPriceQuantity} = route.params
      const {productPrice} = productPriceQuantity

      /**productQuantity */
      const {productQuantityQuantity} = route.params
      const {productQuantity} = productQuantityQuantity

      /**ProductCurrency */

      const {productCurrencyQuantity} = route.params
      const {productCurrency} = productCurrencyQuantity

      /**productGalleryQuantity */

      const {productGalleryQuantity} = route.params
      const {productGallery} = productGalleryQuantity

    /**States */
    /**Initial value for descriptionList */


    const [descriptionList, setDescriptionList] = useState([{
      product_description: ''
    }])
    const [index, setIndex] = useState(1)
    const [exampleState, setExampleState] =useState(descriptionList)
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);


    useEffect(()=>{
      // console.log("SUBCATEGORY ID", subCategoryId)
    })

    const addNewInput = useCallback(() =>{

      var newArray =[...descriptionList,{product_description:"", language_code:langQuery, product_title:productTitle}];
      // setIndex(index+1)
      // console.log(descriptionList.length)
      setExampleState(newArray);
      setDescriptionList(newArray);
    })

    const updateArray = useCallback((index, product_description)=>{
      var newArray = [...descriptionList];
      if(product_description === newArray[index].product_description){
        // console.log('Same value foo')
      }else{
        newArray[index].product_description = product_description
        newArray[index].language_code = langQuery
        newArray[index].product_title = productTitle
        console.log("NEW ARRAY", newArray)
        setExampleState(newArray);
        setDescriptionList(newArray)
        addNewInput()
      }
      
     
    })
    const keyboardVerticalOffset = Platform.OS === 'ios' ? 40 : 0
    const Item = ({item, index}) =>{
      return(

        <KeyboardAvoidingView  behavior='position' keyboardVerticalOffset={keyboardVerticalOffset}>
          <Text>
          {item.product_description}
        </Text>
          <TextInput
              // onChangeText={(product_description)=> {
              //   updateArray(index, product_description)
  
              // }}
              // onBlur={(e)=>{console.log(e.nativeEvent)}}
              onEndEditing={(e)=>{
                updateArray(index, e.nativeEvent.text)
              }}
              defaultValue={item.product_description}
              returnKeyType="done"
              onSubmitEditing={(e)=>{
                updateArray(index, e.nativeEvent.text);
                console.log(descriptionList)
              }}
               onKeyPress={(e)=>{
                 if(e.nativeEvent.key== 'Enter'){
                   Alert.alert("keypress", 'Press')
                 }
               }}
               style={{fontSize:20, backgroundColor:'#e0e0e0', paddingHorizontal:8, flexGrow:1}}
               placeholder="Add your description"/>
        </KeyboardAvoidingView>
      
      );
    } 
    
    

    const renderItem = ({ item, index }) => <Item
      item={item}
      index={index}
    />;

    return(
        <SafeAreaView style={{flex:1, paddingVertical:-45}}>
                        <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
        <View style={{paddingHorizontal:5}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('products.addProductDescriptions.title')}
            </Text>
            </View>
            <View style={{paddingVertical:5}}>
            <Text style={{fontSize:18}}>
                {i18n.t('products.addProductDescriptions.body')}
            </Text>
        </View>
        <FlatList data={descriptionList}
                          renderItem={renderItem} 
                          contentContainerStyle={{paddingBottom: 25}}
                          keyExtractor={(item, index) => index.toString()}></FlatList>
        <TouchableOpacity
            style={{backgroundColor:'#FF6D00', borderRadius:10, paddingVertical:15, marginTop:10}}
            onPress={()=> {if(descriptionList[0].product_description){
              navigation.navigate('AddDeliveryOptions',{
                productSKUDescriptions:{productSKU},
                productTitleDescriptions:{productTitle},
                productSlugDescriptions:{productSlug},
                productImageDescriptions:{image},
                productPriceDescriptions:{productPrice},
                productQuantityDescriptions:{productQuantity},
                productCurrencyDescriptions:{productCurrency},
                productDescriptionsDescriptions:{descriptionList},
                subCategoryId,
                productGalleryDescriptions:{productGallery}
              })
            }else{
              Alert.alert("Error", i18n.t('products.addProductDescriptions.alert_body'))
              if(Platform.OS === 'web'){
                setMessageTitle(i18n.t('products.addProductDescriptions.alert_title'))
                setMessageBody(i18n.t('products.addProductDescriptions.alert_body'))
                setModalVisibilityAlert(true)
              }
            }}}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:18}}>
            {i18n.t('products.addProductQuantity.btn_next')}
            </Text>
          </TouchableOpacity>
        </View>
    </SafeAreaView>
    )
    
}

export default AddProductDescription;