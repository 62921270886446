import { useMutation } from '@apollo/client';
import i18n from 'i18n-js';
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Alert, TextInput} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { UPDATE_CUSTOM_CATEGORY_NAME_LANGUAGE } from '../../lib/queries';

const EditCategoryTranslations = ({navigation, route}) => {

  /**route.params */
  /**customCategoryName */
    const { custom_category_nameDetail} = route.params
    const {customCategoryNameQ} = custom_category_nameDetail
    /**customCategoryId */

    const {custom_category_idDetail} = route.params
    const {customCategoryIdDetail} = custom_category_idDetail

    /**customCategoryLanguageCode */
    const {custom_category_language_codeDetail} = route.params
    const {customCategoryLanguageCodeDetail} = custom_category_language_codeDetail

    /**customCategoryTranslationId */

    const {custom_category_translation_idDetail} = route.params
    const {customCategoryTranslationIdentifier} = custom_category_translation_idDetail

    const [customCategoryText, setcustomCategoryText] = useState(customCategoryNameQ? customCategoryNameQ: null)
    const [customCategoryId, setcustomCategoryId] = useState(customCategoryIdDetail?customCategoryIdDetail:null)
    const [customCategoryLanguageCode, setCustomCategoryLanguageCode] = useState(customCategoryLanguageCodeDetail? customCategoryLanguageCodeDetail:null)
    const [customCategoryTranslationId, setCustomCategoryTranslationsId] = useState(customCategoryTranslationIdentifier ? customCategoryTranslationIdentifier: null)
    
    const [updateCategoryTranslation, {loading: mutationPush, data:mutationResponse}] = useMutation(UPDATE_CUSTOM_CATEGORY_NAME_LANGUAGE)
    useEffect(()=>{
      // console.log(customCategoryText)
      // console.log("hey ", customCategoryTranslationId)
    })
    return(
    <SafeAreaView style={{flex:1}}>
        <View style={{paddingHorizontal:5}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}> {i18n.t('products.categories.editCategoryName.title')}
            </Text>
          </View>
          <Text style={{fontSize:18, paddingBottom:5, paddingTop:5}}>
           {i18n.t('products.categories.editCategoryName.body')}
          </Text>
          <TextInput style={{fontSize:18, backgroundColor:'#e0e0e0', flexDirection:'row'}}
          defaultValue={customCategoryNameQ}
            placeholder="Edit your Category Label"
            onChangeText={(v)=>{setcustomCategoryText(v)}}
            >
            </TextInput>

            <TouchableOpacity
            style={{backgroundColor:'#000000', borderRadius:6, paddingVertical:10, marginTop:10}}
            onPress={()=>{
              updateCategoryTranslation({
                variables:{
                  customCategoryTranslationId,
                  customCategoryText
                }
              }).then(()=>{
                Alert.alert("Success", "Category Name Modified!")
              })
            }}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:18}}>
              {i18n.t('products.categories.editCategoryName.btn_save')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:10, marginTop:5}}
            onPress={()=> navigation.goBack()}
          >
            <Text style={{textAlign:'center', fontWeight:'bold', fontSize:18, borderRadius:6}}>
              {i18n.t('products.categories.editCategoryName.btn_cancel')}
            </Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    )
    
}

export default EditCategoryTranslations;