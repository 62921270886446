import React, { useState, useEffect } from 'react';
import { View, Text, Platform,TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';
import { StatusBar } from 'expo-status-bar';

const Greeting = ({navigation}) => {

    useEffect(() => {
       
    },)
    return(
        <SafeAreaView style={{flex:1}}>
            <StatusBar style='dark' backgroundColor='white'/>
        <View style={{paddingTop:45, paddingHorizontal:10, flex:1, paddingRight:15,}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignContent:'center'}}>
            <Text style={{fontSize:36, fontWeight:'bold'}}>
                {i18n.t('setup.greeting.title')}
            </Text>
            </View>
            <View style={{paddingTop:25 , alignItems:'center'}}>
                <Text style={{fontSize:22}}>{i18n.t('setup.greeting.body')}
                 </Text>
            </View>
        </View>
        
        <View style={{flex:2}}>
            
        </View> 
        <View style={{flex:0.4, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=>{navigation.navigate('NameScreen',)}}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('setup.greeting.btn_next')}</Text>
            </TouchableOpacity>
            </View>      
    </SafeAreaView>
    )
    
}

export default Greeting;