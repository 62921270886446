import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, Modal, ActivityIndicator, KeyboardAvoidingView , TouchableWithoutFeedback, Keyboard, Platform} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {useMutation} from '@apollo/client'
import { UPDATE_PRODUCT_QUANTITY } from '../../../lib/queries';
import i18n from '../../../i18n/i18n';

const EditProductQuantity = ({navigation, route}) => {

    /**route.params */
    /**productId */

    const {productId} = route.params
    const {product_id} = productId

    const {product_quantity} = route.params
    const {Quantity} = product_quantity


    const [productQuantity, setProductQuantity] = useState(Quantity ? Quantity.toString() : '')

    /**Mutations */

    const [updateProductQuantity, {loading: mutationLoading, error: mutationError}] = useMutation(UPDATE_PRODUCT_QUANTITY)


    useEffect(()=>{
      // console.log(Quantity)
    },)
    return(

      <SafeAreaView style={{flex:1, paddingTop:5}}>
            <Modal
            visible={mutationLoading}
            style={{flex:1, justifyContent:'center', alignItems:'center'}}
            animationType="none"
            transparent={true}>
                <View style={{flex:1, justifyContent:'center', alignItems:'center'}}>
                {/* <Text>Hey</Text> */}
                <ActivityIndicator size="large" color={'#1e76e8'} />
                {/* <Button title="Close" onPress={()=>setVisibleModal(false)}/> */}
                </View>

            </Modal>
        <TouchableWithoutFeedback style={{flex:1}}>


        <View style={{flex:1}}>

            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <View style={{paddingTop:45, paddingLeft:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:18, fontWeight:'bold'}}>
              {i18n.t('products.editProductQuantity.title')}
            </Text>
            </View>

        </View>
        <View style={{flex:2}}>
        <Text style={{fontSize:22 , paddingHorizontal:15}}>
          {i18n.t('products.editProductQuantity.edit_product_quantity')}
        </Text>
            <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:12,
             marginHorizontal:15, fontSize:18}}
             placeholder="4"
            contextMenuHidden={true}
             defaultValue={productQuantity}
             keyboardType={'number-pad'}
             onChangeText={(v)=> setProductQuantity(v.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, ''))}
             />


        </View>
        <View style={{flex:0.6, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#000000', paddingVertical:15, borderRadius:6}}
                    onPress={()=> {if(productQuantity){
                                updateProductQuantity({
                                  variables:{
                                    productQuantity,
                                    product_id
                                  }
                                }).then(()=>{
                                  Alert.alert('Success', 'Product quantity updated successfully')
                                })
                              }else{
                                Alert.alert('Error', 'Please type the quantity of product')
                              }}}
                      >
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>
                  {i18n.t('products.editProductQuantity.btn_save')}
                </Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
        </View>
        </TouchableWithoutFeedback>
    </SafeAreaView>

    )

}

export default EditProductQuantity;