import React, { useState, useEffect } from 'react';
import { View, Text, Button, Image, ImageBackground, TouchableOpacity, Alert, Dimensions, ScrollView, TextInput, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as ImagePicker from 'expo-image-picker';
// import {} from 'react-native-gesture-handler';
import { BlurView } from 'expo-blur';
import { Picker } from '@react-native-picker/picker';
import Firebase from '../../lib/firebase';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useQuery } from '@apollo/client';
import { GET_LANGUAGES, INSERT_CUSTOM_CATEGORIES } from '../../lib/queries';
import i18n from '../../i18n/i18n';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

const auth = getAuth(Firebase);
const storage = getStorage(Firebase);



const AddCustomCategories = ({ navigation }) => {
  const lang = i18n.locale;
  const languageD = lang.substring(0, 2);
  const langQuery = languageD.toUpperCase();
  const [nearProducts, setNearProducts] = useState([]);
  const uid = getAuth().currentUser.uid;
  const [image, setImage] = useState(null);
  const [customCategoryName, setCustomCategoryName] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState(langQuery)
  const [language, setLanguage] = useState([])


  /**Queries */
  const { loading: languageFetch, data: languageData } = useQuery(GET_LANGUAGES)

  /**Mutations */
  const [insertCustomCategories, { loading: fetchCustomCategories, data: responseCustomCategories }] = useMutation(INSERT_CUSTOM_CATEGORIES)

  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
    // let newLanguages
    // if(languageData){
    //     const {languages} = languageData
    //     newLanguages = languages
    // }
    // console.log(newLanguages)
    // setLanguage(newLanguages)
  }, []);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [6, 3],
      quality: Platform.OS === 'ios' ? 0 : 1,
    });

    // console.log(result);

    if (!result.canceled) {
      setImage(result.assets[0].uri);
    }
  };

  /**Uploadimage function */

  async function uploadImage(uri) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    // const blob = await new Promise((resolve, reject) => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.onload = function () {
    //       resolve(xhr.response);
    //     };
    //     xhr.onerror = function (e) {
    //       console.log(e);
    //       reject(new TypeError("Network request failed"));
    //     };
    //     xhr.responseType = "blob";
    //     xhr.open("GET", uri, true);
    //     xhr.send(null);
    //   });

    //   // const ref = Firebase.storage().ref().child('chat-images/' + uuidv4());
    //   const fileref = ref(storage,uuidv4());
    //   const result = await uploadBytes(fileref, blob);
    //   // const snapshot = await ref.put(blob);

    //   // We're done with the blob, close and release it
    //   blob.close();

    // return await getDownloadURL(fileref)

    const fileref = ref(storage, 'custom-categories/' + uuidv4());
    const img = await fetch(uri);
    const blob = await img.blob();

    // console.log("upload completed");

    const uploadTask = uploadBytesResumable(fileref, blob);

    uploadTask.on('state_changed', (snapshot) => {

    }, (error) => {
      // console.log(error);
    }, () => {
      // console.log("upload completed");

      getDownloadURL(fileref).then(url => {
        // console.log(url);

        insertCustomCategories({
          variables: {
            uid,
            customCategoryPhoto: url,
            customCategoryName,
            selectedLanguage
          }
        }).catch(e => {
          Alert.alert("Error", e.message)
        })
      })
    });


  }

  async function putCustomCategories() {
    if (customCategoryName.trim() === '') {
      Alert.alert('Error', 'Category Title is required')
    } else {
      if (image === null) {
        Alert.alert('Error', 'Category Image is required')
      } else {
        await uploadImage(image).finally(() => {

          Alert.alert("Success", "Custom Category Added")
        });
      }

    }

  }

  const screenWidth = Dimensions.get('window').width - 10;
  return (
    <SafeAreaView style={{ flex: 1, paddingTop: -45 }}>
      <ScrollView>
        <View style={{ paddingHorizontal: 5 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text style={{ fontSize: 32, fontWeight: 'bold' }}>
              {i18n.t('products.categories.addCategories.title')}
            </Text>
          </View>
          <View style={{ paddingVertical: 5 }}>
            <TextInput style={{ backgroundColor: '#e0e0e0', fontSize: 18, paddingHorizontal: 6, flexGrow: 1 }}
              placeholder={i18n.t('products.categories.addCategories.category_placeholder')}
              onChangeText={(v) => setCustomCategoryName(v)}>
            </TextInput>
          </View>
          <View >
            <Text style={{ fontSize: 18, paddingVertical: 10 }}>
              {i18n.t('products.categories.addCategories.language_label')}
            </Text>
            <Picker
              style={{ flexGrow: 1, paddingVertical: 10 }}
              selectedValue={selectedLanguage}
              enabled={false}
              onValueChange={(itemValue, itemIndex) => { setSelectedLanguage(itemValue); }}
            >
              <Picker.Item label={i18n.t('products.categories.addCategories.picker_item')}
                value="" key="0" />
              {(languageData) ? (
                languageData.languages.map((v) => {
                  return <Picker.Item label={v.language_name} value={v.language_code} key={v.language_code} />
                })
              ) : null}

            </Picker>


          </View>
          <View style={{ paddingVertical: 10 }}>
            <Button title={i18n.t('products.categories.addCategories.btn_pick_image')} onPress={pickImage} />
            <View>


              <ImageBackground source={{ uri: image }} resizeMode="cover" style={{ width: screenWidth, height: 200 }}>


                <Text style={{ position: 'absolute', paddingHorizontal: 10, zIndex: 50, fontSize: 32, fontWeight: 'bold', color: '#FFFFFF', marginTop: 75 }}>{(customCategoryName) ? (
                  customCategoryName
                ) : "Default"}</Text>
                <View style={{ alignSelf: 'flex-start', height: 200, borderRadius: 16, backgroundColor: '#000000', opacity: 0.2, width: screenWidth, }}>

                </View>
              </ImageBackground>
            </View>

          </View>
        </View>

        <View style={{ paddingVertical: 5, paddingHorizontal: 5 }}>
          <TouchableOpacity
            style={{ backgroundColor: '#000000', borderRadius: 6, paddingVertical: 15, }}
            onPress={putCustomCategories}
          >
            <Text style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, borderRadius: 6, color: '#FFFFFF' }}>
              {i18n.t('products.categories.btn_add_category')}
            </Text>
          </TouchableOpacity>

        </View>
      </ScrollView>

    </SafeAreaView>
  )

}

export default AddCustomCategories;