import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, Alert, Image, ScrollView, Touchable, Share, Platform } from 'react-native';
import {Picker} from '@react-native-picker/picker'; 
import { useQuery, useMutation } from '@apollo/client';
import { Feather, Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_USER, UPDATE_USER_NAME, UPDATE_USER_LASTNAME, UPDATE_USER_PHOTO, UPDATE_USER_THUMBNAIL, UPDATE_USER_LANGUAGE } from '../lib/queries';
import i18n from '../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'; 
import Modal from 'react-native-modal'
import QRCode from 'react-native-qrcode-svg';

const RNQRGenerator = Platform.select({
  native: ()=> require('rn-qr-generator'),
  web: ()=> null,
});
const auth = getAuth(Firebase);
const storage = getStorage(Firebase);
const UserData = ({navigation}) => {

  // AsyncStorage.getItem('language').then(language => {
  //   setSelected_language(language);
  // });
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  const uid = getAuth().currentUser.uid;
  const [editing, setEditing] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false)
  const [user_name, setuser_name] = useState('');
  const [user_lastname, setuser_lastname] = useState('')
  const [user_email, setuser_email] = useState('')
  const [user_photo, setuser_photo] = useState('')
  const [user_thumbnail, setuser_thumbnail] = useState('')
  const [selected_language, setSelected_language] = useState(0)
  const [modalQR, setModalQR] = useState(false);
  const [shortURL, setShortURL] = useState('');

  const { loading: fetchLoading, data } = useQuery(GET_USER, { variables: { uid }});
  const [ updateUser, { loading: mutationLoading, data: response } ] = useMutation(UPDATE_USER_NAME);
  const [ updateLastName, {loading: mutationLoadingLastName, data: responseLastName}] = useMutation(UPDATE_USER_LASTNAME)

  const [updateUserPhoto, {loading: mutationLoadingPhoto, data: responsePhoto}] = useMutation(UPDATE_USER_PHOTO);
  const [updateUserThumbnail, {loading: mutationLoadingThumbnail, data: responseThumbnail}] = useMutation(UPDATE_USER_THUMBNAIL);
  const [updateUserLanguage, {loading: mutationLoadingLanguage, data: responseLanguage}] = useMutation(UPDATE_USER_LANGUAGE);

  const shareProfile = async () => {
    try {
      const payload ={
        dynamicLinkInfo:{
            domainUriPrefix: 'https://menucitos.page.link',
            link: 'https://menucitos.com/drawer/usersettings/profile/public/'+uid,
            androidInfo:{
                androidPackageName:'com.nelson_arguera.menu',
            },
            iosInfo:{
                iosBundleId: 'com.nelsonarguera.menu',
                iosAppStoreId: 'id1643431406',
            },
            socialMetaTagInfo:{
                socialTitle: user_name + ' ' + user_lastname,
                socialDescription: i18n.t('userSettings.profile.check_out_my_profile'),
                socialImageLink: user_thumbnail,
            }
        },
        /**ios */
    };

    let apiKey ="AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
    // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
    const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
    let json;
    let shortLink;
    const response = await fetch(url, {
        method: 'POST',
        headers:{
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload),
    }).then(res => {
        res.json().then(async (data) => {
            console.log(data);
            json = data;
            const result = await Share.share({
            message: json.shortLink,
            url: json.shortLink,
            title: 'Share via',
            });
        })
    }).catch(err => {console.log("WHAT",JSON.stringify(err))});
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // AsyncStorage.getItem('language').then(language => {
    //   setSelected_language(language);
    // });

    setSelected_language(language);

    let newName = null;
    let newLastName = null;
    let newUserEmail = null;
    let newPhoto = null;
    let newThumbnail = null;
    // console.log("FIREBASEUID: ", uid)
    if (data) {

      // console.log("DATOS: ", data)
      const { users_by_pk: user } = data;
      const { user_name } = user;
      const { user_lastname } = user;
      const { user_email} = user;
      const { user_photo } = user;
      const { user_thumbnail } = user;

      
      if (user_name === null){
        setuser_name('Empty')
        // console.log(user_name)
      }
      {/* if a name already exists, set it as the name */}
      newName = user_name;
      newLastName = user_lastname
      newUserEmail = user_email
      newPhoto = user_photo
      newThumbnail = user_thumbnail
      console.log('newPhoto: ', newPhoto)
    }    
    if (response) {
      // console.log("DATOS: ", response)
      const { update_users_by_pk } = response;
      const { user_name } = update_users_by_pk;

      {/* when a user updates their name, set that as the name */}
      newName = user_name;
    }

    if(responsePhoto){
      const { update_users_by_pk } = responsePhoto;
      const { user_photo } = update_users_by_pk;

      newPhoto = user_photo;
    }

    if(responseThumbnail){
      const { update_users_by_pk } = responseThumbnail;
      const { user_thumbnail } = update_users_by_pk;

      newThumbnail = user_thumbnail;
    }

    setuser_name(newName);
    setuser_lastname(newLastName)
    setuser_email(newUserEmail)
    setuser_photo(newPhoto)
    setuser_thumbnail(newThumbnail)

    // console.log('language: ', language)
  }, [data, response, responsePhoto, responseThumbnail]);

  const handlerPickerLanguage = (itemValue, itemIndex) => {
    setSelected_language(itemValue);
    console.log("Picker: ", itemValue, itemIndex);
    // console.log("Language: ", i18n.language);
    console.log(itemValue)
    const number = itemValue;
    console.log(number)
    if(number === 'en'){
      i18n.locale = 'en'
      setSelected_language('en')
      AsyncStorage.setItem('language', 'en');
      updateUserLanguage({
        variables: {
          uid: uid,
          languageCode: 'en',
        },
      }).catch((error) => {
        console.log(error);
      }).then((data) => {
        console.log(data);
      });
    }else if(number === 'es'){

      i18n.locale = 'es'
      setSelected_language('es')
      updateUserLanguage({
        variables: {
          uid: uid,
          languageCode: 'es',
        },
      }).catch((error) => {
        console.log(error)
      }).then((data) => {
        console.log(data)
      });
      //save in asyncstorage
      AsyncStorage.setItem('language', 'es');
    }
  }

  const deleteUser = () => {
    const user = auth.currentUser;
    user.delete().then(() => {
      Alert.alert(i18n.t('userSettings.profile.user_deleted'), i18n.t('userSettings.profile.user_deleted_body'));
    }).catch((error) => {
      if (error.code === 'auth/requires-recent-login') {
        // Require user to sign in again.
        // console.log('Requires recent login');
        Alert.alert(i18n.t('userSettings.profile.log_out_log_in'), i18n.t('userSettings.profile.log_out_log_in_body'));
      }
    });
  };

  const openCamera = async (mode = 1)=>{
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
    if (permissionResult.granted === false) {
        alert("You've refused to allow this app to access your camera!");
        return;
      }
  
      const result = await ImagePicker.launchCameraAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          aspect: [2, 2],
          quality:Platform.OS === 'ios' ? 0 : 1,
      });
  
      // Explore the result
      // console.log("RESULT: ", result);
  
      if (!result.canceled) {
        // setProductkNewImage(result.uri);
        const imageuniqueID = uuidv4();
        try {
         await postNewProduct(result.uri, imageuniqueID, mode);
        } catch (error) {
          console.log("Error CAMERA", error);
        }
      }
}

const openGallery = async (mode = 1 )=>{

  const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
  if (permissionResult.granted === false) {
      alert("You've refused to allow this app to access your camera!");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [1, 1],
        quality:Platform.OS === 'ios' ? 0 : 1,
    });

    // Explore the result
    // console.log("RESULT: ", result);

    if (!result.canceled) {
      // setProductNewImage(result.uri);
      const imageuniqueID = uuidv4();
      try {
       await postNewProduct(result.uri ,imageuniqueID, mode);
      } catch (error) {
      // setProcessLoading(false);
        console.log("Error XDra", error);
      }
    }
}

     /**Uploadimage function */
     function delay(t, v) {
      return new Promise(function(resolve) { 
        setTimeout(resolve.bind(null, v), t)
      });
    }
    
    function keepTrying(triesRemaining, storageRef) {
      if (triesRemaining < 0) {
        return Promise.reject('out of tries');
      }
  
      return getDownloadURL(storageRef).then(url => {
        return url;
      }).catch(err => {
        if (err.code === 'storage/object-not-found') {
          return delay(1000).then(() => {
            return keepTrying(triesRemaining - 1, storageRef);
          });
        } else {
          return Promise.reject(err);
        }
      })
    }

    async function uploadImage(uri, imageName, mode) {

      console.log("IMAGE UUID, ", imageName)

      const fileref = ref(storage, 'users/'+imageName);
      const img = await fetch(uri);
      const blob = await img.blob();

      console.log('uploading image...');

      const uploadTask = uploadBytesResumable(fileref, blob);

      uploadTask.on('state_changed', (snapshot) => {
          
      }, (error) => {
        console.log(error);
      } , () => {

        console.log('upload completed');
          
        getDownloadURL(fileref).then(
          async (response) => {
              console.log("Response: ", response );
            if(mode == 1){
                if(user_photo){
                    if(user_thumbnail){
                        console.log('user_photo:', user_photo);
                        console.log("user_thumbnail", user_thumbnail);
                        const storageRef = ref(storage, user_photo);
        
                       await deleteObject(storageRef).then(()=>{
                            console.log("deleted");
                        }).catch((error)=>{
                            console.log(error);
                        });
                        const storageRef1 = ref(storage, user_thumbnail);
    
                        await deleteObject(storageRef1).then(()=>{
                         }).catch((error)=>{
                             console.log("ERROR NO ID", error);
                         });
                    }
                    console.log("deleted");
    
                    // console.log("productID", product_id);
    
                }

                updateUserPhoto({variables:{
                  uid:uid,
                  userPhoto: response,
              }}).then((res)=>{
                  // const productId = res.data.insert_products_one.product_id;
                  // console.log("Product ID:" , productId);
                  
                  const pathRef = ref(storage, 'users/resized/'+imageName+'_500x500')
  
                  keepTrying(9999, pathRef).then(
                      url =>{
                          console.log("URL", url);
          /**upload thumbnail */
                  updateUserThumbnail({variables:{
                      uid:uid,
                      userThumbnail: url
                  }}).then((res)=>{
                  console.log("Thumbnail updated");
                  // setProcessLoading(false);
                      // Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [
                      
                      // {text: 'OK', onPress: () => navigation.goBack()},
                      // ], {cancelable: false});

                      // if(Platform.OS === 'web'){
                      // setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
                      // setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
                      // setModalVisibilityAlert(true);
                      // }
          
                  }).catch((err)=>{
                      console.log("Error Thumbnail", err);
                  })
                  }).catch((err)=>{
                      console.log("Error Portrait", err);
                  })
      
              })
            }else if(mode == 2){

                const fileref1 = ref(storage, 'products/'+imageName);
                const img1 = await fetch(uri);
                const blob1 = await img1.blob();
                console.log('uploading image...'+ uri);
      
                const uploadTask1 = uploadBytesResumable(fileref1, blob1);
      
                uploadTask1.on('state_changed', (snapshot) => {
                    console.log('snapshot progess ' + snapshot);
                }, (err) => {
                    console.log(err);
                } , async () => {
                    console.log('upload image success');
                    const url1 = await getDownloadURL(fileref1);

                    console.log("URL", url1);
      
                    insertProductGalleryOne({variables:{
                        productId: product_id,
                        productGalleryPhoto: url1
                    }}).then(res => {
                    const productGalleryId = res.data.insert_product_gallery_one.product_gallery_id;
      
                    const pathRef1 = ref(storage, 'products/resized/'+imageName+'_500x500');
                        keepTrying(35, pathRef1).then(
                        url2 =>{
                           console.log("URL", url2);
                        /**upload thumbnail */
                        updateProductGalleryThumbnail({variables:{
                            productGalleryId,
                            productThumbnail: url2   
                        }}).then((res)=>{
                          console.log("Thumbnail updated");
                          // setProcessLoading(false);
                          refetch();
                            Alert.alert(i18n.t('products.addPaymentOptions.product_uploaded'), i18n.t('products.addPaymentOptions.product_uploaded_body'), [
                              
                              {text: 'OK', onPress: () => console.log('OK Pressed')},
                            ], {cancelable: false});
      
                            if(Platform.OS === 'web'){
                              setMessageTitle(i18n.t('products.addPaymentOptions.product_uploaded'));
                              setMessageBody(i18n.t('products.addPaymentOptions.product_uploaded_body'));
                              setModalVisibilityAlert(true);
                            }
                  
                          }).catch((err)=>{
                              console.log("Error22222222", err);
                            })
                        }
                      )
                    }).catch(err =>{
                      console.log("Error3333333", err);
                    })  
      
      
            })
            }
          }).catch((error)=>{
                console.log("Error", error);
            }
        )


      });

    }

    async function postNewProduct(uri,imageName, mode){
        // setProcessLoading(true);
        const productImage = await uploadImage(uri, imageName, mode);
        console.log("Product Image: ", imageName);
    }

  if (fetchLoading || mutationLoading || mutationLoadingLastName) return (
    // flex-1 justify-center items-center
    <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
      <ActivityIndicator color={'#1e76e8'} size={"large"}/>
    </SafeAreaView>
  )
  return (
    <SafeAreaView style={{flex:1}}>

<Modal isVisible={modalQR}
        backdropColor={'rgba(0,0,0,0.5)'}
          // backdropColor='black'
          backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={()=> setModalQR(false)}
        onBackdropPress={()=> setModalQR(false)}
        style={{elevation:5,justifyContent:'flex-end', margin:0, height:450, flex:0, bottom:0, position:'absolute', width:'100%', borderRadius:6}}
        >
          <View style={{backgroundColor:'white', padding:22, height:450, borderRadius:10, borderColor:'rgba(0, 0, 0, 0.1)'}}>
          <TouchableOpacity onPress={()=> setModalCategoryFilter(false)}>
            <Text style={{fontSize:24, fontFamily:'Inter-Black'}}>
              {i18n.t('userSettings.profile.qr_code')}
            </Text>
          </TouchableOpacity>
          <Text style={{fontSize:16, fontFamily:'Inter-Regular'}}>
            {i18n.t('userSettings.profile.qr_code_body')}
          </Text>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10}}>
          <QRCode value={shortURL} size={200}/>
          </View>

          <TouchableOpacity style={{backgroundColor:'#1e76e8', padding:10, borderRadius:16, marginTop:10}}
          onPress={()=>{
            if(Platform.OS !== 'web'){
              RNQRGenerator.generate({
                value: shortURL,
                height: 200,
                width: 200,
                color: '#000000',
              }).then(response =>{
                
                Sharing.shareAsync(response.uri)
  
              }).catch(error => {
                console.log(error);
              });  
            }
          }}>
            <Text style={{color:'#fff', fontSize:16, fontFamily:'Inter-Black', textAlign:'center'}}>
              {i18n.t('userSettings.profile.qr_code_share')}
            </Text>
          </TouchableOpacity>

          </View>
        </Modal>


    <ScrollView style={{flex:1, backgroundColor:'#fff'}}>
      <View style={{paddingHorizontal:5, paddingTop:-45}}>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
          <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
            {i18n.t('userSettings.profile.title')}
          </Text>

          

        </View>
        <View >
        <Image source={user_photo ? {uri:user_photo} : require('../assets/gray-image-placeholder.png')} style={{width:250, height:250, alignSelf:'center', borderRadius:250}}/>
        <TouchableOpacity style={{position:'absolute', bottom:0, right:250, backgroundColor:'#FF6D00', borderRadius:50, padding:10, margin:10}} onPress={()=>{
          Alert.alert(i18n.t('userSettings.profile.change_photo'), i18n.t('userSettings.profile.change_photo_body'), [
            {
              text: i18n.t('userSettings.profile.cancel'),
              onPress: () => console.log('Cancel Pressed'),
              style: 'cancel'
            },
            { text: i18n.t('userSettings.profile.camera'), onPress: () => openCamera() },
            { text: i18n.t('userSettings.profile.gallery'), onPress: () => openGallery() }

          ], { cancelable: true });

        }}>
          <Text style={{color:'white', fontSize:18}}>
            {/* {i18n.t('userSettings.profile.change_photo')} */}
            <Feather name="camera" size={24} color="white" />
          </Text>
        </TouchableOpacity>


        </View>
        <View style={{flexDirection:'row',alignItems:'center', justifyContent:'center', paddingTop:10}}>
          <TouchableOpacity style={{padding:8, backgroundColor:'#FF6D00', borderRadius:10, marginHorizontal:2}} onPress={()=>{
            shareProfile();
          }}>
            <Text style={{color:"#ffffff", fontFamily:'Inter-Bold'}}>
            {i18n.t('userSettings.profile.share_profile')}

            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={{padding:8, backgroundColor:'#e0e0e0', borderRadius:10, marginHorizontal:2}} onPress={()=>{
            navigation.navigate('PublicUserNav', {user_id:uid})
          }}>
            <Text style={{fontFamily:'Inter-Medium'}}>
            {i18n.t('userSettings.profile.see_public_profile')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity style={{padding:8, backgroundColor:'#e0e0e0', borderRadius:6, marginHorizontal:2}} onPress={async ()=>{
                    try{
                      const payload ={
                        dynamicLinkInfo:{
                            domainUriPrefix: 'https://menucitos.page.link',
                            link: 'https://menucitos.com/drawer/usersettings/profile/public/'+uid,
                            androidInfo:{
                                androidPackageName:'com.nelson_arguera.menu',
                            },
                            iosInfo:{
                                iosBundleId: 'com.nelsonarguera.menu',
                                iosAppStoreId: 'id1643431406',
                            },
                            socialMetaTagInfo:{
                                socialTitle: user_name + ' ' + user_lastname,
                                socialDescription: i18n.t('userSettings.profile.check_out_my_profile'),
                                socialImageLink: user_thumbnail,
                            }
                        },
                        /**ios */
                    };
                    let apiKey ="AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U"
                    // const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';            `;
                    const url = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyDnVNgoYXa8PiXobhyDmDXcfABbgMR-b8U';
                    let json;
                let shortLink;
                const response = await fetch(url, {
                    method: 'POST',
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                }).then(res => {
                    res.json().then(async (data) => {
                        // console.log(data);
                        json = data;
                        setShortURL(json.shortLink);
                        setModalQR(true);

                    })
                }).catch(err => {console.log("WHAT",JSON.stringify(err))});
    
                    }catch(err){
                      console.log('err', err);
                    }
                  }}>
            <Ionicons name="qr-code-outline" size={20} color="#000"/>
          </TouchableOpacity>
        </View>
        <View style={{marginTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Medium'}}>
            {i18n.t('userSettings.profile.label_email')}
          </Text>
          {(!user_email) ? (
            <Text>
              No Email data
            </Text>
          ): null}
          {(user_email) ? (
            <Text style={{fontSize:22, color:'#1e76e8', fontFamily:"Inter-Medium", letterSpacing:-0.5}}>
              {user_email}
            </Text>
          ): null}
          {(!user_name || editing ) ? (
            <View>
              <Text style={{fontSize:18, }}>
                {i18n.t('userSettings.profile.label_name_placeholder')}
              </Text>
              <View style={{backgroundColor:'#B0BEC5', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <TextInput
                  placeholder="Not Faraz"
                  onChangeText={val => setuser_name(val)}
                  onFocus={() => setEditing(true)}
                  style={{fontSize:22, flexDirection:'row'}}
                />
                <TouchableOpacity
                  style={{width:32, height:32, backgroundColor:'#1e76e8', alignItems:'center', justifyContent:'center', borderRadius:6}}
                  onPress={() => {
                    updateUser({
                      variables: {
                        uid,
                        user_name
                      }
                    });
                    setEditing(false);
                  }}
                >
                  <Feather name='check' size={16} color='#fff' />
                </TouchableOpacity>
              </View>
            </View>
            
          ): null}
          {(user_name && !editing) ? (
            <View>
              <Text style={{fontSize:18,  fontFamily:'Inter-Medium'}}>
                {i18n.t('userSettings.profile.label_name')}
              </Text>
              <View style={{marginTop:2, flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Text
                  style={{fontSize:22, color:'#1e76e8', fontFamily:"Inter-Medium", letterSpacing:-0.5}}>
                  {user_name}
                </Text>
                <TouchableOpacity
                  style={{width:32, height:32, backgroundColor:'#1e76e8', alignItems:'center', justifyContent:'center', borderRadius:6}}
                  onPress={() => setEditing(true)}
                >
                  <Feather name='edit' size={16} color='#fff' />
                </TouchableOpacity>
              </View>
            </View>
          ) : null}
        {(!user_lastname || editingLastName) ? (
            <View>
              <Text style={{fontSize:18}}>
                {i18n.t('userSettings.profile.label_last_name_placeholder')}
              </Text>
              <View style={{backgroundColor:'#B0BEC5', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <TextInput
                  placeholder="Not Faraz"
                  onChangeText={val => setuser_lastname(val)}
                  onFocus={() => setEditingLastName(true)}
                  style={{fontSize:22, flexGrow:1}}
                />
                <TouchableOpacity
                  style={{width:32, height:32, backgroundColor:'#1e76e8', alignItems:'center', justifyContent:'center', borderRadius:6}}
                  onPress={() => {
                    updateLastName({
                      variables: {
                        uid,
                        user_lastname
                      }
                    }).catch(err => console.log(err));
                    setEditingLastName(false);
                  }}
                >
                  <Feather name='check' size={16} color='#fff' />
                </TouchableOpacity>
                
              </View>
              </View>
        ): null}

        {(user_lastname && !editingLastName) ? (
            
            <View>
              <Text style={{fontSize:18,  fontFamily:'Inter-Medium'}}>
                {i18n.t('userSettings.profile.label_last_name')}
              </Text>
              <View style={{marginTop:2, flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                <Text
                  style={{fontSize:22, color:'#1e76e8', fontFamily:"Inter-Medium", letterSpacing:-0.5}}>
                  {user_lastname}
                </Text>
                <TouchableOpacity
                  style={{width:32, height:32, backgroundColor:'#1e76e8', alignItems:'center', justifyContent:'center', borderRadius:6}}
                  onPress={() => setEditingLastName(true)}
                >
                  <Feather name='edit' size={16} color='#fff' />
                </TouchableOpacity>
              </View>
              <Text style={{fontSize:18,  fontFamily:'Inter-Medium'}}>
              {i18n.t('userSettings.profile.label_language')}
              </Text>
              <Picker
              selectedValue={selected_language}
              onValueChange={handlerPickerLanguage}
              >
              <Picker.Item label={i18n.t('userSettings.profile.picker_label_language')} value={0}/>
              <Picker.Item label={i18n.t('userSettings.profile.picker_EN')} value={'en'}/>
              <Picker.Item label={i18n.t('userSettings.profile.picker_ES')} value={'es'}/>
              </Picker>
            </View>     
          ) : null}
        </View>
        {/* Delete Acocunt Button */}
        <View style={{position:'relative', bottom:0}}>
          <TouchableOpacity onPress={()=>{
            Alert.alert(
              i18n.t('userSettings.profile.delete_account_title'),
              i18n.t('userSettings.profile.delete_account_body'),
              [
                {
                  text: i18n.t('userSettings.profile.alert_button_cancel'),
                  onPress: () => console.log('Cancel Pressed'),
                  style: 'cancel'
                },
                { text: i18n.t('userSettings.profile.alert_button_delete'), onPress: () => deleteUser() }
              ],
              { cancelable: false }
            );
          }}>
            <Text style={{color:'#1e76e8', fontSize:18, textAlign:'center', fontFamily:"Inter-Medium"}}>
              {i18n.t('userSettings.profile.delete_account')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
    </SafeAreaView>
  );
}

export default UserData;