import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, FlatList, Image } from 'react-native';
import { useMutation, useSubscription } from '@apollo/client';
import Firebase from '../../lib/firebase';
import { CREATE_CHAT_FROM_PRODUCT, CREATE_CHAT_MYSELF_FROM_PRODUCT, GET_ORDERS_REALTIME_BY_SELLER_UID } from '../../lib/queries';
import i18n from '../../i18n/i18n';
import { getAuth } from 'firebase/auth';

const auth = getAuth(Firebase);

const OrdersSeller = ({navigation}) => {

  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();


  const uid = getAuth(Firebase).currentUser.uid;
  const [orders, setOrders] = useState([]);
  const {loading:loadingOrders,error:errorOrders, data:dataOrders} = useSubscription(GET_ORDERS_REALTIME_BY_SELLER_UID, {variables:{
    sellerUid: uid,
    languageCode: languageCode
  }});

  const [createChat, {loading:loadingCreateChat}] = useMutation(CREATE_CHAT_FROM_PRODUCT)
  const [createChatMyself, {loading:createChatMyselfLoading, data:createChatMyselfData, error:createChatMyselfError}] = useMutation(CREATE_CHAT_MYSELF_FROM_PRODUCT);

  useEffect(() => {
    // console.log(errorOrders)
    // console.log("DATA ORDERS: ", errorOrders)
    // console.log('OrdersSeller useEffect: ', dataOrders);
    if(dataOrders){
    const {user_orders} = dataOrders;
    setOrders(user_orders);
    // console.log('OrdersSeller useEffect: ', user_orders);
    }
  })

  const OrderItem = ({order, order_id, delivery_option_name, user_name, user_lastname, payment_option_name, order_status, user_expo_token, delivery_cost,
    transaction_status, transaction_total, product_currency, order_reviewed, transaction_status_id, customerUid, chat_id, order_delivered, organization_thumbnail, organization_name, organization_id, order_type_id, currency_code}) => {
    return(
      <View style={{marginVertical:2, paddingVertical:15, borderColor:'#B0BEC5', borderWidth:1, borderRadius:6, paddingHorizontal:9}}>
        {order_type_id === 1 ? (
          <>
          <Text style={{fontSize:22, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.order_id')} #{order_id}</Text>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.customer')} </Text>
          <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>{user_name} {user_lastname}</Text>

        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.delivery_type')} </Text>
          <Text style={{fontSize:18, flexShrink:1, fontFamily:'Inter-Regular'}}>{delivery_option_name}</Text>
        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.payment_type')} </Text>
          <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>{payment_option_name}</Text>
        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.order_status')} </Text>
          <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>{order_status}</Text>
        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.transaction_status')} </Text>
          {(transaction_status_id ===1)?(
            <Text style={{fontSize:18, backgroundColor:'#81C784', paddingHorizontal:9, borderRadius:6, fontFamily:'Inter-Regular'}}>{transaction_status}</Text>
          ):(transaction_status_id ===2)?(
            <Text style={{fontSize:18, backgroundColor:'#FFA726', paddingHorizontal:9, borderRadius:6, fontFamily:'Inter-Regular'}}>{transaction_status}</Text>
          ):(transaction_status_id ===3)?(
            <Text style={{fontSize:18, backgroundColor:'#FF8A80', paddingHorizontal:9, borderRadius:6, fontFamily:'Inter-Regular'}}>{transaction_status}</Text>
          ):null}
        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.order')} </Text>
          {(order_reviewed) ? <Text style={{fontSize:18, backgroundColor:'#81C784', borderRadius:6, paddingHorizontal:9, fontFamily:'Inter-Regular'}}>{i18n.t('ordersCustomers.reviewed')}</Text> : <Text style={{fontSize:18, backgroundColor:'#FF8A80', borderRadius:6, paddingHorizontal:9}}>{i18n.t('ordersCustomers.not_reviewed')}</Text>}
        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.marked_as')} </Text>
          {(order_delivered) ? <Text style={{fontSize:18, backgroundColor:'#81C784', borderRadius:6, paddingHorizontal:9, fontFamily:'Inter-Regular'}}>{i18n.t('ordersCustomers.delivered')}</Text> : <Text style={{fontSize:18, backgroundColor:'#FF8A80', borderRadius:6, paddingHorizontal:9}}>{i18n.t('ordersCustomers.not_delivered')}</Text>}
        </View>
        <View style={{flexDirection:'row', paddingTop:10}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.delivery_cost')} </Text>
          <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>{delivery_cost} {product_currency}</Text>
        </View>
        {(organization_id)? (
          <View style={{flexDirection:'row', paddingTop:10, alignItems:'center'}}>
          <Image source={{uri:organization_thumbnail}} style={{width:50, height:50, borderRadius:50}}/>
          <View style={{paddingLeft:10}}>
              <Text style={{fontSize:18, fontFamily:'Inter-Black'}}>{organization_name}</Text>
          </View>

      </View>
        ):null}
        <View style={{flexDirection:'row', alignItems:'center', flexGrow:1, paddingTop:10}}>
                    <TouchableOpacity style={{paddingVertical:9, backgroundColor:'#304FFE',flexGrow:1, marginRight:6, borderRadius:6 }}
                    onPress={()=>{

                        navigation.navigate('ReviewOrder',{
                            order_id:order_id,
                        })
                    }}>
                        <Text style={{textAlign:'center', color:'#FFFFFF', fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.btn_details')}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{alignItems:'center' ,paddingHorizontal:15, backgroundColor:'#000000', paddingVertical:9, marginHorizontal:6, borderRadius:6}}
                    onPress={()=>{
                      if(chat_id !== null){
                        // console.log('chat_id: ', chat_id);
                        navigation.navigate('Messages',{
                          chatId:chat_id,
                          sellerId:customerUid,
                          expoToken:user_expo_token,
                        })
                      }else{
                        createChat({variables:{
                          senderId:uid,
                          recipientId:customerUid,
                        }}).then(res=>{
                          console.log(res);
                          const chatSellerId = res.data.insert_chat_one.chat_id;
                          const sellerId = uid;
                          navigation.navigate('Messages', {
                              sellerId,
                              chatId:chatSellerId,
                              expoToken:user_expo_token,
                          })
                        })
                      }
                      console.log('chat_id: ', chat_id);
                      console.log('customerUid: ', customerUid);
                    }}>
                    <Text style={{color:'#FFFFFF', fontWeight:'bold', textAlign:'center'}}>Chat</Text>
                </TouchableOpacity>
                    <Text style={{fontWeight:'bold', fontSize:18, textAlign:'right'}}>{i18n.t('ordersCustomers.total')}</Text>
                    <Text style={{fontSize:18, textAlign:'right'}}>{((transaction_total*100)/100).toFixed(2)} {product_currency}</Text>
                </View>
          </>
        ):null}
        {(order_type_id === 2)?(
          <>
          <Text style={{fontSize:22, fontFamily:'Inter-Black'}}>{i18n.t('ordersCustomers.order_id')} #{order_id}</Text>
          <View>
            <Text style={{fontSize:18}}>  
              {i18n.t('ordersCustomers.transaction_description_seller', {
                transaction_total:transaction_total,
                user_name: user_name,
                currency_code: currency_code,
              })}
            </Text>
          </View>
          </>
        ):null}
      </View>
    )
  }

  const renderOrders = ({item}) => {
    let chat_id = null;

    if(item.order.user.uid === uid){
      console.log('item.order.user.chat_myself: ', item.order.user.chat_myself);
      if(item.order.user.chat_myself[0] !== undefined){
        chat_id = item.order.user.chat_myself[0].user_chat_chat_id
      }
    }else{
      console.log('item.order.user.user_chats: ', item.order.user.user_chats);
      if(item.order.user.user_chats[0] !== undefined){
        chat_id = item.order.user.user_chats[0].user_chat_chat_id
      }
    }

 

    return(
      <OrderItem item={item}
      order_id={item.order.order_id}
      user_name={item.order.user.user_name}
      user_lastname={item.order.user.user_lastname}
      user_expo_token={item.order.user.user_expo_token ? item.order.user.user_expo_token : null}
      customerUid={item.order.user.uid}
      delivery_option_name={item.order.country_delivery_option ? item.order.country_delivery_option.delivery_option ? item.order.country_delivery_option.delivery_option.delivery_option_translations[0].delivery_option_name : null : null}
      payment_option_name={item.order.country_payment_option ? item.order.country_payment_option.payment_option ? item.order.country_payment_option.payment_option.payment_option_translations[0].payment_option_name : null : null}
      order_status={item.order.order_status.order_status_translations[0].order_status_name}
      transaction_status={item.order.transactions[0] ? item.order.transactions[0].transaction_status.transaction_status_translations[0].transaction_status_name : null}
      transaction_total={item.order.transactions_aggregate.aggregate.sum.transaction_total}
      product_currency={item.order.transactions[0] ? item.order.transactions[0].product_currency ? item.order.transactions[0].product_currency.currency_code : null : null}
      order_reviewed={item.order.order_reviewed}
      chat_id={chat_id}
      transaction_status_id={item.order.transactions[0] ? item.order.transactions[0].transaction_status_id : null}
      order_delivered={item.order.order_delivered}
      organization_name={item.order.organization ? item.order.organization.organization_name : null}
      organization_thumbnail={item.order.organization ? item.order.organization.organization_thumbnail : null}
      organization_id={item.order.order_organization_id}
      delivery_cost={item.order.order_delivery_fees}
      order_type_id={item.order.order_type_id}
      currency_code={item.order.transactions[0] ? item.order.transactions[0].transaction_currency_code : null}
      />
    )
  }

  function header(){
    return(
      <View>
        <Text style={{fontSize:32, fontFamily:'Inter-Black'}}>
          {i18n.t('ordersCustomers.title')}
        </Text>
      </View>
    )
  }
  return (
    <SafeAreaView style={{flex:1, paddingHorizontal:9, paddingTop:-45}}>
      <View style={{flex:1}}>
        {(orders)?(
          <FlatList ListHeaderComponent={header} data={orders} renderItem={renderOrders} keyExtractor={item => item.order.order_id.toString()}></FlatList>
        ):null}
      </View>
    </SafeAreaView>
  );
}

export default OrdersSeller;