import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, Alert, Image, ScrollView, Touchable, Share, Platform} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { useQuery, useMutation } from '@apollo/client';
import { Feather, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_USER, UPDATE_USER_NAME, UPDATE_USER_LASTNAME, UPDATE_USER_PHOTO, UPDATE_USER_THUMBNAIL, GET_IF_CHAT_EXISTS, CREATE_CHAT_FROM_PRODUCT, GET_USER_CURRENCIES_BY_UID, GET_USER_COUNTRY_PAYMENT_OPTIONS, CREATE_ORDER_CASH_PAYEE, INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT } from '../../lib/queries';
import i18n from '../../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-native-modal'
import QRCode from 'react-native-qrcode-svg';
import RNQRGenerator from 'rn-qr-generator';
import * as Sharing from 'expo-sharing';
import { parse } from 'expo-linking';
import { FlatList } from 'react-native-gesture-handler';
import { useIsFocused } from '@react-navigation/native';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { initPaymentSheet, presentPaymentSheet } from '@stripe/stripe-react-native';
//import initPaymentSheet, pressentPaymentSheet only native 

const initPaymentSheet = Platform.select({
  web: () => null,
  native: () => require('@stripe/stripe-react-native').initPaymentSheet,
})()

const presentPaymentSheet = Platform.select({
web: () => null,
native: () => require('@stripe/stripe-react-native').presentPaymentSheet,
})()

const functions = getFunctions(Firebase);
const auth = getAuth(Firebase);
const storage = getStorage(Firebase);

const UserPay = ({navigation, route}) => {

  const { user_id } = route.params;

  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  const isFocused = useIsFocused();

  const uid = getAuth().currentUser.uid;
  const [editing, setEditing] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false)
  const [user_name, setuser_name] = useState('');
  const [user_lastname, setuser_lastname] = useState('')
  const [user_email, setuser_email] = useState('')
  const [user_photo, setuser_photo] = useState('')
  const [user_thumbnail, setuser_thumbnail] = useState('')
  const [selected_language, setSelected_language] = useState(0);
  const [chatId, setChatId] = useState();
  const [chatMyselfId, setChatMyselfId] = useState();
  const [sellerExpoToken, setSellerExpoToken] = useState();
  const [modalQR, setModalQR] = useState(false);
  const [shortURL, setShortURL] = useState('');

  const [amount, setAmount] = useState('');
  const [userCurrencies, setUserCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [userCountryPaymentOptions, setUserCountryPaymentOptions] = useState([]);
  const [customerCountryPaymentOptions, setCustomerCountryPaymentOptions] = useState('');
  const [selectedCountryPaymentOption, setSelectedCountryPaymentOption] = useState();
  const [currencyCodeStripeFee, setCurrencyCodeStripeFee] = useState('');

  const [modalVisibilityIndicator, setModalVisibilityIndicator] = useState(false);


  const { loading: fetchLoading, data } = useQuery(GET_USER, { variables: { uid: user_id }});
  const {loading: userCurrenciesLoading, data: userCurrenciesData, error: userCurrenciesError, refetch:refetchUserCurrencies} = useQuery(GET_USER_CURRENCIES_BY_UID, {variables: {uid: uid, payee: user_id}})
  const {loading: userCountryPaymentOptionsLoading, data: userCountryPaymentOptionsData, error: userCountryPaymentOptionsError, refetch:refetchUserCountryPaymentOptions} = useQuery(GET_USER_COUNTRY_PAYMENT_OPTIONS, {variables: {payee: user_id, currencyCode: selectedCurrency, languageCode: languageCode}})

  const {loading: loadingChat, data: dataChat, error: errorChat} = useQuery(GET_IF_CHAT_EXISTS, {variables: {recipientId: user_id, senderId: uid}})

  const [createChat, {loading:createChatLoading, data:createChatData, error:createChatError}] = useMutation(CREATE_CHAT_FROM_PRODUCT, {variables: {recipientId: user_id, senderId: uid}});
  const [createChatMyself, {loading:createChatMyselfLoading, data:createChatMyselfData, error:createChatMyselfError}] = useMutation(CREATE_CHAT_FROM_PRODUCT);
  const [createUserPaymentOptionFromProduct, {loading:createUserPaymentOptionFromProductLoading, error:createUserPaymentOptionFromProductError}] = useMutation(INSERT_USER_PAYMENT_OPTION_FROM_PRODUCT);


  //create order cash payee
  const [createOrderCashPayee, {loading:createOrderCashPayeeLoading, data:createOrderCashPayeeData, error:createOrderCashPayeeError}] = useMutation(CREATE_ORDER_CASH_PAYEE);
  useEffect(() => {


    let newName = null;
    let newLastName = null;
    let newUserEmail = null;
    let newPhoto = null;
    let newThumbnail = null;
    let newExpoToken = null;
    let newUserCurrencies = null;
    let newUserCountryPaymentOptions = null;
    let newCustomerCountryPaymentOptions = null;

    // console.log("FIREBASEUID: ", uid)
    // console.log('error: ', errorChat)
    if (data) {

      // console.log("DATOS: ", data)
      const { users_by_pk: user } = data;
      const { user_name } = user;
      const { user_lastname } = user;
      const { user_email} = user;
      const { user_photo } = user;
      const { user_thumbnail } = user;
      const { user_expo_token } = user;


      // if (user_name === null){
      //   setuser_name('Empty')
      //   // console.log(user_name)
      // }
      {/* if a name already exists, set it as the name */}
      newName = user_name;
      newLastName = user_lastname
      newUserEmail = user_email
      newPhoto = user_photo
      newThumbnail = user_thumbnail
      newExpoToken = user_expo_token

      // console.log('newPhoto: ', newPhoto)
    }
    if(userCurrenciesError){
        console.log('userCurrenciesError: ', userCurrenciesError)
    }
    if (userCurrenciesData){
        const { user_currencies, users_by_pk } = userCurrenciesData;
        console.log('user_currencies: ', user_currencies)
        newUserCurrencies = user_currencies

        if(Object.keys(user_currencies).length === 0){
            setSelectedCurrency(null)
        }else{
            setSelectedCurrency(user_currencies[0].user_currency_code);
            setCurrencyCodeStripeFee(user_currencies[0].currency.currency_code_stripe_fee);
            console.log('selectedCurrency: ', selectedCurrency)
            console.log('currencyCodeStripeFee: ', currencyCodeStripeFee)
        }

        if(Object.keys(users_by_pk).length === 0){
            setCustomerCountryPaymentOptions(null)
        }else{
            setCustomerCountryPaymentOptions(users_by_pk.user_country_payment_options)
            console.log('customerCountryPaymentOptions: ', customerCountryPaymentOptions)
        }
    }

    if(userCountryPaymentOptionsData){
      const { user_country_payment_option } = userCountryPaymentOptionsData;
      console.log('user_country_payment_option: ', user_country_payment_option)
      newUserCountryPaymentOptions = user_country_payment_option
    }

    if (dataChat) {
        const { chat } = dataChat;
        if(Object.keys(chat).length === 0){
            setChatId(null)
        }else{
            setChatId(chat[0].chat_id);
        }

        const { chat_myself } = dataChat;
        if(Object.keys(chat_myself).length === 0){
            setChatMyselfId(null)
        }else{
            setChatMyselfId(chat_myself[0].chat_id);
        }
    }

    if(isFocused){
      refetchUserCurrencies()
      refetchUserCountryPaymentOptions()
    }

    setuser_name(newName);
    setuser_lastname(newLastName)
    setuser_email(newUserEmail)
    setuser_photo(newPhoto)
    setuser_thumbnail(newThumbnail)
    setSellerExpoToken(newExpoToken)
    setUserCurrencies(newUserCurrencies)
    setUserCountryPaymentOptions(newUserCountryPaymentOptions)
    // console.log('language: ', language)
  }, [data, dataChat, userCurrenciesData, userCountryPaymentOptionsData, customerCountryPaymentOptions, isFocused, selectedCurrency, userCurrencies]);

  const ItemUserCountryPayment = ({ item, country_code, payment_option_name, stripeUserId, country_payment_options_id}) => {
    return (
      <View>
        <TouchableOpacity style={{flexGrow:1, backgroundColor:'#FF6D00', marginTop:10, paddingVertical:10, borderRadius:6}} onPress={async()=>{
          // console.log('stripeUserId: ', stripeUserId)
          console.log('country_payment_options_id: ', country_payment_options_id)
          // setModalVisibilityIndicator(true);

          if(amount != null && amount != 0){
            if(stripeUserId === null){
            }else{
              setSelectedCountryPaymentOption(3);
              // find if customer have a customer id from the same country payment option
              let customerCountryPaymentOption;
              customerCountryPaymentOptions.map(item => {
                if(item.country_payment_options_id === country_payment_options_id){
                    customerCountryPaymentOption = item;
                    console.log("CUSTOMER COUNTRY PAYMENT OPTION: ", customerCountryPaymentOption)
                }else{
                }
            })
  
            async function showPaymentSheet(stripeCustomerId) {
              console.log("CUSTOMER HAVE A STRIPE CUSTOMER ID for this country payment option")
                  //create paymentIntent
                  setModalVisibilityIndicator(true);
                  
                  const createStripePaymentIntent = httpsCallable(functions, 'createStripePaymentIntent');

                  let transactionSellerFee; 

                  transactionSellerFee = currencyCodeStripeFee;
  
                  let amountInCents = amount * 100;
                  let feeInCents = amount * 0.05;
                  let amountFeeInCents = feeInCents.toFixed(2) * 100;
                  let transactionTotal = amountInCents + amountFeeInCents + transactionSellerFee;
  
                  console.log("AMOUNT: ", amount)
                  console.log("AMOUNT IN CENTS: ", amountInCents)
                  console.log("FEE: ", feeInCents)
                  console.log("FEE IN CENTS: ", amountFeeInCents)
                  console.log("TRANSACTION TOTAL: ", transactionTotal)
  
  
                  const { data } = await createStripePaymentIntent({
                      amount: transactionTotal,
                      currency: selectedCurrency,
                      userStripeCustomerId: stripeCustomerId,
                      userStripeId: stripeUserId,
                  });
  
                  console.log("DATA: ", data)
                  let clientSecret = data.paymentIntent.client_secret;
                  let ephemeralKeySecret = data.ephemerelKey.secret;
  
                  console.log("CLIENT SECRET: ", clientSecret)
  
                  // init paymentSheet
  
                  const { error } = await initPaymentSheet({
                      merchantDisplayName: "Menucitos",
                      customerId: stripeCustomerId,
                      customerEphemeralKeySecret: ephemeralKeySecret,
                      paymentIntentClientSecret: clientSecret,
                      googlePay: true,
                  });
                  
                  if(error){
                      console.log("ERROR: ", error)
                      setModalVisibilityIndicator(false);
                  }else{
                      console.log("PAYMENT SHEET INITIALIZED")
                      setModalVisibilityIndicator(false);
                      const {error} = await presentPaymentSheet({
                          confirmPayment: true,
                      });
  
                      if(error){
                          console.log("ERROR: ", error)
                      }else{
                          console.log("Payment successful")
                          
                          // create a transaction

                        createOrderCashPayee({
                          variables:{
                            customerUid: uid,
                            countryPaymentOptionId: selectedCountryPaymentOption,
                            transactionTotal: amount,
                            transactionFee: amountFeeInCents * 0.01,
                            sellerUid: user_id,
                            transactionSellerFee: transactionSellerFee * 0.01,
                            currencyCode: selectedCurrency,

                          }
                        }).then((res) => {
                          Alert.alert(
                            "Orden creada",
                            "Tu orden ha sido creada exitosamente",
                            [
                              { text: "OK", onPress: () => console.log("OK Pressed") }
                            ]
                          );
                          // setModalVisibilityIndicator(false);

                        }).catch((error) => {
                          console.log("error: ", error)
                        })

                      }
  
                  }
            }
  
            if(customerCountryPaymentOption){
              if(customerCountryPaymentOption.user_stripe_customer_id){
                console.log(customerCountryPaymentOption.user_stripe_customer_id);
                showPaymentSheet(customerCountryPaymentOption.user_stripe_customer_id);
              }else{
                console.log("NO STRIPE CUSTOMER ID");
                // create a customer
                const idUserCountryPaymentOptionId = customerCountryPaymentOption.id_user_country_payment_option;
                const savePaymentMethodSetupIntentFromProduct = httpsCallable(functions, 'savePaymentMethodSetupIntentFromProduct');
                let stripeCustomerId;
                let ephemeralKeySecret;
                let setupIntent;
  
                const { data } = await savePaymentMethodSetupIntentFromProduct({
                    idUserCountryPaymentOptionId: idUserCountryPaymentOptionId,
                });
  
                ephemeralKeySecret = data.ephemerelKey.secret;
                setupIntent = data.setupIntent.client_secret;
                stripeCustomerId = data.customer.id;
  
                console.log("Stripe Customer Id: ", stripeCustomerId);
  
                const { error } = await initPaymentSheet({
                    merchantDisplayName: "MenuPay",
                    customerEphemeralKeySecret: ephemeralKeySecret,
                    setupIntentClientSecret: setupIntent,
                    customerId: stripeCustomerId,
                })
  
                if(!error){
                    console.log("PAYMENT SHEET INITIALIZED SETUP INTENT");
  
                    const {error} = await presentPaymentSheet({
                        clientSecret: setupIntent,
                    }).then(async res => {
                        console.log("PAYMENT SHEET COMPLETED FILLING");
                        console.log(res);
                    }).catch(err => {
                        console.log("ERROR: ", err);
                    })
  
                    if(error){
                        console.log("ERROR: ", error);
                    }else{
                        console.log("PAYMENT SHEET PRESENTED");
                        console.log("PAYMENT SHEET PRESENTED");
                        presentPaymentSheet(stripeCustomerId);
                        
                    }
                }else {
                    console.log("ERROR: ", error);
                }
                
              }
            }else{
              console.log("NO COUNTRY PAYMENT OPTION");
              setModalVisibilityIndicator(true);
                  createUserPaymentOptionFromProduct({
                      variables:{
                          uid: uid,
                          countryPaymentOptionId: country_payment_options_id,
                      }
                  }).then(async res => {
                      // refetchProductDetail();
                      let userCountryPaymentOptionId = res.data.insert_user_country_payment_option_one.id_user_country_payment_option;

                      const savePaymentMethodSetupIntentFromProduct = httpsCallable(functions, 'savePaymentMethodSetupIntentFromProduct');
                      let stripeCustomerId;
                      let ephemeralKeySecret;
                      let setupIntent;
                      const { data } = await savePaymentMethodSetupIntentFromProduct({
                          idUserCountryPaymentOptionId: userCountryPaymentOptionId,
                      });

                      ephemeralKeySecret = data.ephemerelKey.secret;
                      setupIntent = data.setupIntent.client_secret;
                      stripeCustomerId = data.customer.id;

                      console.log("Stripe Customer Id: ", stripeCustomerId);
                      console.log("Ephemeral Key Secret: ", ephemeralKeySecret);
                      console.log("Setup Intent: ", setupIntent);

                      const { error } = await initPaymentSheet({
                          merchantDisplayName: "MenuPay",
                          customerEphemeralKeySecret: ephemeralKeySecret,
                          setupIntentClientSecret: setupIntent,
                          customerId: stripeCustomerId,
                      })

                      if(!error){
                          console.log("PAYMENT SHEET INITIALIZED");
                          // setModalVisibilityIndicator(false);
                          const {error} = await presentPaymentSheet({
                              clientSecret: setupIntent,
                              
                          }).then(async res => {
                              console.log("PAYMENT SHEET FINISHED");
                              // await showPaymentSheet(stripeCustomerId);
                              console.log(res);
                              if(res.error){
                                  console.log("ERROR: ", res.error);
                                  setModalVisibilityIndicator(false);

                              } else {
                                  console.log("PAYMENT SHEET PRESENTED");
                                  // console.log("PAYMENT SHEET PRESENTED");
                                  await showPaymentSheet(stripeCustomerId);
                              }
                          }).catch(err => {
                              console.log("ERROR: ", err);
                              setModalVisibilityIndicator(false);
                          })

                          if(error){
                              console.log("ERROR: ", error);
                              setModalVisibilityIndicator(false);
                          }else{
                              console.log("PAYMENT SHEET PRESENT PAYMENT SHEET PRESENTED");
                              // console.log("PAYMENT SHEET PRESENTED");
                              await showPaymentSheet(stripeCustomerId);
                          }
                      }else {
                          console.log("ERROR: ", error);
                          setModalVisibilityIndicator(false);
                      }
                  }).catch(err => {})
            }
            }
          }  
        }}>
          <Text style={{textAlign:'center', color:"#FFFF", fontFamily:'Inter-Bold', fontSize:16}}>
            {country_code} - {payment_option_name}
          </Text>
          <Text style={{paddingHorizontal:5, textAlign:'center'}}>
            {i18n.t('userSettings.userPay.fee_description',{
              fee: currencyCodeStripeFee / 100,
              currency_code: selectedCurrency
            })}
          </Text>
        </TouchableOpacity>
      </View>
    )
  }

  const renderItemUserCountryPayments = ({ item }) => {
    return (
      <ItemUserCountryPayment item={item} 
      country_code={item.country_payment_option.country.country_code}
      payment_option_name={item.country_payment_option.payment_option.payment_option_translations[0].payment_option_name}
      stripeUserId={item.user_stripe_id}
      country_payment_options_id={item.country_payment_option.country_payment_options_id}
      /> 
    )
  }


  if (fetchLoading || loadingChat) return (
    // flex-1 justify-center items-center
    <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
      <ActivityIndicator color={'#1e76e8'} size={"small"}/>
    </SafeAreaView>
  )
  return (
    <SafeAreaView style={{flex:1, backgroundColor:'#ffffff'}}>

      {/* // Modal Activity Indicator */}
      <Modal isVisible={modalVisibilityIndicator}
      backdropColor={'rgba(0,0,0,0.5)'}

      animationIn={'slideInUp'}
      animationOut={'slideOutDown'}
      animationInTiming={500}
      animationOutTiming={500}
      backdropTransitionInTiming={500}
      backdropTransitionOutTiming={500}
      onBackButtonPress={()=> setModalVisibilityIndicator(false)}
      onBackdropPress={()=> setModalVisibilityIndicator(false)}
      style={{elevation:5,justifyContent:'center', alignItems:'center'}}
      >
        <View style={{}}>
          <ActivityIndicator color={'#FF6D00'} size={"large"}/>
          <TouchableOpacity style={{marginTop:20, borderColor:"#FF6D00", borderWidth:2, borderRadius:6}} onPress={() => {
                    setModalVisibilityIndicator(false)
                }}>
                    <Text style={{color:'#FF6D00', paddingHorizontal:10}}>Close</Text>
                </TouchableOpacity>
        </View>
      </Modal>
      
         <Modal isVisible={modalQR}
        backdropColor={'rgba(0,0,0,0.5)'}
          // backdropColor='black'
          backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={()=> setModalQR(false)}
        onBackdropPress={()=> setModalQR(false)}
        style={{elevation:5,justifyContent:'flex-end', margin:0, height:450, flex:0, bottom:0, position:'absolute', width:'100%', borderRadius:6}}
        >
          <View style={{backgroundColor:'white', padding:22, height:450, borderRadius:10, borderColor:'rgba(0, 0, 0, 0.1)'}}>
          <TouchableOpacity onPress={()=> setModalCategoryFilter(false)}>
            <Text style={{fontSize:24, fontFamily:'Inter-Black'}}>
              {i18n.t('userSettings.profile.qr_code')}
            </Text>
          </TouchableOpacity>
          <Text style={{fontSize:16, fontFamily:'Inter-Regular'}}>
            {i18n.t('userSettings.profile.qr_code_body')}
          </Text>
          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10}}>
          <QRCode value={shortURL} size={200}/>
          </View>

          <TouchableOpacity style={{backgroundColor:'#1e76e8', padding:10, borderRadius:16, marginTop:10}}
          onPress={()=>{
            if(Platform.OS !== 'web'){
              RNQRGenerator.generate({
                value: shortURL,
                height: 200,
                width: 200,
                color: '#000000',
              }).then(response =>{

                Sharing.shareAsync(response.uri)

              }).catch(error => {
                console.log(error);
              });
            }
          }}>
            <Text style={{color:'#fff', fontSize:16, fontFamily:'Inter-Black', textAlign:'center'}}>
              {i18n.t('userSettings.profile.qr_code_share')}
            </Text>
          </TouchableOpacity>

          </View>
    </Modal>
        <View style={{paddingHorizontal:5, paddingTop:-45}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
              {i18n.t('userSettings.userPay.title')}
            </Text>
          </View>
          <View style={{flexDirection:'row', paddingTop:25}}>
          <Image source={user_photo ? {uri:user_photo} : require('../../assets/gray-image-placeholder.png')} style={{width:75, height:75, alignSelf:'flex-start', borderRadius:250}}/>
            <View style={{flexDirection:'column', paddingLeft:15, flexShrink:1}}>
                <Text style={{fontSize:22, fontFamily:'Inter-Medium', flexShrink:1, letterSpacing:-0.5}}>
                    {i18n.t('userSettings.userPay.body', {user_name: user_name, amount: amount})} {selectedCurrency ? selectedCurrency : '$'}
                </Text>
            </View>
          </View>

          <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:25}}>
            <TextInput
            style={{backgroundColor:'#f2f2f2', maxWidth:250,borderRadius:10, padding:10, flex:1, marginRight:10, textAlign:'center', fontFamily:'Inter-Regular', fontSize:22, alignSelf:"center", letterSpacing:-0.5}}
            placeholder={i18n.t('userSettings.userPay.amount')}
            value={amount}
            // defaultValue='$'
            onFocus={()=> setAmount('')}
            //only 2 decimal places
            onChangeText={(text)=> {
                if(text.indexOf('.') > -1){
                    let decimalLength = text.split('.')[1].length
                    if(decimalLength > 2){
                        return
                    }
                }
                setAmount(text)
                console.log('text: ', text)
                console.log('amount: ', amount)
            }}
            keyboardType={'numeric'}
            />

            {(userCurrencies && userCurrencies.length > 0) && (
                <Picker style={{
                    backgroundColor:'#f2f2f2',
                    borderRadius:10,
                    padding:10,
                    width:100,
                    fontFamily:'Inter-Regular',
                    fontSize:28,
                }}
                selectedValue={selectedCurrency}
                onValueChange={(itemValue, itemIndex) => {
                    setSelectedCurrency(itemValue)
                    console.log('itemValue: ', itemValue)
                    console.log('itemIndex: ', itemIndex)
                }}
                >
                    {userCurrencies && userCurrencies.map((currency, index)=>(
                        <Picker.Item key={index} label={currency.user_currency_code} value={currency.user_currency_code} />
                    ))}
                </Picker>
            )}
          </View>
          <FlatList 
          data={userCountryPaymentOptions}
          style={{marginTop:25}}
          renderItem={renderItemUserCountryPayments}
          keyExtractor={item => item.id_user_country_payment_option.toString()}
          >
          </FlatList>
        </View>
   
    </SafeAreaView>
  );
}

export default UserPay;