import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Modal, ActivityIndicator, TouchableWithoutFeedback, TouchableOpacity, ImageBackground, Alert, Platform, Linking } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import Firebase from '../../lib/firebase';
import { GET_ORDER_BY_BUYER_ID, GET_PRODUCTS_BY_ORDER_ID , GET_ORDER_REALTIME_BY_ID,SET_ORDER_DELIVERED_BY_ORDER_ID, SET_ORDER_VIEWED_BY_ID, SET_TRANSACTION_STATUS_BY_ORDER_ID_FILLING, SET_TRANSACTION_STATUS_BY_ORDER_ID_COMPLETED, SET_ORDER_DELIVERED_CASH_BY_ORDER_ID} from '../../lib/queries';
import { Feather } from '@expo/vector-icons';
import { or, set } from 'react-native-reanimated';
import i18n from '../../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import * as Localization from 'expo-localization';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(Firebase);
import openMap, { createMapLink } from 'react-native-open-maps';
// import FastImage from 'react-native-fast-image';

const auth = getAuth(Firebase);
const ReviewOrder = ({navigation, route}) => {
    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();
    const {order_id} = route.params;

    const [productList, setProductList] = useState([]);
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [address3, setAddress3] = useState('');
    const [city, setCity] = useState('');
    const [zip_code, setZipCode] = useState('');
    const [province, setProvince] = useState('');
    const [notes, setNotes] = useState('');
    const [orderInfo, setOrderInfo] = useState('');
    const [transactionStatusName, setTransactionStatusName] = useState('');
    const [transactionStatusId, setTransactionStatusId] = useState('');
    const [orderReviewed, setOrderReviewed] = useState('');
    const [reviewedTimeStamp, setReviewedTimeStamp] = useState('');
    const [paymentOptionId, setPaymentOptionId] = useState('');
    const [customerExpoToken, setCustomerExpoToken] = useState('');
    const [delivered, setDelivered] = useState('');
    const [orderRealtime, setOrderRealtime] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [deliveryOptionId, setDeliveryOptionId] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');

    const {loading:loadingProductList, error:errorProductList, data:dataProductList} = useQuery(GET_PRODUCTS_BY_ORDER_ID, {variables:{
        orderId: order_id,
    }});

    const {loading:loadingOrder, error:errorOrder, data:dataOrder} = useSubscription(GET_ORDER_REALTIME_BY_ID, {variables:{
        orderId: order_id,
        languageCode: languageCode
    }});

    /**Mutation */

    const [setOrderViewed, {loading:loadingSetOrderViewed, error:errorSetOrderViewed}] = useMutation(SET_ORDER_VIEWED_BY_ID, {variables:{
        orderId: order_id,
    }});

    const [setOrderFilling, {loading:loadingSetOrderFilling, error:errorSetOrderFilling}] = useMutation(SET_TRANSACTION_STATUS_BY_ORDER_ID_FILLING);

    const [setOrderCompleted, {loading:loadingSetOrderCompleted, error:errorSetOrderCompleted}] = useMutation(SET_TRANSACTION_STATUS_BY_ORDER_ID_COMPLETED);
    
    const [setOrderDelivered, {loading:loadingSetOrderDelivered, error:errorSetOrderDelivered}] = useMutation(SET_ORDER_DELIVERED_BY_ORDER_ID);

    const [setOrderDeliveredCash, {loading:loadingSetOrderDeliveredCash, error:errorSetOrderDeliveredCash}] = useMutation(SET_ORDER_DELIVERED_CASH_BY_ORDER_ID);

    const uid = getAuth().currentUser.uid;
    // const [orders, setOrders] = useState();
    // const [modalVisible, setModalVisible] = useState(false);
    // const {loading: loadingOrders, data: dataOrders, error:errorOrders, refetch} = useQuery(GET_ORDER_BY_BUYER_ID, {variables:{customerUid:uid}});

    useEffect(() => {
        // console.log(order_id);
        // console.log(errorOrder);
        // console.log(errorProductList);
        if(dataProductList){
            const {orders_by_pk} = dataProductList;
            setProductList(orders_by_pk.order_products);
            // console.log(productList);
            setAddress1(orders_by_pk.user_location.address1);
            setAddress2(orders_by_pk.user_location.address2);
            setAddress3(orders_by_pk.user_location.address3);
            setCity(orders_by_pk.user_location.city.city_name);
            setProvince(orders_by_pk.user_location.province.province_name);
            setZipCode(orders_by_pk.user_location.zip_code);
            setNotes(orders_by_pk.notes);
            // console.log(dataOrder);
            console.log("Hey:", errorOrder, loadingOrder);
            
        }
        if(dataOrder){
            const {orders_by_pk} = dataOrder;
            setOrderInfo(orders_by_pk);
            setTransactionStatusName(orders_by_pk['transactions'][0]['transaction_status']['transaction_status_translations'][0]['transaction_status_name']);
            setTransactionStatusId(orders_by_pk['transactions'][0]['transaction_status_id']);
            setOrderReviewed(orders_by_pk['order_reviewed']);
            setReviewedTimeStamp(orders_by_pk['order_reviewed_time']);
            setPaymentOptionId(orders_by_pk['country_payment_option']['payment_option_id']);
            setDeliveryOptionId(orders_by_pk['country_delivery_option']['delivery_option_id']);
            setCustomerExpoToken(orders_by_pk['user']['user_expo_token']);
            setDelivered(orders_by_pk['order_delivered']);
            setOrderRealtime(orders_by_pk['order_realtime']);
            setLatitude(orders_by_pk['user']['user_location']['coordinates'][1]);
            setLongitude(orders_by_pk['user']['user_location']['coordinates'][0]);
            console.log('dick:', latitude, longitude);

            if(orderReviewed === false){
                setOrderViewed().then(() => {
                    const sendExpoPushNotification = httpsCallable(functions, 'sendExpoPushNotification');
                    sendExpoPushNotification({
                        token: customerExpoToken,
                        title: i18n.t('orders.order_detailed_seller.expo_notification_status_order_reviewed_title'),
                        body: i18n.t('orders.order_detailed_seller.expo_notification_status_order_reviewed_body'),
                    });
                }).finally(() => {
                    console.log('Notification sent');
                })
            }else{
                console.log('Already reviewed');
            }
        }
    }, [orderReviewed, dataProductList, dataOrder]);

    const ProductItem = ({item, product_title, product_image, product_currency, product_quantity, created_at, date, dayName}) => (
        <View>
            <Text style={{fontSize:16, fontWeight:'bold'}}>{product_title}</Text>
            <View style={{flexDirection:'row'}}>
                <ImageBackground source={{uri: product_image}} resizeMode='contain' style={{paddingVertical:5, width:125, height:125, overflow:'hidden', borderRadius:6}}></ImageBackground>
                {/* <FastImage source={{uri: product_image}} resizeMode='contain' style={{paddingVertical:5, width:190, height:190, overflow:'hidden', borderRadius:6}}></FastImage> */}
                <View style={{flexDirection:'column', paddingLeft:5, flexShrink:1}}>
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.unit_price')}</Text>
                    </View>
                    <Text style={{fontSize:16}}> {item.product_price} {product_currency}</Text>
                    <View style={{flexDirection:'row', alignItems:'center'}}>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.quantity')}</Text>
                        <Text style={{fontSize:16}}> {product_quantity}</Text>
                    </View>
                    <View style={{flexDirection:'row', alignItems:'center', flexShrink:1}}>
                        <Text style={{fontSize:16, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.ordered_at')}</Text>
                    </View>
                    <Text style={{fontSize:16, flexShrink:1}}>{dayName} {date} {created_at}</Text>
                </View>
            </View>
        </View>
    )

    const renderProductList = ({item}) => {
        const timeOrder = new Date(item.created_at);
        const timeOrdered = timeOrder.toLocaleTimeString();
        const dateOrdered = timeOrder.toLocaleDateString();
        
        /**get Day name */
        const day = timeOrder.getDay();

        // const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const dayNames = [i18n.t('orders.order_detailed.sunday'), 
        i18n.t('orders.order_detailed.monday'), 
        i18n.t('orders.order_detailed.tuesday'),
        i18n.t('orders.order_detailed.wednesday'), 
        i18n.t('orders.order_detailed.thursday'), 
        i18n.t('orders.order_detailed.friday'), 
        i18n.t('orders.order_detailed.saturday')];
        return(
            <ProductItem item={item}
            product_title={item['product']['product_translations'][0].product_title}
            product_image={item['product'].product_photo_portrait}
            product_currency={item['product_currency'].currency_code}
            product_quantity={item.product_quantity}
            created_at={timeOrdered}
            date={dateOrdered}
            dayName={dayNames[day]}
            />
        )
    }

    function header(){
        return(
            <View>
                <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('orders.order_detailed_seller.title')}
            </Text>  
            <Text style={{fontSize:16, paddingBottom:9}}>{i18n.t('orders.order_detailed_seller.body')}</Text>
            <ActivityIndicator size='small' color={'#000000'}></ActivityIndicator> 
            <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.products_ordered')}</Text>
            <FlatList data={productList} renderItem={renderProductList} keyExtractor={item => item.product_id.toString()}></FlatList> 
            </View>
        )
    }

    function footer(){
        const timeOrder = new Date(reviewedTimeStamp);
        
        const timeOrdered = timeOrder.toLocaleTimeString();
        const dateOrdered = timeOrder.toLocaleDateString();
        
        /**get Day name */
        const day = timeOrder.getDay();

        
        const dayNames = [i18n.t('orders.order_detailed.sunday'), 
        i18n.t('orders.order_detailed.monday'), 
        i18n.t('orders.order_detailed.tuesday'),
        i18n.t('orders.order_detailed.wednesday'), 
        i18n.t('orders.order_detailed.thursday'), 
        i18n.t('orders.order_detailed.friday'), 
        i18n.t('orders.order_detailed.saturday')];

        const dayName = dayNames[day];

        return(
            <View style={{flex:1}}>
                <View style={{flex:1}}>
                <View style={{flexDirection:'row', alignContent:'center',alignItems:'center'}}>
                    <Feather name='map-pin' size={18} color='#000000' style={{paddingRight:5}}></Feather>
                    <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.delivery_address')}</Text>
                </View>
                <Text style={{fontSize:16}}>{address1} {address2} {address3} {zip_code} {city} {province}</Text>
                <View style={{flexDirection:'row', alignContent:'center',alignItems:'center'}}>
                <Feather name='clipboard' size={18} color='#000000' style={{paddingRight:5}}></Feather>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.notes')}</Text>
                    
                </View>
                {(notes) ? <Text style={{fontSize:16}}>{notes}</Text> : <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed_seller.no_notes')}</Text>}
                <View style={{flexDirection:'row', alignContent:'center',alignItems:'center'}}>
                    <Feather name='credit-card' size={18} color='#000000' style={{paddingRight:5}}></Feather>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('orders.order_detailed_seller.transactions_status')} {transactionStatusName}</Text>
                </View>
                <View style={{flexDirection:'row'}}>
                {/* <Text style={{fontSize:22}}>{orderInfo['transactions'][0]['transaction_status']['transaction_status_translations'][0].transaction_status_name}</Text> */}
                {(transactionStatusId === 3)? <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed_seller.transaction_status_3')}</Text>: null}
                {(transactionStatusId === 2)? <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed_seller.transaction_status_2')}</Text>: null}
                {(transactionStatusId === 1)? <Text style={{fontSize:16}}>{i18n.t('orders.order_detailed_seller.transaction_status_1')}</Text>: null}

                </View>
                {(orderReviewed)?(
                    <Text style={{color:'green', fontSize:16}}>{i18n.t('orders.order_detailed_seller.order_reviewed')} {(reviewedTimeStamp)?(
                        dayName + ', ' + dateOrdered + ' ' + timeOrdered
                    ):null}</Text>
                ):(
                    <Text style={{color:'red', fontSize:16}}>{i18n.t('orders.order_detailed_seller.order_not_reviewed')}</Text>
                )}
                </View>
                <View style={{}}>
                    {/* {()} */}
                    {(!delivered &&  deliveryOptionId ===1)?(
                        <TouchableOpacity style={{backgroundColor:'#000000', marginVertical:5, paddingVertical:12, marginHorizontal:1, borderRadius:6, flexDirection:'row',
                        alignItems:'center', alignContent:'center', justifyContent:'center'}}
                        onPress={()=>{
                            console.log('deliverd');
                            const locationString = `${latitude},${longitude}`;
                            const mapLink = createMapLink({query:locationString, provider: Platform.OS === 'ios' ? 'apple' : 'google'});
                            Linking.openURL(mapLink);
                        }}>
                                <Text style={{textAlign:'center', color:'white', fontWeight:'bold', fontSize:16}}>{i18n.t('orders.order_detailed_seller.see_delivery_location')}</Text>
                                <Feather name='map' size={28} style={{paddingLeft:10}} color={'white'}></Feather>
                            </TouchableOpacity>
                    ):null}
                </View>
                <View style={{}}>
                    {(transactionStatusId === 3)?(
                        <TouchableOpacity style={{backgroundColor:'#304FFE', marginVertical:5, paddingVertical:12, marginHorizontal:1, borderRadius:6, flexDirection:'row',
                        alignItems:'center', alignContent:'center', justifyContent:'center'}}
                        onPress={()=>{
                            setOrderFilling({variables:{
                                orderId:order_id, 
                            }}).then(()=>{
                                const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');

                                sendExpoPushNotification({
                                    token: customerExpoToken,
                                    title: i18n.t('orders.order_detailed_seller.expo_notification_status_start_filling'),
                                    body: i18n.t('orders.order_detailed_seller.expo_notification_status_start_filling_body'),
                                }).catch(err => {
                                    console.log("ERROR", err)
                                  })
                            }).catch(err => {
                                console.log("ERROR", err)
                            });
                        }}>
                                <Text style={{textAlign:'center', color:'white', fontWeight:'bold', fontSize:16}}>{i18n.t('orders.order_detailed_seller.start_filling_btn')}</Text>
                                <Feather name='arrow-right' size={28} style={{paddingLeft:10}} color={'white'}></Feather>
                            </TouchableOpacity>  
                    ):null}
                    {(transactionStatusId === 2 )?(
                        <TouchableOpacity style={{backgroundColor:'#304FFE', marginVertical:5, paddingVertical:12, marginHorizontal:1, borderRadius:6, flexDirection:'row',
                        alignItems:'center', alignContent:'center', justifyContent:'center'}}
                        onPress={()=>{
                            setOrderCompleted({
                                variables:{
                                    orderId:order_id,
                                }
                            }).then(()=>{
                                const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');

                                sendExpoPushNotification({
                                    token: customerExpoToken,
                                    title: i18n.t('orders.order_detailed_seller.expo_notification_status_complete_your_order'),
                                    body: i18n.t('orders.order_detailed_seller.expo_notification_status_complete_your_order_body'),
                                }).catch(err => {
                                    console.log("ERROR", err)
                                  })
                            })
                        }}>
                                <Text style={{textAlign:'center', color:'white', fontWeight:'bold', fontSize:16}}>{i18n.t('orders.order_detailed_seller.complete_your_order_btn')}</Text>
                                <Feather name='arrow-right' size={28} style={{paddingLeft:10}} color={'white'}></Feather>
                            </TouchableOpacity>
                    ):null}
                    {(transactionStatusId === 1 )?(
                        <TouchableOpacity style={{backgroundColor:'#304FFE', marginVertical:5, paddingVertical:12, marginHorizontal:1, borderRadius:6, flexDirection:'row',
                        alignItems:'center', alignContent:'center', justifyContent:'center'}}
                        onPress={()=>{


                            Alert.alert(i18n.t('orders.order_detailed_seller.mark_as_completed'), "", [
                                {text: 'Cancel', onPress: () => console.log('Cancel Pressed'), style: 'cancel'},
                                {text: 'OK', onPress: () => {
                                    if(paymentOptionId === 2){
                                        setOrderDelivered({
                                            variables:{
                                                orderId:order_id,
                                            }
                                        }).then(()=>{
                                            const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');
                                            sendExpoPushNotification({
                                                token: customerExpoToken,
                                                title: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered'),
                                            }).catch(err => {
                                                console.log("ERROR", err)
                                              })
                                        }).catch(err => {
                                            console.log("ERROR", err)
                                        });
                                        
                                    }else if(paymentOptionId ===1){
                                        setOrderDeliveredCash({
                                            variables:{
                                                orderId:order_id,
                                            }
                                        }).then(()=>{
                                            const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');

                                            sendExpoPushNotification({
                                                token: customerExpoToken,
                                                title: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered'),
                                                body: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered_body'),
                                            }).catch(err => {
                                                console.log("ERROR", err)
                                              })
                                        })
                                    } else if(paymentOptionId === 3){
                                        setOrderDelivered({
                                            variables:{
                                                orderId:order_id,
                                            }
                                        }).then(()=>{
                                            const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');
                                            sendExpoPushNotification({
                                                token: customerExpoToken,
                                                title: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered'),
                                            }).catch(err => {
                                                console.log("ERROR", err)
                                              })
                                        }).catch(err => {
                                            console.log("ERROR", err)
                                        });
                                        
                                    }
                                }},
                            ]);

                            if(Platform.OS === 'web'){
                                setModalVisible(true);
                                setMessageTitle(i18n.t('orders.order_detailed_seller.mark_as_completed'));

                            }
                        }}>
                                <Text style={{textAlign:'center', color:'white', fontWeight:'bold', fontSize:16}}>{i18n.t('orders.order_detailed_seller.mark_as_delivered_btn')}</Text>
                                {/* <Feather name='arrow-right' size={28} style={{paddingLeft:10}} color={'white'}></Feather> */}
                            </TouchableOpacity>
                    ):null}
                    {(delivered)?(
                    <TouchableWithoutFeedback style={{backgroundColor:'green'}}
                    onPress={()=>{

                    }}>
                    <Text style={{fontSize:18, textAlign:'center', backgroundColor:'#81C784', paddingVertical:12, fontWeight:'bold', borderRadius:6, marginTop:12}}>{i18n.t('orders.order_detailed.btn_order_delivered')}</Text>
                </TouchableWithoutFeedback>
                ):null}
                </View>
            </View>
        )}

    return(
        <SafeAreaView style={{flex:1, paddingTop:-45}}>
        <Modal visible={modalVisible}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisible(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                <Text style={{fontSize:20}}>{messageBody}</Text>
                <TouchableOpacity style={{marginTop:20}} onPress={() => {
                    if(paymentOptionId === 2){
                        setOrderDelivered({
                            variables:{
                                orderId:order_id,
                            }
                        }).then(()=>{
                            const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');
                            sendExpoPushNotification({
                                token: customerExpoToken,
                                title: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered'),
                            }).catch(err => {
                                console.log("ERROR", err)
                              })

                            console.log("DELIVERED")
                        }).catch(err => {
                            console.log("ERROR", err)
                            })

                    }else if(paymentOptionId ===1){
                        setOrderDeliveredCash({
                            variables:{
                                orderId:order_id,
                            }
                        }).then(()=>{
                            const sendExpoPushNotification = httpsCallable(functions,'sendExpoPushNotification');

                            sendExpoPushNotification({
                                token: customerExpoToken,
                                title: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered'),
                                body: i18n.t('orders.order_detailed_seller.expo_notification_status_mark_as_delivered_body'),
                            }).catch(err => {
                                console.log("ERROR", err)
                              })
                        }).catch(err => {
                            console.log("ERROR", err)
                            }
                        )

                    }
                }}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                </TouchableOpacity>
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisible(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>
        <View style={{flex:1, paddingHorizontal:9, paddingTop:5}}>
        <FlatList ListHeaderComponent={header} ListFooterComponent={footer}>
            
        </FlatList>
        </View>
    </SafeAreaView>
    )
    
}

export default ReviewOrder;