import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, ImageBackground, Linking,TouchableWithoutFeedback, FlatList, Platform, Image, Dimensions, useWindowDimensions, Share, ScrollView, Alert } from 'react-native';
import Firebase from '../../lib/firebase';
import { useQuery, useLazyQuery, useMutation} from '@apollo/client';
import { DELETE_FROM_CART_BY_ID, DELETE_USER_ORGANIZATION_BY_ID, GET_ORGANIZATION_USERS_BY_ORGANIZATION_ID, GET_USER_BY_EMAIL, INSERT_ORGANIZATION_USER_ONE, UPDATE_ORGANIZATION_OWNER, UPDATE_ORGANIZATION_USER_LEVEL_BY_ID
} from '../../lib/queries';
import * as TaskManager from 'expo-task-manager';
import * as Location from 'expo-location';
import i18n from '../../i18n/i18n';

import { useFonts } from 'expo-font';
// import * as Linking from 'expo-linking'
// import FastImage from 'react-native-fast-image';
import { useIsFocused } from '@react-navigation/native';
import { getAuth } from 'firebase/auth';
import {Feather} from '@expo/vector-icons';
import { async } from '@firebase/util';
import Modal from 'react-native-modal';
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import {Picker} from '@react-native-picker/picker';


const storage = getStorage(Firebase);

const ModalDropdown = Platform.select({
    native: () => require('react-native-modal-dropdown'),
    web: () => null 
})();

const auth = getAuth(Firebase);




const EditOrganizationUsers = ({navigation, route}) => {

    const {organizationId} = route.params;

  const [fontsLoaded] = useFonts({
    'Inter-Black': require('../../assets/fonts/Roboto-Black.ttf'),
    'Inter-BlackItalic': require('../../assets/fonts/Roboto-BlackItalic.ttf'),
    'Inter-Bold': require('../../assets/fonts/Roboto-Bold.ttf'),
    'Inter-BoldItalic': require('../../assets/fonts/Roboto-BoldItalic.ttf'),
    'Inter-Italic': require('../../assets/fonts/Roboto-Italic.ttf'),
    'Inter-Light': require('../../assets/fonts/Roboto-Light.ttf'),
    'Inter-LightItalic': require('../../assets/fonts/Roboto-LightItalic.ttf'),
    'Inter-Medium': require('../../assets/fonts/Roboto-Medium.ttf'),
    'Inter-MediumItalic': require('../../assets/fonts/Roboto-MediumItalic.ttf'),
    'Inter-Regular': require('../../assets/fonts/Roboto-Regular.ttf'),
    'Inter-Thin': require('../../assets/fonts/Roboto-Thin.ttf'),
    'Inter-ThinItalic': require('../../assets/fonts/Roboto-ThinItalic.ttf'),
  });
  // console.log(getLocation());

  /**Near products */
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();



  const isFocused = useIsFocused();

  /**Firebase */
  const uid= getAuth().currentUser.uid;

  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [modalAddUserVisible, setModalAddUserVisible] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [selectedOrganizationUserId, setSelectedOrganizationUserId] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [organizationOwner, setOrganizationOwner] = useState(false);
  const [email, setEmail] = useState('');

  const { loading:organizationUsersLoading, error:organizationUsersError, data:organizationUsersData, refetch:refetchUsers } = useQuery(GET_ORGANIZATION_USERS_BY_ORGANIZATION_ID, {variables: {organizationId: route.params.organizationId}});
//   const { loading:getUserByEmail, error:getUserByEmailError, data:getUserByEmailData, refetch:refetchGetUserByEmail } = useQuery(GET_USER_BY_EMAIL, {variables: {email: email}});
  const [getUserByEmail, { loading:getUserByEmailLoading, error:getUserByEmailError, data:getUserByEmailData, refetch:refetchGetUserByEmail }] = useLazyQuery(GET_USER_BY_EMAIL);
  /** Mutation deleteuser */

  const [deleteUser, { loading:deleteUserLoading, error:deleteUserError, data:deleteUserData }] = useMutation(DELETE_USER_ORGANIZATION_BY_ID);

  /**Insert Organization User */
  const [insertOrganizationUser, { loading:insertOrganizationUserLoading, error:insertOrganizationUserError, data:insertOrganizationUserData }] = useMutation(INSERT_ORGANIZATION_USER_ONE);
  const [transferOwnership, { loading:transferOwnershipLoading, error:transferOwnershipError, data:transferOwnershipData }] = useMutation(UPDATE_ORGANIZATION_OWNER);
  /**Update Organization User Level*/

    const [updateOrganizationUserLevel, { loading:updateOrganizationUserLevelLoading, error:updateOrganizationUserLevelError, data:updateOrganizationUserLevelData }] = useMutation(UPDATE_ORGANIZATION_USER_LEVEL_BY_ID);


  async function getOrganizationUsers(){
    if(organizationUsersError){
        // console.log(organizationUsersError);
    }
    if(organizationUsersData){
        const {organization_users, organization_by_pk} = organizationUsersData;
        setOrganizationUsers(organization_users);
        if(organization_by_pk){
            if(organization_by_pk.organization_owner === uid){
                setOrganizationOwner(true);
                console.log('owner');
            }
        }
    }
  }



  useEffect(() => {

    if(isFocused){
        getOrganizationUsers();
        refetchUsers();
    }
  }, [organizationUsersData, selectedLevel, getUserByEmailData]);

  let screenWidth
  let numColumns 
  let itemWidth 

  const itemHeight = Dimensions.get('window').width*0.50;

  if(Platform.OS === 'android' || Platform.OS === 'ios'){
    screenWidth = Dimensions.get('window').width;
    numColumns = 3;
    itemWidth = screenWidth/numColumns;
  }else if(Platform.OS === 'web'){
    screenWidth = useWindowDimensions().width;
    if(screenWidth > 1200){
      numColumns = 8;
      itemWidth = screenWidth / numColumns;
    }else if(screenWidth > 768){
      numColumns = 5;
      itemWidth = screenWidth / numColumns;
    }
    else{
      numColumns = 2;
      itemWidth = screenWidth/numColumns;
    }
  }

  function header(){
    return(
        <View>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <Text style={{fontSize:28, fontWeight:'bold', paddingHorizontal:5}}>
            {i18n.t('home.editOrganizationUsers.title')}
        </Text>

        </View>

        <View style={{padding:5}}>
        <Text style={{fontSize:16}}>
            {i18n.t('home.editOrganizationUsers.body')}
        </Text>
        <TouchableOpacity
        style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:15,marginTop:10}}
        onPress={() => setModalAddUserVisible(true)}
        >
            <Text
             style={{color:'#000000', textAlign:'center', fontWeight:'bold'}}
            >
                {i18n.t('home.editOrganizationUsers.addUsers')}
            </Text>
        </TouchableOpacity>
        </View>
        </View>
    )
  }

  const ItemUsers = ({item, user_name, user_lastname, user_email, user_role, uid, organization_owner}) => {
    return(
        <View style={{flexDirection:'row'}}>
            <View style={{flex:1, padding:5}}>
        <Text style={{fontSize:18, fontWeight:'bold'}}>
            {user_email}
        </Text>
        <View style={{flexDirection:'row', alignItems:'center'}}>
        <Text></Text>

        <Text style={{fontSize:16, paddingHorizontal:5}}>
            {user_name} {user_lastname}
        </Text>
        </View>
            <Text>
                {(user_role === 1)? i18n.t('home.editOrganizationUsers.admin') : i18n.t('home.editOrganizationUsers.user')}
            </Text>
            <Text>
                {organization_owner ? 'Owner' : ''}
            </Text>
        </View>
        <View style={{flexDirection:'column', alignContent:'center', alignItems:'center', justifyContent:'center'}}>
        <TouchableOpacity onPress={()=>{
            if(uid !== getAuth().currentUser.uid){
                setModalEditVisible(true);
                setSelectedLevel(user_role);
                setSelectedOrganizationUserId(item.id);
                setSelectedUserId(uid);
                console.log("item.id", item.id);
            }else {
                Alert.alert(
                    i18n.t('home.editOrganizationUsers.modifyUserError'),
                    i18n.t('home.editOrganizationUsers.modifyUserErrorBody'),
                );
            }
            
        }}>
            <Feather name='edit' size={24} color='black'></Feather>
        </TouchableOpacity>
        <TouchableOpacity onPress={()=>{
            if(uid !== getAuth().currentUser.uid){
                Alert.alert(
                i18n.t('home.editOrganizationUsers.deleteUser'),
                i18n.t('home.editOrganizationUsers.deleteUserBody'),
                [
                    {
                        text: i18n.t('home.editOrganizationUsers.cancel'),
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                    },
                    { text: i18n.t('home.editOrganizationUsers.delete'), onPress: () => {
                        deleteUser({variables: {organizationUserId: item.id}}).then(()=>{
                            refetchUsers();
                        });
                       
                    } }
                    ]
            );
            }else{
                Alert.alert(
                    i18n.t('home.editOrganizationUsers.deleteUserError'),
                    i18n.t('home.editOrganizationUsers.deleteUserErrorBody'),
                );

            }
        }}>
        <Feather name='trash' size={24} color='red'></Feather>
        </TouchableOpacity>
        </View>
        </View>
    )
  } 

  const renderItemUsers = ({item}) => {
        return(
            <ItemUsers item={item} 
            user_name={item.user.user_name}
            user_lastname={item.user.user_lastname}
            user_email={item.user.user_email}
            user_role={item.role.role_id}
            uid={item.user.uid}
            organization_owner={item.organization_owner}
            />
        )
  };    

  return (
    <SafeAreaView style={{flex:1, paddingTop:-45}}>
    <Modal isVisible={modalEditVisible}
    backdropColor={'rgba(0,0,0,0.5)'}
    backdropOpacity={75}
    animationIn={'slideInUp'}
    animationOut={'slideOutDown'}
    animationInTiming={500}
    animationOutTiming={500}
    backdropTransitionInTiming={500}
    backdropTransitionOutTiming={500}
    onBackButtonPress={()=>{
        setModalEditVisible(false);
    }}
    onBackdropPress={()=>{
        setModalEditVisible(false);
    }}
    style={{elevation:5,justifyContent:'flex-end', margin:0, height:450, flex:0, bottom:0, position:'absolute', width:'100%', borderRadius:6}}
    >
    <View style={{flex:1, backgroundColor:'white', borderRadius:6}}>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:5}}>
        <Text style={{fontSize:18, fontWeight:'bold'}}>
            {i18n.t('home.editOrganizationUsers.editUser')}
        </Text>

    </View>
        <Text style={{fontSize:16, padding:5}}>
            {i18n.t('home.editOrganizationUsers.editLevel')}
        </Text>
        <Picker selectedValue={selectedLevel}
        onValueChange={(itemValue, itemIndex) => {
            setSelectedLevel(itemValue);
            updateOrganizationUserLevel({
                variables: {
                    id: selectedOrganizationUserId,
                    roleId: itemValue
                }
            }).then(()=>{
                setModalEditVisible(false);
                refetchUsers();
            })
        }}

        >   
            <Picker.Item label={i18n.t('home.editOrganizationUsers.selectLevel')} 
            value={0} key="0"/>
            <Picker.Item label={i18n.t('home.editOrganizationUsers.admin')} 
            value={1} key="1"/>
            <Picker.Item label={i18n.t('home.editOrganizationUsers.user')}
            value={2} key="2"/>
        </Picker>

        {(organizationOwner) ? (
            <>
            <TouchableOpacity style={{
            backgroundColor:'#FF6D00',
            padding:10,
            borderRadius:5,
            margin:10
        }} onPress={()=>{
            Alert.alert(
                i18n.t('home.editOrganizationUsers.transferOwnership'),
                i18n.t('home.editOrganizationUsers.transferOwnershipBody'),
                [
                    {
                        text: i18n.t('home.editOrganizationUsers.cancel'),
                        onPress: () => console.log("Cancel Pressed"),
                        style: "cancel"
                    },

                    { text: i18n.t('home.editOrganizationUsers.transfer'), onPress: () => {
                        console.log("selectedOrganizationUserId", selectedOrganizationUserId);
                        transferOwnership({
                            variables: {
                                uid: uid,
                                organizationId: organizationId,
                                itemUserId: selectedUserId
                            }
                        }).then(()=>{
                            refetchUsers();
                            setModalEditVisible(false);
                        }).catch((err)=>{
                            console.log(err);
                        }
                        );
                    }
                    }
                ]
            );
        }}>
            <Text style={{textAlign:'center'}}>
                {i18n.t('home.editOrganizationUsers.transferOwnership')}
            </Text>
        </TouchableOpacity>
            </>
        ) : null}
    </View>
    </Modal>


    <Modal isVisible={modalAddUserVisible}
       backdropColor={'rgba(0,0,0,0.5)'}
    backdropOpacity={75}
    animationIn={'slideInUp'}
    animationOut={'slideOutDown'}
    animationInTiming={500}
    animationOutTiming={500}
    backdropTransitionInTiming={500}
    backdropTransitionOutTiming={500}
    onBackButtonPress={()=>{
        setModalAddUserVisible(false);
    }}
    onBackdropPress={()=>{
        setModalAddUserVisible(false);
    }}
    style={{elevation:5,justifyContent:'flex-end', margin:0, height:450, flex:0, bottom:0, position:'absolute', width:'100%', borderRadius:6}}
    >
        <View style={{flex:1, backgroundColor:'white', borderRadius:6}}>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:5}}>
        <Text style={{fontSize:18, fontWeight:'bold'}}>
            {i18n.t('home.editOrganizationUsers.addUser')}
        </Text>
        
    </View>
        <Text style={{fontSize:16, padding:5}}>
            {i18n.t('home.editOrganizationUsers.addUserBody')}
        </Text>
        <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:2, fontSize:18, borderRadius:2, marginHorizontal:5}}
        placeholder={i18n.t('home.editOrganizationUsers.email')}
        onChangeText={(text)=>{
            setEmail(text);
        }}
        autoCapitalize='none'
        textContentType='emailAddress'
        keyboardType='email-address'
        autoCorrect={false}
        autoComplete='email'
        ></TextInput>

        {/* add user button */}

        <TouchableOpacity style={{backgroundColor:'#3f51b5', padding:10, margin:10, borderRadius:2, alignItems:'center', justifyContent:'center'}}
        onPress={async ()=>{
                await getUserByEmail({variables:{
                    email: email,
                }}).then((data)=>{
                    refetchGetUserByEmail().then((data)=>{
                        console.log("user by email", data.data.users[0]);
                        if(data.data.users[0] !== undefined){
                            const {organization_users} = data.data.users[0];
                            console.log("organization users", organization_users);
                            // if(organization_users.length > 0){
                            //     Alert.alert(
                            //         i18n.t('home.editOrganizationUsers.userExists'),
                            //         i18n.t('home.editOrganizationUsers.userExistsBody'),
                            //     );
                            // }else{
                            //     insertOrganizationUser({variables:{
                            //     organizationId: organizationId,
                            //     userId: data.data.users[0].uid,
                            //     roleId: 2,
                            // }})
                            // }

                            insertOrganizationUser({variables:{
                                organizationId: organizationId,
                                userId: data.data.users[0].uid,
                                roleId: 2,
                            }}).then(()=>{
                                refetchUsers();
                                setModalAddUserVisible(false);
                            }).catch((err)=>{
                                console.log(err);
                            })
                            
                        }else{
                            Alert.alert(
                                i18n.t('home.editOrganizationUsers.userDoesNotExist'),
                                i18n.t('home.editOrganizationUsers.userDoesNotExistBody'),
                            );
                        }
                    })
                }).catch((error)=>{ console.log(error)});
        }}
        >
            <Text style={{color:'white', fontSize:18, fontWeight:'bold'}}>
                {i18n.t('home.editOrganizationUsers.addUser')}
            </Text>
        </TouchableOpacity>


    </View>
    </Modal>


        <FlatList
        ListHeaderComponent={header}
        data={organizationUsers}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItemUsers}
        >
        </FlatList>
    </SafeAreaView>
  );
}

export default EditOrganizationUsers;