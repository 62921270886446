import React, { useEffect, useState} from 'react';
import {
    SafeAreaView, Text, View, TextInput, TouchableOpacity, ImageBackground ,Alert,KeyboardAvoidingView, Platform, Pressable, Dimensions, Image, useWindowDimensions,
    Modal,
    Linking
  } from 'react-native';
import * as Localization from 'expo-localization';
import Firebase from '../lib/firebase';
import { getAuth, signInAnonymously, signInWithEmailAndPassword } from 'firebase/auth';
import WelcomeLogo from '../assets/WelcomeScreen.png';
import LogoBienvenida from '../assets/PantallaBievenida.png';
import { Picker } from '@react-native-picker/picker';
import { useFonts } from 'expo-font';
import { useTogglePasswordVisibility } from '../hooks/useTogglePasswordVisibility';
// import Pressable from 'react-native/Libraries/Components/Pressable/Pressable';
import { Feather } from '@expo/vector-icons';
import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from '../i18n/i18n';
import AppStoreBadge from '../assets/svgs/app-store-badge';

const auth = getAuth(Firebase);

  /**Function login */
  const Landing = ({ navigation }) => {
    const [fontsLoaded] = useFonts({
      'Inter-Black': require('../assets/fonts/Roboto-Black.ttf'),
      'Inter-BlackItalic': require('../assets/fonts/Roboto-BlackItalic.ttf'),
      'Inter-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
      'Inter-BoldItalic': require('../assets/fonts/Roboto-BoldItalic.ttf'),
      'Inter-Italic': require('../assets/fonts/Roboto-Italic.ttf'),
      'Inter-Light': require('../assets/fonts/Roboto-Light.ttf'),
      'Inter-LightItalic': require('../assets/fonts/Roboto-LightItalic.ttf'),
      'Inter-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
      'Inter-MediumItalic': require('../assets/fonts/Roboto-MediumItalic.ttf'),
      'Inter-Regular': require('../assets/fonts/Roboto-Regular.ttf'),
      'Inter-Thin': require('../assets/fonts/Roboto-Thin.ttf'),
      'Inter-ThinItalic': require('../assets/fonts/Roboto-ThinItalic.ttf'),
    });
    
      useEffect(() => {

      }, [])

      let screenWidth
      if(Platform.OS === 'ios' || Platform.OS === 'android'){
        
      screenWidth = Dimensions.get('window').width;
      }else if(Platform.OS === 'web'){
        if(screenWidth < 550){
          screenWidth = useWindowDimensions().width;
        }else {
          screenWidth = useWindowDimensions().width;
        }
      }
      

      return (
        <SafeAreaView style={{flex:1}}>
          <View style={{flexDirection:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 'column-reverse': 'row'}}>
            <Image source={require('../assets/landing.png')} style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2, height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2}} />
            <View style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2, height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2}}>
            <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 35 : 40, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 50 : 100, fontFamily:'Inter-Bold'}}>{i18n.t('landing.title')}</Text>
            <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 30, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 50, fontFamily:'Inter-Regular'}}>{i18n.t('landing.body')}</Text>
            {/* Descarga para android y Ios */}
            <View>
              {/* Discover Button */}
              <View style={{flexDirection:'row', justifyContent:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 50}}>
                <TouchableOpacity onPress={async () => {
                  navigation.navigate('Drawer')
                }}>
                  <Text>
                    {i18n.t('landing.discover')}
                  </Text>
                </TouchableOpacity>
                </View>
            </View>
            <View style={{flexDirection:'row', justifyContent:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 50}}>
              <TouchableOpacity onPress={() => {
                Linking.openURL('https://play.google.com/store/apps/details?id=com.nelson_arguera.menu')
              }}>
              <Image source={require('../assets/google-play-badge.png')} style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 200 : 200, height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 75 : 75}} />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => {
                Linking.openURL('https://apps.apple.com/us/app/menucitos/id1643431406')
              }}>
              <AppStoreBadge/>
              </TouchableOpacity>
            </View>
            {/* <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 30, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 50, fontFamily:'Inter-Regular', fontWeight:'normal', fontStyle:'normal'}}>Una experiencia digital.</Text> */}
            </View>
          </View>
          <View style={{flexDirection:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 'column-reverse': 'row-reverse'}}>
            <Image source={require('../assets/landing4.png')} style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2 , height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2}} />
            <View style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2, height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2}}>
            <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 35 : 40, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 50 : 100, fontFamily:'Inter-Bold', paddingHorizontal:15}}>{i18n.t('landing.title2')}</Text>
            <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 30, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 50, fontFamily:'Inter-Regular'}}>{i18n.t('landing.body2')}</Text>
            </View>
          </View>
          <View style={{flexDirection:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 'column-reverse': 'row'}}>
            <View>
              <Image source={require('../assets/Group 1.png')} style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2 , height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2}} />
            </View>
            <View style={{width:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2, height:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? screenWidth : screenWidth/2}}>
            <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 35 : 40, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 50 : 100, fontFamily:'Inter-Bold', paddingHorizontal:15}}>{i18n.t('landing.title3')}</Text>
            <Text style={{fontSize:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 30, color:'#000', textAlign:'center', marginTop:Platform.OS === 'ios' ||  Platform.OS === 'android' || screenWidth < 500 ? 25 : 50, fontFamily:'Inter-Regular' }}>
            {i18n.t('landing.body3')}
            </Text>
            </View>
          </View>
        </SafeAreaView>
      )
   }
  
export default Landing; 