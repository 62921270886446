import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, TouchableWithoutFeedback, Keyboard, KeyboardAvoidingView, Platform, Modal } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import {Picker} from '@react-native-picker/picker';
import { useQuery } from '@apollo/client';
import { GET_USER_COUNTRY_CURRENCY_OPTION } from '../../lib/queries';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import i18n from '../../i18n/i18n';
import { useIsFocused } from '@react-navigation/native';


const auth = getAuth(Firebase);

const AddProductPrice = ({navigation, route}) => {

  const {subCategoryId} = route.params


  /**productTitle */
  const {productTitleImage} = route.params
  const {productTitle} = productTitleImage

  /**productImage */
  const {productImageImage} = route.params
  const {image}= productImageImage

  /**productSKU */
  const {productSKUImage} = route.params
  const {productSKU} = productSKUImage

  /**productSlug */
  const {productSlugImage} = route.params
  const {productSlug} = productSlugImage

  /**productGallery */
  const {productGalleryGallery} = route.params
  const {productGallery} = productGalleryGallery
  
  /**Firebase uid */
  const uid = getAuth().currentUser.uid;
  /**States */
  const [productPrice, setProductPrice] = useState() 
  const [productCurrency, setProductCurrency] = useState(0)
  const [currencyList, setCurrencyList] = useState()
  const [messageTitle, setMessageTitle] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
  /**Queries */
  const {loading: currencyLoading, error: currencyError, data: currencyData, refetch} = useQuery(GET_USER_COUNTRY_CURRENCY_OPTION, {variables:{uid}})

  useEffect(()=>{
    console.log(productGallery)
    // console.log("SUBCATEGORY ID Price", subCategoryId)
    let newCurrencyList;
    if(currencyData){
      const {user_currencies} = currencyData
      // console.log(user_country_payment_option[0]['user_country_payment_option_config']['currencies']);
      newCurrencyList = user_currencies;
      console.log(newCurrencyList);
    }

    setCurrencyList(newCurrencyList);

    const unsubscribe = navigation.addListener('focus', () => {
      refetch();
    });
  }, [currencyData, productCurrency])

    return(
      <SafeAreaView style={{flex:1 , paddingTop:-45}}>
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
        <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={{paddingTop:5, paddingHorizontal:5, flex:1}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('products.addProductPrice.title')}
            </Text>
            </View>
            <View style={{paddingTop:25}}>
                {/* <Text style={{fontSize:22}}> Select a good and attractive price for your product</Text> */}
            </View>
        </View>
        </TouchableWithoutFeedback>
        <View style={{flex:2.5}}>
        <Text style={{fontSize:16, paddingHorizontal:5}}>{i18n.t('products.addProductPrice.body')}</Text>
            <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:15,
             marginHorizontal:15, fontSize:18}} 
              keyboardType={'numeric'}
             contextMenuHidden={true}
             onChangeText={(v)=>{setProductPrice(v)}}
             placeholder="4.99"/>
              <Text style={{fontSize:16, paddingHorizontal:15}}>{i18n.t('products.addProductPrice.label_currency')}</Text>
              <Picker
              style={{paddingHorizontal:15, marginTop:15, marginHorizontal:5}}
              selectedValue={productCurrency} onValueChange={(itemValue)=>{
               setProductCurrency(itemValue);
              //  console.log(itemValue)
             }}
            
             >
                <Picker.Item label={i18n.t('products.addProductPrice.picker_currency_label')} value="0" key="0" />
                {(currencyList)?currencyList.map((item, index)=>{
                  return <Picker.Item label={item.user_currency_code} value={item.user_currency_code} key={index} />
                }):null}
             </Picker>

        </View> 
        <View style={{flex:0.6, paddingHorizontal:5}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
            onPress={()=> {
              if(productPrice != null && productPrice !='' && productPrice !=0){
                if(productCurrency != 0){
                          navigation.navigate('AddProductQuantity',{
                          productSKUPrice:{productSKU},
                          productTitlePrice:{productTitle},
                          productSlugPrice:{productSlug},
                          productImagePrice:{image},
                          productPricePrice:{productPrice},
                          productCurrencyPrice:{productCurrency},
                          subCategoryId,
                          productGalleryPrice:{productGallery}
                        })
                }else{
                  Alert.alert(i18n.t('products.addProductPrice.alert_title'), i18n.t('products.addProductPrice.alert_body'))
                  if(Platform.OS === 'web'){
                    setMessageTitle(i18n.t('products.addProductPrice.alert_title'))
                    setMessageBody(i18n.t('products.addProductPrice.alert_body'))
                    setModalVisibilityAlert(true)
                  }
                }
              }else{
                Alert.alert(i18n.t('products.addProductPrice.alert_title'), i18n.t('products.addProductPrice.alert_body'))
                if(Platform.OS === 'web'){
                  setMessageTitle(i18n.t('products.addProductPrice.alert_title'))
                  setMessageBody(i18n.t('products.addProductPrice.alert_body'))
                  setModalVisibilityAlert(true)
                }


              }
              // if(productPrice && productCurrency != '0'){
              //           navigation.navigate('AddProductQuantity',{
              //             productSKUPrice:{productSKU},
              //             productTitlePrice:{productTitle},
              //             productSlugPrice:{productSlug},
              //             productImagePrice:{image},
              //             productPricePrice:{productPrice},
              //             productCurrencyPrice:{productCurrency},
              //             subCategoryId,
              //           })
              //         }else if(productCurrency == 0){
              //           Alert.alert(i18n.t('products.addProductPrice.alert_title'), i18n.t('products.addProductPrice.alert_body'))
              //           if(Platform.OS === 'web'){
              //             setMessageTitle(i18n.t('products.addProductPrice.alert_title'))
              //             setMessageBody(i18n.t('products.addProductPrice.alert_body'))
              //             setModalVisibilityAlert(true)
              //           }
              //         }
                    }
                    }
            >
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('products.addProductPrice.btn_next')}</Text>
            </TouchableOpacity>
            </View>
        </KeyboardAvoidingView>
        {/* </TouchableWithoutFeedback> */}
              
    </SafeAreaView>
    )
    
}

export default AddProductPrice;