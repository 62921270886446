import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, FlatList, ImageBackground, TouchableWithoutFeedback, Alert, Dimensions } from 'react-native';
import { BlurView } from 'expo-blur';
import Firebase from '../../../lib/firebase';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER_PRODUCT_IMAGE_BY_ID, INSERT_PRODUCTS_TO_CUSTOM_CATEGORY, GET_USER_PRODUCT_IMAGE_BY_ID_NOT_IN_CATEGORY } from '../../../lib/queries';
import { getAuth } from 'firebase/auth';
import i18n from '../../../i18n/i18n';

const auth = getAuth(Firebase);

const lang = i18n.locale;
const language = lang.substring(0, 2);
const languageCode = language.toUpperCase();

const screenWidth = Dimensions.get('window').width;
const numColumns = 2;
const itemWidth = screenWidth / numColumns;

const Item = ({ item, backgroundColor, onPress }) => (
  <View style={{ width: itemWidth, height: itemWidth }}>
    <TouchableOpacity style={{ flex: 1 }} onPress={onPress}>

      <ImageBackground source={{ uri: item['product'].product_thumbnail }} resizeMode="contain" imageStyle={{ borderRadius: 3, padding:10, margin:10 }} style={{ flex: 1 }}>

      </ImageBackground>
    </TouchableOpacity>
    <View style={{ borderRadius: 6, alignItems: 'stretch' }}>
      <Text numberOfLines={1} ellipsizeMode='clip' style={backgroundColor}>
      {/* {console.log('item', item['product']['product_translations'] ? item['product']['product_translations'][0] ? item['product']['product_translations'][0].product_title : '' : '')} */}

        {item['product']['product_translations'] ? item['product']['product_translations'][0] ? item['product']['product_translations'][0].product_title.length < 16 ? item['product']['product_translations'][0].product_title : item['product']['product_translations'][0].product_title.substring(0, 15) + '...' : '' : ''}
      </Text>
    </View>


  </View>


);

const AddProductsByCategory = ({ navigation, route }) => {

  // /**Route.params */
  const { custom_name } = route.params;
  const { categoryName } = custom_name;

  const { custom_id } = route.params;
  const { customCategoryId } = custom_id;

  const { usercategory_id } = route.params;
  const { user_category_id } = usercategory_id;

  const { userProductsId } = route.params;
  const { newUserProductsIdByCategory } = userProductsId;

  console.log('newUserProductsIdByCategory', newUserProductsIdByCategory);

  const uid = getAuth().currentUser.uid;
  /**Queries */
  const { loading: loadingProducts, error: errorProducts, data: dataProducts, refetch: refetchProducts } = useQuery(GET_USER_PRODUCT_IMAGE_BY_ID_NOT_IN_CATEGORY, { variables: { uid, userProductId: newUserProductsIdByCategory, languageCode } });
  /**Mutations */
  const [insertProductsToCustomCategory, { loading: loadingInsertProductsToCustomCategory, error: errorInsertProductsToCustomCategory, data: dataInsertProductsToCustomCategory }] = useMutation(INSERT_PRODUCTS_TO_CUSTOM_CATEGORY);

  const [myProducts, setMyProducts] = useState([]);
  const [arrayProductId, setArrayProductId] = useState([]);
  const [productArray, setProductArray] = useState([]);

  useEffect(() => {
    console.log('errorProducts', errorProducts);
    console.log("user_category_id:", user_category_id)
    let newMyProducts;
    if (dataProducts) {
      const { user_products } = dataProducts;
      newMyProducts = user_products;
    }

    setMyProducts(newMyProducts);
    // console.log('myProducts', myProducts);

    const unsubscribe = navigation.addListener('focus', () => {
      refetchProducts();
    })
  });

  /**renderItem */


  const renderItem = ({ item, index }) => {

    const backgroundColor = productArray[index] ? { fontSize: 22, color: "#FFFFFF", textAlign: 'center', backgroundColor: '#1e76e8', marginHorizontal: 7 } : { fontSize: 22, color: "#FFFFFF", textAlign: 'center', backgroundColor: '#000000', marginHorizontal: 7 }
    return (
      <Item item={item}
        product_id={item.product_id}
        index={index}
        backgroundColor={backgroundColor}
        onPress={() => {
          let newProductArray = [...productArray];
          newProductArray[index] = !newProductArray[index];
          setProductArray(newProductArray);

          let newArrayProductId = [...arrayProductId];
          if (newProductArray[index]) {
            newArrayProductId.push({ user_id: uid, user_product_id: item.id, user_category_id: user_category_id });
            setArrayProductId(newArrayProductId);
            console.log('arrayProductId', newArrayProductId);
          } else {
            newArrayProductId.splice(index, 1);
            setArrayProductId(newArrayProductId);
            console.log('arrayProductId', newArrayProductId);
          }


        }} />)
  }


  return (
    <SafeAreaView style={{ flex: 1, paddingHorizontal:-45}}>
      <View style={{ paddingHorizontal: 3, paddingVertical:-45, flex: 1 }}>
        <View style={{ alignItems: 'center' }}>
          <Text style={{ fontSize: 32, textAlign: 'center', fontWeight: 'bold' }}>
            {categoryName}
          </Text>
        </View>
        <View style={{ alignItems: 'center' }}>
          <Text style={{ fontSize: 22 }}>
            {i18n.t('products.categories.addProductsByCategory.title')}
          </Text>

            <FlatList data={myProducts} renderItem={renderItem}
            numColumns={2}
            contentContainerStyle={{ paddingBottom: 175 }}
            keyExtractor={item => item.product_id}
            extraData={arrayProductId} />

        </View>
      </View>
      {/* <View style={{ flex: 0.4 }}> */}
        <TouchableOpacity
          style={{ backgroundColor: '#000000', borderRadius: 6, paddingVertical: 12, marginTop: 4, marginHorizontal: 5 }}
          onPress={() => {
            insertProductsToCustomCategory({
              variables: {
                userCategoryProducts: arrayProductId
              }
            }).then(() => {
              Alert.alert("Success", "Products succesfully added to " + categoryName, [
                {
                  text: "OK",
                  onPress: () => navigation.goBack()
                }
              ]);
            })
          }}
        >
          <Text style={{ color: '#FFFFFF', textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>
            {i18n.t('products.categories.addProductsByCategory.btn_save')}
          </Text>
        </TouchableOpacity>
      {/* </View> */}
    </SafeAreaView>
  );
}

export default AddProductsByCategory;