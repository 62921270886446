import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { View, Text, ActivityIndicator, TouchableOpacity,Alert, ScrollView , TextInput, Modal, Platform} from 'react-native';
import { Feather } from '@expo/vector-icons';
import { SafeAreaView } from 'react-native-safe-area-context';
// import Firebase from '../../lib/firebase';
import {Picker} from '@react-native-picker/picker';
import {UPDATE_USER_LOCATIONS, GET_COUNTRIES, GET_CITY_BY_PROVINCE, GET_PROVINCE_BY_COUNTRY, DELETE_USER_LOCATIONS } from '../../lib/queries';
import UserData from '../UserData';
// import { TextInput } from 'react-native-gesture-handler';
import i18n from '../../i18n/i18n';



const EditLocations = ({navigation, route}) => {
    /**SelectValue */
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedProvince, setSelectedProvince] = useState()
    const [selectedCity, setSelectedCity] = useState()
    
    const {userLocationIdentifier} = route.params;
    const {user_location_id} = userLocationIdentifier
    const {countryIdentifier} = route.params;
    const {country_identifier} = countryIdentifier
    const {provinceIdentifier} = route.params
    const {province_identifier} = provinceIdentifier
    const {cityIdentifier} = route.params
    const {city_identifier} = cityIdentifier
    const {address1Param} = route.params
    const {address1} = address1Param
    const {address2Param} = route.params
    const {address2} = address2Param
    const {zipCodeParam} = route.params
    const {zip_code} = zipCodeParam

    const [countryId, setCountryId] = useState(country_identifier ? country_identifier: null);
    const [provinceId, setProvinceId] = useState(province_identifier ? province_identifier: null);
    const [cityId, setCityId] = useState(city_identifier ? city_identifier: null);
    const [userLocationId, setUserLocationId] = useState(user_location_id ? user_location_id: null)
    const [address_1, setAddress_1] = useState(address1 ? address1: '')
    const [address_2, setAddress_2] = useState(address2 ? address2: '')
    const [zipCode, setZipCode] = useState(zip_code ? zip_code: '')
    /**Queries */
    // const {loading: userLocationFetch, data: userLocationData} = useQuery(GET_USER_lOCATION_ID, {variables:{userLocationId}})
    const {loading: countryFetch, data: countryData} = useQuery(GET_COUNTRIES)
    const {loading: provinceFetch, data: provinceData} = useQuery(GET_PROVINCE_BY_COUNTRY, {variables:{ countryId }})
    const {loading: cityFetch, data: cityData} = useQuery(GET_CITY_BY_PROVINCE, {variables:{ provinceId }})
    const [updateUserLocation, {loading: mutationLoadingUpdateUserLocation, data: updateUserLocationResponse}] = useMutation(UPDATE_USER_LOCATIONS)
    const [deleteUserLocation, {loading: mutationLoadingDeleteUserLocation, data: deleteUserLocationResponse}] = useMutation(DELETE_USER_LOCATIONS)
    
    /**User ID */
    
     /**Defining states react*/
    const [country, setCountry] = useState([])
    const [province, setProvince] = useState([])
    const [city, setCity] = useState([])
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);
    const [modalVisibilityDelete, setModalVisibilityDelete] = useState(false);

    let newCountry = null
    let newProvince = null
    let newCity = null 
      /**Queries*/
    useEffect(()=>{

        console.log("Country ID Bro",countryId)
        // console.log("Console params: ", route.params)
        // console.log(user_location_id);
        // console.log("City: ",cityId, " Province: ", provinceId, ' Country ', countryId)
        let newProvinces
        if(countryData){
            const {countries} = countryData
            newCountry = countries
            // console.log(country)
        }

        if(provinceData){
            const {provinces: provin} = provinceData
            newProvince = provin
        }

        if(cityData){
            const {cities} = cityData
            newCity = cities
        }

        setCountry(newCountry)
        setProvince(newProvince)
        setCity(newCity)

        console.log(newProvince)
        
    });


    if(countryFetch || provinceFetch || cityFetch) return(
            <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
            <ActivityIndicator color={'#1e76e8'} size={"large"}/>
          </SafeAreaView>
    )
    return(
        <SafeAreaView style={{flex:1, paddingVertical:-45}}>
            <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
                              {/* <TouchableOpacity style={{marginTop:20}} onPress={() => {
                                setModalVisibilityAlert(false)
                              }}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                              </TouchableOpacity> */}
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
                              {/* <TouchableOpacity style={{marginTop:20}} onPress={() => {
                                setModalVisibilityAlert(false)
                              }}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                              </TouchableOpacity> */}
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
            <Modal visible={modalVisibilityDelete}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityDelete(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
                              <TouchableOpacity style={{marginTop:20}} onPress={() => {
                                deleteUserLocation({variables:{userLocationId}}).then(res => {
                                  /**timeout to alert */
                                  setMessageTitle(i18n.t('userSettings.locations.editLocation.alert_delete_success_title'))
                                  setMessageBody(i18n.t('userSettings.locations.editLocation.alert_delete_success_body'))
                                  setTimeout(() => {

                                  setModalVisibilityDelete(false);
                                  }, 1250);
                                })
                              }}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Okay</Text>
                              </TouchableOpacity>
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityDelete(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>   
        <ScrollView style={{paddingHorizontal:5}}>
            <View style={{flexDirection:'row', alignItems:'center'}}>
            <TouchableOpacity
                  style={{backgroundColor:'#e0e0e0', width:32, height:32, alignItems:'center', justifyContent:'center'}}
                  onPress={() => navigation.goBack()}
                >
                  <Feather name='arrow-left' size={16} color='#000000' />
                </TouchableOpacity>
            <Text style={{fontSize:32, fontWeight:'bold', paddingHorizontal:2}}>
                {i18n.t('userSettings.locations.editLocation.title')}
            </Text>
            </View>

                <View style={{paddingVertical:5}}>
        <Text style={{fontSize:18}}>
            {i18n.t('userSettings.locations.editLocation.country')}
        </Text>

        <Picker
        
    style={{flexDirection:'row', paddingTop:2}}
    selectedValue={countryId}
    itemStyle={{height: 100}}
    onValueChange={(itemValue, itemIndex) => setCountryId(itemValue)}>
                 <Picker.Item label="Show Items" 
              value="0" key="0"/>
      {(countryData)?(
          countryData.countries.map((v) =>{
            return <Picker.Item label={v.country_name} value={v.country_id} key={v.country_id}></Picker.Item>
          })
      ):null}
  </Picker>



  <View style={{paddingVertical:2}}>
        <Text style={{fontSize:20}}>
            {i18n.t('userSettings.locations.editLocation.province')}
        </Text>

          <Picker style={{flexDirection:'row'}}
          selectedValue={provinceId}
          itemStyle={{height: 100}}
          onValueChange={(itemValue, itemIndex) => setProvinceId(itemValue)}>
                       <Picker.Item label="Show Items" 
              value="0" key="0"/>
              {(provinceData)?(
                 provinceData.provinces.map((v)=>{
                      return <Picker.Item label={v.province_name} value={v.province_id} key={v.province_id}/>
                  })
              ):null}
          </Picker>
    

      </View>
      <View style={{paddingVertical:2}}>
        <Text style={{fontSize:22}}>
            {i18n.t('userSettings.locations.editLocation.city')}
        </Text>

          <Picker style={{flexDirection:'row'}}
          selectedValue={cityId}
          itemStyle={{height: 100}}
          onValueChange={(itemValue, itemIndex) => setCityId(itemValue)}>
              <Picker.Item label="Show Items" 
              value="0" key="0"/>
              {(cityData)?(
                 cityData.cities.map((v)=>{
                      return <Picker.Item label={v.city_name} value={v.city_id} key={v.city_id}/>
                  })
              ):null}
          </Picker>
    

      </View>
      <View style={{paddingVertical:5, flexDirection:'row', paddingTop:-45, paddingBottom:-45}}>
            <TextInput style={{backgroundColor:'#e0e0e0', paddingHorizontal:5, flexGrow:1}}
            placeholder={i18n.t('userSettings.locations.editLocation.address1')}
            defaultValue={address_1}
            onChangeText={(v)=> {setAddress_1(v); console.log(address1)}}>
            </TextInput>
          </View>
          <View style={{paddingBottom:5, flexDirection:'row'}}>
            <TextInput style={{backgroundColor:'#e0e0e0', paddingHorizontal:5, flexGrow:1}}
            placeholder={i18n.t('userSettings.locations.editLocation.address2')}
            defaultValue={address_2}
            onChangeText={(v)=> {setAddress_2(v)}}
            >
            </TextInput>
          </View>
          <View removeClippedSubviews={true} style={{flexDirection:'row'}}>
            <TextInput style={{backgroundColor:'#e0e0e0', paddingHorizontal:5, flexGrow:1}}
            keyboardType={'numeric'}
            contextMenuHidden={true}
            defaultValue={zipCode}
            placeholder={i18n.t('userSettings.locations.editLocation.postal_code')}
            onChangeText={(v)=> setZipCode(v)}>
            
            </TextInput>
          </View>
      </View>
      {/* Add for tomorrow the dataprovince.provinces to requery shit */}
      <View style={{paddingHorizontal:5}}>
       <TouchableOpacity
            style={{backgroundColor:'#000000', borderRadius:5, paddingVertical:5, marginTop:10}}
            onPress={()=> {
              if(address_1 === ''){
                Alert.alert(i18n.t('userSettings.locations.addLocation.error_primary_address'), i18n.t('userSettings.locations.addLocation.error_primary_address_body'))
                  if(Platform.OS === 'web'){
                    setMessageTitle(i18n.t('userSettings.locations.addLocation.error_primary_address'))
                    setMessageBody(i18n.t('userSettings.locations.addLocation.error_primary_address_body'))
                    setModalVisibilityAlert(true);
                  }
              }
              else{
                updateUserLocation({
                  variables:{
                    cityId,
                    countryId,
                    provinceId,
                    zipCode,
                    address_1,
                    address_2,
                    userLocationId
                  }
                }).then(()=>{
                  Alert.alert(i18n.t('userSettings.locations.editLocation.alert_success_title'), i18n.t('userSettings.locations.editLocation.alert_success_body'))
                  if(Platform.OS === 'web'){
                    setMessageTitle(i18n.t('userSettings.locations.editLocation.alert_success_title'))
                    setMessageBody(i18n.t('userSettings.locations.editLocation.alert_success_body'))
                    setModalVisibilityAlert(true);
                  }
                }).catch(e =>{
                  Alert.alert('Error', e.message)
                })
              }
            }
          }
          
          >
            <Text style={{textAlign:'center', fontWeight:'bold', fontSize:18, borderRadius:6, color:'#FFFFFF'}}>
              {i18n.t('userSettings.locations.editLocation.btn_save')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:6, marginTop:2}}
            onPress={()=> navigation.goBack()}
          >
            <Text style={{textAlign:'center', fontWeight:'bold', fontSize:18}}>
              {i18n.t('userSettings.locations.editLocation.btn_cancel')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{backgroundColor:'#1e76e8', borderRadius:6, paddingVertical:6, marginTop:2}}
            onPress={()=> {
              Alert.alert(i18n.t('userSettings.locations.editLocation.alert_delete_title'), i18n.t('userSettings.locations.editLocation.alert_delete_body'),
            [
              { 
                text:"Deny",
                onPress: ()=> console.log("Denied")
              },
              {
                text:"Accept",
                onPress: ()=> {deleteUserLocation({variables:{userLocationId}})}
              }
          ])
          if(Platform.OS === 'web'){
            setMessageTitle(i18n.t('userSettings.locations.editLocation.alert_delete_title'))
            setMessageBody(i18n.t('userSettings.locations.editLocation.alert_delete_body'))
            setModalVisibilityDelete(true);
          }
        }}
          >
            <Text style={{textAlign:'center', fontWeight:'bold', fontSize:18}}>
              {i18n.t('userSettings.locations.editLocation.btn_delete')}
            </Text>
          </TouchableOpacity>
       </View>
   
        </ScrollView>
    </SafeAreaView>
    )
    
}

export default EditLocations;