import React, { useState, useEffect, useRef } from 'react';
import { SafeAreaView, StyleSheet, Text, TouchableOpacity, ActivityIndicator, Alert, View, TextInput, ImageBackground, TouchableWithoutFeedback, FlatList, Platform, Image, Dimensions, useWindowDimensions, Linking, ScrollView, Pressable } from 'react-native';
import Firebase from '../lib/firebase';
import { getAuth, signInWithEmailAndPassword, FacebookAuthProvider, signInWithCredential } from 'firebase/auth';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_CITIES, GET_CITY_BY_PROVINCE, GET_COUNTRIES, GET_MY_PRODUCTS, GET_NEAR_PRODUCTS, GET_NEAR_STORES, GET_PROVINCES, GET_PROVINCE_BY_COUNTRY,
  GET_CATEGORIES, GET_SUBCATEGORIES_BY_CATEGORY_ID, GET_USER, INSERT_ORGANIZATION_ONE, DELETE_ORGANIZATION_BY_ID, VERSION_INFORMATION
} from '../lib/queries';
import i18n from '../i18n/i18n';
import { decycle } from 'json-cyclic'
import { useFonts } from 'expo-font';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { useTogglePasswordVisibility } from '../hooks/useTogglePasswordVisibility';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { makeRedirectUri, ResponseType } from 'expo-auth-session';

// import { BannerAd, BannerAdSize, TestIds } from 'react-native-google-mobile-ads'
import { Link, useIsFocused } from '@react-navigation/native';
// import MapView from 'react-native-maps';
// import { MapView } from 'expo';
// import MapView, { Polyline, Circle, Marker } from 'react-native-maps';
import Slider from '@react-native-community/slider';
import MapView, { Circle, Marker } from 'react-native-maps';
import { PROVIDER_GOOGLE } from 'react-native-maps';
// import DropDownPicker from 'react-native-dropdown-picker';
import { Feather, Ionicons } from '@expo/vector-icons';
import Modal from 'react-native-modal'
import { useCallback } from 'react';
import { getStorage, deleteObject } from 'firebase/storage';
const storage = getStorage(Firebase);
//expo constants
import Constants from 'expo-constants';
//expo-applications
import * as Application from 'expo-application';
import { Modal as RNModal } from 'react-native';
import { getFunctions, httpsCallable } from 'firebase/functions';
import * as AppleAuthentication from 'expo-apple-authentication';
import { Helmet } from 'react-helmet-async';

/**Import based on Platform native or web */
const ModalDropdown = Platform.select({
  native: () => require('react-native-modal-dropdown'),
  web: () => null
})();

const BannerAd = Platform.select({
  native: () => require('react-native-google-mobile-ads').BannerAd,
  web: () => null
})();

const BannerAdSize = Platform.select({
  native: () => require('react-native-google-mobile-ads').BannerAdSize,
  web: () => null
})();


const auth = getAuth(Firebase);
const functions = getFunctions(Firebase);



const Home = ({ navigation, route }) => {
  const [fontsLoaded] = useFonts({
    'Inter-Black': require('../assets/fonts/Roboto-Black.ttf'),
    'Inter-BlackItalic': require('../assets/fonts/Roboto-BlackItalic.ttf'),
    'Inter-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
    'Inter-BoldItalic': require('../assets/fonts/Roboto-BoldItalic.ttf'),
    'Inter-Italic': require('../assets/fonts/Roboto-Italic.ttf'),
    'Inter-Light': require('../assets/fonts/Roboto-Light.ttf'),
    'Inter-LightItalic': require('../assets/fonts/Roboto-LightItalic.ttf'),
    'Inter-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
    'Inter-MediumItalic': require('../assets/fonts/Roboto-MediumItalic.ttf'),
    'Inter-Regular': require('../assets/fonts/Roboto-Regular.ttf'),
    'Inter-Thin': require('../assets/fonts/Roboto-Thin.ttf'),
    'Inter-ThinItalic': require('../assets/fonts/Roboto-ThinItalic.ttf'),
  });

  const ref = useRef();

  const isFocused = useIsFocused();

  const { latitude } = route.params;
  const { longitude } = route.params;

  let lat = parseFloat(latitude);
  let long = parseFloat(longitude);
  /**Near products */
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const langQuery = language.toUpperCase();
  const [uid, setUid] = useState();
  const [user, setUser] = useState(null);
  const [nearProducts, setNearProducts] = useState([]);
  const [isInOrganization, setIsInOrganization] = useState(false);
  const [organizationData, setOrganizationData] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [nearOrganizations, setNearOrganizations] = useState([]);
  const [organizationId, setOrganizationId] = useState();
  const [organizationName, setOrganizationName] = useState();
  const [organizationSetup, setOrganizationSetup] = useState(false);
  const [distance, setDistance] = useState(80000);
  const [latitudeState, setLatitudeState] = useState(lat);
  const [longitudeState, setLongitudeState] = useState(long);
  const [regionLatitude, setRegionLatitude] = useState(lat);
  const [regionLongitude, setRegionLongitude] = useState(long);
  const [modalVisibleMap, setModalVisibleMap] = useState(false);
  const [latitudeDelta, setLatitudeDelta] = useState(0.0022);
  const [longitudeDelta, setLongitudeDelta] = useState(0.0022);
  const [myProducts, setMyProducts] = useState([]);

  const [modalVisibleFilter, setModalVisibleFilter] = useState(false);
  const [modalCategoryFilter, setModalCategoryFilter] = useState(false);
  const [modalOrganizations, setModalOrganizations] = useState(false);

  const [modalLoginVisible, setModalLoginVisible] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [country, setCountry] = useState(undefined);
  const [province, setProvince] = useState(undefined);
  const [city, setCity] = useState(undefined);

  const [indexCountry, setIndexCountry] = useState(-1);
  const [indexProvince, setIndexProvince] = useState(-1);
  const [indexCity, setIndexCity] = useState(-1);


  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedProvinceName, setSelectedProvinceName] = useState('');
  const [selectedCityName, setSelectedCityName] = useState('');

  const [organizationCountryId, setOrganizationCountryId] = useState(undefined);
  const [organizationProvinceId, setOrganizationProvinceId] = useState(undefined);
  const [organizationCityId, setOrganizationCityId] = useState(undefined);


  const [category, setCategory] = useState(undefined);
  const [subCategory, setSubCategory] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState(undefined);

  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');


  const [selectedCityId, setSelectedCityId] = useState(0);
  const [selectedProvinceId, setSelectedProvinceId] = useState(0);
  const [selectedCountryId, setSelectedCountryId] = useState(0);
  const [versionMatches, setVersionMatches] = useState(false);

  const [locationFilter, setLocationFilter] = useState(undefined);

  const [userLocationsFilter, setUserLocationsFilter] = useState({
    "_or": [
      {
        "user": {
          "user_locations": {
            "country": {
              "user_locations": {
                "user_id": {
                  "_eq": uid
                }
              }
            }
          }
        }
      }
    ]
  });

  const [organizationLocationFilter, setOrganizationLocationFilter] = useState({
    "country": {
      "user_locations": {
        "user_id": {
          "_eq": uid
        }
      }
    }
  });

  const [productTranslationsFilter, setProductTranslationsFilter] = useState(undefined);

  /**searchText */

  const [searchText, setSearchText] = useState(null);

  const [jsonGeography, setJsonGeography] = useState({
    "type": "Point",
    "coordinates": [
      longitudeState,
      latitudeState
    ]
  });

  const { passwordVisibility, rightIcon, handlePasswordVisibility } = useTogglePasswordVisibility()


  // const isFocused = useIsFocused();

  /**Firebase */
  // const uid= getAuth().currentUser.uid;

  const { loading: loadingUserData, error: errorUserData, data: dataUserData, refetch: refetchUserQuery } = useQuery(GET_USER, { variables: { uid: uid } });
  const { loading: loadingNearProducts, error: errorNearProducts, data: dataNearProducts, refetch } = useQuery(GET_NEAR_PRODUCTS, { variables: { uid, point: jsonGeography, distance, languageCode: langQuery, locationFilter, categoryFilter, userLocationsFilter, productTranslationsFilter, searchText } });
  const { loading: loadingNearStores, error: errorNearStores, data: dataNearStores, refetch: refetchNearStores } = useQuery(GET_NEAR_STORES, { variables: { point: jsonGeography, distance, uid: uid, organizationCountryId, organizationProvinceId, organizationCityId, searchText, organizationLocationFilter } });
  const { loading: loadingGetMyProducts, error: errorGetMyProducts, data: dataGetMyProducts, refetch: refetchGetMyProducts } = useQuery(GET_MY_PRODUCTS, { variables: { uid: uid } });
  const { loading: loadingGetSystemVersion, error: errorGetSystemVersion, data: dataGetSystemVersion, refetch: refetchGetSystemVersion } = useQuery(VERSION_INFORMATION);
  const { loading: loadingCountries, data: dataCountries, refetch: refetchCountries } = useQuery(GET_COUNTRIES);
  const { loading: loadingProvinces, data: dataProvinces, refetch: refetchProvinces } = useQuery(GET_PROVINCE_BY_COUNTRY, { variables: { countryId: selectedCountryId } });
  const { loading: loadingCities, data: dataCities, refetch: refetchCities } = useQuery(GET_CITY_BY_PROVINCE, { variables: { provinceId: selectedProvinceId } });
  const { loading: loadingCategories, data: dataCategories, error: errorCategories, refetch: refetchCategories } = useQuery(GET_CATEGORIES, { variables: { languageCode: langQuery } });
  const { loading: loadingSubCategories, data: dataSubCategories, error: errorSubCategories, refetch: refetchSubCategories } = useQuery(GET_SUBCATEGORIES_BY_CATEGORY_ID, { variables: { categoryId: selectedCategory, languageCode: langQuery } });
  const [insertOrganizationOne, { loading: loadingInsertOrganizationOne, error: errorInsert }] = useMutation(INSERT_ORGANIZATION_ONE);
  const [deleteOrganizationById, { loading: loadingDeleteOrganizationById, error: errorDeleteOrganizationById }] = useMutation(DELETE_ORGANIZATION_BY_ID);

  async function getNearProducts() {
    // console.log("LATITUDE, LONGITUDE: ", lat, long)
    console.log("ERROR NEAR PRODUCTS: ", errorNearProducts);
    console.log("ERROR NEAR Stores:", errorNearStores);
    if (errorNearProducts) {
      // console.log("ERROR NEAR PRODUCTS: ", errorNearProducts);
    }
    // console.log("DATA NEAR PRODUCTS: ", dataNearProducts);
    if (dataNearProducts) {
      // console.log("dataNearProducts", dataNearProducts);
      // console.log(langQuery);
      const { search_products } = dataNearProducts;
      // console.log("user_products", user_products);
      setNearProducts(search_products);

    }
  }

  async function getNearStores() {
    console.log("ERROR NEAR STORES: ", errorNearStores);
    // console.log(dataNearStores);
    if (dataNearStores) {
      const { search_organizations } = dataNearStores;
      setNearOrganizations(search_organizations);
      // console.log("ORGANIZATION: ", organization);
    }
  }

  async function getMyProducts() {
    console.log("ERROR MY PRODUCTS: ", errorGetMyProducts);
    if (dataGetMyProducts) {
      const { user_products } = dataGetMyProducts;

      setMyProducts(user_products);

      // setNearProducts(user_products);
    }
  }

  async function getCountries() {
    if (dataCountries) {
      const { countries } = dataCountries;
      // console.log("COUNTRIES: ", countries);
      setCountry(countries);

    }
  }

  async function getProvinces() {
    if (dataProvinces) {
      const { provinces } = dataProvinces;
      // console.log("PROVINCES: ", provinces);
      setProvince(provinces);
    }
  }

  async function getCategories() {
    if (dataCategories) {
      const { default_categories } = dataCategories;
      setCategory(default_categories);
    }
  }
  async function getSubCategories() {
    console.log("ERROR SUBCATEGORIES: ", errorSubCategories);
    if (dataSubCategories) {
      const { default_subcategories } = dataSubCategories;
      setSubCategory(default_subcategories);
    }
  }

  async function getCities() {
    if (dataCities) {
      const { cities } = dataCities;
      // console.log("CITIES: ", cities);
      setCity(cities);
    }
  }

  async function getUserData() {
    if (dataUserData) {
      const { users_by_pk } = dataUserData;
      // check if organization_users is not null
      const { organization_users } = users_by_pk;
      if (organization_users) {
        setOrganizationData(organization_users);
      }
      if (organizationData && organizationData.length > 0) {
        setIsInOrganization(true)
        setOrganizationId(organizationData[0].organization_id)
        setOrganizationName(organizationData[0].organization.organization_name)
        setOrganizationSetup(organizationData[0].organization.organization_setup);
        setOrganizations(users_by_pk['organization_users'])
      }
    } else {
      setIsInOrganization(false)
      setOrganizationSetup(false);
    }
  }

  async function getSystemVersion() {
    if (dataGetSystemVersion) {
      const { version_information } = dataGetSystemVersion;
      const { 0: version } = version_information;
      const { version_code } = version;
      console.log("VERSION INFORMATION: ", version_information);
      if (version_code == Application.nativeApplicationVersion) {
        console.log("VERSION MATCHES");
        setVersionMatches(true);
      } else {
        console.log("VERSION DOES NOT MATCH");
        setVersionMatches(false);
      }
    }
  }

  /**Facebook result */
  const [request, response, promptAsync] = Facebook.useAuthRequest({
    ResponseType: ResponseType.Token,
    clientId: '308615167097974',
    scopes: ['public_profile', 'email'],
  }, {
    useProxy: false,
  });


  useEffect(() => {
    ref.current?.setAddressText(prevAddress => prevAddress);

    if (isFocused) {
      getAuth().onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.
          // console.log("USER: ", user);
          setUser(user);
          setUid(user.uid);
          // setOrganizationSetup(false);
          // setIsInOrganization(false)
          getNearProducts();
          getNearStores();
          getMyProducts();
          getCountries();
          getProvinces();
          getCities();
          getCategories();
          getSubCategories();
          getUserData();
          getSystemVersion();

          // refetchs

          refetch();
          refetchNearStores();
          refetchGetMyProducts();
          refetchCountries();
          refetchProvinces();
          refetchCities();
          refetchCategories();
          refetchUserQuery();
          refetchNearStores();
          refetchSubCategories();
          refetchGetSystemVersion();

        } else {
          // No user is signed in.
          // console.log("NO USER");
          setUser(null);
          setUid("");
          // setOrganizationSetup(false);
          // setIsInOrganization(false)
          getNearProducts();
          getNearStores();
          getMyProducts();
          getCountries();
          getProvinces();
          getCities();
          getCategories();
          getSubCategories();
          getUserData();
          getSystemVersion();

          // refetchs

          refetch();
          refetchNearStores();
          refetchGetMyProducts();
          refetchCountries();
          refetchProvinces();
          refetchCities();
          refetchCategories();
          refetchUserQuery();
          refetchNearStores();
          refetchSubCategories();
          refetchGetSystemVersion();
        }
      });

      (async () => {
        // setSelectedLanguage(language);

        if (response?.type === 'success') {
          const { access_token } = response.params;
          console.log("ACCESS TOKEN", response.params)
          // login with facebook using cloud function createuserfaceobok
          // const credential = new FacebookAuthProvider.credential(access_token);
          // const credential = provider.credential(access_token);
          // Sign in with the credential from the Facebook user.
          // console.log("CREDENTIAL", credential)

          const credential = FacebookAuthProvider.credential(access_token);

          //signInWithCredential(auth, credential) and then wait for custom claims to be set
          await signInWithCredential(auth, credential).catch((error) => {
            console.log("ERROR", error)
          });
        }
        //request

        if (request) {
          // console.log("REQUEST", request)
        }
      })();
    }
  });

  let screenWidth
  let numColumns
  let itemWidth
  let screenLarge;

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    screenWidth = useWindowDimensions().width;
    numColumns = 3;
    itemWidth = screenWidth / numColumns;
  } else if (Platform.OS === 'web') {
    screenWidth = useWindowDimensions().width;

    if (screenWidth > 1200) {
      numColumns = 8;
      screenLarge = true;
      itemWidth = screenWidth / numColumns;
    } else if (screenWidth > 768 && screenWidth <= 1200) {
      numColumns = 5;
      itemWidth = screenWidth / numColumns;
      screenLarge = true;
    }
    else {
      numColumns = 3;
      itemWidth = screenWidth / numColumns;
      screenLarge = false;
    }
  }

  const NearProducts = ({ item, product_id, product_photo, product_title, product_currency, product_price, seller_id, product_thumbnail, product_category, product_subcategory, user_province, user_city, organization_id }) => (
    <View style={{}}>
      {Platform.OS !== 'web' ? (
        <TouchableOpacity style={{ backgroundColor: "#FAFAFA", marginHorizontal: 2, borderRadius: 10, marginBottom: 5, maxWidth: itemWidth - 4 }}
          onPress={() => {
            // console.log(product_price)
            navigation.navigate("ProductDetailed", {
              productId: product_id,
              sellerId: seller_id,
              distanceCart: distance,
              latitude: latitude,
              longitude: longitude,
              organization_id: organization_id ? organization_id : 0,
            })
          }}>
          <Image style={{ height: itemWidth - 4, width: itemWidth - 4, borderRadius: 10 }} source={{ uri: product_thumbnail }} />
          {/* <FastImage style={{height:itemWidth, width:itemWidth-2, borderRadius:6}} source={{uri:product_photo , priority:FastImage.priority.high}}/> */}

          {/* <View style={{flexDirection:'row'}}> */}

          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 16 : 18, fontFamily: 'Inter-Medium', textAlign: 'left' }}>{
            product_title.length > 16 ? product_title.substring(0, 15) + "..." : product_title
          }</Text>

          {/* </View> */}

          <Text style={{ fontSize: 14, fontFamily: 'Inter-Regular', textAlign: 'right', paddingHorizontal: 3 }}>{product_category}</Text>
          <Text style={{ fontSize: 14, fontFamily: 'Inter-Regular', textAlign: 'right', paddingHorizontal: 3 }}>{user_city}, {user_province}</Text>
          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 18, fontFamily: 'Inter-Bold', textAlign: 'right' }}>{product_price} {product_currency} </Text>
        </TouchableOpacity>
      ) : (
        <View style={{ backgroundColor: "#FAFAFA", marginHorizontal: 2, borderRadius: 10, marginBottom: 5, maxWidth: itemWidth - 4 }}>
          <Image style={{ height: itemWidth - 4, width: itemWidth - 4, borderRadius: 10 }} source={{ uri: product_thumbnail }} />
          {/* <FastImage style={{height:itemWidth, width:itemWidth-2, borderRadius:6}} source={{uri:product_photo , priority:FastImage.priority.high}}/> */}

          {/* <View style={{flexDirection:'row'}}> */}
          {/* <Link to={{screen: "ProductDetailed", params: {
          productId: product_id,
          sellerId: seller_id,
          distanceCart: distance,
          latitude: latitude,
          longitude: longitude,
          organization_id: organization_id ? organization_id : 0,
        }}}> */}
          <Link to={{
            screen: "Drawer",
            params: {
              screen: "Home",
              params: {
                screen: "HomeTab",
                params: {
                  screen: "ProductDetailed",
                  params: {
                    productId: product_id,
                    sellerId: seller_id,
                    distanceCart: distance,
                    latitude: latitude,
                    longitude: longitude,
                    organization_id: organization_id ? organization_id : 0,
                  }
                }
              }
            }
          }}>
            <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 16 : 18, fontFamily: 'Inter-Medium', textAlign: 'left', textDecorationLine: 'underline' }}>{
              product_title.length > 16 ? product_title.substring(0, 15) + "..." : product_title
            }</Text>
          </Link>
          <Text style={{ fontSize: 14, fontFamily: 'Inter-Regular', textAlign: 'right', paddingHorizontal: 3 }}>{product_category}</Text>
          <Text style={{ fontSize: 14, fontFamily: 'Inter-Regular', textAlign: 'right', paddingHorizontal: 3 }}>{user_city}, {user_province}</Text>
          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 18, fontFamily: 'Inter-Bold', textAlign: 'right' }}>{product_price} {product_currency} </Text>
        </View>
      )}
    </View>
  )

  const bannerError = () => {
    // console.log("bannerError");
  }

  let count = useRef(0);

  const renderSeparator = () => {
    count.current += 1;

    if (count.current % 3 == 0) {
      return (
        <View>
          <BannerAd
            unitId={Platform.OS === 'android' ? "ca-app-pub-4925893888940052/5962803076" : "ca-app-pub-4925893888940052/5268911695"}
            size={BannerAdSize.FULL_BANNER}
            requestOptions={{
              requestNonPersonalizedAdsOnly: true,
            }}
            onAdLoaded={() => {
              // console.log('Advert loaded');
            }}
            onAdFailedToLoad={(error) => {
              // console.log('Advert failed to load: ', error);
            }}
          />
        </View>
      )
    } else {
      return null;
    }
  }
  const NearStoresItem = ({ item, id, organization_name, organization_logo, organization_distance, organization_description }) => (
    <>
      {(Platform.OS != 'web') ? (
        <TouchableOpacity
          style={{ marginRight: 5, backgroundColor: '#FAFAFA', borderRadius: 6, width: Platform.OS === 'ios' || Platform.OS === 'android' ? 320 : screenLarge ? 400 : 320 }}
          onPress={() => {
            // console.log(id);
            navigation.navigate("OrganizationPage", {
              latitude: parseFloat(latitudeState),
              longitude: parseFloat(longitudeState),
              organizationId: id,
              distance: distance
            })
          }}>
          <Image
            // style={{height:160, width:320, borderRadius:6}}
            style={{ height: Platform.OS === 'ios' || Platform.OS === 'android' ? 160 : screenLarge ? 200 : 160, width: Platform.OS === 'ios' || Platform.OS === 'android' ? 320 : screenLarge ? 400 : 320, borderRadius: 6 }}
            source={{ uri: organization_logo }}
          ></Image>
          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 18 : 18, fontFamily: 'Inter-Medium', paddingHorizontal: 3 }}>{organization_name} - {(organization_distance / 1000).toFixed(0)} Km</Text>
          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 15, fontFamily: 'Inter-Regular', maxWidth: Platform.OS === 'android' || Platform.OS === 'ios' ? 320 : 400, paddingHorizontal: 3, paddingBottom: 5 }}>{organization_description}</Text>
        </TouchableOpacity>
      ) : (
        <View
          style={{ marginRight: 5, backgroundColor: '#FAFAFA', borderRadius: 6, width: Platform.OS === 'ios' || Platform.OS === 'android' ? 320 : screenLarge ? 400 : 320 }}
        >
          <Image
            // style={{height:160, width:320, borderRadius:6}}
            style={{ height: Platform.OS === 'ios' || Platform.OS === 'android' ? 160 : screenLarge ? 200 : 160, width: Platform.OS === 'ios' || Platform.OS === 'android' ? 320 : screenLarge ? 400 : 320, borderRadius: 6 }}
            source={{ uri: organization_logo }}
          ></Image>
          {(latitude && longitude) ? (
            <>
              <Link to={{
                screen: "Drawer",
                params: {
                  screen: "Home",
                  params: {
                    screen: "HomeTab",
                    params: {
                      screen: "OrganizationPage",
                      params: {
                        latitude: parseFloat(latitudeState),
                        longitude: parseFloat(longitudeState),
                        organizationId: id,
                        distance: distance
                      }
                    }
                  }
                }
              }}
              >
                <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 18 : 20, fontFamily: 'Inter-Medium', paddingHorizontal: 3, textDecorationLine: "underline" }}>{organization_name} - {(organization_distance / 1000).toFixed(0)} Km</Text>
              </Link>
            </>
          ) : (
            <>
              <Link to={{
                screen: "Drawer",
                params: {
                  screen: "Home",
                  params: {
                    screen: "HomeTab",
                    params: {
                      screen: "OrganizationPage",
                      params: {
                        organizationId: id,
                      }
                    }
                  }
                }
              }}
              >
                <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 18 : 20, fontFamily: 'Inter-Medium', paddingHorizontal: 3, textDecorationLine: "underline" }}>{organization_name} - {(organization_distance / 1000).toFixed(0)} Km</Text>
              </Link>
            </>
          )}
          <Text style={{ fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 15, fontFamily: 'Inter-Regular', maxWidth: Platform.OS === 'android' || Platform.OS === 'ios' ? 320 : 400, paddingHorizontal: 3, paddingBottom: 5 }}>{organization_description}</Text>
        </View>
      )}
    </>

  )

  const renderItemStores = useCallback(({ item }) => {
    return (
      <NearStoresItem item={item}
        id={item.organization_id}
        organization_name={item.organization_name}
        organization_logo={item.organization_logo}
        organization_distance={item.organization_distance}
        organization_description={item.organization_description}
      />
    )
  }, [])

  const renderItem = useCallback(({ item }) => {
    return (
      <NearProducts item={item}
        product_photo={item.product.product_photo_portrait}
        product_title={item.product.product_translations[0].product_title}
        product_currency={item.product.product_currencies[0].currency_code}
        product_price={item.product.product_currencies[0].product_price}
        product_id={item.product.product_id}
        seller_id={item.user.uid}
        product_thumbnail={item.product.product_thumbnail}
        product_category={item.product.product_default_subcategories[0].default_subcategory.default_category.default_category_translations[0].default_category_name}
        user_province={item.user.user_locations[0].province.province_abreviation}
        user_city={item.user.user_locations[0].city.city_name}
        organization_id={item.user?.organization_users[0] ? item.user.organization_users[0].organization_id : null}
      // product_subcategory={item.product.product_default_subcategories[0].default_subcategory.default_subcategory_translations[0].default_subcategory_name}
      />
    )
  }, [])

  const MyProducts = ({ item, product_id, product_photo, product_title, product_currency, product_price, seller_id, product_thumbnail, city_name, organization_id }) => (
    <View style={{}}>
      <TouchableOpacity style={{ maxWidth: itemWidth * 0.70, marginHorizontal: 3, marginVertical: 1, backgroundColor: '#FAFAFA', borderRadius: 10, alignItems: 'center', justifyContent: 'center' }}
        onPress={() => {
          // console.log(product_price)
          navigation.navigate("ProductDetailed", {
            productId: product_id,
            sellerId: seller_id,
            distanceCart: distance,
            latitude: latitude,
            longitude: longitude,
            organization_id: organization_id ? organization_id : 0,
          })
        }}>
        <Image style={{ height: itemWidth * 0.70, width: itemWidth * 0.70, borderRadius: 10, paddingHorizontal: 10, marginHorizontal: 2 }} source={{ uri: product_thumbnail }} />
        {/* <FastImage style={{height:itemWidth, width:itemWidth-2, borderRadius:6}} source={{uri:product_photo , priority:FastImage.priority.high}}/> */}
        <Text style={{ fontFamily: 'Inter-Regular', fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 14 : 18 }}>{
          product_title.length > 14 ? product_title.substring(0, 13) + "" : product_title
        }</Text>
      </TouchableOpacity>



      {/* <Text style={{ textAlign:'center', fontSize:  Platform.OS === 'android' || Platform.OS === 'ios'? 16 : 18}}> {product_price} {product_currency} </Text> */}
    </View>
  )

  const renderItemMyProducts = useCallback(({ item }) => {
    return (
      <MyProducts item={item}
        product_photo={item.product.product_photo_portrait}
        product_title={item.product.product_translations[0].product_title}
        product_currency={item.product.product_currencies[0].currency_code}
        product_price={item.product.product_currencies[0].product_price}
        product_id={item.product.product_id}
        seller_id={item.user.uid}
        product_thumbnail={item.product.product_thumbnail}
        organization_id={item.user.organization_users[0] ? item.user.organization_users[0].organization_id : null}
      />
    )
  }, [])

  const EmptyListMessage = ({ item }) => {
    return (
      // Flat List Item
      <Text style={{ textAlign: 'center', fontSize: 16, fontFamily: 'Inter-Regular' }}>
        {i18n.t('home.no_stores')}
      </Text>
    );
  };

  const EmptyListMessageMyProducts = ({ item }) => {
    return (
      // Flat List Item
      <Text style={{ textAlign: 'center', fontSize: 16, fontFamily: 'Inter-Regular' }}>
        {i18n.t('home.no_products_added')}
      </Text>
    );
  };

  const LoadingListMessage = ({ item }) => {
    return (
      // Flat List Item
      <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
        <ActivityIndicator size="large" color="#FF6D00" />
      </View>
    );
  };


  const EmptyMyProductsListMessage = ({ item }) => {
    return (
      <View>
        {(myProducts) ? (
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>

            <Text style={{ fontFamily: 'Inter-Medium', fontSize: Platform.OS === 'android' || Platform.OS === 'ios' ? 16 : 18 }}>
              {i18n.t('home.no_products_added')}
            </Text>
          </View>
        ) : (
          <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
            <ActivityIndicator size="large" color="#FF6D00" />
          </View>
        )}
      </View>
    )

  }

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    }
    catch (error) {
      console.log("Error LOGIN: ", error);
      if (error.code === 'auth/wrong-password') {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.wrong_password'), i18n.t('login.wrong_password_message'));
        } else if (Platform.OS === 'web') {
          // alert(i18n.t('login.wrong_password'), i18n.t('login.wrong_password_message'));
          setModalVisibility(true)
        }
      } else if (error.code === 'auth/too-many-requests') {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.too_many_attempts'), i18n.t('login.too_many_attempts_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityAttempts(true)
        }
      } else if (error.code === 'auth/user-not-found') {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.email_not_found'), i18n.t('login.email_not_found_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityUserNotFound(true)
        }
      } else if (error.code === "auth/network-request-failed") {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.network_error'), i18n.t('login.network_error_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityNetworkError(true)
        }
      } else if (error.code === "auth/invalid-email") {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.invalid_email'), i18n.t('login.invalid_email_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityInvalidEmail(true)
        }
      } else if (error.code === "(auth/user-not-found)") {
        if (Platform.OS === 'ios' || Platform.OS === 'android') {
          Alert.alert(i18n.t('login.user_not_found'), i18n.t('login.user_not_found_message'));
        } else if (Platform.OS === 'web') {
          setModalVisibilityUserNotFound(true)
        }
      }
    }
  }

  const handleSignup = async () => {

    try {
      {/* You should ideally do some sort of validation before this
      Think password length and so on */}

      /**Call firebase function */

      const registerUser = httpsCallable(functions, 'registerUser');
      await registerUser({ email, password });

      // console.log("Result: ", result);

      //Log the user in
      await signInWithEmailAndPassword(auth, email, password);

    } catch (error) {
      // console.log(error);
      /**Manage errors firebase signinWithEmail */
      Alert.alert('Error', error.message);
      if (error.message === 'The email address is badly formatted.') {
        Alert.alert(i18n.t('signup.email_invalid'), i18n.t('signup.email_invalid_body'));
        if (Platform.OS === 'web') {
          setMessageTitle(i18n.t('recover.email_invalid'));
          setMessageBody(i18n.t('recover.email_invalid_body'));
          setModalVisibilityAlert(true);
        }
      } else if (error.message === 'The email address is already in use by another account.') {
        Alert.alert(i18n.t('recover.error_email_in_use'), i18n.t('recover.error_email_in_use_body'));
        if (Platform.OS === 'web') {
          setMessageTitle(i18n.t('recover.error_email_in_use'));
          setMessageBody(i18n.t('recover.error_email_in_use_body'));
          setModalVisibilityAlert(true);
        }
      } else if (error.message === 'The password must be 6 characters long or more.') {
        Alert.alert(i18n.t('signup.password_too_short'), i18n.t('signup.password_too_short'));
        if (Platform.OS === 'web') {
          setMessageTitle(i18n.t('signup.password_too_short'));
          setMessageBody(i18n.t('signup.password_too_short'));
          setModalVisibilityAlert(true);
        }
      }
    }
  };

  const handleFacebookLogin = async () => {
    console.log("Facebook Login");

    if (Platform.OS === 'ios' || Platform.OS === 'android' || Platform.OS === 'web') {
      await promptAsync();
    }
  }

  function header() {
    return (
      <View>
        {(!versionMatches && Platform.OS !== 'web') ? (
          <TouchableOpacity
            style={{ backgroundColor: '#3D5AFE', borderRadius: 10, marginHorizontal: 9, marginTop: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingVertical: 5 }}
            onPress={() => { Linking.openURL(Platform.OS === 'ios' ? 'https://apps.apple.com/us/app/menucitos/id1643431406' : 'https://play.google.com/store/apps/details?id=com.nelson_arguera.menu') }}>
            <Text style={{ fontSize: 16, fontFamily: 'Inter-Regular', paddingHorizontal: 9, color: '#FFF' }}>{i18n.t('home.update_body')}</Text>
          </TouchableOpacity>
        ) : null}
        <Text style={{ fontSize: 24, paddingTop: 10, fontFamily: 'Inter-Medium', paddingHorizontal: 9 }}>{i18n.t('home.filters')}</Text>
        <TextInput style={{ paddingHorizontal: 12, marginHorizontal: 9, fontFamily: 'Inter-Regular', backgroundColor: '#e0e0e0', flexGrow: 1, paddingVertical: 2, fontSize: 18, borderRadius: 6, marginBottom: 5, }}
          placeholder={i18n.t('home.search')}
          onChangeText={text => {
            setSearchText(text ? text : null)
            console.log('searchText', searchText)
            // refetch();
            // refetchNearStores();
          }} />
        <ScrollView horizontal={true} style={{ flexDirection: 'row', paddingBottom: Platform.OS === 'web' ? 0 : 0, marginHorizontal: 6 }}>
          <TouchableOpacity onPress={() => setModalVisibleFilter(true)} style={{ padding: 10, backgroundColor: '#e0e0e0', borderRadius: 15, flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontSize: 16, padding: Platform.OS === 'web' ? 1 : 0, margin: Platform.OS === 'web' ? 1 : 0, fontFamily: 'Inter-Regular' }}>
              {i18n.t('home.location')}
            </Text>
            <Feather name='chevron-down' />
          </TouchableOpacity>
          {/**Clean filter */}
          {(locationFilter !== undefined) ? (
            <Pressable onPress={
              () => {
                setLocationFilter(undefined);
                setSelectedCountryName('');
                setSelectedProvinceName('');
                setSelectedCityName('');
                setOrganizationCountryId(undefined);
                setOrganizationProvinceId(undefined);
                setOrganizationCityId(undefined);
                setUserLocationsFilter(
                  {
                    "_or": [
                      {
                        "user": {
                          "user_locations": {
                            "country": {
                              "user_locations": {
                                "user_id": {
                                  "_eq": uid
                                }
                              }
                            }
                          }
                        }
                      }
                    ]
                  }
                );
                setOrganizationLocationFilter({
                  "country": {
                    "user_locations": {
                      "user_id": {
                        "_eq": uid
                      }
                    }
                  }
                })
                refetch();
                refetchNearStores();
              }
            }>
              <Feather name='x' size={20} style={{ padding: 10 }} />
            </Pressable>
          ) : (null)}
          <TouchableOpacity onPress={() => setModalCategoryFilter(true)} style={{ padding: 10, marginLeft: 10, backgroundColor: '#e0e0e0', borderRadius: 15, flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontSize: 16, padding: Platform.OS === 'web' ? 0 : 0, margin: Platform.OS === 'web' ? 0 : 0, fontFamily: 'Inter-Regular' }}>
              {i18n.t('home.category')}
            </Text>
            <Feather name='chevron-down' />
          </TouchableOpacity>
          {/**Clean filter */}
          {(categoryFilter !== undefined) ? (
            <Pressable onPress={
              () => {
                setCategoryFilter(undefined);
                setSelectedCategoryName('');
                setSelectedSubCategoryName('');
              }
            }>
              <Feather name='x' size={20} style={{ padding: 10 }} />
            </Pressable>
          ) : (null)}
        </ScrollView>

        {(!uid) ? (
          <View style={{ flexDirection: 'column', marginVertical: 5 }}>
            <TouchableOpacity onPress={() => navigation.navigate('Login')} style={{ backgroundColor: '#FFE082', borderRadius: 3, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingVertical: 10, paddingHorizontal: 10, marginHorizontal: 10 }}>
              <Text style={{ fontSize: 16, fontFamily: 'Inter-Medium', paddingHorizontal: 9 }}>{i18n.t('home.login_for_better_experience')}</Text>
            </TouchableOpacity>
          </View>
        ) : (null)}

        <Text style={{ fontSize: 20, marginLeft: 3, fontFamily: 'Inter-Regular' }}>{i18n.t('home.stores_near_you')}</Text>
        {(nearOrganizations) ? (
          <FlatList
            listKey='stores'
            contentContainerStyle={{ marginLeft: 3, marginRight: 3, paddingRight: 3 }}
            horizontal={true}
            data={nearOrganizations}
            showsHorizontalScrollIndicator={true}
            renderItem={renderItemStores}

            ListEmptyComponent={loadingNearStores ? LoadingListMessage : EmptyListMessage}
            keyExtractor={(item, index) => index.toString()}
          ></FlatList>
        ) : (
          <ActivityIndicator size="large" color="#FF6D00" />
        )}
        <View style={{ flexDirection: 'row' }}>
          <TouchableOpacity style={{
            backgroundColor: '#e0e0e0', paddingHorizontal: 6, marginHorizontal: 6,
            marginBottom: 6, paddingVertical: 0, borderRadius: 10, marginVertical: 10, flex: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center'
          }} onPress={() => {
            if (user) {
              navigation.navigate('AddProducts')
            } else {
              setModalLoginVisible(true);
            }
          }}>
            <Text style={{ fontSize: 18, color: 'black', fontFamily: 'Inter-Medium' }}>{i18n.t('home.btn_start_selling')}</Text>
          </TouchableOpacity>
          {/* {(isInOrganization && organizationSetup && organizationData)?(
          <TouchableOpacity style={{backgroundColor:'#3D5AFE', paddingLeft:6, marginRight:6,
      marginBottom:6, paddingVertical:6, borderRadius:15, marginVertical:10, flex:1,alignItems:'center', alignContent:'center', justifyContent:'center' }} onPress={()=>{
        navigation.navigate("OrganizationPage", {
        latitude: parseFloat(latitudeState),
        longitude: parseFloat(longitudeState),
        organizationId: organizationId,
        distance: distance
      })
      }}>
          <Text style={{fontSize:18,  fontFamily:'Inter-Medium', color:'white'}}>

           {organizationName}</Text>
        </TouchableOpacity>
        ):(null)} */}

          {(!isInOrganization) ? (
            <TouchableOpacity style={{
              backgroundColor: '#3D5AFE', paddingLeft: 6, marginRight: 6,
              marginBottom: 6, paddingVertical: 10, borderRadius: 10, marginVertical: 10, flex: 1
            }} onPress={() => {
              if (user) {
                insertOrganizationOne({
                  variables: {
                    uid: uid,
                  }
                }).then((res) => {
                  // console.log(res)
                  const organization_id = res.data.insert_organization_one.organization_id

                  navigation.navigate("SetupOrganizationPage", {
                    latitude: parseFloat(latitudeState),
                    longitude: parseFloat(longitudeState),
                    organizationId: organization_id,
                    distance: distance
                  })
                })
              } else {
                setModalLoginVisible(true);
              }
            }}>
              <Text style={{ fontSize: 18, fontFamily: 'Inter-Medium', color: 'white', textAlign: 'center' }}>{i18n.t('home.affiliate_us')}</Text>
            </TouchableOpacity>
          ) : null}

          {(isInOrganization) ? (
            <TouchableOpacity style={{
              backgroundColor: '#3D5AFE', paddingLeft: 6, marginRight: 6,
              marginBottom: 6, paddingVertical: 10, borderRadius: 10, marginVertical: 10, flex: 1
            }} onPress={() => {
              setModalOrganizations(true)
            }}>
              <Text style={{ fontSize: 18, fontFamily: 'Inter-Medium', color: 'white', textAlign: 'center' }}>{i18n.t('home.view_organizations')}</Text>
            </TouchableOpacity>
          ) : (null)}

        </View>
        <TouchableOpacity style={{
          backgroundColor: '#FF6D00', paddingHorizontal: 15, marginHorizontal: 6,
          marginBottom: 6, paddingVertical: 10, borderRadius: 10
        }} onPress={() => {
          setModalVisibleMap(true)
        }}>
          <Text style={{ fontSize: 18, fontFamily: 'Inter-Medium', color: 'white', textAlign: 'center' }}>{i18n.t('home.change_location')}</Text>
        </TouchableOpacity>

        <Text style={{ fontSize: 20, marginLeft: 3, fontFamily: "Inter-Regular" }}>{i18n.t('home.your_products')}</Text>
        <FlatList
          listKey='myProducts'
          contentContainerStyle={{}}
          horizontal={true}
          data={myProducts}
          showsHorizontalScrollIndicator={true}
          renderItem={renderItemMyProducts}
          ListEmptyComponent={loadingGetMyProducts ? LoadingListMessage : EmptyListMessageMyProducts}
          keyExtractor={(item, index) => item.product_id.toString()}
          indicatorStyle={'white'}
        ></FlatList>
        <Text style={{ fontSize: 20, marginLeft: 3, paddingBottom: 5, paddingTop: 5, fontFamily: 'Inter-Regular' }}>{i18n.t('home.products_near_you')}</Text>
      </View>
    )
  }

  const setItem = value => {
    console.log("you touched option: " + value);
  }

  const renderButtonCountry = (rowData) => {
    const { country_id, country_name } = rowData;
    return `${country_name}`
  }

  const renderDropDownListCountry = (rowData) => {
    return (
      <Text style={{ padding: 10, fontSize: 18 }}>{rowData.country_name}</Text>
    )
  }

  const renderButtonProvince = (rowData) => {
    const { province_id, province_name } = rowData;
    return `${province_name}`
  }

  const renderDropDownListProvince = (rowData) => {
    return (
      <Text style={{ padding: 10, fontSize: 18 }}>{rowData.province_name}</Text>
    )
  }

  const renderButtonCity = (rowData) => {
    const { city_id, city_name } = rowData;
    return `${city_name}`
  }

  const renderDropDownListCity = (rowData) => {
    return (
      <Text style={{ padding: 10, fontSize: 18 }}>{rowData.city_name}</Text>
    )
  }

  const renderButtonCategory = (rowData) => {
    const { category_id, default_category_translations } = rowData;
    return `${default_category_translations[0].default_category_name}`
  }

  const renderDropDownListCategory = (rowData) => {
    return (
      <Text style={{ padding: 10, fontSize: 18 }}>{rowData.default_category_translations[0].default_category_name}</Text>
    )
  }

  const renderButtonSubCategory = (rowData) => {
    const { default_subcategory_translations } = rowData;
    return `${default_subcategory_translations[0].default_subcategory_name}`
  }


  const renderDropDownListSubCategory = (rowData) => {
    return (
      <Text style={{ padding: 10, fontSize: 18 }}>{rowData.default_subcategory_translations[0].default_subcategory_name}</Text>
    )
  }

  const MyOrganizations = ({ item, index, organization_setup, organization_name, organization_thumbnail, organization_id, organization_logo }) => {
    return (
      <View style={{ flexDirection: 'row' }}>
        <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: 10, paddingHorizontal: 10, backgroundColor: 'white', borderRadius: 10, marginBottom: 10, borderWidth: 1, borderColor: "#e0e0e0", flexGrow: 1 }} onPress={() => {
          if (organization_setup) {
            navigation.navigate("OrganizationPage", {
              latitude: parseFloat(latitudeState),
              longitude: parseFloat(longitudeState),
              organizationId: organization_id,
              distance: distance
            })
            setModalOrganizations(false)
          } else {
            navigation.navigate("SetupOrganizationPage", {
              latitude: parseFloat(latitudeState),
              longitude: parseFloat(longitudeState),
              organizationId: organization_id,
              distance: distance
            })
            setModalOrganizations(false)
          }

        }}>
          <Image source={{ uri: organization_thumbnail }} style={{ width: 50, height: 50, borderRadius: 50, }}></Image>
          <View style={{ flexDirection: 'column', marginLeft: 10 }}>
            <Text style={{ fontSize: 18, fontFamily: 'Inter-Medium' }}>{organization_name}</Text>
            <Text style={{ fontSize: 14, fontFamily: 'Inter-Regular' }}>{organization_setup}</Text>
          </View>
        </TouchableOpacity>
        <View style={{ flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
          <TouchableOpacity style={{ paddingHorizontal: 3 }} onPress={() => {
            //here
            Alert.alert(
              i18n.t('home.editOrganizationPage.alert_title_delete'),
              i18n.t('home.editOrganizationPage.alert_body_delete'),
              [
                {
                  text: i18n.t('home.editOrganizationPage.alert_cancel'),
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                {
                  text: i18n.t('home.editOrganizationPage.alert_ok'), onPress: () => {
                    deleteOrganizationById({
                      variables: {
                        organizationId: organization_id,
                      }
                    }).then(async (result) => {
                      Alert.alert(i18n.t('home.editOrganizationPage.alert_title_delete_success'), i18n.t('home.editOrganizationPage.alert_body_delete_success'));
                      if (organization_logo) {
                        if (organization_thumbnail) {
                          // console.log('organization Image:', organizationLogo);
                          // console.log("thumbnail", organizationThumbnail);
                          const storageRef = ref(storage, organization_logo);

                          try {
                            await deleteObject(storageRef).then(() => {
                              console.log("deleted");
                            })
                          } catch (error) {
                            console.log("ERROR NO ID", error);
                          }

                          const storageRef1 = ref(storage, organization_thumbnail);

                          try {
                            await deleteObject(storageRef1).then(() => {
                              console.log("deleted");
                            })
                          } catch (error) {
                            console.log("ERROR NO ID1", error);
                          }
                        }
                      }
                    }).catch((error) => { console.log(error) })
                  }
                }
              ]
            );
          }}>
            <Feather name='trash' size={24} color='black' style={{ marginBottom: 10 }} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }


  //renderItemOrganizations

  const renderItemOrganizations = ({ item, index }) => {
    return (
      <MyOrganizations item={item} index={index}
        organization_setup={item.organization.organization_setup}
        organization_name={item.organization.organization_name}
        organization_thumbnail={item.organization.organization_thumbnail}
        organization_id={item.organization_id}
        organization_logo={item.organization.organization_logo}
      />
    )
  }



  return (
    <SafeAreaView style={{ flex: 1 }}>

      {(Platform.OS === 'web') ? (
        <>
          <Helmet>
            <title>{i18n.t('home.title')}</title>
            
          </Helmet>
        </>
      ) : null}

      <RNModal visible={modalVisibleMap} transparent={true} animationType='slide' onRequestClose={() => setModalVisibleMap(false)}
        onDismiss={() => setModalVisibleMap(false)}
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <View style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: 6, margin: 10, padding: 10 }}>
            <Text style={{ fontSize: 18, fontFamily: 'Inter-Black', color: 'black' }}>{i18n.t('home.change_location')}</Text>
            <GooglePlacesAutocomplete
              placeholder={i18n.t('home.change_location')}
              styles={{
                container: {
                  flex: 1,
                  width: 300,
                  justifyContent: 'center',
                  top: 40,
                  zIndex: 100,
                  position: 'absolute',
                  elevation: 3,
                  paddingHorizontal: 15
                },
                listView: {
                  position: 'absolute',
                  width: 300,
                  zIndex: 100,
                  top: 50,
                  paddingHorizontal: 15,
                }
              }}
              query={{
                key: "AIzaSyDaiYrHKXczdwO5QRLLTjKBvY-v3o7hqGM",
                language: "en",
              }}
              requestUrl={{
                useOnPlatform: "web", // or "all"
                url: "https://corsproxy.io/?https://maps.googleapis.com/maps/api"
              }}
              fetchDetails={true}
              onPress={(data, details = null) => {
                // 'details' is provided when fetchDetails = true
                console.log(data, details);
                ref.current?.setAddressText(data.description);
                navigation.setParams({
                  latitude: parseFloat(details.geometry.location.lat),
                  longitude: parseFloat(details.geometry.location.lng),
                })
                setLatitudeState(details.geometry.location.lat)
                setLongitudeState(details.geometry.location.lng)
                setRegionLatitude(details.geometry.location.lat)
                setRegionLongitude(details.geometry.location.lng)
                refetch();
                refetchNearStores();
              }}
            >

            </GooglePlacesAutocomplete>
            {(Platform.OS === 'web') ? (
              <MapView style={{ height: 450, width: 450, borderRadius: 10 }}
                region={{
                  latitude: regionLatitude ? regionLatitude : 1,
                  longitude: regionLongitude ? regionLongitude : 1,
                  latitudeDelta: latitudeDelta ? latitudeDelta : 1,
                  longitudeDelta: longitudeDelta ? longitudeDelta : 1,
                }}
                onRegionChangeComplete={(region) => {
                  // console.log(region);
                  setRegionLatitude(region.latitude)
                  setRegionLongitude(region.longitude)
                }}
                onPress={async (e) => {
                  let data = await e;
                  // console.log(data);

                  let lat = await data.latLng.lat;

                  // console.log(lat());

                  let lng = await data.latLng.lng;
                  // console.log(lng());
                  setLatitudeState(lat)
                  setLongitudeState(lng)

                }}
                onPoiClick={(e) => {
                  let data = decycle(e);
                  // console.log(data.latLng.lat);
                }}
              >
                <MapView.Marker
                  key={1}
                  coordinate={{
                    latitude: latitudeState ? latitudeState : 1,
                    longitude: longitudeState ? longitudeState : 1,
                  }}
                  title={i18n.t('home.my_location')}
                  description={i18n.t('home.my_location')}
                />

              </MapView>

            ) : (Platform.OS === 'android' || Platform.OS === 'ios') ? (
              <MapView
                provider={PROVIDER_GOOGLE}
                style={{ height: 450, width: screenWidth, zIndex: 1 }}
                region={{
                  latitude: regionLatitude ? regionLatitude : 1,
                  longitude: regionLongitude ? regionLongitude : 1,
                  latitudeDelta: latitudeDelta ? latitudeDelta : 1,
                  longitudeDelta: longitudeDelta ? longitudeDelta : 1,

                }}
                initialRegion={{
                  latitude: regionLatitude ? regionLatitude : 1,
                  longitude: regionLongitude ? regionLongitude : 1,
                  latitudeDelta: latitudeDelta ? latitudeDelta : 1,
                  longitudeDelta: longitudeDelta ? longitudeDelta : 1,
                }}
                onRegionChangeComplete={(region) => {
                  // console.log(region);
                  setRegionLatitude(region.latitude)
                  setRegionLongitude(region.longitude)
                  setLatitudeDelta(region.latitudeDelta)
                  setLongitudeDelta(region.longitudeDelta)
                }}
                onPress={(e) => {
                  let data = e;
                  // console.log(e.nativeEvent.coordinate);

                  let lat = data.nativeEvent.coordinate.latitude;
                  let lng = data.nativeEvent.coordinate.longitude;
                  setLatitudeState(lat)
                  setLongitudeState(lng)
                }}
              >
                <Marker
                  key={1}
                  coordinate={{
                    latitude: latitudeState ? latitudeState : 1,
                    longitude: longitudeState ? longitudeState : 1,
                  }}
                  title={i18n.t('home.my_location')}
                  description={i18n.t('home.my_location')}

                ></Marker>
                <Circle
                  center={{
                    latitude: latitudeState ? latitudeState : 1,
                    longitude: longitudeState ? longitudeState : 1,
                  }}
                  radius={distance}
                  fillColor={'rgba(118, 131, 197, 0.52)'}
                >

                </Circle>
              </MapView>

            ) : (null)}
            <View style={{ backgroundColor: '#e0e0e0', borderTopLeftRadius: 6, borderTopRightRadius: 6 }}>
              <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#000000', paddingHorizontal: 6 }}>{i18n.t('home.range_label')}</Text>
              <Text style={{ paddingHorizontal: 6 }}>{i18n.t('home.range_label_description')}</Text>
              <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                <Slider
                  style={{ width: 200, height: 75, paddingBottom: 10 }}
                  minimumValue={2000}
                  maximumValue={80000}
                  value={distance}
                  minimumTrackTintColor="#FFA726"
                  maximumTrackTintColor="#000000"
                  onSlidingComplete={value => setDistance(value)}
                />

                <View style={{ flexDirection: 'row' }}>
                  <Text>{(distance / 1000).toFixed(0)}</Text>
                  <Text style={{ fontWeight: 'bold' }}> Km</Text>
                </View>
              </View>
            </View>
            <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setModalVisibleMap(false)}>
              <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
                {i18n.t('home.close_button')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </RNModal>
      {/* Modal Login */}

      <Modal isVisible={modalLoginVisible}
        // hasBackdrop={true}
        backdropColor={'rgba(0,0,0,0.5)'}
        // backdropColor='black'
        backdropOpacity={75}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        onBackdropPress={() => {
          setModalLoginVisible(false)
        }}
        onRequestClose={() => setModalLoginVisible(false)}
      >
        {/* <View style={{flex:1,justifyContent:'center', alignItems:'center'}}> */}
        <View
          style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: 6, margin: 5, padding: 5, width: screenWidth - 20, maxWidth: 700 }}
        >
          <Text style={{ fontSize: 18, fontFamily: 'Inter-Black', color: 'black' }}>{i18n.t('home.login')}</Text>
          {/* <View style={{ flex: 1 }}> */}
          <Text style={{ paddingHorizontal: 15, fontSize: 16, fontFamily: 'Inter-Black' }}>{i18n.t('login.email')}</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <TextInput style={{
              paddingHorizontal: 12, backgroundColor: '#e0e0e0', paddingVertical: 5, flexGrow: 1,
              marginHorizontal: 15, fontSize: 18, borderRadius: 10, fontFamily: 'Inter-Regular'
            }}
              onChangeText={(val) => setEmail(val.trim())}
              placeholder='carlos@gmail.com'
              autoCapitalize='none'
              textContentType='emailAddress'
              keyboardType='email-address'
              autoCorrect={false}
              autoComplete='email'
            />
          </View>
          <Text style={{ paddingHorizontal: 15, fontSize: 16, fontFamily: 'Inter-Black' }}>{i18n.t('login.password')}</Text>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <TextInput style={{
              paddingHorizontal: 12, backgroundColor: '#e0e0e0', paddingVertical: 5, flexGrow: 1,
              marginHorizontal: 15, fontSize: 18, borderRadius: 10, fontFamily: 'Inter-Regular'
            }}
              placeholder={i18n.t('login.password')}
              onChangeText={(val) => setPassword(val)}
              secureTextEntry={passwordVisibility}
            />
            <Pressable onPress={handlePasswordVisibility} style={{ position: 'absolute', alignSelf: 'center', right: 25 }}>
              <Feather name={rightIcon} size={22} ></Feather>
            </Pressable>
          </View>

          <View style={{ marginTop: 5, flexDirection: 'row', justifyContent: 'center' }}>
            <Text style={{ fontSize: 16 }}>{i18n.t('login.not_registered')}</Text>
            <TouchableOpacity
              style={{ marginLeft: 5 }}
              onPress={() => navigation.navigate('Signup')}
            >
              <Text style={{ fontSize: 16, fontFamily: 'Inter-Black', color: '#FF6D00' }}>
                {i18n.t('login.btn_signup')}
              </Text>
            </TouchableOpacity>
          </View>
          <View>
            <View style={{ marginTop: 5, flexDirection: 'row', justifyContent: 'center' }}>
              <Text style={{ fontSize: 16 }}>{i18n.t('login.forgot_password')}</Text>
              <TouchableOpacity
                style={{ marginLeft: 5 }}
                onPress={() => navigation.navigate('Recover')}
              >
                <Text style={{ fontSize: 16, fontFamily: 'Inter-Black', color: '#FF6D00' }}>
                  {i18n.t('login.forgot_password_btn')}
                </Text>
              </TouchableOpacity>

            </View>
            <View style={{ paddingTop: 5, paddingBottom: 15, paddingHorizontal: 15, maxWidth: 250 }}>
            </View>
          </View>
          <TouchableOpacity style={{ borderWidth: 2, borderColor: '#FF6D00', backgroundColor: "#FF6D00", paddingVertical: 10, borderRadius: 6, paddingHorizontal: 30, marginBottom: 5, flexDirection: 'row', justifyContent: 'center', marginTop: 3 }}
            onPress={handleLogin}>
            <Text style={{ alignSelf: 'center', color: '#FFFFFF', fontFamily: 'Inter-Bold', fontSize: 18 }}>{i18n.t('login.btn_login')}</Text>
            <Feather name='mail' size={22} color='#FFFFFF' style={{ alignSelf: 'center', marginLeft: 10 }}></Feather>
          </TouchableOpacity>
          <TouchableOpacity style={{ borderWidth: 2, borderColor: '#FF6D00', paddingVertical: 10, borderRadius: 6, paddingHorizontal: 30, marginBottom: 5, flexDirection: 'row', justifyContent: 'center', marginTop: 3 }}
            onPress={handleSignup}>
            <Text style={{ alignSelf: 'center', color: '#FF6D00', fontFamily: 'Inter-Bold', fontSize: 18 }}>{i18n.t('login.btn_signup_email')}</Text>
            <Feather name='mail' size={22} color='#FF6D00' style={{ alignSelf: 'center', marginLeft: 10 }}></Feather>
          </TouchableOpacity>
          {(Platform.OS === 'android' || Platform.OS === 'web' || Platform.OS === 'ios') ? (
            <TouchableOpacity style={{ backgroundColor: '#3D5AFE', paddingVertical: 10, borderRadius: 6, paddingHorizontal: 30, flexDirection: 'row', justifyContent: 'center' }}
              onPress={handleFacebookLogin}>
              <Text style={{ alignSelf: 'center', color: 'white', fontFamily: 'Inter-Bold', fontSize: 18 }}>{i18n.t('login.btn_facebook')}</Text>
              <Ionicons name='logo-facebook' size={22} color='white' style={{ alignSelf: 'center', marginLeft: 10 }}></Ionicons>
            </TouchableOpacity>
          ) : null}
          {(Platform.OS === 'ios') && (
            <View style={{ flex: 1, marginTop: 3 }}>
              <AppleAuthentication.AppleAuthenticationButton
                buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                cornerRadius={5}
                style={{
                  flexGrow: 1,
                  height: 44,
                }}
                onPress={async () => {
                  try {
                    const credential = await AppleAuthentication.signInAsync({
                      requestedScopes: [
                        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                        AppleAuthentication.AppleAuthenticationScope.EMAIL,
                      ],
                    });
                    //sign in with credential
                    const { identityToken, email, state } = credential;
                    const provider = new OAuthProvider('apple.com');
                    const credentialFirebase = provider.credential({
                      idToken: identityToken,
                      rawNonce: state,
                    });
                    await signInWithCredential(auth, credentialFirebase);

                  } catch (e) {
                    console.log(e);
                    if (e.code === 'ERR_REQUEST_CANCELED') {
                      // handle that the user canceled the sign-in flow

                    } else {
                      // handle other errors
                    }
                  }
                }}
              />
            </View>
          )}
        </View>
      </Modal>



      {/* <Modal Organizations */}

      <Modal isVisible={modalOrganizations}
        // hasBackdrop={true}
        backdropColor={'rgba(0,0,0,0.5)'}
        // backdropColor='black'
        backdropOpacity={75}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
        onBackdropPress={() => {
          setModalOrganizations(false)
        }}
        onRequestClose={() => setModalOrganizations(false)}
      >
        {/* <View style={{flex:1,justifyContent:'center', alignItems:'center'}}> */}

        <View
          style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: 6, margin: 5, padding: 5, width: screenWidth - 20 }}
        >
          <Text style={{ fontSize: 18, fontFamily: 'Inter-Black', color: 'black' }}>{i18n.t('home.your_organizations')}</Text>
          <TouchableOpacity
            style={{ backgroundColor: '#FF6D00', borderRadius: 6, padding: 10, margin: 5 }}
            onPress={() => {
              insertOrganizationOne({
                variables: {
                  uid: uid,
                }
              }).then((res) => {
                // console.log(res)

                const organization_id = res.data.insert_organization_one.organization_id

                navigation.navigate("SetupOrganizationPage", {
                  latitude: parseFloat(latitudeState),
                  longitude: parseFloat(longitudeState),
                  organizationId: organization_id,
                  distance: distance
                })

                setModalOrganizations(false);
              })
            }}
          >
            <Text style={{ fontSize: 16, fontWeight: 'bold', color: '#FFF', paddingHorizontal: 6 }}>{i18n.t('home.affiliate_us')}</Text>
          </TouchableOpacity>
          <FlatList
            data={organizations}
            renderItem={renderItemOrganizations}
            keyExtractor={item => item.organization_id.toString()}
          ></FlatList>
          <TouchableOpacity style={{ marginTop: 20 }} onPress={() => setModalOrganizations(false)}>
            <Text style={{ fontSize: 20, fontWeight: 'bold' }}>
              {i18n.t('home.close_button')}
            </Text>
          </TouchableOpacity>
        </View>
        {/* </View> */}
      </Modal>

      <Modal isVisible={modalVisibleFilter}
        backdropColor={'rgba(0,0,0,0.5)'}
        // backdropColor='black'
        backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={() => setModalVisibleFilter(false)}
        onBackdropPress={() => setModalVisibleFilter(false)}
        style={{ elevation: 5, justifyContent: 'flex-end', margin: 0, height: 450, flex: 0, bottom: 0, position: 'absolute', width: '100%', borderRadius: 6 }}
      >
        <View style={{ backgroundColor: 'white', padding: 22, height: 450, borderRadius: 4, borderColor: 'rgba(0, 0, 0, 0.1)' }}>
          <TouchableOpacity onPress={() => setModalVisibleFilter(false)}>
            <Text style={{ fontSize: 24, fontWeight: 'bold' }}>
              {i18n.t('home.location_settings')}
            </Text>
          </TouchableOpacity>
          <Text style={{ fontSize: 16 }}>
            {i18n.t('home.search_options')}
          </Text>
          <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
            {i18n.t('home.country')}
          </Text>
          {(Platform.OS === 'android' || Platform.OS === 'ios') && (
            <View>
              <View style={{ flexDirection: 'row' }}>
                <ModalDropdown
                  dropdownStyle={{ width: 200, height: 200 }}
                  dropdownTextStyle={{ fontSize: 16 }}
                  // defaultIndex={indexCountry}
                  style={{ borderRadius: 6, padding: 8, backgroundColor: '#e0e0e0', flexGrow: 1 }} textStyle={{ fontSize: 16 }} options={country}
                  renderButtonText={(rowData) => renderButtonCountry(rowData)}
                  renderRow={(rowData) => renderDropDownListCountry(rowData)}
                  onSelect={(index, value) => {
                    setSelectedCountryId(value.country_id);
                    setSelectedCountryName(value.country_name);
                    setLocationFilter({
                      ...locationFilter,
                      country_id: {
                        "_eq": value.country_id
                      }
                    })
                    setUserLocationsFilter(
                      {
                        "_or": [
                          {
                            "user": {
                              "user_locations": {
                                "user_id": {
                                  "_eq": uid
                                }
                              }
                            }
                          },
                          {
                            "country": {
                              "country_id": {
                                "_eq": value.country_id
                              }
                            }
                          }
                        ]
                      }
                    )
                    setOrganizationLocationFilter({
                      "country": {
                        "country_id": {
                          "_eq": value.country_id
                        }
                      }
                    })
                    setOrganizationCountryId({
                      ...organizationCountryId,
                      "_eq": value.country_id
                    })
                    setOrganizationProvinceId({

                    })
                    setOrganizationCityId({

                    })


                    // refetchNearStores();
                    // refetch();
                  }}
                >
                  <Text>
                    {selectedCountryName}
                  </Text>
                </ModalDropdown>
                <Feather name='chevron-down' style={{ position: 'absolute', alignSelf: 'center', right: 25 }} />
              </View>
              <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                {i18n.t('home.province')}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <ModalDropdown
                  dropdownStyle={{ width: 200, height: 200 }}
                  renderButtonText={(rowData) => renderButtonProvince(rowData)}
                  renderRow={(rowData) => renderDropDownListProvince(rowData)}
                  onSelect={(index, value) => {
                    setSelectedProvinceId(value.province_id);
                    setSelectedProvinceName(value.province_name);
                    setLocationFilter({
                      ...locationFilter,
                      province_id: {
                        "_eq": value.province_id
                      }
                    })
                    setUserLocationsFilter(
                      {
                        "_or": [
                          {
                            "user": {
                              "user_locations": {
                                "user_id": {
                                  "_eq": uid
                                }
                              }
                            }
                          },
                          {
                            "province": {
                              "province_id": {
                                "_eq": value.province_id
                              }
                            }
                          }
                        ]
                      }
                    )
                    setOrganizationLocationFilter({
                      "province": {
                        "province_id": {
                          "_eq": value.province_id
                        }
                      }
                    })

                    setOrganizationProvinceId({
                      ...organizationProvinceId,
                      "_eq": value.province_id
                    })
                    // console.log(locationFilter);
                    refetchProvinces();
                  }}
                  dropdownTextStyle={{ fontSize: 16 }}
                  style={{ borderRadius: 6, padding: 8, backgroundColor: '#e0e0e0', flexGrow: 1 }} textStyle={{ fontSize: 16 }} options={province}>
                  <Text>
                    {selectedProvinceName}
                  </Text>
                </ModalDropdown>
                <Feather name='chevron-down' style={{ position: 'absolute', alignSelf: 'center', right: 25 }} />
              </View>
              <Text style={{ fontSize: 16, fontWeight: 'bold' }}>
                {i18n.t('home.city')}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <ModalDropdown
                  dropdownStyle={{ width: 200, height: 200 }}
                  dropdownTextStyle={{ fontSize: 16 }}
                  // defaultIndex={}
                  // defaultValue={'option 1'}
                  renderButtonText={(rowData) => renderButtonCity(rowData)}
                  renderRow={(rowData) => renderDropDownListCity(rowData)}
                  onSelect={(index, value) => {
                    setSelectedCityId(value.city_id);
                    setSelectedCityName(value.city_name);
                    setLocationFilter({
                      ...locationFilter,
                      city_id: {
                        "_eq": value.city_id
                      }
                    })
                    setUserLocationsFilter(
                      {
                        "_or": [
                          {
                            "user": {
                              "user_locations": {
                                "user_id": {
                                  "_eq": uid
                                }
                              }
                            }
                          },
                          {
                            "city": {
                              "city_id": {
                                "_eq": value.city_id
                              }
                            }
                          }
                        ]
                      }
                    )
                    setOrganizationLocationFilter({
                      "city": {
                        "city_id": {
                          "_eq": value.city_id
                        }
                      }
                    })
                    setOrganizationCityId({
                      ...organizationCityId,
                      "_eq": value.city_id
                    })
                    // console.log(locationFilter);
                    refetchCities();
                  }}

                  style={{ borderRadius: 6, padding: 8, backgroundColor: '#e0e0e0', flexGrow: 1 }} textStyle={{ fontSize: 16 }} options={city}>
                  <Text>
                    {selectedCityName}
                  </Text>
                </ModalDropdown>
                <Feather name='chevron-down' style={{ position: 'absolute', alignSelf: 'center', right: 25 }} />
              </View>
            </View>
          )}
        </View>
      </Modal>

      {/**
          Modal Category Filter
           */}

      <Modal isVisible={modalCategoryFilter}
        backdropColor={'rgba(0,0,0,0.5)'}
        backdropOpacity={75}
        animationIn={'slideInUp'}
        animationOut={'slideOutDown'}
        animationInTiming={500}
        animationOutTiming={500}
        backdropTransitionInTiming={500}
        backdropTransitionOutTiming={500}
        onBackButtonPress={() => setModalCategoryFilter(false)}
        onBackdropPress={() => setModalCategoryFilter(false)}
        style={{ elevation: 5, justifyContent: 'flex-end', margin: 0, height: 450, flex: 0, bottom: 0, position: 'absolute', width: '100%', borderRadius: 6 }}>

        <View style={{ backgroundColor: 'white', padding: 22, height: 450, borderRadius: 4, borderColor: 'rgba(0, 0, 0, 0.1)' }}>
          <TouchableOpacity onPress={() => setModalCategoryFilter(false)}>
            <Text style={{ fontSize: 24, fontFamily: 'Inter-Black' }}>
              {i18n.t('home.category_settings')}
            </Text>
          </TouchableOpacity>
          <Text style={{ fontSize: 16, fontFamily: 'Inter-Regular' }}>
            {i18n.t('home.search_options')}
          </Text>
          <Text style={{ fontSize: 16, fontFamily: 'Inter-Black' }}>
            {i18n.t('home.category')}
          </Text>
          {/**here */}
          {(Platform.OS === 'android' || Platform.OS === 'ios') && (
            <View>
              <View style={{ flexDirection: 'row' }}>
                <ModalDropdown
                  dropdownStyle={{ width: 200, height: 200 }}
                  dropdownTextStyle={{ fontSize: 16 }}
                  // defaultValue={'option 1'}
                  renderButtonText={(rowData) => renderButtonCategory(rowData)}
                  renderRow={(rowData) => renderDropDownListCategory(rowData)}
                  onSelect={(index, value) => {
                    // console.log("LOL: ", value);
                    setSelectedCategory(value.category_id);
                    setSelectedCategoryName(value.default_category_translations[0].default_category_name);
                    setCategoryFilter({
                      ...categoryFilter,
                      default_subcategory: {
                        default_category_id: {
                          "_eq": value.category_id
                        }
                      }
                    })
                    // console.log(categoryFilter);
                    // console.log(locationFilter);
                    refetchCategories();
                  }}

                  style={{ borderRadius: 6, padding: 8, backgroundColor: '#e0e0e0', flexGrow: 1 }} textStyle={{ fontSize: 16 }} options={category}>
                  <Text>
                    {selectedCategoryName}
                  </Text>
                </ModalDropdown>
                <Feather name='chevron-down' style={{ position: 'absolute', alignSelf: 'center', right: 25 }} />
              </View>
              <Text style={{ fontSize: 16, fontFamily: 'Inter-Black' }}>
                {i18n.t('home.subcategory')}
              </Text>
              <View style={{ flexDirection: 'row' }}>
                <ModalDropdown
                  dropdownStyle={{ width: 200, height: 200 }}
                  dropdownTextStyle={{ fontSize: 16 }}
                  // defaultValue={'option 1'}
                  renderButtonText={(rowData) => renderButtonSubCategory(rowData)}
                  renderRow={(rowData) => renderDropDownListSubCategory(rowData)}
                  onSelect={(index, value) => {
                    setSelectedSubCategory(value.subcategory_id);
                    setSelectedSubCategoryName(value.default_subcategory_translations[0].default_subcategory_name);
                    setCategoryFilter({
                      ...categoryFilter,
                      default_subcategory_id: {
                        "_eq": value.subcategory_id
                      }
                    })
                  }}

                  style={{ borderRadius: 6, padding: 8, backgroundColor: '#e0e0e0', flexGrow: 1 }} textStyle={{ fontSize: 16 }} options={subCategory}>
                  <Text>
                    {selectedSubCategoryName}
                  </Text>
                </ModalDropdown>
                <Feather name='chevron-down' style={{ position: 'absolute', alignSelf: 'center', right: 25 }} />
              </View>
              {/**Here */}
            </View>
          )}
        </View>
      </Modal>
      <FlatList
        listKey='products'
        ItemSeparatorComponent={Platform.OS === 'android' || Platform.OS === 'ios' ? renderSeparator : () => null}
        numColumns={numColumns}
        key={numColumns}
        contentContainerStyle={{ paddingBottom: 250 }}
        ListHeaderComponent={header()}
        initialNumToRender={6}
        data={nearProducts} renderItem={renderItem}
        ListEmptyComponent={loadingNearProducts ? <ActivityIndicator size="large" color="#FF6D00" /> : <Text style={{ textAlign: 'center', fontSize: 16, fontFamily: 'Inter-Regular' }}>{i18n.t('home.no_products')}</Text>}
        keyExtractor={(item, index) => item.product.product_id.toString()} />
      {/* sticky footer*/}
    </SafeAreaView>
  );
}
export default Home;

