import React, { useState, useEffect } from 'react';
import { View, Text, FlatList, Modal, TouchableWithoutFeedback, TouchableOpacity, ImageBackground, ActivityIndicator, Image } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery, useSubscription } from '@apollo/client';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_INBOX_MESSAGES } from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import { set } from 'react-native-reanimated';
import i18n from '../i18n/i18n';
import { useIsFocused } from '@react-navigation/native';

const auth = getAuth(Firebase);

const Inbox = ({navigation, route}) => {

    // const isFocused = useIsFocused();

    const uid = getAuth().currentUser.uid;

    const {loading:inboxLoading, data:inboxData, error:inboxError} = useSubscription(GET_INBOX_MESSAGES, {variables:{recipientId:uid}});

   const [messages, setMessages] = useState([]);


    useEffect(() => {
    // console.log('inboxData', inboxError)


        if(inboxData){
            const {chat_aggregate} = inboxData;
            const {nodes} = chat_aggregate;

            setMessages(nodes);

            // console.log('messages', inboxData)
        }


    },);


    const Message = ({chat_id, user_expo_token, sender_name, sender_lastname, sender_id, message, recipient_name, recipient_lastname, recipient_status}) => message?(
        <TouchableOpacity style={{borderWidth:1, borderRadius:6, borderColor:'#bda9a8', marginVertical:2, paddingHorizontal:10, flexDirection:'row' }}
        onPress={()=>{
            navigation.navigate('Message',{
                chatId:chat_id,
                expoToken:user_expo_token,
            })
        }}>
        <View style={{}}>
        <Text style={{fontSize:18, fontFamily:'Inter-Medium'}}>
                { recipient_name ? recipient_name.user.user_name: null} { recipient_lastname ? recipient_lastname.user.user_lastname: null}
                {console.log(recipient_status)} 
                {/* ({recipient_name}? {recipient_name}) */}
             {/* {console.log('sender_name', recipient_name ? recipient_name.user.user_name: null)} */}
             </Text>
        <Image source={recipient_name ? {uri: recipient_name.user.user_thumbnail} : require('../assets/gray-image-placeholder.png')} style={{width:50, height:50, borderRadius:100, marginVertical:5}}/>
        <View style={{flexDirection:'row', alignContent:'center', alignItems:'center'}}>
            <Text style={{fontSize:12}}>
            { recipient_status ? recipient_status.user.user_active ? i18n.t('inbox.recipient_online'): i18n.t('inbox.recipient_offline') : "Empty"}
            </Text>
            {(recipient_status)? (
                recipient_status.user.user_active ?           <View style={{width:10, height:10, backgroundColor:"#4CAF50", borderRadius:100, marginHorizontal:2}}>

                </View> :             <View style={{width:10, backgroundColor:"#BDBDBD", borderRadius:100, height:10, marginHorizontal:2}}>

</View>
            ):null}
            {/* {(recipient_status ? recipient_status.user.user_active)?(
                        <View style={{width:10, backgroundColor:"#4CAF50"}}>

                        </View>
            ):null} */}
            </View> 
        </View>

            {(sender_id === uid) ? (
                <View style={{flexDirection:'row', alignItems:'center'}}>
                    <Text style={{fontWeight:'bold'}}>{i18n.t('inbox.me')} </Text>
                    <Text style={{fontSize:18}}>{message} {console.log(message)}</Text>
                </View>
            ):(
            <View style={{flexDirection:'row', alignItems:'center'}}>
                <Text style={{fontSize:18}}>{message} {console.log(message)}</Text>
            </View>
            )}

        </TouchableOpacity>
    ):null;

    const renderMessages = ({item}) => {

        return(
            <Message
                chat_id={item.chat_id}
                user_expo_token={item.messages[0] ?item.messages[0].userBySenderId.user_expo_token : null}
                sender_name={item.messages[0] ? item.messages[0].userBySenderId.user_name : null}
                sender_lastname={item.messages[0] ? item.messages[0].userBySenderId.user_lastname : null}
                sender_id={item.messages[0] ? item.messages[0].userBySenderId.uid : null}
                message={item.messages[0]?item.messages[0].message:null}
                recipient_name={item.messages[0]?item.messages[0].chat.user_chats[0] : null}
                recipient_lastname={item.messages[0] ?item.messages[0].chat.user_chats[0]: null}
                recipient_status={item.messages[0] ? item.messages[0].chat.user_chats[0]: null}
                // recipient_name={item.user_chats[0]}
                // recipient_lastname={item.user_chats[0].user.user_lastname}
            />
        )
    }

    return(
        <SafeAreaView style={{flex:1, paddingTop:-45, paddingBottom:-60}}>

        <View style={{flex:1, paddingHorizontal:9}}>
        <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
                {i18n.t('inbox.title')}
            </Text>
        {(messages) ? (
            <FlatList data={messages}
            style={{}}
            renderItem={renderMessages}
            keyExtractor={item => item.chat_id}></FlatList>
        ):null}

        </View>
    </SafeAreaView>
    )

}

export default Inbox;