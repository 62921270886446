import React, { useState, useEffect } from 'react';
import { View, Text, ImageBackground, Button, Alert, TouchableOpacity, Dimensions, Platform} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { BlurView } from 'expo-blur';
import Firebase from '../../lib/firebase';
import * as ImagePicker from 'expo-image-picker';
import { useMutation, useQuery } from '@apollo/client';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'
import { DELETE_CUSTOM_CATEGORY, GET_CUSTOM_CATEGORY_TRANSLATION_NAME, UPDATE_CUSTOM_CATEGORY_PHOTO } from '../../lib/queries';
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject, uploadBytesResumable} from 'firebase/storage';
import i18n from '../../i18n/i18n';
import { Ionicons } from '@expo/vector-icons';

const storage = getStorage(Firebase);



const EditCustomCategories = ({navigation, route}) => {

    /**Pre routed variables */
    const {imageLink} = route.params
    const {custom_cat_photo} = imageLink
    /**custom_category_id */
    const {customCategoryIdentifier} = route.params
    const {custom_category_id} = customCategoryIdentifier
    /**custom_category_name */
    const {customCategory_Name} = route.params
    const {custom_category_name} = customCategory_Name
    /**language_code*/
    const {customLanguageCode} = route.params
    const {custom_category_language_code} = customLanguageCode

    /**custom_category_translations_id */

    const {customCategoryTranslationIdentifier} = route.params
    const {custom_category_translations_id} = customCategoryTranslationIdentifier

    const [image, setImage] = useState(custom_cat_photo ? custom_cat_photo:null)
    const [customCategoryId, setCustomCategoryId] = useState(custom_category_id ? custom_category_id:null)
    const [customCategoryName, setCustomCategoryName] = useState(custom_category_name? custom_category_name:null)
    const [customCategoryLanguageCode, setCustomCategoryLanguageCode] = useState(custom_category_language_code ? custom_category_language_code:null)
    const [customCategoryTranslationId, setCustomCategoryTranslationsId] = useState(custom_category_translations_id? custom_category_translations_id: null)
    const [customTranslationName, setCustomTranslationName] = useState('')
    /**Queries */
    const {loading: fetchLoading, data: translationName, refetch} = useQuery(GET_CUSTOM_CATEGORY_TRANSLATION_NAME, {variables:{customCategoryTranslationId}})
    const [updateCustomCategoryPhoto, {loading: mutationFetch, data: response}] = useMutation(UPDATE_CUSTOM_CATEGORY_PHOTO);
    const [deleteCustomCategory, {loading: deleteMutationFetch, data: deleteResponse}] = useMutation(DELETE_CUSTOM_CATEGORY);

    useEffect(()=>{
      (async () => {
        if (Platform.OS !== 'web') {
          const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
          if (status !== 'granted') {
            alert('Sorry, we need camera roll permissions to make this work!');
          }
        }
      })();
      let newTranslationName
      // console.log(translationName)
      if(translationName){
        const {custom_category_translations_by_pk} = translationName
        const {custom_category_name} = custom_category_translations_by_pk
        newTranslationName = custom_category_name
      }
      setCustomTranslationName(newTranslationName)
      // console.log(image);
      // console.log(customLanguageCode.custom_category_language_code)
      // console.log("Heey",customCategoryTranslationId)
      // console.log("Heey",customTranslationName)
      const unsubscribe = navigation.addListener('focus', () => {
        // console.log("Focus")
        refetch()
      });
    })
    async function deleteOldImage(imageurl){
      const deleted = false
      // var imageref = Firebase.storage().refFromURL(imageurl);


      //get old image url

      const imageref = ref(storage, imageurl)

      deleteObject(imageref).then(async function() {
        //  console.log("Image deleted successfully");
      }).catch(function(error) {
        // console.log(error);
      })
      

     
    }

    const pickImage = async () => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [6, 3],
        quality: Platform.OS === 'ios' ? 0 : 1,
      });

      console.log(result);
      
      if (!result.canceled) {
        deleteOldImage(image);
        uploadImage(result.assets[0].uri);
      }
    };

  /**Uploadimage function */
      
  async function uploadImage(uri) {
    // Why are we using XMLHttpRequest? See:
    // https://github.com/expo/expo/issues/2402#issuecomment-443726662
    // const blob = await new Promise((resolve, reject) => {
    //     const xhr = new XMLHttpRequest();
    //     xhr.onload = function () {
    //       resolve(xhr.response);
    //     };
    //     xhr.onerror = function (e) {
    //       console.log(e);
    //       reject(new TypeError("Network request failed"));
    //     };
    //     xhr.responseType = "blob";
    //     xhr.open("GET", uri, true);
    //     xhr.send(null);
    //   });
    
    //   // const ref = Firebase.storage().ref().child('chat-images/' + uuid.v4());
    //   const fileref = ref(storage,uuid.v4());
    //   const result = await uploadBytes(fileref, blob);
    //   // const snapshot = await ref.put(blob);
    
    //   // We're done with the blob, close and release it
    //   blob.close();

    // return await getDownloadURL(fileref)

    const fileref = ref(storage,'custom-categories/'+uuidv4());

    const img = await fetch(uri);
    const blob = await img.blob();

    // console.log('uploading image');

    const uploadTask = uploadBytesResumable(fileref, blob);

    uploadTask.on('state_changed', (snapshot) => {

    }, (error) => {
      // console.log(error);
    }
    , async () => {
      // console.log('upload complete');
      const url = await getDownloadURL(fileref)
      setImage(url);
          updateCustomCategoryPhoto({variables:{
          customCategoryId,
          customCategoryPhoto: url,
        }}).then(()=>{
          Alert.alert(i18n.t('products.categories.editCustomCategory.alert_success_image'), i18n.t('products.categories.editCustomCategory.alert_success_image_body'))
        }).catch(()=>{
          Alert.alert(i18n.t('products.categories.editCustomCategory.alert_error_image'), i18n.t('products.categories.editCustomCategory.alert_error_image_body'))
        })
      
    });

  }

    // async function editCustomCategory(){
    //     const customCategoryPhoto = await uploadImage(image)
    //     /**Update Image */
    //     updateCustomCategoryPhoto({variables:{
    //       customCategoryId,
    //       customCategoryPhoto
    //     }}).then(()=>{
    //       Alert.alert(i18n.t('products.categories.editCustomCategory.alert_success_image'), i18n.t('products.categories.editCustomCategory.alert_success_image_body'))
    //     }).catch(()=>{
    //       Alert.alert(i18n.t('products.categories.editCustomCategory.alert_error_image'), i18n.t('products.categories.editCustomCategory.alert_error_image_body'))
    //     })

    // }

    const screenWidth = Dimensions.get('window').width- 10;
    return(
    <SafeAreaView style={{flex:1}}>
        <View style={{paddingHorizontal:5}}>
          <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>{i18n.t('products.categories.editCustomCategory.title')}
            </Text>
          </View>
          <View style={{paddingVertical:5}}>
            <Text style={{fontSize:16}}>
              
            </Text>
          </View>
          <View>
          <Button title={i18n.t('products.categories.editCustomCategory.btn_camera_roll')} onPress={()=> pickImage()}/>
          <ImageBackground source={{uri: image}} imageStyle={{borderRadius:6}} resizeMode="cover" style={{width:screenWidth, height:200}}>

                <Text style={{position:'absolute', paddingHorizontal:10, zIndex:50,fontSize:32 ,fontWeight:'bold', color:'#FFFFFF', marginTop:75}}>{(customTranslationName)?(
        customTranslationName
      ): "Default"}</Text>
      <View style={{alignSelf:'flex-start', height:200, borderRadius:16, backgroundColor:'#000000', opacity:0.2, width:screenWidth, }}>

      </View>
    </ImageBackground>
          </View>
        </View>
        <View style={{paddingVertical:5, paddingHorizontal:5}}>
        <TouchableOpacity
            style={{backgroundColor:'#1e76e8', borderRadius:6, paddingVertical:10, marginTop:2}}
            onPress={()=>{
              navigation.navigate('detailedCustomCategories',{
                customCategoryNameEdit:{customTranslationName},
                customCategoryIdEdit:{customCategoryId},
                customCategoryLanguageCodeEdit:{customCategoryLanguageCode},
                customCategoryTranslationIdEdit:{customCategoryTranslationId}
              })
            }}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:16}}>
              {i18n.t('products.categories.editCustomCategory.btn_more')}
            </Text>
          </TouchableOpacity>
       {/* <TouchableOpacity
            style={{backgroundColor:'#000000', borderRadius:6, paddingVertical:10, marginTop:2}}
            onPress={()=>editCustomCategory()}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold', borderRadius:6, fontSize:16}}>
              {i18n.t('products.categories.editCustomCategory.btn_save')}
            </Text>
          </TouchableOpacity> */}
          <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:10, marginTop:2}}
            onPress={()=> navigation.goBack()}
          >
            <Text style={{fontWeight:'bold', textAlign:'center', fontSize:18, borderRadius:6}}>
              {i18n.t('products.categories.editCustomCategory.btn_cancel')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{backgroundColor:'#FF8A80', 
            borderRadius:6, paddingVertical:10, marginTop:2}}
            onPress={()=> {
              Alert.alert(
                i18n.t('products.categories.editCustomCategory.alert_delete_title'),
                i18n.t('products.categories.editCustomCategory.alert_delete_body'),
                [
                  {
                    text: i18n.t('products.categories.editCustomCategory.alert_delete_btn_cancel'),
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel'
                  },
                  { text: i18n.t('products.categories.editCustomCategory.alert_delete_btn_ok'), onPress: () => {
                    deleteCustomCategory({variables:{
                      customCategoryId: customCategoryId
                    }}).then((res)=>{
                      try{
                        const {data} = res
                        console.log("DATA: ", data);
                        const {delete_custom_categories_by_pk} = data
                        //delete photo 
                        deleteOldImage(delete_custom_categories_by_pk.custom_category_photo);
                        console.log("IMAGE TO DELETE: ", delete_custom_categories_by_pk.custom_category_photo );
                      }catch(err){
                        console.log(err)
                      }
                      Alert.alert(i18n.t('products.categories.editCustomCategory.alert_success_delete'), i18n.t('products.categories.editCustomCategory.alert_success_delete_body'))
                      navigation.goBack()
                    }).catch((err)=>{
                      console.log(err);
                      // Alert.alert(i18n.t('products.categories.editCustomCategory.alert_error_delete'), i18n.t('products.categories.editCustomCategory.alert_error_delete_body'))
                    })
                  } }
                ]
              );
            }}
          >
            <Text style={{fontWeight:'bold', textAlign:'center', fontSize:18, borderRadius:6}}>
              {i18n.t('products.categories.editCustomCategory.btn_delete')}
            </Text>
          </TouchableOpacity>
       </View>
       {/* <TouchableOpacity style={{paddingHorizontal:10, marginHorizontal:10,zIndex:50, flexDirection:'row', alignItems:'center', backgroundColor:"#FF6D00", width:Dimensions.get('window').width}} onPress={()=> navigation.goBack()}>
            <Text>
              Delete
            </Text>
            <Ionicons name="trash-outline" size={32} color="#000000" />
          </TouchableOpacity>
   */}
      </SafeAreaView>
    )

}

export default EditCustomCategories;