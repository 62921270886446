import { ApolloClient, from, fromPromise, HttpLink, InMemoryCache, split} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities'
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import Firebase from './firebase'
import { getAuth } from 'firebase/auth'
import { onError } from "@apollo/client/link/error";

const auth = getAuth(Firebase);

// This is where the magic happens, this function
// is called every time we make a request to our Hasura
// database. And because of that, we always get a fresh
// token. This way, we never run into issues with expired tokens

const refreshAuthLink = setContext(async (_, { headers }) => {
    const token = await getAuth().currentUser.getIdToken(true);
    return {
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
        },
    };
});


const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors){
        for (let err of graphQLErrors){
            switch (err.extensions.code){
                case 'UNAUTHENTICATED':
                    auth.signOut();
                    break;
                    // auth/quota-exceeded
                    case 'auth/quota-exceeded':
                    console.log('auth/quota-exceeded');
                    break;
                    case 'claims key: \'https://hasura.io/jwt/claims\' not found':
                        console.log('claims key: \'https://hasura.io/jwt/claims\' not found')
                        return fromPromise(
                            getAuth().currentUser.getIdToken(true).catch((error) => {
                                console.log('error', error);
                            })
                        ).filter((value) => Boolean(value)).flatMap((token) => {
                            const oldHeaders = operation.getContext().headers;
                            operation.setContext({
                                headers: {
                                    ...oldHeaders,
                                    Authorization: token ? `Bearer ${token}` : "",
                                },
                            });
                            console.log("END");
                            return forward(operation);
                        });
                    break;

            }

            switch (err.message){
                case 'jwt expired':
                    // auth.signOut();
                    console.log('jwt expired')
                    break;
                    // claims key: 'https://hasura.io/jwt/claims' not found
                case 'claims key: \'https://hasura.io/jwt/claims\' not found':
                    console.log('THROW ERROR ON APOLLO.JS: claims key: \'https://hasura.io/jwt/claims\' not found')
                    return fromPromise(
                        getAuth().currentUser.getIdToken(true).catch((error) => {
                            console.log('error', error);
                        })
                    ).filter((value) => Boolean(value)).flatMap((token) => {
                        const oldHeaders = operation.getContext().headers;
                        operation.setContext({
                            headers: {
                                ...oldHeaders,
                                Authorization: token ? `Bearer ${token}` : "",
                            },
                        });
                        console.log("END");
                        return forward(operation);
                    }
                    );
                break;
            }
        }


    }

  });

const asyncAuthLink = setContext(async () => {
    return {
        headers:{
            Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`
        }
    }
})

const httpLink = new HttpLink({
    uri: 'https://console.menucitos.com/v1/graphql'
})


const wsLink = new GraphQLWsLink(
    createClient({
        url: "wss://console.menucitos.com/v1/graphql",
        connectionParams: async () => {
            return{
                headers: {
                    Authorization: `Bearer ${await getAuth().currentUser.getIdToken()}`
                }
            }
        }
    })
);



/**Split Link */

const splitLink = split(
    ({query}) => {
        const {kind, operation} = getMainDefinition(query)
        return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    asyncAuthLink.concat(httpLink),
)

export const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies:{
            Query:{
                fields:{
                    users_by_pk:{
                        merge(existing, incoming){
                            return incoming
                        }
                    },
                    organization_by_pk:{
                        merge(existing, incoming){
                            return incoming
                        }
                    },
                    users:{
                        merge(existing, incoming){
                            return incoming
                        }
                    },
                    // define a custom merge function for the messages.userBySenderId field
                    messages: {
                        keyArgs: false,
                        merge(existing, incoming) {
                            return incoming;
                        }
                    },
                    


                }
            },
            Subscription:{
                fields:{
                    messages:{
                        merge(existing, incoming){
                            return incoming
                        }

                    }
                }
            }
        }
    }),
    link: from([asyncAuthLink, errorLink, splitLink]),
});