import React, { useEffect, useState} from 'react';
import {
    SafeAreaView, Text, View, TextInput, TouchableOpacity, Alert,KeyboardAvoidingView, Platform, Pressable, Dimensions, Image, useWindowDimensions,
    Modal,
    Linking,
    ScrollView
  } from 'react-native';
import * as Localization from 'expo-localization';
import Firebase from '../lib/firebase';
import { getAuth, signInWithEmailAndPassword, FacebookAuthProvider, signInWithCredential, reauthenticateWithCredential,OAuthProvider } from 'firebase/auth';
import * as Facebook from 'expo-auth-session/providers/facebook';
import * as AppleAuthentication from 'expo-apple-authentication';
import { makeRedirectUri, ResponseType } from 'expo-auth-session';
// import WelcomeLogo from '../assets/WelcomeScreen.png';
import LogoBienvenida from '../assets/menufin.png';
import { Picker } from '@react-native-picker/picker';
import { useFonts } from 'expo-font';
import { useTogglePasswordVisibility } from '../hooks/useTogglePasswordVisibility';
// import Pressable from 'react-native/Libraries/Components/Pressable/Pressable';
import { Feather, Ionicons } from '@expo/vector-icons';
import AsyncStorage from "@react-native-async-storage/async-storage"
import i18n from '../i18n/i18n';
import * as WebBrowser from 'expo-web-browser';


const auth = getAuth(Firebase);
WebBrowser.maybeCompleteAuthSession();

  /**Function login */
  const Login = ({ navigation }) => {

    const { passwordVisibility, rightIcon, handlePasswordVisibility } = useTogglePasswordVisibility()
    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const languageCode = language.toUpperCase();
    console.log("LANGUAGE CODE", languageCode)
      const [email, setEmail] = useState('')
      const [password, setPassword] = useState('')
      const [modalVisibility, setModalVisibility] = useState(false)
      const [modalVisibilityAttempts, setModalVisibilityAttempts] = useState(false)
      const [modalVisibilityUserNotFound, setModalVisibilityUserNotFound] = useState(false);
      const [modalVisibilityNetworkError, setModalVisibilityNetworkError] = useState(false);
      const [modalVisibilityInvalidEmail, setModalVisibilityInvalidEmail] = useState(false);
      const [selectedCountry, setSelectedCountry] = useState(0);
      const [selectedLanguage, setSelectedLanguage] = useState('')


      /**Facebook result */
      const [request, response, promptAsync] = Facebook.useAuthRequest({
        ResponseType: ResponseType.Token,
        clientId: '308615167097974',
        scopes: ['public_profile','email'],
      }, {
        useProxy: false,
      });


      useEffect(() => {

       (async () => {
        setSelectedLanguage(language);

        if (response?.type === 'success') {
          const { access_token } = response.params;
          console.log("ACCESS TOKEN", response.params)
          // login with facebook using cloud function createuserfaceobok
          // const credential = new FacebookAuthProvider.credential(access_token);
          // const credential = provider.credential(access_token);
          // Sign in with the credential from the Facebook user.
          // console.log("CREDENTIAL", credential)
          
          const credential = FacebookAuthProvider.credential(access_token);
          
          //signInWithCredential(auth, credential) and then wait for custom claims to be set
          await signInWithCredential(auth, credential).catch((error) => {
            console.log("ERROR", error)
          });
        }
        //request

        if(request){
          // console.log("REQUEST", request)
        }
       })();
      // console.log("LANGUAGE CODE", languageCode)
      /**Handler */
      }, [response, request, languageCode]);

      const handleLogin = async() => {
        try{
          await signInWithEmailAndPassword(auth, email, password);

          AsyncStorage.setItem('session', 'true');
        }
        catch(error){
          // console.log("Error: ", error.code);
          if(error.code === 'auth/wrong-password'){
            if(Platform.OS === 'ios' || Platform.OS === 'android'){
              Alert.alert(i18n.t('login.wrong_password'), i18n.t('login.wrong_password_message'));
            }else if(Platform.OS === 'web'){
              // alert(i18n.t('login.wrong_password'), i18n.t('login.wrong_password_message'));
              setModalVisibility(true)
            }
          }else if(error.code === 'auth/too-many-requests'){
            if(Platform.OS === 'ios' || Platform.OS === 'android'){
              Alert.alert(i18n.t('login.too_many_attempts'), i18n.t('login.too_many_attempts_message'));
            }else if(Platform.OS === 'web'){
              setModalVisibilityAttempts(true)
            }
          }else if(error.code === 'auth/user-not-found'){
            if(Platform.OS === 'ios' || Platform.OS === 'android'){
              Alert.alert(i18n.t('login.email_not_found'), i18n.t('login.email_not_found_message'));
            }else if(Platform.OS === 'web'){
              setModalVisibilityUserNotFound(true)
            }
          }else if(error.code === "auth/network-request-failed"){
            if(Platform.OS === 'ios' || Platform.OS === 'android'){
              Alert.alert(i18n.t('login.network_error'), i18n.t('login.network_error_message'));
            }else if(Platform.OS === 'web'){
              setModalVisibilityNetworkError(true)
            }
          }else if(error.code === "auth/invalid-email"){
            if(Platform.OS === 'ios' || Platform.OS === 'android'){
              Alert.alert(i18n.t('login.invalid_email'), i18n.t('login.invalid_email_message'));
            }else if(Platform.OS === 'web'){
              setModalVisibilityInvalidEmail(true)
            }
          }
        }
      }

      const handleFacebookLogin = async() => {
        console.log("Facebook Login");

        if(Platform.OS === 'ios' || Platform.OS === 'android' || Platform.OS === 'web'){
        await promptAsync();
      }
    }
      let screenWidth
      if(Platform.OS === 'ios' || Platform.OS === 'android'){
        
      screenWidth = Dimensions.get('window').width;
      }else if(Platform.OS === 'web'){
        if(screenWidth < 550){
          screenWidth = useWindowDimensions().width;
        }else {
          screenWidth = useWindowDimensions().width * 0.5;
        }
      }

      
      const handlerPickerLanguage = (itemValue, itemIndex) => {
        setSelectedLanguage(itemValue);
        console.log("Picker: ", itemValue, itemIndex);
        // console.log("Language: ", i18n.language);
        console.log(itemValue)
        const number = itemValue;
    
        if(number === 'en'){
          i18n.locale = 'en'
          setSelectedLanguage('en')
          AsyncStorage.setItem('language', 'en');
        }else if(number === 'es'){

          i18n.locale = 'es'
          setSelectedLanguage('es')
          AsyncStorage.setItem('language', 'es');
        }
      }

      return (
        <SafeAreaView style={{flex:1}}>
          {/* Modal Wrong Password */}
          <Modal visible={modalVisibility}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibility(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontFamily:'Inter-Black'}}>{i18n.t('login.wrong_password')}</Text>
                <Text style={{fontSize:20}}>{i18n.t('login.wrong_password_message')}</Text>

                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibility(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>

              </View>
          </Modal>
          {/* /**Modal too many attemps */}
          <Modal visible={modalVisibilityAttempts}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibilityAttempts(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('login.too_many_attempts')}</Text>
                <Text style={{fontSize:20}}>{i18n.t('login.too_many_attempts_message')}</Text>
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAttempts(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>

          {/* /**Modal user not found */}
          <Modal visible={modalVisibilityUserNotFound}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibilityUserNotFound(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('login.email_not_found')}</Text>
                <Text style={{fontSize:20}}>{i18n.t('login.email_not_found_message')}</Text>
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityUserNotFound(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>

          {/* /**Modal network error */}
          <Modal visible={modalVisibilityNetworkError}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibilityNetworkError(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('login.check_internet')}</Text>
                <Text style={{fontSize:20}}>{i18n.t('login.check_internet_message')}</Text>
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityNetworkError(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>

          {/* /**Modal invalid email */}

          <Modal visible={modalVisibilityInvalidEmail}
          transparent={true}
          animationType={'fade'}
          onRequestClose={() => setModalVisibilityInvalidEmail(false)}
          >
            <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
              <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                <Text style={{fontSize:20, fontWeight:'bold'}}>{i18n.t('login.invalid_email')}</Text>
                <Text style={{fontSize:20}}>{i18n.t('login.invalid_email_message')}</Text>
                <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityInvalidEmail(false)}>
                  <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                </TouchableOpacity>
                </View>
              </View>
          </Modal>






          
            <KeyboardAvoidingView style={{flex:1, flexDirection:Platform.OS === 'android' || Platform.OS === 'ios'? 'column': 'column'}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
              <ScrollView>
             <View style={{flex:0.8, width: Platform.OS === 'android' || Platform.OS === 'ios'? '100%': '100%'
            }}>
             <View style={{paddingTop:0, paddingHorizontal:15, flex:0.5}}>
              {/* <View style={{flexDirection:'row'}}>
              <Text style={{fontSize:32,fontFamily:'Inter-Black'}}>
                  {i18n.t('login.title')}
              </Text>
              </View> */}

          </View>
          <View style={{flex:4}}>
            <View style={{maxWidth:screenWidth}}>
            <Image  source={languageCode === 'EN'? LogoBienvenida : LogoBienvenida}  
              style={{width:screenWidth, height:screenWidth*0.3, borderRadius:15}}/>
            </View>
 
              <Text style={{paddingHorizontal:15, fontSize:16, fontFamily:'Inter-Black'}}>{i18n.t('login.email')}</Text>
              <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:5,
               marginHorizontal:15, fontSize:18, borderRadius:10, fontFamily:'Inter-Regular'}}
              onChangeText={(val) => setEmail(val.trim())}
              placeholder='carlos@gmail.com'
              autoCapitalize='none'
              textContentType='emailAddress'
              keyboardType='email-address'
              autoCorrect={false}
              autoComplete='email'
               />
              <Text style={{paddingHorizontal:15, fontSize:16, fontFamily:'Inter-Black'}}>{i18n.t('login.password')}</Text>
              <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
              <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:5,flexGrow:1 ,
               marginHorizontal:15, fontSize:18, borderRadius:10, fontFamily:'Inter-Regular'}} 
               placeholder={i18n.t('login.password')}
               onChangeText={(val) => setPassword(val)}
               secureTextEntry={passwordVisibility}
              />
              <Pressable onPress={handlePasswordVisibility} style={{position:'absolute', alignSelf:'center', right:25}}>
                <Feather name={rightIcon} size={22} ></Feather>
              </Pressable>
              </View>
 
              <View style={{marginTop:5,flexDirection:'row', justifyContent:'center'}}>
             <Text style={{fontSize:16}}>{i18n.t('login.not_registered')}</Text>
              <TouchableOpacity
              style={{marginLeft:5}}
              onPress={() => navigation.navigate('Signup')}
            >
              <Text style={{fontSize:16, fontFamily:'Inter-Black', color:'#FF6D00'}}>
                {i18n.t('login.btn_signup')}
              </Text>
            </TouchableOpacity>
          </View>
          <View>
                     <View style={{marginTop:5,flexDirection:'row', justifyContent:'center'}}>
             <Text style={{fontSize:16}}>{i18n.t('login.forgot_password')}</Text>
              <TouchableOpacity
              style={{marginLeft:5}}
              onPress={() => navigation.navigate('Recover')}
            >
              <Text style={{fontSize:16, fontFamily:'Inter-Black', color:'#FF6D00'}}>
                {i18n.t('login.forgot_password_btn')}
              </Text>
            </TouchableOpacity>

          </View>
          <View style={{paddingTop:5, paddingBottom:15, paddingHorizontal:15, maxWidth:250}}>
            <Text style={{fontSize:18, fontFamily:'Inter-Black', paddingHorizontal:15}}>{i18n.t('login.select_language')} </Text>
            <Picker
            selectedValue={selectedLanguage}
            itemStyle={{height:75}}
            onValueChange={handlerPickerLanguage}
            >
            <Picker.Item label={i18n.t('login.change_language')} value={0} />
            <Picker.Item label={i18n.t('userSettings.profile.picker_EN')} value={'en'}/>
              <Picker.Item label={i18n.t('userSettings.profile.picker_ES')} value={'es'}/>
            </Picker>
        </View>
          </View>
 
          
          <View style={{paddingHorizontal:15, alignSelf:'auto'}}>
          <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:10, borderRadius:6, paddingHorizontal:30, marginBottom:5, flexDirection:'row', justifyContent:'center'}}
              onPress={handleLogin}>
                  <Text style={{alignSelf:'center', color:'white',fontFamily:'Inter-Bold', fontSize:18}}>{i18n.t('login.btn_login')}</Text>
                  <Feather name='arrow-right' size={22} color='white' style={{alignSelf:'center', marginLeft:10}}></Feather>
              </TouchableOpacity>
              {(Platform.OS ==='android' || Platform.OS ==='web' || Platform.OS === 'ios')? (
                <TouchableOpacity style={{backgroundColor:'#3D5AFE', paddingVertical:10, borderRadius:6, paddingHorizontal:30, flexDirection:'row', justifyContent:'center'}}
              onPress={handleFacebookLogin}>
                  <Text style={{alignSelf:'center', color:'white', fontFamily:'Inter-Bold', fontSize:18}}>{i18n.t('login.btn_facebook')}</Text>
                  <Ionicons name='logo-facebook' size={22} color='white' style={{alignSelf:'center', marginLeft:10}}></Ionicons>
              </TouchableOpacity>
              ): null}
              {(Platform.OS === 'ios') && (
                    <View style={{flex:1, marginTop:3}}>
                    <AppleAuthentication.AppleAuthenticationButton
                      buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                      buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                      cornerRadius={5}
                      style={{
                        flexGrow:1,
                        height: 44,
                      }}
                      onPress={async () => {
                        try {
                          const credential = await AppleAuthentication.signInAsync({
                            requestedScopes: [
                              AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                              AppleAuthentication.AppleAuthenticationScope.EMAIL,
                            ],
                          });
                          //sign in with credential
                          const {identityToken, email, state} = credential;
                          const provider = new OAuthProvider('apple.com');
                          const credentialFirebase = provider.credential({
                            idToken: identityToken,
                            rawNonce: state,
                          });
                          await signInWithCredential(auth, credentialFirebase);
                          
                        } catch (e) {
                          console.log(e);
                          if (e.code === 'ERR_REQUEST_CANCELED') {
                            // handle that the user canceled the sign-in flow
                            
                          } else {
                            // handle other errors
                          }
                        }
                      }}
                    />
                  </View>
              )}
              <TouchableOpacity style={{borderWidth:2, borderColor:'#FF6D00', paddingVertical:10, borderRadius:6, paddingHorizontal:30, marginBottom:5, flexDirection:'row', justifyContent:'center', marginTop:3}}
              onPress={()=>{
                navigation.navigate('Signup')
              }}>
                  <Text style={{alignSelf:'center', color:'#FF6D00',fontFamily:'Inter-Bold', fontSize:18}}>{i18n.t('login.btn_signup_email')}</Text>
                  <Feather name='mail' size={22} color='#FF6D00' style={{alignSelf:'center', marginLeft:10}}></Feather>
              </TouchableOpacity>
              <TouchableOpacity style={{borderWidth:2, borderColor:'#128C7E',backgroundColor:'#128C7E', paddingVertical:8, borderRadius:10, paddingHorizontal:30, marginBottom:5, flexDirection:'row', justifyContent:'center', marginTop:3}}
              onPress={()=>{
                //open whatsapp
                Linking.openURL('https://wa.me/+12136932550')
              }}>
                  <Text style={{alignSelf:'center', color:'#fff',fontFamily:'Inter-Bold', fontSize:18}}>{i18n.t('login.contact_whatsapp')}</Text>
                  <Ionicons name='logo-whatsapp' size={22} color='#fff' style={{alignSelf:'center', marginLeft:10}}></Ionicons>

              </TouchableOpacity>

              </View>
          </View>   
             </View>
             </ScrollView>
          </KeyboardAvoidingView>
      </SafeAreaView>
      )
   }
  
export default Login; 