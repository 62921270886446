import React, { useState, useEffect, useCallback } from 'react';
import { View, Text,  TouchableOpacity, KeyboardAvoidingView, Image,FlatList ,ImageBackground, Platform, Alert, TextInput, TouchableWithoutFeedback, Dimensions, useWindowDimensions } from 'react-native';
// import { } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '../lib/queries';
import { Feather } from '@expo/vector-icons';
import i18n from '../i18n/i18n';
import * as Location from 'expo-location';

import { useIsFocused } from '@react-navigation/native';

import Slider from '@react-native-community/slider';

const Search = ({navigation}) => {
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();

  const [location, setLocation] = useState('');
  const [search, setSearch] = useState('')
  const [categories, setCategories] = useState([])
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [searchText, setSearchText] = useState('')
  const isFocused = useIsFocused();
  const [distance, setDistance] = useState(80000)
  /**Query get_categories */
  const {loading:categoriesLoading, data:categoriesData, error:categoriesError, refetch} = useQuery(GET_CATEGORIES, {variables:{languageCode: languageCode}});

    useEffect(() => {
        if(isFocused){
          refetch();
          // console.log("ERROR: ", categoriesError)
          if(categoriesData){
            
            const {default_categories} = categoriesData;
            // console.log("CATEGORIES: ", default_categories)
            setCategories(default_categories)
          }
          (async () =>{
            let {status} = await Location.requestForegroundPermissionsAsync();
            console.log("STATUS: ", status)

            if (status !== 'granted') {
              return;
            }
    
            let location = await Location.getCurrentPositionAsync({accuracy: Location.Accuracy.Highest});
            setLocation(location);
            setLatitude(location.coords.latitude)
            setLongitude(location.coords.longitude);
            console.log("LOCATION: ", location)
          })();
    
          // console.log("LOcation:", location)
        }
    }, [categories, categoriesData, isFocused])

    let screenWidth
    let numColumns
    let itemWidth 
  
    if(Platform.OS === 'ios' || Platform.OS === 'android'){
      screenWidth = Dimensions.get('window').width;
      numColumns = 2;
      itemWidth = screenWidth / numColumns;
    }else if(Platform.OS === 'web'){
      // screenWidth = window.innerWidth;
      // numColumns = 3;
      // itemWidth = screenWidth / numColumns;
  
      screenWidth = useWindowDimensions().width;
  
      if(screenWidth > 1200){
        numColumns = 6;
        itemWidth = screenWidth / numColumns;
      }else if(screenWidth > 768){
        numColumns = 5;
        itemWidth = screenWidth / numColumns;
      }
      else{
        numColumns = 2;
        itemWidth = screenWidth/numColumns;
      }
    }

    const CategoryItem = ({item, default_category_name, category_photo, category_id}) => (

      <TouchableOpacity 
      key={category_id}
      style={{paddingHorizontal:8}}
      onPress={()=>{
        navigation.navigate('CategoryDetailed',{
          categoryId:category_id,
          distanceCart:distance,
          latitude,
          longitude
        })
      }}>
        <Image key={category_id} source={{uri: category_photo}} style={{paddingVertical:5, width:itemWidth-15, height:itemWidth-15, borderRadius:6, paddingHorizontal:25}}></Image>
        <Text style={{fontFamily:'Inter-Medium', fontSize:18, textAlign:'center'}}>{default_category_name}</Text>
      
      </TouchableOpacity>

    )

    const renderItem = useCallback(({item}) => {
      return(
        <CategoryItem item={item} 
        default_category_name={item.default_category_translations[0].default_category_name}
        category_photo={item.category_photo}
        category_id={item.category_id}
        />
      )
    
    }, [latitude, longitude])
    function header(){
      return(
        <View style={{paddingTop:5, paddingHorizontal:5, flex:1}}>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
            {i18n.t('search.title')}
        </Text>
        </View>
        <View style={{paddingTop:5, flexDirection:'row'}}>
        <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0',flexGrow:1, paddingVertical:5, fontSize:18, borderRadius:6}} 
        //enter
        onSubmitEditing={()=>{
          navigation.navigate('Results',{
            latitude,
            longitude,
            searchText,
            distance
          })
        }}
        onChangeText={text => setSearchText(text)} />
        <TouchableOpacity style={{paddingHorizontal:12, backgroundColor:'#CFD8DC', paddingVertical:5, marginLeft:10, borderRadius:6}} onPress={
          ()=>{
            navigation.navigate('Results',{
              latitude,
              longitude,
              searchText,
              distance
            })
          }
        }>

        <Feather name="search" size={20} color='#000000'/>
        </TouchableOpacity>
        
        </View>
        <View>
          
        <Text style={{fontSize:16, fontFamily:'Inter-Medium', color:'#000000', paddingHorizontal:6}}>{i18n.t('home.range_label')}</Text>
          <Text style={{paddingHorizontal:6}}>Aumenta de Rango para ver productos mas lejanos de ti</Text>
            <View style={{flexDirection:'row', alignItems:'center', alignContent:'center', justifyContent:'center'}}>
            <Slider
            style={{width: 200, height: 75, paddingBottom:10}}
            minimumValue={2000}
            maximumValue={80000}
            value={distance}
            minimumTrackTintColor="#FFA726"
            maximumTrackTintColor="#000000"
            // onValueChange={(value)=>{
            //   setDistance(value);
            // }}
            onSlidingComplete={(value)=>{
              setDistance(parseFloat(value));
            }}
          />

          <View style={{flexDirection:'row'}}>
              <Text>{(distance/1000).toFixed(0)}</Text>
              <Text style={{fontWeight:'bold'}}> Km</Text>
          </View>

            </View>
        <Text style={{fontSize:22, paddingTop:20, fontWeight:'bold'}}>{i18n.t('search.explore')} </Text>
        <View style={{alignItems:'center', alignContent:'center'}}>
        </View>
        </View>
        </View>
      )
    }

    return(
      <SafeAreaView style={{flex:1, paddingTop:-45}}>
        
  <FlatList 
  ListHeaderComponent={header()} 
  data={categories} 
  contentContainerStyle={{paddingBottom:10}} 
  numColumns={numColumns} 
  key={numColumns}
  listKey={'categories'}
  renderItem={renderItem} keyExtractor={item => item.category_id}></FlatList>

        
</SafeAreaView>
    )
    
}

export default Search;