import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, FlatList, Alert, Platform } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { Feather } from '@expo/vector-icons';
import { useQuery, useMutation } from '@apollo/client';
import { DELETE_USER_COUNTRY_PAYMENT_OPTION, GET_USER_PAYMENT_OPTIONS } from '../../lib/queries';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import base64 from 'react-native-base64';
import i18n from '../../i18n/i18n';
import { getFunctions,httpsCallable } from 'firebase/functions';
// import { useStripe } from '@stripe/stripe-react-native';

const useStripe = Platform.select({
  web: () => null,
  native: () => require('@stripe/stripe-react-native').useStripe,
})();
const auth = getAuth(Firebase);
const functions = getFunctions(Firebase);


const PaymentMethods = ({navigation}) => {
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  /**useruid */
  /**test */
  const uid= getAuth().currentUser.uid;
  const {loading: fetchLoading, error: fetchError, data: fetchData, refetch} = useQuery(GET_USER_PAYMENT_OPTIONS, {variables:{uid, languageCode}});

  /**State */
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [loading, setLoading] = useState(false);

  // dont use on web const {initPaymentSheet, presentPaymentSheet, confirmPaymentSheetPayment} = useStripe();
  Platform.select({
    web: () => null,
    native: () => {
      const {initPaymentSheet, presentPaymentSheet, confirmPaymentSheetPayment} = useStripe();
    }
  })();
  //mutation 
  const [deleteUserPaymentOption, {loading: mutationLoading, error: mutationError, data: mutationData}] = useMutation(DELETE_USER_COUNTRY_PAYMENT_OPTION  );
  useEffect(() => {
    
    console.log(fetchError)
    let newUserCountryPaymentOption;
    if(fetchData) {
      const {user_country_payment_option} = fetchData;
      // console.log(user_country_payment_option);
      newUserCountryPaymentOption = user_country_payment_option;
    }

    /**Fetch my paypal token */
    async function getPaypalToken() {
     const response = await fetch('https://api-m.paypal.com/v1/oauth2/token ', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Accept-Language': 'en_US', 
          'Authorization': 'Basic '+ base64.encode('AZuIVyOpv53U1fXhArisIyI25BDbsCo2aIFLmN90y-fgKMyQ-5NY1eAUupZcT5qza1cgVHnlVnneCCG3:EKIS8dDy-LbzLI1Q3eQ63c5rkrU8lGDNoqMcDPK1m0r1OR04X1fmrSs9_uZIKu411NKDp6EV5hXLczGA'),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'grant_type=client_credentials',
      })

      const content = await response.json();

      console.log(content.access_token);
      setAccessToken(content.access_token);
    }

    getPaypalToken();

    setPaymentOptions(newUserCountryPaymentOption);
    console.log(newUserCountryPaymentOption);

    const unsubscribe = navigation.addListener('focus', () => {
      console.log('focus');
      refetch();
    })
  })

  
    
    /**Select route destination*/
    const SelectRouter =(item) => {
      if(item['country_payment_option']['payment_option'].payment_options_id === 1) {

        const paymentOptionsId = paymentOptions.map(item => item['country_payment_option']['payment_option'].payment_options_id);

        console.log(paymentOptionsId);

        navigation.navigate('CashConfig', {item, paymentOptionsId});

      }else if(item['country_payment_option']['payment_option'].payment_options_id === 2) {
        navigation.navigate('PayPalConfig', {
          accessToken,
        });
        console.log('PayPal ', (accessToken))
      }else if(item['country_payment_option']['payment_option'].payment_options_id === 3) {
        console.log(item.id_user_country_payment_option)
        navigation.navigate('MenuPayIndividual', {
          id: item.id_user_country_payment_option,
        });
      }
    }

    const editPaymentMethod = (item) => {
      if(item['country_payment_option']['payment_option'].payment_options_id === 1) {
        Alert.alert(
          i18n.t("paymentMethods.delete_payment"),
          i18n.t("paymentMethods.delete_payment_body"),
          [
            {
              text: "Cancel",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel"

            },
            {
              text: "OK",
              onPress: () => {
                console.log("OK Pressed");
                deleteUserPaymentOption({
                  variables: {
                    idUserCountryPaymentOption: item.id_user_country_payment_option
                  }
                }).then(() => {
                  refetch();
                  // const deleteStripeAccount = httpsCallable(functions, 'deleteStripeAccount');
                  // deleteStripeAccount({
                  //   userStripeId: item.user_stripe_id
                  // }).then((result) => {
                  //   console.log(result.data);
                  //   console.log('delete stripe account');
                  // }).catch((error) => {
                  //   console.log(error);
                  // });
                }).catch((error) => {
                  console.log(error);
                });
              }
            }
          ]
        );
      }else if(item['country_payment_option']['payment_option'].payment_options_id === 2) {
        Alert.alert(
          i18n.t("paymentMethods.delete_payment"),
          i18n.t("paymentMethods.delete_payment_body"),
          [
            {
              text: "Cancel",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel"

            },
            {
              text: "OK",
              onPress: () => {
                console.log("OK Pressed");
                deleteUserPaymentOption({
                  variables: {
                    idUserCountryPaymentOption: item.id_user_country_payment_option
                  }
                }).then(() => {
                  refetch();
                  // const deleteStripeAccount = httpsCallable(functions, 'deleteStripeAccount');
                  // deleteStripeAccount({
                  //   userStripeId: item.user_stripe_id
                  // }).then((result) => {
                  //   console.log(result.data);
                  //   console.log('delete stripe account');
                  // }).catch((error) => {
                  //   console.log(error);
                  // });
                }).catch((error) => {
                  console.log(error);
                });
              }
            }
          ]
        );
      }else if(item['country_payment_option']['payment_option'].payment_options_id === 3) {
          //delete alert
          console.log(item.user_stripe_id)
          Alert.alert(
            i18n.t("paymentMethods.delete_payment"),
            i18n.t("paymentMethods.delete_payment_body"),
            [
              {
                text: "Cancel",
                onPress: () => console.log("Cancel Pressed"),
                style: "cancel"

              },
              {
                text: "OK",
                onPress: () => {
                  const deleteStripeAccount = httpsCallable(functions, 'deleteStripeAccount');
                  deleteStripeAccount({
                    userStripeId: item.user_stripe_id
                  }).then((result) => {
                    console.log(result.data);
                    console.log('delete stripe account');
                  }).catch((error) => {
                    console.log(error);
                  });

                  const deleteStripeCustomerAccount = httpsCallable(functions, 'deleteStripeCustomerAccount');

                  deleteStripeCustomerAccount({
                    userStripeCustomerId: item.user_stripe_customer_id
                  }).then((result) => {
                    console.log(result.data);
                    console.log('delete stripe customer account');
                  }).catch((error) => {
                    console.log(error);
                  });

                  deleteUserPaymentOption({
                    variables: {
                      idUserCountryPaymentOption: item.id_user_country_payment_option
                    }
                  }).then(() => {
                    refetch();
                  }).catch((error) => {
                    console.log(error);
                  });
                  // console.log("OK Pressed");
                }
              }
            ]
          );
      }
    }   

    const Item = ({item, index}) => {
      console.log(item['country_payment_option']['payment_option'].payment_options_id);

      return(
        <TouchableOpacity style={{paddingVertical:10, backgroundColor:'#304FFE', borderRadius:8, marginTop:3, flexDirection:'row', justifyContent:'center', alignItems:'center'}}
        onLongPress={() =>{
          console.log('long press');
          editPaymentMethod(item);
        }}
        onPress={()=> {SelectRouter(item)}}>
          <Text style={{textAlign:'center', fontSize:16, fontFamily:'Inter-Bold', color:'#FFFFFF'}}>{item['country_payment_option']['payment_option']['payment_option_translations'][0]['payment_option_name']} </Text>
          <Text style={{color:"#fff"}}>
            {"("}{item['country_payment_option']['country']['country_name']}{")"}
          </Text>
        </TouchableOpacity>
        
      )
    }

    const renderItem = ({item, index}) => (<Item item={item} index={index}/>);

    return(
        <SafeAreaView style={{flex:1, paddingVertical:-45}}>
        <View style={{paddingHorizontal:5, paddingTop:5, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:'bold'}}>
                {i18n.t('paymentMethods.title')}
            </Text>
            </View>
            <TouchableOpacity
            style={{backgroundColor:'#e0e0e0', borderRadius:6, paddingVertical:15, marginTop:10, alignItems:'center', justifyContent:'center'}}
            onPress={() => {
              const paymentOptionsId = paymentOptions.map(item => item['country_payment_option']['payment_option'].payment_options_id);
              const countryPaymentOptionsId = paymentOptions.map(item => item['country_payment_option'].country_payment_options_id);

              console.log(paymentOptionsId);
              console.log("ccounrryryy ",countryPaymentOptionsId);

              navigation.navigate('AddPaymentMethodsNav', {
                paymentOptionsId,
                countryPaymentOptionsId
            })}}
          >
                <View style={{flexDirection:'row', alignItems:'center'}}>
                      <Text style={{textAlign:'center', fontWeight:'bold',justifyContent:'center'}}>
              {i18n.t('paymentMethods.btn_add')}              
            </Text>
                </View>
          </TouchableOpacity>
        </View>
  

          <View style={{paddingVertical:5, paddingHorizontal:6, flex:2}}>
          <FlatList contentContainerStyle={{paddingBottom: 200}} data={paymentOptions} renderItem={renderItem} keyExtractor={item => item.id_user_country_payment_option.toString()}/>
          </View>
    </SafeAreaView>
    )
    
}

export default PaymentMethods;