import {initializeApp, getApps} from 'firebase/app';
import { getAuth, initializeAuth } from 'firebase/auth';
import { getAnalytics, initializeAnalytics } from 'firebase/analytics';
import { getReactNativePersistence } from 'firebase/auth/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

const config = {
    apiKey: "AIzaSyAq313Wtxgbep-V2dlyJPbjO-ccOr4NIbg",
    authDomain: "menucitos-328501.firebaseapp.com",
    projectId: "menucitos-328501",
    storageBucket: "menucitos-328501.appspot.com",
    messagingSenderId: "353109732632",
    appId: "1:353109732632:web:e0b188ff9e35830de5026d",
    measurementId: "G-23ZBZHJYMN"
}
let Firebase;
let FirebaseAuth;
if (!getApps().length) {
    Firebase = initializeApp(config);
    FirebaseAuth = initializeAuth(Firebase, {
        persistence: getReactNativePersistence(AsyncStorage),   
    });
    if(Platform.OS ==='web'){
        let FirebaseAnalytics;
        FirebaseAnalytics = initializeAnalytics(Firebase);
        const webAnalytics = getAnalytics(Firebase);
    } else{
        
    }
}

export default Firebase;
