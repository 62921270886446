import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity, Alert, TouchableWithoutFeedback, KeyboardAvoidingView, Platform, Keyboard, Modal} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from '../../i18n/i18n';

const AddProductQuantity = ({navigation, route}) => {

    /**route.params */

    const {subCategoryId} = route.params

    /**productSKUPrice */
    const {productSKUPrice} = route.params
    const {productSKU} = productSKUPrice
    
    /**productTitle */ 
    const {productTitlePrice} = route.params
    const {productTitle} = productTitlePrice

    /**productSlug */
    const {productSlugPrice} = route.params
    const {productSlug} = productSlugPrice

    /**productImage */
    const {productImagePrice} = route.params
    const {image} = productImagePrice

    /**productPrice */

    const {productPricePrice} = route.params
    const {productPrice} = productPricePrice

    /**productCurrencyPrice */

    const {productCurrencyPrice} = route.params
    const {productCurrency} = productCurrencyPrice

    /**productGalleryPrice */

    const {productGalleryPrice} = route.params
    const {productGallery} = productGalleryPrice

    const [productQuantity, setProductQuantity] = useState()
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [modalVisibilityAlert, setModalVisibilityAlert] = useState(false);


    useEffect(()=>{
      // console.log("SUBCAT QUANTITY: ",subCategoryId)
    })
    return(
      <SafeAreaView style={{flex:1, paddingVertical:-45}}>
              <Modal visible={modalVisibilityAlert}
                        transparent={true}
                        animationType={'fade'}
                        onRequestClose={() => setModalVisibilityAlert(false)}
                        >
                          <View style={{flex:1,justifyContent:'center', alignItems:'center', backgroundColor:'rgba(0,0,0,0.5)'}}>
                            <View style={{backgroundColor:'white', borderRadius:10, justifyContent:'center', alignItems:'center'}}>
                              <Text style={{fontSize:20, fontWeight:'bold'}}>{messageTitle}</Text>
                              <Text style={{fontSize:20}}>{messageBody}</Text>
              
                              <TouchableOpacity style={{marginTop:20}} onPress={() => setModalVisibilityAlert(false)}>
                                <Text style={{fontSize:20, fontWeight:'bold'}}>Close</Text>
                              </TouchableOpacity>
                              </View>
                            </View>
            </Modal>
        {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
            <KeyboardAvoidingView style={{flex:1}} behavior={Platform.OS === 'ios'? "padding": null} enabled>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>

            <View style={{paddingTop:5, paddingHorizontal:15, flex:1}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontWeight:'bold', fontSize:32}}>
              {i18n.t('products.addProductQuantity.title')}
            </Text>
            </View>
            {/* <View style={{paddingTop:25}}>
                <Text style={{fontSize:22}}> How much quantity do you have of this product?</Text>
            </View> */}
        </View>
         </TouchableWithoutFeedback>
        <View style={{flex:2}}>
        <Text style={{fontSize:16 , paddingHorizontal:15}}>{i18n.t('products.addProductQuantity.body')}</Text>
            <TextInput style={{paddingHorizontal:12, backgroundColor:'#e0e0e0', paddingVertical:12, marginTop:12,
             marginHorizontal:15, fontSize:18}}
             placeholder="4"
             keyboardType={'number-pad'}
             onChangeText={(v)=> setProductQuantity(v.replace(/[- #*;,.<>\{\}\[\]\\\/]/gi, ''))}
             />

            
        </View> 
        <View style={{flex:0.6, paddingHorizontal:15}}>
            <TouchableOpacity style={{backgroundColor:'#FF6D00', paddingVertical:15, borderRadius:6}}
                   onPress={()=> {if(productQuantity){
              navigation.navigate('AddProductDescription',{
                productSKUQuantity:{productSKU},
                productTitleQuantity:{productTitle},
                productSlugQuantity:{productSlug},
                productImageQuantity:{image},
                productPriceQuantity:{productPrice},
                productQuantityQuantity:{productQuantity},
                productCurrencyQuantity:{productCurrency},
                subCategoryId,
                productGalleryQuantity:{productGallery}
              })
            }else{
              Alert.alert(i18n.t('products.addProductQuantity.alert_body'), i18n.t('products.addProductQuantity.alert_body_quantity'))
              if(Platform.OS === 'web'){
                setMessageTitle(i18n.t('products.addProductQuantity.alert_body'))
                setMessageBody(i18n.t('products.addProductQuantity.alert_body_quantity'))
                setModalVisibilityAlert(true)
              }
            }}}>
                <Text style={{alignSelf:'center', color:'white', fontWeight:'bold', fontSize:18}}>{i18n.t('products.addProductQuantity.btn_next')}</Text>
            </TouchableOpacity>
            </View>     
        </KeyboardAvoidingView>
        {/* </TouchableWithoutFeedback> */}
    </SafeAreaView>
 
    )
    
}

export default AddProductQuantity;