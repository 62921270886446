import React, { useState, useEffect } from 'react';
import { View, Text , FlatList, TouchableOpacity} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Firebase from '../../lib/firebase';
import { getAuth } from 'firebase/auth';
import { useQuery, useMutation } from '@apollo/client';
import { GET_COUNTRY_PAYMENTS_OPTIONS, INSERT_USER_PAYMENT_OPTIONS } from '../../lib/queries';
import i18n from '../../i18n/i18n';

//isfocused

import { useIsFocused } from '@react-navigation/native';
import { Alert } from 'react-native';

const auth = getAuth(Firebase)

const AddPaymentMethod = ({navigation, route}) => {

    const isFocused = useIsFocused();

    const lang = i18n.locale;
    const language = lang.substring(0, 2);
    const langQuery = language.toUpperCase();
  
    /**route.params */

    const {paymentOptionsId} = route.params;
    const {countryPaymentOptionsId} = route.params;

    const uid = getAuth().currentUser.uid;
    /**GET_COUNTRY_PAYMENT_OPTIONS query */
    const{loading: loadingCountryPaymentOptions, error: errorCountryPaymentOptions, data: dataCountryPaymentOptions, refetch:refetchCountryPaymentOptions} = useQuery(GET_COUNTRY_PAYMENTS_OPTIONS, {variables: {uid, paymentOptionId:countryPaymentOptionsId, languageCode: langQuery}});

    const [insertPaymentMethod, {loading: mutationLoading, data: mutationData}] = useMutation(INSERT_USER_PAYMENT_OPTIONS);
    const [arrayPaymentOptions, setArrayPaymentOptions] = useState([]);
    const [arrayPaymentOptionsId, setArrayPaymentOptionsId] = useState([]);

    /**States */
    const [countryPaymentOptions, setCountryPaymentOptions] = useState([]);
    useEffect(() => {
        console.log("error", errorCountryPaymentOptions);
        let newCountryPaymentOptions
        if(dataCountryPaymentOptions){
            const {country_payment_options} = dataCountryPaymentOptions;
            newCountryPaymentOptions = country_payment_options;
            // console.log(newCountryPaymentOptions);
        }
        setCountryPaymentOptions(newCountryPaymentOptions);
        // console.log(dataCountryPaymentOptions);
        if(isFocused){
            refetchCountryPaymentOptions();
        }
    })    

    const pressColor =(index)=>{
        let newArrayPaymentOptions = [...arrayPaymentOptions];
        newArrayPaymentOptions[index] = !newArrayPaymentOptions[index];
        setArrayPaymentOptions(newArrayPaymentOptions);
        // console.log('Array Payment Options: ', arrayPaymentOptions);
        if(newArrayPaymentOptions[index]){
            let newArrayPaymentOptionsId = [...arrayPaymentOptionsId, {country_payment_options_id: countryPaymentOptions[index]['country_payment_options_id'],
            user_id: uid}];
            // console.log("Country Payment options: ", countryPaymentOptions[index]['payment_option'].payment_options_id);
            // newArrayPaymentOptionsId.push(countryPaymentOptions[index]['payment_option'].payment_options_id);
            setArrayPaymentOptionsId(newArrayPaymentOptionsId);
            console.log('Array Payment Options Id: ', newArrayPaymentOptionsId);
        }else{
            let newArrayPaymentOptionsId = [...arrayPaymentOptionsId];
            console.log("NewArrayPaymentOptionsId: ", newArrayPaymentOptionsId)
            newArrayPaymentOptionsId = newArrayPaymentOptionsId.filter((item, indexItem)=>{
                return item['country_payment_options_id'] != countryPaymentOptions[index]['country_payment_options_id'];
            });
            setArrayPaymentOptionsId(newArrayPaymentOptionsId);
            console.log('Array Payment Options Id: ', newArrayPaymentOptionsId);
        }
    }

    const Item = ({item, index}) => {
        const BackgroundColor = arrayPaymentOptions[index] ? {backgroundColor:'#64b5f6', paddingVertical:10, marginTop:3, borderRadius:6, flexDirection:"row",alignItems:"center", justifyContent:'center'} : {backgroundColor:'#e0e0e0', paddingVertical:10, marginTop:3, borderRadius:6, flexDirection:"row",alignItems:"center", justifyContent:'center'}
        return(
            <View key={index}>
                <TouchableOpacity key={index} style={BackgroundColor} onPress={()=>{
                    pressColor(index);
                }}>
                    <Text key={index} style={{ textAlign:'center'}}>{item['payment_option']['payment_option_translations'][0].payment_option_name} </Text>
                    <Text key={'country_name'} style={{ textAlign:'center'}}>{item['country']['country_name']}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    const renderItem = ({item, index}) => <Item
    item={item} 
    index={index}
    />;
    
    return(
        
        <SafeAreaView style={{flex:1, paddingVertical:-45}}>
        <View style={{paddingHorizontal:5}}>
            <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
            <Text style={{fontSize:32, fontWeight:"bold"}}>
                {i18n.t('paymentMethods.addPaymentMethod.title')}
            </Text>
            </View>
            <View style={{paddingVertical:8}}>
        <FlatList data={countryPaymentOptions} renderItem={renderItem} keyExtractor={item => item['country_payment_options_id'].toString()}></FlatList>
        </View>
        <TouchableOpacity
            style={{backgroundColor:'#2979FF', borderRadius:6, paddingVertical:10, marginTop:2}}
            onPress={()=> {
                console.log('Array Payment Options Id: ', arrayPaymentOptionsId);
                insertPaymentMethod({variables: {user_payment_options: arrayPaymentOptionsId}}).then(
                    (res)=>{
                        Alert.alert(i18n.t('paymentMethods.addPaymentMethod.payment_alert_added'), i18n.t('paymentMethods.addPaymentMethod.payment_alert_added_body'));
                        navigation.goBack();
                    }
                );
            }}
          >
            <Text style={{color:'#FFFFFF', textAlign:'center', fontWeight:'bold'}}>
                {i18n.t('paymentMethods.addPaymentMethod.btn_save')}
            </Text>
          </TouchableOpacity>
        </View>
        
    </SafeAreaView>
    )
    
}

export default AddPaymentMethod;