import React, { useState, useEffect } from 'react';
import { SafeAreaView, Text, TouchableOpacity, ActivityIndicator, View, TextInput, Alert, Image, ScrollView, Touchable, Share, Platform } from 'react-native';
import {Picker} from '@react-native-picker/picker'; 
import { useQuery, useMutation } from '@apollo/client';
import { Feather, Ionicons } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import Firebase from '../lib/firebase';
import { getAuth } from 'firebase/auth';
import { GET_USER, UPDATE_USER_NAME, UPDATE_USER_LASTNAME, UPDATE_USER_PHOTO, UPDATE_USER_THUMBNAIL } from '../lib/queries';
import i18n from '../i18n/i18n';
import AsyncStorage from "@react-native-async-storage/async-storage"
import { deleteObject, getStorage, ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import 'react-native-get-random-values';
import { Linking } from 'react-native';
///import ChatWootWidget from '@chatwoot/react-native-widget';\


const ChatWootWidget = Platform.select({
    web: () => null,
    default: () => require('@chatwoot/react-native-widget').default,
})();


const auth = getAuth(Firebase);
const storage = getStorage(Firebase);
const Support = ({navigation}) => {

  // AsyncStorage.getItem('language').then(language => {
  //   setSelected_language(language);
  // });
  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  const uid = getAuth().currentUser.uid;
  const [editing, setEditing] = useState(false);
  const [editingLastName, setEditingLastName] = useState(false)
  const [user_name, setuser_name] = useState('');
  const [user_lastname, setuser_lastname] = useState('')
  const [user_email, setuser_email] = useState('')
  const [user_photo, setuser_photo] = useState('')
  const [user_thumbnail, setuser_thumbnail] = useState('')
  const [selected_language, setSelected_language] = useState(0)
  const [showWidget, toggleWidget] = useState(false);

  const { loading: fetchLoading, data } = useQuery(GET_USER, { variables: { uid }});

//   const [ updateUser, { loading: mutationLoading, data: response } ] = useMutation(UPDATE_USER_NAME);
//   const [ updateLastName, {loading: mutationLoadingLastName, data: responseLastName}] = useMutation(UPDATE_USER_LASTNAME)

//   const [updateUserPhoto, {loading: mutationLoadingPhoto, data: responsePhoto}] = useMutation(UPDATE_USER_PHOTO)
//   const [updateUserThumbnail, {loading: mutationLoadingThumbnail, data: responseThumbnail}] = useMutation(UPDATE_USER_THUMBNAIL)



  useEffect(() => {
    // AsyncStorage.getItem('language').then(language => {
    //   setSelected_language(language);
    // });

    setSelected_language(language);

    let newName = null;
    let newLastName = null;
    let newUserEmail = null;
    let newPhoto = null;
    let newThumbnail = null;
    // console.log("FIREBASEUID: ", uid)
    if (data) {

      // console.log("DATOS: ", data)
      const { users_by_pk: user } = data;
      const { user_name } = user;
      const { user_lastname } = user;
      const { user_email} = user;
      const { user_photo } = user;
      const { user_thumbnail } = user;

      
      if (user_name === null){
        setuser_name('Empty')
        // console.log(user_name)
      }
      {/* if a name already exists, set it as the name */}
      newName = user_name;
      newLastName = user_lastname
      newUserEmail = user_email
      newPhoto = user_photo
      newThumbnail = user_thumbnail
      console.log('newPhoto: ', newPhoto)
    }    
    // if (response) {
    //   // console.log("DATOS: ", response)
    //   const { update_users_by_pk } = response;
    //   const { user_name } = update_users_by_pk;

    //   {/* when a user updates their name, set that as the name */}
    //   newName = user_name;
    // }

    // if(responsePhoto){
    //   const { update_users_by_pk } = responsePhoto;
    //   const { user_photo } = update_users_by_pk;

    //   newPhoto = user_photo;
    // }

    // if(responseThumbnail){
    //   const { update_users_by_pk } = responseThumbnail;
    //   const { user_thumbnail } = update_users_by_pk;

    //   newThumbnail = user_thumbnail;
    // }

    setuser_name(newName);
    setuser_lastname(newLastName)
    setuser_email(newUserEmail)
    setuser_photo(newPhoto)
    setuser_thumbnail(newThumbnail)

    // console.log('language: ', language)
  }, [data]);

  const user ={
    identifier: uid,
    name: user_name + ' ' + user_lastname,
    avatar_url: user_photo,
    email: user_email,
    identifier_hash: '',
  }

  const customAttributes = {accountId: uid }
  const websiteToken = '56oTDC3wvKYXfyeQSYpYKowx';
  const baseUrl ='https://chat.wwoutsourcesolutions.com';
  const locale = language;


  if (fetchLoading) return (
    // flex-1 justify-center items-center
    <SafeAreaView style={{flex:1, justifyContent:'center', alignItems:'center'}}>
      <ActivityIndicator color={'#1e76e8'} size={"large"}/>
    </SafeAreaView>
  )
  return (
    <SafeAreaView style={{flex:1}}>
    <View style={{flex:1, paddingHorizontal:9}}>
        <Text style={{fontSize:24, fontFamily:'Inter-Medium'}}>
            {i18n.t('support.title')}
        </Text>
        <Text style={{fontSize:18, fontFamily:'Inter-Regular'}}>
            {i18n.t('support.body')}
        </Text>
        <TouchableOpacity style={{backgroundColor:'#FF6D00', padding:15, borderRadius:9, alignSelf:'center'}} onPress={() => toggleWidget(true)}>
          <Text style={{fontFamily:'Inter-Black', fontSize:16, color:'#fff'}}>
            {i18n.t('support.open_chat')}
          </Text>
        </TouchableOpacity>
        {
        showWidget&& Platform.OS !== 'web' &&
          <ChatWootWidget
            websiteToken={websiteToken}
            locale={locale}
            baseUrl={baseUrl}
            
            closeModal={() => toggleWidget(false)}
            isModalVisible={showWidget}
            user={user}
            customAttributes={customAttributes}
          />
      }
      <View>
        <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:20}}>
          <Text style={{fontSize:18, fontFamily:'Inter-Medium'}}>
            {i18n.t('support.whatsapp_title')}
          </Text>
          </View>

      </View>
      <TouchableOpacity style={{backgroundColor:'#25D366', padding:15, borderRadius:9, alignSelf:'center', marginTop:20, flexDirection:'row',justifyContent:'center', alignContent:'center', alignItems:'center'}} onPress={() => Linking.openURL('https://wa.me/+12136932550')}>
          <Text style={{fontFamily:'Inter-Black', fontSize:16, color:'#fff'}}>
            {i18n.t('support.open_whatsapp')}
            <Ionicons name="logo-whatsapp" size={24} color="#fff" style={{marginLeft:10, alignSelf:'center', verticalAlign:'middle'}}/>
          </Text>
        </TouchableOpacity>


      </View>

    </SafeAreaView>
  );
}

export default Support;