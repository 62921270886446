import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, TouchableOpacity,KeyboardAvoidingView, Alert, FlatList } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useMutation, useQuery } from '@apollo/client';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid'
import base64 from 'react-native-base64';
import {INSERT_PRODUCT_PAYMENT_OPTION_ONE, INSERT_NEW_PRODUCT, GET_PRODUCT_PAYMENT_OPTIONS, DELETE_PRODUCT_PAYMENT_OPTIONS_BY_ID, GET_USER_PAYMENT_OPTIONS_NOT_SELECTED, GET_USER, IS_USER_IN_ORGANIZATION} from '../../../lib/queries'
import Firebase from '../../../lib/firebase';
import i18n from '../../../i18n/i18n';
import { getAuth } from 'firebase/auth';

const auth = getAuth(Firebase);

const EditPaymentOptions = ({navigation , route}) => {

  const lang = i18n.locale;
  const language = lang.substring(0, 2);
  const languageCode = language.toUpperCase();
  /**route.params */
  
  const {productId } = route.params
  const {product_id} = productId

  const {userCountryPaymentOptionsId} = route.params
  const {userCountryPaymentOptions_Id} = userCountryPaymentOptionsId



  /**Firebase UID */
  const uid =getAuth().currentUser.uid;
  /**Queries */

  const {loading: loadingPaymentOption, error: errorPaymentOption, data: dataPaymentOption, refetch:refetchPaymentOption} = useQuery(GET_PRODUCT_PAYMENT_OPTIONS, {variables:{productId:product_id, languageCode: languageCode}} )

  /**Query available payment methods */
  const {loading: loadingPaymentsNotSelected, error: errorPaymentsNotSelected, data: dataPaymentsNotSelected, refetch:refetchPaymentsNotSelected} = useQuery(GET_USER_PAYMENT_OPTIONS_NOT_SELECTED, {variables:{uid, idUserCountryPaymentOption:userCountryPaymentOptions_Id, languageCode}} )
  const {loading: isUserOnOrganization, error: errorIsUserOnOrganization, data: dataIsUserOnOrganization, refetch:userInOrganization} = useQuery(IS_USER_IN_ORGANIZATION, {variables:{uid}});

  /**Mutations */
  const [deleteProductPaymentOption, {loading: mutationLoadingDelete, data: mutationDataDelete}] = useMutation(DELETE_PRODUCT_PAYMENT_OPTIONS_BY_ID)
  const [insertProductPaymentOption, {loading: mutationLoadingInsert, data: mutationDataInsert}] = useMutation(INSERT_PRODUCT_PAYMENT_OPTION_ONE)
  /**state */
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentOptionsNotSelected, setPaymentOptionsNotSelected] = useState([]);

  const [arrayPaymentOptions, setArrayPaymentOptions] = useState([]);
  const [arrayPaymentOptionsId, setArrayPaymentOptionsId] = useState([]);
  const [tokenPaypal, setTokenPaypal] = useState('');
  const [merchantId, setMerchantId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isInOrganizations, setIsInOrganizations] = useState(false);

  const{loading:userData, data:userDataData, error:userDataError} = useQuery(GET_USER, {variables: {uid}});

  useEffect(() => {
    console.log('errorPaymentOption', errorPaymentsNotSelected)
    async function getPayPalToken(){
      const response = await fetch('https://api-m.paypal.com/v1/oauth2/token ', {
     method: 'POST',
     headers: {
       'Accept': 'application/json',
       'Accept-Language': 'en_US', 
       'Authorization': 'Basic '+ base64.encode('AZuIVyOpv53U1fXhArisIyI25BDbsCo2aIFLmN90y-fgKMyQ-5NY1eAUupZcT5qza1cgVHnlVnneCCG3:EKIS8dDy-LbzLI1Q3eQ63c5rkrU8lGDNoqMcDPK1m0r1OR04X1fmrSs9_uZIKu411NKDp6EV5hXLczGA'),
       'Content-Type': 'application/x-www-form-urlencoded'
     },
     body: 'grant_type=client_credentials',
   })
 
     const content = await response.json();
    //  console.log(content.access_token);
 
     return content.access_token;
     }
 
     const token = getPayPalToken().then(res => {
         // console.log(res);
         setTokenPaypal(res);
     });
 
    //  console.log("token de accesso:", tokenPaypal);
     async function getMerchantId(){
 
         const response = await fetch('https://api-m.paypal.com/v1/customer/partners/YLH8NQMDP8PPQ/merchant-integrations?tracking_id='+ uid,{
             method: 'GET',
             headers: {
                 'Content-Type': 'application/json',
                 'Accept': 'application/json',
                 'Accept-Language': 'en_US',
                 'Authorization': 'Bearer '+ tokenPaypal,
         },
         });
 
         const content = await response.json();
 
         // console.log(content.merchant_id);
         return content.merchant_id;
     }
 
     getMerchantId().then(res => {
        //  console.log("merchantId:", res);
         setMerchantId(res);
     });

    //  console.log('error ', errorIsUserOnOrganization)

     if(dataIsUserOnOrganization){
      const {organization_users} = dataIsUserOnOrganization

      if(organization_users.length > 0){
        setIsInOrganizations(true)
      }else{
        setIsInOrganizations(false)
      }
    }


    let newUserCountryPaymentOption;
    // console.log(dataPaymentOption)

    if(dataPaymentOption){
      const {product_payment_options} = dataPaymentOption
      newUserCountryPaymentOption = product_payment_options
    //   console.log("Hey " , newUserCountryPaymentOption)
    } 

    if( dataPaymentsNotSelected){
        // console.log("dataPaymentsNotSelected", dataPaymentsNotSelected)
        const {user_country_payment_option} = dataPaymentsNotSelected
        setPaymentOptionsNotSelected(user_country_payment_option)
        // console.log("paymentOptionsNotSelected", paymentOptionsNotSelected)
    }
    setPaymentOptions(newUserCountryPaymentOption);
    // console.log(arrayDeliveryOptionsId);

    const unsubscribe = navigation.addListener('focus', () => {
        // console.log("Focus")
        refetchPaymentOption()
        userInOrganization() 
        refetchPaymentsNotSelected()
      });
  })

  const pressColor = (index)=>{
    let newArrayPaymentOptions = [...arrayPaymentOptions];
    newArrayPaymentOptions[index] = !newArrayPaymentOptions[index];
    setArrayPaymentOptions(newArrayPaymentOptions);

    // console.log("Array Delivery Options: ", newArrayPaymentOptions);

    if(newArrayPaymentOptions[index]){
      let newArrayPaymentOptionsId = [...arrayPaymentOptionsId, {user_country_payment_options_id: paymentOptions[index].id_user_country_payment_option}];
      setArrayPaymentOptionsId(newArrayPaymentOptionsId);
      // console.log("Array payment options: ", newArrayPaymentOptionsId)

    }else{
      let newArrayPaymentOptionsId = [...arrayPaymentOptionsId];
      newArrayPaymentOptionsId.splice(index, 1);
      setArrayPaymentOptionsId(newArrayPaymentOptionsId);
      // console.log("Array payment options: ", newArrayPaymentOptionsId);
    }

  }

  const Item = ({item, index})=>{
    return(
      <TouchableOpacity style={{backgroundColor:'#64b5f6', borderRadius:6, marginTop:2, paddingVertical:10 }}
      onPress={()=>{
        // console.log("Hey", item.user_country_payment_options_id)
        Alert.alert("Options", 
        "Do you want to delete this option?",
        [
            {text: "Cancel", onPress: ()=>{}, style: "cancel"},
            {text: "OK", onPress: ()=>{
                // console.log("Hey", item.product_payment_options_id)
                const id = item.product_payment_options_id



                deleteProductPaymentOption({
                    variables:{
                        productPaymentOptionsId:id,
                    }
                }).then(()=>{
                    refetchPaymentOption();
                    Alert.alert("Success", "Option deleted")

                    let newArrayPaymentOptions = [...route.params.userCountryPaymentOptionsId.userCountryPaymentOptions_Id];
                    newArrayPaymentOptions.splice(index, 1);


                    navigation.setParams({
                        userCountryPaymentOptionsId: {
                            userCountryPaymentOptions_Id: newArrayPaymentOptions
                        }
                    })
                })
            }},
        ],
        {cancelable: false}
        )
      }}>
        <Text style={{fontSize:16, textAlign:'center', color:'#000000'}}>
          {item['user_country_payment_option']['country_payment_option']['payment_option']['payment_option_translations'][0].payment_option_name}{" "}
          {item['user_country_payment_option']['country_payment_option']['country']['country_name']}
        </Text>
      </TouchableOpacity>
      
    )
    
  }

  const renderItem = ({item, index})=><Item item={item} index={index}/>


  const ItemPaymentsNotSelected = ({item, index, paypal_label, paypal_enabled})=>{
      return(
          <View>
              {/* <Text>View</Text> */}
              <TouchableOpacity style={{backgroundColor:'#e0e0e0', paddingVertical:10, marginTop:1}}
              disabled={paypal_enabled}
              onPress={()=>{
                //   console.log("Hey", item.id_user_country_payment_option)
                // console.log("hey", item['country_payment_option']['payment_option']['payment_option_translations'][0].payment_option_name)
                Alert.alert(i18n.t('products.editPaymentOptions.alert_add_payment'),
                i18n.t('products.editPaymentOptions.alert_add_payment_body'),
                [
                    
                    {text: "Cancel", onPress: ()=>{}, style: "cancel"},
                    {text: "OK", onPress: ()=>{
                        // console.log("Hey", item.id_user_country_payment_option)
                        const id = item.id_user_country_payment_option

                        let newArrayPaymentOptions = [...route.params.userCountryPaymentOptionsId.userCountryPaymentOptions_Id];
                        newArrayPaymentOptions.push(id);

                        navigation.setParams({
                            userCountryPaymentOptionsId:{
                                userCountryPaymentOptions_Id: newArrayPaymentOptions
                            }
                        });

                        insertProductPaymentOption({
                            variables:
                            {productId:product_id, 
                            idUserCountryPaymentOptions:id
                        }
                        }).then(()=>{
                            refetchPaymentOption();
                            Alert.alert(i18n.t('products.editPaymentOptions.payment_option_added'),
                               i18n.t('products.editPaymentOptions.payment_option_added_body'))
                            

                        })
                        
                    // )
                }
                },
                ],
                {cancelable: false}
                )
              }}>
                  <Text style={{fontSize:16, textAlign:'center'}}>
                    {item['country_payment_option']['payment_option']['payment_option_translations'][0].payment_option_name}{" "}
                    {item['country_payment_option']['country']['country_name']}
                  </Text>
              </TouchableOpacity>
              {(paypal_label == '')?(
                  <></>
              ):(
                <Text style={{fontWeight:'bold', color:'#FF6D00'}}>{paypal_label}</Text>
              )}
          </View>
      )
  }

  const renderPaymentsNotSelected = ({item, index})=>{

    let paymentOptionId = item['country_payment_option']['payment_option'].payment_options_id

    let paypalenabled = true;
    let paypalLabel = '';

    if(paymentOptionId === 2 && merchantId == undefined){
      paypalenabled = true;
      paypalLabel = i18n.t('products.addPaymentOptions.configure_paypal');
    }else{
      paypalenabled = false;
      paypalLabel = '';
    }

    if(isInOrganizations && paymentOptionId === 2){
      paypalenabled = false;
      paypalLabel = i18n.t('products.addPaymentOptions.paypal_organization');
    }

      return(
        <ItemPaymentsNotSelected item={item} index={index}
        paypal_label={paypalLabel}
        paypal_enabled={paypalenabled}
        />
      )
  }

  const ListFooter = ()=>{
    return(
      <View style={{marginTop:1, paddingVertical:2, borderRadius:6}}>
        <Text style={{fontSize:18, color:'#000000', fontWeight:'bold'}}>
          {i18n.t('products.editPaymentOptions.add_new_payment')}
        </Text>
        <Text style={{paddingTop:3, fontSize:16}}>
          {i18n.t('products.editPaymentOptions.add_new_payment_body')}
        </Text>
        <FlatList data={paymentOptionsNotSelected} renderItem={renderPaymentsNotSelected} keyExtractor={(item, index)=>index.toString()}/>
      </View>
    )
  }

    return(
        <SafeAreaView style={{flex:1}}>
        <View style={{paddingHorizontal:5}}>
            <View style={{flexDirection:'row', justifyContent:'space-between'}}>
            <Text style={{fontSize:16, fontWeight:'bold'}}>
                {i18n.t('products.editPaymentOptions.title')}
            </Text>
            </View>
            <View style={{paddingHorizontal:5, flexDirection:'row'}}>
            <Text style={{fontSize:16}}>
             {i18n.t('products.editPaymentOptions.edit_product_payment')}
            </Text>
        </View>
        <View style={{paddingTop:5}}>
            <FlatList data={paymentOptions} ListFooterComponent={ListFooter} renderItem={renderItem} keyExtractor={item => item.user_country_payment_options_id.toString()}/>
        </View>
        </View>
        
    </SafeAreaView>
    )
}

export default EditPaymentOptions;